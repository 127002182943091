import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { REMOTE_DISCOUNT_FIELDS as R_CAMPAIGN } from '../remote-fields/discount-fields'
import { CampaignAdapter } from '../adapters/campaign-adapter'
import { postResquestResponseHandler } from '../../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para update dos dados de uma campanha de descontos
 */
const updateDiscountCampaign = (formValues) => {
  return new Promise((resolve, reject) => {
    const path = `/offers/api/v1/campaign/${formValues[R_CAMPAIGN.ID]}`
    const payload = CampaignAdapter.outboundNameStatus(formValues)
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .put(path, payload, headers)
      .then((response) => {
        return postResquestResponseHandler(response)
      })
      .then((json) => {
        resolve(CampaignAdapter.inbound(json))
      })
      .catch((error) => reject(error))
  })
}
/* eslint-enable no-unused-vars */

export { updateDiscountCampaign }
