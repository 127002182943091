import React, { Component } from 'react'
import PropTypes from 'prop-types'
import contentsService from '../../services/contentsService'
import ShowPdf from '../molecules/showPdf'
import ShowH5p from '../molecules/showH5p'

class ShowContent extends Component {
  constructor(props) {
    super(props)

    let { id } = props
    if (id === undefined) {
      id = props.match.params.id
    }

    this.state = {
      content: [],
      connection: [],
      id
    }
  }

  componentDidMount() {
    const { id } = this.state
    const payload = {
      event: 'Content_Accessed',
      payload: {
        "content_id": id
      }
    }
    Promise.all([
      contentsService().getContent(id),
      contentsService().getAuthorization(),
      contentsService().sendTracking(payload),
    ]).then(([contentResponse, authorizationResponse]) => {
      this.setState({
        content: contentResponse.data,
        connection: authorizationResponse.data
      })
    })
  }

  render() {
    const { connection, content } = this.state
    const { type, token } = connection
    const authorization = `${type} ${token}`
    const url = content.mediaLink

    switch (content.contentType) {
      case 'application/pdf':
        return (
          <div>
            <ShowPdf authorization={authorization} url={url} />
          </div>
        )
      case 'application/octet-stream':
        return (
          <div>
            <ShowH5p file={content.name} />
          </div>
        )
      default:
        return <div />
    }
  }
}

ShowContent.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

ShowContent.defaultProps = {
  match: PropTypes.shape
}

export default ShowContent
