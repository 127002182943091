import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import { Warning } from '@material-ui/icons'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

const ALL = 'changeAll'
const LOCAL = 'changeLocaly'

export default function SwitchDialog({ open, onSave, onClose }) {
  const [checked, setChecked] = React.useState({
    [ALL]: false,
    [LOCAL]: false
  })
  const classes = useStyles()

  const handleChange = (event, checkedKey) => {
    const otherKey = checkedKey === ALL ? LOCAL : ALL
    const currentOtherValue = checked[otherKey]
    const nextOtherValue =
      event.target.checked && currentOtherValue ? false : currentOtherValue

    setChecked((prev) => ({
      ...prev,
      [checkedKey]: Boolean(event.target.checked),
      [otherKey]: nextOtherValue
    }))
  }

  const handleSave = () => {
    onSave(checked[ALL])
  }

  const disabledSaveButton = () => {
    if (checked[ALL] || checked[LOCAL]) {
      return false
    }
    return true
  }

  return (
    <div>
      <Dialog
        className={classes.root}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle id="customized-dialog-title" onClose={onClose}>
          Alterar disponibilidade do material para o aluno
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <div className={classes.contentAlign}>
            <WarningRoundedIcon className={classes.icon} />
            <Typography
              gutterBottom
              variant="body1"
              style={{ color: '#0000008a' }}
            >
              Você está alterando a disponibilidade do material para o aluno.
              Isso será refletido apenas neste curso. Caso queira alterar para
              todos os cursos, marque a opção alterar para todos os cursos.
            </Typography>
          </div>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkContent}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.changeAll}
                      onChange={(event) => handleChange(event, ALL)}
                      style={{ color: '#624C92' }}
                    />
                  }
                  label="Sim, quero alterar para todos os cursos."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.changeLocaly}
                      onChange={(event) => handleChange(event, LOCAL)}
                      style={{ color: '#624C92' }}
                    />
                  }
                  label="Não, quero alterar somente para esse curso."
                />
              </FormGroup>
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button
            disabled={disabledSaveButton()}
            onClick={handleSave}
            color="primary"
          >
            Salvar
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  )
}

SwitchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

SwitchDialog.defaultProps = {}
