import React, { useContext } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core'

import useStyles from './styles'
import HelpIcon from '../../../../components/HelpIcon'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'

const LTI_URLS = [
  {
    name: 'URL da ferramenta:',
    path: `${process.env.REACT_APP_FUSION_ORIGIN}`
  },
  {
    name: 'Public Keyset:',
    path: `${process.env.REACT_APP_FUSION_ORIGIN}/accounts/api/v1/lti/jwks`
  },
  {
    name: 'URL de login:',
    path: `${process.env.REACT_APP_FUSION_ORIGIN}/accounts/api/v1/lti/login`
  },
  {
    name: 'URL de redirecionamento:',
    path: `${process.env.REACT_APP_FUSION_ORIGIN}/accounts/api/v1/lti/launch`
  }
]

function AuthenticationUrls() {
  const classes = useStyles()
  const { productSelected } = useContext(GeneralContext)
  const { connection } = useContext(ConnectionContext)

  const renderLibraryCode = () => {
    if (productSelected.value !== 'bds') {
      return null
    }

    return (
      <>
        <Divider />

        <ListItem>
          <ListItemText
            primary="Chave da Biblioteca:"
            className={classes.label}
          />
          <ListItemText
            primary={connection.library_code}
            className={classes.label}
          />
        </ListItem>
      </>
    )
  }

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        2. Dados a serem enviados para IES:
        <HelpIcon onClick={() => {}} className={classes.helpIcon} />
      </Typography>

      <List component="nav" disablePadding>
        {renderLibraryCode()}

        <Divider />

        {LTI_URLS.map((url) => (
          <div key={url.path}>
            <ListItem>
              <ListItemText primary={url.name} className={classes.label} />
              <ListItemText primary={url.path} className={classes.value} />
            </ListItem>

            <Divider />
          </div>
        ))}
      </List>
    </>
  )
}

export default AuthenticationUrls
