export const ORDER_DETAIL_FIELDS = {
  KEY: 'id',
  ACQUISITION_DATE: 'acquisitionDate',
  ORDER_STATUS: 'orderStatus',
  ORDER_PRICE: 'orderPrice',
  PAYMENT_METHOD: 'paymentMethod',
  CARD_FLAG: 'cardFlag',
  CARD_NAME: 'cardName',
  CODE: 'code',
  DUE_DATE: 'dueDate',
  BUYER_DOCUMENT: 'buyerDocument',
  NUMBER_INSTALLMENTS: 'numberInstallments',
  BILLING_ADDRESS: 'billingAddress',
  STREET: 'street',
  CEP: 'cep',
  CITY: 'city',
  NEIGHBORHOOD: 'neighborhood',
  STATE: 'state',
  NUMBER: 'number',
  COUNTRY: 'country',
  VOUCHER: 'voucher',
  VOUCHER_NAME: 'voucherName',
  VOUCHER_PERCENTAGE: 'voucherPercentage',
  COURSE_SUBTOTAL: 'courseSubtotal',
  ORDER_SUMMARY: 'orderSummary',
  COURSE_ID: 'courseId',
  COURSE_NAME: 'courseName',
  COURSE_PRICE: 'coursePrice',
  COURSE_IMAGE: 'courseImage'
}
