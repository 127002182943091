import React, { useState, useEffect, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { Dialog, TextInput } from '@equipe-ninja/saraiva-ui'
import Typography from '@material-ui/core/Typography'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { CreditCardInfo } from './CreditCardInfo'
import { BankSplipInfo } from './BankSlipInfo'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import {
  getPaymentDetails,
  reverseCoursePayment
} from '../../../../services/user-management-content'
import {
  PAYMENT_INFO_FIELDS,
  COURSE_PAYMENT_TYPES
} from '../../../../domain/user-management-content/user-orders'
import useStyles from './styles'

const {
  PAYMENT_TYPE,
  BANK_SLIP_CODE,
  BANK_SLIP_DUE_DATE,
  CREDIT_CARD,
  CREDIT_CARD_HOLDER_NAME,
  INSTALLMENTS,
  PERSON_ID,
  PERSON_NAME,
  VALUE
} = PAYMENT_INFO_FIELDS

export const CourseReversalDialog = (props) => {
  const {
    showDialog,
    orderItemId,
    onReversalPaymentSuccess,
    onCloseDialog,
    courseName
  } = props

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [state, setState] = useState({
    isExecutingTask: false,
    isFetching: false,
    paymentInfo: {}
  })
  const [reason, setReason] = useState({
    value: '',
    error: ''
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const classes = useStyles()

  const onChange = (event) => {
    const value = event.target?.value
    let error = ''
    if (value.trim() === '') {
      setIsDisabled(true)
      error = 'O campo é obrigatório'
    } else {
      setIsDisabled(false)
    }
    setReason((prev) => ({ ...prev, value, error }))
  }

  const fetchPaymentInfo = useCallback((id) => {
    setState((prev) => ({ ...prev, isFetching: true }))

    getPaymentDetails(id)
      .then((data) => {
        setState((prev) => ({ ...prev, paymentInfo: data }))
      })
      .catch(() => null)
      .finally(() => setState((prev) => ({ ...prev, isFetching: false })))
  }, [])

  const executePaymentReversal = () => {
    setState((prev) => ({ ...prev, isExecutingTask: true }))

    reverseCoursePayment()
      .then(() => {
        setSuccessMessage('Curso estornado com sucesso')
        setOpenConfirmDialog(false)
        setReason('')
        onReversalPaymentSuccess()
      })
      .catch(() => {
        setErrorMessage('Não foi possível estornar o curso')
      })
      .finally(() => {
        setState((prev) => ({ ...prev, isExecutingTask: false }))
      })
  }

  const closeDialogHandler = () => {
    setReason((prev) => ({ ...prev, error: '', value: '' }))
    onCloseDialog()
  }

  const changeStep = () => {
    setOpenConfirmDialog(!openConfirmDialog)
    onCloseDialog()
  }

  useEffect(() => {
    if (showDialog) {
      fetchPaymentInfo(orderItemId)
    }
  }, [showDialog, orderItemId, fetchPaymentInfo])

  return (
    <>
      <Dialog
        open={showDialog}
        title="Estornar curso"
        primaryButton="Próximo"
        secondaryButton="Cancelar"
        handleClose={() => closeDialogHandler()}
        handleConfirm={() => changeStep()}
        isDisabled={isDisabled || state.isExecutingTask}
      >
        <ContainerWithLoader isLoading={state.isFetching}>
          <div className={classes.contentContainer}>
            <Typography
              className={cls(classes.contentItem, classes.textPrimary)}
              variant="h6"
            >
              Dados do pagamento
            </Typography>

            {state.paymentInfo?.[PAYMENT_TYPE] ===
              COURSE_PAYMENT_TYPES.CREDIT_CARD.value && (
              <CreditCardInfo
                creditCard={state.paymentInfo?.[CREDIT_CARD]}
                holderName={state.paymentInfo?.[CREDIT_CARD_HOLDER_NAME]}
                installments={state.paymentInfo?.[INSTALLMENTS]}
                value={state.paymentInfo?.[VALUE]}
              />
            )}

            {state.paymentInfo?.[PAYMENT_TYPE] ===
              COURSE_PAYMENT_TYPES.BANK_SLIP.value && (
              <BankSplipInfo
                code={state.paymentInfo?.[BANK_SLIP_CODE]}
                dueDate={state.paymentInfo?.[BANK_SLIP_DUE_DATE]}
                personID={state.paymentInfo?.[PERSON_ID]}
                personName={state.paymentInfo?.[PERSON_NAME]}
                value={state.paymentInfo?.[VALUE]}
              />
            )}

            <TextInput
              className={cls(classes.contentItem, classes.marginTopMedium)}
              label="Justificativa de alteração*"
              color="primary"
              helperText={reason.error}
              error={!!reason.error}
              size="full"
              value={reason.value}
              onChange={onChange}
            />
          </div>
        </ContainerWithLoader>
      </Dialog>

      <Dialog
        title="Estornar curso"
        secondaryButton="CANCELAR"
        primaryButton="SALVAR"
        size="md"
        handleClose={changeStep}
        handleConfirm={() => executePaymentReversal()}
        open={openConfirmDialog}
        label="Sim, tenho certeza!"
        alert
      >
        {courseName}
      </Dialog>
    </>
  )
}

CourseReversalDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  orderItemId: PropTypes.number.isRequired,
  onReversalPaymentSuccess: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  courseName: PropTypes.string
}

CourseReversalDialog.defaultProps = {
  courseName: ''
}
