import React, { useRef, useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import { TextInput, Button } from '@equipe-ninja/saraiva-ui'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import useStyles from './styles'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const htmlToState = (value) => {
  const { contentBlocks, entityMap } = htmlToDraft(value)
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  )
  return EditorState.createWithContent(contentState)
}

export function TextEditor({
  onChange,
  initialValue,
  showEditor,
  refreshToken
}) {
  const classes = useStyles()

  const [editorState, setEditorState] = useState(() => {
    const { contentBlocks, entityMap } = htmlToDraft(initialValue)
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    return EditorState.createWithContent(contentState)
  })
  const [rawContent, setRawContent] = useState(initialValue)
  const [isShowingEditor, setIsShowingEditor] = useState(showEditor)

  const convertAndNotify = useRef(
    debounce((st) => {
      const rawContentState = convertToRaw(st.getCurrentContent())
      const markup = draftToHtml(rawContentState)

      setRawContent(markup)
      onChange(markup)
    }, 600)
  ).current

  const debouncedConvert = useRef(
    debounce((value) => {
      htmlToState(value)
      const newState = htmlToState(value)
      setEditorState(newState)
    }, 600)
  ).current

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
    convertAndNotify(newEditorState)
  }

  const onRawEditorChange = (newValue) => {
    debouncedConvert(newValue)
    setRawContent(newValue)
    onChange(newValue)
  }

  const toggleShowEditor = () => {
    setIsShowingEditor((prev) => !prev)
  }

  useEffect(() => {
    htmlToState(initialValue)
    setRawContent(initialValue)
    const newState = htmlToState(initialValue)
    setEditorState(newState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken])

  useEffect(() => {
    setIsShowingEditor(showEditor)
  }, [showEditor])

  return (
    <div>
      <Button
        className={classes.toggleButton}
        onClick={toggleShowEditor}
        variant="outlined"
        color="primary"
      >
        {isShowingEditor ? <VisibilityOffIcon /> : <VisibilityIcon />}
        <span style={{ marginLeft: 8 }}>
          {isShowingEditor ? 'Ocultar Editor' : 'Mostrar Editor'}
        </span>
      </Button>

      {isShowingEditor && (
        <Editor
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          toolbarClassName={classes.toolbar}
          onEditorStateChange={onEditorStateChange}
          localization={{ locale: 'pt' }}
          // defaultEditorState={editorState}
          editorState={editorState}
        />
      )}

      {!isShowingEditor && (
        <TextInput
          size="full"
          rows={10}
          color="primary"
          value={rawContent}
          onChange={(ev) => onRawEditorChange(ev.target.value)}
          multiline
        />
      )}
    </div>
  )
}

TextEditor.propTypes = {
  /**
   * callback que sera chamada quando houverem mudancas no estado do interno do input
   * essa chamada passa por um debounce de 1000ms
   *
   * ### Exemplo
   *
   * ```js
   * const onChange = (rawHtml) => {
   *   setContent(rawHtml)
   * }
   *
   * <TextEditor onChange={onChange} />
   * ```
   *
   */
  onChange: PropTypes.func,
  /**
   * estado inicial usado para preload do html, mudar esse valor nao causa mudancas
   */
  initialValue: PropTypes.string,
  showEditor: PropTypes.bool,
  refreshToken: PropTypes.number
}

TextEditor.defaultProps = {
  onChange: () => null,
  initialValue: '',
  showEditor: true,
  refreshToken: 0
}

export default TextEditor
