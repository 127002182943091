import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import { Button, SearchBar } from '@equipe-ninja/saraiva-ui'
import {
  CircularProgress,
  Paper,
  TablePagination,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import { Reply } from '@material-ui/icons'
import { useRecoilValue, useRecoilState } from 'recoil'
import useStyles from './styles'
import { REGISTER_ELEARNING_LIST_SUBJECTS_IN_COURSE_FIELDS } from '../../../../domain/courses/register-elearning-page/register-elearning-list-subjects-in-course-fields'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { getCourseSubjectsPagesList } from '../../../../services/course-management-content/use-cases/get-course-subject-list'
import { getAllSubjectsPagesList } from '../../../../services/course-management-content/use-cases/get-all-subject-list'
import { SubjectsTable } from '../SubjectsTable'
import { RegisterElearningContext } from '../../../../contexts/RegisterElearningContext'
import { UploadProvider } from '../../../../contexts/UploadContext'
import UploadButton from '../UploadButton'
import { ContentsProvider } from '../../../../contexts/ContentsContext'
import {
  allTopicSelector,
  selectedTopicsDataSelector,
  subjectSearchSelector,
  reusableTopicSelector
} from '../../../../atoms/course-topics'
import { REUSABLE_TOPICS } from '../../../../domain/courses/register-elearning-page'
import { getCourseOverview } from '../../../../services/courses'

// const SUBJECT_BREADCRUMBS_LENGTH = 1
const THEME_BREADCRUMBS_LENGTH = 2
const CLASS_BREADCRUMBS_LENGTH = 3
const CONTENT_BREADCRUMBS_LENGTH = 4

const { SUBJECT, KEY } = REGISTER_ELEARNING_LIST_SUBJECTS_IN_COURSE_FIELDS

const {
  TITLE,
  ORIGIN_COURSE_NAME,
  ORIGIN_SERVICE_SKU,
  UPDATED_AT,
  ORIGIN_COURSE_ID
} = REUSABLE_TOPICS

export const SubjectContents = () => {
  const classes = useStyles()
  const {
    setSearchInAllSubjectsValue,
    setSearchInAllSubjectsTerm,
    setIsFetchingInAllSubjects,
    searchInAllSubjectsValue,
    searchInAllSubjectsTerm,
    setAllSubjectsList,
    relationshipId,
    setRelationshipId,
    handleReuseTopics
  } = useContext(RegisterElearningContext)

  const state = useRecoilValue(selectedTopicsDataSelector)
  const [allTopicsState, setAllTopics] = useRecoilState(allTopicSelector)
  const [searchState, setSearchState] = useRecoilState(subjectSearchSelector)
  const [reusableTopics, setReusableTopics] = useRecoilState(
    reusableTopicSelector
  )
  const hierarchyIds = state.topicVisitingHierarchy.map((e) => e[KEY.value])
  const { id: courseId } = useParams()

  const [localSearchState, setLocalSearchState] = useState('')

  const { setErrorMessage } = useContext(GeneralContext)

  const [newButtonTitle, setNewButtonTitle] = useState('NOVA DISCIPLINA')

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(SUBJECT.label)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState([])
  const [list, setList] = useState([])
  const [multipleSelected, setMultipleSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)

  const headersCellsSubjectsInDatabase = [
    { name: 'Disciplina', order: true, code: TITLE },
    { name: 'Curso de origem', order: true, code: ORIGIN_COURSE_NAME },
    { name: 'SKU do Curso', code: ORIGIN_SERVICE_SKU },
    { name: 'Data de alteração', code: UPDATED_AT }
  ]
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const descendingComparator = (a, b, orderByComparator) => {
    if (b[orderByComparator] < a[orderByComparator]) {
      return -1
    }
    if (b[orderByComparator] > a[orderByComparator]) {
      return 1
    }
    return 0
  }

  const getComparator = (orderArg, orderByarg) => {
    return orderArg === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByarg)
      : (a, b) => -descendingComparator(a, b, orderByarg)
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const orderArg = comparator(a[0], b[0])
      if (orderArg !== 0) return orderArg
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelect = (e, discipline) => {
    const { id, checked } = e.target
    const nextMulptipleSelected = [...multipleSelected, discipline]
    setIsCheck([...isCheck, Number(id)])
    setRelationshipId([...relationshipId, discipline.relationship_id])
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== Number(id)))
      setRelationshipId(
        relationshipId.filter((item) => item !== discipline.relationship_id)
      )
      setMultipleSelected(
        nextMulptipleSelected.filter(
          (arch) =>
            arch.id !== discipline.id &&
            arch.relationship_id !== discipline.relationship_id
        )
      )
      setIsCheckAll(false)
    }
  }

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(list.map((li) => li.topic_id))
    setRelationshipId(list.map((li) => li.relationship_id))
    setMultipleSelected(list)
    if (isCheckAll) {
      setRelationshipId([])
      setIsCheck([])
      setMultipleSelected([])
    }
  }

  const delayedQueryInCourse = useRef(
    debounce((e) => {
      setSearchState((prev) => ({ ...prev, subjectSearch: e }))
    }, 800)
  ).current

  const delayedQueryInAllSubjects = useRef(
    debounce((e) => {
      setSearchInAllSubjectsValue(e)
    }, 800)
  ).current

  const isUploadButtonDisabled = () => {
    if (state.topicVisitingHierarchy.length === 0) return false

    return (
      state.topicVisitingHierarchy?.[0]?.[ORIGIN_COURSE_ID] !== Number(courseId)
    )
  }

  const handleSearchInCourseValue = (e) => {
    setLocalSearchState(e.target.value)
    delayedQueryInCourse(e.target.value)
  }

  const handleSearchInAllSubjectsValue = (e) => {
    setSearchInAllSubjectsTerm(e.target.value)
    delayedQueryInAllSubjects(e.target.value)
  }

  const clearSearchInCourse = () => {
    setLocalSearchState('')
    setSearchState((prev) => ({ ...prev, subjectSearch: '' }))
  }

  const clearSearchInAllSubjects = () => {
    setSearchInAllSubjectsTerm('')
    setSearchInAllSubjectsValue('')
  }

  const fetchCourseOverview = useCallback(
    (id) => {
      getCourseOverview(id)
        .then((data) => {
          setAllTopics((prev) => ({
            ...prev,
            title: data?.course?.title ?? ''
          }))
        })
        .catch(() => null)
    },
    [setAllTopics]
  )

  const fetchCourseTopics = useCallback(() => {
    setAllTopics((prev) => ({ ...prev, isFetching: true }))

    getCourseSubjectsPagesList(parseInt(courseId, 10))
      .then((listArg) => {
        setAllTopics((prev) => ({
          ...prev,
          allTopics: listArg,
          requestTimeStamp: Date.now()
        }))
      })
      .catch(() =>
        setErrorMessage(
          'Não foi possível buscar a lista de disciplinas no curso'
        )
      )
      .finally(() => setAllTopics((prev) => ({ ...prev, isFetching: false })))
  }, [courseId, setAllTopics, setErrorMessage])

  useEffect(() => {
    fetchCourseTopics()
  }, [fetchCourseTopics, allTopicsState.requestId])

  useEffect(() => {
    fetchCourseOverview(courseId)
  }, [courseId, fetchCourseOverview])

  const fetchReusableTopics = useCallback((id, search, perPage, pages) => {
    setReusableTopics((prev) => ({ ...prev, isFetchingReusableTopics: true }))

    getAllSubjectsPagesList(id, search, perPage, pages)
      .then(([listData, count]) => {
        setList(listData)
        setCount(count)
        setIsCheck([])
        setIsCheckAll(false)
      })
      .catch(() =>
        setErrorMessage('Não foi possível buscar a lista de disciplinas')
      )
      .finally(() =>
        setReusableTopics((prev) => ({
          ...prev,
          isFetchingReusableTopics: false
        }))
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchReusableTopics(courseId, searchInAllSubjectsValue, rowsPerPage, page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchInAllSubjectsValue,
    setErrorMessage,
    setAllSubjectsList,
    setIsFetchingInAllSubjects,
    allTopicsState.requestId,
    page,
    rowsPerPage
  ])

  useEffect(() => {
    setLocalSearchState(searchState.subjectSearch)
  }, [searchState.subjectSearch, setLocalSearchState])

  useEffect(() => {
    switch (state.breadcrumbs.length) {
      case THEME_BREADCRUMBS_LENGTH:
        setNewButtonTitle('NOVO TEMA')
        break
      case CLASS_BREADCRUMBS_LENGTH:
        setNewButtonTitle('NOVA AULA')
        break
      case CONTENT_BREADCRUMBS_LENGTH:
        setNewButtonTitle('NOVO CONTEÚDO')
        break
      default:
        setNewButtonTitle('NOVA DISCIPLINA')
    }
  }, [state.breadcrumbs])

  return (
    <>
      <div className={classes.sectionContainer}>
        <div className={classes.subTitle}>
          Para publicar um novo curso, é necessário cadastrar ao menos uma
          disciplina. Aqui você consegue cadastrar novas disciplinas, ou
          reaproveitar disciplinas já cadastradas previamente para outros
          cursos.
        </div>

        <h3 className={classes.sectionTitle}>
          Disciplinas em {allTopicsState.title}
        </h3>

        <div className={classes.searchContainer}>
          <SearchBar
            size="md"
            placeholder="Buscar"
            value={localSearchState}
            onChange={handleSearchInCourseValue}
            clearSearch={clearSearchInCourse}
            goBackSearch={clearSearchInCourse}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          />
          <ContentsProvider>
            <UploadProvider>
              <UploadButton
                courseId={courseId}
                buttonTitle={newButtonTitle}
                onUpload={() => fetchCourseTopics()}
                data={hierarchyIds}
                disabled={isUploadButtonDisabled()}
              />
            </UploadProvider>
          </ContentsProvider>
        </div>
        <div className={classes.tableContainer}>
          {state.isFetching ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : (
            <SubjectsTable courseId={courseId} />
          )}
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <h3 className={classes.sectionTitle}>
          Todas as disciplinas cadastradas no banco
        </h3>
        <div className={classes.searchContainer}>
          <SearchBar
            size="md"
            placeholder="Buscar"
            value={searchInAllSubjectsTerm}
            onChange={handleSearchInAllSubjectsValue}
            clearSearch={clearSearchInAllSubjects}
            goBackSearch={clearSearchInAllSubjects}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          />
          <Button
            color="primary"
            startIcon={<Reply />}
            variant="outlined"
            disabled={isCheck.length <= 0}
            onClick={() => handleReuseTopics(courseId, relationshipId)}
          >
            REAPROVEITAR DISCPLINA
          </Button>
        </div>
        <div className={classes.tableContainer}>
          {reusableTopics.isFetchingReusableTopics ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        onClick={handleSelectAll}
                        checked={isCheckAll}
                        className={classes.checkBox}
                      />
                    </TableCell>
                    {headersCellsSubjectsInDatabase.map((headCell) => (
                      <TableCell
                        sortDirection={
                          orderBy === headCell.code ? order : false
                        }
                      >
                        {['title', 'origin_course_name'].includes(
                          headCell.code
                        ) ? (
                          <TableSortLabel
                            classes={{ icon: classes.icon }}
                            active={orderBy === headCell.code}
                            direction={
                              orderBy === headCell.code ? order : 'asc'
                            }
                            id={headCell.code}
                            onClick={() => handleRequestSort(headCell.code)}
                          >
                            {headCell.name}
                          </TableSortLabel>
                        ) : (
                          <div>{headCell.name}</div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {reusableTopics.isFetchingReusableTopics ? (
                  <CircularProgress />
                ) : (
                  <TableBody>
                    {stableSort(list, getComparator(order, orderBy)).map(
                      (row) => (
                        <>
                          <TableRow
                            key={row.topic_id}
                            className={
                              (isCheck.includes(row.topic_id) &&
                                classes.selectedRow) ||
                              classes.tableRow
                            }
                          >
                            <TableCell>
                              <Checkbox
                                className={classes.checkBox}
                                key={row.topic_id}
                                id={row.topic_id}
                                onClick={(e) => handleSelect(e, row)}
                                checked={isCheck.includes(row.topic_id)}
                              />
                            </TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.origin_course_name}</TableCell>
                            <TableCell>{row.origin_service_sku}</TableCell>
                            <TableCell>{row.updated_at}</TableCell>
                          </TableRow>
                        </>
                      )
                    )}
                  </TableBody>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
      </div>
      <div className={classes.registerButtonContainer}>
        <Button
          color="primary"
          variant="contained"
          // eslint-disable-next-line no-alert
          onClick={() => alert('Finalizar cadastro do curso')}
        >
          FINALIZAR CADASTRO
        </Button>
      </div>
    </>
  )
}
