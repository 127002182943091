import React, { useState } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import ticketService from '../../../services/ticketService'
import { useParams } from 'react-router'

const UnarchiveDialog = ({ open, handleClose }) => {
  const { libraryCode } = useParams()

  const [unarchiving, setUnarchiving] = useState(false)

  const unarchiveLib = async () => {
    try {
      setUnarchiving(true)

      const response = await ticketService.unarchiveTickets(libraryCode)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      if (response.ok) {
        window.location.assign(`/libraries/${libraryCode}`)
      }
    } catch (error) {
      setUnarchiving(false)
      console.error(error)
    }
  }

  return (
    <Dialog
      open={open}
      size="lg"
      title="Desarquivar todos os vouchers da biblioteca"
      secondaryButton="cancelar"
      primaryButton="salvar"
      label="Sim, tenho certeza!"
      alert
      handleClose={handleClose}
      handleConfirm={unarchiveLib}
      isDisabled={unarchiving}
    >
      Caso sejam desarquivados, todos os vouchers com datas ativas desta
      biblioteca poderão ser utilizados pelos usuários. Assim, todos os usuários
      que já ativaram seus vouchers terão novamente seu acesso a biblioteca.
    </Dialog>
  )
}

export default UnarchiveDialog
