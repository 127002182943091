import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, CardInfo } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import cls from 'classnames'
import { ContainerWithLoader } from '../../../../../components/ContainerWithLoader'
import useStyles from './styles'
import {
  getCourseDetail,
  getPaymentDetails
} from '../../../../../services/user-management-content'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import { CreditCardInfo } from '../../CourseReversalDialog/CreditCardInfo'
import { BankSplipInfo } from '../../CourseReversalDialog/BankSlipInfo'
import { CompletionStatus } from '../components/CompletionStatus'
import { CourseReversalDialog } from '../../CourseReversalDialog'
import { CourseExtendDialog } from '../../CourseExtendDialog'
import { USER_MANAGEMENT_COURSE_DETAIL_FIELDS as F } from '../../../../../domain/user-management-content/user-management-page/user-management-course-detail-fields'
import {
  COURSE_PAYMENT_TYPES,
  PAYMENT_INFO_FIELDS,
  USER_ORDER_ITEM_COMPLETION_STATUS
} from '../../../../../domain/user-management-content/user-orders'

const { ON_GOING, CANCELLED } = USER_ORDER_ITEM_COMPLETION_STATUS
const {
  PAYMENT_TYPE,
  BANK_SLIP_CODE,
  BANK_SLIP_DUE_DATE,
  CREDIT_CARD,
  CREDIT_CARD_HOLDER_NAME,
  INSTALLMENTS,
  PERSON_ID,
  PERSON_NAME,
  VALUE
} = PAYMENT_INFO_FIELDS

export function CourseDetails() {
  const { courseId, orderId } = useParams()
  const classes = useStyles()
  const { setErrorMessage } = useContext(GeneralContext)
  const [isFetching, setIsFetching] = useState(false)
  const [courseDetails, setCourseDetails] = useState({})
  const [paymentInfo, setPaymentInfo] = useState({})
  const [paymentReversal, setPaymentReversal] = useState({
    itemId: null,
    showDialog: false
  })

  const [showDialogExtend, setShowDialogExtend] = useState(false)

  useEffect(() => {
    setIsFetching(true)
    getCourseDetail(courseId)
      .then((res) => {
        setCourseDetails(res)
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar o curso')
      })
      .finally(() => setIsFetching(false))
  }, [courseId, setErrorMessage])

  const handleExtendAccess = () => {
    setShowDialogExtend(!showDialogExtend)
  }

  useEffect(() => {
    getPaymentDetails(orderId)
      .then((data) => {
        setPaymentInfo(data)
      })
      .catch(() => null)
      .finally(() => setPaymentInfo((prev) => ({ ...prev })))
  }, [orderId])

  const isReverseDisabled = (status) => {
    return status !== ON_GOING.value
  }

  const onReversePaymentClick = (itemId) => {
    setPaymentReversal((prev) => ({ ...prev, itemId, showDialog: true }))
  }

  const onReversalPaymentSuccess = () => {
    setPaymentReversal((prev) => ({ ...prev, itemId: null, showDialog: false }))
  }

  const onExtendAccessSuccess = () => {
    // TODO
  }

  const divWithMargin8 = {
    background: '#F5F5F5',
    marginTop: '8px'
  }

  const divWithMargin16 = {
    marginTop: '16px',
    background: '#FFF'
  }

  const backgroundWhite = {
    background: '#FFF'
  }

  return (
    <ContainerWithLoader isLoading={isFetching}>
      <>
        <Typography variant="h5" className={classes.textH5}>
          {courseDetails[F.NAME.value]}
        </Typography>
        <Typography variant="h7" className={`${classes.textSub}`}>
          SKU: {courseDetails[F.SKU.value]}
        </Typography>
        <Typography variant="h6" className={classes.textH6}>
          Acesso
        </Typography>
        <CardInfo
          text={
            courseDetails?.[F.STATUS.value] !== CANCELLED.value
              ? 'Prorrogar acesso'
              : ''
          }
          onClick={handleExtendAccess}
          color="#245A93"
          className={backgroundWhite}
        >
          <>
            <Typography className={cls(classes.CardSubTitle)}>
              Data de início do acesso ao curso
            </Typography>
            <Typography className={cls(classes.text)}>
              {courseDetails[F.START_DATE.value]}
            </Typography>

            <Typography className={cls(classes.CardSubTitle)}>
              Data de fim do acesso ao curso
            </Typography>
            <Typography className={cls(classes.text)}>
              {courseDetails[F.END_DATE.value]}
            </Typography>

            <Typography className={cls(classes.CardSubTitle)}>
              Último acesso ao curso no ambiente de aprendizagem
            </Typography>
            <Typography className={cls(classes.text)}>
              {courseDetails[F.LAST_ACESS.value]}
            </Typography>

            <Typography className={cls(classes.CardSubTitle)}>
              Status do usuário no curso
            </Typography>
            <CompletionStatus status={courseDetails?.[F.STATUS.value]} />
          </>
        </CardInfo>

        {courseDetails[F.EXTENDED.value] && (
          <CardInfo color="#245A93" className={divWithMargin8}>
            <>
              <Typography className={cls(classes.CardSubTitle)}>
                Prorrogado em
              </Typography>
              <Typography className={cls(classes.text)}>
                {courseDetails[F.EXTENDED.value][F.EXTENDED_DATE.value]}
              </Typography>

              <Typography className={cls(classes.CardSubTitle)}>
                Prorrogação realizada por
              </Typography>
              <Typography className={cls(classes.text)}>
                {courseDetails[F.EXTENDED.value][F.EXTENDED_BY.value]}
              </Typography>

              <Typography className={cls(classes.CardSubTitle)}>
                Prorrogação de
              </Typography>
              <Typography className={cls(classes.text)}>
                {courseDetails[F.EXTENDED.value][F.EXTENDED_FROM_TO.value]}
              </Typography>

              <Typography className={cls(classes.CardSubTitle)}>
                Justificativa
              </Typography>
              <Typography className={cls(classes.text)}>
                {
                  courseDetails[F.EXTENDED.value][
                    F.EXTENDED_JUSTIFICATION.value
                  ]
                }
              </Typography>
            </>
          </CardInfo>
        )}

        <Typography variant="h6" className={classes.textH6}>
          Detalhes de pagamento do curso
        </Typography>
        <CardInfo className={backgroundWhite}>
          <>
            <Typography className={cls(classes.CardSubTitle)}>Preço</Typography>
            <Typography className={cls(classes.text)}>
              {courseDetails[F.PRICE.value]}
            </Typography>

            <Typography className={cls(classes.CardSubTitle)}>
              Desconto de oferta
            </Typography>
            <Typography className={cls(classes.text)}>
              {courseDetails[F.DISCOUNT.value]}
            </Typography>

            <Typography className={cls(classes.CardSubTitle)}>
              Subtotal
            </Typography>
            <Typography className={cls(classes.text)}>
              {courseDetails[F.SUBTOTAL.value]}
            </Typography>
          </>
        </CardInfo>
        {courseDetails[F.VOUCHER.value] && (
          <CardInfo className={divWithMargin16}>
            <>
              <Typography className={cls(classes.CardSubTitle)}>
                Voucher aplicado no pedido
              </Typography>
              <Typography className={cls(classes.textWithOutMb)}>
                {courseDetails[F.VOUCHER.value][F.VOUCHER_NAME.value]}
              </Typography>
              <Typography className={cls(classes.text)}>
                {courseDetails[F.VOUCHER.value][F.VOUCHER_PERCENTAGE.value]}
              </Typography>
            </>
          </CardInfo>
        )}
        <CardInfo className={divWithMargin16}>
          <>
            <Typography className={cls(classes.CardTextH6)}>
              Valor total do curso: R$ {courseDetails[F.TOTAL.value]}
            </Typography>
          </>
        </CardInfo>
        <Button
          color="primary"
          disabled={isReverseDisabled(courseDetails?.[F.STATUS.value])}
          onClick={() => onReversePaymentClick(courseDetails?.[F.KEY.value])}
          className={cls(classes.errorButton, classes.marginLeft16)}
          variant="outlined"
        >
          {courseDetails?.[F.STATUS.value] === CANCELLED.value
            ? 'Curso estornado'
            : 'Estornar curso'}
        </Button>

        {courseDetails?.[F.STATUS.value] === CANCELLED.value && (
          <CardInfo color="#245A93" className={divWithMargin8}>
            <>
              <Typography className={cls(classes.CardSubTitle)}>
                Estorno em
              </Typography>
              <Typography className={cls(classes.text)}>
                {courseDetails?.[F.REVERSAL.value][F.REVERSAL_DATE.value]}
              </Typography>

              <Typography className={cls(classes.CardSubTitle)}>
                Estorno realizado por
              </Typography>
              <Typography className={cls(classes.text)}>
                {courseDetails?.[F.REVERSAL.value][F.REVERSAL_BY.value]}
              </Typography>

              {paymentInfo?.[PAYMENT_TYPE] ===
                COURSE_PAYMENT_TYPES.CREDIT_CARD.value && (
                <CreditCardInfo
                  creditCard={paymentInfo?.[CREDIT_CARD]}
                  holderName={paymentInfo?.[CREDIT_CARD_HOLDER_NAME]}
                  installments={paymentInfo?.[INSTALLMENTS]}
                  value={paymentInfo?.[VALUE]}
                />
              )}

              {paymentInfo?.[PAYMENT_TYPE] ===
                COURSE_PAYMENT_TYPES.BANK_SLIP.value && (
                <BankSplipInfo
                  code={paymentInfo?.[BANK_SLIP_CODE]}
                  dueDate={paymentInfo?.[BANK_SLIP_DUE_DATE]}
                  personID={paymentInfo?.[PERSON_ID]}
                  personName={paymentInfo?.[PERSON_NAME]}
                  value={paymentInfo?.[VALUE]}
                />
              )}

              <Typography className={cls(classes.CardSubTitle)}>
                Justificativa
              </Typography>
              <Typography className={cls(classes.text)}>
                {
                  courseDetails[F.REVERSAL.value][
                    F.REVERSAL_JUSTIFICATION.value
                  ]
                }
              </Typography>
            </>
          </CardInfo>
        )}

        <CourseExtendDialog
          showDialog={showDialogExtend}
          courseId={courseId}
          courseStatus={courseDetails?.[F.STATUS.value]}
          courseDateEnd={courseDetails?.[F.END_DATE.value]}
          onCloseDialog={() => handleExtendAccess()}
          onExtendAccessSuccess={onExtendAccessSuccess}
        />

        <CourseReversalDialog
          showDialog={paymentReversal.showDialog}
          orderItemId={paymentReversal.itemId}
          onCloseDialog={() =>
            setPaymentReversal((prev) => ({ ...prev, showDialog: false }))
          }
          onReversalPaymentSuccess={onReversalPaymentSuccess}
          courseName={courseDetails?.[F.NAME.value]}
        />
      </>
    </ContainerWithLoader>
  )
}
