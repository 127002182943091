import Joi from 'joi'
import { ECOMMERCE_FORM_FIELDS as F } from '../../courses/entities'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../helpers/validation'
import { SELECTABLE_CATEGORIES } from './selectable-categories'

const schema = Joi.object({
  [F.DESCRIPTION]: Joi.string().required(),
  [F.COURSE_LENGTH]: Joi.string().required(),
  [F.ACCESS_WINDOW]: Joi.number().required().greater(0).messages({
    'number.base': 'Campo obrigatório',
    'number.greater': 'Deve ser maior que 0'
  }),
  [F.VALUE]: Joi.string()
    .required()
    .pattern(/^\d*,?\d*$/)
    .messages({
      'string.pattern.base': 'Deve possuir o formato ##,##'
    }),
  [F.CATEGORY]: Joi.number().required().messages({
    'number.base': 'Campo obrigatório'
  }),
  [F.SUB_CATEGORY]: Joi.any()
    .when(F.CATEGORY, {
      switch: [
        {
          is: SELECTABLE_CATEGORIES.ATUALIZACAO_PRATICA,
          then: Joi.string().allow('')
        },
        {
          is: SELECTABLE_CATEGORIES.LFG_PLAY,
          then: Joi.string().allow('')
        }
      ],
      otherwise: Joi.number()
    })
    .messages({
      'number.base': 'Campo obrigatório'
    }),
  [F.URL]: Joi.string().required(),
  [F.IMG_BINARY]: Joi.any().when(F.IMG_CHANGED, {
    is: true,
    then: Joi.object().instance(File).messages({
      'object.instance': 'Campo obrigatório'
    }),
    otherwise: Joi.any().allow(null)
  })
})

const removeUnwantedEntries = (form) => {
  return Object.fromEntries(
    Object.entries(form).filter(([k]) => k !== F.COURSE_TABS)
  )
}

export const generalInfoValidation = (formValues) => {
  const formWithoutExcludedEntries = removeUnwantedEntries(formValues)

  return validateAndGenerateErrors(formWithoutExcludedEntries, schema)
}

export const generalInfoFieldValidation = (field, value, formValues) => {
  return validateSingleFieldAndGenerateErrors(field, value, formValues, schema)
}
