import React from 'react'
import PropTypes from 'prop-types'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import {
  TextInput,
  DatePickerSaraiva,
  SelectSaraiva
} from '@equipe-ninja/saraiva-ui'
import { InputAdornment } from '@material-ui/core'
import useStyles from './styles'
import {
  VOUCHER_FIELDS,
  VOUCHER_TYPES,
  VOUCHER_STATUSES
} from '../../../../domain/vouchers'
import { numberPipeMask } from '../../../../helpers/input-pipe-masks'

const {
  VOUCHER_TYPE,
  CAMPAIGN_NAME,
  PREFIX,
  DISCOUNT_VALUE,
  USES_PER_VOUCHER,
  VOUCHER_AMOUNT,
  START_DATE,
  END_DATE,
  STATUS
} = VOUCHER_FIELDS

export const NewVoucherForm = (props) => {
  const { formState, formErrors, onChange } = props
  const classes = useStyles()

  const onChangeEvent = (field, event, adapter = (e) => e) => {
    onChange(field, adapter(event.target.value))
  }

  const onChangeValue = (field, value) => {
    onChange(field, value)
  }

  return (
    <>
      <Typography
        className={`${classes.subsectionTitle} ${classes.marginTopLarge}`}
        variant="h5"
      >
        Tipo de voucher
      </Typography>

      <RadioGroup
        aria-label="tipo de voucher a ser criado"
        className={classes.radioContainer}
        name={VOUCHER_TYPE}
        onChange={(ev) => onChangeEvent(VOUCHER_TYPE, ev)}
        value={formState[VOUCHER_TYPE]}
      >
        <FormControlLabel
          control={<Radio />}
          label={VOUCHER_TYPES.ONE_USE.label}
          value={VOUCHER_TYPES.ONE_USE.value}
        />

        <FormControlLabel
          value={VOUCHER_TYPES.MANY_USES.value}
          control={<Radio />}
          label={VOUCHER_TYPES.MANY_USES.label}
        />
      </RadioGroup>

      {formState[VOUCHER_TYPE] && (
        <>
          <Paper className={`${classes.paper} ${classes.marginTopMedium}`}>
            <Typography variant="h6">
              {formState[VOUCHER_TYPE] === VOUCHER_TYPES.ONE_USE.value
                ? 'Um usuário por voucher'
                : 'Multiplos usuários por voucher'}
            </Typography>

            <TextInput
              className={classes.marginTopMedium}
              color="primary"
              error={formErrors[CAMPAIGN_NAME]}
              helperText={formErrors[CAMPAIGN_NAME]}
              label="Nome da campanha"
              onChange={(ev) => onChangeEvent(CAMPAIGN_NAME, ev)}
              required
              size="full"
              value={formState[CAMPAIGN_NAME]}
            />

            <TextInput
              className={classes.marginTopMedium}
              color="primary"
              error={formErrors[PREFIX]}
              helperText={formErrors[PREFIX]}
              label="Prefixo no voucher"
              onChange={(ev) => onChangeEvent(PREFIX, ev)}
              required
              size="full"
              value={formState[PREFIX]}
            />

            <TextInput
              className={classes.marginTopMedium}
              color="primary"
              error={formErrors[DISCOUNT_VALUE]}
              helperText={formErrors[DISCOUNT_VALUE]}
              label="Porcentagem de desconto"
              startAdornment={<InputAdornment>%</InputAdornment>}
              onChange={(ev) =>
                onChangeEvent(DISCOUNT_VALUE, ev, numberPipeMask)
              }
              required
              size="full"
              value={formState[DISCOUNT_VALUE]}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  className={classes.marginTopMedium}
                  color="primary"
                  disabled={
                    formState[VOUCHER_TYPE] === VOUCHER_TYPES.MANY_USES.value
                  }
                  error={formErrors[VOUCHER_AMOUNT]}
                  helperText={formErrors[VOUCHER_AMOUNT]}
                  label="Quantidade de vouchers"
                  onChange={(ev) =>
                    onChangeEvent(VOUCHER_AMOUNT, ev, numberPipeMask)
                  }
                  required
                  size="full"
                  value={formState[VOUCHER_AMOUNT]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  className={classes.marginTopMedium}
                  color="primary"
                  disabled={
                    formState[VOUCHER_TYPE] === VOUCHER_TYPES.ONE_USE.value
                  }
                  error={formErrors[USES_PER_VOUCHER]}
                  helperText={formErrors[USES_PER_VOUCHER]}
                  label="Quantidade de usuários por voucher"
                  onChange={(ev) =>
                    onChangeEvent(USES_PER_VOUCHER, ev, numberPipeMask)
                  }
                  required
                  size="full"
                  value={formState[USES_PER_VOUCHER]}
                />
              </Grid>
            </Grid>
          </Paper>

          <section>
            <Typography className={classes.marginTopXL} variant="h5">
              Publicar
            </Typography>

            <Grid container spacing={3} className={classes.marginTopMedium}>
              <Grid item sm={6}>
                <DatePickerSaraiva
                  error={formErrors[START_DATE]}
                  helperText={formErrors[START_DATE]}
                  label="Data início *"
                  onChange={(selectedDate) =>
                    onChangeValue(START_DATE, selectedDate)
                  }
                  size="full"
                  value={formState[START_DATE]?.toString() || null}
                />
              </Grid>

              <Grid item sm={6}>
                <DatePickerSaraiva
                  error={formErrors[END_DATE]}
                  helperText={formErrors[END_DATE]}
                  label="Data fim *"
                  onChange={(selectedDate) =>
                    onChangeValue(END_DATE, selectedDate)
                  }
                  size="full"
                  value={formState[END_DATE]?.toString() || null}
                />
              </Grid>
            </Grid>

            <div className={classes.marginTopMedium}>
              <SelectSaraiva
                error={formErrors[STATUS]}
                helperText={formErrors[STATUS] || ''}
                label="Status do voucher *"
                onChange={(ev) => onChangeEvent(STATUS, ev)}
                value={formState[STATUS]}
                size="full"
              >
                <MenuItem value="">
                  <em>Nenhum status selecionado</em>
                </MenuItem>

                <MenuItem value={VOUCHER_STATUSES.ACTIVE.value}>
                  {VOUCHER_STATUSES.ACTIVE.label}
                </MenuItem>

                <MenuItem value={VOUCHER_STATUSES.INACTIVE.value}>
                  {VOUCHER_STATUSES.INACTIVE.label}
                </MenuItem>
              </SelectSaraiva>
            </div>
          </section>
        </>
      )}
    </>
  )
}

NewVoucherForm.propTypes = {
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
  formErrors: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired
}

NewVoucherForm.defaultProps = {}
