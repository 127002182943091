import { COURSE_PAYMENT_TYPES } from '../../../domain/user-management-content/user-orders'

const REMOTE_PAYMENT_TYPE = {
  CREDIT_CARD: 1,
  BANK_SLIP: 2
}

const inbound = (remoteValue) => {
  switch (remoteValue) {
    case REMOTE_PAYMENT_TYPE.CREDIT_CARD:
      return COURSE_PAYMENT_TYPES.CREDIT_CARD.value
    case REMOTE_PAYMENT_TYPE.BANK_SLIP:
      return COURSE_PAYMENT_TYPES.BANK_SLIP.value
    default:
      return ''
  }
}
const outbound = (localValue) => {
  switch (localValue) {
    case COURSE_PAYMENT_TYPES.CREDIT_CARD.value:
      return REMOTE_PAYMENT_TYPE.CREDIT_CARD
    case COURSE_PAYMENT_TYPES.BANK_SLIP.value:
      return REMOTE_PAYMENT_TYPE.BANK_SLIP
    default:
      return ''
  }
}

export const PaymentTypeAdapter = {
  inbound,
  outbound
}
