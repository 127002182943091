import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Divider } from '@material-ui/core'
import { SelectSaraiva } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles.js'
import { GeneralContext } from '../../contexts/GeneralContext'
import {
  PRODUCT_SELECTION_OPTIONS,
  PRODUCT_SELECTION_VALUES
} from '../../domain/general/product-selection-options'

export default function ProductSelect({
  onChange,
  onlyBds,
  productsOptions,
  productLabel
}) {
  const classes = useStyles()

  let productList = productsOptions.map((type) =>
    PRODUCT_SELECTION_OPTIONS.find((p) => p.value === type)
  )

  const { productSelected } = useContext(GeneralContext)

  if (onlyBds) {
    productList = PRODUCT_SELECTION_OPTIONS.filter(
      (product) => product.value === PRODUCT_SELECTION_VALUES.BDS
    )
  }

  return (
    <>
      <div className={classes.root}>
        <SelectSaraiva
          label={productLabel || 'Produtos'}
          onChange={(e) => {
            const product =
              productList.find(
                (_product) => _product.value === e.target.value
              ) || {}

            onChange(product)
          }}
          value={productSelected?.value}
        >
          <MenuItem value="">
            <em>Nenhum Produto</em>
          </MenuItem>

          {productList.map((product) => (
            <MenuItem value={product.value} key={product.value}>
              {product.label}
            </MenuItem>
          ))}
        </SelectSaraiva>
      </div>

      <Divider className={classes.divider} />
    </>
  )
}

ProductSelect.defaultProps = {
  onlyBds: false,
  productsOptions: [PRODUCT_SELECTION_VALUES.BDS, PRODUCT_SELECTION_VALUES.DONS]
}

ProductSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onlyBds: PropTypes.bool,
  /**
   * A lista dos produtos que serao exibidos no dropdown
   *
   * ### Exemplo
   *
   * ```js
   * const productsList = ['bds', 'lfg']
   *
   * <ProductSelect {...} productsOptions={productsList} />
   * ```
   */
  productsOptions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(PRODUCT_SELECTION_VALUES))
  )
}
