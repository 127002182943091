export const COURSE_PAYMENT_TYPES = {
  CREDIT_CARD: {
    value: 'creditCard',
    label: 'Cartão'
  },
  BANK_SLIP: {
    value: 'bankSlip',
    label: 'Boleto'
  }
}
