import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonSelectDiscipline: {
    padding: '0',
    height: '5vh',
    width: '258px',
    margin: '24px 8px 8px 8px'
  },
  checkboxItems: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px'
  },
  checked: {
    color: theme.palette.primary.main,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  notChecked: {
    color: theme.palette.action.active,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    color: theme.palette.action.active,
    width: 560,
    height: 362,
    padding: 0,
    margin: '0px 0 24px 0'
  },
  pasteIcon: {
    margin: '0 10px 0 10px'
  }
}))

export default useStyles
