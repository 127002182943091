/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { ContentsContext } from './ContentsContext'
import { GeneralContext } from './GeneralContext'
import ContentConnection from '../services/contentConnection'
import Messages from '../helpers/messages'

const UploadContext = createContext()

function UploadProvider({ children }) {
  const { addContentOnState, moduleParentId } = useContext(ContentsContext)
  const { setInfoMessage } = useContext(GeneralContext)

  const [showingUploadBar, toggleShowingUploadBar] = useState(false)
  const [errorsBar, setErrorsBar] = useState([])
  const [filesError, setFilesError] = useState([])
  const [countFileUpload, setCountFileUpload] = useState(0)
  const [totalFiles, setTotalFiles] = useState(0)
  const [reload, setReload] = useState(false)
  const listUploadErrors = []

  const treePath = new Map()

  const clearTreePath = () => {
    treePath.clear()
  }

  async function uploadFolder(folderName, moduleParentIdUpload, relativePath) {
    const payload = {
      name: folderName,
      description: Messages.pathDescription.default,
      module_parent_id: moduleParentIdUpload
    }

    try {
      const response = await ContentConnection.createFolder(payload)
      const result = await response.json()
      if (response.status === 422) {
        const folderError = {
          name: folderName,
          description: Messages.pathDescription.default,
          moduleParentId: moduleParentIdUpload,
          path: relativePath,
          type: 'folder',
          error: Messages.createFolder.alreadyCreated
        }
        if (result.errors.name[0] === 'Folder already exists') {
          setInfoMessage(Messages.createFolder.alreadyCreated)
        }
        if (!reload) {
          filesError.push(folderError)
        }
        listUploadErrors.push(folderName)
      }

      if (
        moduleParentIdUpload === moduleParentId ||
        moduleParentIdUpload === null
      ) {
        const newFolder = {
          id: result.id,
          name: result.name,
          type: 'folder'
        }
        if (response.ok) {
          addContentOnState(newFolder)
        }
      }

      treePath.set(relativePath, result.id)
    } catch (error) {
      const folderError = {
        name: folderName,
        description: Messages.pathDescription.default,
        moduleParentId: moduleParentIdUpload,
        path: relativePath,
        type: 'folder',
        error: error.response.data
      }
      if (!reload) {
        filesError.push(folderError)
      }
      listUploadErrors.push(folderName)
    }
  }

  async function uploadFile(file, moduleParentIdUpload) {
    const payload = new FormData()
    payload.append('file', file)
    payload.append('file_size', file.size)
    payload.append('name', file.name.replace('.h5p', ''))
    payload.append('module_id', moduleParentIdUpload)

    try {
      const response = await ContentConnection.createFile(payload)
      if (response.status !== 201) {
        const fileError = {
          file,
          moduleParentId: moduleParentIdUpload,
          type: 'file',
          error: Messages.createFile.error
        }
        if (!reload) {
          filesError.push(fileError)
        }
        listUploadErrors.push(file.name)
      }

      if (moduleParentIdUpload === moduleParentId) {
        const newFile = {
          id: response.data.id,
          name: response.data.name,
          type: 'H5P',
          updated_at: response.data.updated_at
        }
        addContentOnState(newFile)
      }
    } catch (error) {
      const fileError = {
        file,
        moduleParentId: moduleParentIdUpload,
        type: 'file',
        error: error.response.data
      }
      if (!reload) {
        filesError.push(fileError)
      }
      listUploadErrors.push(file.name)
    }
  }

  async function updatedContent(content, contentId, moduleId) {

    const payload = new FormData()
    payload.append('file', content)
    payload.append('file_size', content.size)
    payload.append('name', content.name.replace('.h5p', ''))
    payload.append('module_id', moduleId)

    try {
      
      await ContentConnection.updatedFile(payload, contentId)

    } catch (error) {
      const fileError = {
        file: content,
        moduleParentId: moduleId,
        type: 'file',
        error: error.response.data
      }
      if (!reload) {
        filesError.push(fileError)
      }
      listUploadErrors.push(content.name)
    }
  }

  function getRelativePathListFile(webkitRelativePath) {
    const relativePathList = webkitRelativePath.split('/')
    relativePathList.pop()
    return relativePathList
  }

  function verifyIfFolderIsChild(relativePathList) {
    if (moduleParentId === undefined && relativePathList.length - 1 === 0) {
      return null
    }
    if (moduleParentId && relativePathList.length - 1 === 0) {
      return moduleParentId
    }
    const pathList = relativePathList.slice()
    pathList.pop()
    const childRelativePath = pathList.join('/')
    return treePath.get(childRelativePath)
  }

  async function doUploadFile(file, relativePath) {
    const moduleParentIdUpload = treePath.get(relativePath)
    await uploadFile(file, moduleParentIdUpload)
  }

  async function doUploadFolder(relativePathList) {
    const relativePath = relativePathList.join('/')
    const paths = []
    let fatherPath = ''

    if (!treePath.has(relativePath)) {
      for (const folderPath of relativePathList) {
        paths.push(folderPath)
        fatherPath = paths.join('/')

        if (!treePath.has(fatherPath)) {
          const folderName = folderPath
          const moduleParentIdUpload = verifyIfFolderIsChild(paths)
          await uploadFolder(folderName, moduleParentIdUpload, fatherPath)
        }
      }
      paths.splice(0, relativePathList.length - 1)
    }
  }

  async function reloadFiles(files) {
    await setErrorsBar([])
    await setCountFileUpload(0)
    await setTotalFiles(filesError.length)
    for (const file of files) {
      if (file.type === 'folder') {
        await uploadFolder(file.name, file.moduleParentId, file.path)
        await setCountFileUpload((currentState) => {
          return currentState + 1
        })
      }
      if (file.type === 'file') {
        await uploadFile(file.file, file.moduleParentId)
        await setCountFileUpload((currentState) => {
          return currentState + 1
        })
      }
      if (localStorage.getItem('upload') === '0') {
        break
      }
    }
    if (filesError !== []) {
      setErrorsBar(listUploadErrors)
      setReload(true)
    }
  }

  async function clearState() {
    localStorage.setItem('upload', '0')
    await setReload(false)
    await toggleShowingUploadBar(false)
    await setErrorsBar([])
    await setCountFileUpload(0)
    await setTotalFiles(0)
  }

  return (
    <UploadContext.Provider
      value={{
        uploadFolder,

        uploadFile,

        getRelativePathListFile,

        verifyIfFolderIsChild,

        doUploadFile,

        doUploadFolder,

        clearTreePath,

        reloadFiles,

        clearState,

        showingUploadBar,
        toggleShowingUploadBar,

        errorsBar,
        setErrorsBar,

        countFileUpload,
        setCountFileUpload,

        totalFiles,
        setTotalFiles,

        filesError,
        setFilesError,

        reload,
        setReload,

        listUploadErrors,

        updatedContent
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

UploadProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { UploadContext, UploadProvider }
