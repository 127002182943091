import { REGISTER_ELEARNING_LIST_SUBJECTS_IN_COURSE_FIELDS as F } from '../../../domain/courses/register-elearning-page/register-elearning-list-subjects-in-course-fields'
import {
  SUBJECTS_IN_COURSE_FIELDS as REMOTE,
  REMOTE_TOPIC_TYPES
} from '../remote-fields/subjects-in-course-fields'
import { remoteDateStringToHuman } from '../../utils/remote-datetime-utils'

const HIERARCHY = {
  SUBJECT: 'disciplina',
  THEME: 'tema',
  CLASS: 'subtema',
  CONTENT: 'conteudo'
}

const inboundType = (remoteTopicData) => {
  switch (remoteTopicData?.[REMOTE.TYPE]) {
    case REMOTE_TOPIC_TYPES.FILE:
      return remoteTopicData?.[REMOTE.SUBJECT].replace(/.*\.(\w*)$/, '$1')
    case REMOTE_TOPIC_TYPES.MEDIA:
      return 'mídia'
    case REMOTE_TOPIC_TYPES.FOLDER:
      return remoteTopicData?.[REMOTE.TYPE]
    default:
      return 'Não informado'
  }
}

const inboundTopicAdapter = (remoteData, hierarchy) => {
  let nextHierarchy = null
  let childrenObjectKey = null

  switch (hierarchy) {
    case HIERARCHY.SUBJECT:
      nextHierarchy = HIERARCHY.THEME
      childrenObjectKey = REMOTE.THEMES
      break
    case HIERARCHY.THEME:
      nextHierarchy = HIERARCHY.CLASS
      childrenObjectKey = REMOTE.CLASSES
      break
    case HIERARCHY.CLASS:
      nextHierarchy = HIERARCHY.CONTENT
      childrenObjectKey = REMOTE.CONTENTS
      break

    default:
      break
  }

  const adaptedChildren =
    remoteData?.[childrenObjectKey]?.map((child) =>
      inboundTopicAdapter(child, nextHierarchy)
    ) || []

  return {
    [F.KEY.value]: remoteData[REMOTE.KEY],
    [F.SUBJECT.value]: remoteData[REMOTE.SUBJECT],
    [F.ADDED_IN.value]: remoteData[REMOTE.ADDED_IN],
    [F.SKU.value]: remoteData[REMOTE.SKU],
    [F.HIERARCHY.value]: hierarchy,
    [F.UPDATED_AT.value]: remoteDateStringToHuman(
      remoteData[REMOTE.UPDATED_AT]
    ),
    [F.FULL_POSTED.value]: remoteData[REMOTE.FULL_POSTED],
    [F.IS_AVAILABLE.value]: remoteData[REMOTE.IS_AVAILABLE],
    [F.PARENT_ID.value]: null,
    [F.PATH.value]: remoteData[REMOTE.PATH],
    [F.TYPE.value]: inboundType(remoteData),
    [F.CHILDREN.value]: adaptedChildren,
    [F.RELATION_ID.value]: remoteData[REMOTE.COURSE_RELATIONSHIP_ID],
    [F.ORIGIN_COURSE_ID.value]: remoteData[REMOTE.ORIGIN_COURSE_ID]
  }
}

export const listItemAdapterInbound = (remoteDataList) => {
  return remoteDataList.map((remoteData) =>
    inboundTopicAdapter(remoteData, HIERARCHY.SUBJECT)
  )
}
