export const SUBJECTS_IN_COURSE_FIELDS = {
  KEY: 'id',
  SUBJECT: 'title',
  ADDED_IN: 'origin_course_title',
  SKU: 'origin_service_sku',
  UPDATED_AT: 'updated_at',
  IS_AVAILABLE: 'available',
  FULL_POSTED: 'full_posted',
  CHILDREN: 'children',
  TYPE: 'type',
  PATH: 'file_url',
  PARENT_ID: 'parent_id',
  HIERARCHY: 'hierarchy',
  THEMES: 'themes',
  CLASSES: 'classes',
  CONTENTS: 'contents',
  COURSE_RELATIONSHIP_ID: 'course_relationship_id',
  ORIGIN_COURSE_ID: 'origin_course_id'
}

export const REMOTE_TOPIC_TYPES = {
  FILE: 'file',
  FOLDER: 'folder',
  MEDIA: 'media'
}
