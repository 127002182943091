import React from 'react'
import PropTypes from 'prop-types'
import {
  TextInput,
  DatePickerSaraiva,
  SelectSaraiva
} from '@equipe-ninja/saraiva-ui'
import { MenuItem } from '@material-ui/core'
import {
  PROMOTIONAL_PAGES_FIELDS,
  PUBLISHING_STATUS
} from '../../../../domain/promotional-content/promotional-pages'
import { initialURLValueForBrand } from '../../../../domain/brands'
import ContentTitle from '../../../../components/ContentTitle'
import useStyles from './styles'
import TextEditor from '../../../../components/TextEditor'
import { onlyDatePipe } from '../../../../helpers/input-pipe-masks'

export const PromotionalPageForm = ({
  formState,
  formErrors,
  onChange,
  brand,
  originalTitle
}) => {
  const classes = useStyles()

  const { CONTENT, END_DATE, REDIRECT_URL, START_DATE, STATUS, TITLE, URL } =
    PROMOTIONAL_PAGES_FIELDS

  const onChangeHandlerForEvent = (field, event) => {
    onChange(field, event.target.value)
  }

  const onChangeHandler = (field, value, adapter = (f) => f) => {
    onChange(field, adapter(value))
  }

  return (
    <form className={classes.formContainer}>
      {!!originalTitle && (
        <ContentTitle
          style={{ marginTop: '16px' }}
          title={`'${originalTitle}'`}
          size="small"
        />
      )}

      <div className={[classes.spacing, classes.titleSection].join(' ')}>
        <TextInput
          color="primary"
          size="full"
          error={!!formErrors[TITLE.value]}
          helperText={
            <span className={classes.helperText}>
              {formErrors[TITLE.value]}
            </span>
          }
          label={TITLE.label}
          onChange={(v) => onChangeHandlerForEvent(TITLE.value, v)}
          required
          value={formState[TITLE.value]}
        />

        <TextInput
          color="primary"
          size="full"
          error={!!formErrors[URL.value]}
          helperText={
            <>
              {!!formErrors[URL.value] && (
                <div className={classes.helperText}>
                  {formErrors[URL.value]}
                </div>
              )}
              <span className={classes.helperText}>
                Inserir o subdiretório da URL, ou seja as páginas internas do
                seu site: {initialURLValueForBrand(brand)}
                <strong>concursos-cursos-carreiras-juridicas</strong>
              </span>
            </>
          }
          label="URL da página promocional"
          onChange={(ev) => onChangeHandlerForEvent(URL.value, ev)}
          required
          value={formState[URL.value]}
        />
      </div>

      <ContentTitle title="Conteúdo HTML" size="small" />
      <TextEditor
        initialValue={formState[CONTENT.value]}
        onChange={(htmlContent) => onChangeHandler(CONTENT.value, htmlContent)}
      />

      <ContentTitle title="Publicar" size="small" />

      <div className={classes.datesContainer}>
        <div className={classes.datePickerContainers}>
          <DatePickerSaraiva
            error={!!formErrors[START_DATE.value]}
            helperText={
              <span className={classes.helperText}>
                {formErrors[START_DATE.value]}
              </span>
            }
            label="Data de início da página"
            size="sm"
            value={formState[START_DATE.value]}
            onChange={(selectedDate) =>
              onChangeHandler(START_DATE.value, selectedDate, onlyDatePipe)
            }
          />
        </div>

        <div className={classes.datePickerContainers}>
          <DatePickerSaraiva
            error={!!formErrors[END_DATE.value]}
            helperText={
              <span className={classes.helperText}>
                {formErrors[END_DATE.value]}
              </span>
            }
            label="Data de fim da página"
            size="sm"
            value={formState[END_DATE.value]}
            onChange={(selectedDate) =>
              onChangeHandler(END_DATE.value, selectedDate, onlyDatePipe)
            }
          />
        </div>
      </div>

      <TextInput
        className={classes.marginTop24}
        color="primary"
        error={!!formErrors[REDIRECT_URL.value]}
        helperText={
          <>
            {!!formErrors[REDIRECT_URL.value] && (
              <span className={classes.helperText}>
                {formErrors[REDIRECT_URL.value]}
              </span>
            )}
            <ul style={{ padding: 0, margin: 0 }}>
              <li>
                Preencha para qual link os usuários serão redirecionados quando
                a promoção finalizar.
              </li>
              <li>
                Se aplicável, inserir o subdiretório da URL, ou seja as páginas
                internas do seu site: {initialURLValueForBrand(brand)}
                <strong>concursos-cursos-carreiras-juridicas</strong>
              </li>
            </ul>
          </>
        }
        size="full"
        label={REDIRECT_URL.label}
        onChange={(v) => onChangeHandlerForEvent(REDIRECT_URL.value, v)}
        value={formState[REDIRECT_URL.value]}
      />

      <div className={classes.marginTop24}>
        <SelectSaraiva
          error={formErrors[STATUS.value]}
          helperText={
            <span className={classes.helperText}>
              {formErrors[STATUS.value]}
            </span>
          }
          label="Status de publicação"
          onChange={(v) => onChangeHandlerForEvent(STATUS.value, v)}
          value={formState[STATUS.value]}
          size="full"
        >
          <MenuItem value="">
            <em>Nenhum status selecionado</em>
          </MenuItem>

          <MenuItem value={PUBLISHING_STATUS.ACTIVE.value}>
            {PUBLISHING_STATUS.ACTIVE.label}
          </MenuItem>

          <MenuItem value={PUBLISHING_STATUS.INACTIVE.value}>
            {PUBLISHING_STATUS.INACTIVE.label}
          </MenuItem>
        </SelectSaraiva>
      </div>
    </form>
  )
}

PromotionalPageForm.propTypes = {
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
  formErrors: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  brand: PropTypes.string.isRequired,
  originalTitle: PropTypes.string
}

PromotionalPageForm.defaultProps = {
  originalTitle: ''
}
