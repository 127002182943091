import Joi from 'joi'
import { PROMOTIONAL_PAGES_FIELDS as F } from '../promotional-page-fields'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors,
  customAfterDateValidator
} from '../../../../helpers/validation'

const schema = Joi.object({
  [F.TITLE.value]: Joi.string().required(),
  [F.URL.value]: Joi.string()
    .regex(/^(?:https|http):\/\/www\.lfg\.com\.br\/[\w-]+$/)
    .required(),
  [F.STATUS.value]: Joi.string().required(),
  [F.REDIRECT_URL.value]: Joi.string()
    .required()
    .regex(/^(?:https|http):\/\/www\.lfg\.com\.br\/[\w-]*$/),
  [F.START_DATE.value]: Joi.any()
    .when(Joi.ref('$action'), {
      is: 'create',
      then: Joi.date().min(Joi.ref('$now')).required(),
      otherwise: Joi.date().required()
    })
    .messages({
      'date.min': 'Não pode ser uma data retroativa'
    }),
  [F.END_DATE.value]: Joi.date()
    .when(Joi.ref('$action'), {
      is: 'create',
      then: Joi.date()
        .custom(customAfterDateValidator(F.START_DATE.value, F.END_DATE.value))
        .required(),
      otherwise: Joi.date().min(Joi.ref(F.START_DATE.value)).required()
    })
    .messages({
      'date.min': 'Deve ser posterior a data de início',
      'custom.afterDate': 'Deve ser posterior a data de início'
    })
})

const context = (action) => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  return { action, now }
}

/**
 *
 * @param {*} formValues
 * @param {"edit" | "create"} action
 * @returns
 */
export const promotionalPageValidation = (formValues, action = 'edit') => {
  return validateAndGenerateErrors(formValues, schema, context(action))
}

/**
 *
 * @param {*} field
 * @param {*} value
 * @param {*} formValues
 * @param {"edit" | "create"} action
 * @returns
 */
export const promotionalPageFieldValidation = (
  field,
  value,
  formValues,
  action = 'edit'
) => {
  return validateSingleFieldAndGenerateErrors(
    field,
    value,
    formValues,
    schema,
    context(action)
  )
}
