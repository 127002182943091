import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    marginBottom: 24,
    marginRight: '8vw',
    textAlign: 'right'
  },
  icon: {
    marginRight: 24,
    verticalAlign: 'bottom'
  },
  divImageUploadPreview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentUpdate: {
    border: '1px solid rgb(230,230,230)',
    marginBottom: 40,
    borderRadius: 8,
    background: '#FFF',
    padding: 24,
    '& h3': {
      fontWeight: '500'
    },
    '& .MuiFormControl-root': {
      marginBottom: 20
    }
  },
  contentTitle: {
    letterSpacing: '0.15px',
    fontWeight: '500',
    fontSize: 20,
    marginBottom: 16
  },
  contentDisplayLeft: {
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 90,
    alignItems: 'center',
    display: 'flex',
    '& .MuiGrid-justify-content-xs-space-around': {
      justifyContent: 'space-between',
      marginRight: 24,
      width: 300,
      '& .MuiFormControl-root': {
        width: '100% !important'
      }
    }
  },
  boxImage: {
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 350,
    marginRight: 5,
    '& > span': {
      fontSize: 12,
      marginBottom: 10
    }
  },
  boxDelete: {
    '& > .MuiButton-root': {
      padding: 0,
      color: '#624C92'
    },
    '& > .MuiButton-root:hover': {
      background: 'transparent !important'
    }
  },
  footerUpdate: {
    justifyContent: 'flex-end',
    marginTop: 20,
    alignItems: 'center',
    display: 'flex',
    gap: 10
  },
  footerUpdateDelete: {
    color: '#624C92',
    '& .MuiButton-root:hover': {
      background: 'transparent !important'
    }
  },
  button: {
    marginTop: 24,
    marginBottom: 24
  },
  text: {
    marginTop: 37,
    color: 'var(--text-secondary, rgba(0, 0, 0, 0.54))',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '160%',
    letterSpacing: '0.15px'
  },
  bannerPageTitle: {
    marginBottom: 47
  },

  alertBanner: {
    color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.15px'
  },

  alerts: {
    position: 'fixed',
    zIndex: 99999,
    top: '75px',
    right: '15px'
  }
})

export default useStyles
