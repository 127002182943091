import { getSessionToken } from '../../../auth'
import { CardComplementaryContentAdapter } from '../adapters/card-complementary-content'
import httpClient from '../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../utils/response-handlers'

const getComplementaryContents = async (materialId) => {
  const path = `/contents/api/v1/complementary_content/?material_id=${materialId}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)
  const responseJson = await getResquestResponseHandler(response)

  return CardComplementaryContentAdapter.listInbound(responseJson)
}

export { getComplementaryContents }
