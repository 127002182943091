import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const PromotionalContext = createContext()

function PromotionalProvider({ children, type }) {
  const [typeBanner, setTypeBanner] = useState(type || 'none')

  return (
    <PromotionalContext.Provider
      value={{
        typeBanner,
        setTypeBanner
      }}
    >
      {children}
    </PromotionalContext.Provider>
  )
}

PromotionalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired
}

export { PromotionalContext, PromotionalProvider }
