/**
 * lista de campos para as paginas promocionais da listagem
 */
export const BANNER_PAGES_LIST_ITEM_FIELDS = Object.freeze({
  KEY: { value: 'key', label: 'identificador' },
  TITLE: { value: 'title', label: 'Título do Banner Rotativo' },
  URL: [
    { value: 'urls', label: 'Url do Banner' },
    { value: 'urls', label: 'Url do Banner' },
    { value: 'urls', label: 'Url do Banner' },
    { value: 'urls', label: 'Url do Banner' }
  ],
  IMG_WEB: { value: 'imageWeb', label: 'Imagem em Desktop' },
  IMG_RECURSIVE: [
    { value: 'imageRecursive', label: 'Imagem em Mobile' },
    { value: 'imageRecursive', label: 'Imagem em Mobile' },
    { value: 'imageRecursive', label: 'Imagem em Mobile' },
    { value: 'imageRecursive', label: 'Imagem em Mobile' }
  ],
  SESSION: { value: 'session', label: 'Sessão' },
  ORDER: [
    { value: 'orders', label: 'Ordem' },
    { value: 'orders', label: 'Ordem' },
    { value: 'orders', label: 'Ordem' },
    { value: 'orders', label: 'Ordem' }
  ],
  START_DATE: { value: 'startDate', label: 'Data de início do banner' },
  END_DATE: { value: 'endDate', label: 'Data de fim do banner' },
  STATUS: { value: 'status', label: 'Status do Banner' }
})

export const BANNER_CONECTA_LIST_ITEM_FIELDS = Object.freeze({
  TITLE: { value: 'title', label: 'Título do Banner Rotativo' },
  URL: { value: 'urls', label: 'Url do Banner' },
  IMG_WEB: { value: 'imageWeb', label: 'Imagem em Desktop' },
  IMG_TABLET: { value: 'imageTablet', label: 'Imagem em Tablet' },
  IMG_MOBILE: { value: 'imageMobile', label: 'Imagem em Mobile' }
})
