/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react'
import { Snackbar } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import { snackbarStyle } from '../../layouts/styles'

import ConnectionsList from './ConnectionsList'
import { GeneralContext } from '../../contexts/GeneralContext'
import { ConnectionsContext } from '../../contexts/ConnectionsContext'
import ProductSelect from '../../components/ProductSelect'
import useStyles from './styles.js'
import Messages from '../../helpers/messages'
import AvaConnection from '../../services/avaConnection'

function Connections() {
  const classes = useStyles()

  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    setLoading,
    setProduct
  } = useContext(GeneralContext)

  const { setConnections } = useContext(ConnectionsContext)
  const snackbarStyles = snackbarStyle()

  async function fetchConnections(product) {
    setProduct(product)

    if (!product.value) {
      return
    }

    try {
      setLoading(true)

      const response = await AvaConnection.listAvaConnection(product.token)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()
      setConnections(result.data)
      setLoading(false)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setProduct({})
      setErrorMessage(Messages.fetchConnections.error)
      setLoading(false)
    }
  }

  return (
    <>
      <Snackbar
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
        autoHideDuration={5000}
        classes={() => snackbarStyles}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        severity="warning"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
        autoHideDuration={5000}
        classes={() => snackbarStyles}
      >
        {errorMessage}
      </Snackbar>

      <div className={classes.root}>
        <Typography variant="h3" className={classes.titleH3}>
          Gerenciar LTI
        </Typography>
        <ProductSelect onChange={fetchConnections} />
        <ConnectionsList />
      </div>
    </>
  )
}

export default Connections
