import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from '@equipe-ninja/saraiva-ui'
import Divider from '@material-ui/core/Divider'
import DownloadIcon from '@material-ui/icons/GetApp'
import useStyles from './styles'
import { VOUCHER_LIST_ITEM_FIELDS } from '../../../../domain/vouchers'
import {
  exportVouchers,
  getVouchersByCompany
} from '../../../../services/vouchers'
import { useDownloadFile } from '../../../../hooks/use-download-file'
import { GeneralContext } from '../../../../contexts/GeneralContext'

const {
  CAMPAIGN_NAME,
  PREFIX,
  START_DATE,
  END_DATE,
  DISCOUNT_VALUE,
  STATUS,
  TOTAL_AS_TEXT,
  VOUCHER_TYPE,
  ID
} = VOUCHER_LIST_ITEM_FIELDS

const headerRow = [
  {
    name: 'Nome da campanha',
    order: true,
    code: CAMPAIGN_NAME
  },
  {
    name: 'Prefixo',
    order: true,
    code: PREFIX
  },
  {
    name: 'Ativados',
    order: true,
    code: VOUCHER_TYPE
  },
  {
    name: 'Desconto',
    order: true,
    code: DISCOUNT_VALUE
  },
  {
    name: 'Data início',
    order: true,
    code: START_DATE
  },
  {
    name: 'Data fim',
    order: true,
    code: END_DATE
  },
  {
    name: 'Tipo de voucher',
    order: true,
    code: TOTAL_AS_TEXT
  },
  {
    name: 'Status do voucher',
    order: true,
    code: STATUS
  }
]

export const VoucherDetailsVoucherTable = (props) => {
  const { detailsData } = props
  const classes = useStyles()
  const { downloadFile } = useDownloadFile()
  const { setErrorMessage } = useContext(GeneralContext)
  const [listVouchers, setListVouchers] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [uiControls, setUiControls] = useState({
    isExecutingTask: false
  })

  const isExportActionDisabled = () => {
    return uiControls.isExecutingTask || selectedRows.length === 0
  }

  useEffect(() => {
    getVouchersByCompany(detailsData.name)
      .then((dataFromService) => {
        setListVouchers(dataFromService)
      })
      .catch(() => {})
      .finally(() => {
        setUiControls((prev) => ({ ...prev, isFetching: false }))
      })
  }, [detailsData])

  const exportSelectedVouchers = () => {
    if (uiControls.isExecutingTask) return

    setUiControls((prev) => ({ ...prev, isExecutingTask: true }))

    const vouchersIds = selectedRows.map((e) => e[ID])

    exportVouchers(vouchersIds)
      .then((data) => {
        downloadFile(
          data,
          `vouchers_${new Date()
            .toLocaleString('pt-br')
            .replaceAll(/[/ :]/g, '_')}.csv`
        )
      })
      .catch(() => {
        setErrorMessage('Não foi possível exportar os dados selecionados')
      })
      .finally(() => {
        setUiControls((prev) => ({ ...prev, isExecutingTask: false }))
      })
  }

  return (
    <>
      <div className={classes.formActionsContainer}>
        <Button
          disabled={isExportActionDisabled()}
          className={classes.marginLeftSmall}
          startIcon={<DownloadIcon />}
          color="primary"
          variant="contained"
          onClick={exportSelectedVouchers}
        >
          EXPORTAR
        </Button>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.vouchersTable}>
        <Table
          displayRowCheckbox
          selectedRows={selectedRows}
          onCheckRows={(rows) => setSelectedRows(rows)}
          cells={headerRow}
          rows={listVouchers}
        />
      </div>
    </>
  )
}

VoucherDetailsVoucherTable.propTypes = {
  detailsData: PropTypes.objectOf(PropTypes.any).isRequired
}
