import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@equipe-ninja/saraiva-ui'
import { Typography, Button as MuiButton } from '@material-ui/core'
import { CloudUpload, Delete } from '@material-ui/icons'
import { useStyles } from './styles'

export const UploadFile = ({
  onUpload,
  className,
  sequence,
  onDelete,
  label,
  file,
  icon,
  error,
  helperText
}) => {
  const classes = useStyles()

  return (
    <div className={className}>
      <Typography className={classes.cardSubtitle} variant="h6">
        Upload
      </Typography>

      <p className={`${classes.fieldText}`}>
        Faça upload de arquivos nos formatos PDF, xlsx ou txt.
      </p>

      {!file ? (
        <div className={classes.divFileButton}>
          <label htmlFor={`contained-button-file-${sequence}`}>
            <MuiButton
              className={error ? classes.buttonError : ''}
              color="secondary"
              variant="outlined"
              component="span"
              startIcon={icon}
            >
              <input
                type="file"
                accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
                style={{ position: 'absolute', display: 'none' }}
                id={`contained-button-file-${sequence}`}
                onChange={(e) => {
                  onUpload(e.target.files[0])
                }}
              />
              {label}
            </MuiButton>
          </label>
          {error && helperText && (
            <Typography className={classes.colorError}>{helperText}</Typography>
          )}
        </div>
      ) : (
        <div className={classes.divFileUploaded}>
          <span className={classes.spanFilePath}>{file.name}</span>
          <Button
            fullWidth={false}
            className={classes.colorError}
            onClick={onDelete}
            startIcon={<Delete />}
          >
            EXCLUIR ARQUIVO
          </Button>
        </div>
      )}
    </div>
  )
}

UploadFile.defaultProps = {
  icon: <CloudUpload />,
  label: 'faça upload do arquivo*',
  file: null,
  error: false,
  helperText: ''
}

UploadFile.propTypes = {
  onUpload: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  sequence: PropTypes.number.isRequired,
  label: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  file: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.element,
  error: PropTypes.bool,
  helperText: PropTypes.string
}
