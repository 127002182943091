import React from 'react'

import { Box, Divider, Typography } from '@material-ui/core'
import { CardExtraContent } from '@equipe-ninja/saraiva-ui'
import { ViewQuilt, Spellcheck } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import useStyles from './styles'

export default function ManageContent() {
  const classes = useStyles()

  const history = useHistory()

  return (
    <Box>
      <Typography variant="h4" className={classes.title}>
        Gerenciar conteúdos
        <Divider className={classes.divider} />
      </Typography>

      <Typography variant="body2" className={classes.subTitle}>
        Banco de conteúdos da Saraiva Educação
      </Typography>

      <div className={classes.cardContent}>
        <CardExtraContent
          onClick={() => history.push('/contents')}
          copyIcon={false}
          title="Disciplinas H5P"
          hoverTitle="Acessar"
          icon={<ViewQuilt style={{ fontSize: 100 }} />}
        />

        <CardExtraContent
          onClick={() => history.push('/question-database')}
          copyIcon={false}
          title="Banco de questões"
          hoverTitle="Acessar"
          icon={<Spellcheck style={{ fontSize: 100 }} />}
        />
      </div>
    </Box>
  )
}
