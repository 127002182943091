import React, { useState, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import _ from 'lodash'

import { Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { FormComplementaryContent } from './FormComplementaryContent'
import { GeneralContext } from '../../../../../../contexts/GeneralContext'
import { contentMaterialValidation } from '../../../../../../domain/complementary-material/complementary-materials-validation'
import { CARD_COMPLEMENTARY_CONTENT_FIELDS } from '../../../../../../domain/complementary-material/card-complementary-content-fields'
import {
  createComplementaryContent,
  updateComplementaryContent
} from '../../../../../../services/complementary-material/use-cases/post-complementary-content'
import { deleteComplementaryContent } from '../../../../../../services/complementary-material/use-cases/delete-complementary-content'

const { NAME, ORDER, TYPE, PATH } = CARD_COMPLEMENTARY_CONTENT_FIELDS

const emptyFormState = {
  [NAME]: '',
  [ORDER]: null,
  [TYPE]: '',
  [PATH]: null
}

/**
 *
 * @param {{
 *  isOpen: boolean;
 *  handleClose: () => void;
 *  handleOpen: () => void;
 *  mode: 'create' | 'edit';
 *  contentData?: {
 *    id: number;
 *    name: string;
 *    order: number;
 *    type: string;
 *    path: string;
 *    material_id: number;
 *  }
 *  fetchMaterialContents: () => void;
 * }} props
 * @returns {import('react').FC}
 */
export const DialogContentManagement = ({
  isOpen,
  handleClose,
  mode = 'create',
  handleOpen,
  contentData,
  fetchMaterialContents
}) => {
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const initialFormState = useMemo(() => {
    return contentData
      ? {
          ...contentData,
          path: {
            name: contentData.path,
            fileName: contentData.path,
            type: contentData.type
          }
        }
      : emptyFormState
  }, [contentData])
  const { materialId } = useParams()
  const [formState, setFormState] = useState(initialFormState)
  const [formErrors, setFormErrors] = useState(null)
  const [changeUploadFile, setChangeUploadFile] = useState(false)
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false)
  const [isDeleteContentDialogOpen, setIsDeleteContentDialogOpen] =
    useState(false)

  const openDiscardChangesDialog = () => setIsDiscardChangesDialogOpen(true)
  const closeDiscardChangesDialog = () => setIsDiscardChangesDialogOpen(false)

  const openDeleteContentDialog = () => setIsDeleteContentDialogOpen(true)
  const closeDeleteContentDialog = () => setIsDeleteContentDialogOpen(false)

  const formStateHasChanged = !_.isEqual(initialFormState, formState)

  const canSaveChanges = mode === 'create' || formStateHasChanged

  useEffect(() => {
    setFormState(initialFormState)
  }, [initialFormState])

  const onChangeFormState = (field, value) => {
    if (field === PATH && value) {
      // validação para salvar alteração de arquivo no bucket ao salvar conteudo
      setChangeUploadFile(true)
    }
    const newFormState = { ...formState }
    newFormState[field] = value
    setFormState(newFormState)
  }

  const clearFormStateAndErrors = () => {
    setFormState(initialFormState)
    setFormErrors(null)
  }

  const clearFormAndCloseDialog = () => {
    clearFormStateAndErrors()
    handleClose()
  }

  const handleCreateComplementaryContent = () => {
    createComplementaryContent({
      ...formState,
      material_id: materialId
    })
      .then(() => {
        setSuccessMessage('Conteúdos adicionados com sucesso.')
        fetchMaterialContents()
      })
      .catch(() => {
        setErrorMessage('Erro ao adicionar conteúdo complementar.')
      })
  }

  const editComplementaryContent = async () => {
    try {
      await updateComplementaryContent({
        ...formState,
        material_id: materialId,
        changeFile: changeUploadFile
      })
      setSuccessMessage('Conteúdos alterados com sucesso.')
      fetchMaterialContents()
    } catch (error) {
      setErrorMessage('Erro ao alterar conteúdo complementar.')
    }
  }

  const validateContentMaterial = () => {
    const validationResult = contentMaterialValidation(formState)

    if (!validationResult.isValid) {
      setFormErrors(validationResult.errors)
      return false
    }

    return true
  }

  const handleConfirmChanges = () => {
    if (!canSaveChanges) return

    const isValid = validateContentMaterial()

    if (!isValid) return

    if (mode === 'create') {
      handleCreateComplementaryContent()
    } else {
      editComplementaryContent()
    }

    clearFormAndCloseDialog()
  }

  const handleCloseDialog = () => {
    if (formStateHasChanged) {
      handleClose()
      openDiscardChangesDialog()
      return
    }

    clearFormAndCloseDialog()
  }

  const handleDeleteContent = async () => {
    try {
      await deleteComplementaryContent(contentData.id)
      setSuccessMessage(`Conteúdo ${contentData.id} excluído com sucesso.`)
      fetchMaterialContents()
      closeDeleteContentDialog()
    } catch (error) {
      setErrorMessage('Erro ao excluir conteúdo.')
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        title={mode === 'create' ? 'Adicionar conteúdo' : 'Alterar conteúdo'}
        size="lg"
        primaryButton="SALVAR"
        secondaryButton="CANCELAR"
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmChanges}
        isDisabled={!canSaveChanges}
        handleCheck={() => null}
      >
        <FormComplementaryContent
          formState={formState}
          formErrors={formErrors || {}}
          onChangeFormState={onChangeFormState}
          onDeleteContentButtonClick={() => {
            handleClose()
            openDeleteContentDialog()
          }}
          showDeleteContentButton={mode === 'edit'}
        />
      </Dialog>

      <Dialog
        title="Descartar alterações"
        primaryButton="DESCARTAR"
        secondaryButton="CANCELAR"
        open={isDiscardChangesDialogOpen}
        handleConfirm={() => {
          clearFormStateAndErrors()
          closeDiscardChangesDialog()
        }}
        handleClose={() => {
          closeDiscardChangesDialog()
          handleOpen()
        }}
      >
        <Typography>
          Você está saindo sem salvar as alterações feitas e perderá todo o seu
          progresso.
        </Typography>
      </Dialog>

      <Dialog
        title="Excluir conteúdo"
        secondaryButton="CANCELAR"
        primaryButton="CONFIRMAR"
        size="md"
        handleClose={() => {
          closeDeleteContentDialog()
          handleOpen()
        }}
        handleConfirm={handleDeleteContent}
        open={isDeleteContentDialogOpen}
        label="Sim, tenho certeza!"
        alert
      >
        Você tem certeza que deseja excluir este conteúdo do material? Após
        excluir ele não poderá mais ser acessado pelos alunos.
      </Dialog>
    </>
  )
}

DialogContentManagement.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']),
  contentData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  fetchMaterialContents: PropTypes.func.isRequired
}

DialogContentManagement.defaultProps = {
  mode: 'create',
  contentData: null
}
