import React, { useCallback, useEffect, useContext, useState } from 'react'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Tabs } from '@equipe-ninja/saraiva-ui'
import Typography from '@material-ui/core/Typography'
import { courseProductCategoryState } from '../../../atoms/course-product-category'
import { ContainerWithLoader } from '../../../components/ContainerWithLoader'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { SavingInfoWidget } from '../../../components/SavingInfoWidget'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { generalInfoValidation } from '../../../domain/product-course/business'
import { getCategories } from '../../../services/course-product'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { GeneralInfoForm } from './GeneralInfoForm'
import { CoursesTabsForm } from './CoursesTabsForm'
import useStyles from './styles'
import {
  getCourseOverview,
  updateCourseService
} from '../../../services/courses'
import {
  courseFetchingControls,
  ecommerceForm,
  courseOverviewRawData
} from '../../../atoms/course'
import {
  COURSE_OVERVIEW_RAW_DATA,
  COURSE_ECOMMERCE_FIELDS,
  ECOMMERCE_FORM_FIELDS
} from '../../../domain/courses/entities'

const { SERVICE } = COURSE_OVERVIEW_RAW_DATA
const { UPDATED_AT, NAME } = COURSE_ECOMMERCE_FIELDS

const ACTIVE_TAB = { GENERAL_INFO: 0, COURSE_TABS: 1 }

export const CourseProductEdit = () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(ACTIVE_TAB.GENERAL_INFO)
  const setCategories = useSetRecoilState(courseProductCategoryState)
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [rawData, setRawData] = useRecoilState(courseOverviewRawData)
  const [{ isLoading, isExecutingTask }, setControls] = useRecoilState(
    courseFetchingControls
  )
  const [{ values: formValues }, setForm] = useRecoilState(ecommerceForm)
  const { id, brand } = useParams()
  const classes = useStyles()

  const backButtonRoute = useCallback(() => {
    return generatePath(RoutesMapping.courseCreate, {
      brand,
      courseId: id
    })
  }, [brand, id])

  const navigateToEntry = useCallback(() => {
    history.push(backButtonRoute())
  }, [history, backButtonRoute])

  const fetchCategories = useCallback(() => {
    setCategories((prev) => ({ ...prev, isFetching: true }))
    getCategories()
      .then((data) => {
        setCategories((prev) => ({ ...prev, categories: data }))
      })
      .catch(() => {
        setErrorMessage('Falha ao buscar informações sobre categorias')
      })
      .finally(() => {
        setCategories((prev) => ({ ...prev, isFetching: false }))
      })
  }, [setCategories, setErrorMessage])

  const fetchDetails = useCallback(
    (courseId) => {
      setControls((prev) => ({ ...prev, isLoading: true }))
      getCourseOverview(courseId)
        .then((data) => {
          setRawData(data)
        })
        .catch((err) => {
          setErrorMessage(err.message)
        })
        .finally(() => {
          setControls((prev) => ({ ...prev, isLoading: false }))
        })
    },
    [setErrorMessage, setRawData, setControls]
  )

  const onNextSuccess = () => {
    setActiveTab(ACTIVE_TAB.COURSE_TABS)
  }

  const onPreviousSuccess = () => {
    setActiveTab(ACTIVE_TAB.GENERAL_INFO)
  }

  const onChangeTab = (changedTab) => {
    setActiveTab(changedTab)
  }

  const validateGeneralInfoAndSetErrors = () => {
    const { isValid: isGeneralInfoValid, errors } =
      generalInfoValidation(formValues)

    setForm((prev) => ({
      ...prev,
      errors: {
        ...prev.errors,
        ...errors
      }
    }))

    return { isGeneralInfoValid }
  }

  const persistCourseData = () => {
    if (isExecutingTask) return

    setControls((prev) => ({ ...prev, isExecutingTask: true }))

    updateCourseService(
      rawData?.[SERVICE]?.[ECOMMERCE_FORM_FIELDS.ID],
      formValues
    )
      .then(() => {
        setSuccessMessage('Cadastro no e-commerce atualizado com sucesso')

        navigateToEntry()
      })
      .catch(() => {
        setErrorMessage('Falha ao salvar os detalhes do curso')
      })
      .finally(() => {
        setControls((prev) => ({ ...prev, isExecutingTask: false }))
      })
  }

  const onSubmit = () => {
    const { isGeneralInfoValid } = validateGeneralInfoAndSetErrors()

    if (!isGeneralInfoValid) {
      setErrorMessage(
        'Houve falhas de validação nas informações gerais, verifique os campos em vermelho'
      )
      setActiveTab(ACTIVE_TAB.GENERAL_INFO)
      return
    }

    persistCourseData()
  }

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    fetchDetails(id)
  }, [fetchDetails, id])

  return (
    <ContentWithHeader
      backButtonText="Cadastrar no e-commerce"
      backButtonRoute={backButtonRoute()}
      className={classes.relativePosition}
    >
      {!isLoading && (
        <div style={{ position: 'absolute', top: '8px', right: '0' }}>
          <SavingInfoWidget
            isSaving={isExecutingTask}
            savedAt={rawData?.[SERVICE]?.[UPDATED_AT]}
          />
        </div>
      )}

      <ContainerWithLoader isLoading={isLoading}>
        <>
          <Typography variant="h4">{rawData?.[SERVICE]?.[NAME]}</Typography>

          <div className={classes.marginTopXL}>
            <Tabs
              color="primary"
              screenSize="desktop"
              selectedTabIndex={activeTab}
              showDivider
              variant="fullWidth"
              onChange={onChangeTab}
            >
              {[
                {
                  disabled: isExecutingTask,
                  label: 'informações gerais',
                  content: <GeneralInfoForm onSuccess={onNextSuccess} />
                },
                {
                  disabled: isExecutingTask,
                  label: 'abas do curso',
                  content: (
                    <CoursesTabsForm
                      onPreviousSuccess={onPreviousSuccess}
                      onFinalize={onSubmit}
                    />
                  )
                }
              ]}
            </Tabs>
          </div>
        </>
      </ContainerWithLoader>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}

CourseProductEdit.propTypes = {}

CourseProductEdit.defaultProps = {}
