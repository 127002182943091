import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  bannerPageTitle: {
    marginBottom: 47,
    color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
    fontFamily: 'Roboto',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px'
  },
  saveButton: {
    right: 0,
    position: 'absolute',
    marginRight: 24,
    marginBottom: 24
  },
  alerts: {
    position: 'fixed',
    zIndex: 99999,
    top: '75px',
    right: '15px'
  }
})

export default useStyles
