import axios from 'axios'
import httpClient from '../gateway/generalHttpRequest'
import { getSessionToken } from '../auth'

const ContentConnection = {
  async getContent(id) {
    const path = id
      ? `contents/api/v1/modules/${id}`
      : `contents/api/v1/modules`

    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, headers)
    return response
  },

  async getContentByClientId(id) {
    const path = id.clientId
      ? `contents/api/v1/lti/get_client_module_contents?client_id=${id.clientId}&app_token=${id.appToken}`
      : `contents/api/v1/modules/${id}`

    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, headers)
    return response
  },

  async deleteFolder(id) {
    const path = `contents/api/v1/modules/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, headers)
    return response
  },

  async deleteFile(id) {
    const path = `contents/api/v1/contents/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, headers)
    return response
  },

  async createFolder(payload) {
    const path = 'contents/api/v1/modules'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    payload.app_token = process.env.REACT_APP_DONS_TOKEN
    const response = httpClient.post(path, payload, headers)
    return response
  },

  async createFile(payload) {
    const URL = `${
      process.env.REACT_APP_FUSION_ORIGIN
    }/${'contents/api/v1/contents'}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await axios.post(URL, payload, headers)
    return response
  },

  async editFolder(payload, id) {
    const path = `contents/api/v1/modules/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    payload.app_token = process.env.REACT_APP_DONS_TOKEN
    const response = httpClient.patch(path, payload, headers)
    return response
  },

  async editFile(payload, id) {
    const path = `contents/api/v1/contents/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    payload.app_token = process.env.REACT_APP_DONS_TOKEN
    const response = httpClient.patch(path, payload, headers)
    return response
  },

  async getPreview() {
    const path = 'contents/api/v1/contents'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, headers)
    return response
  },

  async updatedFile(payload, id) {
    const { REACT_APP_FUSION_ORIGIN: baseUrl } = process.env
    const path = `${baseUrl}/contents/api/v1/contents/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = axios.put(path, payload, { headers })
    return response
  },

  async getBanner() {
    const path = `contents/api/v1/campaign_upload`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async createBanner(campaingTitle, url, imgWeb, imgTablet, imgMobile) {
    const { REACT_APP_FUSION_ORIGIN: baseUrl } = process.env
    const path = `${baseUrl}/contents/api/v1/campaign_upload`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    const payload = new FormData()
    payload.append('campaign_name', campaingTitle)
    payload.append('redirection_url', url)
    payload.append('web_image', imgWeb)
    payload.append('tablet_image', imgTablet)
    payload.append('mobile_image', imgMobile)

    const response = await axios.post(path, payload, { headers })

    return response
  },

  async updateBanner(
    campaignId,
    campaingTitle,
    url,
    imgWeb,
    imgTablet,
    imgMobile
  ) {
    const { REACT_APP_FUSION_ORIGIN: baseUrl } = process.env
    const path = `${baseUrl}/contents/api/v1/campaign_upload/${campaignId}`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    const payload = new FormData()
    payload.append('campaign_name', campaingTitle)
    payload.append('redirection_url', url)
    payload.append('web_image', imgWeb)
    payload.append('tablet_image', imgTablet)
    payload.append('mobile_image', imgMobile)

    const response = await axios.put(path, payload, { headers })

    return response
  },

  async deleteBanner(id) {
    const path = `contents/api/v1/campaign_upload/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, null, headers)
    return response
  }
}

export default ContentConnection
