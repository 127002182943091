import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 72,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  addConnectionBtn: {
    margin: '24px 0 20px 0',
    height: '42px'
  },
  text: {
    marginTop: '40px',
    marginBottom: '24px',
    color: theme.palette.text.primary
  },
  textH4: {
    fontSize: '34px'
  },
  textH3: {
    marginTop: '22px',
    fontSize: '48px'
  },
  textH5: {
    fontSize: '24px'
  },
  divider: {
    margin: '25px 0px 24px 0px'
  },
  searchWrapper: {
    marginBottom: 24,
  },
  tableWrapper: {
    marginTop: 40,
  },
  paper: {
    width: 277,
    position: 'absolute',
    borderRadius: 4,
    padding: '10px 0px 10px 12px',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main,

    },
    zIndex: 1000,
    marginTop: -16
  },
  empty: {
    display: 'none'
  }
}))

export default useStyles
