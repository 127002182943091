import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { DatePickerSaraiva } from '@equipe-ninja/saraiva-ui/core/date_picker/DatePicker'
import { Typography } from '@material-ui/core'
import useStyles from './styles'
import ticketService from '../../../services/ticketService'
import messages from '../../../helpers/messages'

const EditDate = ({
  open,
  handleClose,
  startDate,
  endDate,
  listIds,
  ticketType,
  setLoading
}) => {
  const classes = useStyles()
  function toDate(dateStr) {
    const parts = dateStr.split('/')
    return new Date(parts[2], parts[1] - 1, parts[0])
  }

  function dateToDB(dateStr) {
    const date = new Date(dateStr)
    const mnth = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const newDate = [date.getFullYear(), mnth, day].join('-')
    return newDate
  }

  function dialogMessage() {
    if (ticketType === 'Múltiplos usuários por voucher' || listIds.length > 1) {
      return 'Alterar a data de vários usuários?'
    }
    return 'Alterar a data de 1 usuário?'
  }

  const [initialDate, setInitialDate] = useState(toDate(startDate))
  const [initialDateError, setInitialDateError] = useState(false)
  const [initialDateErrorMessage, setInitialDateErrorMessage] = useState('')
  const [finalDate, setFinalDate] = useState(toDate(endDate))
  const [finalDateError, setFinalDateError] = useState(false)
  const [finalDateErrorMessage, setFinalDateErrorMessage] = useState('')
  const [paramStartDate, setParamStartDate] = useState(dateToDB(initialDate))
  const [paramEndDate, setParamEndDate] = useState(dateToDB(finalDate))
  const [verifySaveButton, setVerifySaveButton] = useState(true)

  const history = useHistory()

  async function updateDates() {
    setLoading(true)
    try {
      const payload = {
        list_ids: listIds,
        date_params: { start_date: paramStartDate, end_date: paramEndDate }
      }
      const response = await ticketService.update_date(payload)

      if (response.status === 204) {
        handleClose()
        history.go(0)
      }
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  const handleInitialDate = (str) => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)

    if (str < dateNow) {
      setInitialDate(null)
      setInitialDateError(true)
      setInitialDateErrorMessage(messages.dateTicketEdit.retroactive)
      return
    }
    setInitialDateErrorMessage('')
    setInitialDateError(false)
    setVerifySaveButton(false)
    setInitialDate(str)
    setParamStartDate(dateToDB(str))
  }

  const handleFinalDate = (str) => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)

    if (str < dateNow) {
      setFinalDate(null)
      setFinalDateError(true)
      setVerifySaveButton(true)
      setFinalDateErrorMessage(messages.dateTicketEdit.retroactive)
      return
    }
    if (str < initialDate) {
      setFinalDate(null)
      setFinalDateError(true)
      setVerifySaveButton(true)
      setFinalDateErrorMessage(messages.dateTicketEdit.beforeInitialDate)
      return
    }
    setFinalDateError(false)
    setFinalDateErrorMessage('')
    setVerifySaveButton(false)
    setFinalDate(str)
    setParamEndDate(dateToDB(str))
  }

  return (
    <Dialog
      open={open}
      title="Alterar Data"
      secondaryButton="cancelar"
      primaryButton="salvar"
      size="md"
      handleConfirm={updateDates}
      handleClose={handleClose}
      isDisabled={verifySaveButton}
    >
      <div className={classes.discription}>
        <Typography color="textPrimary"> {dialogMessage()} </Typography>
      </div>

      <div className={classes.datePickers}>
        <DatePickerSaraiva
          label="Data de início"
          error={initialDateError}
          helperText={initialDateErrorMessage}
          value={initialDate}
          onChange={handleInitialDate}
          size="xs"
        />
        <DatePickerSaraiva
          label="Data de fim"
          error={finalDateError}
          helperText={finalDateErrorMessage}
          value={finalDate}
          onChange={handleFinalDate}
          size="xs"
        />
      </div>
    </Dialog>
  )
}

EditDate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  listIds: PropTypes.array.isRequired,
  ticketType: PropTypes.string.isRequired,
  setLoading: PropTypes.bool.isRequired
}

export default EditDate
