export const BANNER_STATUS = Object.freeze({
  ACTIVE: {
    label: 'Em exibição no site',
    value: 'ativo'
  },
  INACTIVE: {
    label: 'Inativo',
    value: 'inativo'
  }
})
