import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../../utils/response-handlers'
import { BannerDetailsAdapter } from '../adapters/banner-details-adapter'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar uma paginas promocional
 * @returns Promise com a resposta ou rejeita com erro
 */
const getBanner = (id) => {
  return new Promise((resolve, reject) => {
    const path = `markets/api/v1/banners/${id}`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((json) => {
        resolve(BannerDetailsAdapter.inbound(json.data))
      })
      .catch((error) => reject(error))
  })
}

export { getBanner }
