import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { Typography, Divider } from '@material-ui/core'
import { Table, Button, Tabs } from '@equipe-ninja/saraiva-ui'
import { debounce } from 'lodash'
import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { COURSE_LIST_ITEM_FIELDS } from '../../../domain/courses'
import { GeneralContext } from '../../../contexts/GeneralContext'
import useStyles from '../styles'
import { ContainerWithLoader } from '../../../components/ContainerWithLoader'
import { ModalNewCourses } from './components/ModalNewCourses'
import { getCoursesList } from '../../../services/courses'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { SubjectsTabContent } from './components/SubjectsTabContent'
import { useQueryParams } from '../../../hooks/use-query-params'

const {
  KEY,
  COURSE_NAME,
  URL,
  SKU,
  CHANGE_DATE,
  SALE_START_DATE,
  COURSE_PRICE,
  PUBLICATION_STATE
} = COURSE_LIST_ITEM_FIELDS

export const COURSES_LIST_TABS_LABELS_ENUM = Object.freeze({
  COURSES: 'cursos',
  SUBJECTS: 'disciplinas'
})

const coursesListTabsMap = new Map()
coursesListTabsMap.set(COURSES_LIST_TABS_LABELS_ENUM.COURSES, 0)
coursesListTabsMap.set(COURSES_LIST_TABS_LABELS_ENUM.SUBJECTS, 1)

export function CourseList() {
  const classes = useStyles()
  const [coursesList, setCoursesList] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const { setErrorMessage } = useContext(GeneralContext)
  const [openModalNewCourses, setOpenModalNewCourses] = useState(false)
  const history = useHistory()

  const queryParams = useQueryParams()
  const tab = queryParams.get('tab')

  const handleOpenModal = () => {
    setOpenModalNewCourses(!openModalNewCourses)
  }

  const headersCells = () => {
    return [
      { name: COURSE_NAME.label, order: true, code: COURSE_NAME.value },
      { name: SKU.label, order: true, code: SKU.value },
      { name: URL.label, order: true, code: URL.value },
      { name: CHANGE_DATE.label, order: true, code: CHANGE_DATE.value },
      {
        name: SALE_START_DATE.label,
        order: true,
        code: SALE_START_DATE.value
      },
      { name: COURSE_PRICE.label, order: true, code: COURSE_PRICE.value },
      {
        name: PUBLICATION_STATE.label,
        order: true,
        code: PUBLICATION_STATE.value
      }
    ]
  }

  const navigateToCourse = (courseId) => {
    history.push(
      generatePath(RoutesMapping.courseCreate, {
        brand: 'lfg',
        courseId
      })
    )
  }

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
    }, 800)
  ).current

  const handleSearchValue = (e) => {
    setSearchTerm(e.target.value)
    delayedQuery(e.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
  }

  const fetchData = useCallback(
    (search = '') => {
      const anotateListItemWithRedirect = (list) => {
        return list.map((item) => ({
          ...item,
          redirectTo: {
            fromColumn: COURSE_NAME.value,
            path: generatePath(RoutesMapping.courseCreate, {
              brand: 'lfg',
              courseId: item[KEY.value]
            })
          }
        }))
      }

      setIsFetching(true)
      getCoursesList(search)
        .then((list) => {
          setCoursesList(anotateListItemWithRedirect(list))
        })
        .catch(() => {
          setErrorMessage('Não foi possível buscar os cursos')
        })
        .finally(() => setIsFetching(false))
    },
    [setErrorMessage]
  )

  useEffect(() => {
    fetchData(searchValue)
  }, [fetchData, searchValue])

  const tabOptions = [
    {
      label: COURSES_LIST_TABS_LABELS_ENUM.COURSES,
      content: (
        <>
          <div className={`${classes.searchWrapper} ${classes.flex}`}>
            <SearchBar
              className={classes.search}
              value={searchTerm}
              onChange={handleSearchValue}
              placeholder="Buscar por curso"
              size="md"
              clearSearch={clearSearch}
              goBackSearch={clearSearch}
              onSubmit={(e) => {
                e.preventDefault()
              }}
            />
          </div>

          <ContainerWithLoader isLoading={isFetching}>
            <div className={classes.tableContainer}>
              <Table cells={headersCells()} rows={coursesList} />
            </div>
          </ContainerWithLoader>
        </>
      )
    },
    {
      label: COURSES_LIST_TABS_LABELS_ENUM.SUBJECTS,
      content: <SubjectsTabContent />
    }
  ]

  const selectedTabIndex = useMemo(() => {
    if (tab) {
      return coursesListTabsMap.get(tab)
    }

    return coursesListTabsMap.get(COURSES_LIST_TABS_LABELS_ENUM.COURSES)
  }, [tab])

  return (
    <>
      <Typography variant="h4" className={`${classes.text} ${classes.textH4}`}>
        LFG
      </Typography>

      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={handleOpenModal}
      >
        CADASTRAR CURSO
      </Button>

      <Divider className={classes.divider} />

      <Typography variant="h5" className={`${classes.text} ${classes.textH5}`}>
        Cadastrados
      </Typography>

      <ContainerWithLoader>
        <Tabs
          selectedTabIndex={selectedTabIndex}
          screenSize="desktop"
          color="primary"
          variant="scrollable"
          showDivider
        >
          {tabOptions}
        </Tabs>
      </ContainerWithLoader>

      <ModalNewCourses
        open={openModalNewCourses}
        onClose={handleOpenModal}
        onCreateCourse={navigateToCourse}
      />

      <SnackbarOutlet />
    </>
  )
}

CourseList.defaultProps = {
  brand: 'lfg'
}
