import Joi from 'joi'
import { CARD_COMPLEMENTARY_MATERIAL_FIELDS as MATERIAL } from './card-complementary-material-fields'
import { CARD_COMPLEMENTARY_CONTENT_FIELDS as CONTENT } from './card-complementary-content-fields'
import { validateAndGenerateErrors } from '../../helpers/validation'

const complementaryMaterialSchema = Joi.object({
  [MATERIAL.NAME]: Joi.string().required(),
  [MATERIAL.SHORT_DESCRIPTION]: Joi.string().required(),
  [MATERIAL.FULL_DESCRIPTION]: Joi.string().required(),
  [MATERIAL.ORDER]: Joi.number().required()
})

const contentMaterialSchema = Joi.object({
  [CONTENT.NAME]: Joi.string().required(),
  [CONTENT.ORDER]: Joi.number().required(),
  [CONTENT.TYPE]: Joi.string().required(),
  [CONTENT.PATH]: Joi.object().required()
})

export const complementaryMaterialValidation = (formState) => {
  return validateAndGenerateErrors(formState, complementaryMaterialSchema)
}

export const contentMaterialValidation = (formState) => {
  return validateAndGenerateErrors(formState, contentMaterialSchema)
}
