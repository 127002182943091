import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Paper, Typography } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'
import ContentConnection from '../../../../services/contentConnection'

import useStyles from './styles'

export function BannerHomeTest() {
  const [loading, setLoading] = useState(false)
  const [banner, setBanner] = useState('')

  const history = useHistory()
  const classes = useStyles()

  const getBanner = async () => {
    setLoading(true)
    try {
      const response = await ContentConnection.getBanner()

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setBanner(result[0])
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const editBanner = () => {
    history.push(`/banner-conecta/edit`)
  }

  const createBanner = () => {
    history.push(`/banner-conecta`)
  }

  useEffect(() => {
    if (loading === true) return
    getBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner])

  return (
    <>
      <Typography variant="h6" className={classes.text}>
        BANNER
      </Typography>
      {!banner ? (
        <>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={createBanner}
          >
            CRIAR CAMPANHA
          </Button>
        </>
      ) : (
        <Paper style={{ minWidth: 476, marginTop: 16 }}>
          <img
            src={banner.web_image}
            alt="Banner"
            style={{ minWidth: 444, height: 50, margin: '16px 16px 16px 16px' }}
          />
          <Typography variant="body1" style={{ marginLeft: 16 }}>
            {banner.campaign_name}
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: 'var(--text-secondary, rgba(0, 0, 0, 0.54))',
              margin: '12px 0px 39px 16px'
            }}
          >
            Sessão: Home
          </Typography>
          <Divider />
          <Button onClick={editBanner}>Editar</Button>
        </Paper>
      )}
    </>
  )
}
