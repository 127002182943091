import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '930px !important',
      maxHeight: '303px !important'
    }
  },

  dialogContent: {
    '&.MuiDialogContent-dividers': {
      padding: '24px 24px 0px 24px !important'
    },
    overflow: 'hidden'
  },

  contentAlign: {
    display: 'flex',
    alignItems: 'center'
  },

  checkBoxContainer: {
    marginTop: 9,
    marginBottom: 33,
    marginLeft: 85
  },

  checkContent: {
    display: 'flex',
    flexDirection: 'column'
  },

  icon: {
    width: 51,
    height: 51,
    color: '#624C92',
    marginRight: 24
  }
}))
