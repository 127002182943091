import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  CardContext: {
    border: '1px solid rgb(235,235,235)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    padding: '24px 16px',
    marginBottom: '16px',
    '&:first-child': {
      marginTop: '40px'
    }
  },
  textAddress: {
    color: 'rgba(0,0,0,.54)',
    fontSize: '16px',
    margin: '12px 0',
    lineHeight: '19px'
  },
  CardSubTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px'
  },

  text: {
    fontWeight: 'normal',
    marginBottom: '12px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  textH5: {
    fontSize: '24px',
    marginTop: '40px',
    fontWeight: '400',
    lineHeight: '133.4%'
  },

  textH6: {
    fontSize: '20px',
    marginTop: '40px',
    fontWeight: '500',
    lineHeight: '160%',
    marginBottom: '16px'
  },

  CardTextH6: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '160%'
  },

  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '12px 0'
  },

  Column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '12px'
  },

  Image: {
    width: '100px',
    height: '68px',
    borderRadius: '8px'
  }
}))

export default useStyles
