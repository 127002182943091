import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import CloudDoneIcon from '@material-ui/icons/CloudDone'

import useStyles from './styles'

export const SavingInfoWidget = (props) => {
  const { isSaving, savedAt } = props
  const classes = useStyles()

  const savedFormattedText = () => {
    if (!savedAt) return ''

    return `Salvo ${savedAt.toLocaleTimeString('pt-br', {
      hour: '2-digit',
      minute: '2-digit'
    })} • ${savedAt.toLocaleDateString('pt-br', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })}`
  }

  return (
    <div className={classes.container}>
      {isSaving ? (
        <>
          <AutorenewIcon className={classes.animation} />
          <Typography variant="body2">Salvando...</Typography>
        </>
      ) : (
        <>
          <CloudDoneIcon />
          <Typography variant="body2">{savedFormattedText()}</Typography>
        </>
      )}
    </div>
  )
}

SavingInfoWidget.propTypes = {
  isSaving: PropTypes.bool,
  savedAt: PropTypes.instanceOf(Date).isRequired
}

SavingInfoWidget.defaultProps = {
  isSaving: false
}
