import { selector } from 'recoil'
import { courseTopicsState } from '../course-topics-state'
import { locateTopicById } from '../../../domain/courses/register-elearning-page/business'
import { REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS as F } from '../../../domain/courses/register-elearning-page/register-elearning-all-subject-list-fields'

const selectTopicUpdater = (requestTimeStamp, allTopics, state) => {
  if (requestTimeStamp !== state.requestTimeStamp) {
    const topic = locateTopicById(state.selectedTopicId, allTopics)

    return {
      selectedTopicData: topic?.[F.CHILDREN.value] ?? null,
      subjectSearch: ''
    }
  }

  return {}
}

export const allTopicSelector = selector({
  key: 'allTopicSelector',
  get: ({ get }) => {
    return {
      title: get(courseTopicsState).courseTitle,
      isFetching: get(courseTopicsState).isFetching,
      allTopics: get(courseTopicsState).allTopics,
      requestId: get(courseTopicsState).requestId,
      requestTimeStamp: get(courseTopicsState).requestTimeStamp
    }
  },
  set: (
    { get, set },
    { isFetching, allTopics, requestId, requestTimeStamp, title }
  ) => {
    const selectedTopicUpdated = selectTopicUpdater(
      requestTimeStamp,
      allTopics,
      get(courseTopicsState)
    )

    set(courseTopicsState, {
      ...get(courseTopicsState),
      isFetching,
      allTopics,
      requestId,
      ...selectedTopicUpdated,
      requestTimeStamp,
      courseTitle: title
    })
  }
})
