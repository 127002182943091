import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { deleteRequestResponseHandler } from '../../../utils/response-handlers'

/**
 * Caso de uso para marcar ou desmarcar um curso como 100% postado
 * @param {string|number} topic - identificador tópico que terá sua propriedade alterada
 */
export const removeTopic = (topicRelationId) => {
  const url = ['/offers/api/v1/course_relationship/', topicRelationId].join('')

  return new Promise((resolve, reject) => {
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpClient
      .delete(url, null, headers)
      .then((response) => {
        deleteRequestResponseHandler(response)
        resolve()
      })
      .catch((err) => reject(err))
  })
}
