import axios from 'axios'
import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { CourseServiceUpdateAdapter } from '../adapters/course-service-update'
import { verifyAndPluckIfImageChanged } from '../../../domain/courses/business'

/**
 * Caso de uso para alterar os dados de um curso
 */
export const updateCourseService = (serviceId, localData) => {
  const { REACT_APP_FUSION_ORIGIN: baseUrl } = process.env
  const url = `${baseUrl}/offers/api/v1/services/${serviceId}?update_with_category_and_plan`

  return new Promise((resolve, reject) => {
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    const { image } = verifyAndPluckIfImageChanged(localData)
    const payload = CourseServiceUpdateAdapter.outbound(localData)

    const bodyContent = new FormData()
    if (image) bodyContent.append('file', image)
    bodyContent.append('data', JSON.stringify(payload))

    axios
      .patch(url, bodyContent, {
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          resolve()
          return
        }

        throw new Error('invalid response')
      })
      .catch((err) => {
        reject(err)
      })
  })
}
