import { USER_MANAGEMENT_COURSE_DETAIL_FIELDS as LIST_FIELDS } from '../../../domain/user-management-content/user-management-page/user-management-course-detail-fields'
import { COURSE_DETAIL_FIELDS } from '../remote-fields/course-detail-fields'
import { formatDate } from '../../../helpers/formatDate'

export const courseDetailAdapterInbound = (remoteData) => {
  return {
    [LIST_FIELDS.KEY.value]: remoteData[COURSE_DETAIL_FIELDS.KEY],
    [LIST_FIELDS.NAME.value]: remoteData[COURSE_DETAIL_FIELDS.NAME],
    [LIST_FIELDS.SKU.value]: remoteData[COURSE_DETAIL_FIELDS.SKU],
    [LIST_FIELDS.START_DATE.value]: formatDate(
      remoteData[COURSE_DETAIL_FIELDS.START_DATE]
    ),
    [LIST_FIELDS.END_DATE.value]: formatDate(
      remoteData[COURSE_DETAIL_FIELDS.END_DATE]
    ),
    [LIST_FIELDS.LAST_ACESS.value]: remoteData[COURSE_DETAIL_FIELDS.LAST_ACESS],
    [LIST_FIELDS.STATUS.value]: remoteData[COURSE_DETAIL_FIELDS.STATUS],
    [LIST_FIELDS.PRICE.value]: remoteData[COURSE_DETAIL_FIELDS.PRICE],
    [LIST_FIELDS.DISCOUNT.value]: remoteData[COURSE_DETAIL_FIELDS.DISCOUNT],
    [LIST_FIELDS.SUBTOTAL.value]: remoteData[COURSE_DETAIL_FIELDS.SUBTOTAL],
    [LIST_FIELDS.VOUCHER.value]: remoteData[COURSE_DETAIL_FIELDS.VOUCHER],
    [LIST_FIELDS.REVERSAL.value]: remoteData[COURSE_DETAIL_FIELDS.REVERSAL],
    [LIST_FIELDS.EXTENDED.value]: remoteData[COURSE_DETAIL_FIELDS.EXTENDED],
    [LIST_FIELDS.TOTAL.value]: remoteData[COURSE_DETAIL_FIELDS.TOTAL]
  }
}
