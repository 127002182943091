import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'

/**
 * Caso de uso para exportacao de dados dos ids dos vouchers fornecidos
 */
const exportVouchers = async (ids) => {
  return new Promise((resolve, reject) => {
    const path = 'tickets/api/v1/tickets/ecommerce/export'
    const payload = {
      tickets_ids: ids
    }
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken(),
      accept: '*/*'
    })

    httpClient
      .post(path, payload, headers)
      .then((response) => {
        return response.text()
      })
      .then((csv) => {
        resolve(csv)
      })
      .catch((error) => reject(error))
  })
}

export { exportVouchers }
