/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ConnectionsProvider } from './contexts/ConnectionsContext'
import { ContentsProvider } from './contexts/ContentsContext'
import { GeneralProvider } from './contexts/GeneralContext'
import Home from './pages/Home'
import ContentManager from './pages/Contents'
import Connections from './pages/Connections'
import LtiValidation from './components/pages/ltiValidation'
import Library from './pages/Library'
import Libraries from './pages/Libraries'
import Tickets from './pages/Library/Tickets'
import TicketUsers from './pages/Library/TicketUsers'
import ShowFolder from './components/organisms/showFolder'
import ShowContent from './components/organisms/showContent'
import MarcConnections from './pages/MarcConnections'
import JWTConection from './pages/JWTConections'
import NewMarcConnections from './pages/NewMarcConnections'
import NewJWTConnections from './pages/NewJWTConnections'
import NewLtiConnections from './pages/NewLtiConnections'
import AccessManager from './pages/AccessManager'
import Ies from './pages/PageIes'
import { RoutesMapping } from './helpers/routes-mapping'
import { PrivateRoute } from './components/PrivateRoute'
import { PromotionalContentRoutes } from './routes-modules/PromotionalContentRoutes'
import { UserManagementContentRoutes } from './routes-modules/UserManagementContentRoutes'
import { VouchersRoutes } from './routes-modules/VouchersRoutes'
import { CoursesRoutes } from './routes-modules/CoursesRoutes'
import { ConnectionProvider } from './contexts/ConnectionContext'
import ManageContent from './pages/ManageContent'
import QuestionsDatabase from './pages/QuestionsDatabase'
import { SimulatedActivity } from './pages/Courses/SimulatedActivity'
import { BannerHomeConecta } from './pages/promotional/BannerHomeConecta/index'
import { EditBannerHome } from './pages/promotional/BannerHomeConecta/components/EditBannerHome'
import { UploadQuestionWorksheet } from './contexts/UploadQuestionWorksheetContext'
import { UserData } from './pages/UserManagement/UserData'
import { GenerateUserAccess } from './pages/UserManagement/GenerateUserAccess'
import { UserSheet } from './pages/UserManagement/UserSheet'

export default function Routes() {
  return (
    <Router>
      <GeneralProvider>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={() => <Home />}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/manage-content"
            component={(routeProps) => (
              <ContentsProvider>
                <ManageContent {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/contents"
            component={(routeProps) => (
              <ContentsProvider>
                <ContentManager {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            exact
            path="/SimulatedActivity"
            component={(routeProps) => (
              <ContentsProvider>
                <SimulatedActivity {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            exact
            path="/banner-conecta"
            component={(routeProps) => (
              <ContentsProvider>
                <BannerHomeConecta {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            exact
            path="/banner-conecta/edit/"
            component={(routeProps) => (
              <ContentsProvider>
                <EditBannerHome {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            exact
            path="/ies"
            component={(routeProps) => (
              <ContentsProvider>
                <ConnectionProvider connectionProps={{}}>
                  <Ies {...routeProps} />
                </ConnectionProvider>
              </ContentsProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/question-database"
            component={(routeProps) => (
              <ContentsProvider>
                <UploadQuestionWorksheet>
                  <QuestionsDatabase {...routeProps} />
                </UploadQuestionWorksheet>
              </ContentsProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/libraries"
            component={(routeProps) => (
              <ConnectionsProvider>
                <Libraries {...routeProps} />
              </ConnectionsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            exact
            path="/libraries/:libraryCode"
            component={Library}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/libraries/:libraryCode/tickets"
            component={Tickets}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/libraries/:libraryCode/tickets/:ticketId/users"
            component={TicketUsers}
            defaultLayout
          />

          <PrivateRoute
            exact
            path="/connections"
            component={(routeProps) => (
              <ConnectionsProvider>
                {process.env.REACT_APP_FLAG_CLIENTS_REFACTOR === 'true' ? (
                  <NewLtiConnections {...routeProps} />
                ) : (
                  <Connections {...routeProps} />
                )}
              </ConnectionsProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/marc_connection"
            component={(routeProps) => (
              <ConnectionsProvider>
                {process.env.REACT_APP_FLAG_CLIENTS_REFACTOR === 'true' ? (
                  <NewMarcConnections {...routeProps} />
                ) : (
                  <MarcConnections {...routeProps} />
                )}
              </ConnectionsProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/jwt_connection"
            component={(routeProps) => (
              <ConnectionsProvider>
                {process.env.REACT_APP_FLAG_CLIENTS_REFACTOR === 'true' ? (
                  <NewJWTConnections {...routeProps} />
                ) : (
                  <JWTConection {...routeProps} />
                )}
              </ConnectionsProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/access_manager"
            component={(routeProps) => (
              <ConnectionsProvider>
                {process.env.REACT_APP_FLAG_CLIENTS_REFACTOR === 'true' && (
                  <AccessManager {...routeProps} />
                )}
              </ConnectionsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            path={RoutesMapping.userData}
            component={(routeProps) => (
              <ContentsProvider>
                <UserData {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            path={RoutesMapping.simUser}
            component={(routeProps) => (
              <ContentsProvider>
                <GenerateUserAccess {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <PrivateRoute
            path={RoutesMapping.userSheet}
            component={(routeProps) => (
              <ContentsProvider>
                <UserSheet {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />

          <Route
            path={RoutesMapping.promotionalPageEntryPotint}
            component={(routeProps) => (
              <ContentsProvider>
                <PromotionalContentRoutes {...routeProps} />
              </ContentsProvider>
            )}
          />

          <Route
            path={RoutesMapping.userManagementPageEntryPoint}
            component={(routeProps) => (
              <ContentsProvider>
                <UserManagementContentRoutes {...routeProps} />
              </ContentsProvider>
            )}
          />

          <Route
            path={RoutesMapping.coursesEntryPoint}
            component={(routeProps) => (
              <ContentsProvider>
                <CoursesRoutes {...routeProps} />
              </ContentsProvider>
            )}
          />

          <Route
            path={RoutesMapping.vouchersEntryPoint}
            component={VouchersRoutes}
          />

          <Route
            path={RoutesMapping.registerElearning}
            component={CoursesRoutes}
          />

          <Route
            path={RoutesMapping.complementaryMaterialDetails}
            component={CoursesRoutes}
          />

          <Route
            path="/lti"
            component={(routeProps) => (
              <ContentsProvider>
                <LtiValidation {...routeProps} />
              </ContentsProvider>
            )}
            defaultLayout
          />
          <Route path="/show_folder/:id" component={ShowFolder} />
          <Route path="/show_content/:id" component={ShowContent} />
        </Switch>
      </GeneralProvider>
    </Router>
  )
}
