import { USER_MANAGEMENT_DETAIL_FIELDS as LIST_FIELDS } from '../../../domain/user-management-content/user-management-page'
import { USER_FIELDS_LIST_ITEM } from '../remote-fields/user-list-fields'
import { formatDateHour } from '../../../helpers/formatDate'
import { formatCPF } from '../../../helpers/formatCPF'

export const userDetailAdapterInbound = (remoteData) => {
  return {
    [LIST_FIELDS.KEY.value]: remoteData[USER_FIELDS_LIST_ITEM.KEY],
    [LIST_FIELDS.NAME.value]: remoteData[USER_FIELDS_LIST_ITEM.NAME],
    [LIST_FIELDS.LAST_NAME.value]: remoteData[USER_FIELDS_LIST_ITEM.NAME],
    [LIST_FIELDS.LAST_NAME.value]: remoteData[USER_FIELDS_LIST_ITEM.LAST_NAME],
    [LIST_FIELDS.FULL_NAME.value]: `${remoteData[USER_FIELDS_LIST_ITEM.NAME]} ${
      remoteData[USER_FIELDS_LIST_ITEM.LAST_NAME]
    }`,
    [LIST_FIELDS.ADDRESS.value]: remoteData[USER_FIELDS_LIST_ITEM.ADDRESS],
    [LIST_FIELDS.CPF.value]: formatCPF(remoteData[USER_FIELDS_LIST_ITEM.CPF]),
    [LIST_FIELDS.EMAIL.value]: remoteData[USER_FIELDS_LIST_ITEM.EMAIL],
    [LIST_FIELDS.CREATE_ACCOUNT_DATE.value]: formatDateHour(
      remoteData[USER_FIELDS_LIST_ITEM.CREATE_ACCOUNT_DATE]
    ),
    [LIST_FIELDS.LAST_ACCESS.value]: formatDateHour(
      remoteData[USER_FIELDS_LIST_ITEM.LAST_ACCESS]
    ),
    [LIST_FIELDS.ACCESS_LOCALE.value]:
      remoteData[USER_FIELDS_LIST_ITEM.ACCESS_LOCALE],
    [LIST_FIELDS.JUSTIFICATIONS.value]:
      remoteData[USER_FIELDS_LIST_ITEM.JUSTIFICATIONS],
    [LIST_FIELDS.MOVING_DATE.value]: formatDateHour(
      remoteData[USER_FIELDS_LIST_ITEM.MOVING_DATE]
    ),
    [LIST_FIELDS.CHANGE_BY.value]: remoteData[USER_FIELDS_LIST_ITEM.CHANGE_BY],
    [LIST_FIELDS.INFORMATION_CHANGE.value]:
      remoteData[USER_FIELDS_LIST_ITEM.INFORMATION_CHANGE],
    [LIST_FIELDS.JUSTIFICATION.value]:
      remoteData[USER_FIELDS_LIST_ITEM.JUSTIFICATION],
    [LIST_FIELDS.STATUS.value]:
      remoteData[USER_FIELDS_LIST_ITEM.STATUS] === true ? 'Ativo' : 'inativo'
  }
}
