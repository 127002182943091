export const USER_MANAGEMENT_COURSE_DETAIL_FIELDS = Object.freeze({
  KEY: { value: 'id', label: 'identificador' },
  NAME: {
    value: 'name',
    label: 'Nome do curso'
  },
  SKU: { value: 'sku', label: 'SKU' },
  START_DATE: { value: 'startDate', label: 'Data de início do curso' },
  END_DATE: { value: 'endDate', label: 'Data de fim do curso' },
  LAST_ACESS: { value: 'lastAcess', label: 'Data do último acesso ao curso' },
  STATUS: { value: 'status', label: 'Status do curso' },
  EXTENDED: { value: 'extended', label: 'Flag prorrogado' },
  EXTENDED_DATE: { value: 'extendedDate', label: 'Data de prorrogação' },
  EXTENDED_BY: { value: 'extendedBy', label: 'Prorrogado por' },
  EXTENDED_FROM_TO: { value: 'extendedFromTo', label: 'Prorrogado de para' },
  EXTENDED_JUSTIFICATION: {
    value: 'extendedJustification',
    label: 'Justificativa prorrogação'
  },
  PRICE: { value: 'price', label: 'Valor do curso' },
  DISCOUNT: { value: 'discount', label: 'Porcentagem de desconto' },
  SUBTOTAL: { value: 'subtotal', label: 'Subtotal' },
  VOUCHER: { value: 'voucher', label: 'Voucher' },
  VOUCHER_NAME: {
    value: 'voucherName',
    label: 'Nome do voucher'
  },
  VOUCHER_PERCENTAGE: {
    value: 'voucherPercentage',
    label: 'Porcentagem do voucher'
  },
  TOTAL: { value: 'total', label: 'Total' },
  REVERSAL: { value: 'reversal', label: 'Estorno' },
  REVERSAL_DATE: { value: 'reversalDate', label: 'Data de estorno' },
  REVERSAL_BY: { value: 'reversalBy', label: 'Usuário do estorno' },
  REVERSAL_METHOD: { value: 'reversalMethod', label: 'Metodo estornado' },
  REVERSAL_JUSTIFICATION: {
    value: 'reversalJustification',
    label: 'Justificativa do estorno'
  }
})
