import React, { useEffect, useContext, useState } from 'react'
import { useParams, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import ContentTitle from '../../../../components/ContentTitle'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import Messages from '../../../../helpers/messages'
import { DuplicarBanner } from './DuplicarBanner'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import { PromotionalContext } from '../../../../contexts/PromotionalContext'
import { DuplicarBannerSale } from './DuplicarBannerSale'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { getBanner } from '../../../../services/promotional-content/banners-pages'

export function Duplicate({ type }) {
  const { setInfoMessage, setErrorMessage } = useContext(GeneralContext)
  const { typeBanner, setTypeBanner } = useContext(PromotionalContext, type)
  const [isFetching, setIsFetching] = useState(true)
  const { brand, id } = useParams()
  const [formSharedState, setFormSharedState] = useState()

  const backButtonRoute = () => {
    return generatePath(RoutesMapping.promotionalBannerList, { brand })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoMessage(Messages.duplicateBanner.default)

    getBanner(id)
      .then((banner) => {
        setFormSharedState(banner)
        setTypeBanner(banner.type)
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar o banner rotativo')
      })
      .finally(() => setIsFetching(false))
  }, [id, setErrorMessage, setInfoMessage])

  return (
    <ContainerWithLoader isFetching={isFetching}>
      <ContentWithHeader
        title="Criar Banner"
        backButtonRoute={backButtonRoute()}
      >
        <SnackbarOutlet />
        <ContentTitle title="Tipo de banner" size="small" />

        {typeBanner === 'rotary' && <DuplicarBanner state={formSharedState} />}
        {typeBanner === 'sale' && (
          <DuplicarBannerSale state={formSharedState} />
        )}
      </ContentWithHeader>
    </ContainerWithLoader>
  )
}

Duplicate.propTypes = {
  type: PropTypes.string.isRequired
}
