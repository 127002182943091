import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const AvaConnection = {
  // TO DO: remover apos refatoracao
  async createAvaConnection(payload) {
    const path = 'accounts/api/v1/lti/client'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },

  // TO DO: remover apos refatoracao
  async findAvaConnectionByCnpj(idNumber) {
    const path = `accounts/api/v1/clients/search?id_number=${idNumber}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  // TO DO: remover apos refatoracao
  async saveConfigurationConnect(payload, clientId) {
    const path = `accounts/api/v1/lti/client/${clientId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  // TO DO: remover apos refatoracao
  async listAvaConnection(appToken) {
    const path = `accounts/api/v1/lti/client?app_token=${appToken}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  // TO DO: remover apos refatoracao
  async insertDeploymentId(payload) {
    const path = 'accounts/api/v1/lti/deployment'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  // TO DO: remover apos refatoracao
  async setStatus(payload, clientId) {
    const path = `accounts/api/v1/lti/client/${clientId}/set_status`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  // TO DO: remover apos refatoracao
  async deleteDeploymentId(userIntegrationId, payload) {
    const path = `accounts/api/v1/lti/deployment/${userIntegrationId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, payload, headers)
    return response
  },

  async getContent(id) {
    const path = id
      ? `contents/api/v1/modules/${id}`
      : `contents/api/v1/modules`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, headers)
    return response
  },

  async returnDisciplines() {
    const path = `contents/api/v1/modules`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, headers)
    return response
  },

  async saveDisciplines(clientId, payload) {
    const path = `accounts/api/v1/lti/client/${clientId}/modules`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  }
}

export default AvaConnection
