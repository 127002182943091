import { atom } from 'recoil'
import { scheduledContentDefaultForm } from '../../domain/courses/scheduled-content/business'

export const scheduledContentState = atom({
  key: 'scheduledContentState',
  default: {
    isFetching: false,
    lastFetchedAt: null,
    type: '',
    data: [],
    form: scheduledContentDefaultForm()
  }
})
