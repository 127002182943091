import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '40px 0'
  },
  forms: {
    marginBottom: '30px'
  },
  input: {
    marginRight: '30px'
  },
  sectionText: {
    color: '#0000008a',
    fontFamily: 'Roboto',
    fontWeight: '400px',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '150%',
    marginBottom: '24px'
  },
  backButton: {
    marginLeft: 72
  },
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  }
}))

export default useStyles
