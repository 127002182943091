/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TablePagination,
  TableCell,
  TableHead,
  Checkbox
} from '@material-ui/core'

import { Delete } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import FolderIcon from '@material-ui/icons/Folder'
import useStyles from './styles'
import { ConnectionContext } from '../../../contexts/ConnectionContext'
import DialogDelete from '../DialogDelete'
// import ContentConnection from '../../../services/contentConnection'
import { ContentsContext } from '../../../contexts/ContentsContext'

function SelectSubjectsTable() {
  const classes = useStyles()
  const history = useHistory()

  const { connection, updateConnection, setFetching } =
    useContext(ConnectionContext)

  const { fetchContentsByClientId, contentByClient } =
    useContext(ContentsContext)

  const [subjects, setSubjects] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6)
  // const [subjectId, setsubjectId] = useState([])

  // async function fetchSubjects() {
  //   try {
  //     setFetching(true)

  //     const newArray = []
  //     // eslint-disable-next-line no-unused-expressions
  //     connection.modules?.map(async (item) => {
  //       const response = await ContentConnection.getContent(item)
  //       if (!response.ok) {
  //         const errors = await response.json()
  //         throw errors
  //       }
  //       const result = await response.json()

  //       if (result.path.length > 1) {
  //         newArray.push(result.path[result.path.length - 1])
  //       }
  //       if (result.path.length === 1) {
  //         newArray.push(result.path[0])
  //       }

  //       // console.log(result)

  //       // console.log(newArray)

  //       // console.log(
  //       //   'item',
  //       //   contentByClient.map((subject) => subject.id)
  //       // )

  //       setsubjectId(newArray.map((subject) => subject.id))
  //       const arrayNumeros = subjectId.map((id) => parseInt(id, 10))
  //       const { ies } = history.location.state
  //       ies.modules = arrayNumeros

  //       const subjectsList = newArray.map((subject) => {
  //         const checked = false

  //         return {
  //           id: subject.id,
  //           name: subject.name,
  //           checked
  //         }
  //       })

  //       setSubjects(subjectsList)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   } finally {
  //     setFetching(false)
  //   }
  // }

  function fetchClientContents() {
    const subjectsList = contentByClient.map((subject) => {
      const checked = false

      return {
        id: subject.id,
        name: subject.name,
        checked
      }
    })

    setSubjects(subjectsList)
  }

  useEffect(() => {
    // fetchSubjects()
    fetchContentsByClientId()
  }, [connection.modules, setFetching])

  useEffect(() => {
    updateConnection(history.location.state.ies)
    fetchClientContents()
    window.scrollTo(0, 0)
  }, [contentByClient])

  const checkOrUncheckAll = (evt) => {
    const subjectsUpdated = subjects.map((subject) => ({
      ...subject,
      checked: evt.target.checked
    }))
    setSubjects(subjectsUpdated)
  }

  const checkOrUncheckSubject = (evt, id) => {
    const subjectsUpdated = subjects.map((subject) => {
      if (subject.id === id) {
        return { ...subject, checked: evt.target.checked }
      }
      return subject
    })

    setSubjects(subjectsUpdated)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const [showDialog, setShowDialog] = useState(false)
  const handleOpenDialogDelete = () => {
    setShowDialog(true)
  }

  const handleCloseDialogDelete = () => {
    setShowDialog(false)
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography color="primary">INÍCIO</Typography>
          {subjects.some((subject) => subject.checked) ? (
            <Button
              classesName={classes.btDelete}
              variant="outlined"
              color="primary"
              startIcon={<Delete />}
              onClick={() => handleOpenDialogDelete()}
            >
              Excluir
            </Button>
          ) : null}
        </Box>

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    onChange={checkOrUncheckAll}
                    style={{ marginLeft: -8 }}
                  />
                  Conteúdo da Instituição
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {subjects
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((subject) => {
                  return (
                    <TableRow key={subject.id}>
                      <TableCell className={classes.checkboxItems}>
                        <Checkbox
                          key={subject.id}
                          checked={subject.checked}
                          onChange={(evt) =>
                            checkOrUncheckSubject(evt, subject.id)
                          }
                          color="primary"
                        />

                        <Box
                          className={
                            subject.checked
                              ? classes.checked
                              : classes.notChecked
                          }
                        >
                          <FolderIcon className={classes.pasteIcon} />
                          {subject.name}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              <TablePagination
                rowsPerPageOptions={[6, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={subjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowsPerPageChange={page}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <DialogDelete
        setShowDialog={handleCloseDialogDelete}
        showDialog={showDialog}
        subjects={subjects}
      />
    </>
  )
}
export default SelectSubjectsTable
