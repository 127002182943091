import { makeStyles } from '@material-ui/core/styles'
import { spacing, pageClasses } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  uploadFile: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      color: theme.palette.error.main
    }
  },
  errorMsg: {
    color: theme.palette.error.main
  },
  ...pageClasses(theme),
  hidden: {
    display: 'none'
  },
  labelOverride: {
    '& label': {
      zIndex: '1 !important'
    }
  }
}))

export default useStyles
