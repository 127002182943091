import { USER_MANAGEMENT_FIELDS as F } from './user-management-page-fields'

export const USER_MANAGEMENT_DETAIL_FIELDS = Object.freeze({
  KEY: { value: F.KEY.value, label: F.KEY.label },
  FULL_NAME: { value: F.FULL_NAME.value, label: F.FULL_NAME.label },
  PASSWORD: { value: 'password', label: 'Senha' },
  NAME: { value: 'name', label: 'Nome' },
  LAST_NAME: { value: 'lastName', label: 'Sobrenome' },
  CPF: { value: F.CPF.value, label: F.CPF.label },
  EMAIL: { value: F.EMAIL.value, label: F.EMAIL.label },
  ADDRESS: { value: 'address', label: 'Endereço' },
  CEP: { value: 'cep', label: 'CEP' },
  CITY: { value: 'city', label: 'Cidade' },
  NEIGHBORHOOD: { value: 'neighborhood', label: 'Bairro' },
  STREET: { value: 'street', label: 'Endereço' },
  STATE: { value: 'state', label: 'Estado' },
  JUSTIFICATIONS: { value: 'justifications', label: 'Justificativas' },
  STATUS: { value: F.STATUS.value, label: F.STATUS.label },
  CREATE_ACCOUNT_DATE: {
    value: 'createAccountDate',
    label: 'Horário de cadastro da conta'
  },
  LAST_ACCESS: {
    value: 'lastAccess',
    label: 'Último acesso a conta do e-commerce'
  },
  ACCESS_LOCALE: { value: 'accessLocale', label: 'Local de acesso' },
  MOVING_DATE: { value: 'movingDate', label: 'Alterado em' },
  CHANGE_BY: { value: 'changedBy', label: 'Alterado por' },
  INFORMATION_CHANGE: {
    value: 'informationChange',
    label: 'Alteração de informação'
  },
  JUSTIFICATION: { value: 'justification', label: 'Justificativa' }
})
