import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    marginBottom: 7,
    paddingBottom: 72
  },

  paper: {
    marginTop: 24
  },

  tabs: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  tab: {
    padding: '12px 32px 12px 32px',
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textDecoration: 'none'
  },

  tabActive: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },

  content: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 40,
    paddingBottom: 64
  },

  grid: {
    display: 'grid',
    'grid-template-columns': '440px 300px'
  },

  backButton: {
    marginTop: 16,
    marginBottom: 40
  },

  backIcon: {
    fontSize: '0.85rem !important',
    fontWeight: 500
  },

  labelWrapper: {
    marginBottom: 24
  },

  label: {
    color: theme.palette.text.secondary,
    marginBottom: 8
  },

  value: {
    color: theme.palette.text.primary
  },

  snackDiv: {
    marginBottom: 24
  },

  inputWrapper: {
    marginRight: 24
  },
  buttonsWrapper: {
    display: 'flex',
    width: 'max-content'
  },
  editDate: {
    marginRight: 12
  }
}))

export default useStyles
