import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { GeneralContext } from '../../contexts/GeneralContext'
import messages from '../../helpers/messages'
import IntegrationsService from '../../services/integrationsService'

export function DeleteSimulated({
  showDialogDelete,
  setshowDialogDelete,
  selectedSimulatedIds,
  setSelectedSimulatedIds,
  updateData
}) {
  const [saving, setSaving] = useState(false)
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)

  const handleCloseDialogDelete = () => {
    setshowDialogDelete(false)
  }

  const save = async () => {
    try {
      setSaving(true)

      const deletePromises = selectedSimulatedIds.map((id) =>
        IntegrationsService.deleteSimulated(id)
      )

      const responses = await Promise.all(deletePromises)

      const failedDeletions = responses.filter(
        (response) => response.status !== 204
      )

      if (failedDeletions.length > 0) {
        setErrorMessage(messages.deleteSimulated.error)
      } else {
        setSuccessMessage(messages.deleteSimulated.success)
      }

      updateData()
      setSelectedSimulatedIds([])
    } catch (error) {
      console.error('Erro ao buscar dados do backend:', error)
    } finally {
      setSaving(false)
      handleCloseDialogDelete()
    }
  }

  return (
    <div>
      <Dialog
        title="Excluir simulado"
        secondaryButton="CANCELAR"
        primaryButton="EXCLUIR"
        size="md"
        open={showDialogDelete}
        handleClose={() => handleCloseDialogDelete()}
        handleConfirm={() => save()}
        isDisabled={saving}
        label="Sim, tenho certeza!"
        alert
      >
        <Typography
          variant="body1"
          style={{ color: '#000', paddingLeft: '11px' }}
        >
          Você tem certeza que deseja excluir o simulado? <br />
          Ele também será excluído na plataforma Conecta .
        </Typography>
      </Dialog>
    </div>
  )
}
DeleteSimulated.propTypes = {
  showDialogDelete: PropTypes.bool.isRequired,
  setshowDialogDelete: PropTypes.func.isRequired,
  selectedSimulatedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedSimulatedIds: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired
}
