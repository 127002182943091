import { BRANDS, BRANDS_IDENTIFIER } from '../brands/brands'

export const PRODUCT_SELECTION_VALUES = BRANDS_IDENTIFIER

export const PRODUCT_SELECTION_OPTIONS = Object.freeze([
  {
    ...BRANDS.BDS,
    token: process.env.REACT_APP_BDS_TOKEN
  },
  {
    ...BRANDS.SSO,
    // token: process.env.REACT_APP_BDS_TOKEN
  },
  {
    ...BRANDS.DONS,
    token: process.env.REACT_APP_DONS_TOKEN
  },
  {
    ...BRANDS.LFG,
    token: process.env.REACT_APP_LFG_TOKEN
  },
  {
    ...BRANDS.CONECTA,
    token: process.env.REACT_APP_CONECTA_TOKEN
  }
])
