import React, { useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Pagination, Button } from '@equipe-ninja/saraiva-ui'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'

import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELD } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import useStyles from '../styles'

export default function CardRotary({ banners }) {
  const [page, setPage] = useState(1)
  const classes = useStyles()
  const history = useHistory()

  const getLabel = (field) => {
    return FIELD[field].label
  }

  const getStatus = (status) => {
    return status === 'ativo' ? (
      <font color="green">Em exibição no site</font>
    ) : (
      <font color="red">Inativo</font>
    )
  }

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const setPageCurrent = (e, currentPage) => {
    setPage(currentPage)
  }

  const handleEditBanner = (id) => {
    history.push(
      generatePath(RoutesMapping.promotionalBannerEdit, { brand, id })
    )
  }

  return (
    <>
      {banners.slice((page - 1) * 6, 6 + (page - 1) * 6).map((banner, key) => {
        return (
          <div
            className={classes.CardContext}
            style={{ marginTop: key === 0 ? 48 : 0 }}
          >
            <div className={classes.CardMain}>
              <div className={classes.CardBanner}>
                <img
                  style={{ height: 50, width: 444 }}
                  src={banner.imageWeb}
                  alt=""
                />
              </div>
              <div className={classes.CardTitle}>{banner.title}</div>
              <div className={classes.CardDetals}>
                <span>{getLabel('SESSION')}:</span> {banner.session}
              </div>
              <div className={classes.CardDetals}>
                <span>Ordem:</span> {banner.orders}
              </div>
              <div className={classes.CardDetals}>
                <span>{getLabel('START_DATE')}:</span> {banner.startDate}
                <span>{getLabel('END_DATE')}:</span> {banner.endDate}
              </div>
              <div className={classes.CardDetals}>
                <span>{getLabel('STATUS')}:</span> {getStatus(banner.status)}
              </div>
            </div>
            <div className={classes.CardFooter}>
              <Button
                color="secondary"
                onClick={() => handleEditBanner(banner.key)}
              >
                Editar
              </Button>
            </div>
          </div>
        )
      })}

      <div className={classes.contentCenter}>
        <Pagination
          color="secondary"
          count={banners.length >= 6 ? Math.ceil(banners.length / 6) : 1}
          onChange={setPageCurrent}
          siblingCount={3}
          page={page}
        />
      </div>
    </>
  )
}

CardRotary.propTypes = {
  banners: PropTypes.arrayOf(FIELD)
}

CardRotary.defaultProps = {
  banners: []
}
