import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { COURSE_SERVICE_STATUS } from '../../../domain/courses/entities'
import { SERVICE_REMOTE_FIELDS as S_REMOTE } from '../remote-fields/courses-services-fields'
import { CourseServiceStatusAdapter } from '../adapters/course-service-status'
import {
  postResquestResponseHandler,
  putRequestResponseHandler
} from '../../utils/response-handlers'
import { ServiceAdapter } from '../adapters/service-adapter'

const headers = () => {
  return httpClient.buildHeader({ 'session-token': getSessionToken() })
}

const sericeStatusAdapter = () => ({
  [S_REMOTE.STATUS]: CourseServiceStatusAdapter.outbound(
    COURSE_SERVICE_STATUS.UNAVAILABLE
  )
})

const updateCourseStatus = (id) => {
  const coursePayload = sericeStatusAdapter()
  const path = `/offers/api/v1/services/${id}`

  return new Promise((resolve, reject) => {
    httpClient
      .patch(path, coursePayload, headers())
      .then((response) => putRequestResponseHandler(response))
      .then((parsedJson) => {
        resolve(ServiceAdapter.inbound(parsedJson.data))
      })
      .catch((err) => reject(err))
  })
}

const createRedirect = (serviceId, redirectUrl, currentUrl) => {
  const path = '/markets/api/v1/products_url'
  const payload = {
    name: `${serviceId}-disabled`,
    current_url: currentUrl,
    new_url: redirectUrl,
    service_id: serviceId,
    redirect_url: 1
  }

  return new Promise((resolve, reject) => {
    httpClient
      .post(path, payload, headers())
      .then((response) => postResquestResponseHandler(response))
      .then((parsedJson) => {
        resolve(parsedJson)
      })
      .catch((err) => reject(err))
  })
}

/**
 * cria um um redirecionamento e inativa um curso
 * @param {number} serviceId
 * @param {string} redirect_url
 * @param {string} courseUrl
 * @returns Promise com sucesso se todas as operações forem bem sucedidas
 */
export const desactivateCourse = async (serviceId, redirectUrl, currentUrl) => {
  const updadeStatusResponse = await updateCourseStatus(serviceId)

  const redirectResponse = await createRedirect(
    serviceId,
    redirectUrl,
    currentUrl
  )

  return [updadeStatusResponse, redirectResponse]
}
