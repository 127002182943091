/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'

import { Attachment, SwapHoriz } from '@material-ui/icons'
import { Button } from '@material-ui/core'

import PropTypes from 'prop-types'

import useStyles from './styles'

export function ButtonUpload(props) {
  const { field, image, type, onUpload, className, btnLabel } = props

  const [imageRead, setImageRead] = useState()

  const classes = useStyles()

  const readImage = () => {
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onloadend = (e) => {
      setImageRead(e.target.result)
    }
  }

  useEffect(() => {
    if (image) {
      if (image instanceof File) {
        return readImage()
      }
      return setImageRead(image)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return !image ? (
    <div className={className}>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        id={`contained-button-file-${type}`}
        onChange={(e) => onUpload(field, e.target.files[0], type)}
      />
      <label htmlFor={`contained-button-file-${type}`}>
        <Button color="secondary" variant="outlined" component="span">
          {btnLabel} {type}
          <Attachment />
        </Button>
      </label>
    </div>
  ) : (
    <div className={`${className} ${classes.divImageUploadPreview}`}>
      <img src={imageRead} alt={`${image?.name}`} width="200" height="50" />
      <span>{image?.name}</span>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        id={`contained-button-file-edit-${type}`}
        onChange={(e) => onUpload(field, e.target.files[0], type)}
      />
      <label htmlFor={`contained-button-file-edit-${type}`}>
        <Button color="secondary" variant="text" component="span">
          <SwapHoriz /> EDITAR
        </Button>
      </label>
    </div>
  )
}

ButtonUpload.propTypes = {
  field: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  className: PropTypes.string,
  btnLabel: PropTypes.string
}

ButtonUpload.defaultProps = {
  image: null,
  className: '',
  btnLabel: 'ANEXAR BANNER'
}
