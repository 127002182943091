export const RoutesMapping = Object.freeze({
  promotionalPageEntryPotint: '/promocional/:brand?',
  promotionalPage: '/promocional/:brand?',
  promotionalBannerList: '/promocional/:brand/banners',
  promotionalBannerCreate: '/promocional/:brand/banners/create',
  promotionalBannerEdit: '/promocional/:brand/banners/:id/editar',
  promotionalBannerSaleEdit: '/promocional/:brand/banners/:id/editarsale',
  promotionalBannerDuplicar: '/promocional/:brand/banners/:id/duplicar',
  promotionalBannerSaleDuplicar: '/promocional/:brand/banners/:id/duplicarsale',
  promotionalPromoPagesList: '/promocional/:brand/paginas-promocionais',
  promotionalPromoPagesNew: '/promocional/:brand/paginas-promocionais/nova',
  promotionalPromoPagesEdit:
    '/promocional/:brand/paginas-promocionais/:id/editar',
  promotionalDiscountList: '/promocional/:brand/descontos',
  promotionalDiscountDetails: '/promocional/:brand/descontos/:id',
  userManagementPageEntryPoint: '/gerenciar-usuario',
  userManagementPage: '/gerenciar-usuario/:brand?',
  userManagementPageDetail: '/gerenciar-usuario/:brand/:userId',
  userManagementPageListOrder:
    '/gerenciar-usuario/:brand/:userId/lista-de-pedido',
  userManagementPageOrderDetails:
    '/gerenciar-usuario/:brand/:userId/detalhes-do-pedido/:orderId',
  userManagementPageCourseDetails:
    '/gerenciar-usuario/:brand/:userId/detalhes-do-curso/:courseId',
  vouchersEntryPoint: '/:brand/vouchers',
  vouchersCreate: '/:brand/vouchers/novo',
  vouchersEdit: '/:brand/vouchers/:id/editar',
  vouchersLegacyEntryPoint: '/libraries/',
  coursesEntryPoint: '/curso/',
  courses: '/curso/:brand?',
  courseCreate: '/curso/:brand/cadastrar-curso/:courseId',
  registerEcommerce: '/curso/:brand/cadastrar-ecommerce/:id',
  registerElearning: '/curso/:brand/cadastrar-elearning/:id',
  complementaryMaterialDetails:
    '/curso/:brand/cadastrar-elearning/:id/complementary-material/:materialId',
  subjectDetails: '/curso/disciplina/:subjectId/curso/:courseId',
  userData: '/dados-dos-usuarios',
  simUser: '/simulacao-de-conta',
  userSheet: '/detalhes-do-usuario'
})
