import { selector } from 'recoil'
import { scheduledContentState } from '../scheduled-content-state'

export const scheduledContentTypeSelector = selector({
  key: 'scheduledContentTypeSelector',
  get: ({ get }) => {
    return get(scheduledContentState).type
  },
  set: ({ get, set }, newValue) => {
    set(scheduledContentState, {
      ...get(scheduledContentState),
      type: newValue
    })
  }
})
