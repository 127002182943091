import React, { useContext, useEffect, useState } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import useStyles from '../styles'
import { ConnectionContext } from '../../../contexts/ConnectionContext.js'
import AvaConnection from '../../../services/avaConnection'
import { GeneralContext } from '../../../contexts/GeneralContext'
import Messages from '../../../helpers/messages'

function DialogDelete({ showDialog, setShowDialog, subjects }) {
  const classes = useStyles()
  const location = useLocation()

  const { connection, updateConnection, setSaving, saving } =
    useContext(ConnectionContext)

  const { setSuccessMessage } = useContext(GeneralContext)

  const save = async () => {
    try {
      setSaving(false)

      const selectedSubjectIds = subjects
        .filter((subject) => {
          return subject.checked === false
        })
        .map((item) => item.id)

      const payload = {
        modules_id: selectedSubjectIds
      }

      const arrayNumber = selectedSubjectIds.map((id) => parseInt(id, 10))

      await AvaConnection.saveDisciplines(connection.client_id, payload)
      setSuccessMessage(Messages.subjectsDelete.success)
      updateConnection({ modules: arrayNumber })
    } finally {
      setSaving(true)
      setShowDialog(false)
    }
  }

  const [ies, setIes] = useState({})

  useEffect(() => {
    setIes(location.state.ies)
  }, [location])

  return (
    <>
      {showDialog && (
        <Dialog
          className={classes.root}
          title=" Excluir conteúdos da Instituição"
          secondaryButton="Cancelar"
          primaryButton="Excluir"
          handleConfirm={() => {
            save()
          }}
          disabled={saving}
          size="md"
          handleClose={() => {
            setShowDialog()
          }}
          open={showDialog}
          label="Sim, tenho certeza!"
          alert
        >
          <p>
            <strong>
              Você tem certeza que deseja excluir o conteúdo da{' '}
              {ies.client_name}?{' '}
            </strong>
            Lembre-se que ao excluir, a IES perderá acesso aos conteúdos.
          </p>
        </Dialog>
      )}
    </>
  )
}
export default DialogDelete

DialogDelete.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  subjects: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired
    })
  ).isRequired
}
