import { ECOMMERCE_FORM_FIELDS } from '../entities'

const { IMG_CHANGED, IMG_BINARY } = ECOMMERCE_FORM_FIELDS

/**
 * Essa função representa o lastreamento da regra local que a imagem do curso
 * precisa ter seu novo valor enviado ao servidor ao deletar o valor passado da url
 *
 * @param {Object} data - Valores do Formulário de edição de curso no ecommerce
 * @returns true se a url imagem for vazia
 */
const isChangingCourseImage = (data) => {
  return data[IMG_CHANGED]
}

export const verifyAndPluckIfImageChanged = (data) => {
  if (isChangingCourseImage(data)) {
    return { image: data[IMG_BINARY] }
  }

  return {
    image: null
  }
}
