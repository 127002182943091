import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  editor: {
    backgroundColor: ' #fff',
    minHeight: 'calc(321px - 84px)',
    border: `1px solid #E0E0E0`,
    borderRadius: '8px',
    padding: '0 14px',
    transition: 'border-color 200ms linear',
    '&:hover': {
      border: `1px solid ${theme.palette.text.secondary}`
    },
    '&:focus-within': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  wrapper: {
    width: '100%',
    backgroundColor: '#fff'
  },
  toolbar: {
    border: `1px solid #E0E0E0`,
    borderRadius: '8px',
    transition: 'border-color 200ms linear',
    '&:hover': {
      border: `1px solid ${theme.palette.text.secondary}`
    }
  },
  toggleButton: {
    paddingLeft: 16,
    marginBottom: 16
  },
  hide: {
    display: 'none'
  }
}))

export default useStyles
