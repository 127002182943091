import { getSessionToken } from '../../../auth'
import { CardComplementaryMaterialAdapter } from '../adapters/card-complementary-material'
import httpClient from '../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../utils/response-handlers'

const getComplementaryMaterials = async (courseId) => {
  const path = `/contents/api/v1/backoffice/complementary_material/?course_id=${courseId}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)
  const responseJson = await getResquestResponseHandler(response)

  return CardComplementaryMaterialAdapter.listInbound(responseJson)
}

const getComplementaryMaterialById = async (id) => {
  const path = `/contents/api/v1/backoffice/complementary_material/${id}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)
  const responseJson = await getResquestResponseHandler(response)

  return CardComplementaryMaterialAdapter.inbound(responseJson)
}

export { getComplementaryMaterials, getComplementaryMaterialById }
