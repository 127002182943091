import { COURSE_SERVICES_FOR_DISCOUNTS_FIELDS as FIELD } from '../../courses-services'

export const HEADERS_CREATE_CAMPAIGN = {
  [FIELD.NAME]: 'Nome do Produto',
  [FIELD.SKU]: 'SKU do Produto',
  [FIELD.FROM_VALUE]: 'Preco DE (formato: 1999,99)',
  [FIELD.TO_VALUE]: 'Preco POR (desconto, formato: 1500,00) ',
  [FIELD.PERCENT_DISCOUNT]: '% de desconto',
  [FIELD.START_DATE]: 'Data inicio (formato: 01/12/2022)',
  [FIELD.END_DATE]: 'Data fim (formato: 31/12/2022)'
}
