export const VOUCHER_FIELDS = Object.freeze({
  CAMPAIGN_NAME: 'name',
  DISCOUNT_VALUE: 'discountValue',
  END_DATE: 'endDate',
  ID: 'key',
  PREFIX: 'code',
  START_DATE: 'startDate',
  STATUS: 'status',
  USES_PER_VOUCHER: 'usesPerVoucher',
  VOUCHER_AMOUNT: 'voucherAmount',
  VOUCHER_TYPE: 'type',
  CAMPAIGN_VOUCHERS_LIST: 'campaignVoucherList',
  TOTAL_ACTIVATED: 'totalActivated',
  TOTAL_AVAILABLE: 'totalAvailable'
})
