/**
 *
 * @param {Date} date
 * @returns um nova instancia de Date com os valores de tempo setados parada meio dia
 * para evitar problemas com servidor
 *
 * ### Exemplo
 * ```js
 * const date1 = new Date() // Date Tue Mar 22 2022 11:01:32 GMT-0300 (Brasilia Standard Time)
 * const date2  = onlyDatePipe(date1) // Date Tue Mar 22 2022 12:00:00 GMT-0300 (Brasilia Standard Time)
 *
 * date1 === date2 // false
 * ```
 */
export const onlyDatePipe = (date) => {
  if (!date) return date

  return new Date(date.setHours(12, 0, 0, 0))
}
