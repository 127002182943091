import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { putRequestResponseHandler } from '../../../utils/response-handlers'
import { CourseRelationshipFields as F } from '../../remote-fields/course-relationship-fields'

const outboundAdapter = (topicRelationId, isActivating, changeAll) => ({
  id: topicRelationId,
  available: String(isActivating),
  change_all: String(changeAll)
})

/**
 * Caso de uso para habilitar e desabilitar o acesso de um topico de um curso
 * @param {string|number} topicRelationId - identificador da relação do curso com tópico
 * @param {boolean} isActivating - status para qual o acesso ao curso vai ser modificado
 * @param {boolean} changeAll - indica se a alteração deve ocorrer apenas no curso de origem ou em todos
 */
export const toggleCourseAccess = (
  topicRelationId,
  isActivating,
  changeAll
) => {
  const payload = outboundAdapter(topicRelationId, isActivating, changeAll)

  const url = '/offers/api/v1/change_course_relationship_status'

  return new Promise((resolve, reject) => {
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpClient
      .put(url, payload, headers)
      .then((response) => putRequestResponseHandler(response))
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
}
