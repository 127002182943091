import { generateFormError } from '../../general/generate-form-errors'
import {
  ECOMMERCE_FORM_FIELDS as F,
  ECOMMERCE_TABS_FIELDS as TAB
} from '../entities'

const formFormat = {
  [F.ACCESS_WINDOW]: '',
  [F.CATEGORY]: '',
  [F.COURSE_LENGTH]: '',
  [F.COURSE_TABS]: [
    {
      [TAB.CONTENT]: '',
      [TAB.ID]: null,
      [TAB.ORDER]: 1,
      [TAB.TITLE]: ''
    }
  ],
  [F.DESCRIPTION]: '',
  [F.ID]: null,
  [F.IMG_BINARY]: null,
  [F.IMG_URL]: '',
  [F.IMG_CHANGED]: false,
  [F.KEY_WORDS]: '',
  [F.NAME]: '',
  [F.SKU]: '',
  [F.SUB_CATEGORY]: '',
  [F.URL]: '',
  [F.VALUE]: ''
}

export const ecommerceDefaultForm = () => {
  return {
    values: {
      ...formFormat
    },
    errors: generateFormError(formFormat)
  }
}
