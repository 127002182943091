export const REGISTER_ELEARNING_FIELDS = Object.freeze({
  KEY: { value: 'key', label: 'identification' },
  SUBJECT: { value: 'subject', label: 'Disciplina' },
  ADDED_IN: { value: 'added_in', label: 'Curso de origem' },
  SKU: { value: 'sku', label: 'SKU do Curso' },
  UPDATED_AT: { value: 'updated_at', label: 'Data de alteração' },
  IS_AVAILABLE: { value: 'is_available', label: 'Disponibilizar para o aluno' },
  FULL_POSTED: { value: 'full_posted', label: '100% postada' },
  TYPE: { value: 'type', label: 'Tipo' },
  HIERARCHY: { value: 'hierarchy', label: 'Hierarquia' },
  CHILDREN: { value: 'children', label: 'Filhos' },
  PATH: { value: 'path', label: 'caminho' },
  PARENT_ID: { value: 'parent_id', label: 'ID Pai' },
  RELATION_ID: {
    value: 'relationId',
    label: 'Identificador da relação com curso'
  },
  ORIGIN_COURSE_ID: {
    value: 'origin_course_id',
    label: 'identificador do curso original'
  }
})
