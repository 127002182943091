import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 39,
  },
  titleH3: {
    marginBottom: '24px',
    color: theme.palette.text.primary,
    marginTop: '22px',
    fontSize: '48px'
  }
}))

export default useStyles
