/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react'

import { Menu, MenuItem } from '@material-ui/core'
import {
  CreateNewFolder as CreateNewFolderIcon,
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon
} from '@material-ui/icons'

import useStyles from './styles'
import { ContentsContext } from '../../../../contexts/ContentsContext'

import CreateFolderDialog from '../CreateFolderDialog'
import UploadDirectory from '../UploadDirectory'
import UploadFile from '../UploadFile'

function MenuNew() {
  const {
    contentsPath,
    toggleCreateFolderForm,
    showingContentMenu,
    toggleShowingContentMenu
  } = useContext(ContentsContext)

  const classes = useStyles()

  const handleClick = () => {
    toggleCreateFolderForm(true)
    toggleShowingContentMenu(null)
  }

  return (
    <div>
      <Menu
        anchorEl={showingContentMenu}
        open={Boolean(showingContentMenu)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => toggleShowingContentMenu(null)}
      >
        <label htmlFor="uploadDirectory">
          {contentsPath.length !== 0 ? (
            <div>
              <label htmlFor="uploadFile">
                <UploadFile />
                <MenuItem>
                  <InsertDriveFileIcon className={classes.icon} />
                  Carregar arquivos
                </MenuItem>
              </label>
            </div>
          ) : (
            <div />
          )}
          <UploadDirectory />
          <MenuItem>
            <FolderIcon className={classes.icon} />
            Carregar Pasta
          </MenuItem>
        </label>
        <MenuItem onClick={handleClick}>
          <CreateNewFolderIcon className={classes.icon} />
          Criar Pasta
        </MenuItem>
      </Menu>

      <CreateFolderDialog />
    </div>
  )
}

export default MenuNew
