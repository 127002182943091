import Papa from 'papaparse'
import { COURSE_SERVICES_FOR_DISCOUNTS_FIELDS as FIELD } from '../../../domain/courses-services/course-services-for-discount-fields'
import {
  SERVICE_REMOTE_FIELDS as REMOTE,
  COURSE_SERVICES_PLANS_FIELDS as PLANS_REMOTE
} from '../remote-fields/courses-services-fields'
import { parseRemoteDecimal } from '../../utils/inbound-utils'
import { HEADERS_CREATE_CAMPAIGN as HEADERS } from '../../../domain/promotional-content/discounts-campaign'

const inbound = (data) => {
  const rows = data
    .filter((item) => item[REMOTE.ACTIVE] && item[REMOTE.PLANS].length > 0)
    .sort((a, b) => a[REMOTE.SKU] > b[REMOTE.SKU])
    .map((item, index) => {
      const firstPlan = item[REMOTE.PLANS][0]
      const rowNumber = index + 2

      return {
        [HEADERS[FIELD.NAME]]: item[REMOTE.NAME],
        [HEADERS[FIELD.SKU]]: item[REMOTE.ID],
        [HEADERS[FIELD.FROM_VALUE]]: `=TEXT(${parseRemoteDecimal(
          firstPlan[PLANS_REMOTE.PRICE]
        )}; "0.00")`,
        [HEADERS[FIELD.TO_VALUE]]: '',
        [HEADERS[
          FIELD.PERCENT_DISCOUNT
        ]]: `=IF(ISBLANK(D${rowNumber});"";TEXT(1-D${rowNumber}/C${rowNumber}; "0.00%"))`,
        [HEADERS[FIELD.START_DATE]]: '',
        [HEADERS[FIELD.END_DATE]]: ''
      }
    })

  return Papa.unparse(rows)
}

export const CourseDiscountCSVAdapter = {
  inbound
}
