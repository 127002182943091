import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination
} from '@material-ui/core'

import { SubjectsTableRow } from './SubjectsTableRow'
import { SubjectsTablePaginationActions } from './SubjectsTablePaginationActions'

export function SubjectsTable({
  data,
  perPage,
  setPerPage,
  page,
  setPage,
  subjectCount
}) {
  const [openRowId, setOpenRowId] = useState(null)

  const handleChangePage = (_, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Disciplina</TableCell>
            <TableCell align="left">Curso de origem</TableCell>
            <TableCell align="left">SKU do curso</TableCell>
            <TableCell align="left">Data de criação</TableCell>
            <TableCell align="left">Data de alteração</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((subject) => {
            const isRowOpen = subject.id === openRowId

            return (
              <SubjectsTableRow
                key={subject.id}
                subject={subject}
                onExpand={() => {
                  if (isRowOpen) {
                    setOpenRowId(null)
                  } else {
                    setOpenRowId(subject.id)
                  }
                }}
                isOpen={isRowOpen}
              />
            )
          })}
        </TableBody>

        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            colSpan={6}
            count={subjectCount}
            rowsPerPage={perPage}
            page={page - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={SubjectsTablePaginationActions}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

SubjectsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf({
      name: PropTypes.string.isRequired,
      courseOrigin: PropTypes.string.isRequired,
      courseSku: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      reuseCourses: PropTypes.arrayOf(
        PropTypes.objectOf({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          reuseCourseName: PropTypes.string.isRequired,
          courseSku: PropTypes.string.isRequired,
          createdAt: PropTypes.string.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired,
  perPage: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  subjectCount: PropTypes.number.isRequired
}
