/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState, useRef } from 'react'
import { debounce } from 'lodash'

import {
  Typography,
  Divider,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { Button, Table } from '@equipe-ninja/saraiva-ui'

import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { SnackbarOutlet } from '../../components/SnackbarOutlet'
import Loading from '../../components/Loading'

import DialogForm from './DialogForm'
import EditForm from './EditForm'
import Messages from '../../helpers/messages'

import { GeneralContext } from '../../contexts/GeneralContext'
import { ConnectionsContext } from '../../contexts/ConnectionsContext'
import IntegrationsService from '../../services/integrationsService'

import useStyles, { ContainerButton, PenIcon } from './styles'

function BdsContent() {
  const classes = useStyles()
  const node = useRef()

  const {
    showingConnectionForm,
    toggleConnectionForm,
    connections,
    setConnections
  } = useContext(ConnectionsContext)
  const {
    loading,
    setLoading,
    productSelected,
    setProduct,
    setErrorMessage,
    updated,
    setUpdated
  } = useContext(GeneralContext)

  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [dropDown, setDropDown] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('name')
  const [filteredConnections, setFilteredConnections] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [formControl, setFormControl] = useState('initial')

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setDropDown(false)
  }

  useEffect(() => {
    if (dropDown) {
      document.addEventListener('mousedown', handleClick)
    } else {
      document.removeEventListener('mousedown', handleClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [dropDown])

  useEffect(() => {
    async function fetchConnections() {
      try {
        setLoading(true)
        setUpdated(false)

        let response = await IntegrationsService.listIntegrationsByTypeAndApp(
          'jwt_integration',
          productSelected.token
        )

        if (!response.ok) {
          const errors = await response.json()
          throw errors
        }

        response = await response.json()

        setConnections(response.data)
      } catch (error) {
        console.log(error)
        setProduct({})
        setErrorMessage(Messages.fetchConnections.error)
      } finally {
        setLoading(false)
      }
    }

    fetchConnections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productSelected.token,
    setConnections,
    setErrorMessage,
    setLoading,
    setProduct,
    updated
  ])

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    searchValue.length > 0 ? filterConnections() : setFilteredConnections([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const filterConnections = () => {
    if (selectedFilter === 'name') {
      setFilteredConnections(
        connections.filter((con) =>
          con.client_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      )
    }
    if (selectedFilter === 'code') {
      setFilteredConnections(
        connections.filter((con) =>
          con.service.sku
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      )
    }
    if (selectedFilter === 'cnpj') {
      setFilteredConnections(
        connections.filter((con) =>
          con.client_id_number
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      )
    }
  }

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
      setDropDown(false)
    }, 1000)
  ).current

  const handleSearchValue = (e) => {
    setSearchTerm(e.target.value)
    delayedQuery(e.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
  }

  function renderEditButton() {
    return selectedRows.length > 0
  }

  const renderTable = () => {
    if (loading) {
      return <Loading />
    }

    return (
      <>
        {renderEditButton() && (
          <ContainerButton>
            <Button
              startIcon={<PenIcon disabled={selectedRows.length > 1} />}
              color="primary"
              variant="outlined"
              disabled={selectedRows.length > 1}
              text="Botão"
              className={classes.btnEdit}
              onClick={() => {
                setFormControl('jwt')
                toggleConnectionForm(true)
              }}
            >
              Editar
            </Button>
          </ContainerButton>
        )}

        <Table
          displayRowCheckbox
          disableCheckAll
          onCheckRows={(rows) => {
            setSelectedRows(rows)
          }}
          selectedRows={selectedRows}
          cells={[
            <p className={classes.maxWidthColumn}>Nome da instituição</p>,
            <p className={classes.maxWidthColumn}>Chave da biblioteca</p>,
            <p className={classes.maxWidthColumn}>CNPJ da instituição</p>,
            <p className={classes.maxWidthColumn}>Chave JWT</p>
          ]}
          rows={
            searchTerm.length > 0
              ? filteredConnections.map((conn) => {
                  return {
                    key: conn.id,
                    name: (
                      <p className={classes.maxWidthColumn}>
                        {conn.client_name}
                      </p>
                    ),
                    code: (
                      <p className={classes.maxWidthColumn}>
                        {conn.service.sku}
                      </p>
                    ),
                    id_number: (
                      <p className={classes.maxWidthColumn}>
                        {conn.client_id_number}
                      </p>
                    ),
                    secret: (
                      <p className={classes.maxWidthColumn}>
                        {conn.integration_data.secret_key}
                      </p>
                    )
                  }
                })
              : connections.map((conn) => {
                  return {
                    key: conn.id,
                    name: (
                      <p className={classes.maxWidthColumn}>
                        {conn.client_name}
                      </p>
                    ),
                    code: (
                      <p className={classes.maxWidthColumn}>
                        {conn.service.sku}
                      </p>
                    ),
                    id_number: (
                      <p className={classes.maxWidthColumn}>
                        {conn.client_id_number}
                      </p>
                    ),
                    secret: (
                      <p className={classes.maxWidthColumn}>
                        {conn.integration_data.secret_key}
                      </p>
                    )
                  }
                })
          }
        />
      </>
    )
  }

  return (
    <>
      <Typography variant="h4" className={`${classes.text} ${classes.textH4}`}>
        Biblioteca Digital Saraiva
      </Typography>
      <div className={classes.buttons}>
        <Button
          onClick={() => {
            setFormControl('Cad')
            toggleConnectionForm(true)
          }}
          color="primary"
          variant="contained"
        >
          Cadastrar instituição
        </Button>
      </div>
      <Divider className={classes.divider} />

      <Typography className={classes.textH5}>Bibliotecas</Typography>
      {!loading && (
        <div
          className={classes.searchWrapper}
          onClick={() => setDropDown(true)}
        >
          <SearchBar
            value={searchTerm}
            onChange={handleSearchValue}
            placeholder="Buscar por:"
            size="full"
            clearSearch={clearSearch}
            goBackSearch={clearSearch}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          />
        </div>
      )}
      {dropDown && (
        <Paper ref={node} className={classes.paper}>
          <p style={{ margin: '0px 0px 4px 0px' }}>Filtrar por:</p>
          <RadioGroup
            style={{ paddingLeft: 12, margin: 0 }}
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
          >
            <FormControlLabel
              value="name"
              control={<Radio size="small" />}
              label="Nome da instituição"
            />
            <FormControlLabel
              value="code"
              control={<Radio color="primary" size="small" />}
              label="Chave da biblioteca"
            />
            <FormControlLabel
              value="cnpj"
              control={<Radio size="small" />}
              label="CNPJ da instituição"
            />
          </RadioGroup>
        </Paper>
      )}

      {renderTable()}
      <SnackbarOutlet />
      {showingConnectionForm && (
        <>
          {formControl === 'jwt' && (
            <EditForm
              connectionId={selectedRows[0].key}
              client_name={selectedRows[0].name.props.children}
              client_id_number={selectedRows[0].id_number.props.children}
              sku={selectedRows[0].code.props.children}
              handleConfirm={() => setFormControl('Confirm')}
            />
          )}
          {formControl === 'Cad' && <DialogForm />}
        </>
      )}
    </>
  )
}

export default BdsContent
