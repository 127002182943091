import React, { useContext, useRef } from 'react'
import { UploadBar } from '@equipe-ninja/saraiva-ui'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { UploadContext } from '../../../../contexts/UploadContext'
import { uploadCourseSubject } from '../../../../services/courses'

const UploadButton = ({ buttonTitle, courseId, onUpload, data, disabled }) => {
  const {
    toggleShowingUploadBar,
    showingUploadBar,
    countFileUpload,
    totalFiles,
    setTotalFiles,
    errorsBar,
    clearState,
    clearTreePath,
    setCountFileUpload,
    setErrorsBar
  } = useContext(UploadContext)

  const inputRef = useRef(null)

  const handleCloseUploadBar = () => {
    clearState()
  }
  const handleCancelUploadBar = () => {
    clearState()
  }

  const sendFile = async (file) => {
    setTotalFiles(1)
    setCountFileUpload(0)

    await uploadCourseSubject(courseId, file, data)
      .then(() => {
        clearTreePath()
        onUpload()
      })
      .catch((err) => {
        setErrorsBar([err?.message])
      })
      .finally(() => {
        setCountFileUpload(1)
      })
  }

  const handleReloadFiles = async () => {
    await sendFile(inputRef.current.files[0])
  }

  const uploadFiles = async (evt) => {
    await clearState()
    setTotalFiles(1)
    toggleShowingUploadBar(true)

    const file = evt.target.files[0]

    sendFile(file)
  }

  return (
    <>
      <label htmlFor={disabled ? 'disabled' : 'contained-button-file'}>
        <Button
          color="primary"
          variant="outlined"
          component="label"
          startIcon={<AddIcon />}
          disabled={disabled}
        >
          {buttonTitle}
          <input
            ref={inputRef}
            type="file"
            accept=".zip"
            style={{ display: 'none' }}
            onChange={uploadFiles}
            id="contained-button-file"
          />
        </Button>
      </label>
      {showingUploadBar && (
        <UploadBar
          progressFileUpload={countFileUpload}
          totalUploadFiles={totalFiles}
          errorsList={errorsBar}
          handleCancelUpload={handleCancelUploadBar}
          handleCloseUpload={handleCloseUploadBar}
          reloadErrorsUpload={handleReloadFiles}
        />
      )}
    </>
  )
}

UploadButton.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onUpload: PropTypes.func.isRequired,
  data: PropTypes.shape({
    subject: PropTypes.number,
    theme: PropTypes.number,
    class: PropTypes.number
  }),
  disabled: PropTypes.bool
}

UploadButton.defaultProps = {
  data: {},
  disabled: false
}

export default UploadButton
