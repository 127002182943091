import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    padding: '24px 24px 8px 24px'
  },
  input: {
    display: 'flex',
    flexFlow: 'column',
    margin: '28px 0 20px 0'
  },
  buttonDialogAction: {
    padding: '0'
  },
  button: {
    margin: '0',
    height: '42px'
  },
  titleDialog: {
    padding: '0',
    fontSize: '20px',
    marginBottom: '20px'
  },
  icon: { marginRight: '24px' },
  wrappedInput: {
    display: 'flex',
    flexFlow: 'column',
    margin: '28px 0 20px 0'
  },
  labelInput: {
    margin: '12px 0 12px 0',
    padding: '0 0 0 0'
  }
})

export default useStyles
