import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 33,
    padding: 32
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: theme.palette.text.primary
  },
  subtitle: {
    color: theme.palette.text.secondary,
    margin: 0,
    marginBottom: 30
  }
}))

export default useStyles
