/* eslint no-empty: "error" */
import React, { useState, useContext } from 'react'
import { TextInput, Dialog } from '@equipe-ninja/saraiva-ui'

import useStyles from './styles'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import IntegrationsService from '../../../../services/integrationsService'
import Messages from '../../../../helpers/messages'

function ConfiForm() {
  const {
    connection,
    saving,
    setSaving,
    updateConnection,
    toggleConfigForm,
    isCanvas
  } = useContext(ConnectionContext)

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const canvasIdPlatform = 'https://canvas.instructure.com'
  const INITIAL_STATE = {
    client_id_lms: connection.integration_data.client_id_lms || '',
    iss: isCanvas ? canvasIdPlatform : connection.integration_data.iss || '',
    institution_url: connection.integration_data.key_set_url
      ? connection.integration_data.key_set_url.split('api')[0]
      : '',
    key_set_url: connection.integration_data.key_set_url || '',
    auth_token_url: connection.integration_data.auth_token_url || '',
    auth_login_url: connection.integration_data.auth_login_url || ''
  }

  const classes = useStyles()
  const [data, setData] = useState(INITIAL_STATE)

  const updateField = (evt) => {
    setData({
      ...data,
      [evt.target.name]: evt.target.value
    })
  }

  const updateCanvasFields = (evt) => {
    setData({
      ...data,
      institution_url: evt.target.value,
      key_set_url: `${evt.target.value}/api/lti/security/jwks`,
      auth_token_url: `${evt.target.value}/login/oauth2/token`,
      auth_login_url: `${evt.target.value}/api/lti/authorize_redirect`
    })
  }

  const clearState = () => {
    setData(INITIAL_STATE)
  }

  const clearStateAndCloseForm = () => {
    clearState()
    toggleConfigForm(false)
  }

  const isValidForm = () => {
    return (
      data.client_id_lms &&
      data.iss &&
      data.key_set_url &&
      data.auth_token_url &&
      data.auth_login_url
    )
  }

  const save = async () => {
    try {
      setSaving(true)

      const response = await IntegrationsService.updateIntegration(
        connection.id,
        data
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()
      updateConnection({
        ...data,
        ...result
      })

      setSuccessMessage(Messages.configForm.success)
      toggleConfigForm(false)
    } catch (err) {
      setErrorMessage(Messages.configForm.error)
      // eslint-disable-next-line no-console
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      open
      handleClose={() => clearStateAndCloseForm()}
      handleConfirm={() => save()}
      title="Configurar conexão"
      primaryButton="Salvar"
      secondaryButton="Cancelar"
      isDisabled={saving || !isValidForm()}
    >
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="ID do cliente"
            required
            name="client_id_lms"
            value={data.client_id_lms}
            onChange={updateField}
            size="full"
          />
        </div>

        <div className={`${classes.wrapper} ${classes.root}`}>
          <TextInput
            color="primary"
            label="ID da plataforma"
            required
            name="iss"
            value={data.iss}
            onChange={updateField}
            readOnly={isCanvas}
            size="full"
          />
        </div>

        {isCanvas && (
          <div className={classes.wrapper}>
            <TextInput
              color="primary"
              label="URL da Instituição"
              required
              name="institution_url"
              value={data.institution_url}
              onChange={updateCanvasFields}
              size="full"
            />
          </div>
        )}

        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="URL do conjunto de chaves públicas"
            required
            name="key_set_url"
            value={data.key_set_url}
            onChange={updateField}
            readOnly={isCanvas}
            size="full"
          />
        </div>

        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="URL do token de acesso"
            required
            name="auth_token_url"
            value={data.auth_token_url}
            onChange={updateField}
            readOnly={isCanvas}
            size="full"
          />
        </div>

        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="URL de solicitação de autenticação"
            required
            name="auth_login_url"
            value={data.auth_login_url}
            onChange={updateField}
            readOnly={isCanvas}
            size="full"
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ConfiForm
