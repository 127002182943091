import { TextInput, Alert } from '@equipe-ninja/saraiva-ui'
import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'
import { BANNER_CONECTA_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { UploadProvider } from '../../../../contexts/UploadContext'
import { ImageDimension } from '../../../../helpers/imageDimension'
import {
  BANNER_SIZES_CONECTA,
  bannersSizersConecta
} from '../../../../domain/promotional-content/banners'
import { ButtonUpload } from './ButtonUpload'

export function FormBannerHome({ formState, formErrors, onChange }) {
  const classes = useStyles()

  const { TITLE, URL, IMG_WEB, IMG_TABLET, IMG_MOBILE } = FIELDS

  const [imageDesktopError, setImageDesktopError] = useState(false)
  const [imageTabletError, setImageTabletError] = useState(false)
  const [imageMobileError, setImageMobileError] = useState(false)

  async function handleFileChange(field, image, type) {
    const dimension = bannersSizersConecta(type)
    const sizeIsValid = await ImageDimension.imageSizeValidator(
      image,
      dimension
    )

    onChange(field, sizeIsValid ? image : null)

    if (type === BANNER_SIZES_CONECTA.WEB) {
      setImageDesktopError(!sizeIsValid)
    } else if (type === BANNER_SIZES_CONECTA.TABLET) {
      setImageTabletError(!sizeIsValid)
    } else {
      setImageMobileError(!sizeIsValid)
    }
  }

  const handleInputChange = (field, value) => {
    onChange(field, value)
  }

  return (
    <>
      <div className={classes.contentUpdate}>
        <div className={classes.contentTitle}>Banner página inicial</div>
        <TextInput
          value={formState[TITLE.value]}
          color="primary"
          error={!!formErrors[`${TITLE.value}`]}
          helperText={
            <>
              {!!formErrors[`${TITLE.value}`] && (
                <div>{formErrors[`${TITLE.value}`]}</div>
              )}
            </>
          }
          onChange={(e) => handleInputChange(TITLE.value, e.target.value)}
          size="full"
          label="Título do banner"
          required
        />
        <TextInput
          value={formState[URL.value]}
          color="primary"
          error={!!formErrors[`${URL.value}`]}
          helperText={
            <>
              {!!formErrors[`${URL.value}`] && (
                <div>{formErrors[`${URL.value}`]}</div>
              )}
              <div>
                Inserir URL em que o usuário irá ser redirecionado ao clicar no
                banner
              </div>
            </>
          }
          onChange={(e) => handleInputChange(URL.value, e.target.value)}
          size="full"
          label="URL de redirecionamento do banner"
          required
        />
        <div className={classes.contentDisplayLeft} style={{ marginTop: 0 }}>
          <div className={classes.boxImage}>
            <span>Utilizar JPG ou PNG no tamanho 936x172px</span>
            <UploadProvider>
              <ButtonUpload
                field={IMG_WEB.value}
                image={formState[IMG_WEB.value]}
                type={BANNER_SIZES_CONECTA.WEB}
                // eslint-disable-next-line react/jsx-no-bind
                onUpdate={handleFileChange}
                // eslint-disable-next-line react/jsx-no-bind
                onUpload={handleFileChange}
              />
            </UploadProvider>
            {imageDesktopError && (
              <Alert severity="error">
                Banner web anexado não corresponde ao tamanho indicado
                <Typography className={classes.alertBanner}>
                  *Alteração obrigatória para salvar a campanha
                </Typography>{' '}
              </Alert>
            )}
            {formErrors[`${IMG_WEB.value}`] && (
              <Alert severity="error">{formErrors[`${IMG_WEB.value}`]}</Alert>
            )}
          </div>
          <div className={classes.boxImage}>
            <span>Utilizar JPG ou PNG no tamanho 544x172px</span>
            <UploadProvider>
              <ButtonUpload
                field={IMG_TABLET.value}
                image={formState[IMG_TABLET.value]}
                type={BANNER_SIZES_CONECTA.TABLET}
                // eslint-disable-next-line react/jsx-no-bind
                onUpload={handleFileChange}
                // eslint-disable-next-line react/jsx-no-bind
                onUpdate={handleFileChange}
              />
            </UploadProvider>
            {imageTabletError && (
              <Alert severity="error">
                Banner tablet anexado não corresponde ao tamanho indicado
                <Typography className={classes.alertBanner}>
                  *Alteração obrigatória para salvar a campanha
                </Typography>
              </Alert>
            )}
            {formErrors[`${IMG_TABLET.value}`] && (
              <Alert severity="error">
                {formErrors[`${IMG_TABLET.value}`]}
              </Alert>
            )}
          </div>
          <div className={classes.boxImage}>
            <span>Utilizar JPG ou PNG no tamanho 422x172px</span>
            <UploadProvider>
              <ButtonUpload
                field={IMG_MOBILE.value}
                image={formState[IMG_MOBILE.value]}
                type={BANNER_SIZES_CONECTA.MOBILE}
                // eslint-disable-next-line react/jsx-no-bind
                onUpload={handleFileChange}
                // eslint-disable-next-line react/jsx-no-bind
                onUpdate={handleFileChange}
              />
            </UploadProvider>
            {imageMobileError && (
              <Alert severity="error">
                Banner mobile anexado não corresponde ao tamanho indicado
                <Typography className={classes.alertBanner}>
                  *Alteração obrigatória para salvar a campanha
                </Typography>{' '}
              </Alert>
            )}
            {formErrors[`${IMG_MOBILE.value}`] && (
              <Alert severity="error">
                {formErrors[`${IMG_MOBILE.value}`]}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

FormBannerHome.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired
}
