import { ALL_QUESTIONS_DATABASE_FIELDS as A } from '../remote-fields/all-database-questions'
import { ALL_QUESTIONS_DATABASE_FIELDS as Q } from '../../../domain/courses/register-elearning-page/all-database-questions'

const inbound = (remoteData) => {
  return remoteData.map((data) => {
    return {
      [Q.COURSE_ID]: data[A.COURSE_ID],
      [Q.COURSE_TITLE]: data[A.COURSE_TITLE],
      [Q.EXERCISES_COUNT]: data[A.EXERCISES_COUNT],
      [Q.SUBJECT_ID]: data[A.SUBJECT_ID],
      [Q.SUBJECT_TITLE]: data[A.SUBJECT_TITLE]
    }
  })
}

export const AllQuestionsSubjectAdapter = { inbound }
