import links from '../components/config/h5p/links'
import scripts from '../components/config/h5p/scripts'

export default function createH5pIntegration(file) {
  const script = document.createElement('script')

  const h5pintegration = `window.H5PIntegration = ${JSON.stringify({
    baseUrl: `${process.env.REACT_APP_FUSION_ORIGIN}/h5p/`,
    url: `${process.env.REACT_APP_FUSION_ORIGIN}/h5p/${decodeURIComponent(
      file
    )}`,
    urlLibraries: '',
    postUserStatistics: true,
    ajax: {
      setFinished: `${process.env.REACT_APP_FUSION_ORIGIN}/contents/api/v1/lti/add_activity_score`,
      contentUserData: `${process.env.REACT_APP_FUSION_ORIGIN}/ajax/coMuIJHdFNyaJ6yTWMdd6H8KzaJSlbSv0MqFQiec/content-user-data/:dataType/:subContentId`,
      xapi: `${process.env.REACT_APP_FUSION_ORIGIN}/content/:statements`,
      xAPIResult: `${process.env.REACT_APP_FUSION_ORIGIN}/content/users/1291063473143835968/attempts`
    },
    saveFreq: false,
    siteUrl: 'https://conteudosets.h5p.com',
    l10n: {
      H5P: {
        fullscreen: 'Fullscreen',
        disableFullscreen: 'Disable fullscreen',
        download: 'Download',
        copyrights: 'Rights of use',
        embed: 'Embed',
        size: 'Size',
        showAdvanced: 'Show advanced',
        hideAdvanced: 'Hide advanced',
        advancedHelp:
          'Include this script on your website if you want dynamic sizing of the embedded content:',
        copyrightInformation: 'Rights of use',
        close: 'Close',
        title: 'Title',
        author: 'Author',
        year: 'Year',
        source: 'Source',
        license: 'License',
        thumbnail: 'Thumbnail',
        noCopyrights: 'No copyright information available for this content.',
        reuse: 'Reuse',
        reuseContent: 'Reuse Content',
        reuseDescription: 'Reuse this content.',
        downloadDescription: 'Download this content as a H5P file.',
        copyrightsDescription: 'View copyright information for this content.',
        embedDescription: 'View the embed code for this content.',
        h5pDescription: 'Visit H5P.org to check out more cool content.',
        contentChanged: 'This content has changed since you last used it.',
        startingOver: "You'll be starting over.",
        by: 'by',
        showMore: 'Show more',
        showLess: 'Show less',
        subLevel: 'Sublevel',
        confirmDialogHeader: 'Confirm action',
        confirmDialogBody:
          'Please confirm that you wish to proceed. This action is not reversible.',
        cancelLabel: 'Cancel',
        confirmLabel: 'Confirm',
        licenseU: 'Undisclosed',
        licenseCCBY: 'Attribution',
        licenseCCBYSA: 'Attribution-ShareAlike',
        licenseCCBYND: 'Attribution-NoDerivs',
        licenseCCBYNC: 'Attribution-NonCommercial',
        licenseCCBYNCSA: 'Attribution-NonCommercial-ShareAlike',
        licenseCCBYNCND: 'Attribution-NonCommercial-NoDerivs',
        licenseCC40: '4.0 International',
        licenseCC30: '3.0 Unported',
        licenseCC25: '2.5 Generic',
        licenseCC20: '2.0 Generic',
        licenseCC10: '1.0 Generic',
        licenseGPL: 'General Public License',
        licenseV3: 'Version 3',
        licenseV2: 'Version 2',
        licenseV1: 'Version 1',
        licensePD: 'Public Domain',
        licenseCC010: 'CC0 1.0 Universal (CC0 1.0) Public Domain Dedication',
        licensePDM: 'Public Domain Mark',
        licenseC: 'Copyright',
        contentType: 'Content Type',
        licenseExtras: 'License Extras',
        changes: 'Changelog',
        contentCopied: 'Content is copied to the clipboard',
        connectionLost:
          'Connection lost. Results will be stored and sent when you regain connection.',
        connectionReestablished: 'Connection reestablished.',
        resubmitScores: 'Attempting to submit stored results.',
        offlineDialogHeader: 'Your connection to the server was lost',
        offlineDialogBody:
          'We were unable to send information about your completion of this task. Please check your internet connection.',
        offlineDialogRetryMessage: 'Retrying in :num....',
        offlineDialogRetryButtonLabel: 'Retry now',
        offlineSuccessfulSubmit: 'Successfully submitted results.'
      },
      multiplayerRoleSwitcher: {
        view_as_student: 'View as student',
        exit_student_preview: 'Exit student mode',
        dismiss: 'Dismiss',
        student_preview_enabled: 'Student preview is ON'
      }
    },
    hubIsEnabled: true,
    crossorigin: 'Anonymous',
    crossoriginRegex: '^https?:\\/\\/.*.cdn.h5p.com\\/.*',
    crossoriginCacheBuster: 'ver=origin',
    csrfToken: 'coMuIJHdFNyaJ6yTWMdd6H8KzaJSlbSv0MqFQiec',
    reportingIsEnabled: true,
    libraryConfig: {
      'H5P.MathDisplay': {
        renderer: {
          mathjax: {
            src:
              'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js',
            config: {
              extensions: ['tex2jax.js'],
              showMathMenu: false,
              jax: ['input/TeX', 'output/HTML-CSS'],
              tex2jax: { ignoreClass: 'ckeditor' },
              messageStyle: 'none',
              TeX: {
                extensions: ['cancel.js', 'AMSmath.js', 'AMSsymbols.js']
              }
            }
          }
        }
      }
    },
    pluginCacheBuster: '?v=a0b6742eff57e4f42fb9a6176cb9ea9a964e81c4',
    libraryUrl: '',
    isMathTypeEnabled: false,
    locale: 'en',
    user: {
      name: 'Jeferson Silva',
      mail: 'jeferson.silva@somoseducacao.com.br'
    },
    loadedJs: [],
    loadedCss: [],
    core: {
      styles: links,
      scripts
    },
    context: 'direct'
  })};`

  script.append(h5pintegration)
  return script
}
