export const USER_ORDER_FIELDS = {
  KEY: 'id',
  ORDER_NUMBER: 'number',
  ORDER_TOTAL_VALUE: 'total_value',
  ORDER_DATE: 'date',
  ORDER_ITEMS: 'items'
}

export const USER_ORDER_ITEMS_FIELDS = {
  ITEM_ID: 'id',
  ITEM_COURSE_ID: 'courseId',
  ITEM_DESCRIPTION: 'itemdescription',
  ITEM_VALUE: 'itemValue',
  ITEM_COMPLETION_STATUS: 'itemCompletionStatus',
  ITEM_COVER_IMG: 'img'
}
