import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: '2.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24)
  }
}))
