/* eslint-disable */
import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Snackbar } from '@equipe-ninja/saraiva-ui'

import { GeneralContext } from '../../contexts/GeneralContext'
import ProductSelect from '../../components/ProductSelect'
import BdsContent from './BdsContent'
import DonsContent from './Dons/index'

import useStyles from './styles'

function NewJWTConnections() {
  const classes = useStyles()

  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    productSelected,
    setProduct
  } = useContext(GeneralContext)

  const options = {
    bds: () => { 
      return <BdsContent />}
      ,
    dons: () => { 
      return <DonsContent />
    }
  }

  function mainContent() {
    if (!productSelected.value) return
    return options[productSelected.value]()
  }

  return (
    <Grid className={classes.root}>
      <Snackbar
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
        autoHideDuration={5000}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        severity="error"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
        autoHideDuration={5000}
      >
        {errorMessage}
      </Snackbar>

      <Typography variant="h3" className={`${classes.text} ${classes.textH3}`}>
        Gerenciar JWT
      </Typography>

      <ProductSelect onChange={(e) => setProduct(e)} />

      {mainContent()}
    </Grid>
  )
}

export default NewJWTConnections
