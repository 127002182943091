/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react'
import { Switch, Dialog } from '@equipe-ninja/saraiva-ui'

import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import IntegrationsService from '../../../../services/integrationsService'
import Messages from '../../../../helpers/messages'
import useStyles from './styles.js'

function ToggleButton() {
  const classes = useStyles()
  const { connection, updateConnection } = useContext(ConnectionContext)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [showingDialogMessage, toggleDialogMessage] = useState(false)
  const [saving, setSaving] = useState(false)

  const handleConfirm = async () => {
    const payload = { active: !connection.active }

    try {
      setSaving(true)

      const response = await IntegrationsService.updateIntegration(connection.id, payload)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      updateConnection({ active: !connection.active })
      setSuccessMessage(Messages.toggleConnection.success)
      toggleDialogMessage(false)
    } catch (err) {
      setErrorMessage(Messages.toggleConnection.error)
      
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <Dialog
        open={showingDialogMessage}
        title="Desativar conexão"
        handleClose={() => toggleDialogMessage(false)}
        handleConfirm={handleConfirm}
        isDisabled={saving}
        secondaryButton="Cancelar"
        primaryButton="Desativar"
        label="Sim, tenho certeza!"
        alert
      >
        <div className={classes.dialogContent}>
          <p>
            Você tem certeza que deseja{' '}
            <span className={classes.bold}>desativar</span> a conexão{' '}
            <span className={classes.bold}>
              "{`${connection.client_name} ${connection.integration_data.lms} (LTI v1.3)`}" ?
            </span>
          </p>
        </div>
      </Dialog>

      <Switch
        checked={connection.active}
        color="primary"
        onChange={() => {
          if (connection.active) {
            toggleDialogMessage(true)
          } else {
            handleConfirm()
          }
        }}
      />
    </>
  )
}

export default ToggleButton
