import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import {
  Button,
  MenuItem,
  SelectSaraiva,
  TextInput
} from '@equipe-ninja/saraiva-ui'

import { Delete } from '@material-ui/icons'
import { useStyles } from './styles'
import { UploadFile } from './UploadFile'
import { CARD_COMPLEMENTARY_CONTENT_FIELDS } from '../../../../../../domain/complementary-material/card-complementary-content-fields'

const { NAME, ORDER, TYPE, PATH } = CARD_COMPLEMENTARY_CONTENT_FIELDS

const fileTypes = [
  {
    value: 'pdf',
    text: 'PDF'
  },
  { value: 'txt', text: 'TXT' },
  { value: 'planilha', text: 'Planilha' }
]

const extractTextValueFromError = (error) => {
  return typeof error === 'string' ? error : ''
}

export const FormComplementaryContent = ({
  formState,
  formErrors,
  onChangeFormState,
  onDeleteContentButtonClick,
  showDeleteContentButton
}) => {
  const classes = useStyles()

  return (
    <div className={classes.cardContent}>
      <Typography variant="h6" className={classes.cardSubtitle}>
        Conteúdo
      </Typography>

      <div className={classes.cardForm}>
        <div>
          <p className={`${classes.fieldText}`}>
            Insira o nome deste conteúdo como deve aparecer no ambiente de
            aprendizagem.
          </p>
          <TextInput
            label="Nome"
            value={formState[NAME]}
            error={!!formErrors[NAME]}
            helperText={extractTextValueFromError(formErrors[NAME])}
            onChange={(e) => onChangeFormState(NAME, e.target.value)}
            size="full"
            required
          />
        </div>

        <div>
          <p className={`${classes.fieldText}`}>
            Insira a ordem no qual o card aparecerá na plataforma.
          </p>

          <SelectSaraiva
            value={formState[ORDER]}
            onChange={(e) => {
              onChangeFormState(ORDER, e.target.value)
            }}
            label="Ordem de exibição *"
            required
            size="full"
            error={!!formErrors[ORDER]}
            helperText={extractTextValueFromError(formErrors[ORDER])}
          >
            {[1, 2, 3].map((item, indexForm) => (
              <MenuItem key={item.name} value={indexForm + 1}>
                {indexForm + 1}
              </MenuItem>
            ))}
          </SelectSaraiva>
        </div>

        <div>
          <p className={`${classes.fieldText}`}>
            Escolha qual o formato do arquivo a ser adicionado
          </p>

          <SelectSaraiva
            value={formState[TYPE]}
            onChange={(e) => {
              onChangeFormState(TYPE, e.target.value)
            }}
            label="Formato do arquivo"
            required
            size="full"
            error={!!formErrors[TYPE]}
            helperText={extractTextValueFromError(formErrors[TYPE])}
          >
            {fileTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.text}
              </MenuItem>
            ))}
          </SelectSaraiva>
        </div>

        <UploadFile
          error={!!formErrors[PATH]}
          helperText={extractTextValueFromError(formErrors[PATH])}
          sequence={Date.now()}
          file={formState[PATH]}
          onDelete={() => onChangeFormState(PATH, null)}
          onUpload={(file) => onChangeFormState(PATH, file)}
        />

        {showDeleteContentButton && (
          <div>
            <Typography className={classes.cardSubtitle} variant="h6">
              Excluir
            </Typography>

            <p className={classes.fieldText}>
              Exclua este conteúdo para retirá-lo do ambiente de aprendizagem.
            </p>

            <Button
              onClick={onDeleteContentButtonClick}
              startIcon={<Delete />}
              variant="outlined"
              className={classes.buttonError}
            >
              EXCLUIR CONTEÚDO
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

FormComplementaryContent.propTypes = {
  formState: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  formErrors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  onChangeFormState: PropTypes.func.isRequired,
  onDeleteContentButtonClick: PropTypes.func.isRequired,
  showDeleteContentButton: PropTypes.bool
}

FormComplementaryContent.defaultProps = {
  showDeleteContentButton: false
}
