import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { Button, Dialog } from '@equipe-ninja/saraiva-ui'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import Typography from '@material-ui/core/Typography'
import { EditStatusFormDialog } from '../EditStatusFormDialog'
import { deleteVouchers } from '../../../../services/vouchers'
import {
  VOUCHER_FIELDS,
  getVoucherStatusLabelByValue,
  VOUCHER_STATUSES
} from '../../../../domain/vouchers'
import { CopyMe } from '../../../../components/CopyMe'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { formatDate } from '../../../../helpers/data-transformation'
import useStyles from './styles'

const {
  ID,
  PREFIX,
  DISCOUNT_VALUE,
  VOUCHER_TYPE,
  TOTAL_ACTIVATED,
  TOTAL_AVAILABLE,
  START_DATE,
  STATUS,
  END_DATE
} = VOUCHER_FIELDS

export const VoucherDetails = (props) => {
  const { detailsData } = props
  const [detailsDataState, setDetailsDataState] = useState(detailsData)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [uiControls, setUiControls] = useState({
    isExecutingAction: false,
    showDeleteDialog: false,
    showEditStatusDialog: false
  })
  const history = useHistory()
  const classes = useStyles()

  const navigateToVoucherList = (isReplacing = true) => {
    const targetPath = RoutesMapping.vouchersLegacyEntryPoint

    if (isReplacing) {
      history.replace(targetPath)
      return
    }

    history.push(targetPath)
  }

  const hideEditDialog = () => {
    setUiControls((prev) => ({ ...prev, showEditStatusDialog: false }))
  }

  const notifyChangeHandler = (values) => {
    setDetailsDataState((prev) => ({ ...prev, ...values }))
    hideEditDialog()
  }

  const deleteSelectedVouchers = () => {
    setUiControls((prev) => ({ ...prev, showDeleteDialog: false }))

    const voucherIds = [detailsData[ID]]

    setUiControls((prev) => ({ ...prev, isExecutingAction: true }))

    deleteVouchers(voucherIds)
      .then(() => {
        setSuccessMessage('Voucher removido com sucesso')
        navigateToVoucherList()
      })
      .catch(() => {
        setErrorMessage('Não foi possível remover esse voucher')
      })
      .finally(() =>
        setUiControls((prev) => ({ ...prev, isExecutingAction: false }))
      )
  }

  const copyHandler = () => {
    setSuccessMessage('Prefixo do voucher copiado para área de transferência')
  }

  useEffect(() => {
    setDetailsDataState(detailsData)
  }, [detailsData])

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Prefixo no Voucher
        </Typography>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.marginTopSmaller} variant="body1">
            {detailsDataState?.[PREFIX]}
          </Typography>

          <CopyMe
            afterCopyHandler={copyHandler}
            className={classes.marginLeftSmall}
            color="primary"
            showTooltip={false}
            valueToCopy={detailsDataState?.[PREFIX]}
          />
        </div>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Porcentagem de desconto
        </Typography>

        <Typography className={classes.marginTopSmaller} variant="body1">
          {`${detailsDataState?.[DISCOUNT_VALUE]}%`}
        </Typography>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Tipo de voucher
        </Typography>

        <Typography className={classes.marginTopSmaller} variant="body1">
          {detailsDataState?.[VOUCHER_TYPE]}
        </Typography>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Total de vouchers ativados
        </Typography>

        <Typography className={classes.marginTopSmaller} variant="body1">
          {`${detailsDataState?.[TOTAL_ACTIVATED]}/${detailsDataState?.[TOTAL_AVAILABLE]}`}
        </Typography>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Data de início
        </Typography>

        <Typography className={classes.marginTopSmaller} variant="body1">
          {formatDate(detailsDataState?.[START_DATE])}
        </Typography>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Status do voucher
        </Typography>

        <Typography
          className={cls(classes.marginTopSmaller, {
            [classes.statusActive]:
              detailsDataState?.[STATUS] === VOUCHER_STATUSES.ACTIVE.value,
            [classes.statusInactive]:
              detailsDataState?.[STATUS] === VOUCHER_STATUSES.INACTIVE.value
          })}
          variant="body1"
        >
          {getVoucherStatusLabelByValue(detailsDataState?.[STATUS])}
        </Typography>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography className={classes.textSecondary} variant="h6">
          Data de fim
        </Typography>

        <Typography className={classes.marginTopSmaller} variant="body1">
          {formatDate(detailsDataState?.[END_DATE])}
        </Typography>
      </Grid>

      <Grid item sm={12}>
        <Button
          disabled={uiControls.isExecutingAction}
          color="primary"
          onClick={() =>
            setUiControls((prev) => ({ ...prev, showEditStatusDialog: true }))
          }
          startIcon={<CreateIcon />}
          variant="contained"
        >
          Alterar status do voucher
        </Button>

        <Button
          disabled={uiControls.isExecutingAction}
          className={cls(classes.removeButton, classes.marginLeft16)}
          color="primary"
          onClick={() =>
            setUiControls((prev) => ({ ...prev, showDeleteDialog: true }))
          }
          startIcon={<DeleteIcon />}
          variant="outlined"
        >
          Excluir
        </Button>
      </Grid>

      <Dialog
        alert
        confirmWithoutCheck={() => null}
        handleClose={() =>
          setUiControls((prev) => ({ ...prev, showDeleteDialog: false }))
        }
        handleConfirm={() => deleteSelectedVouchers()}
        label="Sim, tenho certeza!"
        open={uiControls.showDeleteDialog}
        primaryButton="Salvar"
        secondaryButton="Cancelar"
        size="lg"
        title="Excluir voucher"
      >
        <Typography variant="body1">
          Quando excluido, este voucher não poderá ser utilizados por nenhum
          usuário. Assim, todos os usuários que tiverem este voucher em mãos,
          não poderão utiliza-lo mais.
        </Typography>

        <Typography variant="body1">
          Esta ação não pode ser desfeita.
        </Typography>
      </Dialog>

      <EditStatusFormDialog
        detailsData={detailsDataState}
        notifyChangesHandler={notifyChangeHandler}
        onCloseDialog={hideEditDialog}
        open={uiControls.showEditStatusDialog}
      />
    </Grid>
  )
}

VoucherDetails.propTypes = {
  detailsData: PropTypes.objectOf(PropTypes.any).isRequired
}

VoucherDetails.defaultProps = {}
