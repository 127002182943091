import { Button } from '@equipe-ninja/saraiva-ui'
import React from 'react'
import { Edit } from '@material-ui/icons'
import PropTypes from 'prop-types'
import useStyles from './styles'
import { formatDateHour } from '../../../../../helpers/formatDate'
import { USER_MANAGEMENT_DETAIL_FIELDS as F } from '../../../../../domain/user-management-content/user-management-page'

export function CardInfo({ children, button, title, justification, onClick }) {
  const classes = useStyles()

  const openEdit = () => {
    onClick()
  }

  return (
    <>
      <div className={classes.CardContext}>
        <main className={classes.CardMain}>
          <div className={classes.CardTitle}>{title}</div>
          <div className={classes.CardDetails}>{children}</div>
        </main>
        {button && (
          <footer className={classes.CardFooter}>
            <Button color="secondary" onClick={openEdit}>
              {button}
            </Button>
            <Edit className={classes.Icon} onClick={openEdit} />
          </footer>
        )}
      </div>
      {justification &&
        justification.map((data) => {
          return (
            <div key={Math.random()} className={classes.CardJustify}>
              <div className={classes.subTitle}>{F.MOVING_DATE.label}</div>
              <div className={classes.text}>
                {formatDateHour(data[F.MOVING_DATE.value])}
              </div>
              <div className={classes.subTitle}>{F.CHANGE_BY.label}</div>
              <div className={classes.text}>{data[F.CHANGE_BY.value]}</div>
              <div className={classes.subTitle}>
                {F.INFORMATION_CHANGE.label}
              </div>
              {data[F.INFORMATION_CHANGE.value] &&
                data[F.INFORMATION_CHANGE.value].map((text) => (
                  <div key={Math.random()} className={classes.text}>
                    {text}
                  </div>
                ))}
              <div className={classes.subTitle}>{F.JUSTIFICATION.label}</div>
              <div className={classes.text}>{data[F.JUSTIFICATION.value]}</div>
            </div>
          )
        })}
    </>
  )
}

CardInfo.propTypes = {
  children: PropTypes.element.isRequired,
  button: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  justification: PropTypes.arrayOf.isRequired
}
