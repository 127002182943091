import React, { useContext } from 'react'
import { Paper, Typography } from '@material-ui/core'
import moment from 'moment-timezone'

import useStyles from './styles'
import ToggleButton from './ToggleButton'
import Steps from './Steps'
import AuthenticationUrls from './AuthenticationUrls'
import ConfigButton from './ConfigButton'
import SelectSubjectsButton from './SelectSubjectsButton'
import DeploymentsList from './DeploymentsList'
import { ConnectionContext } from '../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../contexts/GeneralContext'

const LTI_VERSION = '(LTI v1.3)'

function ConnectionCard() {
  const classes = useStyles()
  const { connection } = useContext(ConnectionContext)

  return (
    <Paper variant="outlined" className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h5" className={classes.title}>
          {connection.client_name}
        </Typography>

        <ToggleButton />
      </div>

      <Typography variant="subtitle2" className={classes.subtitle}>
        {`${connection.integration_data.lms}
        ${LTI_VERSION}
        ${moment
          .utc(connection.updated_at)
          .tz('America/Sao_Paulo')
          .format('DD/MM/YYYY HH:mm')}`}
      </Typography>

      <Steps />

      <AuthenticationUrls />

      <ConfigButton />

      <SelectSubjectsButton />

      <DeploymentsList />
    </Paper>
  )
}

export default ConnectionCard
