import { selector } from 'recoil'
import { userDetailsState } from '../atom/user-details-state'

export const userOrdersState = selector({
  key: 'userDetailsOrderState',
  get: ({ get }) => {
    return {
      isFetchingOrders: get(userDetailsState).isFetchingOrders,
      orders: get(userDetailsState).orders
    }
  },
  set: ({ get, set }, newOrders) => {
    set(userDetailsState, { ...get(userDetailsState), orders: newOrders })
  }
})
