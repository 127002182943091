import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Box, Divider, Typography } from '@material-ui/core'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import AddIcon from '@material-ui/icons/Add'
import { Button, UploadBar } from '@equipe-ninja/saraiva-ui'
import AdditionalQuestion from '../../components/AdditionalQuestion'
import useStyles from './styles'
import { SnackbarOutlet } from '../../components/SnackbarOutlet'
import { UploadQuestionWorksheetContext } from '../../contexts/UploadQuestionWorksheetContext'
import IconUpload from '../../static/images/iconUpload.svg'
import { QuestionTable } from '../../components/QuestionTable'

export default function QuestionsDatabase() {
  const {
    isUploading,
    setIsUploading,
    totalUploadFiles,
    setTotalUploadFiles,
    countFileUpload,
    setCountFileUpload,
    uploadFile,
    clearState,
    fileName,
    updatedQuestions,
    errorsBar,
    reloadFiles,
    filesError,
    setReload,
    reloaFile
  } = useContext(UploadQuestionWorksheetContext)

  const classes = useStyles()
  const fileInputRef = useRef(null)
  const goBack = () => {
    window.history.back()
  }

  const [showDialog, setShowDialog] = useState(false)

  const handleOpenDialogAdditionalQuestion = () => {
    setShowDialog(true)
  }

  const handleCloseDialogAdditionalQuestion = () => {
    setShowDialog(false)
  }

  const handleAddWorksheetClick = useCallback(() => {
    clearState()
    fileInputRef.current.click()
  }, [clearState, fileInputRef])
  useEffect(() => {
    if (reloaFile > 0) {
      clearState()
      handleAddWorksheetClick()
    }
  }, [reloaFile, clearState, handleAddWorksheetClick])

  const handleCloseUploadBar = () => {
    clearState()
    console.log('Upload cancelado')
  }
  const handleCancelUploadBar = () => {
    clearState()
  }

  const handleReloadFiles = () => {
    reloadFiles(filesError)
  }

  async function handleFileChange(event) {
    try {
      const file = event.target.files[0]
      setTotalUploadFiles(1)
      setIsUploading(true)

      await uploadFile(file)
      setCountFileUpload((currentState) => {
        return currentState + 1
      })
    } catch (error) {
      setIsUploading(false)
      console.log(error)
    } finally {
      event.target.value = null
    }
  }
  useEffect(() => {
    if (filesError.length > 0) {
      setReload(true)
    }
  }, [filesError, setReload])

  return (
    <Box className={classes.container}>
      <Button
        className={classes.buttonManagement}
        color="primary"
        onClick={() => {
          if (localStorage.getItem('lastAccessFromBo')) {
            localStorage.removeItem('lastAccessFromBo')
            window.location.assign('/')
          } else {
            goBack()
          }
        }}
        startIcon={<LeftArrowIcon />}
      >
        GERENCIAR CONTEÚDO
      </Button>

      <Typography variant="h4">
        Banco de Questões
        <Divider className={classes.divider} />
      </Typography>

      <Button
        className={classes.addButton}
        color="primary"
        startIcon={<AddIcon />}
        variant="contained"
        onClick={() => handleOpenDialogAdditionalQuestion()}
      >
        ADICIONAR QUESTÃO
      </Button>
      <>
        <Button
          className={classes.addWorksheet}
          variant="outlined"
          color="primary"
          startIcon={<img src={IconUpload} alt="Upload Icon" />}
          onClick={handleAddWorksheetClick}
        >
          ADICIONAR LOTE
        </Button>
        <input
          type="file"
          onChange={handleFileChange}
          accept=" .csv"
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        {isUploading && (
          <UploadBar
            progressFileUpload={countFileUpload}
            totalUploadFiles={totalUploadFiles}
            errorsList={errorsBar}
            handleCancelUpload={handleCloseUploadBar}
            handleCloseUpload={handleCancelUploadBar}
            uploadMessage={fileName}
            uploadInProgressMessage="Upload de arquivo em andamento"
            errorMessage="O arquivo teve problema ao fazer o upload. Revise os seguintes dados"
            reloadErrorsUpload={handleReloadFiles}
          />
        )}
      </>

      <QuestionTable updatedQuestions={updatedQuestions} />

      <AdditionalQuestion
        setShowDialog={handleCloseDialogAdditionalQuestion}
        showDialog={showDialog}
      />

      <div className={classes.root}>
        <SnackbarOutlet />
      </div>
    </Box>
  )
}
