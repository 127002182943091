import { PROMOTIONAL_PAGES_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages'
import { outboundDate } from '../../../utils/outbound-data-transformation'
import { RemoteDateUtils } from '../../../utils/remote-date-utils'
import { PromotionalPageAdapter } from './promotional-page-fields-adapter'

const REMOTE_FIELDS = {
  CONTENT: 'html_content',
  END_DATE: 'final_date',
  KEY: 'id',
  REDIRECT_URL: 'redirect_url',
  START_DATE: 'initial_date',
  STATUS: 'status',
  TITLE: 'title',
  URL: 'url'
}

const inbound = (remoteData) => {
  return {
    [FIELDS.CONTENT.value]: remoteData[REMOTE_FIELDS.CONTENT],
    [FIELDS.END_DATE.value]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_FIELDS.END_DATE]
    ),
    [FIELDS.KEY.value]: remoteData[REMOTE_FIELDS.KEY],
    [FIELDS.REDIRECT_URL.value]: remoteData[REMOTE_FIELDS.REDIRECT_URL],
    [FIELDS.START_DATE.value]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_FIELDS.START_DATE]
    ),
    [FIELDS.STATUS.value]: PromotionalPageAdapter.inbound.publishing_status(
      remoteData[REMOTE_FIELDS.STATUS]
    ),
    [FIELDS.TITLE.value]: remoteData[REMOTE_FIELDS.TITLE],
    [FIELDS.URL.value]: remoteData[REMOTE_FIELDS.URL]
  }
}

const outbound = (formValues) => {
  return {
    [REMOTE_FIELDS.CONTENT]: formValues[FIELDS.CONTENT.value],
    [REMOTE_FIELDS.END_DATE]: outboundDate(formValues[FIELDS.END_DATE.value]),
    [REMOTE_FIELDS.KEY]: formValues[FIELDS.KEY.value],
    [REMOTE_FIELDS.REDIRECT_URL]: formValues[FIELDS.REDIRECT_URL.value],
    [REMOTE_FIELDS.START_DATE]: outboundDate(
      formValues[FIELDS.START_DATE.value]
    ),
    [REMOTE_FIELDS.STATUS]: PromotionalPageAdapter.outbound.publishing_status(
      formValues[FIELDS.STATUS.value]
    ),
    [REMOTE_FIELDS.TITLE]: formValues[FIELDS.TITLE.value],
    [REMOTE_FIELDS.URL]: formValues[FIELDS.URL.value],
    service_id: 0
  }
}

export const PromoPageDetailsAdapter = {
  inbound,
  outbound
}
