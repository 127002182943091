export const PROMOTIONAL_PAGES_FIELDS = Object.freeze({
  KEY: { value: 'key', label: 'identificador' },
  CONTENT: { value: 'content', label: 'Conteúdo HTML' },
  END_DATE: { value: 'endDate', label: 'Data de fim' },
  REDIRECT_URL: {
    value: 'redirectUrl',
    label: 'URL de redirecionamento de fim de promoção'
  },
  START_DATE: { value: 'startDate', label: 'Data de início' },
  STATUS: { value: 'status', label: 'Status da página' },
  TITLE: { value: 'title', label: 'Título da página' },
  URL: { value: 'url', label: 'URL da página' }
})
