import { selector } from 'recoil'
import {
  courseTopicsState,
  breadcrumbsEntry,
  defaultBreadcrumbsValue
} from '../course-topics-state'
import { locateTopicById } from '../../../domain/courses/register-elearning-page/business'
import { REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS as F } from '../../../domain/courses/register-elearning-page/register-elearning-all-subject-list-fields'

const computeBreadCrumbsNextState = (topic, breadcrumbsList) => {
  if ((topic?.[F.KEY.value] ?? null) === null) {
    return defaultBreadcrumbsValue()
  }

  const topicIndex = breadcrumbsList.findIndex(
    (e) => e.value === topic?.[F.KEY.value]
  )

  if (topicIndex >= 0) {
    return breadcrumbsList.slice(0, topicIndex + 1)
  }

  return [
    ...breadcrumbsList,
    breadcrumbsEntry(topic[F.SUBJECT.value], topic[F.KEY.value])
  ]
}

const computeHierarchyNextState = (topic, currentState) => {
  if ((topic?.[F.KEY.value] ?? null) === null) {
    return { topicVisitingHierarchy: [] }
  }

  const topicIndex = currentState.findIndex(
    (e) => e?.[F.KEY.value] === topic?.[F.KEY.value]
  )

  if (topicIndex >= 0) {
    return { topicVisitingHierarchy: currentState.slice(0, topicIndex + 1) }
  }

  return { topicVisitingHierarchy: [...currentState, topic] }
}

export const selectedTopicsDataSelector = selector({
  key: 'selectedTopicsDataSelector',
  get: ({ get }) => {
    let selectedTopicData = null
    const query = get(courseTopicsState).subjectSearch

    if (query) {
      selectedTopicData = get(courseTopicsState).allTopics.filter((e) => {
        return e[F.SUBJECT.value].toLowerCase().includes(query.toLowerCase())
      })
    } else {
      selectedTopicData =
        get(courseTopicsState).selectedTopicData ||
        get(courseTopicsState).allTopics
    }

    return {
      isFetching: get(courseTopicsState).isFetching,
      isExecutingTask: get(courseTopicsState).isExecutingTask,
      selectedTopicId: get(courseTopicsState).selectedTopicId,
      breadcrumbs: get(courseTopicsState).breadcrumbs,
      subjectSearch: get(courseTopicsState).subjectSearch,
      topicVisitingHierarchy: get(courseTopicsState).topicVisitingHierarchy,
      selectedTopicData
    }
  },
  set: ({ get, set }, { isFetching, isExecutingTask, selectedTopicId }) => {
    let selectedTopicDataUpdater = {}
    let breadcrumbsUpdater = {}
    let hierarchyUpdater = {}

    if (selectedTopicId !== get(courseTopicsState).selectedTopicId) {
      const topic = locateTopicById(
        selectedTopicId,
        get(courseTopicsState).allTopics
      )

      selectedTopicDataUpdater = {
        selectedTopicData: topic?.[F.CHILDREN.value] ?? null,
        subjectSearch: ''
      }

      breadcrumbsUpdater = {
        breadcrumbs: computeBreadCrumbsNextState(
          topic,
          get(courseTopicsState).breadcrumbs
        )
      }

      hierarchyUpdater = computeHierarchyNextState(
        topic,
        get(courseTopicsState).topicVisitingHierarchy
      )
    }

    set(courseTopicsState, {
      ...get(courseTopicsState),
      isFetching,
      isExecutingTask,
      selectedTopicId,
      ...breadcrumbsUpdater,
      ...selectedTopicDataUpdater,
      ...hierarchyUpdater
    })
  }
})
