/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, TextInput } from '@equipe-ninja/saraiva-ui'
import { Add } from '@material-ui/icons'
import { GeneralContext } from '../../contexts/GeneralContext'
import Messages from '../../helpers/messages'
import IntegrationsService from '../../services/integrationsService'

import useStyles from './style'

function AdditionalSimulated({ showDialog, setShowDialog }) {
  const classes = useStyles()

  const [saving, setSaving] = useState(false)
  const [title, setTitle] = useState('')
  const [bookName, setBooksName] = useState('')
  const [caeValues, setCaeValues] = useState([])
  const [caeVinculado, setCaeVinculado] = useState()

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const [caeInputs, setCaeInputs] = useState([
    {
      id: 1,
      label: 'CAE vinculado',
      required: true,
      size: 'xl'
    }
  ])

  const handleCloseDialog = () => {
    setShowDialog(false)
    setTitle('')
    setBooksName('')
    setCaeVinculado('')
    const updatedInputs = [
      {
        id: 1,
        label: 'CAE vinculado',
        required: true,
        size: 'xl',
        value: ''
      }
    ]
    setCaeInputs(updatedInputs)
  }

  const handleInputChange = (id, value) => {
    const updatedInputs = caeInputs.map((input) => {
      if (input.id === id) {
        return {
          ...input,
          value
        }
      }
      return input
    })
    setCaeInputs(updatedInputs)

    const updatedValues = [...caeValues]
    if (id === 1) {
      setCaeVinculado(value)
    } else {
      updatedValues[id - 2] = value
      setCaeValues(updatedValues)
    }
  }

  const save = async () => {
    setSaving(false)

    const tempCaeIdArray = [...caeValues, caeVinculado]

    const caeIdArray = tempCaeIdArray.map(Number)

    const payload = {
      title,
      books_name: bookName,
      cae_ids: caeIdArray,
      status: 'false'
    }

    try {
      const response = await IntegrationsService.createPracticeTest(payload)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setSuccessMessage(Messages.simulatedDialog.success)
      window.location.reload()
    } catch (error) {
      setErrorMessage(Messages.simulatedDialog.error)
      console.error(error)
    } finally {
      setSaving(true)
      handleCloseDialog()
    }
  }

  const handleAddCaeInput = () => {
    const newInput = {
      id: caeInputs.length + 1,
      label: 'CAE vinculado',
      required: true,
      size: 'xl'
    }
    setCaeInputs((prevInputs) => [...prevInputs, newInput])
  }

  return (
    <div>
      <Dialog
        title="Adicionar simulado"
        secondaryButton="CANCELAR"
        primaryButton="SALVAR"
        size="lg"
        open={showDialog}
        handleClose={() => handleCloseDialog()}
        handleConfirm={() => save()}
        disabled={saving}
      >
        <div>
          <TextInput
            className={classes.TextInput}
            color="primary"
            label="Título do simulado"
            required
            size="xl"
            helperText="Atenção: Esse é o título que irá refletir para os usuários no conecta "
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextInput
            className={classes.TextInput}
            color="primary"
            label="Nome do Livro"
            required
            size="xl"
            value={bookName}
            onChange={(e) => setBooksName(e.target.value)}
          />

          {caeInputs.map((input) => (
            <div key={input.id}>
              <TextInput
                className={classes.TextInputCae}
                color="primary"
                label={input.label}
                required={input.required}
                size={input.size}
                value={input.value}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '')
                  handleInputChange(input.id, value)
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </div>
          ))}

          <Button
            className={classes.btn}
            color="primary"
            onClick={handleAddCaeInput}
            startIcon={<Add />}
          >
            CAE
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default AdditionalSimulated

AdditionalSimulated.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired
}
