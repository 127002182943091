import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core'

import useStyles from './styles'

export default function ExportationDialog({
  handleClose,
  handleExport,
  exporting,
  columns
}) {
  const [selectedColumns, selectColumns] = useState([])
  const classes = useStyles()

  return (
    <Dialog
      open
      size="sm"
      title="Exportar"
      secondaryButton="cancelar"
      primaryButton="exportar"
      handleClose={handleClose}
      handleConfirm={() => handleExport(selectedColumns)}
      isDisabled={selectedColumns.length === 0 || exporting}
    >
      <FormControl component="fieldset">
        <span className={classes.legend}>Exportar dados em formato Excel.</span>
        <span className={classes.label}>Dados a serem exportados:</span>

        <FormGroup>
          {columns.map((column) => (
            <FormControlLabel
              key={column.value}
              classes={{
                root: classes.checkboxWrapper,
                label: classes.checkboxLabel
              }}
              control={
                <Checkbox
                  checked={selectedColumns.find(
                    (sc) => sc.value === column.value
                  )}
                  color="primary"
                  onChange={(evt) => {
                    if (evt.target.checked) {
                      selectColumns([
                        ...selectedColumns,
                        column
                      ])
                    } else {
                      const newColumns = selectedColumns.filter(
                        (sc) => sc.value !== column.value
                      )

                      selectColumns(newColumns)
                    }
                  }}
                />
              }
              label={column.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Dialog>
  )
}

ExportationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  exporting: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
}
