export function formatCPF(cpf) {
  const cpfAtualizado = cpf.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    // eslint-disable-next-line func-names
    function (regex, argumento1, argumento2, argumento3, argumento4) {
      return `${argumento1}.${argumento2}.${argumento3}-${argumento4}`
    }
  )
  return cpfAtualizado
}
