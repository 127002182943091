import { PROMOTIONAL_PAGES_FIELDS as F } from './promotional-page-fields'

export const PROMOTIONAL_PAGES_LIST_ITEM_FIELDS = Object.freeze({
  KEY: { value: F.KEY.value, label: F.KEY.label },
  TITLE: { value: F.TITLE.value, label: F.TITLE.label },
  URL: { value: F.URL.value, label: F.URL.label },
  START_DATE: { value: F.START_DATE.value, label: F.START_DATE.label },
  END_DATE: { value: F.END_DATE.value, label: F.END_DATE.label },
  STATUS: { value: F.STATUS.value, label: F.STATUS.label }
})
