import { REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS as F } from '../register-elearning-all-subject-list-fields'

const updateTopic = (id, node, updaterObject) => {
  if (id === node?.[F.KEY.value]) {
    return {
      ...node,
      ...updaterObject
    }
  }

  if (!node?.[F.CHILDREN.value]) {
    return node
  }

  return {
    ...node,
    [F.CHILDREN.value]: node?.[F.CHILDREN.value]?.map((topic) =>
      updateTopic(id, topic, updaterObject)
    )
  }
}

/**
 * localiza um topico na arvore e atualiza se for encontrado
 * @param {number|string} topicId identificador do topico que deve ser atualizado
 * @param {any} allTopics lista de topicos de matérias de um curso
 * @param {Object} updaterObject object com os valores a serem atualizados
 * @returns o novo estado de todos os topics
 */
export const updateTopicById = (topicId, allTopics, updaterObject) => {
  if (!topicId) return allTopics

  return updateTopic(
    topicId,
    {
      [F.KEY.value]: null,
      [F.CHILDREN.value]: allTopics
    },
    updaterObject
  )?.[F.CHILDREN.value]
}
