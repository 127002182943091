import {
  BANNER_STATUS,
  BANNER_TYPE
} from '../../../../domain/promotional-content/banners'

const statusOutbound = (localValue) => {
  switch (localValue) {
    case BANNER_STATUS.ACTIVE.value:
      return true

    default:
      return false
  }
}

const statusInbound = (remoteValue) => {
  switch (remoteValue) {
    case true:
      return BANNER_STATUS.ACTIVE.value

    default:
      return BANNER_STATUS.INACTIVE.value
  }
}

const typeOutbound = (localValue) => {
  switch (localValue) {
    case BANNER_TYPE.ROTARY.value:
      return '1'

    case BANNER_TYPE.SALE.value:
      return '2'

    default:
      return '1'
  }
}

const typeInbound = (remoteValue) => {
  switch (remoteValue) {
    case '1':
      return BANNER_TYPE.ROTARY.value

    case '2':
      return BANNER_TYPE.SALE.value

    default:
      return BANNER_TYPE.ROTARY.value
  }
}

const isRotaryOutbound = (localValue) => {
  switch (localValue) {
    case BANNER_TYPE.ROTARY.value:
      return true

    default:
      return false
  }
}

const isRotaryInbound = (localValue) => {
  switch (localValue) {
    case '1':
      return true

    default:
      return false
  }
}

export const BannerAdapter = {
  outbound: {
    status: statusOutbound,
    type: typeOutbound,
    isRotary: isRotaryOutbound
  },
  inbound: {
    status: statusInbound,
    type: typeInbound,
    isRotary: isRotaryInbound
  }
}
