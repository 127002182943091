import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: '2.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24)
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.secondary,
    fontWeight: 500,
    marginBottom: theme.typography.pxToRem(8)
  },
  divCta: {
    display: 'flex',
    gap: theme.typography.pxToRem(16)
  },
  btnDanger: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  deleteDialogContent: {
    width: '100%',
    display: 'flex',
    gap: theme.typography.pxToRem(30)
  },
  warningIcon: {
    width: theme.typography.pxToRem(50.83),
    height: theme.typography.pxToRem(45.35),
    color: theme.palette.primary.main
  }
}))
