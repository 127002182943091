import { SCHEDULED_CONTENT_FIELDS } from '../scheduled-content-fields'

const { INITIAL_DATE, LINK, ANSWER_CONTENT, ANSWER_PUBLISHING_DATE } =
  SCHEDULED_CONTENT_FIELDS

export const hasMissingInfoInScheduledContentAvailable = (scheduledContent) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return (
    (!scheduledContent?.[LINK] ||
      !scheduledContent?.[ANSWER_CONTENT] ||
      !scheduledContent?.[ANSWER_PUBLISHING_DATE]) &&
    scheduledContent?.[INITIAL_DATE] <= today
  )
}
