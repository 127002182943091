import { USER_MANAGEMENT_LIST_ITEM_FIELDS as LIST_FIELDS } from '../../../domain/user-management-content/user-management-page'
import { USER_FIELDS_LIST_ITEM } from '../remote-fields/user-list-fields'
import { formatCPF } from '../../../helpers/formatCPF'

export const listItemAdapterInbound = (remoteDataList) => {
  return remoteDataList.map((remoteData) => ({
    [LIST_FIELDS.KEY.value]: remoteData[USER_FIELDS_LIST_ITEM.KEY],

    [LIST_FIELDS.FULL_NAME.value]: remoteData[USER_FIELDS_LIST_ITEM.FULL_NAME],

    [LIST_FIELDS.CPF.value]: formatCPF(remoteData[USER_FIELDS_LIST_ITEM.CPF]),

    [LIST_FIELDS.EMAIL.value]: remoteData[USER_FIELDS_LIST_ITEM.EMAIL],

    [LIST_FIELDS.STATUS.value]:
      remoteData[USER_FIELDS_LIST_ITEM.STATUS] === true
        ? 'Curso finalizado'
        : 'Curso em andamento'
  }))
}
