import { COURSE_SERVICE_STATUS } from '../entities/course-ecommerce-status'

/**
 * Representa a regra de negocio que um curso so pode ir para o status inativo
 * se ele estiver ativo
 * @param {string} status
 */
export const courseIsAllowedToInactivate = (status) => {
  return status === COURSE_SERVICE_STATUS.AVAILABLE
}
