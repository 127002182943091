/**
 * Monta uma lista com a quantidade especificada mapeado para a factory fornecida
 *
 * @param {*} count - quantidade de itens que serao retornados
 * @param {*} factory - factory que sera chamada para gerar dados fornece um indice (base 1) como parametro
 *
 * @returns lista com a quantidade de itens solicitadas mapeados com a factory
 *
 * ### Example
 * ```js
 * const count = 2
 * const factory = (index) => ({id: index, name: `user-${index}`})
 * factoryWithCount(count, factory)
 * // [{ id: 1, name: 'user-1' }, { id: 2, name: 'user-2' }]
 * ```
 */
export const factoryWithCount = (count, factory) => {
  return new Array(count).fill(null).map((_, index) => {
    const computedIndex = index + 1

    return factory(computedIndex)
  })
}

/**
 * Simula uma chamada ao servidor de acordo com parametros fornecidos
 *
 * @param {*} data - O dado que sera retornado apos o delay
 * @param {*} shouldMockSuccess - se true retorna a resposta como sucesso, caso contrario sera uma falha
 * @param {*} delay - o tempo em milisegundos que deve ser aguardado ate a resposta propagada
 * @param {*} successAdapter - adapter para ser usado na resposta de sucesso, caso exista
 * @returns Promise com a resposta baseado nos args
 *
 * ### Exemplo
 *
 * ```js
 * const mockedData = { data: {id: 1, name: 'user-1'}}
 * const adapter = (response) => response.data
 *
 * mockedServerAnswer(mockedData, true, 2000, adapter)
 * // 2000 milegundos depois Promise resolve -> {id: 1, name: 'user-1'}
 * ```
 */
export const mockedServerAnswer = (
  data,
  shouldMockSuccess = true,
  delay = 200,
  successAdapter = null
) => {
  return new Promise((resolve, reject) => {
    if (shouldMockSuccess) {
      setTimeout(() => {
        resolve(successAdapter ? successAdapter(data) : data)
      }, delay)
    } else {
      setTimeout(() => reject(data), delay)
    }
  })
}
