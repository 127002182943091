/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState, useRef } from 'react'
import {
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { Button, Dialog, Tooltip } from '@equipe-ninja/saraiva-ui'
import {
  InsertDriveFile as InsertDriveFileIcon,
  Folder as FolderIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  PermMedia as PermMediaIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'
import { GeneralContext } from '../../../contexts/GeneralContext'
import {
  ContentsContext,
  ContentsProvider
} from '../../../contexts/ContentsContext'
import Loading from '../../../components/Loading'
import BreadcrumbsMenu from './Breadcrumbs'
import ContentConnection from '../../../services/contentConnection'
import Messages from '../../../helpers/messages'
import useStyles from './styles'
import Accordion from '../../../components/Accordion/Accordion'
import EditDialog from './ContentList/EditDialog'
import { UploadProvider } from '../../../contexts/UploadContext'
import UploadButton from '../../../components/UploadButton'

function ContentCard() {
  const {
    loading,
    updated,
    setUpdated,
    setErrorMessage,
    setSuccessMessage,
    setInfoMessage
  } = useContext(GeneralContext)
  const {
    fetchContents,
    contents,
    contentsPath,
    editNameContentOnState,
    removeContentOnState,
    removeMultipleContent
  } = useContext(ContentsContext)

  const [accordionStates, setAccordionStates] = useState({
    isActive: false,
    id: 0
  })
  const prevIdRef = useRef()
  const [openEditDialog, toggleOpenEditDialog] = useState(false)
  const [contentSelected, setContentSelected] = useState([])
  const [target, setTarget] = useState('')
  const [multipleSelected, setMultipleSelected] = useState([])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [showingDialogWarning, toggleDialogWarning] = useState(false)
  const [openMenu, toggleOpenMenu] = useState(false)
  const [name, setName] = useState('')
  const classes = useStyles()

  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState([])
  const [list, setList] = useState([])

  useEffect(() => {
    prevIdRef.current = accordionStates.id
  }, [accordionStates])

  useEffect(() => {
    setList(contents)
    setIsCheck([])
    setIsCheckAll(false)
    setUpdated(false)
  }, [list, contents])

  const updatedPath = contentsPath[contentsPath.length - 1]?.id
  useEffect(() => {
    toggleOpenMenu(false)
    fetchContents(updatedPath)
    setList(contents)
    setUpdated(false)
  }, [updated])

  useEffect(() => {
    if (target === null) return
    toggleOpenMenu(target)
  }, [target])

  useEffect(() => {
    if (openMenu !== null) return
    setTarget(null)
  }, [openMenu])

  if (loading) {
    return <Loading />
  }

  const handlerActive = (id) => {
    const option =
      accordionStates.id === 0
        ? 'initial'
        : accordionStates.id === id
        ? 'change'
        : !accordionStates.id === id
        ? 'notChange'
        : 'any'
    const options = {
      initial() {
        return true
      },
      change() {
        return !accordionStates.isActive
      },
      notChange() {
        return accordionStates.isActive
      },
      any() {
        return true
      }
    }
    const response = options[option]
    return response()
  }

  function filterDateWithoutTime(array) {
    return array.map((item) => {
      const newItem = { ...item, updated_at: item.updated_at?.split(' ')[0] }
      return newItem
    })
  }
  function sortByDate(array) {
    const sortedData = array.sort((itemA, itemB) => {
      const a = itemA.updated_at?.split('/').reverse().join('-')
      const b = itemB.updated_at?.split('/').reverse().join('-')
      return new Date(a).getTime() - new Date(b).getTime()
    })
    if (order === 'desc') return sortedData.reverse()
    return sortedData
  }
  function sortByName(array) {
    const sortedData = array.sort((a, b) =>
      a.name.localeCompare(
        b.name,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true
        }
      )
    )
    if (order === 'desc') sortedData.reverse()
    return sortedData
  }
  function stableSort(array) {
    const newArray = filterDateWithoutTime(array)
    if (orderBy === 'updated_at') return sortByDate(newArray, order)
    return sortByName(newArray, order)
  }
  const handleClick = (event, content) => {
    setContentSelected(content)
    setTarget(event.currentTarget)
    setName(content.name)
  }
  const handleCloseEditDialog = () => {
    toggleOpenEditDialog(false)
    setName('')
  }
  const handleContenEdit = (event) => {
    setName(event.target.value)
  }

  async function deleteMultipleFiles() {
    for (const marked of isCheck) {
      try {
        toggleOpenEditDialog(false)
        let response = null
        response = await ContentConnection.deleteFile(marked)

        if (!response.ok) {
          const errors = await response.json()
          throw errors
        } else {
          removeMultipleContent(isCheck)
          setSuccessMessage(Messages.contentDelete.success)
        }
      } catch (err) {
        setErrorMessage(Messages.contentDelete.error)
      } finally {
        toggleDialogWarning(false)
      }
    }
  }

  async function deleteContent() {
    try {
      toggleOpenMenu(false)
      let response = null
      if (contentSelected.type === 'folder')
        response = await ContentConnection.deleteFolder(contentSelected.id)
      else response = await ContentConnection.deleteFile(contentSelected.id)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      } else {
        removeContentOnState(contentSelected)
        setSuccessMessage(Messages.contentDelete.success)
      }
    } catch (err) {
      setErrorMessage(Messages.contentDelete.error)
    } finally {
      toggleDialogWarning(false)
    }
  }

  async function editContent() {
    const payload = {
      name
    }
    try {
      toggleOpenMenu(false)
      let response = null
      if (contentSelected.type === 'folder')
        response = await ContentConnection.editFolder(
          payload,
          contentSelected.id
        )
      else
        response = await ContentConnection.editFile(payload, contentSelected.id)
      if (response.status === 422 && contentSelected.type === 'folder') {
        setInfoMessage(Messages.editContentName.folderAlreadyCreated)
        return
      }
      if (response.status === 422 && contentSelected.type === 'file') {
        setInfoMessage(Messages.editContentName.fileAlreadyCreated)
        return
      }
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      } else {
        editNameContentOnState(contentSelected, payload.name)
        toggleOpenEditDialog(false)
        setSuccessMessage(Messages.editContentName.success)
      }
    } catch (err) {
      toggleOpenEditDialog(false)
      setErrorMessage(Messages.editContentName.error)
    } finally {
      toggleDialogWarning(false)
    }
  }

  const handleSelect = (e, content) => {
    const { id, checked } = e.target
    multipleSelected.push(content)
    setIsCheck([...isCheck, Number(id)])
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== Number(id)))
      setMultipleSelected(
        multipleSelected.filter((arch) => arch.id !== content.id)
      )
      setIsCheckAll(false)
    }
  }

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(list.map((li) => li.id))
    list.map((content) => multipleSelected.push(content))
    if (isCheckAll) {
      setIsCheck([])
      setMultipleSelected([])
    }
  }

  const isFolder = contents.filter((item) => item.type === 'H5P')

  return (
    <>
      <EditDialog
        openDialog={openEditDialog}
        handleClose={handleCloseEditDialog}
        handleConfirm={editContent}
        contentName={name}
        onChange={handleContenEdit}
      />
      <Dialog
        open={Boolean(showingDialogWarning)}
        title="Excluir arquivos(s) ou pasta"
        secondaryButton="cancelar"
        primaryButton="excluir"
        size="md"
        label="Sim, tenho certeza"
        handleClose={() => toggleDialogWarning(false)}
        handleConfirm={() =>
          isCheck.length > 0 ? deleteMultipleFiles() : deleteContent()
        }
        alert
      >
        <Typography color="rgba(0, 0, 0, 0.54)">
          Para <strong>exclusão de arquivos</strong>, lembre-se que:
        </Typography>
        <ul style={{ margin: 0 }}>
          <li>
            Ao excluir um arquivo, todos os parceiros perderão acesso aos
            conteúdos.
          </li>
          <li>
            O acesso do parceiro pode ser recuperado realizando o upload do
            arquivo com mesmo nome.
          </li>
        </ul>
        <Typography color="rgba(0, 0, 0, 0.54)">
          Para <strong>exclusão de pastas</strong>, lembre-se que:
        </Typography>
        <ul style={{ margin: 0 }}>
          <li>Só é possivel excluir pastas vazias.</li>
        </ul>
        <br />
        <Typography color="textPrimary">
          Você tem certeza que deseja excluir?
        </Typography>
      </Dialog>
      <Paper className={classes.table}>
        <div className={classes.contentPadding}>
          <div
            className={
              isCheck.length > 0
                ? `${classes.content} ${classes.contentBG}`
                : classes.content
            }
          >
            <BreadcrumbsMenu />
            {isCheck.length > 0 && (
              <div className={classes.deleteDiv}>
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  className={classes.deleteBtn}
                  onClick={toggleDialogWarning}
                >
                  EXCLUIR
                </Button>
              </div>
            )}
          </div>
        </div>
        <Divider />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {isFolder.length > 0 && (
                <TableCell>
                  <Checkbox
                    onClick={handleSelectAll}
                    checked={isCheckAll}
                    className={classes.checkBoxCustom}
                  />
                </TableCell>
              )}
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Ultimas Atualizações</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(list, orderBy, order).map((row) => (
              <>
                <TableRow
                  key={row.id}
                  className={
                    (isCheck.includes(row.id) && classes.selectedRow) ||
                    (row.type === 'folder' && classes.tableRow)
                  }
                >
                  {row.type === 'H5P' && (
                    <TableCell style={{ width: 10, paddingRight: 0 }}>
                      <Checkbox
                        key={row.id}
                        id={row.id}
                        name={row.name}
                        onClick={(e) => handleSelect(e, row)}
                        checked={isCheck.includes(row.id)}
                        className={classes.checkBoxCustom}
                      />
                    </TableCell>
                  )}
                  {row.type !== 'folder' ? (
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.previa}
                      onClick={() =>
                        setAccordionStates({
                          isActive: handlerActive(row.id),
                          id: row.id
                        })
                      }
                    >
                      <Tooltip
                        title={
                          accordionStates.isActive &&
                          accordionStates.id === row.id
                            ? 'Fechar conteúdo'
                            : 'Ver conteúdo'
                        }
                        direction="bottom"
                        className={classes.cell}
                      >
                        <ExpandMoreIcon
                          className={
                            accordionStates.id === row.id &&
                            accordionStates.isActive
                              ? classes.expanded
                              : accordionStates.id === row.id &&
                                !accordionStates.isActive
                              ? classes.notExpanded
                              : prevIdRef.current === row.id
                              ? classes.notExpanded
                              : ''
                          }
                          id={prevIdRef}
                          style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                        />
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.cell} />
                  )}
                  <TableCell
                    className={
                      row.type === 'folder' ? classes.folder : classes.cell
                    }
                    onClick={
                      row.type === 'folder' ? () => fetchContents(row.id) : null
                    }
                  >
                    {row.type === 'folder' ? (
                      <FolderIcon className={classes.icon} />
                    ) : row.type === 'H5P' ? (
                      <InsertDriveFileIcon className={classes.icon} />
                    ) : (
                      <PermMediaIcon className={classes.icon} />
                    )}
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.updated_at}
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    {row.type === 'folder' ? '-' : row.type}
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    <IconButton
                      onClick={(event) => {
                        handleClick(event, row)
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.accordionCustom} colSpan={6}>
                    <Accordion
                      isOpen={
                        accordionStates.id == row.id && accordionStates.isActive
                          ? true
                          : accordionStates.id == row.id &&
                            !accordionStates.isActive
                          ? false
                          : prevIdRef.current == row.id
                          ? false
                          : false
                      }
                      timeout={1000}
                    />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        <Menu
          anchorEl={openMenu}
          open={Boolean(openMenu)}
          onClose={() => toggleOpenMenu(null)}
        >
          <MenuItem onClick={() => toggleOpenEditDialog(true)}>
            <EditIcon className={classes.icon} />
            Renomear
          </MenuItem>
          {contentSelected.type !== 'folder' && (
            <ContentsProvider>
              <UploadProvider>
                <UploadButton
                  buttonTitle="Substituir"
                  isSwap
                  // eslint-disable-next-line no-console
                  contentId={contentSelected.id}
                  moduleId={contentsPath[0]?.id}
                />
              </UploadProvider>
            </ContentsProvider>
          )}
          <MenuItem onClick={toggleDialogWarning}>
            <DeleteIcon className={classes.icon} />
            Excluir
          </MenuItem>
        </Menu>
      </Paper>
    </>
  )
}
export default ContentCard
