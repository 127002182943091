import {
  COURSE_ECOMMERCE_FIELDS as LOCAL,
  ECOMMERCE_TABS_FIELDS as TABS
} from '../../../domain/courses/entities'
import {
  SERVICE_REMOTE_FIELDS as REMOTE,
  COURSE_SERVICES_PLANS_FIELDS as REMOTE_PLAN,
  COURSE_SERVICE_TABS_REMOTE_FIELDS as REMOTE_TAB
} from '../remote-fields/courses-services-fields'

import { CourseServiceStatusAdapter } from './course-service-status'
import { parseRemoteDateTimeFromUTC } from '../../utils/remote-datetime-utils'
import { RemoteDateUtils } from '../../utils/remote-date-utils'
import {
  defaultValueWhenEmpty,
  parseRemoteDecimal,
  parseRemoteInteger
} from '../../utils/inbound-utils'

const CATEGORY_REMOTE = {
  ACTIVE: 'active',
  ID: 'id',
  NAME: 'name'
}

const digCategoryId = (remoteData) => {
  return (
    remoteData?.[REMOTE.CATEGORIES]?.sort(
      (a, b) => a?.[CATEGORY_REMOTE.ID] > b?.[CATEGORY_REMOTE.ID]
    )?.[1]?.[CATEGORY_REMOTE.ID] ?? ''
  )
}

const digSubCategoryId = (remoteData) => {
  return (
    remoteData?.[REMOTE.CATEGORIES]?.sort(
      (a, b) => a?.[CATEGORY_REMOTE.ID] > b?.[CATEGORY_REMOTE.ID]
    )?.[0]?.[CATEGORY_REMOTE.ID] ?? ''
  )
}
const digFromPlan = (remoteData, field) => {
  return remoteData[REMOTE.PLANS]?.[0]?.[field] ?? ''
}

const TabsAdapter = {
  inbound: (tabArray) => {
    if (!tabArray) return []

    return tabArray.map((item) => ({
      [TABS.CONTENT]: item[REMOTE_TAB.CONTENT],
      [TABS.ORDER]: item[REMOTE_TAB.ORDER],
      [TABS.TITLE]: item[REMOTE_TAB.TITLE]
    }))
  }
}

const inbound = (data) => {
  return {
    [LOCAL.ACTIVE]: data[REMOTE.ACTIVE],
    [LOCAL.CATEGORIES]: data[REMOTE.CATEGORIES],
    [LOCAL.DESCRIPTION]: data[REMOTE.DESCRIPTION],
    [LOCAL.ID]: data[REMOTE.ID],
    [LOCAL.IMAGE_PATH]: data[REMOTE.IMAGE_PATH],
    [LOCAL.INFORMATION_TABS]: data[REMOTE.INFORMATION_TABS],
    [LOCAL.KEYWORDS]: data[REMOTE.KEYWORDS],
    [LOCAL.NAME]: data[REMOTE.NAME],
    [LOCAL.SELLING_END_DATE]: RemoteDateUtils.parseRemoteDate(
      data[REMOTE.SELLING_END_DATE]
    ),
    [LOCAL.SELLING_START_DATE]: RemoteDateUtils.parseRemoteDate(
      data[REMOTE.SELLING_START_DATE]
    ),
    [LOCAL.SKU]: defaultValueWhenEmpty(data[REMOTE.SKU], ''),
    [LOCAL.SLUG]: defaultValueWhenEmpty(data[REMOTE.SLUG], ''),
    [LOCAL.STATUS]: CourseServiceStatusAdapter.inbound(data[REMOTE.STATUS]),
    [LOCAL.UPDATED_AT]: parseRemoteDateTimeFromUTC(data[REMOTE.UPDATED_AT]),
    [LOCAL.INSERTED_AT]: parseRemoteDateTimeFromUTC(data[REMOTE.INSERTED_AT]),
    [LOCAL.SUBCATEGORY_ID]: digCategoryId(data),
    [LOCAL.CATEGORY_ID]: digSubCategoryId(data),
    [LOCAL.PRICE]: parseRemoteDecimal(digFromPlan(data, REMOTE_PLAN.PRICE)),
    [LOCAL.COURSE_LENGTH]: parseRemoteInteger(
      digFromPlan(data, REMOTE_PLAN.DURATION_HOURS)
    ),
    [LOCAL.ACCESS_WINDOW_IN_MONTHS]: digFromPlan(
      data,
      REMOTE_PLAN.AVAILABILITY_MONTHS
    ),
    [LOCAL.TABS]: TabsAdapter.inbound(data[REMOTE.TABS]),
    [LOCAL.IMAGE_BINARY]: data.imgBinary || null
  }
}

export const ServiceAdapter = { inbound }
