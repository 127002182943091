import React, { useState, useEffect, useContext } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { Button, SelectSaraiva, MenuItem } from '@equipe-ninja/saraiva-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { getBannerList } from '../../../services/promotional-content/banners-pages'
import { useRequiredParamsAccess } from '../../../hooks/use-required-param-access'
import { GeneralContext } from '../../../contexts/GeneralContext'
import ContentTitle from '../../../components/ContentTitle'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { Home } from './Home'
import useStyles from './styles'
import { hasAccessToPromotionalPages } from '../../../domain/promotional-content/promotional-pages-access-for-brands'

export function BannersPage() {
  const history = useHistory()
  const classes = useStyles()
  const [tipoBanner, setTipoBanner] = useState('')
  const [bannerListActive, setBannerListActive] = useState([])
  const [bannerListInactive, setBannerListInactive] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const { setErrorMessage } = useContext(GeneralContext)

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const handleCreateBanner = () => {
    return history.push(
      generatePath(RoutesMapping.promotionalBannerCreate, { brand })
    )
  }

  const handleChangeBannerType = (e) => {
    setTipoBanner(e.target.value)
  }

  const hasBannerList = () => {
    return isFetching === false
  }

  useEffect(() => {
    setIsFetching(true)

    getBannerList()
      .then((list) => {
        const banners = list.filter(
          (item) =>
            item.type.toString() === (tipoBanner === 'rotary' ? '1' : '2')
        )
        let bannersActive = []
        let bannersInactive = []

        bannersActive = banners.filter((item) => item.status === 'ativo')
        bannersInactive = banners.filter((item) => item.status === 'inativo')

        setBannerListActive(bannersActive)
        setBannerListInactive(bannersInactive)
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar a lista de banners')
      })
      .finally(() => setIsFetching(false))
  }, [setErrorMessage, tipoBanner])

  return (
    <ContentWithHeader
      title="Gerenciamento de banners"
      backButtonRoute={generatePath(RoutesMapping.promotionalPage, {
        brand
      })}
    >
      <div className={classes.contentContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreateBanner}
        >
          CRIAR BANNER
        </Button>
      </div>

      <Divider className={classes.divider} />

      <ContentTitle title="Banners" size="small" />

      <SelectSaraiva
        disabled={false}
        label="Selecionar tipo de banner"
        size="sm"
        onChange={(e) => handleChangeBannerType(e)}
        value={tipoBanner}
      >
        <MenuItem value="">Nenhum Banner</MenuItem>
        <MenuItem value="rotary">Rotativo</MenuItem>
        <MenuItem value="sale">Ofertas em destaque</MenuItem>
      </SelectSaraiva>

      {isFetching && (tipoBanner === 'rotary' || tipoBanner === 'sale') ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : null}

      {hasBannerList() ? (
        <Home
          bannersAtivos={bannerListActive}
          bannersInativos={bannerListInactive}
          tipoBanner={tipoBanner}
        />
      ) : null}

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}
