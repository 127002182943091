export const USER_ORDER_REMOTE_FIELDS = {
  KEY: 'id',
  ORDER_NUMBER: 'number',
  ORDER_TOTAL_VALUE: 'total_value',
  ORDER_DATE: 'date',
  ORDER_ITEMS: 'items'
}

export const USER_ORDER_ITEMS_REMOTE_FIELDS = {
  ITEM_ID: 'id',
  ITEM_COURSE_ID: 'course_id',
  ITEM_DESCRIPTION: 'description',
  ITEM_VALUE: 'value',
  ITEM_COMPLETION_STATUS: 'status',
  ITEM_COVER_IMG: 'img'
}
