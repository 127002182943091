import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const OffersService = {
  async searchServiceBySku(sku) {
    const path = `offers/api/v1/services?sku=${sku}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  }
}

export default OffersService
