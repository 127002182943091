import { useCallback } from 'react'

/**
 * Gera uma funcao que fara um download de um arquivo
 *
 * ### Exemplo
 *
 * ```js
 * const { downloadFile } = useDownloadFile()
 * downloadFile('header1, header2', 'arquivo.csv') // Ira abrir o wizard de download
 * ```
 */
export const useDownloadFile = () => {
  const downloadFile = useCallback(
    (content, fileName, type = 'text/csv;charset=utf-8') => {
      const blob = new Blob([content], { type })

      const data = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = data
      link.download = fileName
      link.click()

      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
    []
  )

  return { downloadFile }
}
