import {
  COURSE_SERVICE_STATUS,
  COURSE_OVERVIEW_RAW_DATA,
  COURSE_ECOMMERCE_FIELDS
} from '../entities'

const { SERVICE } = COURSE_OVERVIEW_RAW_DATA
const { STATUS } = COURSE_ECOMMERCE_FIELDS
const { AVAILABLE, UNAVAILABLE } = COURSE_SERVICE_STATUS

export const courseRedirectCreationCheck = (overRawData, newStatus) => {
  const currentStatus = overRawData?.[SERVICE]?.[STATUS]

  return currentStatus === AVAILABLE && newStatus === UNAVAILABLE
}
