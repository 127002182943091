import { selector } from 'recoil'
import { subjectState } from '../atoms/subject-state'

export const allQuestionsDatabaseSelector = selector({
  key: 'allQuestionsDatabaseSelector',
  get: ({ get }) => {
    return {
      questionsDatabase: get(subjectState).questionsDatabase.data,
      total: get(subjectState).questionsDatabase.count,
      isFetchingQuestions: get(subjectState).isFetchingQuestions,
      checkedQuestions: get(subjectState).checkedQuestions
    }
  },
  set: (
    { get, set },
    { questionsDatabase, isFetchingQuestions, total, checkedQuestions }
  ) => {
    set(subjectState, {
      ...get(subjectState),
      questionsDatabase: {
        ...get(subjectState).questionsDatabase,
        data: questionsDatabase,
        count: total
      },
      isFetchingQuestions,
      checkedQuestions
    })
  }
})
