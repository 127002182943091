import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { putRequestResponseHandler } from '../../../utils/response-handlers'
import { BannerDetailsAdapter } from '../adapters/banner-details-adapter'
import {
  saveImageBannerRotationRecursive,
  saveImageBannerSales,
  saveImageBannerRotationWeb
} from './upload-image'

const saveNewImageBucketRotary = (formData, type) => {
  return new Promise((resolve, reject) => {
    if (type === 'recursive') {
      saveImageBannerRotationRecursive(formData)
        .then((response) => {
          resolve(response.data.name)
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      saveImageBannerRotationWeb(formData)
        .then((response) => {
          resolve(response.data.name)
        })
        .catch((error) => {
          reject(error)
        })
    }
  })
}

const saveNewImageBucketSales = (formData) => {
  return new Promise((resolve, reject) => {
    saveImageBannerSales(formData)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/* 
  Função para pegar o nome da imagem no url se image for 
    um arquivo envio para o bucket e pego o nome do arquivo
  @param {string || File} url - url da imagem || arquivo de imagem
  @param {string} type - tipo de banner
  @param {string} position - posição do banner
  @returns {string} nome da imagem
*/
const getNameImageInUrl = (image, typeBanner, typeImage) => {
  return new Promise((resolve) => {
    if (image instanceof File) {
      if (typeBanner === 'rotary') {
        const formDataImg = new FormData()
        formDataImg.append('file', image)
        saveNewImageBucketRotary(formDataImg, typeImage).then((response) => {
          resolve(response)
        })
      }

      const formDataImg = new FormData()
      formDataImg.append('file', image)
      saveNewImageBucketSales(formDataImg).then((response) => {
        resolve(response)
      })
    }
    if (image instanceof Array) {
      const listImage = image.map(async (img) => {
        const nameImage = await getNameImageInUrl(img, 'sales')
        return nameImage
      })
      Promise.all(listImage).then((responseAll) => {
        resolve(responseAll)
      })
    }

    /**
     * Tratando nome da imagem para não enviar uma URL
     */
    if (typeof image === 'string') {
      let urlSplit = image.split('/')
      urlSplit = urlSplit[4].split('?')
      resolve(urlSplit[0])
    }
  })
}

/**
 * Caso de uso para criacao de paginas promocionais
 * @returns lista de paginas promocionais apos passar por um adaptador de entrada
 */
const updateBanner = async (formValues) => {
  const path = `markets/api/v1/banners/${formValues.key}`
  const headers = httpClient.buildHeader({
    'session-token': getSessionToken()
  })
  if (formValues.type === 'rotary') {
    const resWeb = await getNameImageInUrl(formValues.imageWeb, 'rotary', 'web')
    formValues.imageWeb = resWeb

    const resRecursive = await getNameImageInUrl(
      formValues.imageRecursive,
      'rotary',
      'recursive'
    )
    formValues.imageRecursive = resRecursive
  } else {
    const res = await getNameImageInUrl(
      formValues.imageRecursive,
      formValues.type,
      'recursive'
    )

    formValues.imageRecursive = res
  }

  const payload = BannerDetailsAdapter.outbound(formValues)
  const responseClient = await httpClient.put(path, payload, headers)
  const json = await putRequestResponseHandler(responseClient)
  return BannerDetailsAdapter.inbound(json.data)
}

export { updateBanner }
