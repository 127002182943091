import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  divFlex: {
    display: 'flex',
    gap: 24
  },
  radio: {
    marginTop: 24,
    width: '100%'
  },
  radioLabel: {
    color: theme.palette.text.primary
  },
  topMargin: {
    marginTop: 24
  },
  root: {
    '& .MuiRadio-root': {
      color: theme.palette.primary.main,
      marginLeft: 8,
      '&:hover': {
        backgroundColor: 'rgba(98, 76, 146, .08)'
      }
    },
    '& .MuiFormControlLabel-root': {
      color: theme.palette.text.primary
    },
    '& .MuiTextField-root': {
      width: 416,
      margin: 0,
      fontSize: 16
    }
  }
}))

export default useStyles
