import { selector } from 'recoil'
import { courseOverviewState } from '../atom/course-overview-state'

export const courseFetchingControls = selector({
  key: 'courseFetchingControls',
  get: ({ get }) => {
    return {
      isFetching: get(courseOverviewState).isFetching,
      isExecutingTask: get(courseOverviewState).isExecutingTask,
      isLoading: get(courseOverviewState).isLoading
    }
  },
  set: ({ get, set }, controls) => {
    set(courseOverviewState, {
      ...get(courseOverviewState),
      ...controls
    })
  }
})
