import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '24px 0 40px 0'
  },

  dividerPurple: {
    margin: '0 0 46px 0'
  },

  searchWrapper: {
    marginBottom: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(39)
  },

  text: {
    marginTop: '40px',
    marginBottom: '24px',
    color: theme.palette.text.primary
  },

  textH4: {
    fontSize: '34px'
  },

  textH5: {
    fontSize: '24px',
    marginBottom: '40px'
  },

  textH6: {
    fontSize: '14px',
    marginBottom: '10px'
  },

  titleH6: {
    fontSize: '20px',
    marginTop: '30px',
    marginBottom: '8px',
    color: theme.palette.text.primary,
    '&:first-child': {
      marginTop: '0px'
    }
  },

  subcategory: {
    marginTop: 12
  },

  button: {
    borderRadius: 4
  },

  buttonExport: {
    marginLeft: '16px'
  },

  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  textDialog: {
    marginBottom: 16
  }
}))

export default useStyles
