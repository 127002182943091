import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  TextInput: {
    marginBottom: 24
  },
  TextInputCae: {
    marginBottom: 16
  },
  btn: {
    position: 'relative',
    left: '780px'
  }
}))

export default useStyles
