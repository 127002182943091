import { selector } from 'recoil'
import { courseTopicsState } from '../course-topics-state'

export const allQuestionsDatabaseSelector = selector({
  key: 'allQuestionsDatabaseSelector',
  get: ({ get }) => {
    return {
      questionsDatabase: get(courseTopicsState).questionsDatabase.data,
      total: get(courseTopicsState).questionsDatabase.count,
      isFetchingQuestions: get(courseTopicsState).isFetchingQuestions
    }
  },
  set: ({ get, set }, { questionsDatabase, isFetchingQuestions, total }) => {
    set(courseTopicsState, {
      ...get(courseTopicsState),
      questionsDatabase: {
        ...get(courseTopicsState).questionsDatabase,
        data: questionsDatabase,
        count: total
      },
      isFetchingQuestions
    })
  }
})
