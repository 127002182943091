/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react'
import { Snackbar } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'

import ConnectionsList from './ConnectionsList'
import ProductSelect from '../../components/ProductSelect'
import { GeneralContext } from '../../contexts/GeneralContext'
import { ConnectionsContext } from '../../contexts/ConnectionsContext'
import Messages from '../../helpers/messages'
import IntegrationsService from '../../services/integrationsService'

import useStyles from './styles.js'
import { snackbarStyle } from '../../layouts/styles'

function Connections() {
  const classes = useStyles()

  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    setLoading,
    setProduct
  } = useContext(GeneralContext)
  const snackbarStyles = snackbarStyle()

  const { setConnections } = useContext(ConnectionsContext)

  async function fetchConnections(product) {
    setProduct(product)

    if (!product?.value) {
      return
    }

    try {
      setLoading(true)

      const response = await IntegrationsService.listIntegrationsByTypeAndApp(
        'lti_client',
        product.token
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setConnections(result.data)
      setLoading(false)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setProduct({})
      setErrorMessage(Messages.fetchConnections.error)
      setLoading(false)
    }
  }

  return (
    <>
      <Snackbar
        classes={() => snackbarStyles}
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
        autoHideDuration={5000}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        classes={() => snackbarStyles}
        severity="warning"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
        autoHideDuration={5000}
      >
        {errorMessage}
      </Snackbar>

      <div className={classes.root}>
        <Typography variant="h3" className={classes.titleH3}>
          Gerenciar LTI
        </Typography>
        <ProductSelect onChange={fetchConnections} />
        <ConnectionsList />
      </div>
    </>
  )
}

export default Connections
