/* eslint-disable no-unused-vars */
import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { CampaignAdapter } from '../adapters/campaign-adapter'
import httpRequest from '../../../../gateway/httpRequest'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para criação de campanha
 * @returns a campanha criada ou levanta um erro
 */
const createDiscountCampaign = (formValues, dataCsv) => {
  return new Promise((resolve, reject) => {
    const path = '/offers/api/v1/campaign'
    const payload = CampaignAdapter.outbound(formValues, dataCsv)

    const headers = httpClient.buildHeader({
      'session-token': getSessionToken(),
      'Content-Type': 'multipart/form-data',
      accept: '*/*'
    })

    httpRequest()
      .post(path, payload, headers)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export { createDiscountCampaign }
