import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },

  container: {
    marginLeft: 132,
    marginRight: 132,
    marginBottom: 40
  },

  buttonManagement: {
    marginBottom: 40
  },

  divider: {
    marginTop: 8,
    marginBottom: 64
  },
  addButton: {
    marginTop: 32,
    marginBottom: 24
  },
  addWorksheet: {
    marginTop: 32,
    marginBottom: 24,
    marginLeft: 16
  }
}))

export default useStyles
