import {
  Button,
  DatePickerSaraiva,
  Alert,
  SelectSaraiva,
  MenuItem,
  Dialog,
  TextInput
} from '@equipe-ninja/saraiva-ui'
import Grid from '@material-ui/core/Grid'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Create, Info, CheckCircle } from '@material-ui/icons'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import useStyles from './styles'
import {
  getCourseOverview,
  updateCurseOverview,
  desactivateCourse
} from '../../../services/courses'
import {
  courseOverviewForm,
  courseOverviewState,
  courseOverviewRawData,
  courseFetchingControls
} from '../../../atoms/course'
import {
  COURSE_OVERVIEW as FORM,
  COURSE_ECOMMERCE_FIELDS,
  COURSE_FIELDS,
  COURSE_SERVICE_STATUS,
  courseServiceStatusLabel,
  COURSE_OVERVIEW_RAW_DATA
} from '../../../domain/courses/entities'
import { ContainerWithLoader } from '../../../components/ContainerWithLoader'
import { GeneralContext } from '../../../contexts/GeneralContext'
import {
  courseOverviewValidation,
  courseOverviewFieldValidation,
  courseIsAllowedToInactivate,
  courseUrlValidation,
  courseRedirectCreationCheck
} from '../../../domain/courses/business'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { SavingInfoWidget } from '../../../components/SavingInfoWidget'
import { useDefaultValueWhenEmpty } from '../../../hooks/use-default-value-when-empty'

const { COURSE, SERVICE } = COURSE_OVERVIEW_RAW_DATA

export function CourseCreate() {
  const classes = useStyles()
  const { brand, courseId } = useParams()
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [openDialogChangeName, setOpenDialogChangeName] = useState(false)
  const [openDialogChangeURL, setOpenDialogChangeURL] = useState(false)
  const [disableInactiveMenu, setDisableInactiveMenu] = useState(true)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [courseState, setCourseState] = useRecoilState(courseOverviewState)
  const setRawData = useSetRecoilState(courseOverviewRawData)
  const formatWhenEmpty = useDefaultValueWhenEmpty()
  const [{ values: formValues, errors: formErrors }, setFormValues] =
    useRecoilState(courseOverviewForm)
  const [{ isLoading }, setControls] = useRecoilState(courseFetchingControls)
  const [redirectForm, setRedirectForm] = useState({
    url: 'https://www.lfg.com.br/',
    error: false
  })

  const handleShowIcon = (status) => {
    if (status === 'success') {
      return <CheckCircle style={{ color: '#1F8563' }} />
    }
    return <Info className={classes.infoClass} />
  }

  const handleChangeCourseData = (field, value) => {
    const fieldError = courseOverviewFieldValidation(
      field,
      value,
      formValues,
      {
        courseValid: courseState.completionStatus.subjects,
        courseEcommerceValid: courseState.completionStatus.ecommerce
      },
      courseState.rawData[COURSE][COURSE_FIELDS.TITLE]
    )

    setFormValues((prev) => ({
      ...prev,
      errors: { ...prev.errors, ...fieldError },
      values: { ...prev.values, [field]: value }
    }))
  }

  const resetTitleValue = () => {
    setTimeout(() => {
      setFormValues((prev) => ({
        ...prev,
        values: {
          ...prev.values,
          [FORM.TITLE]: courseState.rawData[COURSE][COURSE_FIELDS.TITLE]
        },
        errors: {
          ...prev.errors,
          [FORM.TITLE]: false
        }
      }))
    })
  }

  const resetStatusValue = () => {
    setTimeout(() => {
      setFormValues((prev) => ({
        ...prev,
        values: {
          ...prev.values,
          [FORM.STATUS]:
            courseState.rawData[SERVICE][COURSE_ECOMMERCE_FIELDS.STATUS]
        },
        errors: {
          ...prev.errors,
          [FORM.TITLE]: false
        }
      }))
    })
  }
  const handleCloseDialogChangeName = () => {
    setOpenDialogChangeName(false)
    resetTitleValue()
  }

  const handleCloseDialogChangeURL = (reset = true) => {
    setOpenDialogChangeURL(false)
    if (reset) resetStatusValue()
  }

  const handleOpenDialogConfirm = () => {
    setOpenConfirmDialog(!openConfirmDialog)
  }

  const validationChangeCourseURL = () => {
    const error = courseUrlValidation(redirectForm)

    if (error.isValid) {
      handleOpenDialogConfirm()
      return handleCloseDialogChangeURL(false)
    }
    return setRedirectForm((prev) => ({ ...prev, error: error.errors.url }))
  }

  const configureStatusOptions = (rawData) => {
    const currentStatus = rawData[SERVICE][COURSE_ECOMMERCE_FIELDS.STATUS]

    setDisableInactiveMenu(!courseIsAllowedToInactivate(currentStatus))
  }

  const fetchDetails = useCallback(
    (id) => {
      setControls((prev) => ({ ...prev, isLoading: true }))
      getCourseOverview(id)
        .then((data) => {
          configureStatusOptions(data)
          setRawData(data)
        })
        .catch((err) => {
          setErrorMessage(err)
        })
        .finally(() => {
          setControls((prev) => ({ ...prev, isLoading: false }))
        })
    },
    [setErrorMessage, setRawData, setControls]
  )

  const generateFullPathCurrentUrl = () => {
    const currentSlug =
      courseState.rawData[SERVICE][COURSE_ECOMMERCE_FIELDS.SLUG]

    return `https://lfg.com.br/${currentSlug}`
  }

  const navigateToCourseList = () => {
    history.replace(generatePath(RoutesMapping.courses, { brand }))
  }

  const checkAndDesactivateCourse = (callback) => {
    if (
      courseRedirectCreationCheck(courseState.rawData, formValues[FORM.STATUS])
    ) {
      const serviceId = courseState.rawData[SERVICE][COURSE_ECOMMERCE_FIELDS.ID]

      setControls((prev) => ({ ...prev, isExecutingTask: true }))

      desactivateCourse(
        serviceId,
        redirectForm.url,
        generateFullPathCurrentUrl()
      )
        .then(() => {
          setSuccessMessage('Curso desativado com sucesso')
          callback()
          setTimeout(() => {
            navigateToCourseList()
          })
        })
        .catch((error) => {
          setErrorMessage(error.message)
        })
        .finally(() => {
          setControls((prev) => ({ ...prev, isExecutingTask: false }))
        })
    }
  }

  const confirmChangeUrl = () => {
    checkAndDesactivateCourse(() => handleOpenDialogConfirm())
  }

  const saveOverviewData = (data, successCallback = () => null) => {
    const courseIdentifier = courseState.rawData[COURSE][COURSE_FIELDS.ID]
    const serviceId = courseState.rawData[SERVICE][COURSE_ECOMMERCE_FIELDS.ID]

    setCourseState((prev) => ({ ...prev, isFetching: true }))
    updateCurseOverview(courseIdentifier, serviceId, data)
      .then(() => {
        successCallback()
        fetchDetails(courseId)
        setSuccessMessage('Dados salvos com sucesso')
      })
      .catch(() => {
        setErrorMessage('Falha ao salvar os dados')
      })
      .finally(() => {
        setCourseState((prev) => ({ ...prev, isFetching: false }))
      })
  }

  const saveCourse = () => {
    const { errors, isValid } = courseOverviewValidation(formValues, {
      courseValid: courseState.completionStatus.subjects,
      courseEcommerceValid: courseState.completionStatus.ecommerce
    })

    if (!isValid) {
      setFormValues((prev) => ({ ...prev, errors }))
      setErrorMessage(
        'Não foi possivel salvar as modificações, verifique os campos em vermelho'
      )
      return
    }

    saveOverviewData(formValues)
  }

  const handleChangeCourseName = () => {
    saveOverviewData({ [FORM.TITLE]: formValues[FORM.TITLE] }, () => {
      fetchDetails(courseId)
      handleCloseDialogChangeName()
    })
  }

  const navigateToRegisterEcommerce = () => {
    history.push(
      generatePath(RoutesMapping.registerEcommerce, {
        brand,
        id: courseId
      })
    )
  }

  const navigateToRegisterElearning = () => {
    history.push(
      generatePath(RoutesMapping.registerElearning, {
        brand,
        id: courseId,
        name: formValues[FORM.TITLE]
      })
    )
  }

  const mostRecentDate = () => {
    if (!courseState.rawData?.[SERVICE]?.[COURSE_ECOMMERCE_FIELDS.UPDATED_AT])
      return null

    const serviceDate =
      courseState.rawData?.[SERVICE]?.[COURSE_ECOMMERCE_FIELDS.UPDATED_AT]
    const courseDate = courseState.rawData?.[COURSE]?.[COURSE_FIELDS.UPDATED_AT]

    return serviceDate > courseDate ? serviceDate : courseDate
  }

  const subjectsAlertMessage = () => {
    const { subjects, fullpostedCount } = courseState.completionStatus

    if (subjects) {
      return `${fullpostedCount} disciplina(s) disponibilizada(s) para os alunos.`
    }
    return 'Disponibilize para o aluno as disciplinas necessárias para que este curso possa ser publicado.'
  }

  const ecommerceAlertMessage = () => {
    if (courseState.completionStatus.ecommerce) {
      return 'Cadastro das informarções no e-commerce estão finalizadas.'
    }
    return 'Finalize o formulário para que este curso possa ser publicado.'
  }

  useEffect(() => {
    if (
      formValues[FORM.STATUS] === COURSE_SERVICE_STATUS.UNAVAILABLE &&
      courseRedirectCreationCheck(courseState.rawData, formValues[FORM.STATUS])
    ) {
      setOpenDialogChangeURL(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues[FORM.STATUS]])

  const isRenamingDisabled = () => {
    if (courseState.isFetching) return true

    return (
      !!formErrors[FORM.TITLE] ||
      formValues[FORM.TITLE] ===
        courseState.rawData?.[COURSE]?.[COURSE_FIELDS.TITLE]
    )
  }

  useEffect(() => {
    fetchDetails(courseId)
  }, [fetchDetails, courseId])

  return (
    <ContainerWithLoader isLoading={isLoading}>
      <ContentWithHeader
        title={formValues[FORM.TITLE]}
        backButtonRoute={generatePath(RoutesMapping.courses, {
          brand
        })}
        styleTitle={{ marginBottom: '8px' }}
      >
        <h6 className={`${classes.textH6} ${classes.marginBottom}`}>
          SKU:{' '}
          {formatWhenEmpty(
            courseState.rawData?.[SERVICE]?.[COURSE_ECOMMERCE_FIELDS.SKU]
          )}
        </h6>

        <div className={classes.DivSave}>
          <SavingInfoWidget savedAt={mostRecentDate()} />
        </div>

        <p className={classes.TextBody}>
          Para publicar um novo curso, é necessário cadastrar as informações
          chaves deste curso no Ambiente de aprendizagem e no E-commerce. O
          cadastro poder ser feito simultaneamente e as informações salvas
          automaticamente.
        </p>

        <div
          className={`${classes.Row} ${classes.SpaceBetween} ${classes.marginTop}`}
        >
          <div
            className={`${classes.CardContextRegister} ${
              courseState.completionStatus.subjects && classes.BackgroundSuccess
            }`}
          >
            <main className={classes.CardMain}>
              <div className={`${classes.Row} ${classes.SpaceBetween}`}>
                <h6 className={classes.textH6}>Ambiente de aprendizagem</h6>

                {handleShowIcon(
                  courseState.completionStatus.subjects ? 'success' : 'info'
                )}
              </div>

              <p className={classes.CardParagraph}>
                Aqui você poderá cadastrar as informações deste curso para
                dentro do ambiente de aprendizagem. Isto envolverá a criação de
                disciplinas novas ou existentes, os temas e aulas.
              </p>

              <Alert
                variant={
                  courseState.completionStatus.subjects
                    ? 'outlined'
                    : 'standard'
                }
                severity={
                  courseState.completionStatus.subjects ? 'success' : 'info'
                }
              >
                <text className={classes.TextMessage}>
                  {subjectsAlertMessage()}
                </text>
              </Alert>
            </main>

            <footer className={classes.CardFooter}>
              <Button color="secondary" onClick={navigateToRegisterElearning}>
                <text className={classes.TextButton}>
                  Cadastrar no Ambiente de aprendizagem
                </text>
              </Button>
            </footer>
          </div>

          <div
            className={`${classes.CardContextRegister} ${
              courseState.completionStatus.ecommerce &&
              classes.BackgroundSuccess
            }`}
          >
            <main className={classes.CardMain}>
              <div className={`${classes.Row} ${classes.SpaceBetween}`}>
                <h6 className={classes.textH6}>E-commerce</h6>
                {handleShowIcon(
                  courseState.completionStatus.ecommerce ? 'success' : 'info'
                )}
              </div>
              <p className={classes.CardParagraph}>
                Aqui você poderá cadastrar as informações deste curso que
                aparecerão no e-commerce da LFG. Você encontrará formulários de
                descrições, links, imagens, preço, etc.
              </p>

              <Alert
                variant={
                  courseState.completionStatus.ecommerce
                    ? 'outlined'
                    : 'standard'
                }
                severity={
                  courseState.completionStatus.ecommerce ? 'success' : 'info'
                }
              >
                <text className={classes.TextMessage}>
                  {ecommerceAlertMessage()}
                </text>
              </Alert>
            </main>
            <footer className={classes.CardFooter}>
              <Button color="secondary" onClick={navigateToRegisterEcommerce}>
                <text className={classes.TextButton}>
                  Cadastrar no E-commerce
                </text>
              </Button>
            </footer>
          </div>
        </div>
        <div className={classes.CardContext}>
          <main className={classes.CardMain}>
            <h5 className={classes.textH5}>Publicar</h5>

            <p className={classes.CardParagraph}>
              Pronto para publicar este curso? <br />
              Certifique-se que o todas as disciplinas estáo cadastradas
              corretamente e que as informações no E-commerce foram preenchidas
              de acordo.
            </p>

            <h6 className={classes.textH6}>Nome do curso</h6>

            <div className={classes.Row}>
              <p className={classes.TextBody}>
                {courseState.rawData?.[COURSE]?.[COURSE_FIELDS.TITLE]}
              </p>

              <Create
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenDialogChangeName(true)}
              />
            </div>

            <Grid
              container
              spacing={3}
              className={`${classes.marginTop} ${classes.marginBottom}`}
            >
              <Grid item sm={6}>
                <DatePickerSaraiva
                  value={formValues[FORM.START_DATE]}
                  onChange={(value) =>
                    handleChangeCourseData(FORM.START_DATE, value)
                  }
                  label="Data de início da venda*"
                  size="full"
                  error={!!formErrors[FORM.START_DATE]}
                  helperText={formErrors[FORM.START_DATE]}
                />
              </Grid>
              <Grid item sm={6}>
                <DatePickerSaraiva
                  value={formValues[FORM.END_DATE]}
                  onChange={(value) =>
                    handleChangeCourseData(FORM.END_DATE, value)
                  }
                  label="Data de fim da venda*"
                  size="full"
                  error={!!formErrors[FORM.END_DATE]}
                  helperText={formErrors[FORM.END_DATE]}
                />
              </Grid>
            </Grid>

            <SelectSaraiva
              error={!!formErrors[FORM.STATUS]}
              helperText={formErrors[FORM.STATUS]}
              value={formValues[FORM.STATUS]}
              onChange={(e) => {
                handleChangeCourseData(FORM.STATUS, e.target.value)
              }}
              label="Status do curso*"
              required
              size="full"
            >
              <MenuItem value="">Nenhum status selecionado</MenuItem>

              <MenuItem value={COURSE_SERVICE_STATUS.DRAFT}>
                {courseServiceStatusLabel(COURSE_SERVICE_STATUS.DRAFT)}
              </MenuItem>

              <MenuItem
                value={COURSE_SERVICE_STATUS.UNAVAILABLE}
                disabled={disableInactiveMenu}
              >
                {courseServiceStatusLabel(COURSE_SERVICE_STATUS.UNAVAILABLE)}
              </MenuItem>

              <MenuItem value={COURSE_SERVICE_STATUS.AVAILABLE}>
                {courseServiceStatusLabel(COURSE_SERVICE_STATUS.AVAILABLE)}
              </MenuItem>
            </SelectSaraiva>
          </main>
        </div>

        <div className={classes.RowAction}>
          <Button
            color="primary"
            disabled={courseState.isFetching}
            onClick={saveCourse}
            variant="contained"
          >
            SALVAR
          </Button>
        </div>

        <Dialog
          open={openDialogChangeName}
          title="Renomear curso"
          secondaryButton="cancelar"
          primaryButton="salvar"
          size="lg"
          handleClose={handleCloseDialogChangeName}
          handleConfirm={handleChangeCourseName}
          isDisabled={isRenamingDisabled()}
        >
          <div>
            <div className={`${classes.CardDetails} ${classes.marginBottom}`}>
              Caso este curso já esteja a venda, a alteração de seu nome
              aparecerá para o curso tanto no E-commerce, quanto no Ambiente de
              aprendizagem.
            </div>
            <TextInput
              color="primary"
              size="full"
              label="Nome do curso"
              onChange={(e) =>
                handleChangeCourseData(FORM.TITLE, e.target.value)
              }
              required
              fullWidth
              value={formValues[FORM.TITLE]}
              error={!!formErrors[FORM.TITLE]}
              helperText={formErrors[FORM.TITLE]}
            />
          </div>
        </Dialog>

        <Dialog
          open={openDialogChangeURL}
          title="Inativar curso"
          secondaryButton="cancelar"
          primaryButton="salvar"
          size="lg"
          handleClose={handleCloseDialogChangeURL}
          handleConfirm={validationChangeCourseURL}
        >
          <div style={{ width: '100%' }}>
            <div className={`${classes.CardDetails} ${classes.marginBottom}`}>
              Determine para onde os usuários que acessarem a URL originalmente
              cadastrada deste curso serão redirecionados.
            </div>
            <TextInput
              color="primary"
              size="full"
              label="URL de redirecionamento"
              onChange={(e) =>
                setRedirectForm((prev) => ({ ...prev, url: e.target.value }))
              }
              required
              error={!!redirectForm.error}
              helperText={redirectForm.error}
              value={redirectForm.url}
            />
            <div className={classes.helpText}>
              Se aplicavél inserir o subdiretório da URL, ou seja as páginas
              internas do seu site: http://www.lfg.com.br/
              <strong>concursos-cursos-carreiras-juridicas</strong>
            </div>
          </div>
        </Dialog>

        <Dialog
          title="Inativar curso"
          secondaryButton="CANCELAR"
          primaryButton="SALVAR"
          size="lg"
          handleClose={handleOpenDialogConfirm}
          handleConfirm={confirmChangeUrl}
          open={openConfirmDialog}
          label="Sim, tenho certeza!"
          alert
        >
          Quando inativado, este curso não poderá ser utilizados ou comprado por
          nenhum usuário. Assim, todos os usuários que tiverem este curso em
          andamento, não poderão utiliza-lo mais.Esta ação pode ser desfeita a
          qualquer momento.
        </Dialog>

        <SnackbarOutlet />
      </ContentWithHeader>
    </ContainerWithLoader>
  )
}
