export const VOUCHER_STATUSES = Object.freeze({
  ACTIVE: {
    label: 'Disponível',
    value: true
  },
  INACTIVE: {
    label: 'Inativo',
    value: false
  }
})

export const getVoucherStatusLabelByValue = (value) => {
  return Object.values(VOUCHER_STATUSES).find((e) => e.value === value).label
}
