import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../helpers/mock-server-utils'
import {
  USER_ORDER_REMOTE_FIELDS as OF,
  USER_ORDER_ITEMS_REMOTE_FIELDS as IF
} from '../remote-fields/user-order-remote-fields'
import { userOrdersInboundAdapter } from '../adapters/user-orders-adapters'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar os pedidos de um usuario
 * @returns Promise com a resposta ou rejeita com erro
 */
const getUserOrders = (id) => {
  return new Promise((resolve, reject) => {
    const path = `api/wip/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, headers)
      .then((response) => {
        resolve(userOrdersInboundAdapter(response))
      })
      .catch((error) => reject(error))
  })
}

const factory = (index) => {
  return [
    {
      [OF.KEY]: index,
      [OF.ORDER_DATE]: `2022-03-${((index % 28) + 1)
        .toString()
        .padStart(2, '0')}T13:37:37`,
      [OF.ORDER_NUMBER]: `1002592${index.toString().padStart(2, '0')}`,
      [OF.ORDER_TOTAL_VALUE]: 360.0,
      [OF.ORDER_ITEMS]: [
        {
          [IF.ITEM_ID]: 1,
          [IF.ITEM_DESCRIPTION]:
            'Cálculos e Teses de Benefícios Previdenciários',
          [IF.ITEM_VALUE]: 60.0,
          [IF.ITEM_COMPLETION_STATUS]: 1,
          [IF.ITEM_COVER_IMG]: 'https://picsum.photos/id/0/286/141',
          [IF.ITEM_COURSE_ID]: 101
        },
        {
          [IF.ITEM_ID]: 2,
          [IF.ITEM_DESCRIPTION]: 'Legislação Penal Especial',
          [IF.ITEM_VALUE]: 150.5,
          [IF.ITEM_COMPLETION_STATUS]: 2,
          [IF.ITEM_COVER_IMG]: 'https://picsum.photos/id/2/286/141',
          [IF.ITEM_COURSE_ID]: 102
        },
        {
          [IF.ITEM_ID]: 3,
          [IF.ITEM_DESCRIPTION]: 'Legislação Penal Especial',
          [IF.ITEM_VALUE]: 149.5,
          [IF.ITEM_COMPLETION_STATUS]: 3,
          [IF.ITEM_COVER_IMG]: 'https://picsum.photos/id/3/286/141',
          [IF.ITEM_COURSE_ID]: 103
        }
      ]
    },
    {
      [OF.KEY]: index + 1,
      [OF.ORDER_DATE]: `2022-03-${((index % 28) + 1)
        .toString()
        .padStart(2, '0')}T13:37:37`,
      [OF.ORDER_NUMBER]: `1002592${(index + 1).toString().padStart(2, '0')}`,
      [OF.ORDER_TOTAL_VALUE]: 99.0,
      [OF.ORDER_ITEMS]: [
        {
          [IF.ITEM_ID]: 4,
          [IF.ITEM_DESCRIPTION]:
            'Cálculos e Teses de Benefícios Previdenciários',
          [IF.ITEM_VALUE]: 99.0,
          [IF.ITEM_COMPLETION_STATUS]: 1,
          [IF.ITEM_COVER_IMG]: 'https://picsum.photos/id/0/286/141',
          [IF.ITEM_COURSE_ID]: 104
        }
      ]
    }
  ]
}

const mockedResponse = (id) => {
  return mockedServerAnswer(factory(id), true, 1000, userOrdersInboundAdapter)
}

export { mockedResponse as getUserOrders }
