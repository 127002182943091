import React, { useEffect, useCallback, useContext, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Tabs } from '@equipe-ninja/saraiva-ui'
import {
  useHistory,
  useParams,
  generatePath,
  useLocation,
  matchPath
} from 'react-router-dom'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { DetailPageSwitch } from './detailPageSwitch'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { ContainerWithLoader } from '../../../components/ContainerWithLoader'
import {
  getUserDetail,
  getUserOrders
} from '../../../services/user-management-content'
import { useRequiredParamsAccess } from '../../../hooks/use-required-param-access'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { USER_MANAGEMENT_LIST_ITEM_FIELDS } from '../../../domain/user-management-content/user-management-page'
import { userDetailsState, userOrdersState } from '../../../atoms/user-details'

const { FULL_NAME } = USER_MANAGEMENT_LIST_ITEM_FIELDS

export function UserDetail() {
  const history = useHistory()
  const location = useLocation()
  const { brand } = useParams()
  const { setErrorMessage } = useContext(GeneralContext)
  const { userId } = useRequiredParamsAccess(
    'userId',
    RoutesMapping.userManagementPageEntryPoint
  )
  const [userDetail, setUserDetail] = useRecoilState(userDetailsState)
  const setOrders = useSetRecoilState(userOrdersState)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const fetchDetails = useCallback(() => {
    setUserDetail((prev) => ({ ...prev, isFetching: true }))
    getUserDetail(userId)
      .then((data) => {
        setUserDetail((prev) => ({ ...prev, ...data }))
      })
      .catch(() => {
        setErrorMessage('Não foi possível carregar os detalhes do usuário')
      })
      .finally(() => setUserDetail((prev) => ({ ...prev, isFetching: false })))
  }, [userId, setErrorMessage, setUserDetail])

  const handleChangeURL = (index) => {
    if (index === 1) {
      return history.push(
        generatePath(RoutesMapping.userManagementPageListOrder, {
          brand,
          userId
        })
      )
    }
    return history.push(
      generatePath(RoutesMapping.userManagementPageDetail, {
        brand,
        userId
      })
    )
  }

  const getTabIndex = () => {
    const match = matchPath(location.pathname, {
      path: RoutesMapping.userManagementPageDetail
    })

    if (!match.isExact) {
      return setSelectedTabIndex(1)
    }

    return setSelectedTabIndex(0)
  }

  const fetchOrders = useCallback(() => {
    setUserDetail((prev) => ({ ...prev, isFetchingOrders: true }))
    getUserOrders(userId)
      .then((data) => {
        setOrders(data)
      })
      .catch(() => {
        setErrorMessage('Não foi possível carregar os pedidos do usuário')
      })
      .finally(() =>
        setUserDetail((prev) => ({ ...prev, isFetchingOrders: false }))
      )
  }, [userId, setErrorMessage, setOrders, setUserDetail])

  useEffect(() => {
    fetchDetails()
  }, [fetchDetails])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  useEffect(() => {
    getTabIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContentWithHeader
      title={userDetail[FULL_NAME.value]}
      backButtonRoute={generatePath(RoutesMapping.userManagementPage, {
        brand
      })}
      styleTitle={{ fontSize: '34px' }}
    >
      <ContainerWithLoader isLoading={userDetail.isFetching}>
        <Tabs
          screenSize="desktop"
          color="primary"
          variant="fullWidth"
          onChange={handleChangeURL}
          selectedTabIndex={selectedTabIndex}
          showDivider
        >
          {[
            {
              label: 'DETALHES',
              content: <DetailPageSwitch />
            },
            {
              label: 'PEDIDO',
              content: <DetailPageSwitch />
            }
          ]}
        </Tabs>
      </ContainerWithLoader>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}
