import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  tabsContainer: {
    width: '100%',
    marginTop: '-20px',
    '& > div': {
      width: '100%'
    }
  },
  ...spacing
}))

export default useStyles
