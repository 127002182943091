import { makeStyles } from '@material-ui/core/styles'
import {
  spacing,
  textClasses,
  pageClasses
} from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...textClasses(theme),
  ...pageClasses(theme),
  orderSection: {
    padding: '24px 16px'
  },
  orderNumberLabel: {
    lineHeight: '24px',
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: '0.15px'
  },
  infoMargin: {
    marginTop: '2px'
  },
  orderHeader: {
    backgroundColor: '#E0E0E0',
    borderRadius: '8px',
    padding: '24px 16px',
    display: 'flex'
  },
  orderNumberInfo: {
    flex: 1
  },
  orderValueAndLink: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 2
  },
  orderItemCard: {
    padding: '24px 16px',
    minHeight: '195px'
  }
}))

export default useStyles
