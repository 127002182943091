export const COURSE_SERVICES_FOR_DISCOUNTS_FIELDS = {
  NAME: 'name',
  ID: 'id',
  SKU: 'sku',
  FROM_VALUE: 'fromValue',
  TO_VALUE: 'toValue',
  PERCENT_DISCOUNT: 'percentDiscount',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  NOTE: 'note',
  IS_VALID: 'isValid'
}
