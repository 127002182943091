import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'

/**
 * Caso de uso para carregamento das disciplinas disponíveis
 * @returns lista de disciplinas gerais ou de acordo com pesquisa de palavra-chave
 */

const Subjects = {
  async getCoursesSubjects(search, perPage, page) {
    const path = `offers/api/v1/backoffice/subjects?search=${search}&per_page=${perPage}&page=${page}`

    let headers = {
      'session-token': getSessionToken()
    }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)

    return response
  }
}

export default Subjects
