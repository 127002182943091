export const PAYMENT_INFO_REMOTE_FIELDS = Object.freeze({
  ORDER_ITEM_ID: 'id',
  PAYMENT_TYPE: 'payment_type',

  CREDIT_CARD: 'credit_card',
  CREDIT_CARD_HOLDER_NAME: 'holder_name',
  INSTALLMENTS: 'installments',

  BANK_SLIP_CODE: 'code',
  BANK_SLIP_DUE_DATE: 'due_date',
  PERSON_ID: 'person_id',
  PERSON_NAME: 'person_name',

  VALUE: 'value'
})
