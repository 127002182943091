import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  cardSpacing: {
    marginLeft: 24
  },
  userContainer: {
    marginLeft: 8
  }
}))

export default useStyles
