import { COURSE_TABS_FIELDS } from '../product-course-fields'

export const createDefaultEmptyTab = ({ order } = {}) => {
  return {
    [COURSE_TABS_FIELDS.CONTENT]: '<span><span>',
    [COURSE_TABS_FIELDS.ID]: 0,
    [COURSE_TABS_FIELDS.ORDER]: order || '',
    [COURSE_TABS_FIELDS.TITLE]: ''
  }
}

export const createDefaultTabError = () => {
  return {
    [COURSE_TABS_FIELDS.CONTENT]: false,
    [COURSE_TABS_FIELDS.ID]: false,
    [COURSE_TABS_FIELDS.ORDER]: false,
    [COURSE_TABS_FIELDS.TITLE]: false
  }
}
