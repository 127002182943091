import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  maxWidthColumn: {
    padding: 0
  },
  table: {
    width: 804,
    left: 372,
    top: 433,
    borderRadius: '8px 8px 5px 5px',
    marginLeft: 132,
    marginBottom: 80
  },
  headerCell: {
    font: 'roboto'
  },
  checkbox: {
    color: '#624C92',
    '&. checked': {
      color: '#624C92'
    }
  },
  checked: {},
  text: {
    height: 50,
    paddingTop: 16,
    paddingLeft: 19
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '-35px'
  },
  btnEdit: {
    color: '#624C92',
    width: 162,
    height: 32,
    marginRight: 14,
    marginTop: 4
  },
  btnDelete: {
    color: '#A42C31',
    width: 104,
    height: 32,
    marginRight: 14,
    marginTop: 4
  }
})

export default useStyles
