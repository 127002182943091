import React, { useContext } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Users from '../../../../../services/accounts/users'
import messages from '../../../../../helpers/messages'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
export function UserDeleteDialog({
  userDialogOpen,
  setUserDialogOpen,
  userId
}) {
  const history = useHistory()
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const handleClose = () => {
    setUserDialogOpen(false)
  }

  const backPage = () => {
    history.push('/dados-dos-usuarios')
  }

  async function confirmDeletion() {
    try {
      const response = await Users.deleteUser(userId)
      if (response.ok) {
        setSuccessMessage(messages.userDelete.success)
        setTimeout(() => {
          setSuccessMessage('')
          handleClose()
          backPage()
        }, 2000)
      } else {
        handleClose()
        setErrorMessage(messages.userDelete.error)
      }
    } catch (error) {
      console.error('Exception while deleting user', error)
    }
  }

  return (
    <div>
      <Dialog
        title="Excluir usuário "
        secondaryButton="CANCELAR"
        primaryButton="EXCLUIR"
        size="md"
        open={userDialogOpen}
        handleClose={() => handleClose()}
        handleConfirm={() => confirmDeletion()}
        alert
        label="Sim, tenho certeza!"
      >
        <Typography variant="body1" style={{ color: '#000' }}>
          Tem certeza que deseja excluir o usuário?
        </Typography>
        <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Ao realizar essa ação todas as informações do usuário irão ser
          excluídas e ele irá perder todos os acessos.
        </Typography>
      </Dialog>
    </div>
  )
}
UserDeleteDialog.propTypes = {
  userDialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
}
