import { makeStyles } from '@material-ui/core/styles'
import { pageClasses, spacing } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => {
  return {
    ...pageClasses(theme),
    ...spacing,
    actionsSection: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '42px',
      marginTop: '18px',
      alignItems: 'flex-end'
    }
  }
})

export default useStyles
