export default function createH5pContent(data) {
  const content = data.content_json
  const h5p = data.h5p_json
  const h5pScripts = data.h5p_scripts
  const h5pStyles = data.h5p_styles

  const script = document.createElement('script')
  script.append('window.H5PIntegration.contents = []; ')
  const h5pintegrationcontent = `window.H5PIntegration.contents['cid-1'] = ${JSON.stringify(
    {
      library: h5p.mainLibrary,
      jsonContent: JSON.stringify(content),
      fullScreen: false, // No fullscreen support
      exportUrl: '',
      embedCode: '',
      mainId: '1',
      url: '/1,',
      title: h5p.title,
      contentUserData: {
        0: {
          // Sub ID (0 = main content/no id)
          state: false // Data ID
        }
      },
      displayOptions: {
        frame: true, // Show frame and buttons below H5P
        export: true, // Display download button
        embed: true, // Display embed button
        copyright: true, // Display copyright button
        icon: true // Display H5P icon
      },
      metadata: {
        title: h5p.title,
        defaultLanguage: h5p.language,
        license: h5p.license
      },
      styles: h5pStyles,
      scripts: h5pScripts
    }
  )}; `

  script.append(h5pintegrationcontent)
  return script
}
