import { COURSE_OVERVIEW_RAW_DATA as LOCAL } from '../../../domain/courses/entities'
import { COURSE_OVERVIEW_FIELDS as REMOTE } from '../remote-fields/course-overview-fields'
import { ServiceAdapter } from './service-adapter'
import { CourseAdapter } from './course'

const inbound = (data) => {
  return {
    [LOCAL.COURSE]: CourseAdapter.inbound(data[REMOTE.COURSE]),
    [LOCAL.SERVICE]: ServiceAdapter.inbound(data[REMOTE.SERVICE]),
    [LOCAL.FULL_POSTED_SUBJECT_COUNT]: data[REMOTE.FULL_POSTED_COUNT]
  }
}

export const CourseOverviewAdapter = {
  inbound
}
