/* eslint-disable no-unused-vars */
import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para remoção de uma campanha de desconto
 * @returns uma promise que irá resolver quando a ação for bem sucedida e rejeitará quando falhar
 */
const deleteDiscountCampaign = async (discountCampaignId) => {
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const path = `/offers/api/v1/campaign/${discountCampaignId}`
  const response = await httpClient.delete(path, null, headers)
  return response
}

export { deleteDiscountCampaign }
