/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { isAuthenticated, redirectToLogin, setSessionToken } from '../../auth'
import Layout from '../../layouts'

export const PrivateRoute = ({
  component: Component,
  defaultLayout,
  ...routeParams
}) => {
  const searchValues = queryString.parse(routeParams.location.search)

  if (searchValues.sessionToken) {
    setSessionToken(searchValues.sessionToken)
  }

  return (
    <Route
      {...routeParams}
      render={(props) => {
        if (isAuthenticated() && defaultLayout) {
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          )
        }

        if (isAuthenticated()) {
          return <Component {...props} />
        }

        redirectToLogin()
        return null
      }}
    />
  )
}

PrivateRoute.propTypes = {
  defaultLayout: PropTypes.bool,
  component: PropTypes.func.isRequired
}

PrivateRoute.defaultProps = {
  defaultLayout: false
}

/* eslint-enable react/jsx-props-no-spreading */
