import { COURSE_FIELDS as F } from '../../../domain/courses'
import { COURSE_REMOTE_FIELDS as R } from '../remote-fields/course'
import { defaultValueWhenEmpty } from '../../utils/inbound-utils'
import { parseRemoteDateTimeFromUTC } from '../../utils/remote-datetime-utils'

const inbound = (data) => ({
  [F.ID]: data?.[R.ID],
  [F.TITLE]: data?.[R.TITLE],
  [F.INSERTED_AT]: defaultValueWhenEmpty(
    parseRemoteDateTimeFromUTC(data?.[R.INSERTED_AT]),
    null
  ),
  [F.UPDATED_AT]: defaultValueWhenEmpty(
    parseRemoteDateTimeFromUTC(data?.[R.UPDATED_AT]),
    null
  )
})

const outbound = (data) => ({
  [R.ID]: data[F.ID],
  [R.TITLE]: data[F.TITLE]
})

export const CourseAdapter = {
  inbound,
  outbound
}
