import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  CardContext: {
    border: '1px solid rgb(235,235,235)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    marginTop: '24px',
    '&:first-child': {
      marginTop: '40px'
    }
  },
  CardMain: {
    padding: '24px 16px 0px'
  },
  CardTitle: {
    fontWeight: 'normal',
    marginTop: '0px',
    marginBottom: '12px',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  CardDetails: {
    color: 'rgba(0,0,0,.54)',
    fontSize: '16px',
    marginBottom: '13px',
    lineHeight: '150%'
  },
  CardFooter: {
    borderTop: '1px solid rgb(235,235,235)',
    padding: '4px 16px 4px 4px',
    color: '#245A93',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span:hover': {
      cursor: 'pointer'
    }
  },
  Icon: {
    cursor: 'pointer'
  },
  CardJustify: {
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: '8px',
    marginTop: '8px',
    padding: '24px 16px'
  },
  subTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  text: {
    fontWeight: 'normal',
    marginBottom: '12px',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  CardMainPaddingBottom: {
    paddingBottom: '11px'
  }
}))

export default useStyles
