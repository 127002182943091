import React, { Component } from 'react'
import PropTypes from 'prop-types'
import contentsService from '../../services/contentsService'
import FolderList from '../molecules/folderList'

class ShowFolder extends Component {
  constructor(props) {
    super(props)
    let { id } = props
    if (id === undefined) {
      id =
        props && props.match && props.match.params && props.match.params.id
          ? props.match.params.id
          : null
    }

    this.state = {
      list: [],
      path: [],
      id
    }
  }

  componentDidMount() {
    const { id } = this.state
    contentsService()
      .getFolderContent(id)
      .then((contentResponse) => {
        this.setState({
          list: contentResponse.data.list,
          path: contentResponse.data.path
        })
      })
  }

  render() {
    const { list, path } = this.state
    return <FolderList list={list} path={path} />
  }
}
export default ShowFolder

ShowFolder.propTypes = {
  id: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}
ShowFolder.defaultProps = {
  match: PropTypes.shape
}
