import { CARD_COMPLEMENTARY_CONTENT_FIELDS } from '../../../domain/complementary-material/card-complementary-content-fields'
import { COMPLEMENTARY_CONTENT_REMOTE_FIELDS } from '../remote-fields/complementary-content-remote-fields'

const inbound = (remoteData) => {
  return {
    [CARD_COMPLEMENTARY_CONTENT_FIELDS.ID]:
      remoteData[COMPLEMENTARY_CONTENT_REMOTE_FIELDS.ID],
    [CARD_COMPLEMENTARY_CONTENT_FIELDS.NAME]:
      remoteData[COMPLEMENTARY_CONTENT_REMOTE_FIELDS.NAME],
    [CARD_COMPLEMENTARY_CONTENT_FIELDS.ORDER]:
      remoteData[COMPLEMENTARY_CONTENT_REMOTE_FIELDS.ORDER],
    [CARD_COMPLEMENTARY_CONTENT_FIELDS.PATH]:
      remoteData[COMPLEMENTARY_CONTENT_REMOTE_FIELDS.PATH],
    [CARD_COMPLEMENTARY_CONTENT_FIELDS.TYPE]:
      remoteData[COMPLEMENTARY_CONTENT_REMOTE_FIELDS.TYPE],
    [CARD_COMPLEMENTARY_CONTENT_FIELDS.MATERIAL_ID]:
      remoteData[COMPLEMENTARY_CONTENT_REMOTE_FIELDS.MATERIAL_ID]
  }
}

const outbound = (remoteData) => {
  return {
    [COMPLEMENTARY_CONTENT_REMOTE_FIELDS.ID]:
      remoteData[CARD_COMPLEMENTARY_CONTENT_FIELDS.ID],
    [COMPLEMENTARY_CONTENT_REMOTE_FIELDS.NAME]:
      remoteData[CARD_COMPLEMENTARY_CONTENT_FIELDS.NAME],
    [COMPLEMENTARY_CONTENT_REMOTE_FIELDS.ORDER]:
      remoteData[CARD_COMPLEMENTARY_CONTENT_FIELDS.ORDER],
    [COMPLEMENTARY_CONTENT_REMOTE_FIELDS.TYPE]:
      remoteData[CARD_COMPLEMENTARY_CONTENT_FIELDS.TYPE],
    [COMPLEMENTARY_CONTENT_REMOTE_FIELDS.MATERIAL_ID]: parseInt(
      remoteData[CARD_COMPLEMENTARY_CONTENT_FIELDS.MATERIAL_ID],
      10
    )
  }
}

const listInbound = (remoteList) => {
  return remoteList.map((remoteData) => inbound(remoteData))
}

export const CardComplementaryContentAdapter = {
  listInbound,
  inbound,
  outbound
}
