import Joi from 'joi'
import { DISCOUNT_CAMPAIGN_FIELDS as F } from '../discount-campaign-fields'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../../helpers/validation'

const schema = Joi.object({
  [F.CAMPAIGN_NAME]: Joi.string().required(),
  [F.CAMPAIGN_STATUS]: Joi.boolean().required().messages({
    'boolean.base': 'Campo obrigatório'
  })
})

/**
 *
 * @param {*} formValues
 * @returns
 */
export const discountCampaignValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schema)
}

/**
 *
 * @param {*} field
 * @param {*} value
 * @param {*} formValues
 * @returns
 */
export const discountCampaignValidationFieldValidation = (
  field,
  value,
  formValues
) => {
  return validateSingleFieldAndGenerateErrors(field, value, formValues, schema)
}
