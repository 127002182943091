import axios from 'axios'
import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const IntegrationsService = {
  async verifyClientByCNPJ(idNumber) {
    const path = `accounts/api/v1/clients/search?id_number=${idNumber}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async createIntegration(payload) {
    const path = 'accounts/api/v1/integrations'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },

  async updateIntegration(integrationId, payload) {
    const path = `accounts/api/v1/integrations/${integrationId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  async updateIntegrations(integrationId, integrationType, payload) {
    const path = `accounts/api/v1/integrations/${integrationId}/?integration_type=${integrationType}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  async deleteDeploymentId(integrationId, payload) {
    const path = `accounts/api/v1/integrations/${integrationId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, payload, headers)
    return response
  },

  async listIntegrationsByTypeAndApp(integrationType, appToken) {
    const path = `accounts/api/v1/integrations?integration_type=${integrationType}&app_token=${appToken}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },
  async createPracticeTest(payload) {
    const path = `/contents/api/v1/practice_test`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },
  async EditPracticeTest(payload, id) {
    const path = `/contents/api/v1/practice_test/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },
  async createDataBaseQuestion(payload) {
    const path = `contents/api/v1/database_question`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },
  async EditDataBaseQuestion(payload, id) {
    const path = `contents/api/v1/database_question/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  async createAllPracticeTest() {
    const path = `contents/api/v1/all_practice_tests`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },
  async searchDataBaseQuestions(page, perPage, search, category) {
    const path = `contents/api/v1/database_question?page=${page}&per_page=${perPage}&search=${search}&category=${category}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },
  async createPracticeQuestion(payload) {
    const path = `/contents/api/v1/practice_question`

    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },
  async createPracticeSimulated(id) {
    const path = `/contents/api/v1/practice_test/?id=${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },
  async enablingSimulated(payload, id) {
    const path = `/contents/api/v1/practice_test/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.put(path, payload, headers)
    return response
  },

  async deleteQuestionId(id) {
    const path = `contents/api/v1/database_question/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.delete(path, null, headers)
    return response
  },

  async generateUserAccessToken(payload) {
    const path = `accounts/api/v1/generate_user_access_token`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },
  async deleteSimulated(id) {
    const path = `/contents/api/v1/practice_test/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.delete(path, null, headers)
    return response
  },
  async searchFilter(page, perPage, search, category) {
    const path = `contents/api/v1/database_question?page=${page}&per_page=${perPage}&search=${search}&category=${category}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },
  async deleteSimulated(id) {
    const path = `/contents/api/v1/practice_test/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.delete(path, null, headers)
    return response
  },

  async questionWorksheet(file) {
    const { REACT_APP_FUSION_ORIGIN: baseUrl } = process.env
    const path = `${baseUrl}/contents/api/v1/database_question_csv`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })
    const payload = new FormData()
    payload.append('csv', file)

    const response = await axios.post(path, payload, {
      headers
    })

    return response
  }
}

export default IntegrationsService
