import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import { COURSE_PAYMENT_TYPES } from '../../../../../domain/user-management-content/user-orders'
import { formatCurrency } from '../../../../../helpers/data-transformation'

export const CreditCardInfo = (props) => {
  const { creditCard, holderName, installments, value } = props
  const classes = useStyles()

  return (
    <div className={classes.contentContainer}>
      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Método de pagamento
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {COURSE_PAYMENT_TYPES.CREDIT_CARD.label}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Bandeira do cartão
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {creditCard}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Nome (impresso no cartão)
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {holderName}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Número de parcelas
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {`${installments}x`}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Valor total a ser estornado pelo curso
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {formatCurrency(value)}
        </Typography>
      </div>
    </div>
  )
}

CreditCardInfo.propTypes = {
  creditCard: PropTypes.string.isRequired,
  holderName: PropTypes.string.isRequired,
  installments: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

CreditCardInfo.defaultProps = {}
