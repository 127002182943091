import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Button, Tabs } from '@equipe-ninja/saraiva-ui'
import { Card, CircularProgress, Divider, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { generatePath, useParams } from 'react-router-dom'

import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { useStyles } from './styles'
import { DetailsTab } from './components/DetailsTab'
import { getComplementaryMaterialById } from '../../../../services/complementary-material'
import { CARD_COMPLEMENTARY_MATERIAL_FIELDS } from '../../../../domain/complementary-material/card-complementary-material-fields'
import { ContentsTab } from './components/ContentsTab'
import { DialogContentManagement } from './components/DialogContentManagement'

export const ComplementaryMaterialDetails = () => {
  const { setErrorMessage } = useContext(GeneralContext)
  const { brand, id, materialId } = useParams()
  const styles = useStyles()
  const [requestObservable, setRequestObservable] = useState(0)
  const [isFetchingMaterialDetails, setIsFetchingMaterialDetails] =
    useState(false)
  const [materialDetails, setMaterialDetails] = useState(null)
  const [isAddContentDialogOpen, setIsAddContentDialogOpen] = useState(false)

  const openAddContentDialog = () => setIsAddContentDialogOpen(true)
  const closeAddContentDialog = () => setIsAddContentDialogOpen(false)

  const materialName =
    materialDetails?.[CARD_COMPLEMENTARY_MATERIAL_FIELDS.NAME] ||
    'Nome do Material'

  const fetchMaterialDetails = useCallback(async () => {
    setIsFetchingMaterialDetails(true)

    try {
      const material = await getComplementaryMaterialById(materialId)

      setMaterialDetails(material)
    } catch (err) {
      setErrorMessage('Não foi possível buscar os detalhes do material')
    } finally {
      setIsFetchingMaterialDetails(false)
    }
  }, [materialId, setErrorMessage])

  useEffect(() => {
    fetchMaterialDetails()
  }, [fetchMaterialDetails])

  return (
    <ContentWithHeader
      title={materialName}
      titleSize="medium"
      backButtonRoute={generatePath(RoutesMapping.registerElearning, {
        brand,
        id
      })}
      backButtonText="VOLTAR"
    >
      <Divider />

      <Typography className={styles.subTitle}>
        Adicione os conteúdos que farão parte deste material complementar. Você
        pode alterar estes conteúdos na aba Conteúdos.
      </Typography>

      <Button
        color="primary"
        variant="contained"
        startIcon={<Add />}
        onClick={openAddContentDialog}
      >
        NOVO CONTEÚDO
      </Button>

      {isFetchingMaterialDetails ? (
        <CircularProgress className={styles.loading} />
      ) : (
        <Card className={styles.cardContainer}>
          <Tabs screenSize="desktop" color="primary" showDivider mode="grow">
            {[
              {
                label: 'Detalhes',
                content: <DetailsTab material={materialDetails || {}} />
              },
              {
                label: 'Conteúdos',
                content: (
                  <ContentsTab
                    materialId={materialId}
                    requestId={requestObservable}
                  />
                )
              }
            ]}
          </Tabs>
        </Card>
      )}

      <DialogContentManagement
        isOpen={isAddContentDialogOpen}
        handleClose={closeAddContentDialog}
        handleOpen={openAddContentDialog}
        fetchMaterialContents={() => setRequestObservable(Date.now())}
      />

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}
