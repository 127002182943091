/* eslint-disable react/forbid-prop-types */
import React, { useState, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'

const ConnectionContext = createContext()

function ConnectionProvider({ children, connectionProps }) {
  const [connection, setConnection] = useState(connectionProps)
  const [showingConfigForm, toggleConfigForm] = useState(false)
  const [showingSubjectsDialog, toggleSubjectsDialog] = useState(false)
  const [showingDeploymentForm, toggleDeploymentForm] = useState(false)
  const [saving, setSaving] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [isCanvas, setIsCanvas] = useState(false)

  const updateConnection = (payload) => {
    setConnection({ ...connection, ...payload })
  }

  useEffect(() => {
    const checkingLmsType = () => {
      if (connection.lms === 'Canvas') {
        setIsCanvas(true)
      }
    }
    checkingLmsType()
  }, [connection])

  return (
    <ConnectionContext.Provider
      value={{
        connection,
        updateConnection,

        showingConfigForm,
        toggleConfigForm,

        showingSubjectsDialog,
        toggleSubjectsDialog,

        showingDeploymentForm,
        toggleDeploymentForm,

        saving,
        setSaving,

        fetching,
        setFetching,

        deleting,
        setDeleting,

        isCanvas,
        setIsCanvas
      }}
    >
      {children}
    </ConnectionContext.Provider>
  )
}

ConnectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  connectionProps: PropTypes.object.isRequired
}

export { ConnectionContext, ConnectionProvider }
