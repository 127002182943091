import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%'
  },
  searchWrapper: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32)
  }
}))

const useRowStyles = makeStyles({
  root: {
    '&.expanded': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  }
})

const useSubRowStyles = makeStyles({
  root: {
    background: '#FAFAFA'
  }
})

const usePaginationStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

export { useStyles, useRowStyles, useSubRowStyles, usePaginationStyles }
