import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    marginTop: 32,
    marginBottom: 16
  },

  addButton: {
    float: 'right'
  },

  emptyDeploymentIdsMessage: {
    color: theme.palette.text.secondary,
    textAlign: 'center'
  }
}))

export default useStyles
