import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    '&.MuiButton-textSizeLarge': {
      margin: '24px 0px 24px 16px'
    }
  },
  searchContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 32
  },
  searchContainerHidden: {
    display: 'none'
  },
  tableContainer: {
    marginTop: 24
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sectionContainer: {
    width: '100%'
  },
  notQuestions: {
    height: 750,
    textAlign: 'center',
    paddingTop: 72,
    color: '#0000008a',
    fontWeight: 100
  },
  checkBox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  selectedRow: {
    backgroundColor: '#245a9314'
  },
  exerciceNoCount: {
    display: 'none'
  },
  exerciceCount: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'end',
    margin: '24px 16px 24px 0px',
    padding: '0px !important'
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#00000008'
    }
  },
  fisrtCell: {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  }
}))

export default useStyles
