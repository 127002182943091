import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import { COURSE_PAYMENT_TYPES } from '../../../../../domain/user-management-content/user-orders'
import {
  formatCurrency,
  formatDate
} from '../../../../../helpers/data-transformation'
import { formatCPF } from '../../../../../helpers/formatCPF'

export const BankSplipInfo = (props) => {
  const { code, dueDate, personID, personName, value } = props
  const classes = useStyles()

  return (
    <div className={classes.contentContainer}>
      <div className={cls(classes.contentItem)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Método de pagamento
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {COURSE_PAYMENT_TYPES.BANK_SLIP.label}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Código
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {code}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Vencimento
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {formatDate(dueDate)}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          CPF do Comprador
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {formatCPF(personID)}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Nome do Comprador
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {personName}
        </Typography>
      </div>

      <div className={cls(classes.contentItem, classes.marginTopSmall)}>
        <Typography variant="body1" className={classes.textPrimary}>
          Valor total a ser estornado pelo curso
        </Typography>

        <Typography variant="body2" className={classes.textSecondary}>
          {formatCurrency(value)}
        </Typography>
      </div>
    </div>
  )
}

BankSplipInfo.propTypes = {
  code: PropTypes.string.isRequired,
  dueDate: PropTypes.instanceOf(Date).isRequired,
  personID: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

BankSplipInfo.defaultProps = {}
