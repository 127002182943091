import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  maxWidthColumn: {
    padding: 0
  },
  table: {
    width: 804,
    left: 372,
    top: 433,
    borderRadius: '8px 8px 5px 5px',
    marginLeft: 132,
    marginBottom: 80
  },
  tableContainer: {
    width: 912,
    marginTop: 20
  },
  headerCell: {
    font: 'roboto'
  },
  checkbox: {
    color: '#624C92',
    '&. checked': {
      color: '#624C92'
    }
  },
  checked: {},
  text: {
    height: 50,
    paddingTop: 16,
    paddingLeft: 19
  }
})

export default useStyles
