import { selector } from 'recoil'

import {
  QUESTION_ALTERNATIVES_FIELDS,
  QUESTION_FIELDS
} from '../../../domain/fixation-exercise/question-fields'
import { fixationExerciseState } from '../atoms/fixation-exercise-state'

export const questionDialogFormValuesSelector = selector({
  key: 'questionDialogFormValues',
  get: ({ get }) => {
    return get(fixationExerciseState).questionDialogFormValues
  },
  set: ({ get, set }, data) => {
    const currentState = get(fixationExerciseState)

    set(fixationExerciseState, {
      ...currentState,
      questionDialogFormValues: { ...data }
    })
  }
})

export const questionUpdateIdSelector = selector({
  key: 'questionUpdateId',
  get: ({ get }) => {
    return get(fixationExerciseState).questionUpdateId
  },
  set: ({ set, get }, data) => {
    set(fixationExerciseState, {
      ...get(fixationExerciseState),
      questionUpdateId: data
    })
  }
})

export const questionAlternativesSelector = selector({
  key: 'questionAlternativesSelector',
  get: ({ get }) => {
    return get(questionDialogFormValuesSelector)[QUESTION_FIELDS.ALTERNATIVES]
  },
  set: ({ get, set }, data) => {
    const currentState = get(questionDialogFormValuesSelector)

    set(questionDialogFormValuesSelector, {
      ...currentState,
      [QUESTION_FIELDS.ALTERNATIVES]: data.map((alternative, index) => ({
        ...alternative,
        [QUESTION_ALTERNATIVES_FIELDS.ORDER]: index + 1
      }))
    })
  }
})

export const questionFormErrorsSelector = selector({
  key: 'questionFormErrorsSelector',
  get: ({ get }) => {
    return get(fixationExerciseState).questionDialogFormErrors
  },
  set: ({ get, set }, data) => {
    set(fixationExerciseState, {
      ...get(fixationExerciseState),
      questionDialogFormErrors: { ...data }
    })
  }
})
