import Joi from 'joi'
import { COURSE_OVERVIEW as F, COURSE_SERVICE_STATUS } from '../entities'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../helpers/validation'

const { AVAILABLE, DRAFT, UNAVAILABLE } = COURSE_SERVICE_STATUS

const schema = Joi.object({
  [F.END_DATE]: Joi.date().min(Joi.ref(F.START_DATE)).required().messages({
    'date.min': 'Deve ser posterior a data de início.'
  }),
  [F.START_DATE]: Joi.date().required(),
  [F.STATUS]: Joi.when(Joi.ref('$requiredConditions'), {
    is: true,
    then: Joi.string().valid(AVAILABLE, DRAFT, UNAVAILABLE).messages({
      'any.only': 'Campo obrigatório'
    }),
    otherwise: Joi.string().when('', {
      is: Joi.string().required(),
      otherwise: Joi.when(Joi.ref('$courseValid'), {
        is: false,
        then: Joi.valid(DRAFT, UNAVAILABLE).messages({
          'any.only': 'Cadastro do ambiente de aprendizagem está incompleto'
        }),
        otherwise: Joi.valid(DRAFT, UNAVAILABLE).messages({
          'any.only': 'Cadastro do e-commerce está incompleto'
        })
      })
    })
  }).messages({
    'any.invalid': 'Campo obrigatório'
  }),
  [F.TITLE]: Joi.string()
    .required()
    .invalid(Joi.ref('$previousTitle'))
    .messages({
      'any.invalid': 'O nome do curso deve ser diferente do anterior'
    })
})

const context = (
  { courseValid, courseEcommerceValid },
  previousTitle = ''
) => ({
  courseValid,
  courseEcommerceValid,
  previousTitle,
  requiredConditions: courseValid && courseEcommerceValid
})

export const courseOverviewValidation = (
  formValues,
  completionStatus = {
    courseValid: true,
    courseEcommerceValid: true
  }
) => {
  return validateAndGenerateErrors(
    formValues,
    schema,
    context(completionStatus)
  )
}

export const courseOverviewFieldValidation = (
  field,
  value,
  formValues,
  completionStatus = {
    courseValid: true,
    courseEcommerceValid: true
  },
  previousTitle = ''
) => {
  return validateSingleFieldAndGenerateErrors(
    field,
    value,
    formValues,
    schema,
    context(completionStatus, previousTitle)
  )
}
