import { COURSE_LIST_ITEM_FIELDS as LIST_FIELDS } from '../../../domain/courses/courses-list-item-field'
import { COURSES_FIELDS_LIST_ITEM } from '../remote-fields/courses-list-fields'
import { COURSE_REMOTE_FIELDS as C_R } from '../remote-fields/course'
import {
  SERVICE_REMOTE_FIELDS as S_R,
  COURSE_SERVICES_PLANS_FIELDS as SP_R
} from '../remote-fields/courses-services-fields'
import {
  parseRemoteDecimal,
  defaultValueWhenEmpty
} from '../../utils/inbound-utils'
import { remoteDateStringToHuman } from '../../utils/remote-datetime-utils'
import { RemoteDateUtils } from '../../utils/remote-date-utils'
import { CourseServiceStatusAdapter } from '../adapters/course-service-status'
import { courseServiceStatusLabel } from '../../../domain/courses/entities'
import {
  initialURLValueForBrand,
  BRANDS_IDENTIFIER
} from '../../../domain/brands'

const { LFG } = BRANDS_IDENTIFIER

const digAndAdaptCoursePrice = (service) => {
  return parseRemoteDecimal(service?.[S_R.PLANS]?.[0]?.[SP_R.PRICE])
}

const inbound = (data) => {
  return data.map((item) => ({
    [LIST_FIELDS.KEY.value]: item[C_R.ID],
    [LIST_FIELDS.COURSE_NAME.value]: item[C_R.TITLE],
    [LIST_FIELDS.SKU.value]: defaultValueWhenEmpty(
      item?.[C_R.SERVICE]?.[S_R.SKU]
    ),
    [LIST_FIELDS.URL.value]: item?.[C_R.SERVICE]?.[S_R.SLUG]
      ? `${initialURLValueForBrand(LFG)}${item?.[C_R.SERVICE]?.[S_R.SLUG]}`
      : defaultValueWhenEmpty(item?.[C_R.SERVICE]?.[S_R.SLUG]),
    [LIST_FIELDS.CHANGE_DATE.value]: remoteDateStringToHuman(
      item[C_R.UPDATED_AT]
    ),
    [LIST_FIELDS.SALE_START_DATE.value]: defaultValueWhenEmpty(
      RemoteDateUtils.parseDateStringToHuman(
        item?.[C_R.SERVICE]?.[S_R.SELLING_START_DATE]
      )
    ),
    [LIST_FIELDS.COURSE_PRICE.value]: defaultValueWhenEmpty(
      digAndAdaptCoursePrice(item?.[C_R.SERVICE])
    ),
    [LIST_FIELDS.PUBLICATION_STATE.value]: courseServiceStatusLabel(
      CourseServiceStatusAdapter.inbound(item[C_R.SERVICE]?.[S_R.STATUS])
    )
  }))
}

export const listItemAdapterInbound = (remoteDataList) => {
  return remoteDataList.map((remoteData) => ({
    [LIST_FIELDS.KEY.value]: remoteData[COURSES_FIELDS_LIST_ITEM.KEY],

    [LIST_FIELDS.COURSE_NAME.value]:
      remoteData[COURSES_FIELDS_LIST_ITEM.COURSE_NAME],

    [LIST_FIELDS.URL.value]: remoteData[COURSES_FIELDS_LIST_ITEM.URL],
    [LIST_FIELDS.SKU.value]: remoteData[COURSES_FIELDS_LIST_ITEM.SKU],
    [LIST_FIELDS.CHANGE_DATE.value]:
      remoteData[COURSES_FIELDS_LIST_ITEM.CHANGE_DATE],
    [LIST_FIELDS.SALE_START_DATE.value]:
      remoteData[COURSES_FIELDS_LIST_ITEM.SALE_START_DATE],
    [LIST_FIELDS.COURSE_PRICE.value]:
      remoteData[COURSES_FIELDS_LIST_ITEM.COURSE_PRICE],
    [LIST_FIELDS.PUBLICATION_STATE.value]:
      remoteData[COURSES_FIELDS_LIST_ITEM.PUBLICATION_STATE]
  }))
}

export const CourseListAdapter = {
  inbound
}
