import { makeStyles, styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'

export const ContainerButton = styled(Box)({
  display: 'flex',
  right: '0px',
  marginRight: '0px',
  alignItems: 'end',
  justifyContent: 'right',
  paddingBottom: '16px',
  height: '74px'
})

export const PenIcon = styled(Edit)(({ disabled }) => ({
  color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#624C92',
  width: '18px',
  height: '18px'
}))

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 72,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    },
    '& .MuiTableCell-body': {
      maxWidth: 200
    }
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  input: {
    marginBottom: '24px'
  },
  btnRegister: {
    margin: '24px 12px 0 0',
    height: '42px'
  },
  textH4: {
    fontSize: '34px',
    color: theme.palette.text.primary,
    marginTop: 40
  },
  titleH3: {
    marginBottom: '24px',
    color: theme.palette.text.primary,
    marginTop: '22px',
    fontSize: '48px'
  },
  textH5: {
    fontSize: '24px',
    marginBottom: 0
  },
  line: {
    width: '100%',
    height: '1px',
    margin: '21px 0px 24px 0px',
    backgroundColor: theme.palette.divider
  },
  divider: {
    margin: '24px 0 40px 0'
  },
  maxWidthColumn: {
    wordBreak: 'break-all'
  },
  searchWrapper: {
    margin: '22px 0 24px 0'
  },
  paper: {
    width: 277,
    position: 'absolute',
    borderRadius: 4,
    padding: '10px 0px 10px 12px',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main
    },
    zIndex: 1000,
    marginTop: -16
  },
  empty: {
    display: 'none'
  },
  btnEdit: {
    margin: '16px 16px 0 16px',
    right: '0',
    position: 'relative',
    height: '42px'
  }
}))

export default useStyles
