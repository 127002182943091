import Joi from 'joi'
import { COURSE_TABS_FIELDS as F } from '..'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../helpers/validation'

const schema = Joi.object({
  [F.TITLE]: Joi.string().required()
})

export const singleProductCourseTabValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schema)
}

export const singleProductCourseTabFieldValidation = (
  field,
  value,
  formValues
) => {
  return validateSingleFieldAndGenerateErrors(field, value, formValues, schema)
}
