import React, { useState } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import ticketService from '../../../services/ticketService'
import { useParams } from 'react-router'

const ArchiveDialog = ({ open, handleClose }) => {
  const { libraryCode } = useParams()

  const [archiving, setArchiving] = useState(false)

  const archiveLib = async () => {
    try {
      setArchiving(true)

      const response = await ticketService.archiveTickets(libraryCode)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      if (response.ok) {
        window.location.assign(`/libraries/${libraryCode}`)
      }
    } catch (error) {
      setArchiving(false)
      console.error(error)
    }
  }

  return (
    <Dialog
      open={open}
      size="lg"
      title="Arquivar todos os vouchers da biblioteca"
      secondaryButton="cancelar"
      primaryButton="salvar"
      label="Sim, tenho certeza!"
      alert
      handleClose={handleClose}
      handleConfirm={archiveLib}
      isDisabled={archiving}
    >
      Enquanto arquivado, todos os vouchers já criados para esta biblioteca não
      poderão ser utilizados por nenhum usuário. Assim, todos os usuários que já
      ativaram seus vouchers, perderão seu acesso a biblioteca.<br/>Esta biblioteca
      poderá ser desarquivada a qualquer momento.
    </Dialog>
  )
}

export default ArchiveDialog
