import React, { useState, useContext, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Dialog,
  Button,
  TextInput,
  DatePickerSaraiva,
  SelectSaraiva
} from '@equipe-ninja/saraiva-ui'
import cls from 'classnames'
import { useParams } from 'react-router-dom'
import useStyles from './styles'
import {
  scheduledContentFormSelector,
  scheduledContentState
} from '../../../../../../atoms/scheduled-content'
import {
  SCHEDULED_CONTENT_FIELDS,
  scheduledContentExerciseTypeLabel,
  SCHEDULED_CONTENT_EXERCISE_TYPE
} from '../../../../../../domain/courses'
import {
  scheduledContentFieldValidation,
  scheduledContentValidation,
  scheduledContentDefaultForm
} from '../../../../../../domain/courses/scheduled-content/business'
import {
  deleteScheduledContent,
  createScheduledContent,
  updateScheduledContent
} from '../../../../../../services/courses'
import { GeneralContext } from '../../../../../../contexts/GeneralContext'

const {
  ANSWER_CONTENT,
  ANSWER_PUBLISHING_DATE,
  CONTENT,
  END_DATE,
  EXERCISE_TYPE,
  ID,
  INITIAL_DATE,
  LINK,
  TITLE
} = SCHEDULED_CONTENT_FIELDS

export const ScheduledContentForm = (props) => {
  const { showId, onSaveCallback, onDeleteCallback } = props
  const classes = useStyles()
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [showFormDialog, setShowFormDialog] = useState(false)
  const [showNotSavedDalog, setShowNotSavedDalog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [form, setForm] = useRecoilState(scheduledContentFormSelector)
  const [{ isFetching }, setState] = useRecoilState(scheduledContentState)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const { id: courseId } = useParams()

  const onChangeValue = (field, value) => {
    setIsFormDirty(true)
    const errorField = scheduledContentFieldValidation(
      field,
      value,
      form.values
    )
    setForm((prev) => ({
      ...prev,
      values: { ...prev.values, [field]: value },
      errors: { ...prev.errors, ...errorField }
    }))
  }

  const onChangeEvent = (ev, field) => {
    onChangeValue(field, ev.target.value)
  }

  const closeForm = () => {
    setShowNotSavedDalog(false)
    setShowFormDialog(false)
    setShowDeleteDialog(false)
    setForm(scheduledContentDefaultForm())
  }

  const handleCreateScheduledContent = async () => {
    if (isFetching) return

    setState((prev) => ({ ...prev, isFetching: true }))

    try {
      await createScheduledContent(form.values, courseId)

      setSuccessMessage('Exercício salvo com sucesso')
      closeForm()
      onSaveCallback()
    } catch (err) {
      setErrorMessage(err)
    } finally {
      setState((prev) => ({ ...prev, isFetching: false }))
    }
  }

  const handleUpdatedScheduledContent = async () => {
    if (isFetching) return

    setState((prev) => ({ ...prev, isFetching: true }))

    try {
      await updateScheduledContent(form.values)

      setSuccessMessage('Alterações feitas com sucesso')
      closeForm()
      onSaveCallback()
    } catch (err) {
      setErrorMessage(err)
    } finally {
      setState((prev) => ({ ...prev, isFetching: false }))
    }
  }

  const handleDeleteScheduledContent = async () => {
    if (isFetching) return

    setState((prev) => ({ ...prev, isFetching: true }))

    try {
      const deleteSuccess = await deleteScheduledContent(form.values[ID])

      if (!deleteSuccess) {
        setErrorMessage('Falha ao remover conteúdo, tente novamente')
        return
      }

      setSuccessMessage('Conteúdo deletado com sucesso')
      closeForm()
      onDeleteCallback(form.values[ID])
    } catch (err) {
      setErrorMessage(err)
    } finally {
      setState((prev) => ({ ...prev, isFetching: false }))
    }
  }

  const saveForm = () => {
    if (form.values[ID]) {
      handleUpdatedScheduledContent()
    } else {
      handleCreateScheduledContent()
    }
  }

  const deleteForm = () => {
    handleDeleteScheduledContent()
  }

  const submit = () => {
    const { errors, isValid } = scheduledContentValidation(form.values)

    if (!isValid) {
      setForm((prev) => ({
        ...prev,
        errors: { ...prev.errors, ...errors }
      }))

      setErrorMessage(
        'Não foi possível salvar o exercício, verifique os campos em vermelho'
      )

      return
    }

    saveForm()
  }

  const handlerFormClosing = () => {
    if (isFormDirty) {
      setShowNotSavedDalog(true)

      return
    }

    closeForm()
  }

  useEffect(() => {
    if (showId) {
      setShowFormDialog(true)
      setIsFormDirty(false)
    }
  }, [showId])

  return (
    <>
      <Dialog
        handleClose={() => handlerFormClosing()}
        handleConfirm={() => submit()}
        isDisabled={isFetching}
        open={showFormDialog}
        primaryButton="Salvar"
        secondaryButton="cancelar"
        size="lg"
        title={form[ID] ? 'Alterar Exercício ' : 'Adicionar Exercício'}
      >
        <form className={classes.formContainer}>
          <Typography variant="h6" color="textPrimary">
            Detalhes
          </Typography>

          <div className={classes.marginTopMedium}>
            <SelectSaraiva
              error={!!form.errors[EXERCISE_TYPE]}
              helperText={form.errors[EXERCISE_TYPE]}
              label="Tipo de exercício *"
              onChange={(evt) => onChangeEvent(evt, EXERCISE_TYPE)}
              required
              size="full"
              value={form.values[EXERCISE_TYPE]}
            >
              <MenuItem value="">Nenhum exercício selecionado</MenuItem>
              <MenuItem value={SCHEDULED_CONTENT_EXERCISE_TYPE.QUESTION_BLOCK}>
                {scheduledContentExerciseTypeLabel(
                  SCHEDULED_CONTENT_EXERCISE_TYPE.QUESTION_BLOCK
                )}
              </MenuItem>
              <MenuItem value={SCHEDULED_CONTENT_EXERCISE_TYPE.PECA_PROCESSUAL}>
                {scheduledContentExerciseTypeLabel(
                  SCHEDULED_CONTENT_EXERCISE_TYPE.PECA_PROCESSUAL
                )}
              </MenuItem>
            </SelectSaraiva>
          </div>

          <Typography
            className={classes.marginTopMedium}
            color="textPrimary"
            variant="h6"
          >
            Enunciado
          </Typography>

          <Typography
            className={classes.marginTopSmaller}
            color="textSecondary"
            variant="body1"
          >
            Insira o título e corpo do texto do enunciado do exercício em
            questão.
          </Typography>

          <TextInput
            className={classes.marginTopMedium}
            error={!!form.errors[TITLE]}
            helperText={form.errors[TITLE]}
            label="Título do enunciado"
            onChange={(evt) => onChangeEvent(evt, TITLE)}
            required
            size="full"
            value={form.values[TITLE]}
          />

          <TextInput
            className={classes.marginTopMedium}
            error={!!form.errors[CONTENT]}
            helperText={form.errors[CONTENT]}
            label="Texto do enunciado"
            multiline
            onChange={(evt) => onChangeEvent(evt, CONTENT)}
            required
            rows={4}
            size="full"
            value={form.values[CONTENT]}
          />

          <Typography
            className={classes.marginTopMedium}
            color="textPrimary"
            variant="h6"
          >
            Formulário de exercicio
          </Typography>

          <Typography
            className={classes.marginTopSmaller}
            color="textSecondary"
            variant="body1"
          >
            Determine o prazo de início e fim que o aluno poderá enviar este
            exercício. A plataforma finaliza o prazo de envio do exercício as
            23:59.
          </Typography>

          <div className={cls(classes.marginTop16, classes.datesContainer)}>
            <DatePickerSaraiva
              error={!!form.errors[INITIAL_DATE]}
              helperText={form.errors[INITIAL_DATE]}
              label="Data de início*"
              onChange={(selectedDate) =>
                onChangeValue(INITIAL_DATE, selectedDate)
              }
              size="full"
              value={form.values[INITIAL_DATE]}
            />

            <DatePickerSaraiva
              error={!!form.errors[END_DATE]}
              helperText={form.errors[END_DATE]}
              label="Data de fim*"
              onChange={(selectedDate) => onChangeValue(END_DATE, selectedDate)}
              size="full"
              value={form.values[END_DATE]}
            />
          </div>

          <Typography
            className={classes.marginTopMedium}
            color="textSecondary"
            variant="body1"
          >
            Insira o link do formulário em que o aluno enviará o exercício.
            Preencha este campo até antes da data de início para que o exercício
            possa estar disponível para o aluno.
          </Typography>

          <TextInput
            className={classes.marginTop16}
            error={!!form.errors[LINK]}
            helperText={form.errors[LINK]}
            label="Link do formulário"
            onChange={(evt) => onChangeEvent(evt, LINK)}
            required
            size="full"
            value={form.values[LINK]}
          />

          <Typography
            className={classes.marginTopMedium}
            color="textPrimary"
            variant="h6"
          >
            Gabarito
          </Typography>

          <Typography
            className={classes.marginTopSmaller}
            color="textSecondary"
            variant="body1"
          >
            Insira o gabarito para o exercício. O gabarito deve ser liberado
            aproximadamente uma semana antes do exame da segunda fase.
          </Typography>

          <TextInput
            className={classes.marginTop16}
            error={!!form.errors[ANSWER_CONTENT]}
            helperText={form.errors[ANSWER_CONTENT]}
            label="Texto do gabarito"
            onChange={(evt) => onChangeEvent(evt, ANSWER_CONTENT)}
            required
            multiline
            rows={4}
            size="full"
            value={form.values[ANSWER_CONTENT]}
          />

          <div
            className={cls(classes.marginTopMedium, classes.datesContainer)}
            style={{ width: 'calc(50% - 12px)' }}
          >
            <DatePickerSaraiva
              error={!!form.errors[ANSWER_PUBLISHING_DATE]}
              helperText={form.errors[ANSWER_PUBLISHING_DATE]}
              label="Data de liberação do gabarito *"
              onChange={(selectedDate) =>
                onChangeValue(ANSWER_PUBLISHING_DATE, selectedDate)
              }
              size="full"
              value={form.values[ANSWER_PUBLISHING_DATE]}
            />
          </div>

          {form.values[ID] && (
            <>
              <Typography
                className={classes.marginTopMedium}
                color="textPrimary"
                variant="h6"
              >
                Excluir
              </Typography>

              <Typography
                className={classes.marginTopSmaller}
                color="textSecondary"
                variant="body1"
              >
                Exclua este exercício para retirá-lo do ambiente de
                aprendizagem.
              </Typography>

              <div className={classes.marginTop16}>
                <Button
                  className={classes.errorButton}
                  fullWidth={false}
                  onClick={() => setShowDeleteDialog(true)}
                  variant="outlined"
                >
                  <DeleteIcon />

                  <span className={classes.marginLeftSmall}>
                    Excluir Exercício
                  </span>
                </Button>
              </div>
            </>
          )}
        </form>
      </Dialog>

      <Dialog
        handleClose={() => setShowNotSavedDalog(false)}
        handleConfirm={() => closeForm()}
        isDisabled={isFetching}
        open={showNotSavedDalog}
        primaryButton="descartar"
        secondaryButton="cancelar"
        size="md"
        title="Descartar alterações"
      >
        <Typography color="textPrimary" variant="body1">
          Você está saindo sem salvar as alterações feitas e perderá todo o seu
          progresso.
        </Typography>
      </Dialog>

      <Dialog
        alertMessage
        handleClose={() => setShowDeleteDialog(false)}
        handleConfirm={() => deleteForm()}
        isDisabled={isFetching}
        label="Sim, tenho certeza!"
        open={showDeleteDialog}
        primaryButton="Excluir"
        secondaryButton="Cancelar"
        size="md"
        title="Excluir exercício"
      >
        Você tem certeza que deseja excluir este exercício? Após excluir ele não
        poderá mais ser acessado pelos alunos.
      </Dialog>
    </>
  )
}

ScheduledContentForm.propTypes = {
  onDeleteCallback: PropTypes.func,
  onSaveCallback: PropTypes.func,
  showId: PropTypes.number
}

ScheduledContentForm.defaultProps = {
  onDeleteCallback: () => null,
  onSaveCallback: () => null,
  showId: 0
}
