import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react'
import { Typography } from '@material-ui/core'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Table } from '@equipe-ninja/saraiva-ui'
import { debounce } from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { getUserManagementPagesList } from '../../../services/user-management-content'
import { USER_MANAGEMENT_LIST_ITEM_FIELDS } from '../../../domain/user-management-content/user-management-page'
import useStyles from '../styles'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { RoutesMapping } from '../../../helpers/routes-mapping'

const { KEY, FULL_NAME, CPF, EMAIL, STATUS } = USER_MANAGEMENT_LIST_ITEM_FIELDS

export function UserList({ brand }) {
  const classes = useStyles()

  const [usersPageList, setUsersPageList] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const { setErrorMessage } = useContext(GeneralContext)

  const headersCells = () => {
    return [
      { name: FULL_NAME.label, order: true, code: FULL_NAME.value },
      { name: CPF.label, order: true, code: CPF.value },
      { name: EMAIL.label, order: true, code: EMAIL.value },
      { name: STATUS.label, order: true, code: STATUS.value }
    ]
  }

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
    }, 800)
  ).current

  const handleSearchValue = (e) => {
    setSearchTerm(e.target.value)
    delayedQuery(e.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
  }

  const anotateListItemWithRedirect = useCallback(
    (list) => {
      return list.map((item) => ({
        ...item,
        redirectTo: {
          fromColumn: FULL_NAME.value,
          path: generatePath(RoutesMapping.userManagementPageDetail, {
            brand,
            userId: item[KEY.value]
          })
        }
      }))
    },
    [brand]
  )

  useEffect(() => {
    setIsFetching(true)

    getUserManagementPagesList(searchValue)
      .then((list) => {
        setUsersPageList(anotateListItemWithRedirect(list))
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar a lista de usuários')
      })
      .finally(() => setIsFetching(false))
  }, [anotateListItemWithRedirect, searchValue, setErrorMessage])

  return (
    <>
      {isFetching ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Typography
            variant="h4"
            className={`${classes.text} ${classes.textH4}`}
          >
            LFG
          </Typography>
          <Typography
            variant="h5"
            className={`${classes.text} ${classes.textH5}`}
          >
            Usuários
          </Typography>
          <div className={classes.searchWrapper}>
            <SearchBar
              value={searchTerm}
              onChange={handleSearchValue}
              placeholder="Buscar por:"
              size="full"
              clearSearch={clearSearch}
              goBackSearch={clearSearch}
              onSubmit={(e) => {
                e.preventDefault()
              }}
            />
          </div>

          <div className={classes.tableContainer}>
            <Table cells={headersCells()} rows={usersPageList} />
          </div>
        </>
      )}

      <SnackbarOutlet />
    </>
  )
}

UserList.propTypes = {
  brand: PropTypes.string
}

UserList.defaultProps = {
  brand: 'lfg'
}
