import React, { useContext } from 'react'
import { Typography, Divider } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'

import ConnectionForm from '../ConnectionForm'
import ConnectionCard from '../ConnectionCard'
import { ConnectionsContext } from '../../../contexts/ConnectionsContext'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { ConnectionProvider } from '../../../contexts/ConnectionContext'
import Loading from '../../../components/Loading'
import useStyles from './styles.js'

function ConnectionsList() {
  const classes = useStyles()

  const { connections, toggleConnectionForm } = useContext(ConnectionsContext)

  const { loading, productSelected } = useContext(GeneralContext)

  if (loading) {
    return <Loading />
  }

  if (!productSelected.value) {
    return null
  }

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        {productSelected.label}
      </Typography>

      <Button
        color="primary"
        onClick={() => toggleConnectionForm(true)}
        text="Botão"
        variant="contained"
        className={classes.addConnectionBtn}
      >
        ADICIONAR CONEXÃO
      </Button>

      <ConnectionForm />

      <Divider />

      <Typography variant="h5" className={classes.title}>
        Gerenciamento de Conexões
      </Typography>

      {connections.map((connection) => (
        <ConnectionProvider connectionProps={connection} key={connection.id}>
          <ConnectionCard />
        </ConnectionProvider>
      ))}
    </>
  )
}

export default ConnectionsList
