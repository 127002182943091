import { makeStyles } from '@material-ui/core/styles'
import { pageClasses, spacing } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  actionsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '42px',
    marginTop: '18px',
    alignItems: 'flex-end'
  },
  linkBtn: {
    color: theme.palette.secondary.light,
    fontSize: 16
  },
  divider: {
    margin: '40px 0'
  },
  waitingLnk: {
    cursor: 'wait',
    color: theme.palette.text.disabled
  },
  ...spacing,
  ...pageClasses(theme)
}))

export default useStyles
