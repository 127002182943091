import React, { useState, createContext, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import IntegrationsService from '../services/integrationsService'
import messages from '../helpers/messages'
import { GeneralContext } from './GeneralContext'

export const UploadQuestionWorksheetContext = createContext()

function UploadQuestionWorksheet({ children }) {
  const [isUploading, setIsUploading] = useState(false)
  const [totalUploadFiles, setTotalUploadFiles] = useState(0)
  const [countFileUpload, setCountFileUpload] = useState(0)
  const [fileName, setFileName] = useState('')
  const [updatedQuestions, setUpdatedQuestions] = useState(false)
  const [errorsBar, setErrorsBar] = useState([])
  const [filesError, setFilesError] = useState([])
  const [reload, setReload] = useState(false)
  const [listUploadErrors, setListUploadErrors] = useState([])
  const [reloaFile, setReloaFile] = useState(0)

  const { setErrorMessage } = useContext(GeneralContext)

  const translateError = (errorDetail) => {
    const translations = {
      order: 'ordem',
      code: 'código',
      type: 'tipo',
      knowledge_area: 'área de conhecimento',
      cogna_origin: 'Backoffice',
      subject: 'disciplina',
      theme: 'tema',
      goal: 'objetivo',
      question_source: 'fonte da questão',
      year: 'ano',
      tags: 'tags',
      content: 'conteúdo',
      difficulty_level: 'nível de dificuldade',
      correct_alternative_id: 'alternativa correta',
      question_alternatives: 'alternativas'
    }

    return errorDetail.replace(
      new RegExp(`\\b(${Object.keys(translations).join('|')})\\b`, 'g'),
      (match) => translations[match] || match
    )
  }

  const uploadFile = async (file) => {
    const validFileTypes = ['text/csv']
    setFileName(file.name)

    if (!file || !validFileTypes.includes(file.type)) {
      setErrorMessage(messages.invalidFile.error)
      setIsUploading(false)
      return
    }

    setIsUploading(true)
    setTotalUploadFiles(1)
    setCountFileUpload(0)
    setUpdatedQuestions(true)

    try {
      const response = await IntegrationsService.questionWorksheet(file)

      if (response.status !== 201) {
        const { errors } = response.data

        const fileError = {
          errorCode: response.status,
          errorDetail: errors
        }

        if (!reload) {
          setFilesError((prevErrors) => [...prevErrors, fileError])
        }
        setListUploadErrors((prevErrors) => [...prevErrors, file.name])
      } else {
        setUpdatedQuestions(false)
      }
    } catch (error) {
      const errorCode = error.response ? error.response.status : 'NETWORK_ERROR'
      const errorDetail =
        error.response && error.response.data.errors
          ? error.response.data.errors
          : error.message

      const fileError = {
        errorCode,
        errorDetail
      }
      if (!reload) {
        setFilesError((prevErrors) => [...prevErrors, fileError])
      }

      let extractedErrors
      if (Array.isArray(errorDetail)) {
        extractedErrors = errorDetail.map((detail) => {
          const match = detail.match(/^(.*?)(?=")/)
          return match ? match[1].trim() : detail
        })
      } else {
        extractedErrors = [errorDetail]
      }

      extractedErrors.forEach((errDetail) => {
        if (typeof errDetail === 'string') {
          const translatedError = translateError(errDetail)
          setListUploadErrors((prevErrors) => [...prevErrors, translatedError])
        } else if (typeof errDetail === 'object' && errDetail !== null) {
          const errorsArray = Object.entries(errDetail).map(
            ([key, value]) => `${key}: ${translateError(String(value))}`
          )
          setListUploadErrors((prevErrors) => [...prevErrors, ...errorsArray])
        }
      })
    }
  }
  useEffect(() => {
    if (filesError.length > 0) {
      setErrorsBar(listUploadErrors)
      setReload(true)
    }
  }, [filesError, listUploadErrors])

  async function reloadFiles() {
    setReloaFile((prevState) => prevState + 1)
  }

  const clearState = () => {
    setIsUploading(false)
    setTotalUploadFiles(0)
    setCountFileUpload(0)
    setFileName('')
    setUpdatedQuestions(false)
    setErrorsBar([])
    setFilesError([])
    setReload(false)
    setListUploadErrors([])
    setReloaFile(0)
  }

  return (
    <UploadQuestionWorksheetContext.Provider
      value={{
        isUploading,
        setIsUploading,

        totalUploadFiles,
        setTotalUploadFiles,

        countFileUpload,
        setCountFileUpload,

        uploadFile,

        clearState,

        fileName,

        updatedQuestions,
        setUpdatedQuestions,

        errorsBar,
        setErrorsBar,

        reloadFiles,

        filesError,
        setFilesError,

        reload,
        setReload,

        listUploadErrors,
        setListUploadErrors,

        reloaFile,
        setReloaFile
      }}
    >
      {children}
    </UploadQuestionWorksheetContext.Provider>
  )
}

UploadQuestionWorksheet.propTypes = {
  children: PropTypes.node.isRequired
}

export { UploadQuestionWorksheet }
