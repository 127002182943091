/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { DatePickerSaraiva } from '@equipe-ninja/saraiva-ui/core/date_picker/DatePicker'
import { useHistory } from 'react-router-dom'
import ticketService from '../../services/ticketService'
import messages from '../../helpers/messages'

import useStyles from './styles.js'

const EditLibraryDate = ({
  handleOpen,
  handleClose,
  setLoading,
  listIds,
  startDate,
  endDate
}) => {
  const classes = useStyles()

  function toDate(dateStr) {
    const parts = dateStr.split('/')
    const correctDay = parseInt(parts[0], 10) + 1
    return new Date(parts[2], parts[1] - 1, correctDay)
  }

  function dateToDB(dateStr) {
    const date = new Date(dateStr)
    const mnth = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const newDate = [date.getFullYear(), mnth, day].join('-')
    return newDate
  }

  const [initialDate, setInitialDate] = useState(toDate(startDate))
  const [initialDateError, setInitialDateError] = useState(false)
  const [initialDateErrorMessage, setInitialDateErrorMessage] = useState('')
  const [finalDate, setFinalDate] = useState(toDate(endDate))
  const [finalDateError, setFinalDateError] = useState(false)
  const [finalDateErrorMessage, setFinalDateErrorMessage] = useState('')
  const [paramStartDate, setParamStartDate] = useState(dateToDB(initialDate))
  const [paramEndDate, setParamEndDate] = useState(dateToDB(finalDate))
  const [verifySaveButton, setVerifySaveButton] = useState(true)

  const history = useHistory()

  async function updateDates() {
    setLoading(true)
    try {
      const payload = {
        list_ids: listIds,
        date_params: { start_date: paramStartDate, end_date: paramEndDate }
      }
      const response = await ticketService.update_date(payload)

      if (response.status === 204) {
        handleClose()
        history.go(0)
      }
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  const handleInitialDate = (str) => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)

    if (str < dateNow) {
      setVerifySaveButton(true)
      setInitialDate(null)
      setInitialDateError(true)
      setInitialDateErrorMessage(messages.dateTicketEdit.retroactive)
      return
    }
    setInitialDateErrorMessage('')
    setInitialDateError(false)
    setVerifySaveButton(false)
    setInitialDate(str)
    setParamStartDate(dateToDB(str))
  }

  const handleFinalDate = (str) => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)

    if (str < dateNow) {
      setVerifySaveButton(true)
      setFinalDate(null)
      setFinalDateError(true)
      setFinalDateErrorMessage(messages.dateTicketEdit.retroactive)
      return
    }
    if (str < initialDate) {
      setVerifySaveButton(true)
      setFinalDate(null)
      setFinalDateError(true)
      setFinalDateErrorMessage(messages.dateTicketEdit.beforeInitialDate)
      return
    }
    setVerifySaveButton(false)
    setFinalDate(str)
    setFinalDateError(false)
    setFinalDateErrorMessage('')
    setParamEndDate(dateToDB(str))
  }

  return (
    <Dialog
      open={handleOpen}
      title="Alterar a data de todos os vouchers da biblioteca"
      primaryButton="salvar"
      secondaryButton="cancelar"
      size="lg"
      handleClose={handleClose}
      handleConfirm={updateDates}
      isDisabled={verifySaveButton}
    >
      <div id="input" className={classes.inputWrapper}>
        <DatePickerSaraiva
          label="Data de inicio"
          size="md"
          error={initialDateError}
          helperText={initialDateErrorMessage}
          value={initialDate}
          onChange={handleInitialDate}
        />
      </div>

      <div id="input">
        <DatePickerSaraiva
          label="Data de fim"
          size="md"
          error={finalDateError}
          helperText={finalDateErrorMessage}
          value={finalDate}
          onChange={handleFinalDate}
        />
      </div>
    </Dialog>
  )
}

EditLibraryDate.propTypes = {
  handleOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.bool.isRequired,
  setLoading: PropTypes.bool.isRequired,
  listIds: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
}

export default EditLibraryDate
