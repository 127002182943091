import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  CardSubTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px'
  },
  text: {
    fontWeight: 'normal',
    marginBottom: '12px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  textWithOutMb: {
    fontWeight: 'normal',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textH5: {
    fontSize: '24px',
    marginTop: '40px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '133.4%'
  },
  textH6: {
    fontSize: '20px',
    margin: '40px 0px 16px',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '160%'
  },
  CardTextH6: {
    fontSize: '20px',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '160%',
    heigth: '30px'
  },
  textSub: {
    fontSize: '14px',
    marginTop: '40px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '133.4%'
  },
  errorButton: {
    marginTop: '40px',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}))

export default useStyles
