import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../../helpers/mock-server-utils'
import {
  SCHEDULED_CONTENT_REMOTE_FIELDS as R,
  SCHEDULED_CONTENT_REMOTE_TYPES as RT,
  SCHEDULED_CONTENT_REMOTE_EXERCISE_TYPE as ET
} from '../../remote-fields/scheduled-content'
import { ScheduledContentAdapter } from '../../adapters/scheduled-content'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para deletar um conteúdo agendado
 */
const deleteScheduledContent = async (id) => {
  const path = `/contents/api/v1/scheduled_content?id=${id}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.delete(path, null, headers)
  return response.ok
}

/* eslint-enable no-unused-vars */

const factory = () => {
  return [
    {
      [R.ANSWER_CONTENT]: 'conteudo',
      [R.ANSWER_PUBLISHING_DATE]: '2022-07-31',
      [R.CONTENT]: null,
      [R.END_DATE]: '2022-07-31',
      [R.ID]: 1,
      [R.INITIAL_DATE]: '2022-07-01',
      [R.LINK]: 'www.lfg.com.br',
      [R.TITLE]: 'Title 1',
      [R.SCHEDULED_CONTENT_TYPE]: RT.OAB_SECOND_PHASE,
      [R.EXERCISE_TYPE]: ET.QUESTION_BLOCK
    }
  ]
}

// eslint-disable-next-line no-unused-vars
const mockedResponse = (id) => {
  return mockedServerAnswer(
    factory(),
    true,
    1000,
    ScheduledContentAdapter.inbound
  )
}

export { deleteScheduledContent }
