/**
 * Transforma uma valor decimal em uma representação de string com duas casas demais e separador
 *
 * ### Example
 *
 * ```js
 *  const decimalValue = 1700.00
 * parseRemoteDecimal(decimalValue) // "1700,00"
 * const value2 = null
 * parseRemoteDecimal(value2) // ""
 * ```
 * @param {number} decimalValue
 * @returns
 */
export const parseRemoteDecimal = (decimalValue) => {
  if (decimalValue === undefined || decimalValue === null) return ''
  return decimalValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    useGrouping: false
  })
}

export const parseRemoteInteger = (integer) => {
  if (integer === undefined || integer === null) return ''
  return integer?.toString()
}

/**
 * Recebe um valor e checa se eles se encontrada na lista que considera vazio
 * @param {any} value o valor que será checado se é vazio
 * @param {any} defaultValue valor default que sera retornando caso o valor de entrada esteja vazio,
 * default: `"Não informado"`
 * @param {[any]} emptyValues lista de valores que serão considerados como vazio
 * default: `[null, '', undefined]`
 * @returns valor de entrada caso o valor não esteja no array que considera vazio, ou o valor default
 *
 * ### Example
 *
 * ```js
 * defaultValueWhenEmpty(null) // "Não informado"
 * defaultValueWhenEmpty('vazio', 'pendente', ['vazio']) // "pendente"
 * ```
 */
export const defaultValueWhenEmpty = (
  value,
  defaultValue = 'Não informado',
  emptyValues = [null, '', undefined]
) => {
  return emptyValues.includes(value) ? defaultValue : value
}
