import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '24px'
  },
  divider: {
    marginTop: '24px',
    marginBottom: '36px'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableContainer: {
    marginTop: '24px'
  },
  CardContext: {
    border: '1px solid rgb(235,235,235)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    marginTop: '0px',
    marginBottom: '24px'
  },
  CardMain: {
    padding: '15px 15px'
  },
  CardBanner: {
    marginBottom: '0px'
  },
  CardsBanner: {
    flexDirection: 'row',
    display: 'flex'
  },
  CardBannerSale: {
    marginBottom: '0px',
    '& img': {
      maxWidth: '80px',
      maxHeight: '80px',
      margin: '5px'
    }
  },
  CardTitle: {
    fontWeight: 'normal',
    marginTop: '16px'
  },
  CardDetals: {
    color: 'rgba(0,0,0,.54)',
    marginTop: '8px',
    '& span': {
      marginRight: '2px'
    },
    '& span:nth-child(2)': {
      marginLeft: '30px'
    }
  },
  CardFooter: {
    borderTop: '1px solid rgb(235,235,235)',
    padding: '5px 5px',
    color: '#245A93',
    '& span:hover': {
      cursor: 'pointer'
    }
  },
  contentCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  tabs: {
    marginTop: '24px'
  },
  paper: {
    width: 400,
    borderRadius: 10,
    filter: 'none',
    boxShadow: 'none',
    background: 'transparent',
    marginBottom: 24,
    padding: '0px',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main
    },
    zIndex: 1000,
    '& .MuiPaper-root': {
      flexDirection: 'row',
      flexWrap: 'none',
      display: 'flex'
    }
  },
  contentUpdate: {
    border: '1px solid rgb(230,230,230)',
    marginBottom: 40,
    borderRadius: 8,
    background: '#FFF',
    padding: 24,
    '& h3': {
      fontWeight: '500'
    },
    '& .MuiFormControl-root': {
      marginBottom: 20
    }
  },
  contentTitle: {
    letterSpacing: '0.15px',
    fontWeight: '500',
    fontSize: 20,
    marginBottom: 16
  },
  contentDisplayLeft: {
    justifyContent: 'flex-start',
    marginTop: 24,
    alignItems: 'center',
    display: 'flex',
    '& .MuiGrid-justify-content-xs-space-around': {
      justifyContent: 'flex-start',
      marginRight: 24,
      width: 300,
      '& .MuiFormControl-root': {
        width: '100% !important'
      }
    }
  },
  boxImage: {
    marginRight: 100,
    height: 130,
    '& > span': {
      fontSize: 12,
      marginBottom: 10,
      display: 'block'
    }
  },
  boxDelete: {
    '& > .MuiButton-root': {
      padding: 0,
      color: 'rgb(150,0,0)'
    },
    '& > .MuiButton-root:hover': {
      background: 'transparent !important'
    }
  },
  footerUpdate: {
    justifyContent: 'flex-end',
    marginTop: 20,
    alignItems: 'center',
    display: 'flex',
    gap: 10
  },
  footerUpdateDelete: {
    border: '1px solid rgb(150,0,0)',
    color: 'rgb(150,0,0)',
    '& .MuiButton-root:hover': {
      background: 'transparent !important',
      border: '1px solid rgb(150,0,0)'
    }
  }
}))

export default useStyles
