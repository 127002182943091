import { remoteDateStringToHuman } from '../../utils/remote-datetime-utils'

const inbound = (data) => {
  return data.map((item) => {
    const reuse = item.subject.other_courses
    let reuseCourses = []

    if (item.subject.other_courses.length > 0) {
      const items = reuse.map((subItem) => ({
        id: subItem.subject_id,
        name: subItem.subject_title,
        courseId: subItem.course_id,
        reuseCourseName: subItem.course_title,
        courseSku: subItem.course_sku,
        createdAt: remoteDateStringToHuman(subItem.link_with_course)
      }))
      reuseCourses = [...items]
    }

    const subj = item.subject.origin_course

    const origin = {
      id: subj.subject_id,
      name: subj.subject_title,
      courseOrigin: subj.course_title,
      courseSku: subj.course_sku,
      courseId: subj.course_id,
      reuseCourses,
      createdAt: remoteDateStringToHuman(subj.link_with_course),
      updatedAt: remoteDateStringToHuman(subj.updated_at)
    }
    return origin
  })
}

export const CoursesSubjectsAdapter = {
  inbound
}
