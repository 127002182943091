import React from 'react'
import PropTypes from 'prop-types'
import { USER_ORDER_ITEM_COMPLETION_STATUS } from '../../../../../domain/user-management-content/user-orders'

export const CompletionStatus = ({ status }) => {
  const { CANCELLED, COMPLETED, ON_GOING } = USER_ORDER_ITEM_COMPLETION_STATUS

  const statusLabel = () => {
    switch (status) {
      case CANCELLED.value:
        return CANCELLED.label
      case COMPLETED.value:
        return COMPLETED.label
      default:
        return ON_GOING.label
    }
  }

  const colorCoding = () => {
    switch (status) {
      case CANCELLED.value:
        return '#a42c31'
      case COMPLETED.value:
        return '#212121'
      default:
        return '#1f8563'
    }
  }

  return <span style={{ color: colorCoding() }}>{statusLabel()}</span>
}

CompletionStatus.propTypes = {
  status: PropTypes.string.isRequired
}
