import {
  TextInput,
  SelectSaraiva,
  MenuItem,
  Alert
} from '@equipe-ninja/saraiva-ui'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useStyles from '../styles'
import { ButtonUpload } from './ButtonUpload'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { UploadProvider } from '../../../../contexts/UploadContext'
import { ImageDimension } from '../../../../helpers/imageDimension'
import {
  bannersSizers,
  BANNER_SIZES
} from '../../../../domain/promotional-content/banners'

export function FormBannerSale({ formState, formErrors, onChange, sequence }) {
  const classes = useStyles()
  const { URL, ORDER, IMG_RECURSIVE } = FIELDS
  const [imageMobileError, setImageMobileError] = useState(false)

  async function handleFileChange(field, image) {
    const dimension = bannersSizers(BANNER_SIZES.OFFERS)
    const sizeIsValid = await ImageDimension.imageSizeValidator(
      image,
      dimension
    )
    onChange(field, sizeIsValid ? image : null, sequence)
    setImageMobileError(!sizeIsValid)
  }

  const onDeleteFile = (field) => {
    setImageMobileError(false)
    onChange(field, null, sequence)
  }

  return (
    <>
      <div className={classes.contentUpdate}>
        <div className={classes.contentTitle}>Banner ofertas em destaque</div>
        <TextInput
          value={formState[URL[0].value][sequence]}
          color="primary"
          error={!!formErrors[`${URL[0].value}[${sequence}]`]}
          helperText={
            <>
              {!!formErrors[`${URL[0].value}[${sequence}]`] && (
                <div>{formErrors[`${URL[0].value}[${sequence}]`]}</div>
              )}
              <div>
                Inserir o subdiretório da URL, ou seja as páginas internas do
                seu site: http://www.lfg.com.br/
                <b>concursos-cursos-carreiras-juridicas</b>
              </div>
            </>
          }
          onChange={(e) =>
            onChange(URL[sequence].value, e.target.value, sequence)
          }
          size="full"
          label="URL de redirecionamento do banner"
          required
        />
        <SelectSaraiva
          error={!!formErrors[`${ORDER[0].value}[${sequence}]`]}
          helperText={formErrors[`${ORDER[0].value}[${sequence}]`]}
          value={formState[ORDER[0].value][sequence]}
          onChange={(e) => onChange(ORDER[0].value, e.target.value, sequence)}
          label="Ordem de exibição *"
          size="full"
          required
        >
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
        </SelectSaraiva>
        <div className={classes.contentDisplayLeft} style={{ marginTop: 0 }}>
          <div className={classes.boxImage}>
            <span>Utilizar JPG ou PNG no tamanho 283x230px</span>
            <UploadProvider>
              <ButtonUpload
                field={IMG_RECURSIVE[0].value}
                image={formState[IMG_RECURSIVE[0].value][sequence]}
                type=""
                // eslint-disable-next-line react/jsx-no-bind
                onUpload={handleFileChange}
                onDelete={onDeleteFile}
                sequence={sequence}
              />
            </UploadProvider>
            {imageMobileError && (
              <Alert severity="error">
                Banner responsivo anexado não corresponde ao tamanho indicado
              </Alert>
            )}
            {formErrors[`${IMG_RECURSIVE[0].value}[${sequence}]`] && (
              <Alert severity="error">
                {formErrors[`${IMG_RECURSIVE[0].value}[${sequence}]`]}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

FormBannerSale.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  sequence: PropTypes.number.isRequired
}
