import { BRANDS_IDENTIFIER } from './brands'

/**
 *
 * @param {*} brandIdentifier {@link BRANDS_IDENTIFIER}
 * @returns a base url para paginas promocionais da brand selecionada
 *
 * ### Regra de Negocio
 * Todo e qualquer campo que tenha que ter uma url da LFG já deve vir com a url `http://www.lfg.com.br/` preenchida para o usuário
 */
export const initialURLValueForBrand = (brandIdentifier) => {
  switch (brandIdentifier) {
    case BRANDS_IDENTIFIER.BDS:
    case BRANDS_IDENTIFIER.DONS:
      return ''
    default:
      return 'https://www.lfg.com.br/'
  }
}
