import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const ticketService = {
  async listLibraries() {
    const path = 'tickets/api/v1/tickets/libraries'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async create(payload) {
    const path = '/tickets/api/v1/tickets'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },

  async update_date(payload) {
    const path = '/tickets/api/v1/tickets/update_date'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.patch(path, payload, headers)
    return response
  },

  async fetchTicketsByLibraryCode(libraryCode) {
    const path = `tickets/api/v1/libraries/${libraryCode}/tickets`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async fetchUsersByTicketId(ticketId) {
    const path = `tickets/api/v1/tickets/${ticketId}/users`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async getLibrary(code) {
    const path = `tickets/api/v1/tickets/libraries/${code}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async deleteTickets(payload) {
    const path = `/tickets/api/v1/tickets`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, payload, headers)
    return response
  },

  async unbindUsers(ticketId, payload) {
    const path = `/tickets/api/v1/tickets/${ticketId}/users`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, payload, headers)
    return response
  },

  async exportTicketUsers(ticketId, { userIds, columns }) {
    const payload = { list_users: userIds, columns }
    const path = `/tickets/api/v1/tickets/${ticketId}/export_ticket_users`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },

  async exportLibraryTickets(libraryCode, { ticketIds, columns }) {
    const payload = { ticket_ids: ticketIds, columns }
    const path = `/tickets/api/v1/libraries/${libraryCode}/export_library_tickets`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },

  async exportTicketsUsers(libraryCode, { ticketIds, columns }) {
    const payload = { ticket_ids: ticketIds, columns }
    const path = `/tickets/api/v1/libraries/${libraryCode}/export_tickets_users`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  },

  async searchLibrariesBy(type, searchParam) {
    const path = `tickets/api/v1/tickets/libraries/search_libraries_by?${type}=${searchParam}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async searchTicketsBy(libraryCode, type, searchParam) {
    const path = `tickets/api/v1/libraries/${libraryCode}/tickets/search_vouchers_by?${type}=${searchParam}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async searchUsersBy(ticketId, type, searchParam) {
    const path = `tickets/api/v1/tickets/${ticketId}/users/search_users_by?${type}=${searchParam}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async archiveTickets(libraryCode) {
    const path = `/tickets/api/v1/libraries/${libraryCode}/tickets/archive_tickets`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.patch(path, null, headers)
    return response
  },

  async unarchiveTickets(libraryCode) {
    const path = `/tickets/api/v1/libraries/${libraryCode}/tickets/unarchive_tickets`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.patch(path, null, headers)
    return response
  }
}

export default ticketService
