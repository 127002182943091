/* eslint-disable consistent-return */
import Papa from 'papaparse'

/**
 *
 * @param data array de objetos com mesmo formato
 * @param headersOverrides objeto com os nomes das colunas que devem ser substituidas
 * @returns dados no formato de CSV
 *
 * ### Exemplo
 *
 * ```js
 * const data = [{ col1: 'João', col2: 18, altura: '180cm' }]
 * const columns = { col1: 'nome', col2: 'idade' }
 *
 * exportDataToCsv(data, columns)
 * // nome, idade, altura
 * // João, 18, 180cm
 * ```
 */
export const exportDataToCsv = (
  data,
  headersOverrides = {},
  delimiter = ','
) => {
  if (data[0]) {
    const sample = data[0]
    const template = Object.fromEntries(
      Object.keys(sample).map((k) => [k, headersOverrides[k] || k])
    )

    const dataToConvert = data.map((e) =>
      Object.fromEntries(Object.entries(e).map(([k, v]) => [template[k], v]))
    )

    return Papa.unparse(dataToConvert, { delimiter })
  }
}
