import { generateFormError } from '../../general/generate-form-errors'
import { COURSE_OVERVIEW as FIELDS } from '../entities'

const formFormat = {
  [FIELDS.END_DATE]: null,
  [FIELDS.START_DATE]: null,
  [FIELDS.STATUS]: '',
  [FIELDS.TITLE]: ''
}

export const couserOverviewDefaultForm = () => {
  return {
    values: {
      ...formFormat
    },
    errors: generateFormError(formFormat)
  }
}
