export const SCHEDULED_CONTENT_EXERCISE_TYPE = {
  QUESTION_BLOCK: 'question-block',
  PECA_PROCESSUAL: 'peca-processual'
}

export const scheduledContentExerciseTypeLabel = (type) => {
  switch (type) {
    case SCHEDULED_CONTENT_EXERCISE_TYPE.QUESTION_BLOCK:
      return 'Bloco de questões'

    case SCHEDULED_CONTENT_EXERCISE_TYPE.PECA_PROCESSUAL:
      return 'Peça Processual'

    default:
      return ''
  }
}
