/**
 * Classes de utilidades para tratar datas remotas
 */
export class RemoteDateUtils {
  /**
   * Transforma uma string que representa uma data recebida do servidor para uma string legivel
   *
   * ### Example
   * ```js
   * const dateString = "2022-03-10T13:37:37"
   * RemoteDateUtils.parseDateStringToHuman(dateString) // "10/03/2022"
   * ```
   *
   * @param {string} dateString - um date no formado de string vinda do servidor
   */
  static parseDateStringToHuman(dateString) {
    return (
      dateString &&
      new Date(`${dateString}T00:00:00`).toLocaleDateString('pt-BR')
    )
  }

  /**
   * Transforma uma string que representa uma data recebida do servidor para uma string legivel
   *
   * ### Example
   * ```js
   * const dateString = "2022-03-10T13:37:37"
   * RemoteDateUtils.parseRemoteDate(dateString)
   * // Date Thu Mar 10 2022 13:37:37 GMT-0300 (Brasilia Standard Time)
   * ```
   *
   * @param {*} dateString - um date no formado de string vinda do servidor
   */
  static parseRemoteDate(dateString) {
    return dateString && new Date(`${dateString}T00:00:00`)
  }
}
