import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../helpers/mock-server-utils'
import { PaymentInfoAdapter } from '../adapters/payment-info-adapters'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para estonar um valor curso
 * @returns Promise com a resposta ou rejeita com erro
 */
const extendAccessCourse = (courseId, dateToExtend, reason) => {
  return new Promise((resolve, reject) => {
    const path = `promotional/api/v1/services/${courseId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, headers)
      .then((response) => {
        resolve(PaymentInfoAdapter(response))
      })
      .catch((error) => reject(error))
  })
}

const factory = (id) => {
  return {
    id
  }
}

const mockedResponse = (courseId, dateToExtend, reason) => {
  return mockedServerAnswer(factory(courseId), true, 1000)
}

export { mockedResponse as extendAccessCourse }
