import { getSessionToken, parseJwt } from '../../auth'
import httpClient from '../../gateway/generalHttpRequest'

const Users = {
  getFullName() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    return `${user.first_name} ${user.last_name}`
  },

  getEmail() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    return user.email
  },

  getUserId() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    return user.id
  },

  getIntegrationType() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    return user.integration_type
  },

  async searchDataUsers(page, perPage, email) {
    const path = `accounts/api/v1/users_management?page=${page}&per_page=${perPage}&email=${email}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },

  async searchFilterUser(page, perPage, email) {
    const path = `accounts/api/v1/users_management?page=${page}&per_page=${perPage}&email=${email}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },

  async searchUserProducts(userId) {
    const path = `accounts/api/v1/user_management/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },

  async deleteUserProductCae(id_or_email, app_name, cae_list) {
    const path = `/accounts/api/v1/user_management/service?id_or_email=${id_or_email}&app_name=${app_name}&cae_list=${JSON.stringify(
      cae_list
    )}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.delete(path, null, headers)
    return response
  },

  async editUser(payload, user_id) {
    const path = `/accounts/api/v1/user_management/${user_id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.put(path, payload, headers)
    return response
  },

  async recoverPasswordEmail(payload) {
    const path = `accounts/api/v1/password`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async editCae(user_id, old_cae, new_cae) {
    const path = `/accounts/api/v1/user_management/service`

    const body = {
      user_id: Number(user_id),
      old_cae: Number(old_cae),
      new_cae: Number(new_cae)
    }

    let headers = {
      'session-token': getSessionToken(),
      'Content-Type': 'application/json'
    }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.put(path, body, headers)
    return response
  },

  async deleteUser(user_id) {
    const path = `/accounts/api/v1/user_management/?user_id=${user_id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.delete(path, null, headers)
    return response
  }
}

export default Users
