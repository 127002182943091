import { COURSE_OVERVIEW_RAW_DATA, ECOMMERCE_FORM_FIELDS } from '../entities'
import {
  generalInfoValidation,
  singleProductCourseTabValidation
} from '../../product-course/business'
import { generateEcommerceFormFromRaw } from './generate-ecommerce-form-values-from-raw'

const { SERVICE, FULL_POSTED_SUBJECT_COUNT } = COURSE_OVERVIEW_RAW_DATA
const { COURSE_TABS } = ECOMMERCE_FORM_FIELDS

const hasInvalidTabs = (tabs) => {
  if (!tabs) return true

  return tabs?.some((tab) => {
    const { isValid: tabIsValid } = singleProductCourseTabValidation(tab)

    return !tabIsValid
  })
}

export const isCourseEcommerceReady = (overviewRawData) => {
  const { isValid } = generalInfoValidation(
    generateEcommerceFormFromRaw(overviewRawData)
  )

  if (!isValid) return false

  if (overviewRawData?.[SERVICE]?.[COURSE_TABS]?.length === 0) return false

  if (hasInvalidTabs(overviewRawData?.[SERVICE]?.[COURSE_TABS])) {
    return false
  }

  return true
}

export const isCourseAvaReady = (overviewRawData) => {
  const count = overviewRawData?.[FULL_POSTED_SUBJECT_COUNT] || 0

  return count > 0
}
