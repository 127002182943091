import {
  Button,
  Dialog,
  DatePickerSaraiva,
  SelectSaraiva,
  MenuItem
} from '@equipe-ninja/saraiva-ui'
import React, { useState, useContext, useEffect } from 'react'
import { useHistory, generatePath, useParams } from 'react-router-dom'
import { Delete } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isNumber } from 'lodash'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { initialURLValueForBrand } from '../../../../domain/brands'
import {
  getBanner,
  updateBanner,
  deleteBanner
} from '../../../../services/promotional-content/banners-pages'
import useStyles from '../styles'
import {
  bannerRotationValidation,
  bannerRotationFieldValidation
} from '../../../../domain/promotional-content/banners/business/banners-ratativo-validation'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import ContentTitle from '../../../../components/ContentTitle'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { PromotionalContext } from '../../../../contexts/PromotionalContext'
import { FormBannerSale } from '../Form/FormBannerSale'

const { KEY, URL, TITLE, ORDER, IMG_RECURSIVE, START_DATE, END_DATE, STATUS } =
  FIELDS

export function EditBannerSale() {
  const classes = useStyles()
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [isExecutingTask, setIsExecutingTask] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const { setTypeBanner } = useContext(PromotionalContext)

  const { id } = useParams()

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalBannerList,
    hasAccessToPromotionalPages
  )

  const handleDuplicateBanner = (idDuplicated, typeBanner) => {
    setTypeBanner(typeBanner)
    history.push(
      generatePath(RoutesMapping.promotionalBannerDuplicar, {
        brand,
        id: idDuplicated
      })
    )
  }

  const [formState, setFormState] = useState({
    [KEY.value]: null,
    [URL[0].value]: URL.map(() => initialURLValueForBrand(brand)),
    [ORDER[0].value]: ORDER.map(() => ''),
    [IMG_RECURSIVE[0].value]: IMG_RECURSIVE.map(() => ''),
    [END_DATE.value]: null,
    [START_DATE.value]: null,
    [STATUS.value]: ''
  })

  const [formErrors, setFormErrors] = useState({
    [KEY.value]: false,
    [URL[0].value]: false,
    [ORDER[0].value]: false,
    [IMG_RECURSIVE[0].value]: false,
    [START_DATE.value]: false,
    [END_DATE.value]: false,
    [STATUS.value]: false
  })

  const onChangeOrder = (newValue, itemIndex) => {
    const newValueNumber = parseInt(newValue, 10)
    const currentOrderValue = formState[ORDER[0].value][itemIndex]
    const currentHolderIndexForNewValue = formState[ORDER[0].value].reduce(
      (prev, current, index) => {
        if (prev) return prev
        return current === newValueNumber ? index : prev
      },
      null
    )

    setFormState((prevState) => ({
      ...prevState,
      [ORDER[0].value]: prevState[ORDER[0].value].map((item, index) => {
        if (index === itemIndex) return newValueNumber
        if (index === currentHolderIndexForNewValue) return currentOrderValue
        return item
      })
    }))
  }

  const onChangeHandler = (field, dateValue, sequence) => {
    if (isNumber(sequence)) {
      if (field === ORDER[0].value) {
        onChangeOrder(dateValue, sequence)
      } else {
        const newState = formState[field].map((e, i) => {
          return i === sequence ? dateValue : e
        })

        setFormState((prev) => ({ ...prev, [field]: newState }))
      }
    } else {
      const fieldValidation = bannerRotationFieldValidation(
        field,
        dateValue,
        formState,
        'edit'
      )
      setFormState({ ...formState, [field]: dateValue })
      setFormErrors((prev) => ({ ...prev, ...fieldValidation }))
    }
  }

  const onUpdate = () => {
    updateBanner(formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage(
          `Banner '${response[TITLE.value]}' atualizado com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const hasFormValidation = () => {
    const { isValid, errors } = bannerRotationValidation(formState, 'edit')
    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }
    onUpdate()
  }

  useEffect(() => {
    setIsFetching(true)

    getBanner(id)
      .then((banner) => {
        setFormState(banner)
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar a lista de banner de oferta')
      })
      .finally(() => setIsFetching(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrorMessage, id])

  const handleDelete = () => {
    deleteBanner(id)
      .then(() => {
        setOpenDialog(false)
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage('Banner excluído com sucesso')
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  return (
    <ContentWithHeader
      title="Editar Banner"
      backButtonRoute={generatePath(RoutesMapping.promotionalBannerList, {
        brand
      })}
    >
      <ContentTitle title="" size="small" />
      {isFetching ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {URL.map((url_, index) => (
            <FormBannerSale
              formState={formState}
              formErrors={formErrors}
              onChange={onChangeHandler}
              sequence={index}
            />
          ))}
          <ContentTitle title="Publicar" size="small" />
          <div
            className={classes.contentDisplayLeft}
            style={{ alignItems: 'flex-start' }}
          >
            <DatePickerSaraiva
              error={!!formErrors[START_DATE.value]}
              helperText={formErrors[START_DATE.value]}
              label="Data de início do banner *"
              value={formState[START_DATE.value]}
              onChange={(selectedDate) => {
                onChangeHandler(START_DATE.value, selectedDate)
              }}
              size="sm"
            />
            <DatePickerSaraiva
              error={!!formErrors[END_DATE.value]}
              helperText={formErrors[END_DATE.value]}
              label="Data de fim do banner *"
              value={formState[END_DATE.value]}
              onChange={(selectedDate) => {
                onChangeHandler(END_DATE.value, selectedDate)
              }}
              size="sm"
            />
          </div>
          <div className={classes.contentDisplayLeft}>
            <SelectSaraiva
              error={!!formErrors[STATUS.value]}
              helperText={formErrors[STATUS.value]}
              value={formState[STATUS.value]}
              onChange={(e) => {
                onChangeHandler(STATUS.value, e.target.value)
              }}
              label="Status da publicação*"
              required
              size="full"
            >
              <MenuItem value="">Nenhum status de publicação</MenuItem>
              <MenuItem value="ativo">Em exibição no site</MenuItem>
              <MenuItem value="inativo">Inativo</MenuItem>
            </SelectSaraiva>
          </div>
          <div className={classes.footerUpdate} style={{ marginBottom: 178 }}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<Delete />}
              className={classes.footerUpdateDelete}
              onClick={() => setOpenDialog(true)}
            >
              EXCLUIR BANNER
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleDuplicateBanner(formState[KEY.value], 'sale')
              }}
            >
              DUPLICAR BANNER
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={hasFormValidation}
              disabled={isExecutingTask}
            >
              SALVAR
            </Button>
          </div>
          <SnackbarOutlet />
          <Dialog
            open={openDialog}
            title="Excluir banner"
            secondaryButton="CANCELAR"
            primaryButton="EXCLUIR"
            handleClose={() => setOpenDialog(false)}
            handleConfirm={handleDelete}
            content=""
          >
            Ao excluir este banner, ele não estará mais visível no site, nem
            será mantido como histórico.
          </Dialog>
        </>
      )}
    </ContentWithHeader>
  )
}
