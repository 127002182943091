import { atom } from 'recoil'
import { USER_MANAGEMENT_DETAIL_FIELDS } from '../../../domain/user-management-content/user-management-page'

const {
  ACCESS_LOCALE,
  ADDRESS,
  CEP,
  CHANGE_BY,
  CITY,
  CPF,
  CREATE_ACCOUNT_DATE,
  EMAIL,
  FULL_NAME,
  INFORMATION_CHANGE,
  JUSTIFICATION,
  JUSTIFICATIONS,
  KEY,
  LAST_ACCESS,
  LAST_NAME,
  MOVING_DATE,
  NAME,
  NEIGHBORHOOD,
  PASSWORD,
  STATE,
  STATUS,
  STREET
} = USER_MANAGEMENT_DETAIL_FIELDS

export const userDetailsState = atom({
  key: 'userDetailsState',
  default: {
    isFetching: false,
    isFetchingOrders: false,
    orders: [],
    [ACCESS_LOCALE.value]: '',
    [ADDRESS.value]: '',
    [CEP.value]: '',
    [CHANGE_BY.value]: '',
    [CITY.value]: '',
    [CPF.value]: '',
    [CREATE_ACCOUNT_DATE.value]: '',
    [EMAIL.value]: '',
    [FULL_NAME.value]: '',
    [INFORMATION_CHANGE.value]: '',
    [JUSTIFICATION.value]: '',
    [JUSTIFICATIONS.value]: '',
    [KEY.value]: '',
    [LAST_ACCESS.value]: '',
    [LAST_NAME.value]: '',
    [MOVING_DATE.value]: '',
    [NAME.value]: '',
    [NEIGHBORHOOD.value]: '',
    [PASSWORD.value]: '',
    [STATE.value]: '',
    [STATUS.value]: '',
    [STREET.value]: ''
  }
})
