import React, { useContext } from 'react'
import { Typography, List, Divider } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Button } from '@equipe-ninja/saraiva-ui'

import HelpIcon from '../../../../components/HelpIcon'
import useStyles from './styles'
import DeploymentRow from '../DeploymentRow'
import DeploymentForm from '../DeploymentForm'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'

function DeploymentsList() {
  const classes = useStyles()
  const {
    connection,
    showingDeploymentForm,
    toggleDeploymentForm
  } = useContext(ConnectionContext)

  const renderList = () => {
    if ( !connection.integration_data.deployment 
        || connection.integration_data.deployment.length === 0) {
      return (
        <Typography
          variant="body2"
          className={classes.emptyDeploymentIdsMessage}
        >
          Ainda não foi adicionado nenhum ID de desenvolvimento
        </Typography>
      )
    }

    return (
      <List component="nav" disablePadding>
        <Divider />
        {connection.integration_data.deployment.map((deploymentId) => (
          <DeploymentRow deploymentId={deploymentId} key={deploymentId} />
        ))}
      </List>
    )
  }

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        3. ID de desenvolvimento
        <HelpIcon onClick={() => {}} />
        <Button
          className={classes.addButton}
          color="primary"
          onClick={() => toggleDeploymentForm(true)}
          startIcon={<AddIcon />}
        >
          ID DE DESENVOLVIMENTO
        </Button>
      </Typography>

      {showingDeploymentForm && <DeploymentForm />}

      {renderList()}
    </>
  )
}

export default DeploymentsList
