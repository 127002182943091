import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@equipe-ninja/saraiva-ui'
import { IconButton } from '@material-ui/core'
import { MoreVert, Edit } from '@material-ui/icons'

export const EditContentMenu = ({ contentId, handleEditContent }) => {
  const [anchorEl, setAnchorEl] = useState()

  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.target)}>
        <MoreVert />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl()}
      >
        <MenuItem onClick={() => handleEditContent(contentId)}>
          <Edit /> Alterar conteúdo
        </MenuItem>
      </Menu>
    </>
  )
}

EditContentMenu.propTypes = {
  contentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  handleEditContent: PropTypes.func.isRequired
}
