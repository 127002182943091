import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  drawerList: {
    marginTop: '80px'
  },
  item: {
    color: theme.palette.text.primary,
    padding: '12px 18px'
  },
  muiButton: {
    padding: '4px 16px 4px 16px'
  },
  selectedItem: {
    color: theme.palette.primary.main
  },
  selectedListItem: {
    color: '#624C92',
    backgroundColor: '#F5F5F5'
  },

  itemLabel: {
    paddingLeft: '16px'
  },

  heading: {
    paddingLeft: '16px'
  },
  headingExpanded: {
    color: theme.palette.primary.main,
    paddingLeft: '36px'
  },
  iconExpanded: {
    color: theme.palette.primary.main
  },
  customAccordionSummary: {
    marginTop: '4px',
    marginBottom: '4px'
  },
  accordionSummary: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },

  root: {
    '& .MuiList-root': {
      width: '-webkit-fill-available'
    },
    '&.MuiAccordionDetails-root': {
      padding: '0px'
    }
  },

  accordion: {
    boxShadow: 'none',

    '&::before': {
      backgroundColor: 'transparent'
    }
  },

  listSubheader: {
    fontSize: '12px'
  },

  bottomList: {
    position: 'absolute',
    bottom: '0px',
    width: '100%'
  },

  paper: {
    overflowY: 'unset',
    width: '284px'
  }
}))
