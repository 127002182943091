import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

export const CustomRouterLink = (props) => {
  const { to, label } = props
  const classes = useStyles()
  return (
    <Link to={to} className={classes.customLink}>
      <Typography variant="body2">{label}</Typography>
    </Link>
  )
}

CustomRouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

CustomRouterLink.defaultProps = {}
