import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const GeneralContext = createContext()

function GeneralProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [productSelected, setProduct] = useState({})
  const [updated, setUpdated] = useState(false)
  const [allPractice, setAllPractice] = useState()
  const [selectedSimulado, setSelectedSimulado] = useState()
  const [selectedQuestion, setSelectedQuestion] = useState([])
  const [view, setView] = useState()
  const [newQuestion, setNewQuestion] = useState(null)
  const [selectedQuestionId, setSelectedQuestionId] = useState([])
  const [questions, setQuestions] = useState([])
  const [deletedQuestion, setDeletedQuestion] = useState(null)
  const [viewSimulated, setViewSimulated] = useState()
  const [viewEditQuestion, setViewEditQuestion] = useState()
  const [userData, setUserData] = useState()

  return (
    <GeneralContext.Provider
      value={{
        loading,
        setLoading,

        successMessage,
        setSuccessMessage,

        errorMessage,
        setErrorMessage,

        infoMessage,
        setInfoMessage,

        productSelected,
        setProduct,

        updated,
        setUpdated,

        allPractice,
        setAllPractice,

        selectedSimulado,
        setSelectedSimulado,

        selectedQuestion,
        setSelectedQuestion,

        view,
        setView,

        newQuestion,
        setNewQuestion,

        selectedQuestionId,
        setSelectedQuestionId,

        questions,
        setQuestions,

        deletedQuestion,
        setDeletedQuestion,

        viewSimulated,
        setViewSimulated,

        viewEditQuestion,
        setViewEditQuestion,

        userData,
        setUserData
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

GeneralProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { GeneralContext, GeneralProvider }
