import React from 'react'
import PropTypes from 'prop-types'
import { Table } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import { COURSE_SERVICES_FOR_DISCOUNTS_FIELDS } from '../../../../domain/courses-services/course-services-for-discount-fields'

const {
  NAME,
  SKU,
  FROM_VALUE,
  TO_VALUE,
  PERCENT_DISCOUNT,
  START_DATE,
  END_DATE,
  NOTE,
  IS_VALID
} = COURSE_SERVICES_FOR_DISCOUNTS_FIELDS

const headers = [
  'Nome',
  'SKU',
  'De',
  'Por',
  '% desconto',
  'Inicio',
  'Fim',
  'Observação'
]

export const ImportedDiscountsTable = (props) => {
  const { data } = props
  const classes = useStyles()
  return (
    <div className={classes.marginTopMedium}>
      <Table cells={headers} rows={data} />
    </div>
  )
}

ImportedDiscountsTable.propTypes = {
  data: PropTypes.shape({
    [NAME]: PropTypes.string,
    [SKU]: PropTypes.string,
    [FROM_VALUE]: PropTypes.string,
    [TO_VALUE]: PropTypes.string,
    [PERCENT_DISCOUNT]: PropTypes.string,
    [START_DATE]: PropTypes.string,
    [END_DATE]: PropTypes.string,
    [NOTE]: PropTypes.string,
    [IS_VALID]: PropTypes.string
  }).isRequired
}

ImportedDiscountsTable.defaultProps = {}
