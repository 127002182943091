import { makeStyles } from '@material-ui/core/styles'
import { spacing, textClasses } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...textClasses(theme),
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  contentItem: {
    flex: 1
  }
}))

export default useStyles
