import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  backButton: {
    marginLeft: 72
  },
  productTag: {
    backgroundColor: 'rgba(98, 76, 146, 1)',
    color: '#fff',
    padding: '2px 5px 3px 5px',
    borderRadius: 4,
    width: 'fit-content',
    fontSize: 14,
    fontWeight: 400,
    font: 'roboto',
    marginLeft: 8
  },

  filterContainer: {
    marginBottom: 32
  }
}))

export default useStyles
