/* eslint-disable radix */
import React, { useState, useContext } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import _ from 'lodash'

import { useParams } from 'react-router-dom'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import FormComplementaryMaterial from './FormComplementaryMaterial'
import { CARD_COMPLEMENTARY_MATERIAL_FIELDS } from '../../../../../domain/complementary-material/card-complementary-material-fields'
import { complementaryMaterialValidation } from '../../../../../domain/complementary-material/complementary-materials-validation'
import {
  createComplementaryMaterial,
  updateComplementaryMaterial
} from '../../../../../services/complementary-material/use-cases/post-complementary-material'

const { NAME, FULL_DESCRIPTION, SHORT_DESCRIPTION, ORDER } =
  CARD_COMPLEMENTARY_MATERIAL_FIELDS

const emptyFormState = {
  [NAME]: '',
  [FULL_DESCRIPTION]: '',
  [SHORT_DESCRIPTION]: '',
  [ORDER]: null
}

/**
 *
 * @param {{
 *  isOpen: boolean;
 *  handleClose: () => void;
 *  handleOpen: () => void;
 *  mode: 'create' | 'edit';
 *  materialData: {
 *    id: number;
 *    name: string;
 *    shortDescription: string;
 *    fullDescription: string;
 *    order: number;
 *  } | null
 * }} props
 * @returns {import('react').FC}
 */
export const DialogComplementaryMaterialManagement = ({
  isOpen,
  handleClose,
  handleOpen,
  mode = 'create',
  materialData
}) => {
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const initialFormState = materialData || emptyFormState
  const { id } = useParams()
  const [formState, setFormState] = useState(initialFormState)
  const [formErrors, setFormErrors] = useState(null)
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false)

  const openDiscardChangesDialog = () => setIsDiscardChangesDialogOpen(true)
  const closeDiscardChangesDialog = () => setIsDiscardChangesDialogOpen(false)

  const formStateHasChanged = !_.isEqual(initialFormState, formState)

  const canSaveChanges = mode === 'create' || formStateHasChanged

  const onChangeFormState = (field, value) => {
    const newFormState = { ...formState }
    newFormState[field] = value
    setFormState(newFormState)
  }

  const clearFormStateAndErrors = () => {
    setFormErrors(null)
    setFormState(initialFormState)
  }

  const validadeComplementaryMaterial = () => {
    const validationResult = complementaryMaterialValidation(formState)

    if (!validationResult.isValid) {
      setFormErrors(validationResult.errors)
      return false
    }

    return true
  }

  const clearStateAndCloseDialog = () => {
    clearFormStateAndErrors()
    handleClose()
  }

  const createMaterial = async () => {
    try {
      await createComplementaryMaterial({
        ...formState,
        courseId: (id && parseInt(id)) || null
      })
      setSuccessMessage('Material complementar adicionado com sucesso.')
    } catch (error) {
      setErrorMessage('Erro ao adicionar material complementar.')
    }
  }

  const editMaterial = async () => {
    try {
      await updateComplementaryMaterial(formState)
      setSuccessMessage('Material complementar alterado com sucesso.')
    } catch (error) {
      setErrorMessage('Erro ao editar material complementar.')
    }
  }

  const handleConfirmChanges = () => {
    if (!canSaveChanges) return

    const isValid = validadeComplementaryMaterial()

    if (!isValid) return

    if (mode === 'create') {
      createMaterial()
    } else {
      editMaterial()
    }

    clearStateAndCloseDialog()
  }

  const handleCloseDialog = () => {
    if (formStateHasChanged) {
      handleClose()
      openDiscardChangesDialog()
      return
    }

    clearStateAndCloseDialog()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        title={mode === 'create' ? 'Adicionar material' : 'Alterar material'}
        size="lg"
        primaryButton="SALVAR"
        secondaryButton="CANCELAR"
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmChanges}
        isDisabled={!canSaveChanges}
      >
        <FormComplementaryMaterial
          formState={formState}
          formErrors={formErrors || {}}
          onChangeFormState={onChangeFormState}
        />
      </Dialog>

      <Dialog
        title="Descartar alterações"
        primaryButton="DESCARTAR"
        secondaryButton="CANCELAR"
        open={isDiscardChangesDialogOpen}
        handleConfirm={() => {
          clearFormStateAndErrors()
          closeDiscardChangesDialog()
        }}
        handleClose={() => {
          closeDiscardChangesDialog()
          handleOpen()
        }}
      >
        <Typography>
          Você está saindo sem salvar as alterações feitas e perderá todo o seu
          progresso.
        </Typography>
      </Dialog>
    </>
  )
}

DialogComplementaryMaterialManagement.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']),
  materialData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
}

DialogComplementaryMaterialManagement.defaultProps = {
  mode: 'create',
  materialData: null
}
