import React, { useContext, useEffect } from 'react'

import { Snackbar } from '@equipe-ninja/saraiva-ui'
import { Box, Typography } from '@material-ui/core'
import { snackbarStyle } from '../../layouts/styles'

import { GeneralContext } from '../../contexts/GeneralContext'
import { ContentsContext } from '../../contexts/ContentsContext'
import { UploadProvider } from '../../contexts/UploadContext'

import useStyles from './styles'
import ButtonNew from './ButtonNew'
import ContentCard from './ContentCard'

function ContentManager() {
  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setInfoMessage,
    infoMessage,
    setErrorMessage
  } = useContext(GeneralContext)
  const { fetchContents, setContents } = useContext(ContentsContext)
  const snackbarStyles = snackbarStyle()

  const classes = useStyles()

  useEffect(() => {
    fetchContents()
    // eslint-disable-next-line
  }, [setContents])

  return (
    <>
      <Snackbar
        classes={() => snackbarStyles}
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        classes={() => snackbarStyles}
        severity="error"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
      >
        {errorMessage}
      </Snackbar>

      <Snackbar
        classes={() => snackbarStyles}
        severity="info"
        show={!!infoMessage}
        handleClose={() => setInfoMessage('')}
      >
        {infoMessage}
      </Snackbar>

      <Typography variant="h1" className={classes.title}>
        Gerenciar Conteúdo
      </Typography>
      <Typography variant="h4" className={classes.subtitle}>
        Arquivos Saraiva
      </Typography>
      <Box className={classes.line} />

      <UploadProvider>
        <ButtonNew />
      </UploadProvider>

      <ContentCard />
    </>
  )
}

export default ContentManager
