import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { ScheduledContentAdapter } from '../../adapters/scheduled-content'
import { SCHEDULED_CONTENT_REMOTE_FIELDS as REMOTE_FIELDS } from '../../remote-fields/scheduled-content'

const updateScheduledContent = async (formValues) => {
  const bodyData = ScheduledContentAdapter.outbound(formValues)

  const path = `/contents/api/v1/scheduled_content?id=${
    formValues[REMOTE_FIELDS.ID]
  }`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.put(path, bodyData, headers)
  const responseData = await response.json()
  return ScheduledContentAdapter.inbound(responseData)
}

export { updateScheduledContent }
