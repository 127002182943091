import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  breadcrumbs: {
    marginTop: 3,
    marginLeft: 36
  }
})

export default useStyles
