import {
  Dialog,
  TextInput,
  SelectSaraiva,
  MenuItem
} from '@equipe-ninja/saraiva-ui'
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { DialogContentText, Typography } from '@material-ui/core'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import useStyles from '../../styles'
import { COURSE_CREATE_FIELDS } from '../../../../domain/courses/course-create-fields'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import {
  courseCreateFirstValidation,
  courseCreateFirstFieldValidation
} from '../../../../domain/courses/business'
import { createCourses } from '../../../../services/courses'
import { CategorySelector } from '../../../../components/LFG/CategorySelector'

const { COURSE_NAME, TYPE, CATEGORY, SUB_CATEGORY, SKU } = COURSE_CREATE_FIELDS

export function ModalNewCourses({ open, onClose, onCreateCourse }) {
  const classes = useStyles()
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const [formState, setFormState] = useState({
    [CATEGORY]: '',
    [COURSE_NAME]: '',
    [SKU]: '',
    [SUB_CATEGORY]: '',
    [TYPE]: ''
  })

  const [formErrors, setFormErrors] = useState({
    [CATEGORY]: false,
    [COURSE_NAME]: false,
    [SKU]: false,
    [SUB_CATEGORY]: false,
    [TYPE]: false
  })

  const onChangeHandler = (field, dateValue) => {
    const validationError = courseCreateFirstFieldValidation(
      field,
      dateValue,
      formState
    )
    setFormState((prev) => ({ ...prev, [field]: dateValue }))
    setFormErrors((prev) => ({ ...prev, ...validationError }))
  }

  const resetForm = () => {
    setFormState({
      [COURSE_NAME]: '',
      [TYPE]: '',
      [CATEGORY]: '',
      [SUB_CATEGORY]: '',
      [SKU]: ''
    })
  }

  const onCloseDialog = () => {
    resetForm()
    onClose()
  }

  const saveNewCourse = () => {
    createCourses(formState)
      .then((courseId) => {
        setSuccessMessage(
          `Novo curso ${formState[TYPE]} cadastrado com sucesso`
        )
        onCreateCourse(courseId)
      })
      .catch(() => {
        setErrorMessage(
          `Não foi possível cadastrar o curso ${formState[COURSE_NAME]}`
        )
      })
  }

  const hasFormValidation = () => {
    const { isValid, errors } = courseCreateFirstValidation(formState)

    if (!isValid) {
      setFormErrors((prev) => ({ ...prev, ...errors }))
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }

    saveNewCourse()
  }

  return (
    <Dialog
      open={open}
      title="Cadastrar curso"
      secondaryButton="cancelar"
      primaryButton="cadastrar curso"
      handleClose={onCloseDialog}
      handleConfirm={hasFormValidation}
      size="lg"
      fullWidth
    >
      <ContainerWithLoader isLoading={false}>
        <Typography variant="h6" className={classes.titleH6}>
          Nome
        </Typography>
        <DialogContentText className={classes.textDialog}>
          Qual será o nome que aparecerá para o curso tanto no E-commerce,
          quanto no Ambiente de aprendizagem? Esta informação pode ser alterada
          a qualquer momento.
        </DialogContentText>
        <TextInput
          color="primary"
          size="full"
          label="Nome do curso"
          onChange={(e) => {
            onChangeHandler(COURSE_NAME, e.target.value)
          }}
          error={!!formErrors[COURSE_NAME]}
          helperText={formErrors[COURSE_NAME]}
          required
          fullWidth
          value={formState[COURSE_NAME]}
        />

        <Typography variant="h6" className={classes.titleH6}>
          SKU
        </Typography>
        <DialogContentText className={classes.textDialog}>
          Certifique-se que o SKU está correto e validado. Esta informação{' '}
          <strong>não</strong> poderá mais ser alterada.
        </DialogContentText>
        <TextInput
          color="primary"
          size="full"
          label="SKU do curso"
          onChange={(e) => {
            onChangeHandler(SKU, e.target.value)
          }}
          error={!!formErrors[SKU]}
          helperText={formErrors[SKU]}
          required
          fullWidth
          value={formState[SKU]}
        />

        <Typography variant="h6" className={classes.titleH6}>
          Tipo
        </Typography>
        <DialogContentText className={classes.textDialog}>
          Determine qual tipo de curso este será. Curso simples são os cursos
          convencionais, compostos de disciplinas novas ou reaproveitadas.
          Cursos combo são 2 ou mais cursos simples vendidos em conjunto. Esta
          informação não poderá ser alterada.
        </DialogContentText>
        <SelectSaraiva
          error={!!formErrors[TYPE]}
          helperText={formErrors[TYPE]}
          value={formState[TYPE]}
          onChange={(e) => {
            onChangeHandler(TYPE, e.target.value)
          }}
          label="Tipo de curso*"
          required
          size="full"
        >
          <MenuItem value="">Nenhum tipo selecionado</MenuItem>
          <MenuItem value="simples">Simples</MenuItem>
          <MenuItem value="combo">Combo</MenuItem>
        </SelectSaraiva>

        <Typography variant="h6" className={classes.titleH6}>
          Categoria
        </Typography>
        <DialogContentText className={classes.textDialog}>
          Insira a categoria e, caso aplicável, a subcategória deste curso. Esta
          informação pode ser alterada a qualquer momento.
        </DialogContentText>

        <CategorySelector
          category={formState[CATEGORY]}
          categoryError={formErrors[CATEGORY]}
          categoryField={CATEGORY}
          subcategory={formState[SUB_CATEGORY]}
          subcategoryError={formErrors[SUB_CATEGORY]}
          subcategoryField={SUB_CATEGORY}
          onChange={(event, field) => {
            onChangeHandler(field, event.target.value)
          }}
        />
      </ContainerWithLoader>
    </Dialog>
  )
}

ModalNewCourses.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateCourse: PropTypes.func.isRequired
}
