import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    color: theme.palette.text.secondary,
    marginTop: 20,
    marginBottom: 16
  },

  label: {
    flex: 'none',
    color: theme.palette.text.secondary,
    fontSize: 14,
    width: 247
  },

  value: {
    color: theme.palette.text.secondary,
    fontSize: 14
  },

  helpIcon: {
    marginTop: -4
  }
}))

export default useStyles
