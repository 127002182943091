import { REGISTER_ELEARNING_FIELDS as RE } from './register-elearning-fields'

export const REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS = Object.freeze({
  KEY: { value: RE.KEY.value, label: RE.KEY.label },
  SUBJECT: { value: RE.SUBJECT.value, label: RE.SUBJECT.label },
  ADDED_IN: { value: RE.ADDED_IN.value, label: RE.ADDED_IN.label },
  SKU: { value: RE.SKU.value, label: RE.SKU.label },
  UPDATED_AT: { value: RE.UPDATED_AT.value, label: RE.UPDATED_AT.label },
  IS_AVAILABLE: { value: RE.IS_AVAILABLE.value, label: RE.IS_AVAILABLE.label },
  FULL_POSTED: { value: RE.FULL_POSTED.value, label: RE.FULL_POSTED.label },
  TYPE: { value: RE.TYPE.value, label: RE.TYPE.label },
  HIERARCHY: { value: RE.HIERARCHY.value, label: RE.HIERARCHY.label },
  CHILDREN: { value: RE.CHILDREN.value, label: RE.CHILDREN.label },
  PARENT_ID: { value: RE.CHILDREN.value, label: RE.PARENT_ID.label },
  RELATIONSHIP_ID: { value: RE.RELATION_ID.value, label: RE.RELATION_ID.label }
})
