import React, { useContext } from 'react'
import { Button } from '@equipe-ninja/saraiva-ui'
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'

export default function SelectSubjectsButton() {
  const classes = useStyles()

  const { connection } = useContext(ConnectionContext)
  const { productSelected } = useContext(GeneralContext)

  const history = useHistory()

  return (
    <>
      <Button
        color="primary"
        onClick={() =>
          history.push({
            pathname: '/ies',
            state: { ies: connection, product: productSelected }
          })
        }
        text="Botão"
        variant="outlined"
        className={
          productSelected.value === 'bds'
            ? classes.hiddenButton
            : classes.button
        }
        startIcon={<SchoolRoundedIcon />}
      >
        CONTEÚDO DA INSTITUIÇÃO
      </Button>
    </>
  )
}
