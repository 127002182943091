/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { generatePath } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Table, SearchBar } from '@equipe-ninja/saraiva-ui'
import cls from 'classnames'
import { debounce } from 'lodash'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { DISCOUNT_CAMPAIGN_FIELDS } from '../../../../domain/promotional-content/discounts-campaign'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import { getDiscountList } from '../../../../services/promotional-content/campaign-discounts'
import { getCoursesForDiscounts } from '../../../../services/courses'
import { useDownloadFile } from '../../../../hooks/use-download-file'
import { CreateDiscountCampaignDialog } from '../CreateDiscountCampaignDialog'
import useStyles from './styles'

const { CAMPAIGN_NAME, CAMPAIGN_STATUS, ID } = DISCOUNT_CAMPAIGN_FIELDS

export const DiscountCampaignList = () => {
  const { downloadFile } = useDownloadFile()
  const [searchTerm, setSearchTerm] = useState('')
  const [tableData, setTableData] = useState({
    campaigns: [],
    isFetching: false
  })
  const [isFetching, setIsFetching] = useState(false)
  const classes = useStyles()
  const { setErrorMessage } = useContext(GeneralContext)
  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const headersCells = () => {
    return [
      { name: 'Nome da campanha', order: true, code: CAMPAIGN_NAME },
      { name: 'Status da campanha', order: true, code: CAMPAIGN_STATUS }
    ]
  }

  const anotateListItemWithRedirect = useCallback(
    (list) => {
      return list.map((item) => ({
        campaignName: item.campaignName,
        campaignStatus: item.campaignStatus,
        redirectTo: {
          fromColumn: CAMPAIGN_NAME,
          path: generatePath(RoutesMapping.promotionalDiscountDetails, {
            brand,
            id: item[ID]
          })
        }
      }))
    },
    [brand]
  )

  const getData = useCallback(
    (term) => {
      setTableData((prev) => ({ ...prev, isFetching: true }))
      getDiscountList(term)
        .then((list) => {
          setTableData((prev) => ({
            ...prev,
            campaigns: anotateListItemWithRedirect(list)
          }))
        })
        .catch(() => {
          setErrorMessage('Não foi possível buscar a lista de descontos')
        })
        .finally(() => setTableData((prev) => ({ ...prev, isFetching: false })))
    },
    [anotateListItemWithRedirect, setErrorMessage]
  )

  const debouncedSearch = useRef(debounce((term) => getData(term), 800)).current

  const handleSearchValue = (event) => {
    const newValue = event?.target?.value
    setSearchTerm(newValue)
    debouncedSearch(newValue)
  }

  const clearSearch = () => {
    setSearchTerm('')
    getData()
  }

  const downloadProductsCSV = () => {
    if (isFetching) return

    setIsFetching(true)
    getCoursesForDiscounts()
      .then((csv) => downloadFile(csv, 'matriz-cursos.csv', 'csv'))
      .finally(() => setIsFetching(false))
  }

  const header = headersCells()

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <ContentWithHeader
      title="Gerenciar descontos"
      titleSize="medium"
      backButtonRoute={generatePath(RoutesMapping.promotionalPage, {
        brand
      })}
    >
      <CreateDiscountCampaignDialog
        successHandler={() => getData(searchTerm)}
      />

      <div>
        <Link
          className={cls(
            classes.linkBtn,
            classes.marginTopSmall,
            isFetching && classes.waitingLnk
          )}
          component="button"
          onClick={() => downloadProductsCSV()}
        >
          Baixe o template da matriz CSV
        </Link>
      </div>

      <Divider className={classes.divider} />

      <Typography className={classes.subsectionTitle} variant="h5">
        Descontos
      </Typography>

      <div className={classes.actionsSection}>
        <div style={{ width: '416px' }}>
          <SearchBar
            value={searchTerm}
            onChange={handleSearchValue}
            placeholder="Buscar por campanha"
            size="full"
            clearSearch={clearSearch}
            goBackSearch={clearSearch}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          />
        </div>
      </div>

      <ContainerWithLoader isLoading={tableData.isFetching}>
        <div className={classes.marginTopMedium}>
          <Table cells={header} rows={tableData.campaigns} />
        </div>
      </ContainerWithLoader>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}

DiscountCampaignList.propTypes = {}

DiscountCampaignList.defaultProps = {}
