export const BANNER_REMOTE_FIELDS = {
  KEY: 'id',
  TYPE: 'type',
  TITLE: 'descricao',
  SESSION: 'page_id',
  URL: 'targenting_url',
  URL_2: 'targenting_url_2',
  URL_3: 'targenting_url_3',
  URL_4: 'targenting_url_4',
  END_DATE: 'final_date',
  START_DATE: 'initial_date',
  STATUS: 'status',
  ACTIVE: 'active',
  PAGE_ID: 'page_id',
  ORDER: 'display_order',
  ORDER_2: 'display_order_2',
  ORDER_3: 'display_order_3',
  ORDER_4: 'display_order_4',
  IMAGE_WEB: 'file_url_web',
  IMAGE_RECURSIVE: 'file_url_recursive',
  IMAGE_RECURSIVE_2: 'file_url_recursive_2',
  IMAGE_RECURSIVE_3: 'file_url_recursive_3',
  IMAGE_RECURSIVE_4: 'file_url_recursive_4'
}
