import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

/**
 * Verifica se um parametro esta no parametros da rotas e redireciona caso nao esteja ou se
 * a funcao verifyAccess retornar falso
 *
 * @param {*} paramName - nome do parametro que sera checado se existe
 * @param {*} redirectUrl - url para redirecionamento caso o parametro não esteja presente
 * @param {*} verifyAccess - funcao que sera chamada para validar se pode acessar a pagina
 *
 * @returns valor do parametro
 *
 * ### Exemplo
 *
 * ```js
 * const veryfyAccess = (brand) => ['lfg', 'bds'].includes(brand)
 * const { brand } = useRequiredParamsAccess('brand', '/entry', verifyAccess)
 * ```
 */
export const useRequiredParamsAccess = (
  paramName,
  redirectUrl,
  verifyAccess = () => true
) => {
  const paramList = useParams()
  const [requiredParam] = useState(paramList?.[paramName] || null)
  const history = useHistory()

  useEffect(() => {
    if (requiredParam === null) history.replace(redirectUrl)
    if (!verifyAccess(requiredParam)) history.replace(redirectUrl)
  }, [requiredParam, redirectUrl, history, verifyAccess])

  return { [paramName]: requiredParam }
}
