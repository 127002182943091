export class ImageDimension {
  /**
   * Valida se a imagem recebida possui exatamente as dimensões informadas
   * @param {*} imageToValidate
   * @param {*} dimension - dimensão no formato do objeto
   * @returns promise que se reolverar para true se as dimensoes da imagem sao iguais ao valor fornecidos
   *
   * ### Exemplo
   *
   * ```js
   * const dimensions = {width: 100, height: 50}
   * const img = getFile() //imagem de 100x50px
   * ImageDimensions.imageSizeValidator(img, dimensions)
   *  .then((isValid) => console.debug(isValid)) // true
   * ```
   */
  static imageSizeValidator = async (imageToValidate, dimension) => {
    const { width, height } = dimension
    const imageDimension = await this.getImageDimensionsFromFile(
      imageToValidate
    )
    if (imageDimension.width !== width || imageDimension.height !== height) {
      return false
    }
    return true
  }

  static getImageDimensionsFromFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()

      reader.onload = () => {
        const img = new Image()
        img.src = reader.result

        img.onload = () =>
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight
          })
      }

      reader.readAsDataURL(file)
    })
  }
}
