import { Button } from '@equipe-ninja/saraiva-ui'
import React, { useState, useContext, useEffect } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormBannerRotary } from '../Form/FormBannerRotary'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { initialURLValueForBrand } from '../../../../domain/brands'
import { createBanner } from '../../../../services/promotional-content/banners-pages'
import useStyles from '../styles'
import { bannerRotationValidation } from '../../../../domain/promotional-content/banners/business/banners-ratativo-validation'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import ContentTitle from '../../../../components/ContentTitle'

const {
  KEY,
  TITLE,
  URL,
  SESSION,
  ORDER,
  IMG_WEB,
  IMG_RECURSIVE,
  START_DATE,
  END_DATE,
  STATUS
} = FIELDS

export function DuplicarBanner({ state }) {
  const classes = useStyles()
  const history = useHistory()
  const [title, setTitle] = useState()
  const [isExecutingTask, setIsExecutingTask] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const [formState, setFormState] = useState({
    type: 'rotary',
    [KEY.value]: null,
    [TITLE.value]: '',
    [URL[0].value]: initialURLValueForBrand(brand),
    [SESSION.value]: 2,
    [ORDER[0].value]: '',
    [IMG_WEB.value]: '',
    [IMG_RECURSIVE[0].value]: '',
    [END_DATE.value]: null,
    [START_DATE.value]: null,
    [STATUS.value]: ''
  })

  const [formErrors, setFormErrors] = useState({
    [KEY.value]: false,
    [TITLE.value]: false,
    [URL[0].value]: false,
    [SESSION.value]: false,
    [ORDER[0].value]: false,
    [IMG_WEB.value]: false,
    [IMG_RECURSIVE[0].value]: false,
    [START_DATE.value]: false,
    [END_DATE.value]: false,
    [STATUS.value]: false
  })

  const onChangeHandler = (field, dateValue) => {
    setFormState((prevState) => {
      return { ...prevState, [field]: dateValue }
    })
  }

  const create = () => {
    createBanner(formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage(
          `Banner '${response[TITLE.value]}' criado com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const hasFormValidation = () => {
    const { isValid, errors } = bannerRotationValidation(formState)

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }
    create()
  }

  const getHandle = () => {
    // eslint-disable-next-line prefer-template
    if (title === undefined) {
      return ''
    }
    // eslint-disable-next-line prefer-template
    return '"' + title + '"'
  }

  useEffect(() => {
    if (state) {
      setFormState(state)
    }
  }, [state])

  return (
    <>
      <ContentTitle title={getHandle()} size="small" />
      <FormBannerRotary
        formState={formState}
        formErrors={formErrors}
        onChange={onChangeHandler}
        onTitle={setTitle}
      />
      <div className={classes.footerUpdate} style={{ marginBottom: 178 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={hasFormValidation}
          disabled={isExecutingTask}
        >
          SALVAR
        </Button>
      </div>
      <SnackbarOutlet />
    </>
  )
}

DuplicarBanner.propTypes = {
  state: PropTypes.objectOf.isRequired
}
