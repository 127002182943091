import {
  USER_ORDER_REMOTE_FIELDS as R_ORDER,
  USER_ORDER_ITEMS_REMOTE_FIELDS as R_ITEM
} from '../remote-fields/user-order-remote-fields'
import {
  USER_ORDER_FIELDS as ORDER,
  USER_ORDER_ITEMS_FIELDS as ITEM
} from '../../../domain/user-management-content/user-orders'
import { OrderItemCompletionStatusAdapter } from './order-item-completion-adapters'
import { RemoteDateUtils } from '../../utils/remote-date-utils'

const orderItemInboundAdapter = (remoteItem) => {
  return {
    [ITEM.ITEM_ID]: remoteItem[R_ITEM.ITEM_ID],
    [ITEM.ITEM_DESCRIPTION]: remoteItem[R_ITEM.ITEM_DESCRIPTION],
    [ITEM.ITEM_VALUE]: remoteItem[R_ITEM.ITEM_VALUE],
    [ITEM.ITEM_COMPLETION_STATUS]: OrderItemCompletionStatusAdapter.inbound(
      remoteItem[R_ITEM.ITEM_COMPLETION_STATUS]
    ),
    [ITEM.ITEM_COVER_IMG]: remoteItem[R_ITEM.ITEM_COVER_IMG],
    [ITEM.ITEM_COURSE_ID]: remoteItem[R_ITEM.ITEM_COURSE_ID]
  }
}

const userSingleOrderInboundAdapter = (remoteOrder) => {
  return {
    [ORDER.KEY]: remoteOrder[R_ORDER.KEY],
    [ORDER.ORDER_DATE]: RemoteDateUtils.parseRemoteDate(
      remoteOrder[R_ORDER.ORDER_DATE]
    ),
    [ORDER.ORDER_NUMBER]: remoteOrder[R_ORDER.ORDER_NUMBER],
    [ORDER.ORDER_TOTAL_VALUE]: remoteOrder[R_ORDER.ORDER_TOTAL_VALUE],
    [ORDER.ORDER_ITEMS]: remoteOrder[R_ORDER.ORDER_ITEMS].map((e) =>
      orderItemInboundAdapter(e)
    )
  }
}

export const userOrdersInboundAdapter = (remoteData) => {
  return remoteData.map((e) => userSingleOrderInboundAdapter(e))
}
