import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar uma paginas promocional
 * @returns Promise com a resposta ou rejeita com erro
 */
const deleteBanner = (id) => {
  return new Promise((resolve, reject) => {
    const path = `markets/api/v1/banners/${id}`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpClient
      .delete(path, null, headers)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => reject(error))
  })
}

export { deleteBanner }
