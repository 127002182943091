const PRODUCT_COURSE_GENERAL_FIELDS = {
  ACCESS_WINDOW: 'accessWindow',
  CATEGORY: 'category',
  COURSE_LENGTH: 'courseLength',
  COURSE_TABS: 'courseTabs',
  DESCRIPTION: 'description',
  ID: 'id',
  IMG_BINARY: 'imgBinary',
  IMG_URL: 'imgUrl',
  KEY_WORDS: 'keyWords',
  NAME: 'name',
  SUB_CATEGORY: 'subCategory',
  URL: 'url',
  VALUE: 'value'
}

export const COURSE_TABS_FIELDS = {
  CONTENT: 'content',
  ID: 'id',
  ORDER: 'order',
  TITLE: 'title'
}

export const PRODUCT_COURSE_FIELDS = {
  ...PRODUCT_COURSE_GENERAL_FIELDS,
  UPDATED_AT: 'updatedAt'
}
