import { typeAdapter } from '../../utils/type-adapter'
import { COURSE_SERVICE_STATUS as LOCAL } from '../../../domain/courses/entities/course-ecommerce-status'
import { REMOTE_COURSE_SERVICE_STATUS as REMOTE } from '../remote-fields/courses-services-fields'

export const CourseServiceStatusAdapter = typeAdapter(
  LOCAL,
  REMOTE,
  'Service status',
  { inbound: LOCAL.DRAFT }
)
