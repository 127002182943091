import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListContents from '../molecules/listContents'
import ShowContent from '../organisms/showContent'
import ShowFolder from '../organisms/showFolder'
import contentsService from '../../services/contentsService'

function renderLtiDeepLinkingRequest(data) {
  return (
    <div>
      <ListContents
        deepLinkReturnUrl={data.deep_link_return_url}
        listItems={data.list_items}
        path={data.path}
      />
    </div>
  )
}

function renderLtiResourceLinkRequest(data) {
  return (
    <div>
      <ShowContent id={data.id} />
    </div>
  )
}

function renderFolder(id) {
  return (
    <div>
      <ShowFolder id={id} />
    </div>
  )
}

class LtiValidation extends Component {
  constructor(props) {
    super(props)

    const urlString = window.location.href
    const url = new URL(urlString)

    if (url.searchParams.get('id_token') !== '') {
      localStorage.setItem('@dons/token', url.searchParams.get('id_token'))
      localStorage.setItem('@dons/state', url.searchParams.get('state'))
      localStorage.removeItem('@dons/client_id')
      localStorage.removeItem('@dons/client_name')
    }

    if (url.searchParams.get('client_id')) {
      localStorage.setItem('@dons/client_id', url.searchParams.get('client_id'))
      localStorage.setItem('@dons/token', url.searchParams.get('token'))
      localStorage.setItem(
        '@dons/client_name',
        url.searchParams.get('client_name')
      )
    }

    this.state = {
      data: [],
      id: url.searchParams.get('id'),
      isJwt: !!localStorage.getItem('@dons/client_id')
    }
  }

  componentDidMount() {
    const { id, isJwt } = this.state
    console.log(isJwt)
    if (isJwt) {
      contentsService()
        .jwtValidation(id)
        .then((response) => {
          this.setState({ data: response.data })
        })
    } else {
      contentsService()
        .ltiValidation(id)
        .then((response) => {
          this.setState({ data: response.data })
        })
    }
  }

  render() {
    const { id, data } = this.state
    if (data.length === 0) {
      return <div />
    }

    localStorage.setItem('@dons/folder_id', data.id)

    if (data.lti_type === 'LtiDeepLinkingRequest') {
      return renderLtiDeepLinkingRequest(data)
    }
    if (data.lti_type === 'LtiResourceLinkRequest' && data.type === 'folder') {
      return renderFolder(id !== null ? id : data.id)
    }
    if (data.lti_type === 'LtiResourceLinkRequest') {
      return renderLtiResourceLinkRequest(data)
    }
    return <div>Erro</div>
  }
}

export default LtiValidation

LtiValidation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

LtiValidation.defaultProps = {
  match: PropTypes.shape
}
