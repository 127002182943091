import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  customLink: {
    height: '24px',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    },
    '&:link': {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  }
}))

export default useStyles
