import React, { useContext, useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Button } from '@equipe-ninja/saraiva-ui'
import { ExerciceCard } from '../DetailsCard'
import { ContainerWithLoader } from '../../../../../../components/ContainerWithLoader'
import {
  scheduledContentState,
  scheduledContentFormTypeValues
} from '../../../../../../atoms/scheduled-content'
import { getScheduledContentByType } from '../../../../../../services/courses'
import { GeneralContext } from '../../../../../../contexts/GeneralContext'
import { SCHEDULED_CONTENT_FIELDS } from '../../../../../../domain/courses'
import { ScheduledContentForm } from '../ScheduledContentForm'
import useStyles from './styles'

const { ID, SCHEDULED_CONTENT_TYPE, INITIAL_DATE } = SCHEDULED_CONTENT_FIELDS

export const ExercisesOab = () => {
  const [state, setState] = useRecoilState(scheduledContentState)
  const [showDialogId, setShowDialogId] = useState(0)
  const setFormTypeValues = useSetRecoilState(scheduledContentFormTypeValues)
  const { setErrorMessage } = useContext(GeneralContext)
  const classes = useStyles()
  const { id: courseId } = useParams()

  const fetchContentByType = useCallback(
    (contentType) => {
      setState((prev) => ({ ...prev, isFetching: true }))

      getScheduledContentByType(courseId, contentType)
        .then((data) => {
          setState((prev) => ({
            ...prev,
            data: data.sort((a, b) =>
              a[INITIAL_DATE] < b[INITIAL_DATE] ? -1 : 1
            )
          }))
        })
        .catch((error) => {
          setErrorMessage(error)
        })
        .finally(() => {
          setState((prev) => ({ ...prev, isFetching: false }))
        })
    },
    [setState, setErrorMessage, courseId]
  )

  const setSelectedTypeOnForm = () => {
    setFormTypeValues((prev) => ({
      ...prev,
      [SCHEDULED_CONTENT_TYPE]: state.type
    }))
  }

  const setDataOnForm = (targetId) => {
    const data = state.data.find((item) => item[ID] === targetId)

    setState((prev) => ({ ...prev, form: { ...prev.form, values: data } }))
  }

  const showDialog = () => {
    setShowDialogId(Date.now())
  }

  const createNewExercise = () => {
    setSelectedTypeOnForm()
    showDialog()
  }

  const editNewExercise = (editedId) => {
    setDataOnForm(editedId)
    showDialog()
  }

  useEffect(() => {
    fetchContentByType(state.type)
  }, [state.type, fetchContentByType])

  const scheduledContentDeletedCallback = (id) => {
    setState((prev) => ({
      ...prev,
      data: prev.data.filter((content) => content[ID] !== id)
    }))
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => createNewExercise()}
      >
        Adicionar exercício
      </Button>

      <ContainerWithLoader
        isLoading={state.isFetching}
        className={classes.marginTopMedium}
      >
        {state.data.map((item, i) => (
          <ExerciceCard
            data={item}
            key={item[ID]}
            onClick={() => editNewExercise(item[ID])}
            position={i + 1}
          />
        ))}
      </ContainerWithLoader>

      <ScheduledContentForm
        onDeleteCallback={scheduledContentDeletedCallback}
        onSaveCallback={() => fetchContentByType(state.type)}
        showId={showDialogId}
      />
    </>
  )
}

ExercisesOab.propTypes = {}

ExercisesOab.defaultProps = {}
