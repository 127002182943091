import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { deleteRequestResponseHandler } from '../../utils/response-handlers'

const deleteComplementaryContent = async (id) => {
  const path = `/contents/api/v1/complementary_content/${id}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)

  const response = await httpClient.delete(path, null, headers)
  const responseJson = await deleteRequestResponseHandler(response)

  return responseJson
}

export { deleteComplementaryContent }
