export const REMOTE_DISCOUNT_FIELDS = {
  ID: 'id',
  CAMPAIGN_NAME: 'name',
  CAMPAIGN_STATUS: 'active',
  CAMPAIGN_DATA: 'csv'
}

export const REMOTE_DISCOUNT_CAMPAIGN_FIELDS = {
  ID: 'id',
  SKU: 'service_id',
  START_DATE: 'start_at',
  END_DATE: 'end_at',
  PRICE_FROM: 'price_from',
  PRICE_TO: 'price',
  NAME: 'service_name',
  DISCOUNT: 'discount'
}
