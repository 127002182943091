import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  backButton: {
    marginLeft: 133,
    marginBottom: 40
  },

  content: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 133,
    marginRight: 131
  },
  addButton: {
    marginBottom: 16,
    padding: '0px 0px 0px 0px'
  },

  titleIes: {
    width: 322,
    height: 42,
    marginBottom: 24
  },
  teste: {
    backgroundColor: 'black'
  }
}))

export default useStyles
