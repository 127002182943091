import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../../utils/response-handlers'
import { listItemAdapterInbound } from './banner-adapters'

/**
 * Caso de uso para carregamento de lista de paginas de banners rotativos
 * @returns lista de paginas banner rotativo apos passar por um adaptador de entrada
 */
export const getBannerList = async () => {
  const path = 'markets/api/v1/banners'
  const headers = httpClient.buildHeader({ 'session-token': getSessionToken() })

  return new Promise((resolve, reject) => {
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((json) => {
        resolve(listItemAdapterInbound(json.data))
      })
      .catch((err) => reject(err))
  })
}
