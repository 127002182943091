export const SCHEDULED_CONTENT_REMOTE_FIELDS = Object.freeze({
  TEMPLATE: 'template',
  TEMPLATE_DATE: 'template_date',
  TEXT_ENUNCIATED: 'text_enunciated',
  FINAL_DATE: 'final_date',
  TYPE: 'type',
  ID: 'id',
  INITIAL_DATE: 'initial_date',
  FORM_LINK: 'form_link',
  SCHEDULED_CONTENT_TYPE: 'scheduled_content_type',
  TITLE: 'title',
  ACTIVE: 'active',
  COURSE_ID: 'course_id'
})

export const SCHEDULED_CONTENT_REMOTE_TYPES = {
  OAB_SECOND_PHASE: 1
}

export const SCHEDULED_CONTENT_REMOTE_EXERCISE_TYPE = {
  QUESTION_BLOCK: 'bloco_questoes',
  PECA_PROCESSUAL: 'peca_processual'
}
