/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { GeneralContext } from './GeneralContext'
import ContentConnection from '../services/contentConnection'
import Messages from '../helpers/messages'

const ContentsContext = createContext()

function ContentsProvider({ children }) {
  const { setErrorMessage, setLoading } = useContext(GeneralContext)
  const location = useLocation()

  const [contents, setContents] = useState([])
  const [contentsPath, setContentsPath] = useState([])
  const [showingCreateFolderForm, toggleCreateFolderForm] = useState(false)
  const [moduleParentId, setModuleParentId] = useState()
  const [folderDescription, setFolderDescription] = useState()
  const [folderName, setFolderName] = useState()
  const [showingContentMenu, toggleShowingContentMenu] = useState(false)
  const [previewContent, setpreviewContent] = useState([])
  const [contentByClient, setContentByClient] = useState([])

  const addContentOnState = (content) => {
    setLoading(true)
    contents.push(content)
    setLoading(false)
  }

  const removeContentOnState = (content) => {
    setContents(contents.filter((c) => c.id !== content.id))
  }

  const removeMultipleContent = (check) => {
    setContents(contents.filter((val) => !check.includes(val.id)))
  }

  function editNameContentOnState(content, newName) {
    contents.map((c) => (c.id === content.id ? (c.name = newName) : c))
  }

  async function fetchContents(id) {
    try {
      setLoading(true)
      const response = await ContentConnection.getContent(id)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setContents(result.list)
      setContentsPath(result.path)
      setModuleParentId(id)
      setLoading(false)
    } catch (err) {
      setErrorMessage(Messages.content.errorList)
      setLoading(false)
    }
  }

  async function fetchContentsByClientId(id) {
    try {
      setLoading(true)
      const params = {
        clientId: location.state.ies.client_id,
        appToken: location.state.product.token
      }
      const response = await ContentConnection.getContentByClientId(params)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setContentByClient(result.list)
      setContents(result.list)
      setContentsPath(result.path)
      setModuleParentId(id)
      setLoading(false)
    } catch (err) {
      setErrorMessage(Messages.content.errorList)
      setLoading(false)
      console.log(err)
    }
  }

  async function fetchPreview() {
    try {
      setLoading(true)

      const response = await ContentConnection.getPreview()
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()

      setpreviewContent(result)
      setLoading(false)
    } catch (err) {
      setErrorMessage(Messages.content.errorList)
      setLoading(false)
    }
  }

  return (
    <ContentsContext.Provider
      value={{
        fetchContents,
        fetchContentsByClientId,
        contentByClient,

        contents,
        setContents,

        contentsPath,
        setContentsPath,

        showingCreateFolderForm,
        toggleCreateFolderForm,

        addContentOnState,
        removeContentOnState,

        moduleParentId,
        setModuleParentId,

        folderName,
        setFolderName,

        folderDescription,
        setFolderDescription,

        showingContentMenu,
        toggleShowingContentMenu,

        editNameContentOnState,

        removeMultipleContent,
        
        fetchPreview,

        previewContent,
        setpreviewContent
      }}
    >
      {children}
    </ContentsContext.Provider>
  )
}

ContentsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { ContentsContext, ContentsProvider }
