import React from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'

import useStyles from './styles.js'

export default function Tabs({ activeTab }) {
  const classes = useStyles()
  const { libraryCode } = useParams()

  const renderLinkClassNames = (tab) => {
    let classNames = classes.tab

    if (tab === activeTab) {
      classNames += ` ${classes.tabActive}`
    }

    return classNames
  }

  return (
    <div className={classes.tabs}>
      <Link
        to={`/libraries/${libraryCode}`}
        className={renderLinkClassNames('library')}
      >
        DETALHES
      </Link>

      <Link
        to={`/libraries/${libraryCode}/tickets`}
        className={renderLinkClassNames('tickets')}
      >
        VOUCHER
      </Link>
    </div>
  )
}

Tabs.propTypes = {
  activeTab: PropTypes.oneOf(['library', 'tickets']).isRequired
}
