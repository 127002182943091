/**
 *
 * ### Example
 * ```js
 * formatPercent(0.3) # "30%"
 * ```
 *
 * @param decimal - decimal que receberá a notação
 */
export function formatPercent(decimal) {
  return `${decimal * 100}%`
}

/**
 * Transforma uma date para um formato humano
 *
 * ### Example
 * ```js
 * const dateObj = new Date()
 * formatDate(dateObj) //
 * ```
 *
 * @param {*} date - um objeto do tipo Date
 * @returns string que representa a Date em um formato humano
 */
export const formatDate = (date) => {
  return date ? date?.toLocaleDateString('pt-BR') : 'Não informado'
}

/**
 *
 * @param {number} decimalValue valor em decimal que sera expresso em formato de moeda
 * @param {boolean} showSymbol indica se deve ou não exibir o simbolo de moeda default: `true`
 * @returns valor convertido em moeda acompanhado do identificador da moeda
 *
 * ### Exemplo
 * ```js
 * formatCurrency(100) // "R$ 100,00"
 * formatCurrency(100, false) // "100,00"
 * ```
 */
export const formatCurrency = (decimalValue, showSymbol = true) => {
  if (decimalValue === null || decimalValue === undefined) {
    return 'Não informado'
  }

  if (!showSymbol)
    return decimalValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })

  return decimalValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}
