import { USER_MANAGEMENT_ORDER_DETAIL_FIELDS as LIST_FIELDS } from '../../../domain/user-management-content/user-management-page/user-management-order-detail-fields'
import { ORDER_DETAIL_FIELDS } from '../remote-fields/order-detail-fields'
import { formatDate, formatDateHour } from '../../../helpers/formatDate'
import { formatCPF } from '../../../helpers/formatCPF'

export const orderDetailAdapterInbound = (remoteData) => {
  return {
    [LIST_FIELDS.KEY.value]: remoteData[ORDER_DETAIL_FIELDS.KEY],
    [LIST_FIELDS.ACQUISITION_DATE.value]: formatDateHour(
      remoteData[ORDER_DETAIL_FIELDS.ACQUISITION_DATE]
    ),
    [LIST_FIELDS.ORDER_STATUS.value]:
      remoteData[ORDER_DETAIL_FIELDS.ORDER_STATUS],
    [LIST_FIELDS.ORDER_PRICE.value]:
      remoteData[ORDER_DETAIL_FIELDS.ORDER_PRICE],
    [LIST_FIELDS.PAYMENT_METHOD.value]:
      remoteData[ORDER_DETAIL_FIELDS.PAYMENT_METHOD],
    [LIST_FIELDS.CARD_FLAG.value]: remoteData[ORDER_DETAIL_FIELDS.CARD_FLAG],
    [LIST_FIELDS.CARD_NAME.value]: remoteData[ORDER_DETAIL_FIELDS.CARD_NAME],
    [LIST_FIELDS.CODE.value]: remoteData[ORDER_DETAIL_FIELDS.CODE],
    [LIST_FIELDS.DUE_DATE.value]: formatDate(
      remoteData[ORDER_DETAIL_FIELDS.DUE_DATE]
    ),
    [LIST_FIELDS.BUYER_DOCUMENT.value]: formatCPF(
      remoteData[ORDER_DETAIL_FIELDS.BUYER_DOCUMENT]
    ),
    [LIST_FIELDS.NUMBER_INSTALLMENTS.value]:
      remoteData[ORDER_DETAIL_FIELDS.NUMBER_INSTALLMENTS],
    [LIST_FIELDS.BILLING_ADDRESS.value]:
      remoteData[ORDER_DETAIL_FIELDS.BILLING_ADDRESS],
    [LIST_FIELDS.VOUCHER.value]: remoteData[ORDER_DETAIL_FIELDS.VOUCHER],
    [LIST_FIELDS.COURSE_SUBTOTAL.value]:
      remoteData[ORDER_DETAIL_FIELDS.COURSE_SUBTOTAL],
    [LIST_FIELDS.ORDER_SUMMARY.value]:
      remoteData[ORDER_DETAIL_FIELDS.ORDER_SUMMARY]
  }
}
