import Joi from 'joi'
import { BANNER_PAGES_LIST_ITEM_FIELDS as F } from '../../promotional-pages/banner-pages-list-item-fields'
import {
  validateAndGenerateErrors,
  customAfterDateValidator,
  validateSingleFieldAndGenerateErrors
} from '../../../../helpers/validation'

const schemaRotary = Joi.object({
  type: Joi.string(),
  [F.TITLE.value]: Joi.string().required(),
  [F.URL[0].value]: Joi.string().required(),
  [F.SESSION.value]: Joi.number()
    .required()
    .messages({ 'number.required': 'Campo obrigatório' }),
  [F.ORDER[0].value]: Joi.number().required(),
  [F.IMG_WEB.value]: Joi.alternatives()
    .try(Joi.object(), Joi.string())
    .required(),
  [F.IMG_RECURSIVE[0].value]: Joi.alternatives()
    .try(Joi.object(), Joi.string())
    .required(),
  [F.STATUS.value]: Joi.string().required(),
  [F.START_DATE.value]: Joi.date()
    .when(Joi.ref('$action'), {
      is: 'create',
      then: Joi.date().min(Joi.ref('$today')).required(),
      otherwise: Joi.date().required()
    })
    .messages({
      'date.min': 'Não pode ser uma data retroativa'
    }),
  [F.END_DATE.value]: Joi.date()
    .when(Joi.ref('$action'), {
      is: 'create',
      then: Joi.date()
        .custom(customAfterDateValidator(F.START_DATE.value, F.END_DATE.value))
        .required(),
      otherwise: Joi.date().min(Joi.ref(F.START_DATE.value)).required()
    })
    .messages({
      'date.min': 'Deve ser posterior a data de início',
      'custom.afterDate': 'Deve ser posterior a data de início'
    })
})

const schemaSale = Joi.object({
  type: Joi.string(),
  [F.URL[0].value]: Joi.array().items(
    Joi.string().required(),
    Joi.string().required(),
    Joi.string().required(),
    Joi.string().required()
  ),
  [F.ORDER[0].value]: Joi.array().items(
    Joi.number().required(),
    Joi.number().required(),
    Joi.number().required(),
    Joi.number().required()
  ),
  [F.IMG_RECURSIVE[0].value]: Joi.array()
    .items(
      Joi.alternatives().try(Joi.object(), Joi.string()).required(),
      Joi.alternatives().try(Joi.object(), Joi.string()).required(),
      Joi.alternatives().try(Joi.object(), Joi.string()).required(),
      Joi.alternatives().try(Joi.object(), Joi.string()).required()
    )
    .messages({
      'array.items.base': 'Campo obrigatório'
    }),

  [F.STATUS.value]: Joi.string().required(),
  [F.START_DATE.value]: Joi.date()
    .when(Joi.ref('$action'), {
      is: 'create',
      then: Joi.date().min(Joi.ref('$today')).required(),
      otherwise: Joi.date().required()
    })
    .messages({
      'date.min': 'Não pode ser uma data retroativa'
    }),
  [F.END_DATE.value]: Joi.date()
    .when(Joi.ref('$action'), {
      is: 'create',
      then: Joi.date()
        .custom(customAfterDateValidator(F.START_DATE.value, F.END_DATE.value))
        .required(),
      otherwise: Joi.date().min(Joi.ref(F.START_DATE.value)).required()
    })
    .messages({
      'date.min': 'Deve ser posterior a data de início',
      'custom.afterDate': 'Deve ser posterior a data de início'
    })
})

const context = (action) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return { action, today }
}

/**
 *
 * @param {*} formValues
 * @param {"create" | "edit"} action
 * @returns
 */
export const bannerRotationValidation = (formValues, action = 'edit') => {
  const schema = formValues.type === 'rotary' ? schemaRotary : schemaSale
  return validateAndGenerateErrors(formValues, schema, context(action))
}

/**
 *
 * @param {*} field
 * @param {*} value
 * @param {*} formValues
 * @param {"create" | "edit"} action
 * @returns
 */
export const bannerRotationFieldValidation = (
  field,
  value,
  formValues,
  action = 'edit'
) => {
  const schema = formValues.type === 'rotary' ? schemaRotary : schemaSale

  return validateSingleFieldAndGenerateErrors(
    field,
    value,
    formValues,
    schema,
    context(action)
  )
}
