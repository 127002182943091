import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: 16,
    marginBottom: 40
  },
  line: {
    height: 1,
    backgroundColor: theme.palette.divider
  },
  addButton: {
    marginTop: 30
  },
  sectionContainer: {
    marginBottom: 8
  },
  icon: {
    marginRight: 24,
    verticalAlign: 'bottom',
    '&.MuiTableSortLabel-icon': {
      opacity: 1
    }
  },
  deleteSubject: {
    color: theme.palette.error.main
  },
  sectionTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 24,
    fontWeight: 'normal',
    marginBottom: 24
  },
  breadcrumbs: {
    marginTop: 20,
    marginLeft: 16
  },
  searchContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableContainer: {
    marginTop: 24
  },
  tableHeaders: {
    display: 'flex',
    [theme.breakpoints.up('laptop')]: {
      whiteSpace: 'nowrap'
    }
  },
  folder: {
    cursor: 'pointer'
  },
  folderText: {
    textDecoration: 'underline',
    color: 'rgba(36, 90, 147, 1)'
  },
  lastColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  registerButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 54
  },
  checkBox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#00000008'
    }
  },
  selectedRow: {
    backgroundColor: '#245a9314'
  }
}))

export default useStyles
