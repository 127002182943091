/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react'
import { Dialog, SearchBarFilter } from '@equipe-ninja/saraiva-ui'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  Typography,
  Divider,
  Box,
  Button
} from '@material-ui/core'

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import useStyles from './style'
import IntegrationsService from '../../services/integrationsService'
import { GeneralContext } from '../../contexts/GeneralContext'
import messages from '../../helpers/messages'
import ViewQuestion from '../ViewQuestion'

export function QuestionBank({
  showQuestionBankDialog,
  setShowQuestionBankDialog
}) {
  const {
    setSuccessMessage,
    setErrorMessage,
    setSelectedQuestion,
    allPractice
  } = useContext(GeneralContext)

  const handleCloseDialog = () => {
    setShowQuestionBankDialog(false)
  }

  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [checked, setChecked] = useState(false)
  const [pages, setPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rows, setRows] = useState([])
  const [saving, setSaving] = useState(false)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [showDialog, setShowDialog] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const filterOptions = [
    { label: 'Código', value: 'code' },
    { label: 'Área', value: 'knowledge_area' },
    { label: 'Disciplina', value: 'subject' },
    { label: 'Tema', value: 'theme' },
    { label: 'Objetivo', value: 'goal' },
    { label: 'Fonte', value: 'question_source' }
  ]

  const { setView } = useContext(GeneralContext)

  const handleInputChange = ({ filterTerm, filterName }) => {
    setSearch(filterTerm)
    setSelectedCategory(filterName)
  }

  const handleCheckAll = (event) => {
    const isChecked = event.target.checked
    setChecked(isChecked)

    const newSelected = isChecked ? rows.map((row) => row.id) : []
    setSelected(newSelected)
  }

  const checkQuestion = (event, id) => {
    let questionListUpdated = [...selected]
    if (event.target.checked) {
      questionListUpdated = [...selected, id]
    } else {
      questionListUpdated.splice(selected.indexOf(id), 1)
    }
    setSelected(questionListUpdated)
  }

  const handleChangePage = (event, newPage) => {
    setPages(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPages(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  useEffect(() => {
    const isAllSelected = rows && rows.every((row) => selected.includes(row.id))
    setChecked(isAllSelected)
  }, [selected, rows])

  const handleButtonClick = (row) => {
    setView(row)
    setShowDialog(true)
  }
  const handleDialogClose = () => {
    setShowDialog(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const pagination = pages === 0 ? 1 : pages === 1 ? 2 : pages + 1

      if (search) {
        try {
          const response = await IntegrationsService.searchFilter(
            pagination,
            rowsPerPage,
            search,
            selectedCategory
          )
          const jsonData = await response.json()
          setRows(jsonData.data)
        } catch (error) {
          console.error('Error while searching:', error)
        }
      } else {
        try {
          const response = await IntegrationsService.searchDataBaseQuestions(
            pagination,
            rowsPerPage,
            search,
            selectedCategory
          )

          if (!response.ok) {
            throw new Error(response.status)
          }

          const jsonData = await response.json()
          console.log('API Response:', jsonData)
          setRows(jsonData.data)
          setTotalQuestions(jsonData.total)
          setChecked(false)
        } catch (error) {
          console.error('Error fetching database questions:', error)
        }
      }
    }

    fetchData()
  }, [pages, rowsPerPage, search, selectedCategory])

  useEffect(() => {
    setPages(0)
  }, [])

  const save = async () => {
    setSaving(true)
    try {
      const selectedQuestionsData = rows.filter((row) =>
        selected.includes(row.id)
      )

      await Promise.all(
        selectedQuestionsData.map(async (row) => {
          setSelectedQuestion((prevSelectedQuestions) => [
            ...prevSelectedQuestions,
            row
          ])
          const payload = {
            database_question_id: row.id,
            practice_test_id: allPractice.id
          }
          const response = await IntegrationsService.createPracticeQuestion(
            payload
          )

          const jsonData = await response.json()
          console.log('jsonData', jsonData)

          setSelected([])
          setShowQuestionBankDialog(false)
          setSuccessMessage(messages.simulatedDialog.success)
        })
      )

      const simulatedResponse =
        await IntegrationsService.createPracticeSimulated(allPractice.id)

      const simulatedJsonData = await simulatedResponse.json()
    } catch (err) {
      setErrorMessage(messages.simulatedDialog.error)
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  useEffect(() => {
    if (!showQuestionBankDialog) {
      setSelected([])
      setPages(0)
      setRowsPerPage(5)
    }
  }, [showQuestionBankDialog])

  return (
    <div>
      <Dialog
        title="Adicionar questão"
        secondaryButton="CANCELAR"
        primaryButton="ADICIONAR QUESTÕES"
        size="lg"
        open={showQuestionBankDialog}
        handleConfirm={() => save()}
        handleClose={() => handleCloseDialog()}
        isDisabled={saving}
      >
        <div>
          <SearchBarFilter
            filterOptions={filterOptions}
            searchPlaceHolder="Buscar por código, área, disciplina, tema, objetivo ou fonte"
            handleSearchTerm={handleInputChange}
            defaultFilterOption="code"
          />
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Box>
              <Typography
                className={classes.text}
                variant="body1"
                color="primary"
              >
                QUESTÕES
              </Typography>
              <Divider />
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={checked}
                      onChange={handleCheckAll}
                    />
                  </TableCell>
                  <TableCell className={classes.headerCell}>Código</TableCell>
                  <TableCell className={classes.headerCell}>Área</TableCell>
                  <TableCell className={classes.headerCell}>
                    Disciplina
                  </TableCell>
                  <TableCell className={classes.headerCell}>Tema</TableCell>
                  <TableCell className={classes.headerCell}>Objetivo</TableCell>
                  <TableCell className={classes.headerCell}>Fonte</TableCell>
                  <TableCell className={classes.headerCell}>Formato</TableCell>
                  <TableCell className={classes.headerCell}>Ano</TableCell>
                  <TableCell className={classes.headerCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${row.id}`
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selected.includes(row.id)}
                          onChange={(event) => checkQuestion(event, row.id)}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell>{row.code}</TableCell>
                      <TableCell>{row.knowledge_area}</TableCell>
                      <TableCell>{row.subject}</TableCell>
                      <TableCell>{row.theme}</TableCell>
                      <TableCell>{row.goal}</TableCell>
                      <TableCell>{row.question_source}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.year}</TableCell>
                      <TableCell className={classes.maxWidthColumn}>
                        <Button
                          style={{ color: '#624C92 ' }}
                          onClick={() => handleButtonClick(row)}
                        >
                          <RemoveRedEyeIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalQuestions}
              rowsPerPage={rowsPerPage}
              page={pages}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {showDialog && (
              <ViewQuestion
                setShowDialog={handleDialogClose}
                showDialog={showDialog}
              />
            )}
          </TableContainer>
        </div>
      </Dialog>
    </div>
  )
}
