import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { ContainerWithLoader } from '../../../../../components/ContainerWithLoader'
import useStyles from './styles'
import { getOrderDetail } from '../../../../../services/user-management-content'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import { USER_MANAGEMENT_ORDER_DETAIL_FIELDS as F } from '../../../../../domain/user-management-content/user-management-page/user-management-order-detail-fields'

export function OrderDetails() {
  const classes = useStyles()
  const { orderId } = useParams()
  const { setErrorMessage } = useContext(GeneralContext)
  const [isFetching, setIsFetching] = useState(false)
  const [orderDetails, setOrderDetails] = useState({})
  const [billingAddress, setBillingAddress] = useState({})
  const [orderSummary, setOrderSummary] = useState([])
  const [voucherDetail, setVoucherDetail] = useState({})

  const handleCardPayment = (paymentMethod) => {
    if (paymentMethod === 'Cartão') {
      return (
        <div className={classes.CardContext}>
          <div className={classes.CardSubTitle}>{F.PAYMENT_METHOD.label}</div>
          <div className={classes.text}>
            {orderDetails[F.PAYMENT_METHOD.value]}
          </div>
          <div className={classes.CardSubTitle}>{F.CARD_FLAG.label}</div>
          <div className={classes.text}>
            {orderDetails[F.CARD_FLAG.value]} **** 4467
          </div>
          <div className={classes.CardSubTitle}>{F.CARD_NAME.label}</div>
          <div className={classes.text}>{orderDetails[F.CARD_NAME.value]}</div>
          <div className={classes.CardSubTitle}>
            {F.NUMBER_INSTALLMENTS.label}
          </div>
          <div className={classes.text}>
            {orderDetails[F.NUMBER_INSTALLMENTS.value]}x
          </div>
        </div>
      )
    }

    return (
      <div className={classes.CardContext}>
        <div className={classes.CardSubTitle}>{F.PAYMENT_METHOD.label}</div>
        <div className={classes.text}>
          {orderDetails[F.PAYMENT_METHOD.value]}
        </div>
        <div className={classes.CardSubTitle}>{F.CODE.label}</div>
        <div className={classes.text}>{orderDetails[F.CODE.value]}</div>
        <div className={classes.CardSubTitle}>{F.DUE_DATE.label}</div>
        <div className={classes.text}>{orderDetails[F.DUE_DATE.value]}</div>
        <div className={classes.CardSubTitle}>{F.BUYER_DOCUMENT.label}</div>
        <div className={classes.text}>
          {orderDetails[F.BUYER_DOCUMENT.value]}
        </div>
      </div>
    )
  }

  useEffect(() => {
    setIsFetching(true)
    getOrderDetail(orderId)
      .then((res) => {
        setOrderDetails(res)
        setBillingAddress(res[F.BILLING_ADDRESS.value])
        setOrderSummary(res[F.ORDER_SUMMARY.value])
        setVoucherDetail(res[F.VOUCHER.value])
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar a lista de usuários')
      })
      .finally(() => setIsFetching(false))
  }, [orderId, setErrorMessage])

  return (
    <ContainerWithLoader isLoading={isFetching}>
      <>
        <Typography
          variant="h5"
          className={`${classes.text} ${classes.textH5}`}
        >
          Pedido: {orderDetails[F.KEY.value]}
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.text} ${classes.textH6}`}
        >
          Pedido
        </Typography>
        <div className={classes.CardContext}>
          <div className={classes.CardSubTitle}>ID</div>
          <div className={classes.text}>{orderDetails[F.KEY.value]}</div>
          <div className={classes.CardSubTitle}>{F.ACQUISITION_DATE.label}</div>
          <div className={classes.text}>
            {orderDetails[F.ACQUISITION_DATE.value]}
          </div>
          <div className={classes.CardSubTitle}>{F.ORDER_STATUS.label} </div>
          <div className={classes.text}>
            {orderDetails[F.ORDER_STATUS.value]}
          </div>
        </div>

        <Typography
          variant="h6"
          className={`${classes.text} ${classes.textH6}`}
        >
          Pagamento
        </Typography>
        {handleCardPayment(orderDetails[F.PAYMENT_METHOD.value])}

        <div className={classes.CardContext}>
          <div className={classes.CardSubTitle}>{F.BILLING_ADDRESS.label}</div>
          <div className={classes.textAddress}>
            {billingAddress[F.STREET.value]}, n.{' '}
            {billingAddress[F.NUMBER.value]}
          </div>
          <div className={classes.textAddress}>
            {billingAddress[F.NEIGHBORHOOD.value]},{' '}
            {billingAddress[F.CITY.value]}, {billingAddress[F.STATE.value]},{' '}
            {billingAddress[F.COUNTRY.value]}
          </div>
          <div className={classes.textAddress}>
            {F.CEP.label}: {billingAddress[F.CEP.value]}
          </div>
        </div>

        <div className={classes.CardContext}>
          <div className={classes.CardSubTitle}>{F.ORDER_SUMMARY.label}</div>
          {orderSummary &&
            orderSummary.map((course) => (
              <div className={classes.Row}>
                <img
                  className={classes.Image}
                  src={course[F.COURSE_IMAGE.value]}
                  alt={F.COURSE_IMAGE.label}
                />
                <div className={classes.Column}>
                  <div className={classes.CardSubTitle}>
                    {course[F.COURSE_NAME.value]}
                  </div>
                  <div className={classes.text}>
                    valor total do curso: R$ {course[F.COURSE_PRICE.value]}
                  </div>
                </div>
              </div>
            ))}

          <div className={classes.CardSubTitle}>{F.COURSE_SUBTOTAL.label}</div>
          <div className={classes.text}>1200,00</div>
          <div className={classes.CardSubTitle}>{F.VOUCHER.label}</div>
          <div className={classes.text}>
            {voucherDetail[F.VOUCHER_NAME.value]}
          </div>
          <div className={classes.text}>
            {voucherDetail[F.VOUCHER_PERCENTAGE.value]}
          </div>
        </div>

        <div className={classes.CardContext}>
          <Typography
            variant="h6"
            className={`${classes.text} ${classes.CardTextH6}`}
          >
            Valor total do pedido: R$ {orderDetails[F.ORDER_PRICE.value]}
          </Typography>
        </div>
      </>
    </ContainerWithLoader>
  )
}
