export const VOUCHERS_REMOTE_LIST_ITEM_FIELDS = Object.freeze({
  ID: 'id',
  CAMPAIGN_NAME: 'company',
  PREFIX: 'code',
  TOTAL_AVAILABLE: 'total_available',
  TOTAL_ACTIVATED: 'total_activated',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  DISCOUNT_VALUE: 'discount',
  VOUCHER_TYPE: 'type',
  STATUS: 'active',
  OFFERABLE_TYPE: 'offerable_type',
  OFFERABLE_ID: 'offerable_id'
})

export const VOUCHERS_REMOTE_DETAILS_FIELDS = Object.freeze({
  ID: 'id',
  CAMPAIGN_NAME: 'company',
  PREFIX: 'code',
  TOTAL_AVAILABLE: 'total_available',
  TOTAL_ACTIVATED: 'total_activated',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  DISCOUNT_VALUE: 'discount',
  VOUCHER_TYPE: 'type',
  STATUS: 'active',
  CAMPAIGN_VOUCHERS_LIST: 'campaign_vouchers',
  OFFERABLE_TYPE: 'offerable_type',
  OFFERABLE_ID: 'offerable_id',
  QUANTITY_TICKETS: 'quantity_tickets'
})
