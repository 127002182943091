import {
  BANNER_FIELDS as FIELDS,
  BANNER_REMOTE_FIELDS as REMOTE_FIELDS
} from '../../../../domain/promotional-content/banners'
import { outboundDate } from '../../../utils/outbound-data-transformation'
import { RemoteDateUtils } from '../../../utils/remote-date-utils'
import { BannerAdapter } from './banner-adapter'

const inbound = (remoteData) => {
  const isRotary = BannerAdapter.inbound.isRotary(remoteData[FIELDS.TYPE.value])
  return {
    [FIELDS.KEY.value]: remoteData[REMOTE_FIELDS.KEY],
    [FIELDS.TYPE.value]: BannerAdapter.inbound.type(
      remoteData[REMOTE_FIELDS.TYPE]
    ),
    [FIELDS.TITLE.value]: remoteData[REMOTE_FIELDS.TITLE],
    [FIELDS.URL.value]: isRotary
      ? remoteData[REMOTE_FIELDS.URL]
      : [
          remoteData[REMOTE_FIELDS.URL],
          remoteData[REMOTE_FIELDS.URL_2],
          remoteData[REMOTE_FIELDS.URL_3],
          remoteData[REMOTE_FIELDS.URL_4]
        ],
    [FIELDS.END_DATE.value]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_FIELDS.END_DATE]
    ),
    [FIELDS.START_DATE.value]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_FIELDS.START_DATE]
    ),
    [FIELDS.STATUS.value]: BannerAdapter.inbound.status(
      remoteData[REMOTE_FIELDS.STATUS]
    ),
    [FIELDS.SESSION.value]: remoteData[REMOTE_FIELDS.SESSION],
    [FIELDS.ORDER.value]: isRotary
      ? remoteData[REMOTE_FIELDS.ORDER]
      : [
          remoteData[REMOTE_FIELDS.ORDER],
          remoteData[REMOTE_FIELDS.ORDER_2],
          remoteData[REMOTE_FIELDS.ORDER_3],
          remoteData[REMOTE_FIELDS.ORDER_4]
        ],
    [FIELDS.IMAGE_WEB.value]: remoteData[REMOTE_FIELDS.IMAGE_WEB],
    [FIELDS.IMAGE_RECURSIVE.value]: isRotary
      ? remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE]
      : [
          remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE],
          remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE_2],
          remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE_3],
          remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE_4]
        ]
  }
}

const outbound = (formValues) => {
  const isRotary = BannerAdapter.outbound.isRotary(
    formValues[FIELDS.TYPE.value]
  )
  return {
    [REMOTE_FIELDS.TYPE]: BannerAdapter.outbound.type(
      formValues[FIELDS.TYPE.value]
    ),
    [REMOTE_FIELDS.TITLE]: formValues[FIELDS.TITLE.value],
    [REMOTE_FIELDS.URL]: isRotary
      ? formValues[FIELDS.URL.value]
      : formValues[FIELDS.URL.value][0],
    [REMOTE_FIELDS.URL_2]: isRotary ? null : formValues[FIELDS.URL.value][1],
    [REMOTE_FIELDS.URL_3]: isRotary ? null : formValues[FIELDS.URL.value][2],
    [REMOTE_FIELDS.URL_4]: isRotary ? null : formValues[FIELDS.URL.value][3],
    [REMOTE_FIELDS.END_DATE]: outboundDate(
      formValues[FIELDS.END_DATE.value]
    ).slice(0, 10),
    [REMOTE_FIELDS.START_DATE]: outboundDate(
      formValues[FIELDS.START_DATE.value]
    ).slice(0, 10),
    [REMOTE_FIELDS.STATUS]: BannerAdapter.outbound.status(
      formValues[FIELDS.STATUS.value]
    ),
    [REMOTE_FIELDS.ACTIVE]: true,
    [REMOTE_FIELDS.PAGE_ID]: 2,
    [REMOTE_FIELDS.ORDER]: isRotary
      ? parseInt(formValues[FIELDS.ORDER.value], 10)
      : parseInt(formValues[FIELDS.ORDER.value][0], 10),
    [REMOTE_FIELDS.ORDER_2]: isRotary
      ? null
      : parseInt(formValues[FIELDS.ORDER.value][1], 10),
    [REMOTE_FIELDS.ORDER_3]: isRotary
      ? null
      : parseInt(formValues[FIELDS.ORDER.value][2], 10),
    [REMOTE_FIELDS.ORDER_4]: isRotary
      ? null
      : parseInt(formValues[FIELDS.ORDER.value][3], 10),
    [REMOTE_FIELDS.IMAGE_WEB]: isRotary
      ? formValues[FIELDS.IMAGE_WEB.value]
      : 'null', // recurso tecnico utilizado para não dar erro ate que o tratamento seja feito no backend
    [REMOTE_FIELDS.IMAGE_RECURSIVE]: isRotary
      ? formValues[FIELDS.IMAGE_RECURSIVE.value]
      : formValues[FIELDS.IMAGE_RECURSIVE.value][0],
    [REMOTE_FIELDS.IMAGE_RECURSIVE_2]: isRotary
      ? ''
      : formValues[FIELDS.IMAGE_RECURSIVE.value][1],
    [REMOTE_FIELDS.IMAGE_RECURSIVE_3]: isRotary
      ? ''
      : formValues[FIELDS.IMAGE_RECURSIVE.value][2],
    [REMOTE_FIELDS.IMAGE_RECURSIVE_4]: isRotary
      ? ''
      : formValues[FIELDS.IMAGE_RECURSIVE.value][3]
  }
}

export const BannerDetailsAdapter = {
  inbound,
  outbound
}
