import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  animation: {
    animation: `$rotate 1000ms linear infinite`,
    transform: 'rotate(0deg)'
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}))

export default useStyles
