import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../helpers/mock-server-utils'
import { courseDetailAdapterInbound } from '../adapters/course-detail-adapters'
import { COURSE_DETAIL_FIELDS as FIELDS } from '../remote-fields/course-detail-fields'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar os detalhes do pedido
 * @returns Promise com a resposta ou rejeita com erro
 */
const getCourseDetail = (id) => {
  return new Promise((resolve, reject) => {
    const path = ``
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, headers)
      .then((response) => {
        resolve(courseDetailAdapterInbound(response))
      })
      .catch((error) => reject(error))
  })
}

const courseDetailFactory = (courseId) => {
  let name
  let status
  let total
  let extended
  if (courseId === '101') {
    name = 'Cálculos e Teses Revisionais de Benefícios Previdenciários'
    status = 'ongoing'
    total = '60,00'
    extended = {
      [FIELDS.EXTENDED_BY]: 'filipe.siqueira@platosedu.com.br',
      [FIELDS.EXTENDED_DATE]: '12:59 - 03/03/2022',
      [FIELDS.EXTENDED_FROM_TO]: '’18/02/2022’ para ‘03/03/2022.‘',
      [FIELDS.EXTENDED_JUSTIFICATION]: 'filipe.siqueira@platosedu.com.br'
    }
  } else if (courseId === '102') {
    name = 'Legislação Penal Especial'
    status = 'completed'
    total = '150,50'
  } else if (courseId === '103') {
    name = 'Legislação Penal Especial'
    status = 'cancelled'
    total = '149,50'
  } else {
    name = 'Cálculos e Teses Revisionais de Benefícios Previdenciários'
    status = 'ongoing'
    total = '99,00'
  }
  return {
    [FIELDS.KEY]: courseId,
    [FIELDS.NAME]: name,
    [FIELDS.SKU]: '3231',
    [FIELDS.START_DATE]: new Date(2022, 10, 1),
    [FIELDS.END_DATE]: new Date(2023, 2, 1),
    [FIELDS.LAST_ACESS]: '11:57 - 18/02/2022',
    [FIELDS.STATUS]: status,
    [FIELDS.PRICE]: '600,00',
    [FIELDS.DISCOUNT]: '10%',
    [FIELDS.SUBTOTAL]: '60,00',
    [FIELDS.VOUCHER]: {
      [FIELDS.VOUCHER_NAME]: 'LFG80%OFF',
      [FIELDS.VOUCHER_PERCENTAGE]: '80%'
    },
    [FIELDS.REVERSAL]: {
      [FIELDS.REVERSAL_DATE]: '11:57 24/02/2022',
      [FIELDS.REVERSAL_BY]: 'filipe.siqueira@platosedu.com.br',
      [FIELDS.REVERSAL_METHOD]: 'Cartão',
      [FIELDS.REVERSAL_JUSTIFICATION]:
        'O cliente solicitou o estorno por motivo de desistência.'
    },
    [FIELDS.EXTENDED]: extended,
    [FIELDS.TOTAL]: total
  }
}

const getCourseDetailMocked = (courseId) => {
  return mockedServerAnswer(
    courseDetailFactory(courseId),
    true,
    1000,
    courseDetailAdapterInbound
  )
}

export { getCourseDetailMocked as getCourseDetail }
