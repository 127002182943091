import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  backButton: {
    marginLeft: 72
  },
  infoCard: {
    display: 'flex',
    width: 936,
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 12
  },
  cardContainer: {
    display: 'block',
    alignItems: 'baseline',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    marginBottom: 20
  },
  productsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  productsCard: {
    display: 'block',
    alignItems: 'baseline',
    borderRadius: 8,
    backgroundColor: '#fff',
    marginBottom: 20,
    width: 304,
    marginRight: 24
  },
  product: {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 12
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editButton: {
    marginRight: 8
  },
  deleteButton: {
    color: '#A42C31',
    borderColor: '#A42C31',
    marginBottom: 16
  },
  deleteBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginRight: 8
  }
}))

export default useStyles
