import { COURSE_SERVICES_FOR_DISCOUNTS_FIELDS as FIELD } from '../../../courses-services'
import { exportDataToCsv } from '../../../../helpers/exporting/export-data-to-csv'

const HEADERS = {
  [FIELD.NAME]: 'Nome do produto',
  [FIELD.SKU]: 'SKU do produto',
  [FIELD.FROM_VALUE]: 'Preço DE',
  [FIELD.TO_VALUE]: '* Preço POR (desconto)',
  [FIELD.PERCENT_DISCOUNT]: '% de desconto',
  [FIELD.START_DATE]: '* Data início',
  [FIELD.END_DATE]: '* Data fim'
}

const { IS_VALID, NOTE, PERCENT_DISCOUNT, START_DATE, END_DATE, TO_VALUE } =
  FIELD

const prepareDiscountValue = (value) => {
  if (value === '') return value

  return value.replace(/[%\s]/g, '').replace(',', '.')
}

const prepareDateValues = (value) => {
  if (!value) return value

  if (value.match(/\d{2}\/\d{2}\/\d{2}$/)) {
    return value.replace(/(\d{2}\/\d{2}\/)(\d{2})/, '$120$2')
  }

  return value
}

const preparePriceValues = (value) => {
  if (value.match(/,/)) return value

  return `${value},00`
}

export const filterAndPrepareCampaignData = (importedCsvData) => {
  const filteredData = importedCsvData
    .filter((data) => data[IS_VALID] && data[NOTE] === '')
    .map(({ [NOTE]: note, [IS_VALID]: isValid, ...rest }) => ({
      ...rest,
      [START_DATE]: prepareDateValues(rest[START_DATE]),
      [END_DATE]: prepareDateValues(rest[END_DATE]),
      [PERCENT_DISCOUNT]: prepareDiscountValue(rest[PERCENT_DISCOUNT]),
      [TO_VALUE]: preparePriceValues(rest[TO_VALUE])
    }))

  return exportDataToCsv(filteredData, HEADERS)
}
