const scripts = [
  '/js/jquery.js',
  '/js/h5p.js?'+new Date().getTime(),
  '/js/h5p-event-dispatcher.js',
  '/js/h5p-x-api-event.js',
  '/js/h5p-x-api.js',
  '/js/h5p-content-type.js',
  '/js/h5p-confirmation-dialog.js',
  '/js/h5p-action-bar.js',
  '/js/request-queue.js',
  '/js/h5p-utils.js'
]
export default scripts
