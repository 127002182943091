import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  marginTop24: {
    marginTop: '24px'
  },
  buttonsContainers: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:nth-child(n+2)': {
      marginLeft: '12px'
    }
  },
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '300px'
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main
    }
  }
}))

export default useStyles
