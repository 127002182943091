import {
  TextInput,
  SelectSaraiva,
  MenuItem,
  Alert
} from '@equipe-ninja/saraiva-ui'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from '../styles'
import { ButtonUpload } from './ButtonUpload'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { UploadProvider } from '../../../../contexts/UploadContext'
import { ImageDimension } from '../../../../helpers/imageDimension'
import {
  bannersSizers,
  BANNER_SIZES
} from '../../../../domain/promotional-content/banners'

export function FormBannerRotary({
  formState,
  formErrors,
  onChange,
  onTitle,
  onBlur
}) {
  const classes = useStyles()
  const { TITLE, URL, SESSION, ORDER, IMG_WEB, IMG_RECURSIVE } = FIELDS
  const [imageDesktopError, setImageDesktopError] = useState(false)
  const [imageMobileError, setImageMobileError] = useState(false)

  async function handleFileChange(field, image, type) {
    const dimension = bannersSizers(type)
    const sizeIsValid = await ImageDimension.imageSizeValidator(
      image,
      dimension
    )

    onChange(field, sizeIsValid ? image : null)

    if (type === BANNER_SIZES.WEB) {
      setImageDesktopError(!sizeIsValid)
    } else {
      setImageMobileError(!sizeIsValid)
    }
  }

  const onDeleteFile = (field, type) => {
    if (type === BANNER_SIZES.WEB) {
      setImageDesktopError(false)
      onChange(field, null)
    }

    if (type === BANNER_SIZES.RESPONSIVE) {
      setImageMobileError(false)
      onChange(field, null, 0)
    }
  }

  return (
    <>
      <div className={classes.contentUpdate}>
        <div className={classes.contentTitle}>Banner Rotativo</div>
        <TextInput
          value={formState[TITLE.value]}
          color="primary"
          error={!!formErrors[TITLE.value]}
          helperText={formErrors[TITLE.value]}
          onBlur={() => onBlur(TITLE.value)}
          onChange={(e) => {
            onChange(TITLE.value, e.target.value)
            if (onTitle !== null) onTitle(e.target.value)
          }}
          size="full"
          label="Titulo do banner rotativo"
          required
        />
        <TextInput
          value={formState[URL[0].value]}
          color="primary"
          error={!!formErrors[URL[0].value]}
          helperText={
            <>
              {!!formErrors[URL[0].value] && (
                <div>{formErrors[URL[0].value]}</div>
              )}
              <div>
                Inserir o subdiretório da URL, ou seja as páginas internas do
                seu site: http://www.lfg.com.br/
                <b>concursos-cursos-carreiras-juridicas</b>
              </div>
            </>
          }
          onBlur={() => onBlur(URL[0].value[0], 0)}
          onChange={(e) => {
            onChange(URL[0].value, e.target.value)
          }}
          size="full"
          label="URL de redirecionamento do banner"
          required
        />
        <SelectSaraiva
          error={!!formErrors[SESSION.value]}
          helperText={formErrors[SESSION.value]}
          value={formState[SESSION.value]}
          onChange={(e) => {
            onChange(SESSION.value, e.target.value)
          }}
          label="Sessão no site *"
          size="full"
          required
        >
          <MenuItem value={2}>Home</MenuItem>
        </SelectSaraiva>
        <SelectSaraiva
          error={!!formErrors[ORDER[0].value]}
          helperText={formErrors[ORDER[0].value]}
          value={formState[ORDER[0].value]}
          onChange={(e) => {
            onChange(ORDER[0].value, e.target.value)
          }}
          label="Ordem de exibição *"
          size="full"
          required
        >
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
        </SelectSaraiva>
        <div className={classes.contentDisplayLeft} style={{ marginTop: 0 }}>
          <div className={classes.boxImage}>
            <span>Utilizar JPG ou PNG no tamanho 1280x350px</span>
            <UploadProvider>
              <ButtonUpload
                field={IMG_WEB.value}
                image={formState[IMG_WEB.value]}
                type={BANNER_SIZES.WEB}
                // eslint-disable-next-line react/jsx-no-bind
                onUpload={handleFileChange}
                onDelete={onDeleteFile}
              />
            </UploadProvider>
            {imageDesktopError && (
              <Alert severity="error">
                Banner web anexado não corresponde ao tamanho indicado
              </Alert>
            )}
            {formErrors[IMG_WEB.value] && (
              <Alert severity="error">{formErrors[IMG_WEB.value]}</Alert>
            )}
          </div>
          <div className={classes.boxImage}>
            <span>Utilizar JPG ou PNG no tamanho 414x430px</span>
            <UploadProvider>
              <ButtonUpload
                field={IMG_RECURSIVE[0].value}
                image={formState[IMG_RECURSIVE[0].value]}
                type={BANNER_SIZES.RESPONSIVE}
                // eslint-disable-next-line react/jsx-no-bind
                onUpload={handleFileChange}
                onDelete={onDeleteFile}
              />
            </UploadProvider>
            {imageMobileError && (
              <Alert severity="error">
                Banner responsivo anexado não corresponde ao tamanho indicado
              </Alert>
            )}
            {formErrors[IMG_RECURSIVE[0].value] && (
              <Alert severity="error">
                {formErrors[IMG_RECURSIVE[0].value]}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

FormBannerRotary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.objectOf(PropTypes.any).isRequired,
  onTitle: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func
}

FormBannerRotary.defaultProps = {
  onTitle: null,
  onBlur: () => null
}
