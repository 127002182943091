import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { listCompanyAdapterInbound } from '../adapters/vouchers-adapters'
import { getResquestResponseHandler } from '../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para dados de voucher por campanha
 * @returns Promise com a resposta ou rejeita com erro
 */

const getVouchersByCompany = (company) => {
  return new Promise((resolve, reject) => {
    const path = `/tickets/api/v1/tickets/ecommerce?company=${company}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, null, headers)
      .then((response) => {
        return getResquestResponseHandler(response)
      })
      .then((json) => {
        resolve(listCompanyAdapterInbound(json))
      })
      .catch((error) => reject(error))
  })
}

export { getVouchersByCompany }
