/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react'
import { Button } from '@equipe-ninja/saraiva-ui'
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded'

import useStyles from './styles'
import ConfigForm from '../ConfigForm'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'

function ConfigButton() {
  const classes = useStyles()
  const { showingConfigForm, toggleConfigForm } = useContext(ConnectionContext)

  return (
    <>
      {showingConfigForm && <ConfigForm />}

      <Button
        color="primary"
        onClick={() => toggleConfigForm(true)}
        text="Botão"
        variant="outlined"
        className={classes.button}
        startIcon={<SettingsRoundedIcon />}
      >
        CONFIGURAR CONEXÃO
      </Button>
    </>
  )
}

export default ConfigButton
