import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  spacing: {
    '& > *:nth-child(n+2)': {
      marginTop: '24px'
    }
  },
  titleSection: {
    marginBottom: '18px'
  },
  htmlSection: {
    marginBottom: '18px',
  },
  datesContainer: {
    display: 'flex',
    '& > *:nth-child(n+2)': {
      marginLeft: '24px'
    }
  },
  datePickerContainers: {
    width: '278px'
  },
  marginTop24: {
    marginTop: '24px'
  },
  buttonsContainers: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  helperText: {
    margin: '0 -14px'
  }
}))

export default useStyles
