import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const AccessLink = {
  // TO DO: remover apos refatoracao
  async createAccessLink(payload) {
    const path = 'accounts/api/v1/activation_links/create_links'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  }
}

export default AccessLink
