import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '100%'
  },
  error: {
    margin: 0,
    color: theme.palette.error.main
  }
}))

export default useStyles
