/* eslint-disable consistent-return */
import Papa from 'papaparse'
import { COURSE_SERVICES_FOR_DISCOUNTS_FIELDS } from '../../../courses-services'

const {
  NAME,
  SKU,
  FROM_VALUE,
  TO_VALUE,
  PERCENT_DISCOUNT,
  START_DATE,
  END_DATE,
  NOTE,
  IS_VALID
} = COURSE_SERVICES_FOR_DISCOUNTS_FIELDS

const POSITION_MAPPING = {
  [NAME]: 0,
  [SKU]: 1,
  [FROM_VALUE]: 2,
  [TO_VALUE]: 3,
  [PERCENT_DISCOUNT]: 4,
  [START_DATE]: 5,
  [END_DATE]: 6
}

const parseValue = (v) => {
  if (v) {
    if (v.trim() === '') return ''

    if (v.includes('R$')) return v

    return `R$ ${v}`
  }
}

const validateValues = (originalValue, newValue, startDate, endDate) => {
  if (!newValue && !startDate && !endDate) {
    return {
      isValid: true,
      note: 'Valores em branco, o item será desconsiderado'
    }
  }

  if (!originalValue) {
    return { isValid: false, note: 'Valor original em branco' }
  }
  if (!newValue) {
    return { isValid: false, note: 'Valor com desconto em branco' }
  }
  if (!startDate) {
    return { isValid: false, note: 'Data de início em branco' }
  }
  if (!endDate) {
    return { isValid: false, note: 'Data final em branco' }
  }

  return { isValid: true, note: '' }
}

export const parseAndValidateImportedCsv = (csv) => {
  const { data } = Papa.parse(csv, { header: true })

  return data
    .filter((item) => item['Nome do Produto'] !== '')
    .map((item) => {
      const values = Object.values(item)
      const originalValue = parseValue(values[POSITION_MAPPING[FROM_VALUE]])
      const newValue = parseValue(values[POSITION_MAPPING[TO_VALUE]])
      const startDate = values[POSITION_MAPPING[START_DATE]].trim()
      const endDate = values[POSITION_MAPPING[END_DATE]].trim()

      const { isValid, note } = validateValues(
        originalValue,
        newValue,
        startDate,
        endDate
      )

      return {
        [NAME]: values[POSITION_MAPPING[NAME]],
        [SKU]: values[POSITION_MAPPING[SKU]],
        [FROM_VALUE]: originalValue,
        [TO_VALUE]: newValue,
        [PERCENT_DISCOUNT]: values[POSITION_MAPPING[PERCENT_DISCOUNT]],
        [START_DATE]: startDate,
        [END_DATE]: endDate,
        [NOTE]: note,
        [IS_VALID]: isValid
      }
    })
}
