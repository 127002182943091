export const ECOMMERCE_FORM_FIELDS = {
  ACCESS_WINDOW: 'accessWindow',
  CATEGORY: 'category',
  COURSE_LENGTH: 'courseLength',
  COURSE_TABS: 'courseTabs',
  DESCRIPTION: 'description',
  ID: 'id',
  IMG_BINARY: 'imgBinary',
  IMG_URL: 'imgUrl',
  IMG_CHANGED: 'hasImageChanged',
  KEY_WORDS: 'keyWords',
  NAME: 'name',
  SUB_CATEGORY: 'subCategory',
  URL: 'url',
  VALUE: 'value',
  SKU: 'sku'
}

export const ECOMMERCE_TABS_FIELDS = {
  CONTENT: 'content',
  ID: 'id',
  ORDER: 'order',
  TITLE: 'title'
}
