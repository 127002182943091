export const generateFormError = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      let computedValue

      switch (v?.constructor) {
        case Object:
          computedValue = generateFormError(v)
          break
        case Array:
          computedValue = v.map((e) => generateFormError(e))
          break
        default:
          computedValue = false
      }

      return [k, computedValue]
    })
  )
