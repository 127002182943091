/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { Button, Dialog, TextInput } from '@equipe-ninja/saraiva-ui'

import PropTypes from 'prop-types'
import { IconButton, TextField, Typography } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import TextEditor from '../TextEditor'

import useStyles from './styles'
import { GeneralContext } from '../../contexts/GeneralContext'

function ViewQuestion({ showDialog, setShowDialog }) {
  const classes = useStyles()
  const { view } = useContext(GeneralContext)

  const renderAlternatives = () => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'
    let correctAlternativeLetter = ''

    return view.question_alternatives.map((alternative, index) => {
      const letter = alphabet[index]
      const isCorrect = alternative.id === view.correct_alternative_id

      if (isCorrect) {
        correctAlternativeLetter = letter.toUpperCase()
      }

      return (
        <div key={alternative.id} className={classes.alternativeContainer}>
          <TextInput
            className={classes.TextAlternative}
            color="primary"
            label={`Alternativa ${letter.toUpperCase()}`}
            size="xl"
            required
            multiline
            rows={2}
            value={alternative.content}
            disabled
          />
          {alternative.justification !== null &&
            alternative.justification !== '' && (
              <TextField
                disabled
                value={alternative.justification}
                label={`Justificativa ${letter.toUpperCase()}`}
                variant="standard"
                fullWidth
                sx={{ m: 1 }}
                InputProps={{
                  endAdornment: (
                    <IconButton disabled>
                      <Close />
                    </IconButton>
                  )
                }}
              />
            )}
        </div>
      )
    })
  }

  return (
    <Dialog
      title="Visualizar questão"
      primaryButton="FECHAR VISUALIZAÇÃO"
      size="lg"
      open={showDialog}
      handleConfirm={() => {
        setShowDialog(false)
      }}
    >
      <div className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          Informações gerais da questão
        </Typography>

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Código"
          size="md"
          disabled
          value={view.code}
        />
        <TextInput
          className={classes.textInput}
          color="primary"
          label="Formato de Questão"
          size="md"
          disabled
          value={view.type}
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Área do conhecimento"
          required
          helperText="Ex: Direito, Administração, Ciências contábeis..."
          size="md"
          disabled
          value={view.knowledge_area}
        />
        <TextInput
          className={classes.textInput}
          color="primary"
          label="Disciplina"
          helperText="Ex: Direito civil, Administração pública, Biologia celular..."
          size="md"
          required
          disabled
          value={view.subject}
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Tema"
          helperText="Ex: Direito das Sucessões, Contratos, Respiração celular..."
          size="md"
          required
          disabled
          value={view.theme}
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Objetivo"
          helperText="Indique se a questão é voltada para OAB, Graduação, ENADE, etc."
          size="md"
          value={view.goal}
          disabled
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Fonte"
          helperText="Indique aqui a origem da questão (local em que foi retirada)"
          size="md"
          value={view.question_source}
          disabled
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Ano"
          helperText="Indique o ano que a questão foi elaborada"
          size="md"
          disabled
          value={view.year}
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="TAGS"
          helperText="Indique TAGS que são relevantes para categorizar essa questão"
          size="md"
          value={view.tags}
          disabled
        />

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Dificuldade"
          helperText="Indique TAGS que são relevantes para categorizar essa questão"
          size="md"
          value={view.dificult_level}
          disabled
        />

        <Typography variant="h6" className={classes.subTitle}>
          Enunciado *
        </Typography>
        <div style={{ pointerEvents: 'none' }}>
          <TextEditor initialValue={view.content} value={view.content} />
        </div>

        <Typography variant="h6" className={classes.subTitle}>
          Alternativas
        </Typography>

        <Typography className={classes.footer}>
          Insira de 2 a 6 alternativas para a questão
        </Typography>

        <div className={classes.container}>{renderAlternatives()}</div>

        <Button
          className={classes.btnAddAlternative}
          startIcon={<Add />}
          color="primary"
          variant="contained"
          disabled
        >
          ADICIONAR ALTERNATIVA
        </Button>

        <Typography variant="h6" className={classes.subTitle}>
          Gabarito
        </Typography>
        <Typography className={classes.footer}>
          Insira a alternativa correta da questão
        </Typography>

        <TextInput
          className={classes.textInput}
          color="primary"
          label="Alternativa correta"
          size="xl"
          disabled
          value={(() => {
            const alphabet = 'abcdefghijklmnopqrstuvwxyz'
            const correctIndex = view.question_alternatives.findIndex(
              (alternative) => alternative.id === view.correct_alternative_id
            )
            const correctValue =
              correctIndex !== -1 ? alphabet[correctIndex].toUpperCase() : ''
            return `Alternativa ${correctValue}`
          })()}
        />
      </div>
    </Dialog>
  )
}

export default ViewQuestion

ViewQuestion.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired
}
