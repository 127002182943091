import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import {
  DatePickerSaraiva,
  SelectSaraiva,
  Dialog
} from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import { VOUCHER_FIELDS, VOUCHER_STATUSES } from '../../../../domain/vouchers'
import {
  editStatusFieldValidation,
  editStatusValidation
} from '../../../../domain/vouchers/business'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { editStatusVouchers } from '../../../../services/vouchers'
import { RoutesMapping } from '../../../../helpers/routes-mapping'

const { START_DATE, END_DATE, STATUS } = VOUCHER_FIELDS

export const EditStatusFormDialog = (props) => {
  const { detailsData, open, onCloseDialog, notifyChangesHandler } = props
  const history = useHistory()
  const classes = useStyles()
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const [uiControls, setUiControls] = useState({
    showChangeStateDialog: false,
    hideFormDialog: false,
    isExecutingTask: false
  })
  const [formState, setFormState] = useState({
    [START_DATE]: detailsData?.[START_DATE],
    [END_DATE]: detailsData?.[END_DATE],
    [STATUS]: detailsData?.[STATUS]
  })
  const [formErrors, setFormErrors] = useState({
    [START_DATE]: false,
    [END_DATE]: false,
    [STATUS]: false
  })

  const haveFormValuesChanged = () => {
    return Object.keys(formState).reduce((prev, field) => {
      return formState[field] !== detailsData[field] ? true : prev
    }, false)
  }

  const changeFieldAndValidate = (field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }))
    const error = editStatusFieldValidation(field, value, formState)
    setFormErrors((prev) => ({ ...prev, ...error }))
  }

  const onChangeEvent = (field, event, adapter = (e) => e) => {
    changeFieldAndValidate(field, adapter(event.target.value))
  }

  const onChangeValue = (field, value) => {
    changeFieldAndValidate(field, value)
  }

  const showInactivatingDialog = () => {
    setUiControls((prev) => ({
      ...prev,
      hideFormDialog: true,
      showChangeStateDialog: true
    }))
  }

  const isInactivatingVoucher = () => {
    return (
      formState[STATUS] === VOUCHER_STATUSES.INACTIVE.value &&
      detailsData[STATUS] === VOUCHER_STATUSES.ACTIVE.value
    )
  }

  const onSaveChangeSuccess = () => {
    setSuccessMessage('Voucher alterado com sucesso')

    if (isInactivatingVoucher()) {
      history.push(RoutesMapping.vouchersLegacyEntryPoint)
    } else {
      setUiControls((prev) => ({
        ...prev,
        hideFormDialog: false,
        showChangeStateDialog: false
      }))
      notifyChangesHandler(formState)
    }
  }

  const saveChanges = () => {
    setUiControls((prev) => ({ ...prev, isExecutingTask: true }))

    editStatusVouchers({ ...detailsData, ...formState })
      .then(() => {
        onSaveChangeSuccess()
      })
      .catch(() => {
        setErrorMessage('Não foi possível salvar as alterações no voucher')
      })
      .finally(() => {
        setUiControls((prev) => ({ ...prev, isExecutingTask: false }))
      })
  }

  const onConfirmInactivatingVoucher = () => {
    saveChanges()
  }

  const onSubmit = () => {
    const { isValid, errors } = editStatusValidation(formState)

    if (!isValid) {
      setFormErrors(errors)
      return
    }

    if (isInactivatingVoucher()) {
      showInactivatingDialog()
      return
    }

    saveChanges()
  }

  const hideInactivatingHandler = () => {
    setUiControls((prev) => ({
      ...prev,
      showChangeStateDialog: false,
      hideFormDialog: false
    }))
  }

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      [START_DATE]: detailsData?.[START_DATE],
      [END_DATE]: detailsData?.[END_DATE],
      [STATUS]: detailsData?.[STATUS]
    }))
  }, [detailsData])

  return (
    <>
      <Dialog
        confirmWithoutCheck={() => null}
        handleClose={() => onCloseDialog()}
        handleConfirm={() => onSubmit()}
        isDisabled={!haveFormValuesChanged() || uiControls.isExecutingTask}
        open={open && !uiControls.hideFormDialog}
        primaryButton="Salvar"
        secondaryButton="Cancelar"
        size="lg"
        title="Alterar status do voucher"
      >
        <form style={{ width: '100%' }}>
          <Grid container spacing={3} className={classes.marginTopMedium}>
            <Grid item sm={6}>
              <DatePickerSaraiva
                error={formErrors[START_DATE]}
                helperText={formErrors[START_DATE] || ''}
                label="Data início *"
                onChange={(selectedDate) =>
                  onChangeValue(START_DATE, selectedDate)
                }
                size="full"
                value={formState[START_DATE]?.toString() || null}
                disabled
              />
            </Grid>

            <Grid item sm={6}>
              <DatePickerSaraiva
                error={!!formErrors[END_DATE]}
                helperText={formErrors[END_DATE] || ''}
                label="Data fim *"
                onChange={(selectedDate) =>
                  onChangeValue(END_DATE, selectedDate)
                }
                size="full"
                value={formState[END_DATE]?.toString() || null}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectSaraiva
                error={formErrors[STATUS]}
                helperText={formErrors[STATUS] || ''}
                label="Status do voucher *"
                onChange={(ev) => onChangeEvent(STATUS, ev)}
                value={formState[STATUS]}
                size="full"
              >
                <MenuItem value="">
                  <em>Nenhum status selecionado</em>
                </MenuItem>

                <MenuItem value={VOUCHER_STATUSES.ACTIVE.value}>
                  {VOUCHER_STATUSES.ACTIVE.label}
                </MenuItem>

                <MenuItem value={VOUCHER_STATUSES.INACTIVE.value}>
                  {VOUCHER_STATUSES.INACTIVE.label}
                </MenuItem>
              </SelectSaraiva>
            </Grid>
          </Grid>
        </form>
      </Dialog>

      <Dialog
        alert
        confirmWithoutCheck={() => null}
        handleClose={hideInactivatingHandler}
        handleConfirm={onConfirmInactivatingVoucher}
        isDisabled={uiControls.isExecutingTask}
        label="Sim, tenho certeza!"
        open={uiControls.showChangeStateDialog}
        primaryButton="Salvar"
        secondaryButton="Cancelar"
        size="lg"
        title="Inativar voucher"
      >
        <Typography variant="body1">
          Enquanto inativo, este voucher não poderá ser utilizados por nenhum
          usuário. Assim, todos os usuários que tiverem este voucher em mãos,
          não poderão utiliza-lo mais.
        </Typography>

        <Typography variant="body1">
          Este voucher poderá ser reativado a qualquer momento.
        </Typography>
      </Dialog>
    </>
  )
}

EditStatusFormDialog.propTypes = {
  detailsData: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  notifyChangesHandler: PropTypes.func.isRequired
}

EditStatusFormDialog.defaultProps = {}
