import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  paddingCard: {
    padding: '0 24px 64px 24px'
  },
  errorStatus: {
    color: theme.palette.error.main
  },
  successStatus: {
    color: theme.palette.success.main
  },
  buttonCampaign: {
    color: 'rgba(164, 44, 49, 1)',
    border: '1px solid rgba(164, 44, 49, 1)',
    marginLeft: 16
  },
  button: {
    marginTop: 24
  }
}))

export default useStyles
