import React, { useState } from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import cls from 'classnames'
import Typography from '@material-ui/core/Typography'
import { Card, Button } from '@equipe-ninja/saraiva-ui'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { userOrdersState } from '../../../../atoms/user-details'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import {
  USER_ORDER_FIELDS,
  USER_ORDER_ITEMS_FIELDS,
  USER_ORDER_ITEM_COMPLETION_STATUS
} from '../../../../domain/user-management-content/user-orders'
import {
  formatDate,
  formatCurrency
} from '../../../../helpers/data-transformation'
import { CustomRouterLink } from '../../../../components/CustomRouterLink'
import useStyles from './styles'
import { CompletionStatus } from './components/CompletionStatus'
import { CourseReversalDialog } from '../CourseReversalDialog'

const { ORDER_NUMBER, KEY, ORDER_DATE, ORDER_TOTAL_VALUE, ORDER_ITEMS } =
  USER_ORDER_FIELDS
const {
  ITEM_ID,
  ITEM_COVER_IMG,
  ITEM_DESCRIPTION,
  ITEM_VALUE,
  ITEM_COMPLETION_STATUS,
  ITEM_COURSE_ID
} = USER_ORDER_ITEMS_FIELDS
const { ON_GOING, CANCELLED } = USER_ORDER_ITEM_COMPLETION_STATUS

export function Orders() {
  const history = useHistory()
  const { userId, brand } = useParams()
  const classes = useStyles()
  const orderState = useRecoilValue(userOrdersState)
  const [paymentReversal, setPaymentReversal] = useState({
    itemId: null,
    showDialog: false
  })

  const orderDetailsUrl = (orderId) => {
    return generatePath(RoutesMapping.userManagementPageOrderDetails, {
      brand,
      userId,
      orderId
    })
  }

  const navigateToCourseDetailsUrl = (courseId) => {
    history.push(
      generatePath(RoutesMapping.userManagementPageCourseDetails, {
        brand: 'lfg',
        userId,
        courseId
      })
    )
  }

  const isReverseDisabled = (status) => {
    return status !== ON_GOING.value
  }

  const onReversePaymentClick = (itemId) => {
    setPaymentReversal((prev) => ({ ...prev, itemId, showDialog: true }))
  }

  const onReversalPaymentSuccess = () => {
    setPaymentReversal((prev) => ({ ...prev, itemId: null, showDialog: false }))
  }

  return (
    <ContainerWithLoader isLoading={orderState.isFetchingOrders}>
      <div>
        {orderState.orders.map((order) => (
          <div className={classes.marginTopXL} key={order?.[KEY]}>
            <div className={classes.orderHeader}>
              <div className={classes.orderNumberInfo} sm={3}>
                <Typography className={classes.orderNumberLabel}>
                  Pedido: {order?.[ORDER_NUMBER]}
                </Typography>

                <Typography variant="body2" className={classes.infoMargin}>
                  Pedido em: {formatDate(order?.[ORDER_DATE])}
                </Typography>
              </div>

              <div sm={9} className={classes.orderValueAndLink}>
                <div>
                  <Typography variant="body2">VALOR TOTAL DO PEDIDO</Typography>

                  <Typography variant="body2" style={{ marginTop: '6px' }}>
                    {formatCurrency(order?.[ORDER_TOTAL_VALUE])}
                  </Typography>
                </div>

                <CustomRouterLink
                  label="Exibir detalhes do pedido"
                  to={orderDetailsUrl(order?.[KEY])}
                />
              </div>
            </div>

            {order?.[ORDER_ITEMS].map((item) => (
              <div className={classes.marginTop16}>
                <Card
                  image={item?.[ITEM_COVER_IMG]}
                  list
                  imageHoverText="Detalhes do curso"
                  onClick={() =>
                    navigateToCourseDetailsUrl(item?.[ITEM_COURSE_ID])
                  }
                >
                  <div>
                    <Typography variant="body1">
                      {item?.[ITEM_DESCRIPTION]}
                    </Typography>

                    <Typography
                      className={cls(
                        classes.textSecondary,
                        classes.marginTop10
                      )}
                    >
                      Valor total do curso:
                      {` ${formatCurrency(item?.[ITEM_VALUE], false)}`}
                    </Typography>

                    <Typography
                      className={cls(
                        classes.textSecondary,
                        classes.marginTop10
                      )}
                    >
                      {'Status do usuário no curso: '}
                      <CompletionStatus
                        status={item?.[ITEM_COMPLETION_STATUS]}
                      />
                    </Typography>

                    <div style={{ marginTop: '20px' }}>
                      <Button
                        onClick={() =>
                          navigateToCourseDetailsUrl(item?.[ITEM_COURSE_ID])
                        }
                        color="primary"
                        variant="contained"
                      >
                        Detalhes do curso
                      </Button>

                      <Button
                        color="primary"
                        disabled={isReverseDisabled(
                          item?.[ITEM_COMPLETION_STATUS]
                        )}
                        onClick={() => onReversePaymentClick(item?.[ITEM_ID])}
                        className={cls(
                          classes.errorButton,
                          classes.marginLeft16
                        )}
                        variant="outlined"
                      >
                        {item?.[ITEM_COMPLETION_STATUS] === CANCELLED.value
                          ? 'curso estornado'
                          : 'Estornar curso'}
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        ))}

        <CourseReversalDialog
          showDialog={paymentReversal.showDialog}
          orderItemId={paymentReversal.itemId}
          onCloseDialog={() =>
            setPaymentReversal((prev) => ({ ...prev, showDialog: false }))
          }
          onReversalPaymentSuccess={onReversalPaymentSuccess}
        />
      </div>
    </ContainerWithLoader>
  )
}
