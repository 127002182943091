/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useContext, forwardRef } from 'react'

import { GeneralContext } from '../../../../contexts/GeneralContext'
import { ContentsContext } from '../../../../contexts/ContentsContext'
import { UploadContext } from '../../../../contexts/UploadContext'

import Messages from '../../../../helpers/messages'

function UploadFile() {
  const { setErrorMessage } = useContext(GeneralContext)

  const { moduleParentId, toggleShowingContentMenu } =
    useContext(ContentsContext)

  const {
    toggleShowingUploadBar,
    setCountFileUpload,
    setTotalFiles,
    filesError,
    setReload,
    listUploadErrors,
    setErrorsBar,
    uploadFile,
    clearState
  } = useContext(UploadContext)

  async function handleFileChange(event) {
    await clearState()

    try {
      toggleShowingContentMenu(null)

      const file = event.target.files

      if (file.length > 0) {
        setTotalFiles(file.length)
        toggleShowingUploadBar(true)
      }

      for (const arch of file) {
        await uploadFile(arch, moduleParentId)
        setCountFileUpload((currentState) => {
          return currentState + 1
        })
      }

      await setErrorsBar(listUploadErrors)
      if (filesError !== []) {
        setReload(true)
      }
    } catch (error) {
      toggleShowingUploadBar(false)
      console.log(error)
      setErrorMessage(Messages.createFile.error)
    }
  }

  return (
    <>
      <input
        id="uploadFile"
        type="file"
        accept=".h5p"
        hidden
        onChange={handleFileChange}
        multiple
      />
    </>
  )
}

export default forwardRef(UploadFile)
