import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiTab-wrapper': {
      fontSize: 15,
      marginBottom: 35
    }
  },

  title: {
    marginBottom: 24
  },
  button: {
    marginTop: 24,
    marginBottom: 24
  },
  text: {
    marginTop: 37,
    color: 'var(--text-secondary, rgba(0, 0, 0, 0.54))',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '160%',
    letterSpacing: '0.15px'
  }
}))

export default useStyles
