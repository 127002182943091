import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  divInputs: {
    width: '100%'
  },
  inputs: {
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0
    }
  }
}))

export default useStyles
