import React, { useContext, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Checkbox,
  Typography,
  Divider,
  Box,
  Switch,
  Button
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { Create, Delete } from '@material-ui/icons'
import useStyles from './style'
import IntegrationsService from '../../services/integrationsService'
import { GeneralContext } from '../../contexts/GeneralContext'
import { EditSimulated } from '../EditSimulated'
import { DeleteSimulated } from '../DeleteSimulated'

const TableSimulatedBooks = () => {
  const history = useHistory()

  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [checked, setChecked] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rows, setRows] = useState([])
  const [orderBy, setOrderBy] = useState('asc')
  const [showEditSimulated, setshowEditSimulated] = useState(false)
  const [showDialogDelete, setshowDialogDelete] = useState(false)

  const { setAllPractice, setSelectedSimulado, setViewSimulated } =
    useContext(GeneralContext)

  const handleSort = () => {
    const newOrderBy = orderBy === 'asc' ? 'desc' : 'asc'
    setOrderBy(newOrderBy)
    setRows((prevRows) => prevRows.reverse())
  }

  const handleOpenEditSimulated = () => {
    const selectedRow = rows.find((row) => row.id === selected[0])
    if (selectedRow) {
      setViewSimulated(selectedRow)
      setshowEditSimulated(true)
    }
  }

  const handleCloseEditSimulated = () => {
    setshowEditSimulated(false)
  }

  const handleOpenDialogDelete = () => {
    setshowDialogDelete(true)
  }

  const handleCloseDialogDelete = () => {
    setshowDialogDelete(false)
  }

  const handleCheckAll = (event) => {
    event.stopPropagation()

    const currentPageRows = rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )

    if (event.target.checked) {
      const newSelected = currentPageRows.map((row) => row.id)
      setSelected(newSelected)
    } else {
      setSelected([])
    }
  }

  const handleClick = (event, id) => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.includes(id)

      if (isSelected) {
        return prevSelected.filter((selectedId) => selectedId !== id)
      }
      return [...prevSelected, id]
    })
  }

  const resetSelected = () => {
    setSelected([])
  }

  const handleCellClick = async (row) => {
    await setAllPractice(row)
  }

  const redirect = () => {
    history.push(`/SimulatedActivity`)
  }

  const dualfunction = (row, title) => {
    handleCellClick(row)
    setSelectedSimulado(title)
    redirect()
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  useEffect(() => {
    const currentPageRows = rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    const isAllSelected = currentPageRows.length === selected.length

    setChecked(isAllSelected)
  }, [selected, rows, page, rowsPerPage])

  useEffect(() => {
    setSelected([])
  }, [page, rowsPerPage, rows, page, rowsPerPage])

  const pageRows = rows
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : []

  const fetchData = async () => {
    try {
      const response = await IntegrationsService.createAllPracticeTest()
      const jsonData = await response.json()
      console.log('Simulated Books Data:', jsonData)
      setRows(jsonData)
      setChecked(false)
    } catch (error) {
      console.error('Erro ao buscar dados do backend:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSwitchChange = async (event, row) => {
    const updatedRows = rows.map((rowData) => {
      if (rowData.id === row.id) {
        return { ...rowData, status: event.target.checked }
      }
      return rowData
    })

    const updatedRow = updatedRows.find((rowData) => rowData.id === row.id)

    const payload = {
      title: updatedRow.title,
      cae_ids: updatedRow.cae_ids,
      books_name: updatedRow.books_name,
      status: updatedRow.status
    }

    try {
      await IntegrationsService.enablingSimulated(payload, row.id)

      setRows(updatedRows)
    } catch (error) {
      console.error('Erro ao atualizar o status do simulado:', error)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Box>
        <Typography className={classes.text} variant="body1" color="primary">
          SIMULADO DOS LIVROS
          <div className={classes.btnContainer}>
            {selected.length > 0 && (
              <div>
                <Button
                  className={classes.btnEdit}
                  size="small"
                  variant="outlined"
                  startIcon={<Create />}
                  onClick={handleOpenEditSimulated}
                  disabled={selected.length > 1}
                >
                  Editar Simulado
                </Button>
                <Button
                  className={classes.btnDelete}
                  size="small"
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={() => handleOpenDialogDelete()}
                >
                  Excluir
                </Button>
              </div>
            )}
          </div>
        </Typography>
        <Divider />
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleCheckAll}
              />
            </TableCell>
            <TableCell className={classes.headerCell}>
              Título do simulado
            </TableCell>
            <TableCell className={classes.headerCell}>Livro</TableCell>
            <TableCell className={classes.headerCell}>CAE vinculado</TableCell>
            <TableCell className={classes.headerCell}>Status</TableCell>
            <TableCell className={classes.headerCell}>
              <TableSortLabel
                className={classes.headerCell}
                active={orderBy === 'asc' || orderBy === 'desc'}
                direction={orderBy}
                onClick={handleSort}
              >
                Inserido em
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageRows.map((row) => {
            const isItemSelected = isSelected(row.id)
            const labelId = `enhanced-table-checkbox-${row.id}`
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
                style={{ cursor: 'pointer' }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onClick={(event) => handleClick(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell onClick={() => dualfunction(row, row.title)}>
                  {row.title}
                </TableCell>
                <TableCell>{row.books_name}</TableCell>
                <TableCell>{row.cae_ids}</TableCell>
                <TableCell>
                  <Switch
                    color="primary"
                    checked={row.status}
                    onChange={(event) => handleSwitchChange(event, row)}
                  />
                  {row.status ? 'Ativo' : 'Inativo'}
                </TableCell>
                <TableCell>
                  {new Date(row.inserted_at).toLocaleDateString('pt-BR')}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10))
          setPage(0)
        }}
      />

      <EditSimulated
        setshowEditSimulated={handleCloseEditSimulated}
        showEditSimulated={showEditSimulated}
        selectedQuestionIds={selected}
        updateData={fetchData}
      />
      <DeleteSimulated
        setshowDialogDelete={handleCloseDialogDelete}
        showDialogDelete={showDialogDelete}
        selectedSimulatedIds={selected}
        setSelectedSimulatedIds={resetSelected}
        updateData={fetchData}
      />
    </TableContainer>
  )
}

export default TableSimulatedBooks
