import { Box, Collapse } from '@material-ui/core'
import React from 'react'

const Accordion = ({ isOpen, timeout, children }) => {
  const sxStyles = {
    width: '100%',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 32,
    overflow: 'auto',
    padding: '8px 20px 0px 20px',
    '&::-webkit-scrollbar': {
      width: 7,
      backgroundColor: '#F9F9F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.54)',
      borderRadius: 20,
      width: 6
    }
  }

  return (
    <Collapse in={isOpen} timeout={timeout} unmountedOnExit>
      <Box sx={sxStyles}>
        {children}
      </Box>
    </Collapse>
  )
}

export default Accordion
