import React, { useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Tabs } from '@equipe-ninja/saraiva-ui'
import MuiButton from '@material-ui/core/Button'
import useStyles from './styles'
import { DISCOUNT_CAMPAIGN_FIELDS } from '../../../../domain/promotional-content/discounts-campaign'
import { COURSE_SERVICES_FOR_DISCOUNTS_FIELDS } from '../../../../domain/courses-services/course-services-for-discount-fields'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import {
  parseAndValidateImportedCsv,
  discountCampaignValidation,
  discountCampaignValidationFieldValidation,
  filterAndPrepareCampaignData
} from '../../../../domain/promotional-content/discounts-campaign/business'
import { ImportedDiscountsTable } from '../ImportedDiscountsTable'
import { createDiscountCampaign } from '../../../../services/promotional-content/campaign-discounts'
import { CampaignForm } from '../CampaignForm'

const { CAMPAIGN_NAME, CAMPAIGN_STATUS } = DISCOUNT_CAMPAIGN_FIELDS
const {
  IS_VALID,
  NAME,
  SKU,
  FROM_VALUE,
  TO_VALUE,
  PERCENT_DISCOUNT,
  START_DATE,
  END_DATE,
  NOTE
} = COURSE_SERVICES_FOR_DISCOUNTS_FIELDS
const TABS = {
  CAMPAIGN: 0,
  DATA: 1
}

export const CreateDiscountCampaignDialog = (props) => {
  const { closeHandler, successHandler } = props
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [isFetching, setisFetching] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS.CAMPAIGN)
  const classes = useStyles()
  const uploadInput = useRef(null)
  const [fileState, setFileState] = useState({
    isEmpty: true,
    file: null,
    importedData: [],
    campaignDataAsCsv: '',
    hasInvalidEntries: false
  })
  const [formState, setFormState] = useState({
    [CAMPAIGN_NAME]: '',
    [CAMPAIGN_STATUS]: false
  })
  const [formErrors, setFormErrors] = useState({
    [CAMPAIGN_NAME]: false,
    [CAMPAIGN_STATUS]: false
  })

  const mapImportedData = (data) => {
    return data.map((e) => ({
      [NAME]: e[NAME],
      [SKU]: e[SKU],
      [FROM_VALUE]: e[FROM_VALUE],
      [TO_VALUE]: e[TO_VALUE],
      [PERCENT_DISCOUNT]: e[PERCENT_DISCOUNT],
      [START_DATE]: e[START_DATE],
      [END_DATE]: e[END_DATE],
      [NOTE]: e[NOTE]
    }))
  }

  const getRawTextFromTarget = (target) => {
    return target.files[0].text()
  }

  const onChangeFileInput = (ev) => {
    getRawTextFromTarget(ev.target).then((rawText) => {
      let data = null

      try {
        data = parseAndValidateImportedCsv(rawText)
      } catch (error) {
        setErrorMessage(
          'Não foi possível fazer a leitura do arquivo, tente novamente'
        )
      }

      if (!data) return
      setFileState((prev) => ({
        ...prev,
        file: ev.target.files[0],
        isEmpty: false,
        importedData: mapImportedData(data),
        campaignDataAsCsv: filterAndPrepareCampaignData(data),
        hasInvalidEntries: data.some((e) => !e[IS_VALID])
      }))
    })
  }

  const onChange = (ev, field) => {
    const error = discountCampaignValidationFieldValidation(
      field,
      ev.target.value
    )

    setFormState((prev) => ({ ...prev, [field]: ev.target.value }))

    setFormErrors((prev) => ({ ...prev, ...error }))
  }

  const onClose = () => {
    setFileState((prev) => ({
      ...prev,
      file: null,
      isEmpty: true,
      importedData: [],
      campaignDataAsCsv: '',
      hasInvalidEntries: false
    }))

    uploadInput.current.value = null

    setFormState((prev) => ({
      ...prev,
      [CAMPAIGN_NAME]: '',
      [CAMPAIGN_STATUS]: false
    }))

    setFormErrors((prev) => ({
      ...prev,
      [CAMPAIGN_NAME]: false,
      [CAMPAIGN_STATUS]: false
    }))

    closeHandler()
  }

  const createCampaign = () => {
    setisFetching(true)
    createDiscountCampaign(formState, fileState.campaignDataAsCsv)
      .then(() => {
        setSuccessMessage('Campanha criada com sucesso.')
        onClose()
        successHandler()
      })
      .catch(() => {
        setErrorMessage('Não foi possível criar a campanha tente novamente')
      })
      .finally(() => {
        setisFetching(false)
      })
  }

  const onSubmit = () => {
    if (fileState.hasInvalidEntries) {
      setActiveTab(TABS.DATA)
      setErrorMessage(
        'Não foi possível criar a campanha, verifique as inconsistências e tente fazer o upload novamente'
      )

      return
    }

    const { isValid, errors } = discountCampaignValidation(formState)

    if (!isValid) {
      setFormErrors(errors)
      setActiveTab(TABS.CAMPAIGN)
      setErrorMessage(
        'Não foi possível criar a campanha verifique os campos em vermelho'
      )
      return
    }

    createCampaign()
  }

  const onChangeTab = (tab) => {
    setActiveTab(tab)
  }

  return (
    <>
      <label htmlFor="upload-dados-nova-campanha">
        <input
          ref={uploadInput}
          accept="text/csv"
          className={classes.input}
          id="upload-dados-nova-campanha"
          type="file"
          onChange={onChangeFileInput}
        />
        <MuiButton variant="contained" color="primary" component="span">
          Criar Campanha
        </MuiButton>
      </label>

      <Dialog
        open={!fileState.isEmpty}
        title="Criar campanha"
        size="lg"
        secondaryButton="cancelar"
        primaryButton="salvar"
        handleClose={onClose}
        handleConfirm={() => onSubmit()}
        isDisabled={isFetching}
      >
        <div className={classes.tabsContainer}>
          <Tabs
            screenSize="desktop"
            color="primary"
            showDivider
            selectedTabIndex={activeTab}
            onChange={onChangeTab}
          >
            {[
              {
                label: 'Campanha',
                content: (
                  <CampaignForm
                    onChange={onChange}
                    formState={formState}
                    formErrors={formErrors}
                  />
                )
              },
              {
                label: 'Dados',
                content: (
                  <ImportedDiscountsTable data={fileState.importedData} />
                )
              }
            ]}
          </Tabs>
        </div>
      </Dialog>
    </>
  )
}

CreateDiscountCampaignDialog.propTypes = {
  closeHandler: PropTypes.func,
  successHandler: PropTypes.func
}

CreateDiscountCampaignDialog.defaultProps = {
  closeHandler: () => null,
  successHandler: () => null
}
