import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from '../styles'

export function DetailsTabContent({ data }) {
  const styles = useStyles()

  return (
    <div className={styles.detailsContainer}>
      <div className="info-group">
        <Typography variant="h6" color="textSecondary">
          Nome da disciplina
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {data.disciplineTitle}
        </Typography>
      </div>
      <div className="info-group">
        <Typography variant="h6" color="textSecondary">
          Curso atual
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {data.currentCourse}
        </Typography>
      </div>
      <div className="info-group">
        <Typography variant="h6" color="textSecondary">
          Curso de origem
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {data.originCourse}
        </Typography>
      </div>
    </div>
  )
}
