import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 72,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  input: {
    marginBottom: '24px'
  },
  btnRegister: {
    margin: '24px 8px 20px 0',
    height: '42px'
  },
  textH4: {
    fontSize: '34px',
    color: theme.palette.text.primary,
    marginTop: 40
  },
  titleH3: {
    marginBottom: '24px',
    color: theme.palette.text.primary,
    marginTop: '22px',
    fontSize: '48px'
  },
  line: {
    width: '100%',
    height: '1px',
    margin: '21px 0px 24px 0px',
    backgroundColor: theme.palette.divider
  }
}))

export default useStyles
