import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14
  },
  legend: {
    fontSize: 16,
    color: theme.palette.text.primary
  },
  checkboxWrapper: {
    marginBottom: -5,
    marginLeft: 0
  },
  checkboxLabel: {
    color: theme.palette.text.primary
  }
}))

export default useStyles
