const CODE = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_ERROR: 500
}

/**
 * Verifica o status code de uma chamada do tipo get ao servidor, caso o status nao seja adequado
 * levantara um erro
 *
 * @param {*} response - Resposta de uma chamada ao servidor remoto
 * @returns caso o status seja correto, retorna a chamada do response.json(), que e uma promise
 */
export const getResquestResponseHandler = (response) => {
  switch (response.status) {
    case CODE.OK:
      return response.json()
    default:
      throw new Error('unexpected response code for get request')
  }
}

/**
 * Verifica o status code de uma chamada do tipo post ao servidor, caso o status nao seja adequado
 * levantara um erro
 *
 * @param {*} response - Resposta de uma chamada ao servidor remoto
 * @returns caso o status seja correto, retorna a chamada do response.json(), que e uma promise
 */
export const postResquestResponseHandler = (response) => {
  switch (response.status) {
    case CODE.CREATED:
      return response.json()
    default:
      throw new Error('unexpected response code for post request')
  }
}

/**
 * Verifica o status code de uma chamada do tipo delete ao servidor, caso o status nao seja adequado
 * levantara um erro
 *
 * @param {*} response - Resposta de uma chamada ao servidor remoto
 * @returns caso o status seja correto retorna null
 */
export const deleteRequestResponseHandler = (response) => {
  switch (response.status) {
    case CODE.NO_CONTENT:
      return null
    default:
      throw new Error('unexpected response code for delete request')
  }
}

/**
 * Verifica o status code de uma chamada do tipo put ao servidor, caso o status nao seja adequado
 * levantara um erro
 *
 * @param {*} response - Resposta de uma chamada ao servidor remoto
 * @returns caso o status seja correto retorna null
 */
export const putRequestResponseHandler = (response) => {
  switch (response.status) {
    case CODE.OK:
      return response.json()
    default:
      throw new Error('unexpected response code for put request')
  }
}
