import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Button } from '@equipe-ninja/saraiva-ui'
import Grid from '@material-ui/core/Grid'
import cls from 'classnames'
import ContentTitle from '../ContentTitle'
import useClasses from './styles'

export default function ContentWithHeader({
  title,
  children,
  backButtonRoute,
  titleSize,
  styleTitle,
  backButtonText,
  className
}) {
  const classes = useClasses()
  const history = useHistory()

  const backButtonClickHandler = () => history.push(backButtonRoute)

  return (
    <Grid className={cls(classes.root, className)}>
      {backButtonRoute && (
        <Button
          className={classes.backButton}
          color="primary"
          onClick={backButtonClickHandler}
        >
          <ChevronLeftIcon />
          <span style={{ marginLeft: '8px' }}>{backButtonText}</span>
        </Button>
      )}

      <ContentTitle style={styleTitle} title={title} size={titleSize} />

      {children}
    </Grid>
  )
}

ContentWithHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  /**
   * Quando presente ira exibir um botao `VOLTAR` para a rota recebida
   *
   * ### Examplo
   * ```jsx
   * <ContentWithHeader
   *  title="Titulo Exemplo"
   *  backButtonRoute={RoutesMapping.promotionalPageEntryPotint}
   * >
   *  <p>Foo Bar</p>
   * </ContentWithHeader>
   * ```
   */
  backButtonRoute: PropTypes.string,
  /**
   *  tamanho da fonte do titulo
   * */
  titleSize: PropTypes.string,
  /**
   * Texto que sera apresentado no botão voltar
   */
  backButtonText: PropTypes.string,
  /**
   * usado para customizar o container
   */
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  styleTitle: PropTypes.objectOf(PropTypes.any)
  /**
   *  style aplicado no titulo da pagina
   * */
}

ContentWithHeader.defaultProps = {
  children: null,
  backButtonRoute: null,
  titleSize: 'large',
  backButtonText: 'VOLTAR',
  className: '',
  styleTitle: {},
  title: ''
}
