/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ContentWithHeader from '../../components/ContentWithHeader'
import ProductSelect from '../../components/ProductSelect'
import { GeneralContext } from '../../contexts/GeneralContext'
import { BRANDS } from '../../domain/brands/brands'
import { PRODUCT_SELECTION_OPTIONS } from '../../domain/general/product-selection-options'
import { UserList } from './UsersList'
import useStyles from './styles'
import { SsoUserSelect } from './SsoUserSelect'

export function UserManagemant() {
  const classes = useStyles()
  const { productSelected, setProduct } = useContext(GeneralContext)
  const { brand } = useParams()

  async function saveSelectedProduct(product) {
    setProduct(product)
  }

  useEffect(() => {
    if (brand) {
      const productList = PRODUCT_SELECTION_OPTIONS.find(
        (p) => p.value === brand
      )
      setProduct(productList)
    } else {
      setProduct({})
    }
  }, [brand, setProduct])

  return (
    <ContentWithHeader title="Gerenciar usuários">
      <ProductSelect
        onChange={saveSelectedProduct}
        productsOptions={[BRANDS.SSO.value, BRANDS.LFG.value]}
      />
      {productSelected.value === BRANDS.LFG.value && (
        <UserList brand={productSelected.value} />
      )}
      {productSelected.value === BRANDS.SSO.value && (
        <SsoUserSelect brand={productSelected.value} />
      )}
    </ContentWithHeader>
  )
}
