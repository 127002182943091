import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import { DISCOUNT_DETAILS_ITEM_FIELDS } from '../../../../../../domain/promotional-content/discounts-campaign'
import { exportDataToCsv } from '../../../../../../helpers/exporting'
import { useDownloadFile } from '../../../../../../hooks/use-download-file'

const { NAME, SKU, START_DATE, END_DATE, FROM_VALUE, TO_VALUE } =
  DISCOUNT_DETAILS_ITEM_FIELDS

const headersLabels = {
  [NAME]: 'Nome',
  [SKU]: 'SKU',
  [START_DATE]: 'Data início',
  [END_DATE]: 'Data fim',
  [FROM_VALUE]: 'Preço de',
  [TO_VALUE]: 'Preço por'
}

export const CampaignDiscountList = (props) => {
  const { data } = props
  const classes = useStyles()
  const [selectedRows, setselectedRows] = useState([])
  const { downloadFile } = useDownloadFile()

  const headersCells = () => {
    return [
      headersLabels[NAME],
      { name: headersLabels[SKU], order: true, code: SKU },
      headersLabels[START_DATE],
      headersLabels[END_DATE],
      headersLabels[FROM_VALUE],
      headersLabels[TO_VALUE]
    ]
  }

  const exportSelectedRows = () => {
    if (selectedRows.length === 0) return

    const selectedDataAsCsv = exportDataToCsv(selectedRows, headersLabels)

    downloadFile(selectedDataAsCsv, 'descontos.csv')
  }

  return (
    <section>
      <div className={classes.actions}>
        <Button
          className={classes.marginTop16}
          color="primary"
          variant="contained"
          disabled={selectedRows.length === 0}
          onClick={exportSelectedRows}
        >
          Exportar
        </Button>
      </div>

      <div className={classes.marginTop16}>
        <Table
          cells={headersCells()}
          rows={data}
          displayRowCheckbox
          selectedRows={selectedRows}
          onCheckRows={(rows) => setselectedRows(rows)}
        />
      </div>
    </section>
  )
}

CampaignDiscountList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      [SKU]: PropTypes.string,
      [START_DATE]: PropTypes.string,
      [END_DATE]: PropTypes.string,
      [FROM_VALUE]: PropTypes.string,
      [TO_VALUE]: PropTypes.string
    })
  ).isRequired
}

CampaignDiscountList.defaultProps = {}
