/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { Button, Tabs } from '@equipe-ninja/saraiva-ui'
import {
  Box,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper
} from '@material-ui/core'

import { ChevronRight, Delete, FormatListBulleted } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import useStyles from './style'

import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import ContentWithHeader from '../../../components/ContentWithHeader'
import ProductSelect from '../../../components/ProductSelect'
import { CourseList } from '../CoursesList'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { PRODUCT_SELECTION_OPTIONS } from '../../../domain/general/product-selection-options'
import { BRANDS } from '../../../domain/brands'
import { QuestionBank } from '../../../components/QuestionBank'
import { DeleteBankQuestion } from '../../../components/DeleteBankQuestion'

export function SimulatedActivity() {
  const classes = useStyles()
  const history = useHistory()
  const [showQuestionBankDialog, setShowQuestionBankDialog] = useState(false)
  const [showDialogDelete, setshowDialogDelete] = useState(false)
  const [selected, setSelected] = useState([])
  const [checkedAll, setCheckedAll] = useState(false) // Estado para o checkbox principal
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([])
  const [mainCheckboxChecked, setMainCheckboxChecked] = useState(false)
  const [isCheckedAll, setIsCheckedAll] = useState(false)

  const [rows, setRows] = useState(() => {
    //  recupera as rows do session
    const storedRows = sessionStorage.getItem('filteredRows')
    return storedRows ? JSON.parse(storedRows) : []
  })

  const {
    selectedQuestion,
    selectedSimulado,
    setSelectedSimulado,
    productSelected,
    setProduct,
    allPractice,
    setQuestions
  } = useContext(GeneralContext)

  const handleOpenQuestionBankDialog = () => {
    setShowQuestionBankDialog(true)
  }

  const handleCloseQuestionBankDialog = () => {
    setShowQuestionBankDialog(false)
  }
  const handleOpenDialogDelete = () => {
    setshowDialogDelete(true)
  }

  const handleCloseDialogDelete = () => {
    setshowDialogDelete(false)
  }

  const handleSimulate = () => {
    window.location.href = '/curso'
  }

  async function saveSelectedProduct(product) {
    setProduct(product)
  }

  useEffect(() => {
    const productList = PRODUCT_SELECTION_OPTIONS.find(
      (p) => p.value === BRANDS.CONECTA.value
    )
    if (!productSelected.value) {
      setProduct(productList)
    }
  }, [productSelected, setProduct])

  const handleCheckAll = (event) => {
    const isChecked = event.target.checked
    setIsCheckedAll(isChecked)

    const newSelected = isChecked ? paginatedData.map((row) => row.id) : []
    setSelected(newSelected)
    setSelectedQuestionIds(newSelected)
  }

  const checkQuestion = (event, id) => {
    let questionListUpdated = [...selected]
    if (event.target.checked) {
      questionListUpdated = [...questionListUpdated, id]
    } else {
      questionListUpdated.splice(questionListUpdated.indexOf(id), 1)
    }
    setSelected(questionListUpdated)
    setSelectedQuestionIds(questionListUpdated) // Atualiza o estado dos checkboxes individuais
  }

  const isSelected = (id) =>
    selected.includes(id) || selectedQuestionIds.includes(id)

  useEffect(() => {
    const isAllSelected = [...rows, ...selectedQuestion].every((row) =>
      selected.includes(row.id)
    )
    setCheckedAll(isAllSelected)
  }, [selected, rows, selectedQuestion])

  const fetchData = async () => {
    try {
      const filteredPractice = (allPractice?.practice_questions || []).filter(
        (objeto) => objeto.status !== 'not_found'
      )

      setRows(filteredPractice)
      setQuestions(filteredPractice)
      console.log('filteredPractice', filteredPractice)
      setCheckedAll(false)
      // Armazena as rows filtradas no session
      sessionStorage.setItem('filteredRows', JSON.stringify(filteredPractice))
    } catch (error) {
      console.error('Erro ao buscar dados do backend:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [allPractice])

  useEffect(() => {
    const selectedSimuladoFromStorage =
      sessionStorage.getItem('selectedSimulado')
    if (selectedSimuladoFromStorage) {
      const parsedSelectedSimulado = JSON.parse(selectedSimuladoFromStorage)
      setSelectedSimulado(parsedSelectedSimulado)
    }
  }, [setSelectedSimulado])
  // Combine os dados das rows e selectedQuestion em um único array
  const combinedData = [
    ...rows,
    ...selectedQuestion.filter(
      (question) => !rows.find((row) => row.id === question.id)
    )
  ]

  const sortedCombinedData = combinedData.sort((a, b) => a.id - b.id)
  const startRow = page * rowsPerPage
  const endRow = startRow + rowsPerPage
  const paginatedData = combinedData.slice(startRow, endRow)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsCheckedAll(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className={classes.content}>
      <ContentWithHeader title="Recursos das plataformas">
        <ProductSelect
          onChange={saveSelectedProduct}
          productsOptions={[BRANDS.LFG.value, BRANDS.CONECTA.value]}
        />

        {productSelected.value === BRANDS.LFG.value && (
          <CourseList brand={productSelected.value} />
        )}
        {productSelected.value === BRANDS.CONECTA.value && (
          <>
            <Typography variant="h4" className={classes.title}>
              Conecta
            </Typography>
            <Tabs
              className={classes.root}
              screenSize="desktop"
              color="primary"
              mode="grow"
              showDivider
            >
              {[
                {
                  label: 'SIMULADO DOS LIVROS',
                  content: (
                    <>
                      <div className={classes.button}>
                        <Button
                          color="primary"
                          startIcon={<FormatListBulleted />}
                          variant="outlined"
                          onClick={() => handleOpenQuestionBankDialog()}
                        >
                          BANCO DE QUESTÕES
                        </Button>
                      </div>

                      <TableContainer component={Paper}>
                        <Box className={classes.tableContainer}>
                          <Button
                            className={classes.btn}
                            variant="body1"
                            color="primary"
                            onClick={() => handleSimulate()}
                          >
                            SIMULADO DOS LIVROS
                          </Button>

                          <ChevronRight className={classes.icon} />
                          <div className={classes.text}>{selectedSimulado}</div>
                          {/* <div className={classes.btnContainer}>
                            {selected.length > 0 && (
                              <Button
                                className={classes.btnDelete}
                                variant="outlined"
                                startIcon={<Delete />}
                                onClick={() => handleOpenDialogDelete()}
                              >
                                Excluir
                              </Button>
                            )}
                          </div> */}
                          <Divider />
                        </Box>
                        <Divider />
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isCheckedAll}
                                  onChange={handleCheckAll}
                                />
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Código
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Área
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Disciplina
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Tema
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Objetivo
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Fonte
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Formato
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                Ano
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedData.map((row) => (
                              <TableRow
                                key={row.id}
                                selected={isSelected(row.id)}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    onClick={(event) =>
                                      checkQuestion(event, row.id)
                                    }
                                    checked={isSelected(row.id)}
                                    inputProps={{
                                      'aria-labelledby': `enhanced-table-checkbox-${row.id}`
                                    }}
                                  />
                                </TableCell>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.knowledge_area}</TableCell>
                                <TableCell>{row.subject}</TableCell>
                                <TableCell>{row.theme}</TableCell>
                                <TableCell>{row.goal}</TableCell>
                                <TableCell>{row.question_source}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.year}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={combinedData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </>
                  )
                }
              ]}
            </Tabs>
          </>
        )}
      </ContentWithHeader>
      <div className={classes.rot}>
        <SnackbarOutlet />
      </div>
      <QuestionBank
        setShowQuestionBankDialog={handleCloseQuestionBankDialog}
        showQuestionBankDialog={showQuestionBankDialog}
      />

      <DeleteBankQuestion
        setshowDialogDelete={handleCloseDialogDelete}
        showDialogDelete={showDialogDelete}
      />
    </div>
  )
}
