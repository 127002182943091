import axios from 'axios'

const httpRequest = (baseURL = process.env.REACT_APP_FUSION_ORIGIN) => {
  const baseConfig = (headers) =>
    axios.create({
      baseURL,
      headers
    })

  const get = (path, headers) => baseConfig(headers).get(path)

  const post = (path, data, headers) => baseConfig(headers).post(path, data)

  const deleteRequest = (path, headers) => baseConfig(headers).delete(path)

  const put = (path, data, headers) => baseConfig(headers).put(path, data)

  const patch = (path, data, headers) => baseConfig(headers).patch(path, data)

  return {
    delete: deleteRequest,
    get,
    post,
    put,
    patch
  }
}

export default httpRequest
