import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    paddingBottom: 42,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  searchBar: {
    marginBottom: 32
  },
  text: {
    marginTop: '40px',
    marginBottom: '24px',
    color: theme.palette.text.primary
  },
  textH3: {
    marginTop: '22px',
    fontSize: '48px'
  },
  divider: {
    margin: '25px 0px 64px 0px'
  },
  container: {
    width: `auto`,
    marginLeft: `8vw`,
    marginRight: `8vw`
  }
}))

export default useStyles
