import React from 'react'
import { Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { PrivateRoute } from '../components/PrivateRoute'
import { RoutesMapping } from '../helpers/routes-mapping'
import { CourseCreate } from '../pages/Courses/CourseCreate'
import { Courses } from '../pages/Courses'
import { CourseProductEdit } from '../pages/academic/CourseProductEdit'
import { RegisterElearningProvider } from '../contexts/RegisterElearningContext'
import { RegisterElearning } from '../pages/CourseManagement/RegisterElearning'
import { ComplementaryMaterialDetails } from '../pages/CourseManagement/RegisterElearning/ComplementaryMaterialDetails'
import { SubjectDetails } from '../pages/Courses/SubjectDetails'

export function CoursesRoutes() {
  return (
    <RecoilRoot>
      <RegisterElearningProvider>
        <Switch>
          <PrivateRoute
            defaultLayout
            exact
            path={RoutesMapping.courses}
            component={Courses}
          />

          <PrivateRoute
            exact
            path={RoutesMapping.courseCreate}
            component={CourseCreate}
            defaultLayout
          />

          <PrivateRoute
            component={CourseProductEdit}
            defaultLayout
            exact
            path={RoutesMapping.registerEcommerce}
          />

          <PrivateRoute
            component={RegisterElearning}
            defaultLayout
            exact
            path={RoutesMapping.registerElearning}
          />

          <PrivateRoute
            component={ComplementaryMaterialDetails}
            defaultLayout
            exact
            path={RoutesMapping.complementaryMaterialDetails}
          />

          <PrivateRoute
            defaultLayout
            exact
            path={RoutesMapping.subjectDetails}
            component={SubjectDetails}
          />
        </Switch>
      </RegisterElearningProvider>
    </RecoilRoot>
  )
}
