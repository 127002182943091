import { makeStyles } from '@material-ui/core/styles'
import { spacing, pageClasses } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...pageClasses(theme),
  vouchersTable: {
    '& > .MuiTableContainer-root': {
      margin: '0 -24px -24px',
      width: 'calc(100% + 48px)',
      boxShadow: 'none'
    }
  },
  divider: {
    margin: '16px -24px 0'
  }
}))

export default useStyles
