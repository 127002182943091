import { Button } from '@equipe-ninja/saraiva-ui'
import React, { useState, useContext, useEffect } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { initialURLValueForBrand } from '../../../../domain/brands'
import { createBanner } from '../../../../services/promotional-content/banners-pages'
import useStyles from '../styles'
import {
  bannerRotationValidation,
  bannerRotationFieldValidation
} from '../../../../domain/promotional-content/banners/business/banners-ratativo-validation'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import { FormBannerSale } from '../Form/FormBannerSale'

const {
  KEY,
  TITLE,
  URL,
  SESSION,
  ORDER,
  IMG_WEB,
  IMG_RECURSIVE,
  START_DATE,
  END_DATE,
  STATUS
} = FIELDS

export function DuplicarBannerSale({ state }) {
  const classes = useStyles()
  const history = useHistory()
  const [isExecutingTask, setIsExecutingTask] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalBannerList,
    hasAccessToPromotionalPages
  )

  const [formState, setFormState] = useState({
    type: 'sale',
    [KEY.value]: null,
    [TITLE.value]: 'Banner ofertas em destaque',
    [URL[0].value]: URL.map(() => initialURLValueForBrand(brand)),
    [SESSION.value]: 2,
    [ORDER[0].value]: ORDER.map((_, i) => (i + 1).toString()),
    [IMG_WEB.value]: '',
    [IMG_RECURSIVE[0].value]: IMG_RECURSIVE.map(() => ''),
    [END_DATE.value]: null,
    [START_DATE.value]: null,
    [STATUS.value]: ''
  })

  const [formErrors, setFormErrors] = useState({
    type: 'sale',
    [KEY.value]: '',
    [TITLE.value]: '',
    [URL[0].value]: URL.map(() => ''),
    [SESSION.value]: 2,
    [ORDER[0].value]: ORDER.map(() => ''),
    [IMG_WEB.value]: null,
    [IMG_RECURSIVE[0].value]: IMG_RECURSIVE.map(() => null),
    [START_DATE.value]: '',
    [END_DATE.value]: '',
    [STATUS.value]: ''
  })

  const onChangeOrder = (newValue, itemIndex) => {
    const newValueNumber = parseInt(newValue, 10)
    const currentOrderValue = formState[ORDER[0].value][itemIndex]
    const currentHolderIndexForNewValue = formState[ORDER[0].value].reduce(
      (prev, current, index) => {
        if (prev) return prev
        return current === newValueNumber ? index : prev
      },
      null
    )

    setFormState((prevState) => ({
      ...prevState,
      [ORDER[0].value]: prevState[ORDER[0].value].map((item, index) => {
        if (index === itemIndex) return newValueNumber
        if (index === currentHolderIndexForNewValue) return currentOrderValue
        return item
      })
    }))
  }

  const onChangeHandler = (field, dateValue, sequence) => {
    if (isNumber(sequence)) {
      if (field === ORDER[0].value) {
        onChangeOrder(dateValue, sequence)
      } else {
        const newState = formState[field].map((e, i) => {
          return i === sequence ? dateValue : e
        })

        setFormState((prev) => ({ ...prev, [field]: newState }))
      }
    } else {
      const fieldValidation = bannerRotationFieldValidation(
        field,
        dateValue,
        formState,
        'create'
      )
      setFormState({ ...formState, [field]: dateValue })
      setFormErrors((prev) => ({ ...prev, ...fieldValidation }))
    }
  }

  const onCreate = () => {
    createBanner(formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage(
          `Banner '${response[TITLE.value]}' criado com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const hasFormValidation = () => {
    const { isValid, errors } = bannerRotationValidation(formState)

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }
    onCreate()
  }

  useEffect(() => {
    if (state) {
      setFormState(state)
    }
  }, [state])

  return (
    <>
      {URL.map((url_, index) => (
        <FormBannerSale
          formState={formState}
          formErrors={formErrors}
          onChange={onChangeHandler}
          sequence={index}
        />
      ))}
      <div className={classes.footerUpdate} style={{ marginBottom: 178 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={hasFormValidation}
          disabled={isExecutingTask}
        >
          SALVAR
        </Button>
      </div>
      <SnackbarOutlet />
    </>
  )
}

DuplicarBannerSale.propTypes = {
  state: PropTypes.objectOf.isRequired
}
