import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { putRequestResponseHandler } from '../../../utils/response-handlers'
import { TOPIC_FIELD as F } from '../../remote-fields/topic-fields'

const outboundAdapter = (name) => ({
  [F.TITLE]: name
})

/**
 * Caso de uso para marcar ou desmarcar um curso como 100% postado
 * @param {string|number} topic - identificador tópico que terá sua propriedade alterada
 * @param {boolean} name - status para qual o acesso ao curso vai ser modificado
 */
export const topicRenamed = (topicId, name, courseId) => {
  const payload = outboundAdapter(name)

  const url = `/offers/api/v1/topic/${topicId}?course_id=${courseId}`

  return new Promise((resolve, reject) => {
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpClient
      .patch(url, payload, headers)
      .then((response) => putRequestResponseHandler(response))
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
}
