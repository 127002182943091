import { CARD_COMPLEMENTARY_MATERIAL_FIELDS } from '../../../domain/complementary-material/card-complementary-material-fields'
import { COMPLEMENTARY_MATERIAL_REMOTE_FIELDS } from '../remote-fields/complementary-material-remote-fields'

const inbound = (remoteData) => {
  return {
    [CARD_COMPLEMENTARY_MATERIAL_FIELDS.ID]:
      remoteData[COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.ID],
    [CARD_COMPLEMENTARY_MATERIAL_FIELDS.NAME]:
      remoteData[COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.NAME],
    [CARD_COMPLEMENTARY_MATERIAL_FIELDS.FULL_DESCRIPTION]:
      remoteData[COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.DESCRIPTION],
    [CARD_COMPLEMENTARY_MATERIAL_FIELDS.SHORT_DESCRIPTION]:
      remoteData[COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.SUMMARY],
    [CARD_COMPLEMENTARY_MATERIAL_FIELDS.ORDER]:
      remoteData[COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.ORDER],
    [CARD_COMPLEMENTARY_MATERIAL_FIELDS.COURSE_ID]:
      remoteData[COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.COURSE_ID]
  }
}

const outbound = (remoteData) => {
  return {
    [COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.NAME]:
      remoteData[CARD_COMPLEMENTARY_MATERIAL_FIELDS.NAME],
    [COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.COURSE_ID]:
      remoteData[CARD_COMPLEMENTARY_MATERIAL_FIELDS.COURSE_ID],
    [COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.DESCRIPTION]:
      remoteData[CARD_COMPLEMENTARY_MATERIAL_FIELDS.FULL_DESCRIPTION],
    [COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.ORDER]:
      remoteData[CARD_COMPLEMENTARY_MATERIAL_FIELDS.ORDER],
    [COMPLEMENTARY_MATERIAL_REMOTE_FIELDS.SUMMARY]:
      remoteData[CARD_COMPLEMENTARY_MATERIAL_FIELDS.SHORT_DESCRIPTION]
  }
}

const listInbound = (remoteDataList) => {
  return remoteDataList.map((remoteData) => inbound(remoteData))
}

export const CardComplementaryMaterialAdapter = {
  listInbound,
  inbound,
  outbound
}
