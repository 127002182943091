/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState } from 'react'

import { UploadBar, Button as ButtonSaraiva } from '@equipe-ninja/saraiva-ui'
import { Delete, Attachment } from '@material-ui/icons'
import { Button } from '@material-ui/core'

import PropTypes from 'prop-types'
import { UploadContext } from '../../../../contexts/UploadContext'

import useStyles from './styles'

export function ButtonUpload(props) {
  const {
    field,
    image,
    type,
    onUpload,
    onDelete,
    sequence,
    className,
    btnLabel
  } = props

  const {
    showingUploadBar,
    countFileUpload,
    totalFiles,
    errorsBar,
    reloadFiles,
    filesError,
    clearState
  } = useContext(UploadContext)

  const [imageRead, setImageRead] = useState()

  const classes = useStyles()

  const handleCloseUploadBar = () => {
    clearState()
  }
  const handleCancelUploadBar = () => {
    clearState()
  }

  const handleReloadFiles = () => {
    reloadFiles(filesError)
  }

  const readImage = () => {
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onloadend = (e) => {
      setImageRead(e.target.result)
    }
  }

  useEffect(() => {
    if (image) {
      if (image instanceof File) {
        return readImage()
      }
      return setImageRead(image)
    }
  }, [image])

  return !image ? (
    <div className={className}>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        id={`contained-button-file-${type}-${sequence}`}
        onChange={(e) => onUpload(field, e.target.files[0], type)}
      />
      <label htmlFor={`contained-button-file-${type}-${sequence}`}>
        <Button color="secondary" variant="outlined" component="span">
          {btnLabel} {type}
          <Attachment />
        </Button>
      </label>
      {showingUploadBar ? (
        <UploadBar
          progressFileUpload={countFileUpload}
          totalUploadFiles={totalFiles}
          errorsList={errorsBar}
          handleCancelUpload={handleCancelUploadBar}
          handleCloseUpload={handleCloseUploadBar}
          reloadErrorsUpload={handleReloadFiles}
        />
      ) : (
        <div />
      )}
    </div>
  ) : (
    <div className={`${className} ${classes.divImageUploadPreview}`}>
      <img src={imageRead} alt={`${image?.name}`} width="200" height="50" />
      <span>{image?.name}</span>
      <div className={classes.boxDelete}>
        <ButtonSaraiva
          color="secondary"
          onClick={() => onDelete(field, type)}
          startIcon={<Delete />}
          className={classes.footerUpdateDelete}
        >
          EXCLUIR
        </ButtonSaraiva>
      </div>
    </div>
  )
}

ButtonUpload.propTypes = {
  field: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  sequence: PropTypes.number,
  className: PropTypes.string,
  btnLabel: PropTypes.string
}

ButtonUpload.defaultProps = {
  image: null,
  sequence: 0,
  className: '',
  btnLabel: 'ANEXAR BANNER'
}
