import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { PromoPageDetailsAdapter } from '../adapters/promo-page-details-adapter'
import { putRequestResponseHandler } from '../../../utils/response-handlers'

/**
 * Caso de uso para edicao de paginas promocionais
 * @returns promive com os valores atualizados ou rejeita com erro
 */
const updatePromotionalPage = (id, formValues) => {
  return new Promise((resolve, reject) => {
    const path = `markets/api/v1/pages/${id}`
    const payload = PromoPageDetailsAdapter.outbound(formValues)
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .put(path, payload, headers)
      .then((response) => putRequestResponseHandler(response))
      .then((json) => {
        resolve(PromoPageDetailsAdapter.inbound(json.data))
      })

      .catch((error) => reject(error))
  })
}

export { updatePromotionalPage }
