import React, { useContext, useEffect, useRef, useState } from 'react'
import Folder from '@material-ui/icons/Folder'
import IconButton from '@material-ui/core/IconButton'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@equipe-ninja/saraiva-ui'
import Accordion from '../Accordion/Accordion'
import contentsService from '../../services/contentsService'
import FolderBreadcrumb from './folderBreadcrumb'
import ShowH5p from './showH5p'
import { ContentsContext } from '../../contexts/ContentsContext'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  table: {
    padding: 0
  },
  header: {
    height: 56
  },
  row: {
    height: 52
  },
  rowHidden: {
    height: 0,
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 0.5s linear 0.3s',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  rowShow: {
    height: 300,
    visibility: 'visible',
    opacity: 1,
    transition: 'all 0.5s linear 0.3s',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  icon: {
    paddingRight: 8
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    position: 'absolute',
    top: 20,
    width: 1
  },
  alignHeader: {
    paddingLeft: 6
  },
  button: {
    height: 42
  },
  cellButton: {
    paddingTop: 2,
    paddingLeft: 22,
    paddingBottom: 2
  },
  cell: {
    color: theme.palette.text.secondary,
    padding: '6px 4px 6px 6px!important'
  },
  accordionDiv: {
    width: '100%'
  },
  flexDiv: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    right: 0,
    maxWidth:"100px"
  },
  previa: {
    color: theme.palette.text.secondary,
    padding: '0!important'
  },
  expanded: {
    animation: '$rotate 800ms ease-in-out',
    transform: 'rotate(-180deg)',
    padding: 0
  },
  notExpanded: {
    animation: '$reverseRotate 800ms ease-in-out',
    transform: 'rotate(0)'
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0)'
    },
    '100%': {
      transform: 'rotate(-180deg)'
    }
  },
  '@keyframes reverseRotate': {
    '0%': {
      transform: 'rotate(-180deg)'
    },
    '100%': {
      transform: 'rotate(0)'
    }
  },
  h5p: {
    '&::.h5p-iframe-wrapper iframe.h5p-iframe': {
        width: 1000,
        backgroundColor: 'cyan'
    }
  }
}))
function AddLink(props) {
  const { type, id, name } = props
  const classes = useStyles()
  if (type === 'folder') {
    return (
      <Link href={`/lti/?id_token=&state=&id=${id}`} className={classes.link}>
        <span className={classes.icon}>
          <IconButton
            aria-label="expand row"
            size="small"
            className={classes.link}
          >
            <Folder />
          </IconButton>
        </span>
        {name}
      </Link>
    )
  }
  return (
    <>
      <span className={classes.icon}>
        <IconButton aria-label="expand row" size="small">
          <InsertDriveFile />
        </IconButton>
      </span>
      <span>{name}</span>
    </>
  )
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
function EnhancedTableHead(props) {
  const classes = useStyles()
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow className={classes.header}>
        <TableCell key="previa" align="left" />
        <TableCell
          key="name"
          align="left"
          sortDirection={orderBy === 'name' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
            className={classes.alignHeader}
          >
            Nome
            {orderBy === 'name' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="updated_at"
          align="left"
          sortDirection={orderBy === 'updated_at' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'updated_at'}
            direction={orderBy === 'updated_at' ? order : 'asc'}
            onClick={createSortHandler('updated_at')}
          >
            Última Atualização
            {orderBy === 'updated_at' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="type"
          align="left"
          sortDirection={orderBy === 'type' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'type'}
            direction={orderBy === 'type' ? order : 'asc'}
            onClick={createSortHandler('type')}
          >
            Tipo
            {orderBy === 'type' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}
export default function ListContents({ deepLinkReturnUrl, listItems, path }) {
  const [accordionStates, setAccordionStates] = useState({
    isActive: false,
    id: 0
  })

  const prevIdRef = useRef()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const { previewContent, fetchPreview } = useContext(ContentsContext)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const classes = useStyles()
  
  async function sendTracking(jwt) {
    const payload = {
      event: 'Content_Selected',
      payload: {
        response_token: jwt
      }
    }
    await contentsService().sendTracking(payload)
    document.forms.auto_submit.JWT.value = jwt
    document.forms.auto_submit.submit()
  }
  
  useEffect(() => {
    prevIdRef.current = accordionStates.id
  }, [accordionStates])
  
  const handlerActive = (id) => {
    const option =
      accordionStates.id === 0
        ? 'initial'
        : accordionStates.id === id
        ? 'change'
        : !accordionStates.id ===id
        ? 'notChange'
        : 'any'
    const options = {
      initial() {
        return true
      },
      change() {
        return !accordionStates.isActive
      },
      notChange() {
        return accordionStates.isActive
      },
      any() {
        return true
      }
    }
    const response = options[option]
    return response()
  }


  useEffect(() => {
    fetchPreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TableContainer className={classes.table} component={Paper}>
      <FolderBreadcrumb path={path} />
      <Table aria-label="collapsible table" size="small">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(listItems, getComparator(order, orderBy)).map((row) => {
            return (
              <>
                <TableRow className={classes.row} hover>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.previa}
                    onClick={() =>
                      setAccordionStates({
                        isActive: handlerActive(row.id),
                        id: row.id
                      })
                    }
                  >
                    <div className={classes.flexDiv}>
                      {row.type !== 'folder' && (
                        <>
                          <Tooltip
                            title={
                              accordionStates.id === row.id &&
                              accordionStates.isActive
                                ? 'Fechar conteúdo'
                                : 'Ver conteúdo'
                            }
                            direction="bottom"
                            text="prévia"
                          >
                            <ExpandMoreIcon
                              className={
                                accordionStates.id === row.id &&
                                accordionStates.isActive
                                  ? classes.expanded
                                  : accordionStates.id === row.id &&
                                    !accordionStates.isActive
                                  ? classes.notExpanded
                                  : prevIdRef.current === row.id
                                  ? classes.notExpanded
                                  : ''
                              }
                              id={prevIdRef}
                              style={{ color: '#624C92' }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <AddLink
                      id={row.id}
                      name={row.name}
                      type={row.type}
                      setActive
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    {row.updated_at}
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    {row.type === 'folder' ? '-' : row.type}
                  </TableCell>
                  <TableCell align="left" className={classes.cellButton}>
                    <form
                      id="auto_submit"
                      action={deepLinkReturnUrl}
                      method="POST"
                    >
                      <input type="hidden" name="JWT" value={row.jwt} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        key={row.name}
                        id={row.id}
                        type="button"
                        name="GO"
                        onClick={() => sendTracking(row.jwt)}
                        className={classes.button}
                      >
                        ADICIONAR
                      </Button>
                    </form>
                  </TableCell>
                </TableRow>
                {previewContent.map((content) => (
                  content.id === accordionStates.id && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Accordion
                          isOpen={
                            accordionStates.id === row.id &&
                            accordionStates.isActive
                              ? true
                              : accordionStates.id === row.id &&
                                !accordionStates.isActive
                              ? false
                              : prevIdRef.current === row.id
                              ? false
                              : false
                          }
                          timeout={1000}
                        >
                          <ShowH5p file={content.name_origin} className={classes.h5p} />
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  )
                ))}
              </>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
AddLink.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}
ListContents.propTypes = {
  deepLinkReturnUrl: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      jwt: PropTypes.string.isRequired
    })
  ).isRequired,
  path: PropTypes.arrayOf(PropTypes.any).isRequired
}
EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired
}
