export const REUSABLE_TOPICS = Object.freeze({
  ORIGIN_COURSE_ID: 'origin_course_id',
  ORIGIN_COURSE_NAME: 'origin_course_name',
  ORIGIN_SERVICE_SKU: 'origin_service_sku',
  RELATIONSHIP_ID: 'relationship_id',
  TITLE: 'title',
  TOPIC_ID: 'topic_id',
  UPDATED_AT: 'updated_at',
  CHECKED: 'checked'
})
