import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {
    marginRight: 8,
    verticalAlign: 'bottom',
    color: '#757575'
  }
})

export default useStyles
