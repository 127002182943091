import axios from 'axios'
import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'

/**
 * Caso de uso para alterar os dados de um curso
 */
export const uploadCourseSubject = (courseId, file, data) => {
  const [subjectId, themeId, classId] = data
  const { REACT_APP_FUSION_ORIGIN: baseUrl } = process.env
  const url = `${baseUrl}/offers/api/v1/course/${courseId}/upload_subject`

  return new Promise((resolve, reject) => {
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    const payload = {
      course_id: Number(courseId),
      subject_relationship_id: (subjectId && Number(subjectId)) ?? null,
      theme_relationship_id: (themeId && Number(themeId)) ?? null,
      class_relationship_id: (classId && Number(classId)) ?? null
    }

    const bodyContent = new FormData()
    bodyContent.append('data', JSON.stringify(payload))
    bodyContent.append('file', file)

    axios
      .post(url, bodyContent, {
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          resolve()
          return
        }

        throw new Error('invalid response')
      })
      .catch((err) => {
        const responseStatus = err?.response?.status

        if (responseStatus === 422) {
          reject(new Error(`Arquivo inválido: "${err.response.data.message}"`))
        } else {
          reject(err)
        }
      })
  })
}
