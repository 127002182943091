import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../utils/response-handlers'
import { CourseDiscountCSVAdapter } from '../adapters/course-discount-csv'

const LFG_BRAND_ID = 1

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para carregamento de lista de cursos para gerar discontos
 * @returns lista de cursos com as informações para discontos
 */
const getCoursesForDiscounts = () => {
  const path = `/offers/api/v1/services?brand_id=${LFG_BRAND_ID}`
  let headers = httpClient.buildHeader({ 'session-token': getSessionToken() })
  headers = httpClient.buildHeader(headers)

  return new Promise((resolve, reject) => {
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((json) => {
        resolve(CourseDiscountCSVAdapter.inbound(json.data))
      })
      .catch((err) => reject(err))
  })
}

/* eslint-enable no-unused-vars */
export { getCoursesForDiscounts }
