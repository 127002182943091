import moment from 'moment-timezone'

export function formatDate(date) {
  return moment.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY')
}

export function formatGMTDate(date) {
  return moment.utc(date).format('DD/MM/YYYY')
}

export function formatDateHour(date) {
  return moment.utc(date).tz('America/Sao_Paulo').format('HH:mm - DD/MM/YYYY')
}

export function formatDateToISO(date) {
  let newDate = date.toISOString()
  newDate = newDate.split('T')
  const dateISO = newDate[0]
  return dateISO
}
