import React from 'react'
import PropTypes from 'prop-types'
import { Edit } from '@material-ui/icons'
import { CardInfo } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import { generatePath, useParams, useHistory } from 'react-router-dom'

import { RoutesMapping } from '../../../../../helpers/routes-mapping'

import useStyles from '../styles'

const bgWhite = {
  background: '#fff'
}

/**
 *
 * @param {{id: number; name: string; order: number; contentsCount: number}} props
 * @returns {import('react').FC}
 */
export const CardComplementaryMaterial = (props) => {
  const { brand, id: courseId } = useParams()
  const history = useHistory()
  const { id, name, order, contentsCount } = props

  const classes = useStyles()

  return (
    <CardInfo
      className={bgWhite}
      text="Gerenciar material complementar"
      icon={<Edit className={classes.cardIcon} />}
      onClick={() => {
        const detailsPath = generatePath(
          RoutesMapping.complementaryMaterialDetails,
          {
            brand,
            id: courseId,
            materialId: id
          }
        )
        history.push(detailsPath)
      }}
    >
      <div className={classes.cardInfo}>
        <Typography className={classes.cardName}>{name}</Typography>
        <Typography>Ordem de exibição: {order}</Typography>
        <Typography>Conteúdos adicionados: {contentsCount}</Typography>
      </div>
    </CardInfo>
  )
}

CardComplementaryMaterial.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  contentsCount: PropTypes.number.isRequired
}

CardComplementaryMaterial.defaultProps = {}
