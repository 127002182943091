import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  }
}))

export default useStyles
