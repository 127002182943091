/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import {
  Box,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import AvaConnection from '../../../services/avaConnection'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { ContentsContext } from '../../../contexts/ContentsContext'
import paperStyles from './styles'
import messages from '../../../helpers/messages'
import { ConnectionContext } from '../../../contexts/ConnectionContext'
import BreadcrumbsMenu from '../../Contents/ContentCard/Breadcrumbs'

function AdditionalContent({ showDialog, setShowDialog }) {
  const paperClasses = paperStyles()
  const PaperProps = { classes: paperClasses }
  const [list, setList] = useState([])
  const { setUpdated, setSuccessMessage } = useContext(GeneralContext)
  const { fetchContents, contents, contentsPath, toggleSubjectsDialog } =
    useContext(ContentsContext)
  const { setSaving, connection, updateConnection } =
    useContext(ConnectionContext)

  const checkOrUncheckAll = (evt) => {
    const { checked } = evt.target
    if (checkOrUncheckAll === true) {
      contador = list.length
    }

    setList((prev) => {
      return prev.map((i) => {
        return { ...i, isChecked: checked }
      })
    })
  }

  const onCheckItem = (evt, itemId) => {
    const { checked } = evt.target
    setList((prev) =>
      prev.map((i) => {
        if (i.id === itemId) return { ...i, isChecked: checked }
        return i
      })
    )
  }

  let contador = 0

  for (let i = 0; i < list.length; i++) {
    if (list[i].isChecked === true) {
      contador++
    }
  }

  const updatedPath = contentsPath[contentsPath.length - 1]?.id

  useEffect(() => {
    fetchContents(updatedPath)
    setUpdated(false)
  }, [updatedPath, showDialog])

  useEffect(() => {
    setList(contents.map((i) => ({ ...i, isChecked: false })))
  }, [contents])

  const save = async () => {
    try {
      setSaving(true)

      const selectedSubjectIds = list
        .filter((subject) => subject.isChecked === true)
        .map((item) => item.id)

      const payload = {
        modules_id: selectedSubjectIds.concat(connection.modules)
      }

      await AvaConnection.saveDisciplines(connection.client_id, payload)
      updateConnection({
        modules: selectedSubjectIds.concat(connection.modules)
      })
      setSuccessMessage(messages.subjectsDialog.success)
      toggleSubjectsDialog(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setSaving(false)
      setShowDialog(false)
    }
  }

  return (
    <>
      <Dialog
        title="Conteúdo da IES"
        secondaryButton="FECHAR"
        primaryButton={`ADICIONAR CONTEÚDO ( ${contador} )`}
        size="lg"
        handleClose={setShowDialog}
        handleConfirm={() => save()}
        confirmWithoutCheck={() => alert('Clicou sem confirmar')}
        open={showDialog}
        PaperProps={PaperProps}
      >
        <div>
          <BreadcrumbsMenu />
          <Divider />
          <Table
            style={{
              width: '897px',
              position: 'relative',
              overflowY: 'auto',
              maxHeight: '400px'
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 8px'
                  }}
                >
                  <Checkbox
                    checked={list.every((item) => {
                      return item.isChecked
                    })}
                    color="primary"
                    onChange={(evt) => checkOrUncheckAll(evt)}
                    style={{ marginLeft: -8 }}
                  />
                  Disciplinas
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'auto',
                height: '300px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {list.map((item) => (
                <TableRow
                  key={item.id}
                  className={
                    item.type === 'folder'
                      ? paperClasses.tableRow
                      : paperClasses.tableRowUA
                  }
                >
                  {item.type === 'folder' && (
                    <Checkbox
                      checked={
                        connection.modules.includes(item.id) || item.isChecked
                      }
                      color="primary"
                      onChange={(ev) => {
                        onCheckItem(ev, item.id)
                      }}
                      style={{ marginLeft: -8 }}
                    />
                  )}
                  <TableCell
                    className={
                      item.type === 'folder'
                        ? paperClasses.folder
                        : paperClasses.cell
                    }
                    onClick={
                      item.type === 'folder'
                        ? () => fetchContents(item.id)
                        : null
                    }
                  >
                    <Box
                      style={{
                        color: item.isChecked ? '#624C92' : '#808080',
                        display: 'contents'
                      }}
                    >
                      {item.type === 'folder' ? (
                        <FolderIcon className={paperClasses.icon} />
                      ) : item.type === 'H5P' ? (
                        <InsertDriveFileIcon className={paperClasses.icon} />
                      ) : (
                        <PermMediaIcon className={paperClasses.icon} />
                      )}
                      {item.name}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <></>
      </Dialog>
    </>
  )
}

export default AdditionalContent
AdditionalContent.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired
}
