import React, { useContext, useEffect, useState } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  TableBody,
  IconButton,
  TablePagination,
  Checkbox,
  Menu,
  MenuItem,
  Switch
} from '@material-ui/core'
import { Clear, Delete, Edit, MoreVert } from '@material-ui/icons'
import { useRecoilState, useSetRecoilState } from 'recoil'
import PropTypes from 'prop-types'
import { Dialog, TextInput } from '@equipe-ninja/saraiva-ui'
import { REGISTER_ELEARNING_LIST_SUBJECTS_IN_COURSE_FIELDS } from '../../../../domain/courses/register-elearning-page/register-elearning-list-subjects-in-course-fields'
import useStyles from '../../styles'
import { SubjectBreadcrumb } from './SubjectBreadcrumb'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import {
  selectedTopicsDataSelector,
  actionOnTopicSelector,
  allTopicSelector
} from '../../../../atoms/course-topics'
import {
  toggleCourseAccess,
  toggleTopicFullPosted,
  topicRenamed,
  removeTopic
} from '../../../../services/courses'
import SwitchDialog from './SwitchDialog'

const {
  SUBJECT,
  ADDED_IN,
  SKU,
  UPDATED_AT,
  IS_AVAILABLE,
  FULL_POSTED,
  TYPE,
  HIERARCHY,
  KEY,
  RELATION_ID,
  ORIGIN_COURSE_ID
} = REGISTER_ELEARNING_LIST_SUBJECTS_IN_COURSE_FIELDS

const DISCIPLINE = 'disciplina'

const headCells = [
  { name: SUBJECT.label, order: true, code: SUBJECT.value },
  { name: ADDED_IN.label, order: true, code: ADDED_IN.value },
  { name: SKU.label, order: true, code: SKU.value },
  {
    name: UPDATED_AT.label,
    order: true,
    code: UPDATED_AT.value
  },
  {
    name: FULL_POSTED.label,
    order: true,
    code: FULL_POSTED.value
  },
  {
    name: IS_AVAILABLE.label,
    order: true,
    code: IS_AVAILABLE.value
  }
]

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const SubjectsTable = (props) => {
  const { courseId, courseName } = props
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(SUBJECT.label)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [openMenu, toggleOpenMenu] = useState([])
  const [openSwitchDialog, setOpenSwitchDialog] = useState({
    open: false,
    id: null,
    relationId: null,
    value: null
  })
  const [contentSelected, setContentSelected] = useState(null)
  const [visibleHeadCells, setVisibleHeadCells] = useState(headCells)
  const [openDialogChangeName, setOpenDialogChangeName] = useState(false)
  const [newName, setNewName] = useState('')
  const [dialogChangeNameTitle, setDialogChangeNameTitle] = useState('')
  const [dialogChangeNameLabel, setDialogChangeNameLabel] = useState('')
  const [disabledButtonChangeName, setDisabledButtonChangeName] = useState(true)
  const [openDialogConfirmChangeName, setOpenDialogConfirmChangeName] =
    useState(false)
  const [topicIdToChange, setTopicIdToChange] = useState()
  const setTopicsState = useSetRecoilState(allTopicSelector)

  // eslint-disable-next-line no-unused-vars
  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [dialogConfirmRemoveTitle, setDialogConfirmRemoveTitle] = useState('')
  const [state, setState] = useRecoilState(selectedTopicsDataSelector)
  const [
    { toggleAvailabilityPayload, toggleFullPostedPayload, topicRenamedPayload },
    setActionOnTopic
  ] = useRecoilState(actionOnTopicSelector)

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const handleClickFolder = (topicId) => {
    setState((prev) => ({ ...prev, selectedTopicId: topicId }))
  }

  const handleCloseSwitchDialog = () => {
    setOpenSwitchDialog((prev) => ({ ...prev, open: false }))
  }

  const handleOpenSwitchDialog = (id, relationId, value) => {
    setOpenSwitchDialog((prev) => ({
      ...prev,
      open: true,
      id,
      relationId,
      value
    }))
  }

  useEffect(() => {
    switch (state.breadcrumbs.length) {
      case 1:
        setVisibleHeadCells(headCells)
        break
      case 2:
        setVisibleHeadCells([
          { name: 'Temas', order: true, code: SUBJECT.value },
          {
            name: UPDATED_AT.label,
            order: true,
            code: UPDATED_AT.value
          },
          {
            name: TYPE.label,
            order: true,
            code: TYPE.value
          },
          {
            name: IS_AVAILABLE.label,
            order: true,
            code: IS_AVAILABLE.value
          }
        ])
        break
      case 3:
        setVisibleHeadCells([
          { name: 'Aulas', order: true, code: SUBJECT.value },
          {
            name: UPDATED_AT.label,
            order: true,
            code: UPDATED_AT.value
          },
          {
            name: TYPE.label,
            order: true,
            code: TYPE.value
          },
          {
            name: IS_AVAILABLE.label,
            order: true,
            code: IS_AVAILABLE.value
          }
        ])
        break
      case 4:
        setVisibleHeadCells([
          { name: 'Conteúdos', order: true, code: SUBJECT.value },
          {
            name: UPDATED_AT.label,
            order: true,
            code: UPDATED_AT.value
          },
          {
            name: TYPE.label,
            order: true,
            code: TYPE.value
          },
          {
            name: IS_AVAILABLE.label,
            order: true,
            code: IS_AVAILABLE.value
          }
        ])
        break
      default:
        setVisibleHeadCells(headCells)
    }
  }, [state.breadcrumbs])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (event, content, rowIndex) => {
    setContentSelected(content)

    toggleOpenMenu((prev) =>
      prev.map((k, i) => (i === rowIndex ? event.target : k))
    )
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const renderSubjectType = (type) => {
    switch (type) {
      case 'folder':
        return 'Pasta'
      default:
        return type
    }
  }

  const handleCloseDialogChangeName = () => {
    setOpenDialogChangeName(false)
  }

  const executeRenameTask = () => {
    setOpenDialogChangeName(false)

    setActionOnTopic((prev) => ({
      ...prev,
      isExecutingTask: true
    }))

    topicRenamed(topicIdToChange, newName, courseId)
      .then(() => {
        setActionOnTopic((prev) => ({
          ...prev,
          isExecutingTask: false,
          ...topicRenamedPayload(topicIdToChange, newName, courseId)
        }))
        setSuccessMessage('Nome alterado com sucesso')
      })
      .catch((err) => {
        setActionOnTopic((prev) => ({
          ...prev,
          isExecutingTask: false
        }))
        setErrorMessage(err?.message || 'Falha na alteração do nome')
      })
      .finally(() => setOpenDialogConfirmChangeName(false))
  }

  const handleChangeName = () => {
    setOpenDialogConfirmChangeName(true)
  }

  const renderDialogChangeNameLabel = () => {
    let newLabel = ''
    switch (contentSelected[HIERARCHY.value]) {
      case 'disciplina':
        newLabel = 'Nome da disciplina'
        break
      case 'tema':
        newLabel = 'Nome do tema'
        break
      case 'subtema':
        newLabel = 'Nome da aula'
        break
      case 'conteudo':
        newLabel = 'Nome do conteúdo'
        break
      default:
        newLabel = ''
    }

    setDialogChangeNameLabel(newLabel)
  }

  const renderDialogChangeNameTitle = () => {
    let newTitle = ''
    switch (contentSelected && contentSelected[HIERARCHY.value]) {
      case 'disciplina':
        newTitle = 'Renomear disciplina'
        break
      case 'tema':
        newTitle = 'Renomear tema'
        break
      case 'subtema':
        newTitle = 'Renomear aula'
        break
      case 'conteudo':
        newTitle = 'Renomear conteúdo'
        break
      default:
        newTitle = ''
    }

    setDialogChangeNameTitle(newTitle)
  }

  const renderDialogConfirmRemoveTitle = () => {
    let newTitle = ''
    switch (contentSelected && contentSelected[HIERARCHY.value]) {
      case 'disciplina':
        newTitle = 'Excluir disciplina'
        break
      case 'tema':
        newTitle = 'Excluir tema'
        break
      case 'subtema':
        newTitle = 'Excluir aula'
        break
      case 'conteudo':
        newTitle = 'Excluir conteúdo'
        break
      default:
        newTitle = ''
    }

    setDialogConfirmRemoveTitle(newTitle)
  }

  const handleOpenDialogChangeName = (topicId) => {
    toggleOpenMenu((prev) => prev.map(() => null))
    renderDialogChangeNameLabel()
    renderDialogChangeNameTitle()
    setNewName(contentSelected[SUBJECT.value])
    setTopicIdToChange(topicId)
    setOpenDialogChangeName(true)
  }

  const handleConfirmChangeName = () => {
    executeRenameTask()
  }

  const handleCloseDialogConfirmChangeName = () => {
    setOpenDialogChangeName(false)
    setOpenDialogConfirmChangeName(false)
  }

  const renderConfirmText = () => {
    let firstText = ''
    let secondText = ''

    switch (contentSelected && contentSelected[HIERARCHY.value]) {
      case 'disciplina':
        firstText = 'uma disciplina'
        secondText = 'ela'
        break
      case 'tema':
        firstText = 'um tema'
        secondText = 'ele'
        break
      case 'subtema':
        firstText = 'uma aula'
        secondText = 'ela'
        break
      case 'conteudo':
        firstText = 'um conteúdo'
        secondText = 'ele'
        break
      default:
        firstText = ''
        secondText = ''
    }

    return `
      Você está renomeando ${firstText}. Essa ação será refletida em todos os cursos no qual ${secondText} se encontra.
    `
  }

  // const handleCloseDialogConfirmRemove = () => {
  //   setOpenDialogConfirmRemove(false)
  // }

  // const handleConfirmRemove = () => {
  //   removeSubjectFromCourse(contentSelected.key)
  //   setOpenDialogConfirmRemove(false)
  //   setSuccessMessage('Removido com sucesso')
  // }

  const handleRemoveSubjectFromCourse = () => {
    setOpenDialogConfirmRemove(true)
    renderDialogConfirmRemoveTitle()
    toggleOpenMenu((prev) => prev.map(() => null))
  }

  const handleRemoveSubjectFromList = () => {
    removeTopic(contentSelected.relationId)
      .then(() => {
        setTopicsState((prev) => ({
          ...prev,
          requestId: prev.requestId + 1
        }))

        setSuccessMessage('Removido com sucesso')

        toggleOpenMenu((prev) => prev.map(() => null))
      })
      .catch(() => {
        setErrorMessage('Erro ao remover')
      })
  }

  const handleToggleAvailable = (id, relationId, value, changeAll) => {
    setActionOnTopic((prev) => ({
      ...prev,
      isExecutingTask: true,
      ...toggleAvailabilityPayload(id, value)
    }))

    toggleCourseAccess(relationId, value, changeAll)
      .then(() => {
        setActionOnTopic((prev) => ({ ...prev, isExecutingTask: false }))
        setSuccessMessage('Disponibilidade atualizada com sucesso.')
        handleCloseSwitchDialog()
      })
      .catch((err) => {
        setActionOnTopic((prev) => ({
          ...prev,
          isExecutingTask: false,
          ...toggleAvailabilityPayload(id, !value)
        }))
        setErrorMessage(err?.message || 'Falha na alteração de disponibilidade')
      })
  }

  const onConfirmChangeAvailability = (changeAll) => {
    const { id, relationId, value } = openSwitchDialog
    handleToggleAvailable(id, relationId, value, changeAll)
  }

  const handleToggleFullPosted = (topicId, value) => {
    setActionOnTopic((prev) => ({
      ...prev,
      isExecutingTask: true,
      ...toggleFullPostedPayload(topicId, value)
    }))

    toggleTopicFullPosted(topicId, value)
      .then(() => {
        setActionOnTopic((prev) => ({ ...prev, isExecutingTask: false }))
        setSuccessMessage('Disciplina atualizada com sucesso.')
      })
      .catch((err) => {
        setActionOnTopic((prev) => ({
          ...prev,
          isExecutingTask: false,
          ...toggleAvailabilityPayload(topicId, !value)
        }))
        setErrorMessage(err?.message || 'Falha na alteração de disciplina')
      })
  }

  const isFullpostedDisabled = (row) => {
    if (parseInt(courseId, 10) !== row[ORIGIN_COURSE_ID.value]) return true

    return state.isExecutingTask
  }

  useEffect(() => {
    if (
      contentSelected &&
      newName !== contentSelected[SUBJECT.value] &&
      newName.trim() !== ''
    ) {
      setDisabledButtonChangeName(false)
    } else {
      setDisabledButtonChangeName(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newName])

  useEffect(() => {
    toggleOpenMenu(new Array(state.selectedTopicData.length).fill(null))
  }, [state.selectedTopicData])

  return (
    <Paper>
      <TableContainer className={classes.table}>
        <SubjectBreadcrumb />
        <Table size="small">
          <TableHead>
            <TableRow>
              {visibleHeadCells.map((headCell) => (
                <TableCell
                  align={headCell.position && 'right'}
                  sortDirection={orderBy === headCell.code ? order : false}
                >
                  {['subject', 'updated_at', 'sku', 'added_in'].includes(
                    headCell.code
                  ) ? (
                    <TableSortLabel
                      classes={{ icon: classes.icon }}
                      active={orderBy === headCell.code}
                      direction={orderBy === headCell.code ? order : 'asc'}
                      id={headCell.code}
                      onClick={() => handleRequestSort(headCell.code)}
                      className={classes.tableHeaders}
                    >
                      {headCell.name}
                    </TableSortLabel>
                  ) : (
                    <div>{headCell.name}</div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(state.selectedTopicData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <TableRow
                    key={row.key}
                    hover={row.type === 'folder'}
                    className={classes.cell}
                  >
                    <TableCell
                      className={
                        row.type === 'folder' ? classes.folder : classes.cell
                      }
                      onClick={
                        row.type === 'folder'
                          ? () => handleClickFolder(row.key)
                          : null
                      }
                    >
                      <div
                        className={
                          row.type === 'folder' ? classes.folderText : ''
                        }
                      >
                        {row.subject}
                      </div>
                    </TableCell>
                    {state.breadcrumbs.length === 1 ? (
                      <>
                        <TableCell className={classes.cell}>
                          {row.added_in}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.sku}
                        </TableCell>
                      </>
                    ) : (
                      ''
                    )}

                    <TableCell className={classes.cell}>
                      {row.updated_at}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {state.breadcrumbs.length === 1 ? (
                        <Checkbox
                          checked={row.full_posted}
                          onChange={(e) =>
                            handleToggleFullPosted(row.key, e.target.checked)
                          }
                          color="primary"
                          disabled={isFullpostedDisabled(row)}
                        />
                      ) : (
                        renderSubjectType(row.type)
                      )}
                    </TableCell>

                    <TableCell className={classes.lastColumn} align="center">
                      <Switch
                        disabled={state.isExecutingTask}
                        checked={row.is_available}
                        onChange={(e) => {
                          handleOpenSwitchDialog(
                            row[KEY.value],
                            row[RELATION_ID.value],
                            e.target.checked
                          )
                        }}
                        color="primary"
                      />
                      <IconButton
                        onClick={(event) => {
                          handleClick(event, row, rowIndex)
                        }}
                        className={classes.optionsButton}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                    <Menu
                      anchorEl={openMenu[rowIndex]}
                      open={Boolean(openMenu[rowIndex])}
                      onClose={() =>
                        toggleOpenMenu((prev) => prev.map(() => null))
                      }
                    >
                      <MenuItem
                        onClick={() =>
                          handleOpenDialogChangeName(row[KEY.value])
                        }
                        disabled={
                          row[ORIGIN_COURSE_ID.value] !== Number(courseId)
                        }
                      >
                        <Edit className={classes.icon} />
                        Renomear
                      </MenuItem>
                      {contentSelected &&
                      contentSelected.added_in !== courseName ? (
                        <MenuItem
                          onClick={() =>
                            handleRemoveSubjectFromList(row[RELATION_ID.value])
                          }
                          className={classes.removeFromList}
                          disabled={
                            row[ORIGIN_COURSE_ID.value] !== Number(courseId) &&
                            row[HIERARCHY.value] !== DISCIPLINE
                          }
                        >
                          <Clear className={classes.icon} />
                          Remover da lista
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => handleRemoveSubjectFromCourse()}
                          className={classes.deleteSubject}
                          disabled={
                            row[ORIGIN_COURSE_ID.value] !== Number(courseId) &&
                            row[HIERARCHY.value] !== DISCIPLINE
                          }
                        >
                          <Delete className={classes.icon} />
                          Excluir do banco
                        </MenuItem>
                      )}
                    </Menu>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={state.selectedTopicData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {openSwitchDialog.open && (
        <SwitchDialog
          open={openSwitchDialog.open}
          onClose={handleCloseSwitchDialog}
          onSave={onConfirmChangeAvailability}
        />
      )}

      <Dialog
        open={openDialogChangeName}
        title={dialogChangeNameTitle}
        secondaryButton="cancelar"
        primaryButton="salvar"
        size="sm"
        handleClose={handleCloseDialogChangeName}
        handleConfirm={handleChangeName}
        isDisabled={disabledButtonChangeName || state.isExecutingTask}
      >
        <TextInput
          color="primary"
          size="full"
          label={dialogChangeNameLabel}
          onChange={(e) => setNewName(e.target.value)}
          required
          fullWidth
          value={newName}
        />
      </Dialog>

      <Dialog
        open={openDialogConfirmChangeName}
        title={dialogChangeNameTitle}
        secondaryButton="cancelar"
        primaryButton="salvar"
        size="lg"
        handleClose={handleCloseDialogConfirmChangeName}
        handleConfirm={handleConfirmChangeName}
        label="Sim, tenho certeza!"
        alert
        isDisabled={state.isExecutingTask}
        handleCheck={() => null}
      >
        {renderConfirmText()}
      </Dialog>
    </Paper>
  )
}

SubjectsTable.propTypes = {
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  courseName: PropTypes.string.isRequired
}
