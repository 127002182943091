import React, { useContext } from 'react'
import { UploadBar } from '@equipe-ninja/saraiva-ui'
import { Button, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { SwapHoriz as SwapIcon } from '@material-ui/icons'
import useStyles from './styles'
import { UploadContext } from '../../contexts/UploadContext'
import Messages from '../../helpers/messages'
import { GeneralContext } from '../../contexts/GeneralContext'
import { ContentsContext } from '../../contexts/ContentsContext'

const UploadButton = ({ buttonTitle, isSwap, contentId, moduleId }) => {
  const {
    toggleShowingUploadBar,
    showingUploadBar,
    countFileUpload,
    totalFiles,
    setTotalFiles,
    errorsBar,
    reloadFiles,
    filesError,
    clearState,
    setCountFileUpload,
    setReload,
    updatedContent
  } = useContext(UploadContext)

  const { setSuccessMessage, setErrorMessage, setUpdated } = useContext(GeneralContext)

  const handleCloseUploadBar = () => {
    clearState()
  }
  const handleCancelUploadBar = () => {
    clearState()
  }

  const classes = useStyles()

  const handleReloadFiles = () => {
    reloadFiles(filesError)
  }

  async function handleFileChange(event) {
    await clearState()
    
    try {
      const file = event.target.files[0]
      setTotalFiles(1)
      
      toggleShowingUploadBar(true)
      
      await updatedContent(file, contentId, moduleId)
      setCountFileUpload((currentState) => {
        return currentState + 1
      })
      setSuccessMessage(Messages.contentUpdate.success)
      setUpdated(true)
      
      if (filesError !== []) {
        setReload(true)
      }

    } catch (error) {
      toggleShowingUploadBar(false)
      setErrorMessage(Messages.contentUpdate.error)
      console.log(error)
    }
  }

  return (
    <>
      <label htmlFor="contained-button-file">
        {!isSwap && (
          <Button
            color="primary"
            variant="outlined"
            component="label"
            startIcon={<AddIcon />}
          >
            {buttonTitle}
            <input
              type="file"
              accept=".h5p"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              id="contained-button-file"
            />
          </Button>
        )}
        {isSwap && (
          <MenuItem>
            <input
              type="file"
              accept=".h5p"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              id="contained-button-file"
            />
            <SwapIcon className={classes.icon} />
            <span style={{ paddingLeft: 11 }}>Substituir</span>
          </MenuItem>
        )}
      </label>
      {showingUploadBar ? (
        <UploadBar
          progressFileUpload={countFileUpload}
          totalUploadFiles={totalFiles}
          errorsList={errorsBar}
          handleCancelUpload={handleCancelUploadBar}
          handleCloseUpload={handleCloseUploadBar}
          reloadErrorsUpload={handleReloadFiles}
        />
      ) : (
        ''
      )}
    </>
  )
}

UploadButton.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  isSwap: PropTypes.bool
}
UploadButton.defaultProps = {
  isSwap: false
}

export default UploadButton
