import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '24px'
  },

  divider: {
    marginTop: '24px',
    marginBottom: '36px'
  },

  loaderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },

  tableContainer: {
    marginTop: '24px'
  },

  searchWrapper: {
    marginBottom: 25
  },

  paper: {
    width: 277,
    position: 'absolute',
    borderRadius: 4,
    padding: '10px 0px 10px 12px',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main
    },
    zIndex: 1000,
    marginTop: -16
  },

  text: {
    marginTop: '40px',
    marginBottom: '24px',
    color: theme.palette.text.primary
  },

  textH4: {
    fontSize: '34px'
  },

  textH5: {
    fontSize: '24px',
    marginTop: '40px'
  },

  textH6: {
    fontSize: '20px',
    marginTop: '40px'
  },

  CardContext: {
    border: '1px solid rgb(235,235,235)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    marginTop: '24px',
    '&:first-child': {
      marginTop: '40px'
    }
  },

  CardMain: {
    padding: '8px 15px 13px 15px'
  },

  CardTitle: {
    fontWeight: 'normal',
    marginTop: '16px',
    marginBottom: '16px',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)'
  },

  CardDetals: {
    color: 'rgba(0,0,0,.54)',
    fontSize: '16px',
    lineHeight: '150%'
  },

  CardFooter: {
    borderTop: '1px solid rgb(235,235,235)',
    padding: '4px 16px 4px 4px',
    color: '#245A93',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span:hover': {
      cursor: 'pointer'
    }
  },

  Icon: {
    cursor: 'pointer'
  },

  CardJustify: {
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: '8px',
    marginTop: '8px',
    padding: '16px'
  },

  subTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)'
  },

  CardTextH6: {
    fontSize: '20px',
    margin: '20px 16px'
  },
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
}))

export default useStyles
