import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../helpers/mock-server-utils'
import { PaymentInfoAdapter } from '../adapters/payment-info-adapters'
import { PAYMENT_INFO_REMOTE_FIELDS as RF } from '../remote-fields/payment-info-remote-fields'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar os detalhes de pagamento de um pedido
 * @returns Promise com a resposta ou rejeita com erro
 */
const getPaymentDetails = (orderItemId) => {
  return new Promise((resolve, reject) => {
    const path = `promotional/api/v1/services/${orderItemId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, headers)
      .then((response) => {
        resolve(PaymentInfoAdapter(response))
      })
      .catch((error) => reject(error))
  })
}

const factory = (id) => {
  if (id % 2 === 0) {
    return {
      [RF.ORDER_ITEM_ID]: id,
      [RF.PAYMENT_TYPE]: 1, // cc
      [RF.CREDIT_CARD]: 'Mastercard****4467',
      [RF.CREDIT_CARD_HOLDER_NAME]: 'Danilo F Mendes',
      [RF.INSTALLMENTS]: '1',
      [RF.VALUE]: 60,
      [RF.PERSON_ID]: '12793612467'
    }
  }

  return {
    [RF.ORDER_ITEM_ID]: id,
    [RF.PAYMENT_TYPE]: 2, // boleto
    [RF.BANK_SLIP_CODE]:
      '34191.09461 25754.580196 61309.830000 1 86700000008000',
    [RF.BANK_SLIP_DUE_DATE]: `2022-08-${((id % 28) + 1)
      .toString()
      .padStart(2, '0')}T13:37:37`,
    [RF.PERSON_ID]: '12793612467',
    [RF.PERSON_NAME]: 'Danilo Ferreira Mendes',
    [RF.VALUE]: 60
  }
}

const mockedResponse = (orderItemId) => {
  return mockedServerAnswer(
    factory(orderItemId),
    true,
    1000,
    PaymentInfoAdapter.inbound
  )
}

export { mockedResponse as getPaymentDetails }
