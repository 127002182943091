import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { ScheduledContentAdapter } from '../../adapters/scheduled-content'
import { SCHEDULED_CONTENT_REMOTE_FIELDS as REMOTE_FIELDS } from '../../remote-fields/scheduled-content'

const createScheduledContent = async (formValues, courseId) => {
  const bodyData = ScheduledContentAdapter.outbound(formValues)
  bodyData[REMOTE_FIELDS.ACTIVE] = true
  bodyData[REMOTE_FIELDS.COURSE_ID] = Number(courseId)

  const path = '/contents/api/v1/scheduled_content'
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.post(path, bodyData, headers)
  const responseData = await response.json()
  return ScheduledContentAdapter.inbound(responseData)
}

export { createScheduledContent }
