export const BANNER_FIELDS = Object.freeze({
  KEY: { value: 'key', label: 'identificador' },
  TYPE: { value: 'type', label: 'Tipo' },
  TITLE: { value: 'title', label: 'Título' },
  SESSION: { value: 'session', label: 'Sessão' },
  URL: { value: 'urls', label: 'URL da página' },
  END_DATE: { value: 'endDate', label: 'Data de fim' },
  START_DATE: { value: 'startDate', label: 'Data de início' },
  STATUS: { value: 'status', label: 'Status' },
  ACTIVE: { value: 'active', label: 'Active' },
  PAGE_ID: { value: 'pageId', label: 'Page Id' },
  ORDER: { value: 'orders', label: 'Ordem de apresentação' },
  ORDER_2: { value: 'order_2', label: 'Ordem de apresentação' },
  ORDER_3: { value: 'order_3', label: 'Ordem de apresentação' },
  ORDER_4: { value: 'order_4', label: 'Ordem de apresentação' },
  IMAGE_WEB: { value: 'imageWeb', label: 'Imagem Web' },
  IMAGE_RECURSIVE: { value: 'imageRecursive', label: 'Imagem Responsiva' },
  IMAGE_RECURSIVE_2: { value: 'file_url_web_2', label: 'Imagem Responsiva' },
  IMAGE_RECURSIVE_3: { value: 'file_url_web_3', label: 'Imagem Responsiva' },
  IMAGE_RECURSIVE_4: { value: 'file_url_web_4', label: 'Imagem Responsiva' }
})
