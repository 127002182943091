import { atom } from 'recoil'

import {
  QUESTION_ALTERNATIVES_FIELDS,
  QUESTION_FIELDS
} from '../../../domain/fixation-exercise/question-fields'

export const fixationExerciseState = atom({
  key: 'fixationExerciseState',
  default: {
    questionUpdateId: null,
    questionDialogFormValues: {
      [QUESTION_FIELDS.KEY_WORDS]: '',
      [QUESTION_FIELDS.TABLE_TEST]: '',
      [QUESTION_FIELDS.TEST_YEAR]: '',
      [QUESTION_FIELDS.ENUNCIATION]: '',
      [QUESTION_FIELDS.ID]: 0,
      [QUESTION_FIELDS.ORIGIN_SUBJECT]: '',
      [QUESTION_FIELDS.ALTERNATIVES]: [
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 1,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        },
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 2,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        }
      ],
      [QUESTION_FIELDS.CORRECT_ALTERNATIVE]: null
    },
    questionDialogFormErrors: null
  }
})
