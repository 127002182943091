/* eslint-disable react/prop-types */
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { GeneralContext } from '../../contexts/GeneralContext'
import messages from '../../helpers/messages'
import IntegrationsService from '../../services/integrationsService'

export function DeleteBankQuestion({ showDialogDelete, setshowDialogDelete }) {
  const handleCloseDialogDelete = () => {
    setshowDialogDelete(false)
  }

  const [saving, setSaving] = useState(false)

  const {
    selectedQuestionId,
    setQuestions,
    questions,
    setSuccessMessage,
    setErrorMessage,
    setDeletedQuestion
  } = useContext(GeneralContext)

  const save = async () => {
    try {
      setSaving(true)

      const promises = selectedQuestionId.map((questionId) =>
        IntegrationsService.deleteQuestionId(questionId)
      )

      const responses = await Promise.all(promises)

      const failedDeletions = responses.filter(
        (response) => response.status !== 204
      )

      if (failedDeletions.length > 0) {
        setErrorMessage(messages.deleteBankQuestion.error)
      }

      const updatedQuestions = questions.filter(
        (question) => !selectedQuestionId.includes(question.id)
      )

      setQuestions(updatedQuestions)
      setDeletedQuestion(selectedQuestionId)
      setSuccessMessage(messages.deleteBankQuestion.success)
    } catch (error) {
      console.error('Erro ao buscar dados do backend:', error)
    } finally {
      setSaving(false)
      handleCloseDialogDelete()
    }
  }

  return (
    <div>
      <Dialog
        title="Excluir questão"
        secondaryButton="CANCELAR"
        primaryButton="EXCLUIR"
        size="md"
        open={showDialogDelete}
        handleClose={() => handleCloseDialogDelete()}
        handleConfirm={() => save()}
        isDisabled={saving}
      >
        <Typography variant="body1" style={{ color: '#000' }}>
          Você tem certeza que deseja excluir a questão do Banco de questões?
        </Typography>
      </Dialog>
    </div>
  )
}
