import { makeStyles } from '@material-ui/core/styles'
import {
  spacing,
  pageClasses,
  flexContainers,
  formClasses
} from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...pageClasses(theme),
  radioContainer: {
    ...flexContainers.flexRow,
    ...formClasses(theme).radioGroup,
    gap: '0 16px',
    padding: '0 10px',
    marginTop: '15px'
  }
}))

export default useStyles
