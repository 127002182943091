import { selector } from 'recoil'
import { courseOverviewState } from '../atom/course-overview-state'
import { ECOMMERCE_FORM_FIELDS } from '../../../domain/courses/entities'

const { COURSE_TABS } = ECOMMERCE_FORM_FIELDS

export const ecommerceTabsForm = selector({
  key: 'ecommerceTabsForm',
  get: ({ get }) => {
    return {
      values: get(courseOverviewState).ecommerceForm.values[COURSE_TABS],
      errors: get(courseOverviewState).ecommerceForm.errors[COURSE_TABS]
    }
  },
  set: ({ get, set }, data) => {
    set(courseOverviewState, {
      ...get(courseOverviewState),
      ecommerceForm: {
        ...get(courseOverviewState).ecommerceForm,
        values: {
          ...get(courseOverviewState).ecommerceForm.values,
          [COURSE_TABS]: data.values
        },
        errors: {
          ...get(courseOverviewState).ecommerceForm.errors,
          [COURSE_TABS]: data.errors
        }
      }
    })
  }
})
