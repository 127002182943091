import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: 72
  },
  infoCard: {
    display: 'flex',
    width: 936,
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 12
  },
  userInfo: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  cardContainer: {
    display: 'block',
    alignItems: 'baseline',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    marginBottom: 20
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editButton: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    marginLeft: 8,
    fontWeight: 400
  },
  editIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 24,
    marginTop: 8
  },
  deleteButton: {
    color: '#A42C31',
    borderColor: '#A42C31'
  },
  deleteBtnContainer: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export default useStyles
