import { parseJwt, getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const FixationExercise = {
  async getFixationExercise(id, page, perPage = 10) {
    const path = `/contents/api/v1/fixation_exercise?subject_id=${id}&page=${page}&per_page=${perPage}`

    let headers = {
      'session-token': getSessionToken()
    }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async deleteFixationExercise(id, exercises) {
    const path = `/contents/api/v1/fixation_exercise/delete_exercise_from_subject?subject_id=${id}&exercises_list=[${exercises}]`

    let headers = {
      'session-token': getSessionToken()
    }

    headers = httpClient.buildHeader(headers)
    const response = await httpClient.delete(path, null, headers)
    return response
  },

  async putFixationExercise(data) {
    const { alternatives, ...rest } = data

    const payload = { ...rest, alternatives }
    const path = `/contents/api/v1/fixation_exercise`
    let headers = {
      'session-token': getSessionToken()
    }

    headers = httpClient.buildHeader(headers)
    const response = await httpClient.put(path, payload, headers)
    return response
  },

  async postFixationExercise(data) {
    const payload = data
    const sessionToken = getSessionToken()
    const jwtPayload = parseJwt(sessionToken)
    const userId = jwtPayload.user.id

    const path = `/contents/api/v1/fixation_exercise?user_id=${userId}`
    let headers = {
      'session-token': sessionToken
    }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async postReusableFixationExercise(subjectId, reusableQuestions) {
    const sessionToken = getSessionToken()
    const questions = reusableQuestions.join(', ')
    const path = `/contents/api/v1/fixation_exercise/add_exercise_to_subject?subject_id=${subjectId}&exercises_list=[${questions}]`

    let headers = {
      'session-token': sessionToken
    }

    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, null, headers)
    return response
  }
}

export default FixationExercise
