const { ENV_CONSTANT_CONFIG } = process.env

export const generateServicesConstantsForEnviroment = () => {
  switch (ENV_CONSTANT_CONFIG) {
    case 'staging':
      return {
        LFG_BRAND_ID: 1,
        APP_ID: 4,
        ACTIVE: true
      }

    case 'production':
      return {
        LFG_BRAND_ID: 1,
        APP_ID: 5,
        ACTIVE: true
      }

    default:
      return {
        LFG_BRAND_ID: 1,
        APP_ID: 1,
        ACTIVE: true
      }
  }
}
