import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  titleH3: {
    marginBottom: '24px',
    color: theme.palette.text.primary,
    marginTop: '22px',
    fontSize: '48px'
  },
  titleH4: {
    fontSize: '34px',
    color: theme.palette.text.primary,
    marginTop: '40px',
    marginBottom: '24px'
  },
  titleH5: {
    fontSize: '24px',
    marginBottom: 24,
    marginTop: '22px'
  }
}))

export default useStyles
