import React, { useEffect, useState } from 'react'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { Button, SearchBarFilter } from '@equipe-ninja/saraiva-ui'
import { Table } from '@equipe-ninja/saraiva-ui/core/v2/Table'
import { TableCell, TableRow, Link, Divider } from '@material-ui/core'
import ContentWithHeader from '../../../components/ContentWithHeader'
import useStyles from './styles'
import { formatCPF } from '../../../helpers/formatCPF'
import Users from '../../../services/accounts/users'

const headers = [
  {
    id: 'name',
    label: 'Nome',
    columnWidth: '1fr'
  },
  {
    id: 'email',
    label: 'Email',
    columnWidth: '1fr'
  },
  {
    id: 'cpf',
    label: 'CPF',
    columnWidth: '1fr'
  },
  {
    id: 'activeProducts',
    label: 'Produtos Ativos'
  }
]

export function UserData() {
  const classes = useStyles()
  const history = useHistory()
  const [data, setData] = useState([])
  const [email, setEmail] = useState('')

  const filterOptions = [{ label: 'E-mail', value: 'email' }]

  const handleInputChange = ({ filterTerm }) => {
    setEmail(filterTerm)
  }

  const fetchData = async () => {
    const page = 1
    const perPage = 1000
    let response

    try {
      if (email) {
        response = await Users.searchFilterUser(page, perPage, email)
      } else {
        response = await Users.searchDataUsers(page, perPage, email)
      }

      if (!response.ok) {
        const errors = await response.json()
        throw new Error(errors.message || 'Something went wrong')
      }

      const result = await response.json()

      let usersData = []
      if (result.user) {
        usersData = [result.user]
      } else if (result.users) {
        usersData = result.users
      }

      if (usersData.length === 0) {
        setData([])
      } else {
        setData(usersData)
      }
    } catch (error) {
      console.error('Error fetching data:', error)

      setData([])
    }
  }

  useEffect(() => {
    fetchData()
  }, [email])

  const rowTemplate = ({ item, rowKey }) => {
    return (
      <TableRow key={rowKey}>
        <TableCell>
          <Link
            component={RouterLink}
            to={{ pathname: '/detalhes-do-usuario', state: { data: item } }}
          >
            {`${item.first_name} ${item.last_name}`}
          </Link>
        </TableCell>

        <TableCell>{`${item.email}`}</TableCell>

        <TableCell>{item.cpf && formatCPF(item.cpf)}</TableCell>

        <TableCell>
          <div style={{ display: 'flex' }}>
            {item.products.conecta && (
              <div key="conecta" className={classes.productTag}>
                Conecta
              </div>
            )}
            {item.products.bds && (
              <div key="bds" className={classes.productTag}>
                BDS
              </div>
            )}
            {item.products.lfg && (
              <div key="bds" className={classes.productTag}>
                LFG
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <div>
        <Button
          color="primary"
          className={classes.backButton}
          onClick={() => history.push('/gerenciar-usuario')}
          startIcon={<LeftArrowIcon />}
        >
          GERENCIAR USUÁRIOS
        </Button>
        <ContentWithHeader title="Dados dos usuários">
          <Divider style={{ marginBottom: 48 }} />

          <div className={classes.filterContainer}>
            <SearchBarFilter
              filterOptions={filterOptions}
              searchPlaceHolder="Buscar por e-mail"
              handleSearchTerm={handleInputChange}
              defaultFilterOption="email"
            />
          </div>
          <Table
            initialData={{ data }}
            columns={{ headers }}
            rowTemplate={rowTemplate}
          />
        </ContentWithHeader>
      </div>
    </>
  )
}
