import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function ShowPdf({ authorization, url }) {
  const [numPages, setNumPages] = useState(null)
  const fileParameters = { 
    httpHeaders: {
      authorization: authorization
    },
    url: url,
    withCredentials: true,
  }

  function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages)
  }

  return (
    <Document file={fileParameters} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page 
        key={`page_${index + 1}`} 
        pageNumber={index + 1} 
        />
        ),)}
    </Document>
  );
  }

ShowPdf.propTypes = {
  authorization: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}
