import { Dialog, TextInput, Alert } from '@equipe-ninja/saraiva-ui'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@material-ui/core'
import useStyles from './styles'
import userService from '../../../../services/accounts/users'
import { userEditAddressValidation } from '../../../../domain/user-management-content/user-management-page/user-managent-edit-validation'

export function ModalEditAddress({ open, data, onClose, onClick }) {
  const styles = useStyles()
  const { field, label, address } = data
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [formState, setFormState] = useState({ justify: '' })
  const [formErrors, setFormErrors] = useState({})
  const [informationChange, setInformationChange] = useState([])
  const [isChangedField, setIsChangedField] = useState()
  const [disabledButtonNext, setDisabledButtonNext] = useState(true)

  const changeStep = () => {
    setOpenConfirmDialog(!openConfirmDialog)
    onClose()
  }

  const handleTitle = () => {
    if (field === 'password') {
      return `Resetar ${label.toLowerCase()} do usuário`
    }
    return `Alterar ${label.toLowerCase()} do usuário`
  }

  const validateFields = (form) => {
    setIsChangedField(false)
    setDisabledButtonNext(true)
    const { errors } = userEditAddressValidation(form)
    setFormErrors(errors)
    const messages = []
    if (!errors.justify) {
      if (!errors.cep) {
        messages.push(`'${address.cep}' para '${form.cep}'?`)
        setDisabledButtonNext(false)
      }
      if (!errors.street) {
        messages.push(`'${address.street}' para '${form.street}'?`)
        setDisabledButtonNext(false)
      }
      if (!errors.neighborhood) {
        messages.push(`'${address.neighborhood}' para '${form.neighborhood}'?`)
        setDisabledButtonNext(false)
      }
      if (!errors.city) {
        messages.push(`'${address.city}' para '${form.city}'?`)
        setDisabledButtonNext(false)
      }
      if (!errors.state) {
        messages.push(`'${address.state}' para '${form.state}'?`)
        setDisabledButtonNext(false)
      }
      setInformationChange(messages)
    }
    const message = 'O campo deve ser diferente do anterior'
    if (
      errors.cep === message &&
      errors.street === message &&
      errors.neighborhood === message &&
      errors.city === message &&
      errors.state === message
    ) {
      setIsChangedField(true)
    }
  }

  const onChangeHandlerForEvent = (fieldTochange, valueInput) => {
    setFormState({ ...formState, [fieldTochange]: valueInput })
    validateFields({ ...formState, [fieldTochange]: valueInput })
  }

  const handleSaveChanges = () => {
    setOpenConfirmDialog(false)
    onClick({
      ...formState,
      date: new Date(),
      changedBy: userService.getEmail(),
      informationChange
    })
  }

  const addressOld = {
    cepold: address.cep,
    streetold: address.street,
    neighborhoodold: address.neighborhood,
    cityold: address.city,
    stateold: address.state
  }

  useEffect(() => {
    setFormState({
      ...formState,
      ...address,
      ...addressOld
    })
    setFormErrors({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <>
      <Dialog
        open={open}
        title={handleTitle()}
        secondaryButton="CANCELAR"
        primaryButton="PRÓXIMO"
        handleClose={() => onClose(false)}
        handleConfirm={changeStep}
        content=""
        isDisabled={disabledButtonNext}
      >
        <Divider />
        <div className={styles.divInputs}>
          <>
            <TextInput
              color="primary"
              size="full"
              className={styles.inputs}
              label="CEP"
              onChange={(e) => onChangeHandlerForEvent('cep', e.target.value)}
              required
              fullWidth
              value={formState.cep}
            />
            <TextInput
              color="primary"
              size="full"
              className={styles.inputs}
              label="Endereço"
              onChange={(e) =>
                onChangeHandlerForEvent('street', e.target.value)
              }
              required
              fullWidth
              value={formState.street}
            />
            <TextInput
              color="primary"
              size="full"
              className={styles.inputs}
              label="Bairro"
              onChange={(e) =>
                onChangeHandlerForEvent('neighborhood', e.target.value)
              }
              required
              fullWidth
              value={formState.neighborhood}
            />
            <TextInput
              color="primary"
              size="full"
              className={styles.inputs}
              label="Cidade"
              onChange={(e) => onChangeHandlerForEvent('city', e.target.value)}
              required
              fullWidth
              value={formState.city}
            />
            <TextInput
              color="primary"
              size="full"
              className={styles.inputs}
              label="Estado"
              onChange={(e) => onChangeHandlerForEvent('state', e.target.value)}
              required
              fullWidth
              value={formState.state}
            />
            {isChangedField && (
              <div className={styles.inputs}>
                <Alert severity="error">
                  Algum campo de endereço deve ser modificado
                </Alert>
              </div>
            )}
          </>
          <TextInput
            color="primary"
            size="full"
            className={styles.inputs}
            label="Justificativa da alteração"
            onChange={(e) => onChangeHandlerForEvent('justify', e.target.value)}
            error={!!formErrors.justify}
            helperText={formErrors.justify}
            required
            fullWidth
            value={formState.justify}
          />
        </div>
      </Dialog>

      <Dialog
        title={handleTitle()}
        secondaryButton="CANCELAR"
        primaryButton="SALVAR"
        size="md"
        handleClose={changeStep}
        handleConfirm={handleSaveChanges}
        open={openConfirmDialog}
        label="Sim, tenho certeza!"
        alert
      >
        {informationChange.length &&
          informationChange.map((labelMessage) => (
            <>
              {labelMessage} <br />
            </>
          ))}
      </Dialog>
    </>
  )
}

ModalEditAddress.defaultProps = {
  open: false
}

ModalEditAddress.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.objectOf.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
