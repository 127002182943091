import React, { useContext, useState, useEffect, useCallback } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'

import { useParams } from 'react-router-dom'
import useStyles from './styles'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import { CardComplementaryMaterial } from './components/CardComplementaryMaterial'
import { getComplementaryMaterials } from '../../../../services/complementary-material'
import { DialogComplementaryMaterialManagement } from './components/DialogComplementaryMaterialManagement'

export function ComplementaryMaterials() {
  const classes = useStyles()
  const { setErrorMessage } = useContext(GeneralContext)
  const { id } = useParams()
  const [isAddMaterialDialogOpen, setIsAddMaterialDialogOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [complementaryMaterialsList, setComplementaryMaterialsList] = useState(
    []
  )

  const fetchComplementaryMaterials = useCallback(async () => {
    setIsFetching(true)

    try {
      const data = await getComplementaryMaterials(id)
      setComplementaryMaterialsList(data)
    } catch (err) {
      setErrorMessage('Não foi possível buscar os materiais complementares')
    } finally {
      setIsFetching(false)
    }
  }, [id, setErrorMessage])

  const openAddMaterialDialog = () => setIsAddMaterialDialogOpen(true)
  const closeAddMaterialDialog = () => {
    setIsAddMaterialDialogOpen(false)
    setTimeout(() => {
      fetchComplementaryMaterials()
    }, 1000)
  }

  const handleAddComplementaryMaterial = () => {
    openAddMaterialDialog()
  }

  useEffect(() => {
    fetchComplementaryMaterials()
  }, [fetchComplementaryMaterials])

  return (
    <div>
      <div className={classes.subTitle}>
        Aqui é possível cadastrar materiais complementares e o simulado do
        curso.
      </div>

      <DialogComplementaryMaterialManagement
        isOpen={isAddMaterialDialogOpen}
        handleOpen={openAddMaterialDialog}
        handleClose={closeAddMaterialDialog}
        mode="create"
      />

      <Button
        color="primary"
        variant="contained"
        onClick={handleAddComplementaryMaterial}
      >
        ADICIONAR MATERIAL
      </Button>

      <div className={classes.cardsListContainer}>
        {isFetching ? (
          <CircularProgress />
        ) : (
          complementaryMaterialsList.map((material) => (
            <CardComplementaryMaterial
              key={material.id}
              id={material.id}
              name={material.name}
              order={material.order}
              contentsCount="Sem informação" // ainda não temos essa informação vindo da api
            />
          ))
        )}
      </div>

      <SnackbarOutlet />
    </div>
  )
}
