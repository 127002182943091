import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'

export const DeleteIcon = ({ onClick, className, classButton, edge }) => {
  return (
    <IconButton className={classButton} onClick={onClick} edge={edge}>
      <DeleteRoundedIcon className={className} />
    </IconButton>
  )
}

DeleteIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  classButton: PropTypes.string,
  edge: PropTypes.string
}

DeleteIcon.defaultProps = {
  className: null,
  classButton: null,
  edge: null
}

export default DeleteIcon
