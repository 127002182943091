import {
  SCHEDULED_CONTENT_REMOTE_FIELDS as REMOTE,
  SCHEDULED_CONTENT_REMOTE_TYPES as REMOTE_TYPES
} from '../remote-fields/scheduled-content'

import { SCHEDULED_CONTENT_FIELDS as FIELD } from '../../../domain/courses'
import { RemoteDateUtils } from '../../utils/remote-date-utils'
import { ScheduledContentTypeAdapter } from './scheduled-content-type'
import { ScheduledContentExerciseTypeAdapter } from './scheduled-content-exercise-types'

const inbound = (item) => ({
  [FIELD.ANSWER_CONTENT]: item[REMOTE.TEMPLATE],
  [FIELD.ANSWER_PUBLISHING_DATE]: RemoteDateUtils.parseRemoteDate(
    item[REMOTE.TEMPLATE_DATE]
  ),
  [FIELD.CONTENT]: item[REMOTE.TEXT_ENUNCIATED],
  [FIELD.END_DATE]: RemoteDateUtils.parseRemoteDate(item[REMOTE.FINAL_DATE]),
  [FIELD.ID]: item[REMOTE.ID],
  [FIELD.INITIAL_DATE]: RemoteDateUtils.parseRemoteDate(
    item[REMOTE.INITIAL_DATE]
  ),
  [FIELD.LINK]: item[REMOTE.FORM_LINK],
  [FIELD.TITLE]: item[REMOTE.TITLE],
  [FIELD.SCHEDULED_CONTENT_TYPE]: ScheduledContentTypeAdapter.inbound(
    REMOTE_TYPES.OAB_SECOND_PHASE
  ),
  [FIELD.EXERCISE_TYPE]: ScheduledContentExerciseTypeAdapter.inbound(
    item[REMOTE.TYPE]
  )
})

const inboundList = (data) => {
  return data.map((item) => inbound(item))
}

const outbound = (item) => ({
  [REMOTE.TEMPLATE]: item[FIELD.ANSWER_CONTENT],
  [REMOTE.TEMPLATE_DATE]: new Date(
    item[FIELD.ANSWER_PUBLISHING_DATE]
  ).toISOString(),
  [REMOTE.TEXT_ENUNCIATED]: item[FIELD.CONTENT],
  [REMOTE.FINAL_DATE]: new Date(item[FIELD.END_DATE]).toISOString(),
  [REMOTE.TYPE]: ScheduledContentExerciseTypeAdapter.outbound(
    item[FIELD.EXERCISE_TYPE]
  ),
  [REMOTE.ID]: item[FIELD.ID],
  [REMOTE.INITIAL_DATE]: new Date(item[FIELD.INITIAL_DATE]).toISOString(),
  [REMOTE.FORM_LINK]: item[FIELD.LINK],
  [REMOTE.SCHEDULED_CONTENT_TYPE]: ScheduledContentTypeAdapter.outbound(
    item[FIELD.SCHEDULED_CONTENT_TYPE]
  ),
  [REMOTE.TITLE]: item[FIELD.TITLE]
})

export const ScheduledContentAdapter = {
  inbound,
  inboundList,
  outbound
}
