export const BANNER_TYPE = Object.freeze({
  ROTARY: {
    label: 'Rotativo',
    value: 'rotary'
  },
  SALE: {
    label: 'Ofertas em destaque',
    value: 'sale'
  }
})
