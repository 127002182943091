import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../shared/shared-style-classes'

const useStyles = makeStyles(() => ({
  ...spacing,
  hidden: {
    display: 'none'
  }
}))

export default useStyles
