import { PUBLISHING_STATUS } from '../../../../domain/promotional-content/promotional-pages'

const publishingStatusOutbound = (localValue) => {
  switch (localValue) {
    case PUBLISHING_STATUS.ACTIVE.value:
      return true

    default:
      return false
  }
}

const publishingStatusInbound = (remoteValue) => {
  switch (remoteValue) {
    case true:
      return PUBLISHING_STATUS.ACTIVE.value

    default:
      return PUBLISHING_STATUS.INACTIVE.value
  }
}

export const PromotionalPageAdapter = {
  outbound: {
    publishing_status: publishingStatusOutbound
  },
  inbound: {
    publishing_status: publishingStatusInbound
  }
}
