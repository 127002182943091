import React, { useContext, useState, useEffect } from 'react'
import { Snackbar } from '@equipe-ninja/saraiva-ui'
import { GeneralContext } from '../../contexts/GeneralContext'

const TYPES = Object.freeze({
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success'
})

/**
 * insere todos os tipos de snackbars, faz uso do {@link GeneralContext}
 *
 * ### Exemplo
 * ```jsx
 * <SnackbarOutlet />
 * ```
 */
export const SnackbarOutlet = () => {
  const [showControls, setShowControls] = useState({
    [TYPES.ERROR]: false,
    [TYPES.INFO]: false,
    [TYPES.SUCCESS]: false
  })

  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    infoMessage,
    setInfoMessage
  } = useContext(GeneralContext)

  const onClose = (type) => {
    setShowControls((prev) => ({ ...prev, [type]: false }))
    let setMessage = null

    switch (type) {
      case TYPES.ERROR:
        setMessage = setErrorMessage
        break
      case TYPES.INFO:
        setMessage = setInfoMessage
        break
      default:
        setMessage = setSuccessMessage
    }

    setTimeout(() => setMessage(''), 250)
  }

  useEffect(() => {
    setShowControls((prev) => ({
      ...prev,
      error: !!errorMessage,
      info: !!infoMessage,
      success: !!successMessage
    }))
  }, [successMessage, infoMessage, errorMessage])

  return (
    <>
      <Snackbar
        severity="error"
        show={showControls[TYPES.ERROR]}
        handleClose={() => onClose(TYPES.ERROR)}
      >
        {errorMessage}
      </Snackbar>

      <Snackbar
        severity="success"
        show={showControls[TYPES.SUCCESS]}
        handleClose={() => onClose(TYPES.SUCCESS)}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        severity="info"
        show={showControls[TYPES.INFO]}
        handleClose={() => onClose(TYPES.INFO)}
      >
        {infoMessage}
      </Snackbar>
    </>
  )
}
