/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, Fragment } from 'react'
import { Dialog, TextInput } from '@equipe-ninja/saraiva-ui'

import { GeneralContext } from '../../contexts/GeneralContext'
import { ConnectionsContext } from '../../contexts/ConnectionsContext'
import { cnpjMask, isInvalidCnpjNumber } from '../../helpers/cnpjControls'

import Messages from '../../helpers/messages'
import ConfirmModal from './ConfirmModal'

import IntegrationsService from '../../services/integrationsService'
import OffersService from '../../services/offersService'

import useStyles from './dialogStyles'

const EditForm = ({
  connectionId,
  client_name,
  sku,
  client_id_number,
  handleConfirm
}) => {
  const classes = useStyles()

  const { saving, setSaving } = useState(false)

  const [isInvalidCnpj, setIsInvalidCnpj] = useState(false)
  const [cnpjErrorMessage, setCnpjErrorMessage] = useState(
    Messages.connectionForm.errorOnCNPJLength
  )

  const [iesName, setIesName] = useState(client_name)
  const [cnpj, setCnpjValue] = useState(client_id_number)
  const [libraryCode, setLibraryCode] = useState(sku)
  const [id, setId] = useState(connectionId)
  const [disabled, setDisabled] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const { setSuccessMessage, setErrorMessage, setUpdated } =
    useContext(GeneralContext)
  const { showingConnectionForm, toggleConnectionForm } =
    useContext(ConnectionsContext)

  const setCnpj = async (_cnpj) => {
    if (_cnpj.length > 18) return

    setCnpjValue(cnpjMask(_cnpj))

    if (isInvalidCnpjNumber(_cnpj)) {
      setIsInvalidCnpj(true)
      setCnpjErrorMessage(Messages.connectionForm.errorOnCNPJLength)
    } else {
      setIsInvalidCnpj(false)
      /* findClientByCnpj(_cnpj) */
    }
  }

  const findLibraryByCode = async () => {
    try {
      let response = await OffersService.searchServiceBySku(libraryCode)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      response = await response.json()
    } catch (error) {
      console.error(error)
    }
  }

  const save = async () => {
    try {
      const payload = {
        name: iesName,
        id_number: cnpj,
        sku: libraryCode
      }

      const response = await IntegrationsService.updateIntegrations(
        id,
        'marc_integration',
        payload
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setSuccessMessage(Messages.configIntegration.success)
      setUpdated(true)
    } catch (error) {
      console.error(error)
      setErrorMessage(Messages.configIntegration.error)
    } finally {
    }
  }

  const isDialogDisabled = () => {
    return saving || cnpj.length !== 18
  }

  const handleClose = () => {
    setCnpjValue('')
    setIsInvalidCnpj(false)
    setIesName('')
    setLibraryCode('')
    toggleConnectionForm(false)
  }

  return (
    <Fragment>
      {!confirm && (
        <Dialog
          isDisabled={isDialogDisabled()}
          open={showingConnectionForm}
          size="md"
          title="Editar Informações"
          secondaryButton="cancelar"
          primaryButton="salvar"
          handleClose={handleClose}
          handleConfirm={() => setConfirm(true)}
          handleCheck={() => setDisabled(!disabled)}
          disabled={disabled}
        >
          <div className={classes.root}>
            <TextInput
              variant="outlined"
              size="full"
              name="nome"
              color="primary"
              label="Nome da Instituição"
              value={iesName}
              onChange={(e) => setIesName(e.target.value)}
              required
              id="1"
            />
            <TextInput
              size="full"
              name="biblioteca"
              color="primary"
              label="Chave da Biblioteca"
              value={libraryCode}
              onChange={(e) => setLibraryCode(e.target.value)}
              onBlur={findLibraryByCode}
              required
              id="2"
            />
            <TextInput
              variant="outlined"
              size="full"
              name="cpnj"
              color="primary"
              label="CNPJ da Instituição"
              value={cnpj}
              error={isInvalidCnpj}
              onChange={(e) => setCnpj(e.target.value)}
              helperText={isInvalidCnpj ? cnpjErrorMessage : ''}
              required
              id="3"
            />
          </div>
        </Dialog>
      )}
      {confirm && (
        <ConfirmModal
          disabled={disabled}
          handleCheck={() => setDisabled(!disabled)}
          handleClickClose={() => setConfirm(false)}
          handleConfirm={() => save()}
        />
      )}
    </Fragment>
  )
}

export default EditForm
