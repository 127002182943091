import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 40,
    marginBottom: 24
  },
  addConnectionBtn: {
    marginBottom: 24
  }
}))

export default useStyles
