export const USER_MANAGEMENT_ORDER_DETAIL_FIELDS = Object.freeze({
  KEY: { value: 'id', label: 'identificador' },
  ACQUISITION_DATE: {
    value: 'acquisitionDate',
    label: 'Data de compra do pedido'
  },
  ORDER_STATUS: { value: 'orderStatus', label: 'Status do pedido' },
  ORDER_PRICE: { value: 'orderPrice', label: 'Preço do pedido' },
  PAYMENT_METHOD: { value: 'paymentMethod', label: 'Método de pagamento' },
  CARD_FLAG: { value: 'cardFlag', label: 'Bandeira do cartão' },
  CARD_NAME: { value: 'cardName', label: 'Nome (impresso no cartão)' },
  CODE: { value: 'code', label: 'Código' },
  DUE_DATE: { value: 'dueDate', label: 'Vencimento' },
  BUYER_DOCUMENT: { value: 'buyerDocument', label: 'CPF do comprador' },
  NUMBER_INSTALLMENTS: {
    value: 'numberInstallments',
    label: 'Número de parcelas'
  },
  BILLING_ADDRESS: { value: 'billingAddress', label: 'Endereço da cobrança' },
  STREET: { value: 'street', label: 'Rua' },
  CEP: { value: 'cep', label: 'CEP' },
  NUMBER: { value: 'number', label: 'Número' },
  COUNTRY: { value: 'country', label: 'País' },
  CITY: { value: 'city', label: 'Cidade' },
  NEIGHBORHOOD: { value: 'neighborhood', label: 'Bairro' },
  STATE: { value: 'state', label: 'Estado' },
  VOUCHER: { value: 'voucher', label: 'Voucher Aplicado' },
  VOUCHER_NAME: { value: 'voucherName', label: 'Nome do Voucher' },
  VOUCHER_PERCENTAGE: {
    value: 'voucherPercentage',
    label: 'Porcentagem do Voucher'
  },
  COURSE_SUBTOTAL: { value: 'courseSubtotal', label: 'Subtotal dos cursos' },
  ORDER_SUMMARY: { value: 'orderSummary', label: 'Resumo do pedido' },
  COURSE_NAME: { value: 'courseName', label: 'Nome do curso' },
  COURSE_PRICE: { value: 'coursePrice', label: 'Valor total do curso' },
  COURSE_IMAGE: { value: 'courseImage', label: 'Imagem do curso' }
})
