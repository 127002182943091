import Joi from 'joi'
import { SCHEDULED_CONTENT_FIELDS as F } from '../scheduled-content-fields'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../../helpers/validation'

const schema = Joi.object({
  [F.ANSWER_CONTENT]: Joi.string().required(),
  [F.ANSWER_PUBLISHING_DATE]: Joi.date().required(),
  [F.CONTENT]: Joi.string().required(),
  [F.INITIAL_DATE]: Joi.date().required(),
  [F.END_DATE]: Joi.date().min(Joi.ref(F.INITIAL_DATE)).required().messages({
    'date.min': 'Deve ser posterior a data de início.'
  }),
  [F.EXERCISE_TYPE]: Joi.string().required(),
  [F.ID]: Joi.number().allow(null),
  [F.LINK]: Joi.string().required(),
  [F.SCHEDULED_CONTENT_TYPE]: Joi.string().required(),
  [F.TITLE]: Joi.string().required()
})

const context = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return { today }
}

export const scheduledContentValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schema, context())
}

export const scheduledContentFieldValidation = (field, value, formValues) => {
  return validateSingleFieldAndGenerateErrors(
    field,
    value,
    formValues,
    schema,
    context()
  )
}
