/**
 * Transforma uma string que representa uma data com horas
 *  recebida do servidor para um objet do Tipo {Date}
 *
 * ### Example
 * ```js
 * const dateString = "2022-03-10T13:37:37"
 * RemoteDateUtils.parseRemoteDate(dateString)
 * // Date Thu Mar 10 2022 13:37:37 GMT-0300 (Brasilia Standard Time)
 * ```
 *
 * @param {string} dateString - um date no formado de string vinda do servidor
 * @returns {Date}
 */
export const parseRemoteDateTime = (dateString) => {
  return dateString && new Date(dateString)
}

export const parseRemoteDateTimeFromUTC = (dateString) => {
  return dateString && new Date(`${dateString}Z`)
}

export const remoteDateStringToHuman = (dateString) => {
  return dateString && new Date(dateString).toLocaleDateString('pt-BR')
}
