/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Table from '@equipe-ninja/saraiva-ui/core/table/Table'
import { VOUCHER_LIST_ITEM_FIELDS } from '../../../../domain/vouchers'

const {
  CAMPAIGN_NAME,
  DISCOUNT_VALUE,
  END_DATE,
  PREFIX,
  START_DATE,
  TOTAL_AS_TEXT,
  VOUCHER_TYPE,
  STATUS
} = VOUCHER_LIST_ITEM_FIELDS

const headerRow = [
  {
    name: 'Nome da campanha',
    order: true,
    code: CAMPAIGN_NAME
  },
  {
    name: 'Prefixo',
    order: true,
    code: PREFIX
  },
  {
    name: 'Ativados',
    order: true,
    code: TOTAL_AS_TEXT
  },
  {
    name: 'Desconto',
    order: true,
    code: DISCOUNT_VALUE
  },
  {
    name: 'Data início',
    order: true,
    code: START_DATE
  },
  {
    name: 'Data fim',
    order: true,
    code: END_DATE
  },
  { name: 'Tipo de voucher', order: true, code: VOUCHER_TYPE },
  { name: 'Status do voucher', order: true, code: STATUS }
]

export const VouchersTable = ({ data, onCheckRows, selectedRows }) => {
  return (
    <Table
      displayRowCheckbox
      selectedRows={selectedRows}
      onCheckRows={onCheckRows}
      cells={headerRow}
      rows={data}
    />
  )
}

VouchersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onCheckRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.any).isRequired
}

VouchersTable.defaultProps = {}
