import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Divider, Typography } from '@material-ui/core'
import Users from '../../../../../services/accounts/users'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import messages from '../../../../../helpers/messages'

export default function EditCaeDialog({
  open,
  onClose,
  currentCae,
  userId,
  onSuccessfulUpdate
}) {
  const [cae, setCae] = useState('')
  const [isCaeValid, setIsCaeValid] = useState(false)
  const [containsLetters, setContainsLetters] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const { setErrorMessage } = useContext(GeneralContext)

  const validateCae = (cae) => {
    const isValidFormat = /^(\d{3}\.?){1,}-?\d+$/.test(cae.trim())
    const containsLetters = /[a-zA-Z]/.test(cae)
    return { isValidFormat, containsLetters }
  }

  const handleCaeChange = (cae) => {
    const { isValidFormat, containsLetters } = validateCae(cae)
    setCae(cae)
    setIsCaeValid(isValidFormat)
    setContainsLetters(containsLetters)
  }

  const handleAlterarClick = () => {
    setIsConfirmationOpen(true)
  }

  const handleConfirm = async () => {
    try {
      const new_cae = cae

      const response = await Users.editCae(userId, currentCae, new_cae)

      if (response.ok) {
        handleCancel()
        onSuccessfulUpdate()
      } else {
        handleCancel()
        setErrorMessage(messages.caeInvalid.error)
        console.error('Error updating CAE', response)
      }
    } catch (error) {
      console.error('Exception while updating CAE', error)
    }
  }

  const handleCancel = () => {
    onClose()
    setCae('')
    setIsConfirmationOpen(false)
    setIsCaeValid(false)
  }

  const handleClose = () => {
    onClose()
    setCae('')
    setIsCaeValid(false)
    setContainsLetters(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: { width: '600px', height: '292px' }
        }}
      >
        <DialogTitle id="form-dialog-title">Alterar CAE</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="current-email"
            label="Atual CAE"
            fullWidth
            variant="standard"
            disabled
            defaultValue={currentCae}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              style: { borderBottom: '1px solid rgba(0, 0, 0, 0.192)' }
            }}
          />
          <TextField
            style={{ marginTop: '15px' }}
            error={containsLetters}
            helperText={
              containsLetters ? 'Não é possível inserir letras nesse campo' : ''
            }
            id="new-cae"
            label="Novo CAE"
            fullWidth
            variant="outlined"
            value={cae}
            onChange={(event) => handleCaeChange(event.target.value)}
            FormHelperTextProps={{
              style: { margin: 0 }
            }}
          />
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCELAR
          </Button>
          <Button
            onClick={handleAlterarClick}
            color="primary"
            disabled={!isCaeValid}
          >
            ALTERAR
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmationOpen}
        onClose={handleCancel}
        PaperProps={{
          style: { width: '600px', height: '292px' }
        }}
      >
        <DialogTitle>Alterar informação</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Typography style={{ paddingTop: 20, paddingBottom: 20 }}>
            Você tem certeza que deseja editar essa informação?
          </Typography>
          <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            Ao realizar essa ação, ela será editada em todos os contextos em que
            está sendo utilizada.
          </Typography>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button onClick={handleCancel} style={{ color: 'gray' }}>
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
EditCaeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  currentCae: PropTypes.number.isRequired,
  onSuccessfulUpdate: PropTypes.func.isRequired
}
