/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import { Grid, Typography, Divider } from '@material-ui/core'
import {
  Dialog,
  SearchBar,
  Snackbar,
  Switch,
  Table
} from '@equipe-ninja/saraiva-ui'
import { snackbarStyle } from '../../layouts/styles'

import useStyles from './styles.js'
import { GeneralContext } from '../../contexts/GeneralContext'
import Loading from '../../components/Loading'
import Messages from '../../helpers/messages'
import AccessService from '../../services/accessService.js'

function AccessManager() {
  const classes = useStyles()
  const [tempUser, setTempUser] = useState()
  const [accessUsers, setAccessUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [messageDialog, setMessageDialog] = useState({
    open: false,
    title: '',
    text: ''
  })
  // eslint-disable-next-line
  const [alertSnackBar, setAlertSnackBar] = useState(false)
  const snackbarStyles = snackbarStyle()
  const {
    loading,
    setLoading,
    setErrorMessage,
    errorMessage,
    setSuccessMessage,
    productSelected,
    successMessage
  } = useContext(GeneralContext)

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
    }, 800)
  ).current

  const handleSearchValue = (e) => {
    setSearchTerm(e.target.value)
    delayedQuery(e.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
  }

  function sortByName(a, b) {
    if (a.first_name < b.first_name) {
      return -1
    }
    if (a.first_name > b.first_name) {
      return 1
    }
    return 0
  }

  const changeUserAccess = (user) => {
    setTempUser(user)

    if (user.access_to_bo) {
      setMessageDialog({
        open: true,
        title: 'Bloquear Acesso',
        text: Messages.accessManager.denyAcces
      })
    } else {
      setMessageDialog({
        open: true,
        title: 'Liberação de Acesso',
        text: Messages.accessManager.allowAccess
      })
    }
  }

  const orderByInnactive = (users) => {
    if (!users[0].active) {
      // eslint-disable-next-line no-param-reassign
      users = users.sort((a, b) => a.active - b.active)
    }
    // Esse retorno é um workaround pois a API não retorna ordenado e nem no mesmo formato de objeto
    return users.email ? [users] : users
  }

  // eslint-disable-next-line consistent-return
  const clearEmptyProperties = (result) => {
    if ((result && result.length) || result.data) {
      if (result.data) {
        return orderByInnactive(
          result.data.sort(sortByName).filter((v) => v.first_name != null)
        )
      }
      if (result.length === 1) {
        return orderByInnactive(result.filter((v) => v.first_name != null))
      }
      return orderByInnactive(
        result.sort(sortByName).filter((v) => v.first_name != null)
      )
    }
  }

  async function fetchAccessUsers() {
    try {
      setLoading(true)

      const response = await AccessService.getAccessUsers(
        'jwt_integration',
        productSelected.token
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      if (result && result.data) {
        const clearedData = clearEmptyProperties(result)
        setAccessUsers(clearedData)
      }
    } catch (err) {
      setAlertSnackBar(true)
      setErrorMessage('Não foi possível realizar a busca. Tente novamente.')
    } finally {
      clearSearch()
      setLoading(false)
    }
  }

  async function handleActiveStatus() {
    try {
      setLoading(true)

      setMessageDialog({
        open: false,
        title: '',
        text: ''
      })

      const response = await AccessService.releaseAccess(tempUser.id)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      } else {
        const updatedUser = await response.json()

        setTempUser(updatedUser)
        fetchAccessUsers(updatedUser)

        setSuccessMessage(
          updatedUser.active
            ? Messages.accessManager.accessAllowed
            : Messages.accessManager.accessDenied
        )
      }
    } catch (err) {
      setAlertSnackBar(true)
      setErrorMessage(
        'Não foi possível liberar acesso no momento. Tente novamente.'
      )
    } finally {
      debounce(() => {
        setLoading(false)
      }, 2000)
    }
  }

  async function fetchUsersByNomeEmail() {
    try {
      setLoading(true)

      const response = await AccessService.searchUsersByNameEmail(searchValue)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()

      setAccessUsers(
        result && result.data.length && clearEmptyProperties(result.data)
      )
    } catch (err) {
      setAlertSnackBar(true)
      setErrorMessage('Não foi possível realizar a busca. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    searchValue.length > 0 ? fetchUsersByNomeEmail() : fetchAccessUsers()
    // eslint-disable-next-line
  }, [searchValue])

  const CELLS = ['Nome', 'Email', 'Liberação de acesso ao BO']

  return (
    <div className={classes.container}>
      <Grid className={classes.root}>
        <Typography
          variant="h3"
          className={`${classes.text} ${classes.textH3}`}
        >
          Gerenciar acessos
        </Typography>
      </Grid>

      <Divider className={classes.divider} />

      <div className={classes.searchBar}>
        <SearchBar
          value={searchTerm}
          className={classes.searchBar}
          onChange={handleSearchValue}
          placeholder="Buscar por nome ou email"
          size="full"
          clearSearch={clearSearch}
          goBackSearch={clearSearch}
          onSubmit={(e) => {
            e.preventDefault()
          }}
        />
      </div>
      {loading ? (
        <Loading />
      ) : accessUsers ? (
        <Table
          cells={CELLS}
          rows={
            accessUsers.length &&
            accessUsers.map((user, index) => {
              const { id, first_name, email, active, access_to_bo } = user
              return {
                key: `#${id}-${index}`,
                name: first_name,
                email,
                access_to_bo: (
                  <Switch
                    key={`#${id}-${access_to_bo}`}
                    checked={access_to_bo}
                    color="primary"
                    disabled={false}
                    onChange={() => changeUserAccess(user)}
                  />
                )
              }
            })
          }
        />
      ) : (
        <Typography>Nenhum usuario encontrado</Typography>
      )}

      {messageDialog.open && (
        <Dialog
          open={Boolean(messageDialog.open)}
          title={messageDialog.title}
          secondaryButton="Cancelar"
          primaryButton="Confirmar"
          handleClose={() => setMessageDialog(false)}
          handleConfirm={(e) => handleActiveStatus(e.target.value)}
          content="Conteúdo default"
        >
          {messageDialog.text}
        </Dialog>
      )}
      <Snackbar
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
        autoHideDuration={5000}
        classes={() => snackbarStyles}
      >
        {successMessage}
      </Snackbar>
      <Snackbar
        severity="error"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
        autoHideDuration={5000}
        classes={() => snackbarStyles}
      >
        {errorMessage}
      </Snackbar>
    </div>
  )
}

export default AccessManager
