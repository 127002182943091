import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#624C92'
    }
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  span: {
    color: 'black'
  }
}))

function HomeLink({ size }) {
  const classes = useStyles()
  if (size === 0) {
    return <span className={classes.span}>Home</span>
  }
  return (
    <Link href="/lti/?id_token=&state=" className={classes.link}>
      Home
    </Link>
  )
}

export default function FolderBreadcrumb({ path }) {
  const classes = useStyles()
  const size = path.length
  const currentPath = []

  function appendToArray() {
    path.find(function (item, index) {
      if (index !== path.length - 1) {
        currentPath.push(item)
      }
    })
  }

  const lastPathPosition = path.find(function (item, index) {
    if (index == path.length - 1) {
      return item
    }
  })

  appendToArray()

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <HomeLink size={size} />
      {currentPath.map((content) => (
        <Link
          href={`/lti/?id_token=&state=&id=${content.id}`}
          className={classes.link}
        >
          {content.name}
        </Link>
      ))}
      {lastPathPosition !== undefined ? (
        <span className={classes.span}>{lastPathPosition.name}</span>
      ) : (
        ''
      )}
    </Breadcrumbs>
  )
}

FolderBreadcrumb.propTypes = {
  path: PropTypes.arrayOf(PropTypes.any).isRequired
}
HomeLink.propTypes = {
  size: PropTypes.number.isRequired
}
