import {
  COURSE_OVERVIEW_RAW_DATA as F,
  COURSE_ECOMMERCE_FIELDS as E_F,
  ECOMMERCE_FORM_FIELDS as ECO_FORM
} from '../entities'

export const generateEcommerceFormFromRaw = (newData) => ({
  [ECO_FORM.ACCESS_WINDOW]: newData?.[F.SERVICE]?.[E_F.ACCESS_WINDOW_IN_MONTHS],
  [ECO_FORM.CATEGORY]: newData?.[F.SERVICE]?.[E_F.CATEGORY_ID],
  [ECO_FORM.COURSE_LENGTH]: newData?.[F.SERVICE]?.[E_F.COURSE_LENGTH],
  [ECO_FORM.DESCRIPTION]: newData?.[F.SERVICE]?.[E_F.DESCRIPTION],
  [ECO_FORM.ID]: newData?.[F.SERVICE]?.[E_F.ID],
  [ECO_FORM.IMG_BINARY]: newData?.[F.SERVICE]?.[E_F.IMAGE_BINARY],
  [ECO_FORM.IMG_URL]: newData?.[F.SERVICE]?.[E_F.IMAGE_PATH],
  [ECO_FORM.KEY_WORDS]: newData?.[F.SERVICE]?.[E_F.KEYWORDS],
  [ECO_FORM.NAME]: newData?.[F.SERVICE]?.[E_F.NAME],
  [ECO_FORM.SKU]: newData?.[F.SERVICE]?.[E_F.SKU],
  [ECO_FORM.SUB_CATEGORY]: newData?.[F.SERVICE]?.[E_F.SUBCATEGORY_ID],
  [ECO_FORM.URL]: newData?.[F.SERVICE]?.[E_F.SLUG],
  [ECO_FORM.VALUE]: newData?.[F.SERVICE]?.[E_F.PRICE]
})
