import React, { useState } from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  SettingsInputCompositeRounded,
  SchoolRounded,
  Web,
  Home,
  Https
} from '@material-ui/icons'
import { generatePath, useHistory } from 'react-router-dom'
import { useStyles } from './styles'
import { RoutesMapping } from '../../helpers/routes-mapping'

const AccordionMenuItem = ({
  item,
  expanded,
  onAccordionChange,
  index,
  classes,
  onSubItemClick,
  selectedSubItem
}) => (
  <Accordion
    className={classes.accordion}
    expanded={expanded === `panel${index}`}
    onChange={onAccordionChange(`panel${index}`)}
  >
    <AccordionSummary
      className={classes.accordionSummary}
      expandIcon={
        <ExpandMoreIcon
          className={expanded === `panel${index}` ? classes.iconExpanded : ''}
        />
      }
    >
      {React.cloneElement(item.icon, {
        className: expanded === `panel${index}` ? classes.iconExpanded : ''
      })}
      <Typography
        className={`${classes.heading} ${
          expanded === `panel${index}` ? classes.iconExpanded : ''
        }`}
      >
        {item.label}
      </Typography>
    </AccordionSummary>
    <AccordionDetails className={classes.root}>
      <List component="div" disablePadding>
        {item.accordionDetails.map((detail, detailIndex) => (
          <ListItem
            key={detailIndex}
            button
            onClick={() => {
              detail.onClick()
              onSubItemClick(detail.label)
            }}
            className={
              selectedSubItem === detail.label ? classes.selectedListItem : ''
            }
          >
            <ListItemText primary={detail.label} />
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  </Accordion>
)

const SimpleMenuItem = ({
  item,
  onMenuItemClick,
  index,
  selectedIndex,
  classes
}) => (
  <ListItem
    button
    key={item.label}
    classes={{ root: classes.item, selected: classes.selectedItem }}
    onClick={(event) => {
      item.onClick()
      onMenuItemClick(event, index)
    }}
    selected={selectedIndex === index}
  >
    {item.icon}
    <ListItemText className={classes.itemLabel} primary={item.label} />
  </ListItem>
)

export default function MenuDrawer() {
  const [selectedIndex, setSelectedIndex] = useState(false)
  const [selectedSubItem, setSelectedSubItem] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const history = useHistory()
  const classes = useStyles()

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleSubItemClick = (subItemLabel) => {
    setSelectedSubItem(subItemLabel)
    setSelectedIndex(false)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setSelectedSubItem(null)

    if (index === 0 || index === 'accessPermissions') {
      setExpanded(false)
    } else if (listItems[index] && listItems[index].accordion) {
      handleAccordionChange(`panel${index}`)(
        event,
        expanded !== `panel${index}`
      )
    }
  }

  const listItems = [
    {
      icon: <Home className={classes.icon} />,
      label: 'Página inicial',
      onClick: () => {
        history.push('/')
      }
    },
    {
      icon: <SettingsInputCompositeRounded className={classes.icon} />,
      label: 'Gerenciar conexões',
      accordion: true,
      accordionDetails: [
        {
          label: 'LTI',
          onClick: () => {
            history.push('/connections')
          }
        },
        {
          label: 'Voucher',
          onClick: () => {
            history.push('/libraries')
          }
        },
        {
          label: 'Marc21',
          onClick: () => {
            history.push('/marc_connection')
          }
        },
        {
          label: 'JWT',
          onClick: () => {
            history.push('/jwt_connection')
          }
        }
      ]
    },
    {
      icon: <SchoolRounded className={classes.icon} />,
      label: 'Gerenciar conteúdos',
      accordion: true,
      accordionDetails: [
        {
          label: 'Conteúdo educacional',
          onClick: () => {
            history.push('/manage-content')
          }
        },
        {
          label: 'Conteúdo promocional',
          onClick: () => {
            history.push(generatePath(RoutesMapping.promotionalPageEntryPotint))
          }
        }
      ]
    },
    {
      icon: <Web className={classes.icon} />,
      label: 'Gerenciar plataformas',
      accordion: true,
      accordionDetails: [
        {
          label: 'Usuários',
          onClick: () => {
            history.push(RoutesMapping.userManagementPageEntryPoint)
          }
        },
        {
          label: 'Recursos e funcionalidades',
          onClick: () => {
            history.push(RoutesMapping.coursesEntryPoint)
          }
        }
      ]
    }
  ]

  return (
    <div>
      <Drawer variant="permanent" classes={{ paper: classes.paper }}>
        <List className={classes.drawerList}>
          {listItems.map((item, index) =>
            item.accordion ? (
              <AccordionMenuItem
                item={item}
                expanded={expanded}
                onAccordionChange={handleAccordionChange}
                index={index}
                classes={classes}
                onSubItemClick={handleSubItemClick}
                selectedSubItem={selectedSubItem}
              />
            ) : (
              <SimpleMenuItem
                item={item}
                onMenuItemClick={handleMenuItemClick}
                index={index}
                selectedIndex={selectedIndex}
                classes={classes}
              />
            )
          )}
        </List>
        <div className={classes.bottomList}>
          <Divider />
          <ListSubheader className={classes.listSubheader}>
            Gerenciar BackOffice
          </ListSubheader>
          <List>
            <ListItem
              button
              onClick={(event) => {
                handleMenuItemClick(event, 'accessPermissions')
                history.push('/access_manager')
              }}
              selected={selectedIndex === 'accessPermissions'}
              classes={{
                root: classes.muiButton
              }}
            >
              <Https
                className={
                  selectedIndex === 'accessPermissions'
                    ? classes.iconExpanded
                    : ''
                }
              />
              <ListItemText
                primary="Permissões de acessos"
                className={`${classes.itemLabel} ${
                  selectedIndex === 'accessPermissions'
                    ? classes.iconExpanded
                    : ''
                }`}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  )
}
