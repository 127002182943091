import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { CampaignAdapter } from '../adapters/campaign-adapter'
import { getResquestResponseHandler } from '../../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para carregamento dos detalhes de uma campanha de descontos
 */
const getDiscountCampaignDetails = (id) => {
  return new Promise((resolve, reject) => {
    const path = `/offers/api/v1/campaign/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, null, headers)
      .then((response) => {
        return getResquestResponseHandler(response)
      })
      .then((json) => {
        resolve(CampaignAdapter.inbound(json))
      })
      .catch((error) => reject(error))
  })
}

export { getDiscountCampaignDetails }
