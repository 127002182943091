import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { Tabs } from '@equipe-ninja/saraiva-ui'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { ScheduledContent } from './ScheduledContent'
import { SubjectContents } from './SubjectContents'
import { ComplementaryMaterials } from './ComplementaryMaterials'

export function RegisterElearning() {
  const { brand, id, name } = useParams()

  return (
    <ContentWithHeader
      title={name}
      titleSize="medium"
      backButtonRoute={generatePath(RoutesMapping.courseCreate, {
        brand,
        courseId: id
      })}
      backButtonText="CADASTRAR NO AMBIENTE DE APRENDIZAGEM"
    >
      <Tabs screenSize="desktop" color="primary" showDivider mode="grow">
        {[
          {
            label: 'Disciplinas',
            content: <SubjectContents />
          },
          {
            label: 'Materiais complementares',
            content: <ComplementaryMaterials />
          },
          {
            label: 'Conteúdos agendados',
            content: <ScheduledContent />
          }
        ]}
      </Tabs>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}
