import React, { useContext, useEffect } from 'react'
import ContentWithHeader from '../../components/ContentWithHeader'
import ProductSelect from '../../components/ProductSelect'
import { GeneralContext } from '../../contexts/GeneralContext'
import { BRANDS } from '../../domain/brands/brands'
import { PRODUCT_SELECTION_OPTIONS } from '../../domain/general/product-selection-options'
import { CourseList } from './CoursesList'
import { CourseConecta } from './CourseConecta'
import { useQueryParams } from '../../hooks/use-query-params'

export function Courses() {
  const { productSelected, setProduct } = useContext(GeneralContext)

  const query = useQueryParams()
  const brand = query.get('brand')

  async function saveSelectedProduct(product) {
    setProduct(product)
  }

  useEffect(() => {
    if (brand) {
      const productList = PRODUCT_SELECTION_OPTIONS.find(
        (p) => p.value === brand
      )
      setProduct(productList)
    } else {
      setProduct({})
    }
  }, [brand, setProduct])

  return (
    <ContentWithHeader title="Recursos das plataformas">
      <ProductSelect
        // eslint-disable-next-line
        onChange={saveSelectedProduct}
        productsOptions={[BRANDS.LFG.value, BRANDS.CONECTA.value]}
      />

      {productSelected.value === BRANDS.LFG.value && (
        <CourseList brand={productSelected.value} />
      )}
      {productSelected.value === BRANDS.CONECTA.value && (
        <CourseConecta brand={productSelected.value} />
      )}
    </ContentWithHeader>
  )
}
