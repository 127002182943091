import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Link as MuiLink
} from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { useRowStyles, useSubRowStyles } from '../../styles'
import { RoutesMapping } from '../../../../../../../helpers/routes-mapping'

export function SubjectsTableRow({ subject, isOpen, onExpand }) {
  const rowStyles = useRowStyles()
  const subRowStyles = useSubRowStyles()
  const location = useHistory()

  const navigateToDetails = (subjetcId, courseId) => {
    const targetUrl = generatePath(RoutesMapping.subjectDetails, {
      subjectId: String(subjetcId),
      courseId: String(courseId)
    })
    location.push(targetUrl)
  }

  return (
    <>
      <TableRow
        className={classNames(rowStyles.root, {
          expanded: isOpen
        })}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={onExpand}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <MuiLink
            onClick={() => navigateToDetails(subject.id, subject.courseId)}
            component={MuiLink}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {subject.name}
          </MuiLink>
        </TableCell>
        <TableCell align="left">{subject.courseOrigin}</TableCell>
        <TableCell align="left">{subject.courseSku}</TableCell>
        <TableCell align="left">{subject.createdAt}</TableCell>
        <TableCell align="left">{subject.updatedAt}</TableCell>
      </TableRow>
      <TableRow className={subRowStyles.root}>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="reuse-courses">
                <TableHead>
                  <TableRow>
                    <TableCell>Disciplina</TableCell>
                    <TableCell>Curso reutilizador</TableCell>
                    <TableCell>SKU do curso</TableCell>
                    <TableCell>Data de criação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subject.reuseCourses.map((reuse) => (
                    <TableRow key={reuse.id}>
                      <TableCell component="th" scope="row">
                        <MuiLink
                          onClick={() =>
                            navigateToDetails(reuse.id, reuse.courseId)
                          }
                          component={MuiLink}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                        >
                          {reuse.name}
                        </MuiLink>
                      </TableCell>
                      <TableCell align="left">
                        {reuse.reuseCourseName}
                      </TableCell>
                      <TableCell align="left">{reuse.courseSku}</TableCell>
                      <TableCell align="left">{reuse.createdAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

SubjectsTableRow.propTypes = {
  subject: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    courseOrigin: PropTypes.string.isRequired,
    courseSku: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    reuseCourses: PropTypes.arrayOf(
      PropTypes.objectOf({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        reuseCourseName: PropTypes.string.isRequired,
        courseSku: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  isOpen: PropTypes.bool,
  onExpand: PropTypes.func.isRequired
}

SubjectsTableRow.defaultProps = {
  isOpen: false
}
