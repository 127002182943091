import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { Orders } from './Orders'
import { OrderDetails } from './Orders/OrderDetails'
import { CourseDetails } from './Orders/CourseDetails'
import { Details } from './Details'

export function DetailPageSwitch() {
  return (
    <Switch>
      <Route
        exact
        path={RoutesMapping.userManagementPageDetail}
        component={Details}
      />
      <Route
        exact
        path={RoutesMapping.userManagementPageListOrder}
        component={Orders}
      />
      <Route
        exact
        path={RoutesMapping.userManagementPageOrderDetails}
        component={OrderDetails}
      />
      <Route
        exact
        path={RoutesMapping.userManagementPageCourseDetails}
        component={CourseDetails}
      />
    </Switch>
  )
}
