import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@equipe-ninja/saraiva-ui'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'

import useStyles from './styles.js'

export default function BackButton({ onClick }) {
  const classes = useStyles()

  return (
    <Button
      color="primary"
      startIcon={<ArrowBackIcon className={classes.icon} />}
      className={classes.button}
      onClick={onClick}
    >
      Voltar
    </Button>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
