import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Button, Tabs } from '@equipe-ninja/saraiva-ui'
import { Container, Divider, Typography } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { useRecoilValue } from 'recoil'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'

import { DetailsTabContent } from './components/DetailsTabContent'
import { FixationExerciseTabContent } from './components/FixationExerciseTabContent'
import { BRANDS } from '../../../domain/brands'
import { COURSES_LIST_TABS_LABELS_ENUM } from '../CoursesList'
import { useStyles } from './styles'
import { disciplineListState } from '../../../atoms/disciplines'
import { RoutesMapping } from '../../../helpers/routes-mapping'

export function SubjectDetails() {
  const styles = useStyles()
  const navigate = useHistory()
  const params = useParams()
  const disciplineId = Number(params.subjectId)
  const courseId = Number(params.courseId)
  const { data } = useRecoilValue(disciplineListState)
  const [disciplineDetail, setDisciplineDetail] = useState({
    disciplineTitle: '',
    currentCourse: '',
    originCourse: ''
  })

  const backToDisciplines = () => {
    const url = generatePath(RoutesMapping.courses, {
      brand: BRANDS.LFG.value
    })
    navigate.push(
      `${url}?brand=${BRANDS.LFG.value}&tab=${COURSES_LIST_TABS_LABELS_ENUM.SUBJECTS}`
    )
  }

  const computeDisciplineDetail = () => {
    const origin = data.find((discipline) => discipline.id === disciplineId)

    if (!origin) backToDisciplines()

    if (origin.courseId === courseId) {
      setDisciplineDetail({
        disciplineTitle: origin.name,
        currentCourse: origin.courseOrigin,
        originCourse: origin.courseOrigin
      })
      return
    }

    const reuse = origin.reuseCourses.find(
      (discipline) => discipline.courseId === courseId
    )

    if (!reuse) backToDisciplines()

    setDisciplineDetail({
      disciplineTitle: reuse.name,
      currentCourse: reuse.reuseCourseName,
      originCourse: origin.courseOrigin
    })
  }

  useEffect(() => {
    if (data.length === 0) return backToDisciplines()
    computeDisciplineDetail()
  }, [data])

  return (
    <>
      <SnackbarOutlet />
      <Container className={styles.container}>
        <Button
          className={styles.backButton}
          startIcon={<ChevronLeft />}
          color="primary"
          onClick={() =>
            navigate.push(
              `/curso?brand=${BRANDS.LFG.value}&tab=${COURSES_LIST_TABS_LABELS_ENUM.SUBJECTS}`
            )
          }
        >
          Disciplinas
        </Button>
        <Typography variant="h4">{disciplineDetail.disciplineTitle}</Typography>
        <Divider className={styles.divider} />
        <Typography
          className={styles.subtitle}
          variant="body2"
          color="textSecondary"
        >
          {disciplineDetail.currentCourse}
        </Typography>
        <Tabs
          screenSize="desktop"
          color="primary"
          variant="scrollable"
          mode="grow"
          showDivider
        >
          {[
            {
              label: 'Detalhes',
              content: <DetailsTabContent data={disciplineDetail} />
            },
            {
              label: 'Exercício de Fixação',
              content: (
                <FixationExerciseTabContent
                  id={disciplineId}
                  data={disciplineDetail}
                />
              )
            }
          ]}
        </Tabs>
      </Container>
    </>
  )
}
