import { Button, Dialog } from '@equipe-ninja/saraiva-ui'
import React, { useState, useContext, useEffect } from 'react'
import { useHistory, generatePath, useParams } from 'react-router-dom'
import { Delete } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormBannerRotary } from '../Form/FormBannerRotary'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { initialURLValueForBrand } from '../../../../domain/brands'
import {
  getBanner,
  updateBanner,
  deleteBanner
} from '../../../../services/promotional-content/banners-pages'
import useStyles from '../styles'
import {
  bannerRotationValidation,
  bannerRotationFieldValidation
} from '../../../../domain/promotional-content/banners/business/banners-ratativo-validation'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import ContentTitle from '../../../../components/ContentTitle'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { PromotionalContext } from '../../../../contexts/PromotionalContext'

const {
  KEY,
  TITLE,
  URL,
  SESSION,
  ORDER,
  IMG_WEB,
  IMG_RECURSIVE,
  START_DATE,
  END_DATE,
  STATUS
} = FIELDS

export function EditBanner() {
  const classes = useStyles()
  const history = useHistory()
  const [title, setTitle] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const { setTypeBanner } = useContext(PromotionalContext)
  const [isExecutingTask, setIsExecutingTask] = useState(false)

  const { id } = useParams()

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalBannerList,
    hasAccessToPromotionalPages
  )

  const handleDuplicateBanner = (idDuplicar, typeBanner) => {
    setTypeBanner(typeBanner)
    history.push(
      generatePath(RoutesMapping.promotionalBannerDuplicar, {
        brand,
        id: idDuplicar
      })
    )
  }

  const [formState, setFormState] = useState({
    type: 'rotary',
    [KEY.value]: null,
    [TITLE.value]: '',
    [URL[0].value]: initialURLValueForBrand(brand),
    [SESSION.value]: 2,
    [ORDER[0].value]: '',
    [IMG_WEB.value]: '',
    [IMG_RECURSIVE.value]: '',
    [END_DATE.value]: null,
    [START_DATE.value]: null,
    [STATUS.value]: ''
  })

  const [formErrors, setFormErrors] = useState({
    type: false,
    [KEY.value]: false,
    [TITLE.value]: false,
    [URL[0].value]: false,
    [SESSION.value]: false,
    [ORDER[0].value]: false,
    [IMG_WEB.value]: false,
    [IMG_RECURSIVE.value]: false,
    [START_DATE.value]: false,
    [END_DATE.value]: false,
    [STATUS.value]: false
  })

  const onChangeHandler = (field, dateValue) => {
    const fieldValidation = bannerRotationFieldValidation(
      field,
      dateValue,
      formState,
      'edit'
    )

    setFormState((prevState) => {
      return { ...prevState, [field]: dateValue }
    })

    setFormErrors((prev) => ({ ...prev, ...fieldValidation }))
  }

  const onUpdate = () => {
    updateBanner(formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage(
          `Banner '${response[TITLE.value]}' atualizado com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const hasFormValidation = () => {
    const { isValid, errors } = bannerRotationValidation(formState, 'edit')

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }
    onUpdate()
  }

  useEffect(() => {
    setIsFetching(true)

    getBanner(id)
      .then((banner) => {
        setFormState(banner)
        setTitle(banner[TITLE.value])
      })
      .catch(() => {
        setErrorMessage('Não foi possível buscar o banner')
      })
      .finally(() => setIsFetching(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setErrorMessage])

  const handleDelete = () => {
    deleteBanner(id)
      .then(() => {
        setOpenDialog(false)
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage('Banner excluído com sucesso')
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  return (
    <ContentWithHeader
      title="Editar Banner"
      backButtonRoute={generatePath(RoutesMapping.promotionalBannerList, {
        brand
      })}
    >
      <ContentTitle title={title} size="small" />
      {isFetching ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <FormBannerRotary
            formState={formState}
            formErrors={formErrors}
            onChange={onChangeHandler}
            onTitle={setTitle}
          />
          <div className={classes.footerUpdate} style={{ marginBottom: 178 }}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<Delete />}
              className={classes.footerUpdateDelete}
              onClick={() => setOpenDialog(true)}
            >
              EXCLUIR BANNER
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleDuplicateBanner(formState[KEY.value], 'rotary')
              }}
            >
              DUPLICAR BANNER
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={hasFormValidation}
              disabled={isExecutingTask}
            >
              SALVAR
            </Button>
          </div>
          <SnackbarOutlet />
          <Dialog
            open={openDialog}
            title="Excluir banner"
            secondaryButton="CANCELAR"
            primaryButton="EXCLUIR"
            handleClose={() => setOpenDialog(false)}
            handleConfirm={handleDelete}
            content=""
          >
            Ao excluir este banner {title}, ele não estará mais visível no site,
            nem será mantido como histórico.
          </Dialog>
        </>
      )}
    </ContentWithHeader>
  )
}
