import { PAYMENT_INFO_FIELDS as PAYMENT } from '../../../domain/user-management-content/user-orders'
import { PAYMENT_INFO_REMOTE_FIELDS as REMOTE_PAYMENT } from '../remote-fields/payment-info-remote-fields'
import { PaymentTypeAdapter } from './payment-type-adapters'
import { RemoteDateUtils } from '../../utils/remote-date-utils'

const inbound = (remoteData) => {
  return {
    [PAYMENT.BANK_SLIP_CODE]: remoteData[REMOTE_PAYMENT.BANK_SLIP_CODE],
    [PAYMENT.BANK_SLIP_DUE_DATE]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_PAYMENT.BANK_SLIP_DUE_DATE]
    ),
    [PAYMENT.CREDIT_CARD]: remoteData[REMOTE_PAYMENT.CREDIT_CARD],
    [PAYMENT.CREDIT_CARD_HOLDER_NAME]:
      remoteData[REMOTE_PAYMENT.CREDIT_CARD_HOLDER_NAME],
    [PAYMENT.INSTALLMENTS]: remoteData[REMOTE_PAYMENT.INSTALLMENTS],
    [PAYMENT.ORDER_ITEM_ID]: remoteData[REMOTE_PAYMENT.ORDER_ITEM_ID],
    [PAYMENT.PAYMENT_TYPE]: PaymentTypeAdapter.inbound(
      remoteData[REMOTE_PAYMENT.PAYMENT_TYPE]
    ),
    [PAYMENT.PERSON_ID]: remoteData[REMOTE_PAYMENT.PERSON_ID],
    [PAYMENT.PERSON_NAME]: remoteData[REMOTE_PAYMENT.PERSON_NAME],
    [PAYMENT.VALUE]: remoteData[REMOTE_PAYMENT.VALUE]
  }
}

export const PaymentInfoAdapter = {
  inbound
}
