import { makeStyles } from '@material-ui/core/styles'
import { spacing, textClasses } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...textClasses(theme),
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  contentItem: {
    flex: 1,
    marginBottom: '12px'
  },
  contentItem2: {
    flex: 1
  },
  CardSubTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px'
  },
  text: {
    fontWeight: 'normal',
    marginBottom: '12px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  text2: {
    marginBottom: '24px'
  }
}))

export default useStyles
