import { makeStyles } from '@material-ui/core/styles'
import { textClasses, spacing } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...textClasses(theme),
  ...spacing,
  statusActive: {
    color: theme.palette.success.main
  },
  statusInactive: {
    color: theme.palette.error.main
  },
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main
    }
  }
}))

export default useStyles
