export const pageClasses = (theme) => ({
  sectionTitle: {
    marginTop: '40px',
    marginBottom: '24px',
    color: theme?.palette?.text?.primary || '#FFF',
    fontSize: '34px'
  },
  sectionDivider: {
    margin: '25px 0px 39px 0px'
  },
  subsectionTitle: {
    fontSize: '24px',
    lineHeight: '32px'
  },
  pagePaddingBottom: {
    paddingBottom: 72
  },
  paper: {
    padding: 24
  },
  formActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  errorButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main
    }
  }
})

export const textClasses = (theme) => ({
  textPrimary: {
    color: theme.palette.text.primary
  },
  textSecondary: {
    color: theme.palette.text.secondary
  }
})

export const spacing = {
  marginTopXL: {
    marginTop: '40px'
  },
  marginTopLarge: {
    marginTop: '36px'
  },
  marginTopMedium: {
    marginTop: '24px'
  },
  marginTopSmall: {
    marginTop: '12px'
  },
  marginTop16: {
    marginTop: '16px'
  },
  marginTop10: {
    marginTop: '10px'
  },
  marginTop20: {
    marginTop: 20
  },
  marginTopSmaller: {
    marginTop: '8px'
  },
  marginLeftSmall: {
    marginLeft: '12px'
  },
  marginLeft16: {
    marginLeft: '16px'
  },
  marginRigth8: {
    marginRigth: '8px'
  },
  marginBottomMedium: {
    marginBottom: '24px'
  },
  marginBottom16: {
    marginBottom: '16px'
  },
  marginBottomSmall: {
    marginBottom: '12px'
  }
}

export const formClasses = (theme) => {
  return {
    radioGroup: {
      '& .MuiRadio-root': {
        color: theme.palette.primary.main
      }
    }
  }
}

export const flexContainers = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  }
}
