import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(48)
  },
  divider: {
    marginTop: theme.typography.pxToRem(5),
    marginBottom: theme.typography.pxToRem(8)
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24),

    '& .info-group.row': {
      display: 'flex',
      gap: theme.typography.pxToRem(32),

      '& .full': {
        flex: 1
      }
    }
  },
  callToAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(32),

    '& .delete-button': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  },
  alternativesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(12)
  }
}))

export { useStyles }
