import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 22,
    marginLeft: '8vw',
    fontSize: 34,
    color: theme.palette.text.primary
  },
  subtitle: {
    marginTop: 40,
    marginLeft: '8vw',
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  line: {
    marginLeft: '8vw',
    marginRight: '8vw',
    height: 1,
    marginTop: 11,
    backgroundColor: theme.palette.divider
  }
}))

export default useStyles
