import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%'
  },

  radioGroup: {
    display: 'flex',
    flexDirection: 'row'
  },

  radioLabel: {
    color: theme.palette.text.primary
  },

  wrapper: {
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0
    }
  },

  label: {
    display: 'flex',
    marginBottom: 12,
    color: theme.palette.text.primary
  }
}))

export default useStyles
