import React, { useContext, useEffect, useState } from 'react'
import { Typography, Paper } from '@material-ui/core'
import { Snackbar, Button } from '@equipe-ninja/saraiva-ui'
import { useHistory, useParams } from 'react-router-dom'
import DateRangeIcon from '@material-ui/icons/DateRange'

import useStyles from './styles.js'
import BackButton from '../../components/BackButton'
import Loading from '../../components/Loading'
import Tabs from './Tabs'
import { GeneralContext } from '../../contexts/GeneralContext'
import ticketService from '../../services/ticketService'
import EditLibraryDate from './EditLibraryDate.js'

import { snackbarStyle } from '../../layouts/styles'
import ArchiveIcon from '@material-ui/icons/Archive'
import ArchiveDialog from './ArchiveDialog/ArchiveDialog.js'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import UnarchiveDialog from './ArchiveDialog/UnarchiveDialog.js'
import messages from '../../helpers/messages.js'

export default function Library() {
  const classes = useStyles()
  const { libraryCode } = useParams()
  const [library, setLibrary] = useState({})
  const [tickets, setTickets] = useState([])
  const [listTicketIds, setListTicketIds] = useState([])
  const [openEditLibraryDate, setOpenEditLibraryDate] = useState()

  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false)
  const [isArchived, setIsArchived] = useState(false)

  const {
    successMessage,
    errorMessage,
    setSuccessMessage,
    setErrorMessage,
    loading,
    setLoading
  } = useContext(GeneralContext)
  const snackbarStyles = snackbarStyle()

  const history = useHistory()

  useEffect(() => {
    async function getLibrary() {
      try {
        setLoading(true)

        const response = await ticketService.getLibrary(libraryCode)

        if (!response.ok) {
          const errors = await response.json()
          throw errors
        }

        const result = await response.json()

        setLibrary(result.data)
      } catch (error) {
        console.log(error)

        setErrorMessage(
          'Não foi possível carregar os detalhes da biblioteca. Tente novamente mais tarde.'
        )
      } finally {
        setLoading(false)
      }
    }

    getLibrary()
  }, [libraryCode, setErrorMessage, setLoading])

  useEffect(() => {
    async function fetchTickets() {
      try {
        setLoading(true)

        const response = await ticketService.fetchTicketsByLibraryCode(
          libraryCode
        )

        if (!response.ok) {
          const errors = await response.json()
          throw errors
        }

        const result = await response.json()
        if (result.data.some((ticket) => ticket.archived === true)) {
          setIsArchived(true)
        }

        setTickets(result.data)

        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
    fetchTickets()
    // eslint-disable-next-line
  }, [])

  const retrieveListIds = () => {
    const temporaryList = tickets.map((ticket) => ticket.id)
    setListTicketIds(temporaryList)
  }

  const pickFirstDateFromTickets = () => {
    let initialStartDate = tickets.map((ticket, i) => {
      if (i === 0) {
        return ticket.start_date
      }
      return null;
    })
    const dates = tickets.map((ticket) =>
      ticket.start_date.split('/').reverse().join('/')
    )
    const formatedDate = dates.map((date) => new Date(date))

    initialStartDate = new Date(Math.min.apply(null, formatedDate))
    return initialStartDate.toLocaleDateString('pt-BR')
  }

  const pickLastDateFromTickets = () => {
    let finalEndDate = tickets.map((ticket, i) => {
      if (i === 0) {
        return ticket.end_date
      }
      return null;
    })
    const dates = tickets.map((ticket) =>
      ticket.end_date.split('/').reverse().join('/')
    )
    const formatedDate = dates.map((date) => new Date(date))

    finalEndDate = new Date(Math.max.apply(null, formatedDate))
    return finalEndDate.toLocaleDateString('pt-BR')
  }
  useEffect(() => {
    async function fetchTickets() {
      try {
        setLoading(true)

        const response = await ticketService.fetchTicketsByLibraryCode(
          libraryCode
        )

        if (!response.ok) {
          const errors = await response.json()
          throw errors
        }

        const result = await response.json()
        setTickets(result.data)
        if (result.data.some((ticket) => ticket.archived === true)) {
          setIsArchived(true)
        }
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
    fetchTickets()
    // eslint-disable-next-line
  }, [])

  const pickUpdateDateFromTickets = () => {
    let updatedDate = tickets.map((ticket, i) => {
      if (i === 0) {
        return ticket.updated_at
      }
      return null;
    })
    const dates = tickets.map((ticket) =>
      ticket.updated_at.split('/').reverse().join('/')
    )
    const formatedDate = dates.map((date) => new Date(date))

    updatedDate = new Date(Math.min.apply(null, formatedDate))
    return updatedDate.toLocaleDateString('pt-BR')
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Snackbar classes={() => snackbarStyles}
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
      >
        {successMessage}
      </Snackbar>

      <Snackbar classes={() => snackbarStyles}
        severity="warning"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
      >
        {errorMessage}
      </Snackbar>

      <div className={classes.root}>
        <BackButton onClick={() => history.push('/libraries')} />

        <Typography variant="h4">Biblioteca {library.name}</Typography>

        <Paper variant="outlined" className={classes.paper}>
          <Tabs activeTab="library" />

          <div className={classes.content}>
            {isArchived && (
              <div className={classes.snackDiv}>
                <Snackbar classes={() => snackbarStyles} noClose severity="info">
                  {messages.archivedLibrary.default}
                </Snackbar>
              </div>
            )}
            <div className={classes.grid}>
              <div className={classes.column}>
                <div className={classes.labelWrapper}>
                  <Typography variant="h6" className={classes.label}>
                    Nº da biblioteca
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {library.number}
                  </Typography>
                </div>

                <div className={classes.labelWrapper}>
                  <Typography variant="h6" className={classes.label}>
                    Chave da biblioteca
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {library.code}
                  </Typography>
                </div>

                <div className={classes.labelWrapper}>
                  <Typography variant="h6" className={classes.label}>
                    Nome da biblioteca
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {library.name}
                  </Typography>
                </div>

                <div className={classes.buttonsWrapper}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<DateRangeIcon />}
                    onClick={() => {
                      setOpenEditLibraryDate(true)
                      retrieveListIds()
                    }}
                    disabled={isArchived}
                    className={classes.editDate}
                  >
                    Alterar Data da Biblioteca
                  </Button>

                  {isArchived ? (
                    <Button
                      color="primary"
                      onClick={() => setOpenUnarchiveDialog(true)}
                      startIcon={<UnarchiveIcon />}
                      variant="outlined"
                    >
                      DESARQUIVAR BIBLIOTECA
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => setOpenArchiveDialog(true)}
                      startIcon={<ArchiveIcon />}
                      variant="outlined"
                    >
                      ARQUIVAR BIBLIOTECA
                    </Button>
                  )}
                </div>
              </div>

              <div className={classes.column}>
                <div className={classes.labelWrapper}>
                  <Typography variant="h6" className={classes.label}>
                    Última alteração na biblioteca
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {pickUpdateDateFromTickets()}
                  </Typography>
                </div>

                <div className={classes.labelWrapper}>
                  <Typography variant="h6" className={classes.label}>
                    Total de vouchers ativados
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {library.total_activated}/{library.total_count}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {openEditLibraryDate && (
            <EditLibraryDate
              handleOpen={openEditLibraryDate}
              handleClose={() => setOpenEditLibraryDate(false)}
              setLoading={setLoading}
              listIds={listTicketIds}
              startDate={pickFirstDateFromTickets()}
              endDate={pickLastDateFromTickets()}
            />
          )}
        </Paper>
      </div>
      {openArchiveDialog && (
        <ArchiveDialog
          open={openArchiveDialog}
          handleClose={() => setOpenArchiveDialog(false)}
          setOpenArchiveDialog={setOpenArchiveDialog}
        />
      )}
      {openUnarchiveDialog && (
        <UnarchiveDialog
          open={openUnarchiveDialog}
          handleClose={() => setOpenUnarchiveDialog(false)}
          setOpenUnarchiveDialog={setOpenUnarchiveDialog}
        />
      )}
    </>
  )
}
