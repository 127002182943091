import { useCallback } from 'react'

export const useDefaultValueWhenEmpty = (
  defaultValue = 'Não informado',
  emptyValues = [null, '', undefined]
) => {
  const defaultValueWhenEmpty = useCallback(
    (value) => {
      return emptyValues.includes(value) ? defaultValue : value
    },
    [defaultValue, emptyValues]
  )

  return defaultValueWhenEmpty
}
