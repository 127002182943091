import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../shared/shared-style-classes'

const useStyles = makeStyles(() => ({
  ...spacing,
  relativePosition: {
    position: 'relative'
  }
}))

export default useStyles
