import React from 'react'
import { CardInfo } from '@equipe-ninja/saraiva-ui'
import { Divider, Typography } from '@material-ui/core'
import { useStyles } from './styles'

const cardStyles = {
  background: '#fff',

  '& main': {
    padding: 24
  }
}

export function QuestionCardPreview(exercise) {
  const classes = useStyles()

  const exercises = exercise['exercise']

  const questionHeader = (index) => {
    switch (index) {
      case 0:
        return 'A)'
      case 1:
        return 'B)'
      case 2:
        return 'C)'
      case 3:
        return 'D)'
      case 4:
        return 'E)'
      default:
        break
    }
  }

  const correctAlternative = (index) => {
    switch (index) {
      case 1:
        return 'A'
      case 2:
        return 'B'
      case 3:
        return 'C'
      case 4:
        return 'D'
      case 5:
        return 'E'
      default:
        break
    }
  }

  const getCorrectAlternative = (correctId) => {
    const filterCorrectAlternative = exercises.alternatives.filter(
      (alternative) => alternative.id === correctId
    )
    const result = correctAlternative(
      filterCorrectAlternative[0]?.order ?? 'Não tem'
    )
    return result
  }

  return (
    <CardInfo className={cardStyles}>
      <Typography variant="h6" color="textPrimary">
        {`Questão ${exercises.number}`}
      </Typography>

      <Typography
        className={classes.subtitle}
        variant="body2"
        color="textSecondary"
      >
        {`Banco de questões: ${exercises.course}`}
      </Typography>

      <div className={classes.infoContainer}>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Palavras-chave
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" color="textSecondary">
            {exercises.tags}
          </Typography>
        </div>
        <div className="info-group row">
          <div className="full">
            <Typography variant="body1" color="textPrimary">
              Prova / Banca
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" color="textSecondary">
              {exercises.institution}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="textPrimary">
              Ano de realização
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" color="textSecondary">
              {exercises.year}
            </Typography>
          </div>
        </div>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Enunciado
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" color="textSecondary">
            {exercises.content}
          </Typography>
        </div>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Alternativas
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.alternativesContainer}>
            {exercises.alternatives.map((i, index) => (
              <Typography variant="body2" color="textSecondary">
                {`${questionHeader(index)} ${i.content}`}
              </Typography>
            ))}
          </div>
        </div>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Alternativa correta:{' '}
            {getCorrectAlternative(exercises.correct_exercise_alternative_id)}
          </Typography>
        </div>
      </div>
    </CardInfo>
  )
}
