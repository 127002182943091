/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Button,
  MenuItem,
  SelectSaraiva,
  TextInput
} from '@equipe-ninja/saraiva-ui'

import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import { Add, Close, Create } from '@material-ui/icons'
import TextEditor from '../TextEditor'

import { QUESTION_INFORMATION } from './DictionaryQuestions/general-question-information'

import useStyles from './styles'
import IntegrationsService from '../../services/integrationsService'
import { GeneralContext } from '../../contexts/GeneralContext'
import messages from '../../helpers/messages'

function EditQuestion({ showEditQuestion, setShowEditQuestion }) {
  const classes = useStyles()

  const { DIFFICULTY } = QUESTION_INFORMATION

  const [code, setCode] = useState('')
  const [area, setArea] = useState('')
  const [discipline, setDiscipline] = useState('')
  const [tema, setTema] = useState('')
  const [objective, setObjective] = useState('')
  const [source, setSource] = useState('')
  const [year, setYear] = useState('')
  const [tagList, setTagList] = useState([])
  const [selectedFormat, setSelectedFormat] = useState('')
  const [selectedDifficulty, setSelectedDifficulty] = useState('')
  const [selectedAlternative, setSelectedAlternative] = useState('')
  const [saving, setSaving] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [correctAlternativeLetter, setCorrectAlternativeLetter] = useState(null)
  const [correctAlternativeId, setCorrectAlternativeId] = useState(null)

  const [formState, setFormState] = useState({
    content: ''
  })

  const handleTextChange = (index, value) => {
    setAlternatives((prevAlternatives) => {
      const newAlternatives = [...prevAlternatives]
      newAlternatives[index].content = value
      return newAlternatives
    })
  }

  const handleJustificationChange = (index, value) => {
    setAlternatives((prevAlternatives) => {
      const newAlternatives = [...prevAlternatives]
      newAlternatives[index].justification = value
      return newAlternatives
    })
  }

  // const handleToggleJustification = (index) => {
  //   // Copie o array de alternativas
  //   const newAlternatives = [...alternatives]

  //   // Altere o objeto de alternativa específico
  //   newAlternatives[index] = {
  //     ...newAlternatives[index],
  //     showJustification: !newAlternatives[index].showJustification, // Alterne o valor de showJustification
  //     justification: newAlternatives[index].showJustification
  //       ? ''
  //       : newAlternatives[index].justification // Limpe o campo se estiver fechando
  //   }

  //   // Atualize o estado com o novo array de alternativas
  //   setAlternatives(newAlternatives)
  // }

  const handleToggleJustification = (index) => {
    const newAlternatives = [...alternatives]
    if (
      newAlternatives[index].showJustification ||
      newAlternatives[index].justification
    ) {
      newAlternatives[index].showJustification = false
      newAlternatives[index].justification = ''
    } else {
      newAlternatives[index].showJustification = true
    }
    setAlternatives(newAlternatives)
  }

  const handleAddAlternative = () => {
    if (alternatives.length < 6) {
      setAlternatives([
        ...alternatives,
        { content: '', justification: '', showJustification: false }
      ])
    }
  }

  const handleDeleteAlternative = (index) => {
    setAlternatives((prevAlternatives) => {
      const newAlternatives = [...prevAlternatives]
      newAlternatives.splice(index, 1)
      return newAlternatives
    })
  }

  const handleInputChange = (field, value) => {
    setFormState({
      ...formState,
      [field]: value
    })
  }

  const {
    setSuccessMessage,
    setErrorMessage,
    setNewQuestion,
    viewEditQuestion
  } = useContext(GeneralContext)

  const alphabet = 'ABCDEF'
  const selectOptions = alternatives.map((alternative, index) => ({
    value: alphabet[index],
    label: `Alternativa ${alphabet[index]}`
  }))

  const handleFormatChange = (event) => {
    const { value } = event.target
    setSelectedFormat(value)
  }

  const handleDifficultyChange = (event) => {
    const { value } = event.target
    setSelectedDifficulty(value)
  }

  const textEditorRef = useRef()

  const handleCloseDialog = () => {
    setShowEditQuestion(false)
  }
  const handleCloseCancel = () => {
    setShowEditQuestion(false)
    window.location.reload()
  }

  const isFormFilled = () => {
    return (
      discipline.trim() !== '' &&
      tema.trim() !== '' &&
      area.trim() !== '' &&
      formState.content.trim() !== '' &&
      selectedFormat.trim() !== '' &&
      selectedAlternative.trim() !== '' &&
      alternatives.every((alternative) => alternative.content.trim() !== '')
    )
  }

  const save = async () => {
    setSaving(false)

    const alternativas = alternatives.map((alternative, index) => ({
      id: index + 1,
      content: alternative.content,
      order: index + 1,
      justification: alternative.justification
    }))

    const correctAlternativeIdToSend = selectedAlternative
      ? selectedAlternative.charCodeAt(selectedAlternative.length - 1) -
        'A'.charCodeAt(0) +
        1
      : correctAlternativeId

    const payload = {
      code,
      type: selectedFormat,
      knowledge_area: area,
      cogna_origin: 'Backoffice',
      subject: discipline,
      theme: tema,
      goal: objective,
      question_source: source,
      year,
      tags: tagList,
      content: formState.content,
      difficulty_level: selectedDifficulty,
      correct_alternative_id: correctAlternativeIdToSend,
      question_alternatives: alternativas
    }

    try {
      const response = await IntegrationsService.EditDataBaseQuestion(
        payload,
        viewEditQuestion.id
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setNewQuestion(result)

      setSuccessMessage(messages.editQuestionDialog.success)
    } catch (error) {
      setErrorMessage(messages.editQuestionDialog.error)
      console.error(error)
    } finally {
      setSaving(true)
      handleCloseDialog()
    }
  }

  useEffect(() => {
    try {
      if (viewEditQuestion) {
        setCode(viewEditQuestion.code)
        setSelectedFormat(viewEditQuestion.type)
        setArea(viewEditQuestion.knowledge_area)
        setDiscipline(viewEditQuestion.subject)
        setTema(viewEditQuestion.theme)
        setObjective(viewEditQuestion.goal)
        setSource(viewEditQuestion.question_source)
        setYear(viewEditQuestion.year)
        setTagList(viewEditQuestion.tags)
        setSelectedDifficulty(viewEditQuestion.dificult_level)

        if (viewEditQuestion) {
          setFormState((prevState) => ({
            ...prevState,
            content: viewEditQuestion.content
          }))
        }
        const sortedAlternatives = viewEditQuestion.question_alternatives.sort(
          (a, b) => a.order - b.order
        )

        setAlternatives(sortedAlternatives || [])

        if (viewEditQuestion) {
          const alphabet = 'abcdefghijklmnopqrstuvwxyz'
          const correctIndex = viewEditQuestion.question_alternatives.findIndex(
            (alternative) =>
              alternative.id === viewEditQuestion.correct_alternative_id
          )
          const correctValue =
            correctIndex !== -1 ? alphabet[correctIndex].toUpperCase() : ''

          console.log('correctValue:', correctValue)

          setCorrectAlternativeLetter(`Alternativa ${correctValue}`)
          setCorrectAlternativeId(correctIndex + 1)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [viewEditQuestion])

  useEffect(() => {
    if (correctAlternativeLetter) {
      const valueToSelect = correctAlternativeLetter.split(' ')[1]
      setSelectedAlternative(valueToSelect)
    }
  }, [correctAlternativeLetter])

  return (
    <Dialog
      open={showEditQuestion}
      className={classes.root}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return
        }
        handleCloseCancel()
      }}
    >
      <DialogTitle>Editar questão </DialogTitle>
      <Divider />
      <DialogContent>
        <div className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            Informações gerais da questão
          </Typography>

          <TextInput
            className={classes.textInput}
            color="primary"
            label="Código"
            size="md"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <SelectSaraiva
            className={classes.textInput}
            label="Formato da questão *"
            required
            size="md"
            value={selectedFormat}
            onChange={handleFormatChange}
          >
            <MenuItem value="Múltipla escolha">Múltipla escolha</MenuItem>
            <MenuItem value="Verdadeiro ou Falso">Verdadeiro ou Falso</MenuItem>
            <MenuItem value="Discursiva">Discursiva</MenuItem>
          </SelectSaraiva>

          <TextInput
            className={classes.textInput}
            color="primary"
            label="Área do conhecimento"
            required
            helperText="Ex: Direito, Administração, Ciências contábeis..."
            size="md"
            value={area}
            onChange={(e) => setArea(e.target.value)}
          />
          <TextInput
            className={classes.textInput}
            color="primary"
            label="Disciplina"
            helperText="Ex: Direito civil, Administração pública, Biologia celular..."
            size="md"
            required
            value={discipline}
            onChange={(e) => setDiscipline(e.target.value)}
          />

          <TextInput
            className={classes.textInput}
            color="primary"
            label="Tema"
            helperText="Ex: Direito das Sucessões, Contratos , Respiração celular..."
            size="md"
            required
            value={tema}
            onChange={(e) => setTema(e.target.value)}
          />

          <TextInput
            className={classes.textInput}
            color="primary"
            label="Objetivo"
            helperText="Indique se a questão é voltada para OAB, Graduação, ENADE, etc."
            size="md"
            value={objective}
            onChange={(e) => setObjective(e.target.value)}
          />

          <TextInput
            className={classes.textInput}
            color="primary"
            label="Fonte"
            helperText="Indique aqui a origem da questão (local em que foi retirada) "
            size="md"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          />

          <TextInput
            className={classes.textInput}
            color="primary"
            label="Ano"
            helperText="Indique o ano que a questão foi elaborada"
            size="md"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />

          <TextInput
            className={classes.textInput}
            color="primary"
            label="TAGS"
            helperText="Indique TAGS que são relevantes para categorizar essa questão "
            size="md"
            value={tagList}
            onChange={(e) => {
              setTagList(e.target.value.split(',').map((tag) => tag.trim()))
            }}
          />
          <SelectSaraiva
            className={classes.textInput}
            label="Dificuldade"
            size="md"
            value={selectedDifficulty}
            onChange={handleDifficultyChange}
          >
            {DIFFICULTY.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectSaraiva>

          <Typography variant="h6" className={classes.subTitle}>
            Enunciado *
          </Typography>

          <TextEditor
            ref={textEditorRef}
            onChange={(htmlContent) =>
              handleInputChange('content', htmlContent)
            }
            initialValue={formState.content}
          />

          <Typography variant="h6" className={classes.subTitle}>
            Alternativas
          </Typography>

          <Typography className={classes.footer}>
            Insira de 2 a 6 alternativas para a questão
          </Typography>

          <div className={classes.container}>
            {alternatives.map((alternative, index) => (
              <div key={index} className={classes.alternativeContainer}>
                <TextInput
                  className={classes.TextAlternative}
                  color="primary"
                  label={`Alternativa ${String.fromCharCode(65 + index)}`}
                  size="xl"
                  required
                  multiline
                  rows={2}
                  value={alternative.content}
                  onChange={(event) =>
                    handleTextChange(index, event.target.value)
                  }
                />
                <div className={classes.contentJusfication}>
                  {alternative.showJustification ||
                  alternative.justification ? (
                    <TextField
                      value={alternative.justification}
                      onChange={(event) =>
                        handleJustificationChange(index, event.target.value)
                      }
                      label="Justificativa"
                      variant="standard"
                      fullWidth
                      sx={{ m: 1 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => handleToggleJustification(index)}
                          >
                            <Close />
                          </IconButton>
                        )
                      }}
                    />
                  ) : (
                    <Button
                      color="primary"
                      startIcon={<Create />}
                      onClick={() => handleToggleJustification(index)}
                    >
                      JUSTIFICATIVA
                    </Button>
                  )}
                </div>

                {alternatives.length > 2 && (
                  <Button
                    className={classes.btnDeleteAlternative}
                    onClick={() => handleDeleteAlternative(index)}
                  >
                    EXCLUIR ALTERNATIVA
                  </Button>
                )}
              </div>
            ))}

            {alternatives.length < 6 && (
              <Button
                className={classes.btnAddAlternative}
                startIcon={<Add />}
                color="primary"
                variant="contained"
                onClick={handleAddAlternative}
              >
                ADICIONAR ALTERNATIVA
              </Button>
            )}
          </div>

          <Typography variant="h6" className={classes.subTitle}>
            Gabarito
          </Typography>
          <Typography className={classes.footer}>
            Insira a alternativa correta da questão
          </Typography>

          <SelectSaraiva
            className={classes.textInput}
            label="Alternativa correta"
            size="xl"
            value={selectedAlternative}
            onChange={(event) => setSelectedAlternative(event.target.value)}
            required
          >
            {selectOptions.length > 0 ? (
              selectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Sem alternativas adicionadas</MenuItem>
            )}
          </SelectSaraiva>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCloseCancel} className={classes.btnCancelar}>
          CANCELAR
        </Button>
        <Button onClick={save} disabled={!isFormFilled()} color="primary">
          SALVAR EDIÇÃO
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditQuestion.propTypes = {
  showEditQuestion: PropTypes.bool.isRequired,
  setShowEditQuestion: PropTypes.func.isRequired
}

export default EditQuestion
