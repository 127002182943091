import { REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS as F } from '../register-elearning-all-subject-list-fields'

const locateTopic = (id, node) => {
  if (id === node?.[F.KEY.value]) {
    return node
  }

  if (!node?.[F.CHILDREN.value]) {
    return null
  }

  let searchedTopic = null

  for (let index = 0; index < node[[F.CHILDREN.value]].length; index += 1) {
    const element = node[F.CHILDREN.value][index]

    const topic = locateTopic(id, element)

    if (topic) {
      searchedTopic = topic
      break
    }
  }

  return searchedTopic
}

export const locateTopicById = (topicId, allTopics) => {
  if (!topicId) return null

  return locateTopic(topicId, {
    [F.KEY.value]: null,
    [F.CHILDREN.value]: allTopics
  })
}
