import React, { useContext } from 'react'
import { Stepper as MUIStepper, Step, StepLabel } from '@material-ui/core'

import useStyles from './styles.js'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext.js'

const STEPS = {
  bds: [
    'Adicionar conexão com AVA',
    'Enviar Dados da IES Receber Dados da IES Configurar conexão',
    'Inserir a ID de desenvolvimento',
    'Concluído, confira com parceiro a conexão'
  ],
  dons: [
    'Adicionar conexão com AVA',
    'Enviar Dados da IES Receber Dados da IES Configurar conexão',
    'Inserir a ID de desenvolvimento',
    'Selecionar disciplinas',
    'Concluído, confira com parceiro a conexão'
  ]
}

function Steps() {
  const classes = useStyles()
  const { connection } = useContext(ConnectionContext)
  const { productSelected } = useContext(GeneralContext)

  const getCurrentStep = () => {
    const {
      deployment,
      modules,
      have_lti_client_id: haveLtiClientId
    } = connection

    if (haveLtiClientId && deployment.length > 0 && modules.length > 0) {
      return 4
    }

    if (haveLtiClientId && deployment.length > 0 && modules.length === 0) {
      return 3
    }

    if (haveLtiClientId && deployment.length === 0) {
      return 2
    }

    return 0
  }

  return (
    <MUIStepper
      activeStep={getCurrentStep(connection)}
      alternativeLabel
      orientation="horizontal"
      classes={{ root: classes.root }}
    >
      {STEPS[productSelected.value].map((label) => (
        <Step key={label}>
          <StepLabel
            classes={{
              labelContainer: classes.labelContainer,
              alternativeLabel: classes.alternativeLabel
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MUIStepper>
  )
}

export default Steps
