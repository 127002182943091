import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { listItemAdapterInbound } from '../adapters/promo-page-list-adapter'
import { getResquestResponseHandler } from '../../../utils/response-handlers'

/**
 * Caso de uso para carregamento de lista de paginas promocionais
 * @returns lista de paginas promocionais apos passar por um adaptador de entrada
 */
const getPromotionalPagesList = async () => {
  const path = 'markets/api/v1/pages'
  const headers = httpClient.buildHeader({ 'session-token': getSessionToken() })

  return new Promise((resolve, reject) => {
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((json) => {
        resolve(listItemAdapterInbound(json.data))
      })
      .catch((err) => reject(err))
  })
}

export { getPromotionalPagesList }
