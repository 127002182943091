import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { SelectSaraiva } from '@equipe-ninja/saraiva-ui'
import MenuItem from '@material-ui/core/MenuItem'
import { useRecoilState } from 'recoil'
import useStyles from './styles'
import { PRODUCT_COURSE_CATEGORY_FIELDS as CATEGORY_FIELDS } from '../../../domain/product-course'
import { SELECTABLE_CATEGORIES } from '../../../domain/product-course/business'
import { courseProductCategoryState } from '../../../atoms/course-product-category'
import { getCategories } from '../../../services/course-product'
import { GeneralContext } from '../../../contexts/GeneralContext'

const EMPTY = ''

export const CategorySelector = (props) => {
  const {
    category,
    categoryError,
    categoryField,
    subcategory,
    subcategoryError,
    subcategoryField,
    onChange,
    className
  } = props
  const classes = useStyles()
  const [categoryState, setCategories] = useRecoilState(
    courseProductCategoryState
  )
  const { setErrorMessage } = useContext(GeneralContext)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const fetchCategories = useCallback(() => {
    if (categoryState?.categories?.length > 0) return
    setCategories((prev) => ({ ...prev, isFetching: true }))
    getCategories()
      .then((data) => {
        setCategories((prev) => ({ ...prev, categories: data }))
      })
      .catch(() => {
        setErrorMessage('Falha ao buscar informações sobre categorias')
      })
      .finally(() => {
        setCategories((prev) => ({ ...prev, isFetching: false }))
      })
  }, [setCategories, setErrorMessage, categoryState?.categories])

  const onChangeCategory = (event) => {
    const newValue = event.target.value

    setSelectedCategory(
      categoryState.categories.find((e) => e[CATEGORY_FIELDS.ID] === newValue)
    )

    onChange(event, categoryField)
    onChange({ target: { value: '' } }, subcategoryField)
  }

  const isHidingSubcategories = () => {
    if (category === EMPTY) return true

    if (category === SELECTABLE_CATEGORIES.LFG_PLAY) return true

    return category === SELECTABLE_CATEGORIES.ATUALIZACAO_PRATICA
  }

  const matchCategories = useCallback(
    (currentCategory) => {
      setSelectedCategory(
        categoryState.categories.find(
          (e) => e[CATEGORY_FIELDS.ID] === currentCategory
        )
      )
    },
    [categoryState.categories]
  )

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    matchCategories(category)
  }, [categoryState.categories, matchCategories, category])

  return (
    <>
      <div className={className}>
        <SelectSaraiva
          disabled={categoryState.isFetching}
          error={!!categoryError}
          helperText={categoryError}
          value={category}
          onChange={(evt) => onChangeCategory(evt)}
          label="Categoria do curso *"
          required
          size="full"
        >
          <MenuItem value={EMPTY}>Nenhuma categoria</MenuItem>

          {categoryState.categories.map((item) => (
            <MenuItem
              key={item[CATEGORY_FIELDS.ID]}
              value={item[CATEGORY_FIELDS.ID]}
            >
              {item[CATEGORY_FIELDS.NAME]}
            </MenuItem>
          ))}
        </SelectSaraiva>
      </div>

      <div
        className={cls(
          classes.marginTopMedium,
          isHidingSubcategories() && classes.hidden
        )}
      >
        <SelectSaraiva
          disabled={categoryState.isFetching}
          error={!!subcategoryError}
          helperText={subcategoryError}
          value={subcategory}
          onChange={(evt) => onChange(evt, subcategoryField)}
          label="Subcategoria do curso *"
          required
          size="full"
        >
          <MenuItem value={EMPTY}>Nenhuma subcategoria</MenuItem>

          {selectedCategory &&
            selectedCategory?.[CATEGORY_FIELDS.SUB_CATEGORIES].map((item) => (
              <MenuItem
                key={item[CATEGORY_FIELDS.ID]}
                value={item[CATEGORY_FIELDS.ID]}
              >
                {item[CATEGORY_FIELDS.NAME]}
              </MenuItem>
            ))}
        </SelectSaraiva>
      </div>
    </>
  )
}

CategorySelector.propTypes = {
  category: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
  categoryError: PropTypes.oneOfType([PropTypes.string, false]).isRequired,
  categoryField: PropTypes.string.isRequired,
  subcategory: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
  subcategoryError: PropTypes.oneOfType([PropTypes.string, false]).isRequired,
  subcategoryField: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
}

CategorySelector.defaultProps = {
  onChange: () => null,
  className: ''
}
