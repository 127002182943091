import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../../../../shared/shared-style-classes'

const useStyles = makeStyles(() => ({
  ...spacing,
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 16px'
  }
}))

export default useStyles
