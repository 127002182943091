import { getSessionToken } from '../../../auth'

import httpClient from '../../../gateway/generalHttpRequest'
import {
  postResquestResponseHandler,
  putRequestResponseHandler
} from '../../utils/response-handlers'
import { CardComplementaryContentAdapter } from '../adapters/card-complementary-content'
import { updateFileContent } from './upload-file-content'

const createComplementaryContent = async (complementaryContent) => {
  const path = '/contents/api/v1/complementary_content'
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)

  const response = await httpClient.post(
    path,
    CardComplementaryContentAdapter.outbound(complementaryContent),
    headers
  )

  const responseJson = await postResquestResponseHandler(response)

  const formDataFile = new FormData()
  formDataFile.append('file', complementaryContent.path)
  formDataFile.append('id', responseJson.id)

  const responseUpload = await updateFileContent(formDataFile)

  if (responseUpload) {
    return CardComplementaryContentAdapter.inbound(responseJson)
  }
  throw new Error(responseUpload.message)
}

const updateComplementaryContent = async (complementaryContent) => {
  const path = `/contents/api/v1/complementary_content/${complementaryContent.id}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)

  const response = await httpClient.put(
    path,
    CardComplementaryContentAdapter.outbound(complementaryContent),
    headers
  )

  const responseJson = await putRequestResponseHandler(response)

  if (complementaryContent.changeFile) {
    const formDataFile = new FormData()
    formDataFile.append('file', complementaryContent.path)
    formDataFile.append('id', responseJson.id)

    const responseUpload = await updateFileContent(formDataFile)
    if (responseUpload) {
      return CardComplementaryContentAdapter.inbound(responseJson)
    }
    throw new Error(responseUpload.message)
  }

  return CardComplementaryContentAdapter.inbound(responseJson)
}

export { createComplementaryContent, updateComplementaryContent }
