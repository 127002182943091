/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Alert, Button, Dialog, Pagination } from '@equipe-ninja/saraiva-ui'
import Add from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
import { CircularProgress } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import {
  questionDialogFormValuesSelector,
  questionFormErrorsSelector
} from '../../../../atoms/subject/selectors/fixation-exercise'
import { questionFormValidation } from '../../../../domain/fixation-exercise/question-form-validation'
import { QuestionCard } from './QuestionCard'
import { QuestionManagementForm } from './QuestionManagementForm'
import FixationExercise from '../../../../services/fixationExerciseService'
import { useStyles } from '../styles'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import {
  QUESTION_FIELDS,
  QUESTION_ALTERNATIVES_FIELDS
} from '../../../../domain/fixation-exercise/question-fields'
import ReusableQuestionTable from './ReusableQuestionTable'
import { allQuestionsDatabaseSelector } from '../../../../atoms/subject'

export function FixationExerciseTabContent({ id, data }) {
  const styles = useStyles()
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const { subjectId } = useParams()

  const [questionFormValues, setQuestionFormValues] = useRecoilState(
    questionDialogFormValuesSelector
  )

  const [allSubjectQuestions] = useRecoilState(allQuestionsDatabaseSelector)

  const { checkedQuestions } = allSubjectQuestions

  const setQuestionFormErrors = useSetRecoilState(questionFormErrorsSelector)
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const openQuestionDialog = () => setIsQuestionDialogOpen(true)
  const closeQuestionDialog = () => setIsQuestionDialogOpen(false)

  const [isQuestionDialogConfirm, questionDialogConfirm] = useState(false)
  const closeConfirmDialog = () => questionDialogConfirm(false)

  const [exerciseList, setExerciseList] = useState([])

  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingQuestions, setIsFetchingQuestions] = useState(true)
  const [isFetchingCreate, setIsFetchingCreate] = useState(true)

  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  async function handleConfirmQuestionDialogChanges() {
    setIsFetchingCreate(true)
    const validationResult = questionFormValidation(questionFormValues)

    if (!validationResult.isValid) {
      setQuestionFormErrors(validationResult.errors)

      return
    }
    await FixationExercise.postFixationExercise(questionFormValues)
    setSuccessMessage('Exercício Criado com sucesso')
    setIsFetchingCreate(false)

    closeQuestionDialog()
    setQuestionFormValues((prev) => ({
      ...prev,
      [QUESTION_FIELDS.KEY_WORDS]: '',
      [QUESTION_FIELDS.CORRECT_ALTERNATIVE]: '',
      [QUESTION_FIELDS.TEST_YEAR]: '',
      [QUESTION_FIELDS.ENUNCIATION]: '',
      [QUESTION_FIELDS.TABLE_TEST]: '',
      [QUESTION_FIELDS.ALTERNATIVES]: [
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 1,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        },
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 2,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        }
      ]
    }))
  }

  const deleteHandler = (deletedId) => {
    setExerciseList((prev) => prev.filter((item) => item.id !== deletedId))
  }

  const handleDialog = () => {
    handleConfirmQuestionDialogChanges()
  }

  const closeDialogs = () => {
    closeConfirmDialog()
    closeQuestionDialog()
    setQuestionFormValues((prev) => ({
      ...prev,
      [QUESTION_FIELDS.KEY_WORDS]: '',
      [QUESTION_FIELDS.CORRECT_ALTERNATIVE]: '',
      [QUESTION_FIELDS.TEST_YEAR]: '',
      [QUESTION_FIELDS.ENUNCIATION]: '',
      [QUESTION_FIELDS.TABLE_TEST]: '',
      [QUESTION_FIELDS.ALTERNATIVES]: [
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 1,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        },
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 2,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        }
      ]
    }))
  }
  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleCountPagination = (countPagination) => {
    setCount(Math.floor(countPagination / 10) + 1)
  }

  async function handleReusebleQuestions() {
    setIsFetchingQuestions(true)
    try {
      const response = await FixationExercise.postReusableFixationExercise(
        id,
        checkedQuestions
      )

      if (!response.ok) {
        const errors = await response.json()
        setErrorMessage('Não foi possível reutilizar os exercícios')
        throw errors
      }

      setIsOpen(false)
    } catch (error) {
      console.error(error)
    }
    setIsFetchingQuestions(false)
  }

  const onSaveUptade = () => {
    setIsFetchingQuestions((prev) => !prev)
  }

  async function getExercises(disciplineId) {
    setIsFetching(true)
    try {
      const response = await FixationExercise.getFixationExercise(
        disciplineId,
        page
      )
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      const newResult = result.fixation_exercise.map((item) => ({
        alternatives: item.alternatives,
        content: String(item.content),
        correct_exercise_alternative_id: Number(
          item.correct_exercise_alternative_id
        ),
        id: Number(item.id),
        inserted_at: String(item.inserted_at),
        institution: String(item.institution),
        origin_subject: Number(item.origin_subject),
        tags: String(item.tags),
        updated_at: String(item.updated_at),
        year: String(item.year),
        currentSubjectId: Number(disciplineId)
      }))

      setExerciseList(newResult)
      handleCountPagination(result.count)
    } catch (error) {
      console.error(error)
    }
    setIsFetching(false)
  }

  useEffect(() => {
    getExercises(id)
  }, [id, page, isFetchingQuestions, isFetchingCreate])

  useEffect(() => {
    setQuestionFormValues((prev) => ({
      ...prev,
      [QUESTION_FIELDS.ORIGIN_SUBJECT]: subjectId
    }))
  }, [])

  return (
    <div className={styles.fixationExerciseContainer}>
      <Alert severity="info">
        O exercício é criado automaticamente ao adicionar uma questão!
      </Alert>
      <div className="cta">
        <Button
          onClick={openQuestionDialog}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          Nova Questão
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<ListIcon />}
          onClick={() => setIsOpen(true)}
        >
          Banco de Questões
        </Button>
      </div>
      {isFetching === true ? (
        <CircularProgress />
      ) : (
        <div className={styles.questionsContainer}>
          {exerciseList.length === 0
            ? null
            : exerciseList.map((item, index) => (
                <QuestionCard
                  exercise={{ item, index, data }}
                  onDelete={deleteHandler}
                  refreshDataCallBack={onSaveUptade}
                />
              ))}
        </div>
      )}
      <Dialog
        open={isQuestionDialogOpen}
        title="Nova Questão"
        size="lg"
        primaryButton="CADASTRAR QUESTÃO"
        secondaryButton="CANCELAR"
        handleClose={questionDialogConfirm}
        handleConfirm={handleDialog}
      >
        <QuestionManagementForm />
      </Dialog>
      <Dialog
        open={isQuestionDialogConfirm}
        title="Fechar cadastro"
        size="md"
        primaryButton="FECHAR"
        secondaryButton="CANCELAR"
        handleClose={closeConfirmDialog}
        handleConfirm={closeDialogs}
      >
        Você está saindo sem salvar as informações cadastradas e perderá todo o
        seu progresso.
      </Dialog>
      <Dialog
        open={isOpen}
        title="Banco de Questões"
        secondaryButton="cancelar"
        primaryButton="adicionar questões"
        size="xl"
        handleClose={() => setIsOpen(false)}
        handleConfirm={() => handleReusebleQuestions()}
        isDisabled={checkedQuestions.length === 0}
      >
        <ReusableQuestionTable />
      </Dialog>
      <div className={styles.pagination}>
        <Pagination
          color="primary"
          count={count}
          onChange={handleChangePage}
          page={page}
          siblingCount={1}
        />
      </div>
    </div>
  )
}
