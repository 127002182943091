import { REUSABLE_TOPICS_FIELDS as R } from '../remote-fields/reusable-topics-fields'
import { REUSABLE_TOPICS as T } from '../../../domain/courses/register-elearning-page'
import { remoteDateStringToHuman } from '../../utils/remote-datetime-utils'
import { defaultValueWhenEmpty } from '../../utils/inbound-utils'

const inbound = (remoteData) => {
  return remoteData.map((data) => {
    return {
      [T.CHECKED]: false,
      [T.ORIGIN_COURSE_ID]: data[R.ORIGIN_COURSE_ID],
      [T.ORIGIN_COURSE_NAME]: defaultValueWhenEmpty(
        data[R.ORIGIN_COURSE_NAME],
        'Nenhum Curso Vinculado'
      ),
      [T.ORIGIN_SERVICE_SKU]: defaultValueWhenEmpty(
        data[R.ORIGIN_SERVICE_SKU],
        'SKU não informado'
      ),
      [T.RELATIONSHIP_ID]: data[R.RELATIONSHIP_ID],
      [T.TITLE]: data[R.TITLE],
      [T.TOPIC_ID]: data[R.TOPIC_ID],
      [T.UPDATED_AT]: remoteDateStringToHuman(data[R.UPDATED_AT])
    }
  })
}

export const ReusableTopicsAdapter = { inbound }
