import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 72,
    marginLeft: 132
  },
  divider: {
    width: 804,
    marginTop: 8,
    marginBottom: 8
  },
  subTitle: {
    marginLeft: 132,
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 32
  },
  cardContent: {
    marginLeft: 132,
    display: 'flex',
    gap: 24
  }
}))

export default useStyles
