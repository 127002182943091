import React, { useContext } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import Users from '../../../../../services/accounts/users'
import Messages from '../../../../../helpers/messages'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../../components/SnackbarOutlet'
import useStyles from './styles'

export function ResetPasswordDialog({
  passwordDialogOpen,
  setPasswordDialogOpen
}) {
  const classes = useStyles()
  const { userData, setSuccessMessage, setErrorMessage } =
    useContext(GeneralContext)

  const handleClose = () => {
    setPasswordDialogOpen(false)
  }

  async function sendEmail() {
    const userEmail = userData.email
    const data = {
      email: userEmail,
      integration_type: 'email_password'
    }
    try {
      const response = await Users.recoverPasswordEmail(data)
      if (response.status === 204) {
        setSuccessMessage(Messages.emailSent.success)
      }
      if (response.status === 401 || response.status === 404) {
        setErrorMessage(Messages.incorrectData.error)
      } else if (response.status === 503) {
        setErrorMessage(Messages.systemUnavailable.error)
      } else if (
        (response.status !== 204 && response.status === 401) ||
        (response.status === 404 && response.status !== 503)
      ) {
        setErrorMessage(Messages.systemUnavailable.error)
      }
    } catch (err) {
      console.error(err)
    } finally {
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        title="Resetar senha do usuário"
        secondaryButton="CANCELAR"
        primaryButton="RESETAR"
        size="md"
        open={passwordDialogOpen}
        handleClose={() => handleClose()}
        handleConfirm={() => sendEmail()}
        alert
        label="Sim, tenho certeza!"
      >
        <Typography variant="body1" style={{ color: '#000' }}>
          Tem certeza que deseja resetar a senha do usuário?
        </Typography>
        <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Ao realizar essa ação o usuário receberá um e-mail para definir a nova
          senha.{' '}
        </Typography>
      </Dialog>

      <div className={classes.root}>
        <SnackbarOutlet />
      </div>
    </div>
  )
}
