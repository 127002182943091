import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import {
  mockedServerAnswer,
  factoryWithCount
} from '../../../helpers/mock-server-utils'
import { USER_FIELDS_LIST_ITEM } from '../remote-fields/user-list-fields'
import { listItemAdapterInbound } from './user-page-adapters'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para carregamento de lista de usuários na pagina de gerenciamento de usuários
 * @returns lista de usuários apos passar por um adaptador de entrada
 */
const getUserManagementPagesList = async (search = '') => {
  const path = ''
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)
  return listItemAdapterInbound(response.data)
}

/* eslint-enable no-unused-vars */

const userManagementPagefactory = (index) => {
  return {
    [USER_FIELDS_LIST_ITEM.KEY]: index,
    [USER_FIELDS_LIST_ITEM.FULL_NAME]: `Usuário ${index}`,
    [USER_FIELDS_LIST_ITEM.CPF]: `${index}999999999${index}`,
    [USER_FIELDS_LIST_ITEM.EMAIL]: 'danilofelipe.mendes@gmail.com',
    [USER_FIELDS_LIST_ITEM.STATUS]: Math.ceil(Math.random() * 10) % 2 === 1
  }
}

const getUserManagementPagesListMocked = (search = '') => {
  return new Promise((resolve, reject) => {
    mockedServerAnswer(
      factoryWithCount(9, userManagementPagefactory),
      true,
      1000,
      listItemAdapterInbound
    )
      .then((list) => {
        if (search.trim() === '') {
          resolve(list)
        }
        resolve(
          list.filter((value) =>
            value[USER_FIELDS_LIST_ITEM.FULL_NAME].includes(search)
          )
        )
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { getUserManagementPagesListMocked as getUserManagementPagesList }
