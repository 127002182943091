import React, { useMemo, useState } from 'react'
import { Button, Tabs } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import useStyles from './style'

import AdditionalSimulated from '../../../components/AdditionalSimulated'
import TableSimulatedBooks from '../../../components/TableSimulatedBooks'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { useQueryParams } from '../../../hooks/use-query-params'
import { ContainerWithLoader } from '../../../components/ContainerWithLoader'
import { BannerHomeTest } from '../../promotional/BannerHomeConecta/components/BannerHome'

export const COURSES_LIST_TABS_LABELS_ENUM = Object.freeze({
  SIMULATED: 'simulados',
  BANNER: 'página inicial'
})

const coursesListTabsMap = new Map()
coursesListTabsMap.set(COURSES_LIST_TABS_LABELS_ENUM.SIMULATED, 0)
coursesListTabsMap.set(COURSES_LIST_TABS_LABELS_ENUM.BANNER, 1)

export function CourseConecta() {
  const classes = useStyles()

  const queryParams = useQueryParams()
  const tab = queryParams.get('tab')

  const [showDialog, setShowDialog] = useState(false)

  const handleOpenDialogAdditionalSimulated = () => {
    setShowDialog(true)
  }

  const handleCloseDialogAdditionalSimulated = () => {
    setShowDialog(false)
  }

  const tabOptions = [
    {
      label: COURSES_LIST_TABS_LABELS_ENUM.SIMULATED,
      content: (
        <>
          <Button
            className={classes.button}
            color="primary"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => handleOpenDialogAdditionalSimulated()}
          >
            NOVO SIMULADO
          </Button>

          <TableSimulatedBooks />
        </>
      )
    },
    {
      label: COURSES_LIST_TABS_LABELS_ENUM.BANNER,
      content: (
        <>
          <BannerHomeTest />
        </>
      )
    }
  ]

  const selectedTabIndex = useMemo(() => {
    if (tab) {
      return coursesListTabsMap.get(tab)
    }
    return coursesListTabsMap.get(COURSES_LIST_TABS_LABELS_ENUM.SIMULATED)
  }, [tab])

  return (
    <div>
      <SnackbarOutlet />
      <Typography variant="h4" className={classes.title}>
        Conecta
      </Typography>
      <ContainerWithLoader>
        <Tabs
          selectedTabIndex={selectedTabIndex}
          screenSize="desktop"
          color="primary"
          variant="scrollable"
          showDivider
        >
          {tabOptions}
        </Tabs>
      </ContainerWithLoader>

      <AdditionalSimulated
        setShowDialog={handleCloseDialogAdditionalSimulated}
        showDialog={showDialog}
      />
    </div>
  )
}
