export const COURSE_ECOMMERCE_FIELDS = {
  ACCESS_WINDOW_IN_MONTHS: 'accessWindowInMonth',
  ACTIVE: 'active',
  CATEGORIES: 'categories',
  CATEGORY_ID: 'categoryId',
  COURSE_LENGTH: 'courseLength',
  DESCRIPTION: 'description',
  ID: 'id',
  IMAGE_BINARY: 'imageBinary',
  IMAGE_PATH: 'imagePath',
  INFORMATION_TABS: 'informationTabs',
  INSERTED_AT: 'insertedAt',
  KEYWORDS: 'keywords',
  NAME: 'name',
  PRICE: 'price',
  SELLING_END_DATE: 'sellingEndDate',
  SELLING_START_DATE: 'sellingStartDate',
  SKU: 'sku',
  SLUG: 'slug',
  STATUS: 'status',
  SUBCATEGORY_ID: 'subCategoryId',
  TABS: 'courseTabs',
  UPDATED_AT: 'updatedAt'
}
