/* eslint-disable no-sequences */
import React, { useState } from 'react'
import { TextInput, Dialog } from '@equipe-ninja/saraiva-ui'
import {
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import { DatePickerSaraiva } from '@equipe-ninja/saraiva-ui/core/date_picker/DatePicker'
import ticketService from '../../services/ticketService'

import useStyles from './dialogStyles'

const SMALL = 'md'

const DialogForm = ({ open, handleClose }) => {
  const classes = useStyles()

  const [newVoucher, setNewVoucher] = useState({
    ticket_params: {
      code_prefix: '',
      total_available: 1,
      tmp_library_code: '',
      initial_date: '',
      final_date: '',
      quantity_chars: 12,
      tmp_library_name: '',
      tmp_library_number: ''
    },
    quantity_tickets: 1
  })

  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [voucherType, setVoucherType] = useState('')
  const [initialDateError, setInitialDateError] = useState(false)
  const [initialDateErrorMessage, setInitialDateErrorMessage] = useState('')
  const [finalDateError, setFinalDateError] = useState(false)
  const [finalDateErrorMessage, setFinalDateErrorMessage] = useState('')
  const [userPerVoucherError, setUserPerVoucherError] = useState(false)
  const [userPerVoucherErrorMessage, setUserPerVoucherErrorMessage] =
    useState('')
  const [ticketQtyError, setTicketQtyError] = useState(false)
  const [ticketQtyErrorMessage, setTicketQtyErrorMessage] = useState('')
  const [saving, setSaving] = useState(false)

  const isValid = () => {
    const { ticket_params: ticketParams, quantity_tickets } = newVoucher
    const {
      code_prefix,
      tmp_library_code,
      initial_date,
      final_date,
      tmp_library_name,
      tmp_library_number,
      total_available
    } = ticketParams

    return !(
      code_prefix &&
      tmp_library_code &&
      !initial_date.includes('aN') &&
      !initial_date.includes('1969') &&
      !final_date.includes('aN') &&
      !final_date.includes('1969') &&
      final_date &&
      initial_date &&
      tmp_library_name &&
      tmp_library_number &&
      voucherType &&
      !total_available < 1 &&
      !quantity_tickets < 1
    )
  }

  const handleInitialDate = (str) => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)
    if (str < dateNow) {
      setInitialDateError(true)
      setInitialDateErrorMessage('Não é possível criar vouchers retroativos.')
      setInitialDate(null)
      setNewVoucher({
        ...newVoucher,
        ticket_params: {
          ...newVoucher.ticket_params,
          initial_date: ''
        }
      })
      return
    }
    setInitialDateErrorMessage('')
    setInitialDateError(false)
    setInitialDate(str)
    const date = new Date(str)
    const mnth = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const newInitialDate = [date.getFullYear(), mnth, day].join('-')
    setNewVoucher({
      ...newVoucher,
      ticket_params: {
        ...newVoucher.ticket_params,
        initial_date: newInitialDate
      }
    })
  }

  const handleFinalDate = (str) => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)
    if (str < dateNow) {
      setFinalDateError(true)
      setFinalDateErrorMessage('Não é possível criar vouchers retroativos.')
      setFinalDate(null)
      setNewVoucher({
        ...newVoucher,
        ticket_params: {
          ...newVoucher.ticket_params,
          final_date: ''
        }
      })
      return
    }

    if (str < initialDate) {
      setFinalDateError(true)
      setFinalDateErrorMessage(
        'A data de fim não pode ser menor que a data de início.'
      )
      setFinalDate(null)
      setNewVoucher({
        ...newVoucher,
        ticket_params: {
          ...newVoucher.ticket_params,
          final_date: ''
        }
      })
      return
    }
    setFinalDateError(false)
    setFinalDateErrorMessage('')
    setFinalDate(str)
    const date = new Date(str)
    const mnth = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const newFinalDate = [date.getFullYear(), mnth, day].join('-')
    setNewVoucher({
      ...newVoucher,
      ticket_params: {
        ...newVoucher.ticket_params,
        final_date: newFinalDate
      }
    })
  }

  const handleChange = (e) => {
    setNewVoucher({
      ...newVoucher,
      ticket_params: {
        ...newVoucher.ticket_params,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleTicketQty = (e) => {
    if (
      e.target.value === '' ||
      e.target.value < 1 ||
      e.target.value === null ||
      e.target.value.match(/\D/g)
    ) {
      setNewVoucher({
        quantity_tickets: 0,
        ticket_params: {
          ...newVoucher.ticket_params
        }
      })
      setTicketQtyError(true)
      setTicketQtyErrorMessage(
        'Esse campo aceita apenas números maiores que zero.'
      )
      return
    }
    setTicketQtyError(false)
    setTicketQtyErrorMessage('')
    setNewVoucher({
      quantity_tickets: parseInt(e.target.value) || 0,
      ticket_params: {
        ...newVoucher.ticket_params
      }
    })
  }

  const handleUserPerVoucher = (e) => {
    if (
      e.target.value === '' ||
      e.target.value < 1 ||
      e.target.value === null ||
      e.target.value.match(/\D/g)
    ) {
      setNewVoucher({
        ...newVoucher,
        ticket_params: {
          ...newVoucher.ticket_params,
          total_available: 0
        }
      })
      setUserPerVoucherError(true)
      setUserPerVoucherErrorMessage(
        'Esse campo aceita apenas números maiores que zero.'
      )
      return
    }
    setUserPerVoucherError(false)
    setUserPerVoucherErrorMessage('')
    setNewVoucher({
      ...newVoucher,
      ticket_params: {
        ...newVoucher.ticket_params,
        total_available: parseInt(e.target.value) || 0
      }
    })
  }

  const changeVoucher = (e) => {
    setVoucherType(e.target.value)
    return (
      voucherType === 'many'
        ? (setNewVoucher({
            ...newVoucher,
            ticket_params: {
              ...newVoucher.ticket_params,
              total_available: 1
            }
          }),
          setUserPerVoucherErrorMessage(''),
          setUserPerVoucherError(false))
        : setNewVoucher({
            quantity_tickets: 1,
            ticket_params: {
              ...newVoucher.ticket_params
            }
          }),
      setTicketQtyErrorMessage(''),
      setTicketQtyError(false)
    )
  }

  const save = async () => {
    const trimmedVoucher = {
      ...newVoucher,
      ticket_params: Object.fromEntries(
        Object.entries(newVoucher.ticket_params).map(([key, value]) => [
          key,
          typeof value === 'number' ? value : value.trim()
        ])
      )
    }

    try {
      setSaving(true)

      const response = await ticketService.create(trimmedVoucher)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      window.location.assign(`/libraries/${result.data[0].tmp_library_code}`)
    } catch (error) {
      setSaving(false)
      console.error(error)
    }
  }

  return (
    <Dialog
      open={open}
      size="lg"
      title="Adicionar vouchers"
      secondaryButton="cancelar"
      primaryButton="salvar"
      handleClose={handleClose}
      handleConfirm={save}
      isDisabled={isValid() || saving}
    >
      <div className={classes.root}>
        <div className={classes.inputs}>
          <div className={classes.inputs}>
            <TextInput
              color="primary"
              required
              name="tmp_library_code"
              onChange={handleChange}
              size="xl"
              value={newVoucher.ticket_params.tmp_library_code}
              label="Chave da biblioteca no CRM"
            />
            <TextInput
              color="primary"
              required
              name="code_prefix"
              onChange={handleChange}
              size="xl"
              value={newVoucher.ticket_params.code_prefix}
              label="Prefixo do voucher"
            />

            <TextInput
              color="primary"
              required
              onChange={handleChange}
              name="tmp_library_name"
              size="xl"
              value={newVoucher.ticket_params.tmp_library_name}
              label="Nome da biblioteca no CRM"
            />
            <TextInput
              color="primary"
              required
              onChange={handleChange}
              name="tmp_library_number"
              size="xl"
              value={newVoucher.ticket_params.tmp_library_number}
              label="Nº da biblioteca"
            />
          </div>
          <div className={classes.divFlex}>
            <DatePickerSaraiva
              label="Data de início *"
              error={initialDateError}
              helperText={initialDateErrorMessage}
              value={initialDate}
              onChange={handleInitialDate}
            />
            <DatePickerSaraiva
              label="Data de fim *"
              error={finalDateError}
              helperText={finalDateErrorMessage}
              value={finalDate}
              onChange={handleFinalDate}
            />
          </div>
        </div>
        <div className={classes.radio}>
          <FormLabel className={classes.radioLabel}>Tipo de voucher</FormLabel>
          <RadioGroup value={voucherType} onChange={changeVoucher}>
            <FormControlLabel
              value="one"
              control={<Radio />}
              label="Um usuário por voucher"
            />
            <FormControlLabel
              value="many"
              control={<Radio />}
              label="Múltiplos usuários por voucher"
            />
          </RadioGroup>
        </div>
        {voucherType !== '' && (
          <div className={`${classes.divFlex} ${classes.topMargin}`}>
            <TextInput
              color="primary"
              required
              error={ticketQtyError}
              helperText={ticketQtyErrorMessage}
              label="Quantidade de vouchers"
              size={SMALL}
              name="quantity_tickets"
              disabled={voucherType === 'many'}
              value={newVoucher.quantity_tickets}
              onChange={(e) => handleTicketQty(e)}
            />
            <TextInput
              color="primary"
              required
              error={userPerVoucherError}
              helperText={userPerVoucherErrorMessage}
              label="Quantidade de usuários por vouchers"
              size={SMALL}
              name="total_available"
              disabled={voucherType === 'one'}
              value={newVoucher.ticket_params.total_available}
              onChange={(e) => handleUserPerVoucher(e)}
            />
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default DialogForm
