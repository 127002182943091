import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'

export const HelpIcon = ({ onClick, className }) => {
  return (
    <IconButton onClick={onClick}>
      <HelpRoundedIcon className={className} />
    </IconButton>
  )
}

HelpIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

HelpIcon.defaultProps = {
  className: null
}

export default HelpIcon
