import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import cls from 'classnames'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import { Button, Dialog } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import { CampaignForm } from '../../../CampaignForm'
import { GeneralContext } from '../../../../../../contexts/GeneralContext'
import {
  deleteDiscountCampaign,
  updateDiscountCampaign
} from '../../../../../../services/promotional-content/campaign-discounts'
import { RoutesMapping } from '../../../../../../helpers/routes-mapping'
import { DISCOUNT_CAMPAIGN_FIELDS } from '../../../../../../domain/promotional-content/discounts-campaign'
import {
  discountCampaignValidation,
  discountCampaignValidationFieldValidation
} from '../../../../../../domain/promotional-content/discounts-campaign/business'

const { ID, CAMPAIGN_NAME, CAMPAIGN_STATUS } = DISCOUNT_CAMPAIGN_FIELDS

export const CampaignDetails = (props) => {
  const { name, status, campaignId, onEdit } = props
  const classes = useStyles()
  const { brand } = useParams()
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [formState, setFormState] = useState({
    [ID]: campaignId,
    [CAMPAIGN_NAME]: name,
    [CAMPAIGN_STATUS]: status
  })

  const [formErrors, setFormErrors] = useState({
    [CAMPAIGN_NAME]: false,
    [CAMPAIGN_STATUS]: false
  })

  const statusText = () => {
    if (status) {
      return 'Em exibição no site'
    }
    return 'Inativo'
  }

  const deleteDiscountCampaignOnClick = () => {
    setShowDeleteDialog(true)
  }

  const editCampaignClickHandler = () => {
    setShowEditDialog(true)
  }

  const redirectToDiscountCampaingList = () => {
    history.replace(
      generatePath(RoutesMapping.promotionalDiscountList, { brand })
    )
  }

  const deleteCampaignHandler = () => {
    if (isFetching) return

    setIsFetching(true)

    deleteDiscountCampaign(campaignId)
      .then(() => {
        setSuccessMessage('Campanha removida com sucesso')
        redirectToDiscountCampaingList()
      })
      .catch(() => {
        setErrorMessage(
          'Não foi possível excluir essa campanha, tente novamente mais tarde'
        )
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const updateCampaign = () => {
    if (isFetching) return

    setIsFetching(true)

    updateDiscountCampaign(formState)
      .then((updateCampaignData) => {
        onEdit(updateCampaignData)
        setFormState(updateCampaignData)
        setSuccessMessage('Campanha atualizada com sucesso')
        setShowEditDialog(false)
      })
      .catch(() => {
        setErrorMessage(
          'Não foi possível alterar a campanha, tente novamente mais tarde'
        )
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const updateCampaignHandler = () => {
    if (isFetching) return

    const { isValid, errors } = discountCampaignValidation(formState)

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possíve alterar a campanha verifique os campos em vermelho'
      )
      return
    }

    updateCampaign()
  }

  const onChange = (ev, field) => {
    const error = discountCampaignValidationFieldValidation(
      field,
      ev.target.value
    )

    setFormState((prev) => ({ ...prev, [field]: ev.target.value }))

    setFormErrors((prev) => ({ ...prev, ...error }))
  }

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      [CAMPAIGN_NAME]: name,
      [CAMPAIGN_STATUS]: status
    }))
  }, [name, status])

  return (
    <section className={classes.paddingCard}>
      <Typography
        variant="h6"
        color="textSecondary"
        className={classes.marginTopXL}
      >
        Status da Campanha
      </Typography>

      <Typography
        variant="body1"
        className={cls(
          status ? classes.successStatus : classes.errorStatus,
          classes.marginTopSmaller
        )}
      >
        {statusText()}
      </Typography>

      <div className={classes.button}>
        <Button
          disabled={isFetching}
          color="primary"
          onClick={editCampaignClickHandler}
          variant="contained"
          startIcon={<CreateIcon />}
        >
          ALTERAR STATUS DA CAMPANHA
        </Button>

        <Button
          disabled={isFetching}
          className={classes.buttonCampaign}
          onClick={deleteDiscountCampaignOnClick}
          variant="outlined"
          color="default"
          startIcon={<DeleteIcon />}
        >
          EXCLUIR CAMPANHA
        </Button>
      </div>

      <Dialog
        title="Excluir campanha"
        size="lg"
        primaryButton="Confirmar"
        secondaryButton="Cancelar"
        handleClose={() => setShowDeleteDialog(false)}
        handleConfirm={deleteCampaignHandler}
        confirmWithoutCheck={() => null}
        open={showDeleteDialog}
        isDisabled={isFetching}
        label="Sim, tenho certeza!"
        alert
      >
        <Typography variant="body1" color="textSecondary">
          Quando excluida, esta campanha não poderá ser usufruida por nenhum
          usuário, nem mantida como histórico no sistema.
        </Typography>

        <Typography variant="body1" color="textSecondary">
          Esta ação não pode ser desfeita.
        </Typography>
      </Dialog>

      <Dialog
        title="Alterar status da campanha"
        size="lg"
        primaryButton="Salvar"
        secondaryButton="Cancelar"
        handleClose={() => setShowEditDialog(false)}
        handleConfirm={updateCampaignHandler}
        confirmWithoutCheck={() => null}
        open={showEditDialog}
        isDisabled={isFetching}
        label="Sim, tenho certeza!"
      >
        <CampaignForm
          formState={formState}
          formErrors={formErrors}
          onChange={onChange}
        />
      </Dialog>
    </section>
  )
}

CampaignDetails.propTypes = {
  name: PropTypes.string,
  status: PropTypes.bool,
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onEdit: PropTypes.func
}

CampaignDetails.defaultProps = {
  name: '',
  status: false,
  campaignId: 0,
  onEdit: () => null
}
