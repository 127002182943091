export const REMOTE_FIELDS_DETAILS = {
  CONTENT: 'content',
  END_DATE: 'end_date',
  KEY: 'id',
  REDIRECT_URL: 'redirect_url',
  START_DATE: 'start_date',
  STATUS: 'status',
  TITLE: 'title',
  URL: 'url'
}

export const REMOTE_FIELDS_LIST_ITEM = {
  CONTENT: 'html_content',
  END_DATE: 'final_date',
  KEY: 'id',
  REDIRECT_URL: 'redirect_url',
  START_DATE: 'initial_date',
  STATUS: 'status',
  TITLE: 'title',
  URL: 'url'
}
