import DateFnsAdapter from '@date-io/date-fns'

const ptErrors = {
  'string.empty': 'Campo obrigatório',
  'array.includes': 'Campo obrigatório',
  'array.items.base': 'Campo obrigatório',
  'date.base': 'Campo obrigatório',
  'object.base': 'Campo obrigatório',
  'custom.beforeDate': 'Data inválida',
  'custom.afterDate': 'Data inválida',
  'custom.passedDate': 'Data inválida',
  'custom.equalsfields': 'Campos iguais',
  'string.email': 'O email deve ser um email válido',
  'custom.inThePast': 'Não pode ser uma data retroativa',
  'string.pattern.base': 'Campo com formato inválido',
  'custom.requiredValue': 'Campo obrigatório',
  'number.base': 'O valor informado deve ser um número',
  'alternatives.types': 'Campo obrigatório'
}

const isInacessibleFieldError = (error) => {
  return !!error?.message?.match('failed custom validation because')
}

/**
 *
 * @param {*} formValues objeto com os valores que sera validado pelo schema
 * @param {*} schema schema de validacoes
 * @returns objeto com mesmo formato do valors do form
 *
 * ### Exemplo
 * ```js
 * const values = {name: '', lastName: ''}
 * const schema = {name: Joi.string().required()}
 *
 * validateAndGenerateErrors(values, schema)
 * // {isValid: false, errors: {name: 'Campo obrigatório', lastName: false}}
 * ```
 */
export const validateAndGenerateErrors = (formValues, schema, context = {}) => {
  const fieldsWithoutErrors = Object.fromEntries(
    Object.keys(formValues).map((e) => [e, false])
  )

  const { error = false } = schema.validate(formValues, {
    messages: ptErrors,
    abortEarly: false,
    allowUnknown: true,
    context
  })

  return {
    isValid: error === false,
    errors: {
      ...fieldsWithoutErrors,
      ...(error
        ? Object.fromEntries(
            error.details.map((a) => [a.context.label, a.message])
          )
        : {})
    }
  }
}

const validateSchemaAndSliceField = (
  field,
  value,
  formValues,
  schema,
  context
) => {
  const { errors } = validateAndGenerateErrors(
    { ...formValues, [field]: value },
    schema,
    context
  )

  return Object.fromEntries(Object.entries(errors).filter(([k]) => k === field))
}

/**
 * Valida um campo de um schema individualmente
 * @param {*} field Nome do campo do schema que sera validado
 * @param {*} value O valor que será validado
 * @param {*} schema O schema completo do dominio
 * @returns Objeto com o formato com o field e mensagem de erro ou falso quando o
 * campo não possuir error
 *
 * ### Ex.:
 * ```js
 * const schema = Joi.object({name: Joi.string().required()})
 * validateSingleFieldAndGenerateErrors('name', null, schema)//{name: 'Campo obrigatorio'}
 * validateSingleFieldAndGenerateErrors('name', 'John', schema)//{name: false}
 * ```
 */
export const validateSingleFieldAndGenerateErrors = (
  field,
  value,
  formValues,
  schema,
  context = {}
) => {
  // eslint-disable-next-line no-underscore-dangle
  const fieldSchema = schema._ids._byKey.get(field)?.schema

  if (!fieldSchema) return { [field]: false }

  try {
    const { error = false } = fieldSchema.validate(value, {
      messages: ptErrors,
      abortEarly: true,
      allowUnknown: true,
      context
    })

    if (isInacessibleFieldError(error)) {
      return validateSchemaAndSliceField(
        field,
        value,
        formValues,
        schema,
        context
      )
    }

    if (!error) return { [field]: false }

    return {
      [field]: error.details.map((e) => e.message).join(' ')
    }
  } catch (error) {
    if (error?.message?.match('Invalid reference exceeds the schema root')) {
      return validateSchemaAndSliceField(
        field,
        value,
        formValues,
        schema,
        context
      )
    }

    throw error
  }
}

/**
 * constroi um validador customizado para comparar datas
 * @param {*} beforeField o campo que servira de base de comparacao que deve ser anterior
 * @param {*} afterField o outro campo que deve ser posterior a outra data
 * @param {*} options configuracoes extras de comportamentos
 *
 * @returns um validator customizado que compara datas
 *
 * Caso um erro seja levantado o erro code e `custom.beforeDate`
 *
 * ```js
 * //Options
 * {
 *   validateRequiredAfter: 'verifica existencia em afterField' // default: false
 * }
 * ```
 */
export const customBeforeDateValidator = (
  beforeField,
  afterField,
  options = { validateRequiredAfter: false }
) => {
  return (value, helpers) => {
    const beforeDate = helpers.state.ancestors[0][beforeField]
    const afterDate = helpers.state.ancestors[0][afterField]

    if (options?.validateRequiredAfter && !afterDate) {
      return helpers.error('custom.requiredValue')
    }

    const isAfter = new DateFnsAdapter().isAfter(beforeDate, afterDate)

    if (isAfter) return helpers.error('custom.beforeDate')

    return value
  }
}

export const customAfterDateValidator = (beforeField, afterField) => {
  return (value, helpers) => {
    const beforeDate = helpers.state.ancestors[0][beforeField]
    const afterDate = helpers.state.ancestors[0][afterField]

    const datefns = new DateFnsAdapter()

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const isAfterDateInThePast = datefns.isBeforeDay(afterDate, today)

    if (isAfterDateInThePast) return helpers.error('custom.inThePast')

    const isAfter = datefns.isAfter(beforeDate, afterDate)

    if (isAfter) return helpers.error('custom.afterDate')

    return value
  }
}

export const customValidateEqualsFields = (fieldName, fieldOld) => {
  return (value, helpers) => {
    const fieldOriginal = helpers.state.ancestors[0][fieldOld]
    const fieldNew = helpers.state.ancestors[0][fieldName]

    if (fieldOriginal === fieldNew) return helpers.error('custom.equalsfields')

    return value
  }
}
