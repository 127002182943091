import { atom } from 'recoil'
import {
  couserOverviewDefaultForm,
  ecommerceDefaultForm
} from '../../../domain/courses/business'

export const courseOverviewState = atom({
  key: 'courseOverviewState',
  default: {
    isFetching: false,
    isLoading: false,
    isExecutingTask: false,
    rawData: {},
    form: couserOverviewDefaultForm(),
    ecommerceForm: ecommerceDefaultForm(),
    completionStatus: {
      ecommerce: false,
      subjects: false,
      fullpostedCount: 0
    }
  }
})
