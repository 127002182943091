import React, { useState, useEffect, useContext, useRef } from 'react'
import { debounce } from 'lodash'
import {
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { Button, Table } from '@equipe-ninja/saraiva-ui'
import { useHistory, useParams } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import EditIcon from '@material-ui/icons/Edit'

import { Dialog } from '@equipe-ninja/saraiva-ui/core/dialog/Dialog'
import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { Snackbar } from '@equipe-ninja/saraiva-ui/core/snackbar/Snackbar'
import ticketService from '../../../services/ticketService'
import useStyles from './styles.js'
import BackButton from '../../../components/BackButton'
import ExportationDialog from '../../../components/ExportationDialog'
import Tabs from '../Tabs'
import Loading from '../../../components/Loading'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { formatGMTDate } from '../../../helpers/formatDate'
import EditDate from './EditDate'
import messages from '../../../helpers/messages'
import { snackbarStyle } from '../../../layouts/styles'

function ticketType(ticket) {
  return ticket.total_available === 1
    ? 'Um usuário por voucher'
    : 'Múltiplos usuários por voucher'
}

function ticketText(ticketsParam) {
  return ticketsParam.some((x) => x.totalActivated.includes('0 /'))
    ? 'Vouchers excluídos não poderão mais ser ativados por nenhum usuário.'
    : 'Vouchers ativados por usuários, quando excluídos, farão com que todos usuários vinculados a eles percam seu acesso à biblioteca.'
}

function prepareExportationColumns(selectedColumns) {
  return selectedColumns.map((sc) => sc.value)
}

const EXPORT_OPTIONS = [
  { label: 'Voucher', value: 'code', order: 1 },
  {
    label: 'Vouchers ativados',
    value: 'total_activated',
    order: 6,
    children: [
      { label: 'Vouchers ativados', value: 'total_activated' },
      { label: 'Total disponível', value: 'total_available' }
    ]
  },
  { label: 'Data início', value: 'start_date', order: 2 },
  { label: 'Data fim', value: 'end_date', order: 3 },
  { label: 'Alterado em', value: 'updated_at', order: 4 },
  {
    label: 'Usuários',
    value: 'users',
    order: 5,
    children: [
      { label: "Primeiro nome", value: "first_name" },
      { label: "Último nome", value: "last_name" },
      { label: "Email", value: "email" },
      { label: "Ticket ativado em", value: "ticket_activated_at" },
      { label: "Ticket inativado em", value: "inactivated_at" },
      { label: "Ticket status", value: "status" },
    ]
  }
];

function Tickets() {
  const classes = useStyles()
  const { libraryCode } = useParams()
  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [showingExportationDialog, toggleExportationDialog] = useState(false)
  const [selectedTickets, selectTickets] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDate, setOpenEditDate] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('code')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searching, setSearching] = useState(false)
  const [alertSnackBar, setAlertSnackBar] = useState(false)
  const [isArchived, setIsArchived] = useState(false)

  const { setErrorMessage, errorMessage } = useContext(GeneralContext)

  const history = useHistory()
  const dropDownNode = useRef()
  const snackbarStyles = snackbarStyle()

  useEffect(() => {
    fetchTickets()
    // eslint-disable-next-line
  }, [history, libraryCode])

  useEffect(() => {
    if (dropDown) {
      document.addEventListener('mousedown', toCloseDropDown)
    } else {
      document.removeEventListener('mousedown', toCloseDropDown)
    }

    return () => {
      document.removeEventListener('mousedown', toCloseDropDown)
    }
  }, [dropDown])

  useEffect(() => {
    searchValue.length > 0 ? searchTicketsBy() : fetchTickets()
    // eslint-disable-next-line
  }, [searchValue])

  async function fetchTickets() {
    try {
      setLoading(true)

      const response = await ticketService.fetchTicketsByLibraryCode(
        libraryCode
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()
      if (result.data.length === 0) {
        history.push('/libraries')
      }

      if (result.data.some((ticket) => ticket.archived === true)) {
        setIsArchived(true)
      }

      setTickets(
        result.data.sort((a, b) => b.total_activated - a.total_activated)
      )

      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const onExportTickets = (selectedColumns) => {
    const hasUsers = selectedColumns.some(column => column.value === 'users')
    const sortedColumns = selectedColumns.sort((a, b) => a.order - b.order)
    const allColumns = sortedColumns.reduce((items, value) => [...items, ...(value.children ? value.children : [value])], [])
    if (hasUsers) {
      exportTicketsUsers(allColumns)
    } else {
      exportTickets(allColumns)
    }
  }

  const exportTickets = async (selectedColumns) => {
    try {
      setExporting(true)

      const payload = {
        ticketIds: selectedTickets.map((t) => t.key),
        columns: prepareExportationColumns(selectedColumns)
      }

      const response = await ticketService.exportLibraryTickets(
        libraryCode,
        payload
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const universalBOM = '\uFEFF'
      const csvContent = universalBOM + (await response.text())
      const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8'
      })
      const data = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = data
      link.download = `biblioteca-${libraryCode}-vouchers.csv`
      link.click()

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        setExporting(false)
        toggleExportationDialog(false)
      }, 100)
    } catch (error) {
      console.error(error)
      setErrorMessage('Não foi possível exportar os usuários.')
      setExporting(false)
    }
  }

  const exportTicketsUsers = async (selectedColumns) => {
    try {
      setExporting(true)

      const payload = {
        ticketIds: selectedTickets.map((t) => t.key),
        columns: prepareExportationColumns(selectedColumns)
      }

      const response = await ticketService.exportTicketsUsers(
        libraryCode,
        payload
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const universalBOM = '\uFEFF'
      const csvContent = universalBOM + (await response.text())
      const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8'
      })
      const data = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = data
      link.download = `biblioteca-${libraryCode}-vouchers.csv`
      link.click()

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        setExporting(false)
        toggleExportationDialog(false)
      }, 100)
    } catch (error) {
      console.error(error)
      setErrorMessage('Não foi possível exportar os usuários.')
      setExporting(false)
    }
  }

  async function searchTicketsBy() {
    try {
      setSearching(true)
      setDropDown(false)

      const response = await ticketService.searchTicketsBy(
        libraryCode,
        selectedFilter,
        searchValue
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setTickets(result.data)
    } catch (err) {
      console.error(err)
      setAlertSnackBar(true)
      setErrorMessage('Não foi possível realizar a busca. Tente novamente.')
    } finally {
      setSearching(false)
    }
  }

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
      setDropDown(false)
    }, 800)
  ).current

  const validateNumber = (n) => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
  }

  const handleSearchValue = (e) => {
    if (selectedFilter === 'active_vouchers') {
      setSearchTerm(validateNumber(e.target.value) || '' ? e.target.value : '')
      delayedQuery(validateNumber(e.target.value) || '' ? e.target.value : '')
    } else {
      setSearchTerm(e.target.value)
      delayedQuery(e.target.value)
    }
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
  }

  const toCloseDropDown = (e) => {
    if (dropDownNode && dropDownNode.current.contains(e.target)) {
      return
    }
    setDropDown(false)
  }

  if (loading) {
    return <Loading />
  }

  const deleteSelectedTickets = async () => {
    const deletedIds = selectedTickets.map((ticket) => ticket.key)

    const ids = {
      ticket_ids: deletedIds
    }

    try {
      setLoading(true)

      const response = await ticketService.deleteTickets(ids)

      if (response.status === 204) {
        setTickets(tickets.filter((ticket) => !deletedIds.includes(ticket.key)))
        selectTickets([])
        setOpenDialog(false)
        history.go(0)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const renderExportationDialog = () => {
    if (!showingExportationDialog) {
      return null
    }

    return (
      <ExportationDialog
        handleClose={() => toggleExportationDialog(false)}
        handleExport={onExportTickets}
        exporting={exporting}
        columns={EXPORT_OPTIONS}
      />
    )
  }

  function pickFirstDateFromTickets() {
    if (selectedTickets.length !== 0) {
      let initialStartDate = selectedTickets[0].start_date
      const dates = selectedTickets.map((ticket) =>
        ticket.start_date.split('/').reverse().join('/')
      )
      const formatedDate = dates.map((date) => new Date(date))

      initialStartDate = new Date(Math.min.apply(null, formatedDate))
      return initialStartDate.toLocaleDateString('pt-BR')
    }
    return null
  }

  function pickLastDateFromTickets() {
    if (selectedTickets.length !== 0) {
      let finalEndDate = selectedTickets[0].end_date
      const dates = selectedTickets.map((ticket) =>
        ticket.end_date.split('/').reverse().join('/')
      )
      const formatedDate = dates.map((date) => new Date(date))

      finalEndDate = new Date(Math.max.apply(null, formatedDate))
      return finalEndDate.toLocaleDateString('pt-BR')
    }
    return null
  }

  return (
    <div className={classes.root}>
      <BackButton onClick={() => history.push(`/libraries/${libraryCode}`)} />
      <Typography variant="h4">Biblioteca {libraryCode}</Typography>

      {renderExportationDialog()}

      <Paper variant="outlined" className={classes.paper}>
        <Tabs activeTab="tickets" />

        {isArchived && (
          <div className={classes.snackDiv}>
            <Snackbar classes={() => snackbarStyles} noClose severity="info">
              {messages.archivedLibrary.details}
            </Snackbar>
          </div>
        )}
        <div
          className={
            selectedTickets.length > 0
              ? `${classes.content} ${classes.contentBG}`
              : classes.content
          }
        >
          <div className={classes.breadcrumbs}>
            <Button color="primary" onClick={() => { }}>
              INÍCIO
            </Button>
          </div>
          {selectedTickets.length > 0 ? (
            <div className={classes.buttons}>
              <Button
                color="primary"
                onClick={() => setOpenEditDate(true)}
                startIcon={<EditIcon />}
                variant="outlined"
                disabled={selectedTickets.length === 0 || isArchived}
                className={classes.button}
              >
                ALTERAR DATA
              </Button>

              <Button
                color="primary"
                onClick={() => setOpenDialog(true)}
                startIcon={<DeleteIcon />}
                variant="outlined"
                disabled={selectedTickets.length === 0 || isArchived}
                className={classes.button}
              >
                EXCLUIR
              </Button>

              <Button
                color="primary"
                onClick={() => toggleExportationDialog(true)}
                startIcon={<GetAppIcon />}
                variant="contained"
                disabled={selectedTickets.length === 0}
                className={classes.button}
              >
                EXPORTAR
              </Button>
            </div>
          ) : (
            <div
              className={classes.searchWrapper}
              onClick={() => setDropDown(true)}
            >
              <SearchBar
                value={searchTerm}
                onChange={handleSearchValue}
                clearSearch={clearSearch}
                placeholder="Buscar por:"
                size="sm"
                goBackSearch={() => history.go(0)}
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              />

              {dropDown && (
                <Paper ref={dropDownNode} className={classes.paperSearch}>
                  <p style={{ margin: '0px 0px 4px 0px' }}>Filtrar por:</p>
                  <RadioGroup
                    style={{ paddingLeft: 12, margin: 0 }}
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                  >
                    <FormControlLabel
                      value="code"
                      control={<Radio size="small" />}
                      label="Voucher"
                    />
                    <FormControlLabel
                      value="active_vouchers"
                      control={<Radio size="small" />}
                      label="Vouchers ativados"
                    />
                  </RadioGroup>
                </Paper>
              )}
            </div>
          )}
        </div>
        {searching ? (
          <Loading />
        ) : (
          <Table
            displayRowCheckbox
            selectedRows={selectedTickets}
            onCheckRows={(rows) => selectTickets(rows)}
            cells={[
              'Voucher',
              {
                name: 'Vouchers ativados',
                order: true,
                code: 'totalActivated'
              },
              {
                name: 'Data de início',
                order: true,
                code: 'start_date'
              },
              {
                name: 'Data de fim',
                order: true,
                code: 'end_date'
              },
              {
                name: 'Alterado em',
                order: true,
                code: 'updatedAt'
              },
              { name: 'Tipo de voucher', order: true, code: 'voucherType' }
            ]}
            rows={tickets.map((ticket) => {
              return {
                key: ticket.id,
                code: ticket.code,
                totalActivated: `${ticket.total_activated} / ${ticket.total_available}`,
                start_date: formatGMTDate(ticket.start_date),
                end_date: formatGMTDate(ticket.end_date),
                updatedAt: formatGMTDate(ticket.updated_at),
                voucherType: ticketType(ticket),
                redirectTo: {
                  path: `/libraries/${libraryCode}/tickets/${ticket.id}/users`,
                  fromColumn: 'code'
                }
              }
            })}
          />
        )}
      </Paper>

      {openDialog && (
        <Dialog
          open={openDialog}
          title="Excluir voucher"
          secondaryButton="cancelar"
          primaryButton="excluir"
          size="md"
          handleClose={() => setOpenDialog(false)}
          handleConfirm={deleteSelectedTickets}
          label="Sim, tenho certeza!"
          alert
        >
          {ticketText(selectedTickets)}
        </Dialog>
      )}
      {openEditDate && (
        <EditDate
          open={openEditDate}
          handleClose={() => setOpenEditDate(false)}
          listIds={selectedTickets.map((ticket) => ticket.key)}
          setLoading={setLoading}
          startDate={pickFirstDateFromTickets()}
          endDate={pickLastDateFromTickets()}
          ticketType={
            selectedTickets.length === 0 ? null : selectedTickets[0].voucherType
          }
        />
      )}

      {
        <Snackbar
          show={alertSnackBar}
          handleClose={() => setAlertSnackBar(false)}
          severity="error"
        >
          {errorMessage}
        </Snackbar>
      }
    </div>
  )
}

export default Tickets
