import { SCHEDULED_CONTENT_REMOTE_EXERCISE_TYPE as REMOTE } from '../remote-fields/scheduled-content'
import { SCHEDULED_CONTENT_EXERCISE_TYPE as LOCAL } from '../../../domain/courses'

const inbound = (type) => {
  const [key] = Object.entries(REMOTE).find(
    (keyValueArr) => keyValueArr[1] === type
  )

  if (!key) {
    throw new Error(
      `${type} is not a valid value for remote scheduled content exercise type`
    )
  }

  return LOCAL[key]
}

const outbound = (type) => {
  const [key] = Object.entries(LOCAL).find(
    (keyValueArr) => keyValueArr[1] === type
  )

  if (!key) {
    throw new Error(
      `${type} is not a valid value for local scheduled content exercise type`
    )
  }

  return REMOTE[key]
}

export const ScheduledContentExerciseTypeAdapter = {
  inbound,
  outbound
}
