import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '85%',
    margin: '0 auto',
    padding: 0
  },
  backButton: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(40)
  },
  divider: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8)
  },
  subtitle: {
    marginBottom: theme.typography.pxToRem(32)
  },
  detailsContainer: {
    marginTop: theme.typography.pxToRem(32),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),

    '& .info-group': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(8)
    }
  },
  fixationExerciseContainer: {
    marginTop: theme.typography.pxToRem(32),
    paddingBottom: theme.typography.pxToRem(32),

    '& .cta': {
      marginTop: theme.typography.pxToRem(32),
      display: 'flex',
      gap: theme.typography.pxToRem(24)
    }
  },
  questionsContainer: {
    marginTop: theme.typography.pxToRem(32),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),

    '& div:first-child': {
      marginTop: 0
    }
  },
  questionManagementForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),

    '& .form-group': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(24),
      alignItems: 'flex-start'
    },

    '& .input-group': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(16)
    },

    '& .alternatives-group': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(32)
    }
  },
  pagination: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center'
  }
}))

export { useStyles }
