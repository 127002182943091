export const COURSE_DETAIL_FIELDS = {
  KEY: 'id',
  NAME: 'name',
  SKU: 'sku',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  LAST_ACESS: 'lastAcess',
  STATUS: 'status',
  EXTENDED: 'extended',
  EXTENDED_DATE: 'extendedDate',
  EXTENDED_BY: 'extendedBy',
  EXTENDED_FROM_TO: 'extendedFromTo',
  EXTENDED_JUSTIFICATION: 'extendedJustification',
  PRICE: 'price',
  DISCOUNT: 'discount',
  SUBTOTAL: 'subtotal',
  VOUCHER: 'voucher',
  VOUCHER_NAME: 'voucherName',
  VOUCHER_PERCENTAGE: 'voucherPercentage',
  REVERSAL: 'reversal',
  REVERSAL_DATE: 'reversalDate',
  REVERSAL_BY: 'reversalBy',
  REVERSAL_METHOD: 'reversalMethod',
  REVERSAL_JUSTIFICATION: 'reversalJustification',
  TOTAL: 'total'
}
