import React, { useState, useContext } from 'react'
import { TextInput, Dialog } from '@equipe-ninja/saraiva-ui'

import useStyles from './styles'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import IntegrationsService from '../../../../services/integrationsService'
import Messages from '../../../../helpers/messages'

function DeploymentForm() {
  const classes = useStyles()
  const {
    connection,
    updateConnection,
    toggleDeploymentForm,
    saving,
    setSaving
  } = useContext(ConnectionContext)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [deploymentId, setDeploymentId] = useState('')

  const clearStateAndCloseForm = () => {
    setDeploymentId('')
    toggleDeploymentForm(false)
  }

  const save = async () => {
    const payload = {
      deployment_id: deploymentId
    }

    try {
      setSaving(true)

      let response = await IntegrationsService.updateIntegration(
        connection.id,
        payload
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      response = await response.json()

      setSuccessMessage(Messages.deploymentForm.created)
      updateConnection(response)
      clearStateAndCloseForm()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setErrorMessage(Messages.deploymentForm.errorOnCreate)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      open
      handleClose={clearStateAndCloseForm}
      handleConfirm={save}
      title="ID de desenvolvimento"
      primaryButton="Salvar"
      isDisabled={saving || !deploymentId}
    >
      <div className={classes.content}>
        <TextInput
          autofocus
          color="primary"
          label="ID de desenvolvimento"
          type="text"
          value={deploymentId}
          onChange={(evt) => setDeploymentId(evt.target.value)}
          size="full"
        />
      </div>
    </Dialog>
  )
}

export default DeploymentForm
