import { selector } from 'recoil'
import { courseOverviewState } from '../atom/course-overview-state'

export const ecommerceForm = selector({
  key: 'ecommerceForm',
  get: ({ get }) => {
    return {
      values: get(courseOverviewState).ecommerceForm.values,
      errors: get(courseOverviewState).ecommerceForm.errors
    }
  },
  set: ({ get, set }, data) => {
    set(courseOverviewState, {
      ...get(courseOverviewState),
      ecommerceForm: { ...get(courseOverviewState).ecommerceForm, ...data }
    })
  }
})
