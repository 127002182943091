import { atom } from 'recoil'

export const subjectState = atom({
  key: 'subjectState',
  default: {
    isFetchingQuestions: false,
    checkedQuestions: [],
    questionsDatabase: { data: [], count: 0 }
  }
})
