import { PRODUCT_COURSE_FIELDS } from '..'

const { ID } = PRODUCT_COURSE_FIELDS

export const SELECTABLE_CATEGORIES = {
  CONCURSO: 1,
  OAB: 2,
  LFG_PLAY: 3,
  ATUALIZACAO_PRATICA: 4
}

const SELECTABLE_CATEGORIES_IDS = Object.values(SELECTABLE_CATEGORIES)

export const filterSelectablesCategories = (categories) => {
  return categories.filter((category) =>
    SELECTABLE_CATEGORIES_IDS.includes(category[ID])
  )
}
