import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiTab-wrapper': {
      fontSize: 15,
      marginBottom: 35
    }
  },
  rot: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  title: {
    marginBottom: 24
  },
  button: {
    marginTop: '24px',
    textAlign: 'right',
    marginBottom: '24px'
  },
  maxWidthColumn: {
    padding: 0
  },
  table: {
    width: 804,
    left: 372,
    top: 433,
    borderRadius: '8px 8px 5px 5px',
    marginLeft: 132,
    marginBottom: 80
  },
  headerCell: {
    font: 'roboto'
  },
  checkbox: {
    color: '#624C92',
    '&. checked': {
      color: '#624C92'
    }
  },
  checked: {},
  btn: {
    height: 50,
    paddingTop: 16,
    paddingLeft: 18,
    color: '#624C92'
  },
  text: {
    fontSize: 16,
    position: 'relative',
    top: 20,
    paddingLeft: 18,
    color: '#624C92'
  },

  tableContainer: {
    display: 'flex'
  },
  icon: {
    position: 'relative',
    top: '18px',
    opacity: '54%'
  },
  btnContainer: {
    textAlign: 'right',
    marginLeft: 'auto',
    marginRight: '28px',
    marginTop: 9
  },
  btnDelete: {
    color: '#A42C31',
    width: '140px',
    height: '40px'
  }
}))

export default useStyles
