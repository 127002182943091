import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { REMOTE_DISCOUNT_FIELDS as REMOTE } from '../remote-fields/discount-fields'
import { DiscountAdapter } from '../adapters/discount-adapter'
import { getResquestResponseHandler } from '../../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para carregamento de lista de campanhas de descontos
 * @returns lista de campanhas apos passar por um adaptador de entrada
 */

const getDiscountList = async (search = '') => {
  return new Promise((resolve, reject) => {
    const path = 'offers/api/v1/campaign'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((json) => {
        if (search !== '') {
          resolve(
            json.data.filter((e) => e[REMOTE.CAMPAIGN_NAME].includes(search))
          )
        }
        resolve(DiscountAdapter.listInbound(json))
      })
      .catch((err) => reject(err))
  })
}

export { getDiscountList }
