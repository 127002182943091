import Joi from 'joi'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../helpers/validation'
import { SELECTABLE_CATEGORIES } from '../../product-course/business'
import { COURSE_CREATE_FIELDS as F } from '../course-create-fields'

const schemaUrl = Joi.object({
  url: Joi.string().uri().required().messages({
    'string.base': `Url inválida`,
    'string.uri': 'Url inválida'
  })
})

const schemaCreate = Joi.object({
  [F.COURSE_NAME]: Joi.string().required(),
  [F.TYPE]: Joi.string().required(),
  [F.SKU]: Joi.string().required(),
  [F.CATEGORY]: Joi.number().required().messages({
    'number.base': 'Campo obrigatório'
  }),
  [F.SUB_CATEGORY]: Joi.any()
    .when(F.CATEGORY, {
      switch: [
        {
          is: SELECTABLE_CATEGORIES.ATUALIZACAO_PRATICA,
          then: Joi.string().allow('')
        },
        {
          is: SELECTABLE_CATEGORIES.LFG_PLAY,
          then: Joi.string().allow('')
        }
      ],
      otherwise: Joi.number()
    })
    .messages({
      'number.base': 'Campo obrigatório'
    })
})

export const courseUrlValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schemaUrl)
}

export const courseCreateFirstValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schemaCreate)
}

export const courseCreateFirstFieldValidation = (field, value, formValues) => {
  return validateSingleFieldAndGenerateErrors(
    field,
    value,
    formValues,
    schemaCreate
  )
}
