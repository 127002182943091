import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import useStyles from './styles'

export default function ContentTitle({ title, size, style }) {
  const classes = useStyles()

  const computeClassName = () => {
    switch (size) {
      case 'large':
        return classes.titleH3
      case 'medium':
        return classes.titleH4
      default:
        return classes.titleH5
    }
  }

  return (
    <Typography style={style} variant="h3" className={computeClassName()}>
      {title}
    </Typography>
  )
}

ContentTitle.propTypes = {
  title: PropTypes.string,
  /**
   * Valores: `large`, `medium` ou `small`
   */
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /**
   * usado para dar overrides em regras de estilos do componete interno
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.objectOf(PropTypes.any)
}

ContentTitle.defaultProps = {
  size: 'large',
  style: {},
  title: ''
}
