import { selector } from 'recoil'
import {
  courseTopicsState,
  computeSelectedTopicFromState
} from '../course-topics-state'
import { updateTopicById } from '../../../domain/courses/register-elearning-page/business'
import { REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS as F } from '../../../domain/courses/register-elearning-page/register-elearning-all-subject-list-fields'

const ACTIONS = {
  TOGGLE_AVAILABILITY: 'toggleAvailability',
  TOGGLE_FULL_POSTED: 'toggleFullPosted',
  TOPIC_RENAMED: 'TopicRenamed'
}

const toggleAvailabilityPayload = (relationId, isActivating) => ({
  action: {
    type: ACTIONS.TOGGLE_AVAILABILITY,
    id: relationId,
    isActivating
  }
})

const toggleFullPostedPayload = (topidId, isActivating) => ({
  action: {
    type: ACTIONS.TOGGLE_FULL_POSTED,
    id: topidId,
    isActivating
  }
})

const topicRenamedPayload = (relationId, title, courseId) => ({
  action: {
    type: ACTIONS.TOPIC_RENAMED,
    id: relationId,
    title,
    courseId
  }
})

const allTopicUpdater = (state, action) => {
  if (Object.values(ACTIONS).includes(action?.type)) {
    let newAllTopic = null

    switch (action.type) {
      case ACTIONS.TOGGLE_AVAILABILITY:
        newAllTopic = updateTopicById(action.id, state.allTopics, {
          [F.IS_AVAILABLE.value]: action.isActivating
        })
        break

      case ACTIONS.TOGGLE_FULL_POSTED:
        newAllTopic = updateTopicById(action.id, state.allTopics, {
          [F.FULL_POSTED.value]: action.isActivating
        })
        break

      case ACTIONS.TOPIC_RENAMED:
        newAllTopic = updateTopicById(action.id, state.allTopics, {
          [F.SUBJECT.value]: action.title
        })
        break

      default:
        newAllTopic = state.allTopics
    }

    return {
      allTopics: newAllTopic,
      ...computeSelectedTopicFromState({
        ...state,
        allTopics: newAllTopic
      })
    }
  }

  return { allTopics: state.allTopics }
}

export const actionOnTopicSelector = selector({
  key: 'actionOnTopicSelector',
  get: ({ get }) => {
    return {
      isExecutingTask: get(courseTopicsState).isExecutingTask,
      toggleAvailabilityPayload,
      toggleFullPostedPayload,
      topicRenamedPayload
    }
  },
  set: ({ get, set }, { isExecutingTask, action = null }) => {
    const newAllTopicUpdater = allTopicUpdater(get(courseTopicsState), action)

    set(courseTopicsState, {
      ...get(courseTopicsState),
      isExecutingTask,
      ...newAllTopicUpdater
    })
  }
})
