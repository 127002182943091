import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 24,
    marginRight: 20
  }
}))

export default useStyles
