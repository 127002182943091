import React, { useState, useEffect, useContext, useRef } from 'react'
import { SearchBar } from '@equipe-ninja/saraiva-ui'

import { useStyles } from './styles'
import { SubjectsTable } from './components/SubjectsTable'
import Subjects from '../../../../../services/courses/use-cases/courses-subjects-list'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import { CoursesSubjectsAdapter } from '../../../../../services/courses/adapters/courses-subjects_adapter'
import { debounce } from 'lodash'
import { disciplineListState } from '../../../../../atoms/disciplines'
import { useRecoilState } from 'recoil'

export function SubjectsTabContent() {
  const styles = useStyles()
  const [{ count, data: subjects }, setSubjects] =
    useRecoilState(disciplineListState)
  const [fecthSubject, setFecthSubject] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [debounceSearch, setDebounceSearch] = useState('')
  const { setErrorMessage } = useContext(GeneralContext)

  const delayedQuery = useRef(
    debounce((e) => {
      setDebounceSearch(e)
    }, 800)
  ).current

  async function fecthSubjectList(search) {
    try {
      setFecthSubject(true)
      const response = await Subjects.getCoursesSubjects(search, perPage, page)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      const bounded = CoursesSubjectsAdapter.inbound(result.data)

      setSubjects((prev) => ({ ...prev, count: result.count, data: bounded }))
      setFecthSubject(false)
    } catch (err) {
      setErrorMessage('Não foi possível buscar as disciplinas')
    }

    setFecthSubject(false)
  }

  useEffect(() => {
    fecthSubjectList(searchText, perPage, page)
  }, [])

  useEffect(() => {
    fecthSubjectList(debounceSearch, perPage, page)
  }, [debounceSearch, setErrorMessage, perPage, page])

  const clearSearchText = () => setSearchText('')

  return (
    <div className={styles.container}>
      <div className={styles.searchWrapper}>
        <SearchBar
          style
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value)
            delayedQuery(e.target.value)
          }}
          placeholder="Buscar por disciplina"
          size="md"
          clearSearch={clearSearchText}
          goBackSearch={clearSearchText}
          onSubmit={(e) => {
            e.preventDefault()
          }}
        />
      </div>

      <SubjectsTable
        data={subjects}
        perPage={perPage}
        setPerPage={setPerPage}
        page={page}
        setPage={setPage}
        subjectCount={count}
      />
    </div>
  )
}
