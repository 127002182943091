import { makeStyles } from '@material-ui/core/styles'
import { spacing, pageClasses } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...pageClasses(theme),
  subTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: 16,
    marginBottom: 40
  },
  Text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    marginBottom: '0px',
    marginRight: '0px',
    marginLeft: '0px',
    padding: '0px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  MainContentDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  cardContent: {
    width: '100%',
    maxHeight: 'calc(603px - 24px)'
  },
  colorError: {
    color: theme.palette.error.main
  },
  cardsListContainer: {
    '& div:first-child': {
      marginTop: 0
    },

    marginTop: theme.typography.pxToRem(24),

    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24)
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8)
  },
  cardName: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16)
  },
  cardIcon: {
    color: theme.palette.text.secondary
  },
  dialogFirstText: {
    marginTop: theme.typography.pxToRem(8)
  }
}))

export default useStyles
