import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: '100%'
  },
  cardForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24)
  },
  cardSubtitle: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(20)
  },
  fieldText: {
    color: theme.palette.text.secondary,
    margin: 0,
    marginBottom: theme.typography.pxToRem(16),

    '&:first-child': {
      marginTop: theme.typography.pxToRem(8)
    }
  },
  spanFilePath: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12)
  },
  divFileButton: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(10)
  },
  divFileUploaded: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(8)
  },
  colorError: {
    color: theme.palette.error.main
  },
  buttonError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  }
}))

export { useStyles }
