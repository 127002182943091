import React, { Component } from 'react'
import PropTypes from 'prop-types'
import contentsService from '../../services/contentsService'
import createH5pContent from '../../helpers/createH5pContent'
import createH5pIntegration from '../../helpers/createH5pIntegration'
import importScripts from '../../helpers/importScripts'
import importLinks from '../../helpers/importLinks'

class ShowH5p extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    const { file } = this.props
    contentsService()
      .getH5pContent(file)
      .then((contentResponse) => {
        this.setState({
          data: contentResponse.data
        })
      })
      .catch(() => {
        this.setState({
          error:
            'Erro ao carregar conteúdo, tente novamente em alguns instantes.'
        })
      })
  }

  render() {
    const { data, error } = this.state
    const { file } = this.props

    if (data.length === 0) {
      return <div>{error}</div>
    }

    const head = document.querySelector('head')

    const scripts = importScripts()
    head.appendChild(scripts)

    const links = importLinks()
    head.appendChild(links)

    const h5pIntegration = createH5pIntegration(file)
    head.appendChild(h5pIntegration)

    const h5pContent = createH5pContent(data)
    head.appendChild(h5pContent)

    return (
      <div className="h5p-iframe-wrapper">
        <iframe
          id="h5p-iframe-1"
          className="h5p-iframe"
          data-content-id="1"
          src="about:blank"
          frameBorder="0"
          scrolling="no"
          title={data.h5p_json.title}
        />
      </div>
    )
  }
}

export default ShowH5p

ShowH5p.propTypes = {
  file: PropTypes.string.isRequired
}
