export const SERVICE_REMOTE_FIELDS = {
  APP_ID: 'app_id',
  ACTIVE: 'active',
  BRAND: 'brand_id',
  CATEGORIES: 'categories',
  COURSE: 'course',
  DESCRIPTION: 'description',
  ID: 'id',
  IMAGE_PATH: 'image_path',
  INFORMATION_TABS: 'information_tabs',
  INSERTED_AT: 'inserted_at',
  KEYWORDS: 'keywords',
  NAME: 'name',
  PLAN_X_CAMPAIGN: 'plan_x_campaign',
  PLANS: 'plans',
  SELLING_END_DATE: 'selling_end_date',
  SELLING_START_DATE: 'selling_start_date',
  SKU: 'sku',
  SLUG: 'slug',
  STATUS: 'status',
  TABS: 'information_tabs',
  UPDATED_AT: 'updated_at',
  OUTBOUND_PLAN: 'plan_update',
  OUTBOUND_CATEGORIES: 'category_ids_update',
  OUTBOUND_TABS: 'information_tabs_update',
  OUTBOUNT_FILE: 'image_update'
}

export const COURSE_SERVICE_TABS_REMOTE_FIELDS = {
  CONTENT: 'content',
  ORDER: 'tab_order',
  TITLE: 'title'
}

export const COURSE_SERVICES_PLANS_FIELDS = {
  ACTIVE: 'active',
  AVAILABILITY_MONTHS: 'availability_months',
  DESCRIPTION: 'description',
  DISCOUNT_AMOUNT: 'discount_amount',
  DURATION_HOURS: 'duration_hours',
  ID: 'id',
  NAME: 'name',
  PAYMENT_FREQUENCY: 'payment_frequency',
  PRICE: 'price'
}

export const REMOTE_COURSE_SERVICE_STATUS = {
  DRAFT: 'draft',
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available'
}
