import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import { Button, Table } from '@equipe-ninja/saraiva-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { PROMOTIONAL_PAGES_LIST_ITEM_FIELDS } from '../../../../domain/promotional-content/promotional-pages'
import { getPromotionalPagesList } from '../../../../services/promotional-content/promotional-pages'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import ContentTitle from '../../../../components/ContentTitle'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import useStyles from './styles'

export function PromotionalPagesList() {
  const history = useHistory()
  const classes = useStyles()
  const [promoPageList, setPromoPageList] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const { setErrorMessage } = useContext(GeneralContext)
  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const navigateToCreateNewPage = () => {
    history.push(
      generatePath(RoutesMapping.promotionalPromoPagesNew, { brand })
    )
  }

  const headersCells = () => {
    const { TITLE, URL, START_DATE, END_DATE, STATUS } =
      PROMOTIONAL_PAGES_LIST_ITEM_FIELDS

    return [
      { name: TITLE.label, order: true, code: TITLE.value },
      { name: URL.label, order: true, code: URL.value },
      { name: START_DATE.label, order: true, code: START_DATE.value },
      { name: END_DATE.label, order: true, code: END_DATE.value },
      { name: STATUS.label, order: true, code: STATUS.value }
    ]
  }

  const anotateListItemWithRedirect = useCallback(
    (list) => {
      return list.map((item) => ({
        ...item,
        redirectTo: {
          fromColumn: PROMOTIONAL_PAGES_LIST_ITEM_FIELDS.TITLE.value,
          path: generatePath(RoutesMapping.promotionalPromoPagesEdit, {
            brand,
            id: item[PROMOTIONAL_PAGES_LIST_ITEM_FIELDS.KEY.value]
          })
        }
      }))
    },
    [brand]
  )

  useEffect(() => {
    setIsFetching(true)

    getPromotionalPagesList()
      .then((list) => {
        setPromoPageList(anotateListItemWithRedirect(list))
      })
      .catch(() => {
        setErrorMessage(
          'Não foi possível buscar a lista de páginas promocionais'
        )
      })
      .finally(() => setIsFetching(false))
  }, [setErrorMessage, anotateListItemWithRedirect])

  return (
    <ContentWithHeader
      title="Gerenciar páginas promocionais"
      backButtonRoute={generatePath(RoutesMapping.promotionalPage, {
        brand
      })}
    >
      <div className={classes.contentContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={navigateToCreateNewPage}
        >
          Criar Página
        </Button>
      </div>

      <Divider className={classes.divider} />

      {isFetching ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <ContentTitle title="Páginas" size="small" />

          <div className={classes.tableContainer}>
            <Table cells={headersCells()} rows={promoPageList} />
          </div>
        </>
      )}

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}
