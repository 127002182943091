/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Typography } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from '@equipe-ninja/saraiva-ui'
import Users from '../../../../services/accounts/users'
import { EbookDeleteDialog } from '../components/EbookDeleteDialog'
import useStyles from './styles'
import { MaterialDeleteDialog } from '../components/MaterialDeleteDialog'
import EditCaeDialog from '../components/EditCaeDialog'

export function UserProducts({ userId }) {
  const classes = useStyles()
  const [productsAux, setProductsAux] = useState({})
  const [products, setProducts] = useState({})
  const [ebookDialogOpen, setEbookDialogOpen] = useState(false)
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false)
  const [caeDialogOpen, setCaeDialogOpen] = useState(false)
  const [currentCae, setCurrentCae] = useState(null)
  const [caeId, setCaeId] = useState(null)
  const [appName, setAppName] = useState(null)

  const fetchProducts = async () => {
    try {
      const response = await Users.searchUserProducts(userId)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setProductsAux(result.information.Products)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [userId])

  useEffect(() => {
    if (Object.keys(productsAux).length > 0) {
      setProducts(productsAux)
    }
  }, [productsAux])

  const refreshData = () => {
    fetchProducts()
  }

  return (
    <>
      <div className={classes.cardContainer}>
        <div className={classes.infoCard}>
          <Typography variant="h6">Saraiva Conecta</Typography>
        </div>
      </div>
      <div className={classes.productsContainer}>
        {products.Conecta?.ebook && (
          <>
            {products.Conecta.ebook.map((ebook, index) => (
              <div key={index} className={classes.productsCard}>
                <div className={classes.product}>
                  <Typography>E-book</Typography>
                  <Typography variant="body2" color="textSecondary">
                    CAE: {ebook}
                  </Typography>
                </div>
                <Divider style={{ marginBottom: 16 }} />
                <div className={classes.deleteBtnContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CreateIcon />}
                    className={classes.editButton}
                    onClick={() => {
                      setCurrentCae(ebook)
                      setCaeDialogOpen(true)
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    className={classes.deleteButton}
                    onClick={() => {
                      setCaeId([Number(ebook)])
                      setAppName('baas')
                      setEbookDialogOpen(true)
                    }}
                  >
                    Excluir
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
        {products.Conecta?.public_material && (
          <>
            {products.Conecta.ebook.map((material, index) => (
              <div key={index} className={classes.productsCard}>
                <div className={classes.product}>
                  <Typography>Material complementar</Typography>
                  <Typography variant="body2" color="textSecondary">
                    CAE: {material}
                  </Typography>
                </div>
                <Divider style={{ marginBottom: 16 }} />
                <div className={classes.deleteBtnContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CreateIcon />}
                    className={classes.editButton}
                    onClick={() => {
                      setCurrentCae(material)
                      setCaeDialogOpen(true)
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    className={classes.deleteButton}
                    onClick={() => {
                      setCaeId([Number(material)])
                      setAppName('baas')
                      setMaterialDialogOpen(true)
                    }}
                  >
                    Excluir
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.infoCard}>
          <Typography variant="h6">LFG</Typography>
        </div>
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.infoCard}>
          <Typography>Curso</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Magistratura 2024
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Data de expiração: Junho/2024
            </Typography>
          </div>
        </div>
      </div>

      <EbookDeleteDialog
        ebookDialogOpen={ebookDialogOpen}
        setEbookDialogOpen={() => setEbookDialogOpen(false)}
        caeId={caeId}
        userId={userId}
        appName={appName}
        onDeletionComplete={fetchProducts}
      />

      <MaterialDeleteDialog
        materialDialogOpen={materialDialogOpen}
        setMaterialDialogOpen={() => setMaterialDialogOpen(false)}
      />

      <EditCaeDialog
        open={caeDialogOpen}
        onClose={() => setCaeDialogOpen(false)}
        currentCae={currentCae}
        userId={userId}
        onSuccessfulUpdate={refreshData}
      />
    </>
  )
}

UserProducts.propTypes = {
  userId: PropTypes.number.isRequired
}
