import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Tooltip from '@material-ui/core/Tooltip'

export const CopyMe = (props) => {
  const {
    valueToCopy,
    className,
    color,
    tooltip,
    showTooltip,
    afterCopyHandler
  } = props
  const [isShowingTooltip, setIsShowingTooltip] = useState(false)

  const onClick = () => {
    navigator.clipboard.writeText(valueToCopy).then(() => {
      if (showTooltip) setIsShowingTooltip(true)
      afterCopyHandler(valueToCopy)
    })
  }

  useEffect(() => {
    if (isShowingTooltip) {
      setTimeout(() => setIsShowingTooltip(false), 2000)
    }
  }, [isShowingTooltip])

  return (
    <div style={{ marginTop: '-12px', height: '24px' }}>
      <Tooltip arrow title={tooltip} placement="top" open={isShowingTooltip}>
        <IconButton color={color} className={className} onClick={onClick}>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

CopyMe.propTypes = {
  /**
   * valor que sera enviado para clipboard ao ser copiado
   */
  valueToCopy: PropTypes.string.isRequired,
  /**
   * className para customizar o IconButton interno
   */
  className: PropTypes.string,
  /**
   * prop repaassada para IconButton interno
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  /**
   * tooltip que sera exibido quando a copia for bem sucedido, default: 'Copiado!'
   */
  tooltip: PropTypes.string,
  /** indica se deve exibir ou esconer o tooltip depois de copiado, default: true */
  showTooltip: PropTypes.bool,
  /** handler para quando for o valor for copiado
   * passa o valor copiado para funcao
   * ### Exemplo
   * ```js
   * const afterCopyHandler = (value) => { console.log(value)}
   * ```
   */
  afterCopyHandler: PropTypes.func
}

CopyMe.defaultProps = {
  color: 'default',
  className: '',
  tooltip: 'Copiado!',
  showTooltip: true,
  afterCopyHandler: () => null
}
