import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  questionManagementForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),

    '& .form-group': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(24),
      alignItems: 'flex-start'
    },

    '& .input-group': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(16)
    },

    '& .alternatives-group': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(32)
    },

    '& .btn-container': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  removeQuestionButton: {
    marginTop: theme.typography.pxToRem(5),
    color: theme.palette.error.main
  }
}))

export { useStyles }
