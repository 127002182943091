import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, TextInput } from '@equipe-ninja/saraiva-ui'

export const EditDialog = ({
  openDialog,
  handleClose,
  handleConfirm,
  contentName,
  onChange
}) => {

  return (
    <>
    <Dialog
      open={openDialog}
      title="Editar nome"
      secondaryButton="cancelar"
      primaryButton="salvar"
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    >
      <TextInput
      color="primary"
      label="Nome"
      required
      name="name"
      value={contentName}
      onChange={onChange}
      size="full"
      />
    </Dialog>
    </>
  )
}

EditDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  contentName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default EditDialog