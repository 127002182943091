import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'

const addReusableTopics = async (courseId, relationshipId) => {
  const path = `/offers/api/v1/backoffice/course/${courseId}/reuse_subjects`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)

  const response = await httpClient.post(path, relationshipId, headers)
  const responseJson = await response.json()

  return responseJson
}

export { addReusableTopics }
