export const VOUCHER_TYPES = {
  ONE_USE: {
    value: 'one_per_item',
    label: 'Um usuário por voucher'
  },
  MANY_USES: {
    value: 'many_per_item',
    label: 'Múltiplos usuários por voucher'
  }
}
