import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    sectionTitle: {
      color: theme?.palette?.text?.primary || '#FFF',
      fontSize: '34px',
      marginBottom: '32px',
      marginTop: '40px'
    },

    exportTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '133.4%',
      marginBottom: '24px'
    },

    sectionText: {
      color: '#0000008a',
      fontFamily: 'Roboto',
      fontWeight: '400px',
      fontSize: '16px',
      fontStyle: 'normal',
      lineHeight: '150%',
      marginBottom: '24px'
    },

    checkboxContainer: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: '32px',
      marginLeft: '-10px'
    },

    checkbox: {
      '&.Mui-checked': {
        color: '#624C92'
      }
    },

    buttonX: {
      height: '56px',
      color: '#624C92',
      justifyContent: 'initial'
    },

    caeContainer: {
      display: 'flex',
      flexDirection: 'column'
    },

    caeInputBox: {
      display: 'flex',
      marginBottom: '24px',
      height: '56px'
    },

    addInputButton: {
      marginBottom: '32px',
      maxWidth: '244px',
      justifyContent: 'flex-start'
    },

    campaignContainer: {
      marginBottom: '24px',
      paddingRight: '36px',
      maxWidth: '838px'
    },

    dataPikerBox: {
      display: 'flex',
      marginBottom: '56px',
      position: 'relative'
    },

    linkButton: {
      width: '150px',
      height: '42px'
    },

    cancelButton: {
      marginLeft: '10px',
      width: '150px',
      height: '42px'
    },

    accessLinkInput: {
      marginRight: '24px'
    },

    alert: {
      position: 'fixed',
      right: 0,
      top: 0,
      marginTop: '88px'
    }
  }
})

export default useStyles
