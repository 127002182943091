import scripts from '../components/config/h5p/scripts'

function createScriptTag(src) {
  const script = document.createElement('script')
  script.src = src
  script.async = false
  return script
}

export default function importScripts() {
  let script = null
  const scriptFragment = document.createDocumentFragment()
  scripts.forEach((src) => {
    script = createScriptTag(src)
    scriptFragment.appendChild(script)
  })
  return scriptFragment
}
