import Joi from 'joi'
import {
  customValidateEqualsFields,
  validateAndGenerateErrors
} from '../../../helpers/validation'

const schemaToValidate = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
    .custom(customValidateEqualsFields('email', 'old'))
    .messages({
      'custom.equalsfields': 'O email deve ser diferente do anterior'
    })
    .required(),
  name: Joi.string()
    .custom(customValidateEqualsFields('name', 'old'))
    .messages({
      'custom.equalsfields': 'O nome deve ser diferente do anterior'
    })
    .required(),
  last_name: Joi.string()
    .custom(customValidateEqualsFields('last_name', 'old'))
    .messages({
      'custom.equalsfields': 'O sobrenome deve ser diferente do anterior'
    })
    .required(),
  cpf: Joi.string()
    .pattern(/[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/)
    .custom(customValidateEqualsFields('cpf', 'old'))
    .messages({
      'custom.equalsfields': 'O CPF deve ser diferente do anterior'
    })
    .required(),
  justify: Joi.string().required()
})

const schemaAddress = Joi.object({
  cep: Joi.string()
    .pattern(/[0-9]{5}-[0-9]{3}$/)
    .custom(customValidateEqualsFields('cep', 'cepold'))
    .messages({
      'custom.equalsfields': 'O campo deve ser diferente do anterior'
    })
    .required(),
  street: Joi.string()
    .custom(customValidateEqualsFields('street', 'streetold'))
    .messages({
      'custom.equalsfields': 'O campo deve ser diferente do anterior'
    })
    .required(),
  neighborhood: Joi.string()
    .custom(customValidateEqualsFields('neighborhood', 'neighborhoodold'))
    .messages({
      'custom.equalsfields': 'O campo deve ser diferente do anterior'
    })
    .required(),
  city: Joi.string()
    .custom(customValidateEqualsFields('city', 'cityold'))
    .messages({
      'custom.equalsfields': 'O campo deve ser diferente do anterior'
    })
    .required(),
  state: Joi.string()
    .custom(customValidateEqualsFields('state', 'stateold'))
    .messages({
      'custom.equalsfields': 'O campo deve ser diferente do anterior'
    })
    .required(),
  justify: Joi.string().required()
})

export const userEditValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schemaToValidate)
}

export const userEditAddressValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schemaAddress)
}
