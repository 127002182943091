import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import {
  Alert,
  Button,
  Button as ButtonSaraiva,
  Dialog
} from '@equipe-ninja/saraiva-ui'
import { Delete } from '@material-ui/icons'
import { FormBannerHome } from './FormBannerHome'
import { BANNER_CONECTA_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import useStyles from './styles'
import BackButton from '../../../../components/BackButton'
import ContentConnection from '../../../../services/contentConnection'

const { TITLE, URL, IMG_WEB, IMG_MOBILE, IMG_TABLET } = FIELDS

export function EditBannerHome() {
  const classes = useStyles()
  const history = useHistory()

  const [openDialog, setOpenDialog] = useState(false)
  const [openBackDialog, setOpenBackDialog] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bannerId, setBannerId] = useState('')

  const [formState, setFormState] = useState({
    [TITLE.value]: '',
    [URL.value]: '',
    [IMG_WEB.value]: '',
    [IMG_TABLET.value]: '',
    [IMG_MOBILE.value]: ''
  })

  // eslint-disable-next-line no-unused-vars
  const [formErrors, setFormErrors] = useState({
    [TITLE.value]: '',
    [URL.value]: '',
    [IMG_WEB.value]: '',
    [IMG_TABLET.value]: '',
    [IMG_MOBILE.value]: ''
  })

  const isImagesValid = () => {
    const {
      [IMG_WEB.value]: imgWeb,
      [IMG_TABLET.value]: imgTablet,
      [IMG_MOBILE.value]: imgMobile
    } = formState

    const title = formState[TITLE.value]
    const url = formState[URL.value]

    return (
      title.trim() !== '' &&
      url.trim() !== '' &&
      (typeof imgWeb === 'object' || imgWeb instanceof File) &&
      (typeof imgTablet === 'object' || imgTablet instanceof File) &&
      (typeof imgMobile === 'object' || imgMobile instanceof File)
    )
  }

  const handleChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [field]: value
    }))
  }

  const getBanner = async () => {
    setLoading(true)
    try {
      const response = await ContentConnection.getBanner()

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      const updatedFormState = {
        [TITLE.value]: result[0].campaign_name || '',
        [URL.value]: result[0].redirection_url || '',
        [IMG_WEB.value]: result[0].web_image || '',
        [IMG_TABLET.value]: result[0].tablet_image || '',
        [IMG_MOBILE.value]: result[0].mobile_image || ''
      }

      setFormState((prevFormState) => ({
        ...prevFormState,
        ...updatedFormState
      }))

      setBannerId(result[0].id)

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const updateBanner = async () => {
    setLoading(true)

    try {
      const response = await ContentConnection.updateBanner(
        bannerId,
        formState[TITLE.value],
        formState[URL.value],
        formState[IMG_WEB.value],
        formState[IMG_TABLET.value],
        formState[IMG_MOBILE.value]
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      await response.json()

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const deleteBanner = async () => {
    setLoading(true)

    try {
      const response = await ContentConnection.deleteBanner(bannerId)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      await response.json()

      setLoading(false)
      setOpenDialog(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleCloseAlert = () => {
    setTimeout(() => {
      setOpenUpdateAlert(false)
      setOpenDeleteAlert(false)
    }, 3000)
  }

  const handleUpdateBanner = async () => {
    updateBanner()
    setOpenUpdateAlert(true)
    handleCloseAlert()
    setTimeout(() => {
      history.push('/curso?brand=conecta&tab=página%20inicial')
    }, 3000)
  }

  const handleDeleteBanner = async () => {
    deleteBanner()
    setOpenDeleteAlert(true)
    handleCloseAlert()
    setTimeout(() => {
      history.push('/curso?brand=conecta&tab=página%20inicial')
    }, 5000)
  }

  const handleBackButton = () => {
    const hasObjectOrFileValues = Object.values(formState).some(
      (value) =>
        typeof value === 'object' && value !== null && !Array.isArray(value)
    )

    if (hasObjectOrFileValues) {
      setOpenBackDialog(true)
    } else {
      history.push('/curso?brand=conecta&tab=página%20inicial')
    }
  }

  useEffect(() => {
    if (loading === true) return
    getBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ paddingLeft: 50 }}>
      <BackButton onClick={() => handleBackButton()} />
      <Typography className={classes.bannerPageTitle} variant="h4">
        EDITAR CAMPANHA
      </Typography>
      <FormBannerHome
        formState={formState}
        onChange={handleChange}
        formErrors={formErrors}
      />

      <div className={classes.footerUpdate} style={{ marginBottom: 178 }}>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<Delete />}
          className={classes.footerUpdateDelete}
          onClick={() => setOpenDialog(true)}
        >
          EXCLUIR
        </Button>

        <ButtonSaraiva
          color="primary"
          variant="contained"
          disabled={!isImagesValid()}
          className={classes.saveButton}
          onClick={() => handleUpdateBanner()}
        >
          SALVAR
        </ButtonSaraiva>
      </div>

      <Dialog
        title="Excluir a campanha"
        primaryButton="Confirmar"
        secondaryButton="Cancelar"
        open={openDialog}
        handleConfirm={() => handleDeleteBanner()}
        handleClose={() => setOpenDialog(false)}
      >
        Tem certeza que deseja excluir a campanha? Suas informações serão
        deletadas de forma permanente!
      </Dialog>

      <Dialog
        title="Sair da página"
        primaryButton="Sair"
        secondaryButton="Cancelar"
        size="md"
        open={openBackDialog}
        handleConfirm={() =>
          history.push('/curso?brand=conecta&tab=página%20inicial')
        }
        handleClose={() => setOpenBackDialog(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ color: 'black' }}>
            Tem certeza que deseja sair da página?
          </Typography>
          <Typography>
            Ao sair dessa página sem salvar você perderá as informações já
            cadastradas
          </Typography>
        </div>
      </Dialog>
      <div
        className={classes.alerts}
        style={{ display: !openUpdateAlert ? 'none' : 'initial' }}
      >
        <Alert severity="success" open={openUpdateAlert}>
          Campanha atualizada com sucesso!
        </Alert>
      </div>

      <div
        className={classes.alerts}
        style={{ display: !openDeleteAlert ? 'none' : 'initial' }}
      >
        <Alert severity="success" open={openDeleteAlert}>
          Campanha excluída com sucesso!
        </Alert>
      </div>
    </div>
  )
}
