export const QUESTION_FIELDS = Object.freeze({
  KEY_WORDS: 'tags',
  TABLE_TEST: 'institution',
  TEST_YEAR: 'year',
  ENUNCIATION: 'content',
  ALTERNATIVES: 'alternatives',
  CORRECT_ALTERNATIVE: 'correct_exercise_alternative_id',
  ORIGIN_SUBJECT: 'origin_subject',
  ID: 'id'
})

export const QUESTION_ALTERNATIVES_FIELDS = Object.freeze({
  ORDER: 'order',
  CONTENT: 'content',
  ID: 'id'
})

export const MAX_QUESTION_ALTERNATIVES_COUNT = 5
export const MIN_QUESTION_ALTERNATIVES_COUNT = 2
