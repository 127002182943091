import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex',
    '& > p': {
      margin: 0
    }
  },
  bold: {
    fontWeight: 700
  }
}))

export default useStyles
