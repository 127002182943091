import { selector } from 'recoil'
import { courseOverviewState } from '../atom/course-overview-state'
import {
  COURSE_OVERVIEW_RAW_DATA as F,
  COURSE_FIELDS as C_F,
  COURSE_ECOMMERCE_FIELDS as E_F,
  COURSE_OVERVIEW,
  ECOMMERCE_FORM_FIELDS as ECO_FORM
} from '../../../domain/courses/entities'
import {
  isCourseEcommerceReady,
  isCourseAvaReady
} from '../../../domain/courses/business'
import { createDefaultTabError } from '../../../domain/product-course/business'

const hasChangedCourse = (rawData, newData) => {
  if (!(F.COURSE in rawData) && F.COURSE in newData) return true

  if (
    rawData?.[F.COURSE]?.[C_F.UPDATED_AT] !==
    newData?.[F.COURSE]?.[C_F.UPDATED_AT]
  )
    return true

  return false
}

const courseSubjectCompletion = (rawData) => {
  const isReady = isCourseAvaReady(rawData)
  const count = rawData?.[F.FULL_POSTED_SUBJECT_COUNT]

  return {
    subjects: isReady,
    fullpostedCount: count
  }
}

const nextFormValue = (newData) => {
  return {
    [COURSE_OVERVIEW.TITLE]: newData?.[F.COURSE]?.[C_F.TITLE],
    [COURSE_OVERVIEW.START_DATE]:
      newData?.[F.SERVICE]?.[E_F.SELLING_START_DATE],
    [COURSE_OVERVIEW.END_DATE]: newData?.[F.SERVICE]?.[E_F.SELLING_END_DATE],
    [COURSE_OVERVIEW.STATUS]: newData?.[F.SERVICE]?.[E_F.STATUS]
  }
}

const nextEcommerceValue = (newData) => ({
  [ECO_FORM.ACCESS_WINDOW]: newData?.[F.SERVICE]?.[E_F.ACCESS_WINDOW_IN_MONTHS],
  [ECO_FORM.CATEGORY]: newData?.[F.SERVICE]?.[E_F.CATEGORY_ID],
  [ECO_FORM.COURSE_LENGTH]: newData?.[F.SERVICE]?.[E_F.COURSE_LENGTH],
  [ECO_FORM.DESCRIPTION]: newData?.[F.SERVICE]?.[E_F.DESCRIPTION],
  [ECO_FORM.ID]: newData?.[F.SERVICE]?.[E_F.ID],
  [ECO_FORM.IMG_BINARY]: newData?.[F.SERVICE]?.[E_F.IMAGE_BINARY],
  [ECO_FORM.IMG_URL]: newData?.[F.SERVICE]?.[E_F.IMAGE_PATH],
  [ECO_FORM.KEY_WORDS]: newData?.[F.SERVICE]?.[E_F.KEYWORDS],
  [ECO_FORM.NAME]: newData?.[F.SERVICE]?.[E_F.NAME],
  [ECO_FORM.SKU]: newData?.[F.SERVICE]?.[E_F.SKU],
  [ECO_FORM.SUB_CATEGORY]: newData?.[F.SERVICE]?.[E_F.SUBCATEGORY_ID],
  [ECO_FORM.URL]: newData?.[F.SERVICE]?.[E_F.SLUG],
  [ECO_FORM.VALUE]: newData?.[F.SERVICE]?.[E_F.PRICE]
})

const nextTabs = (newData) => {
  return newData[F.SERVICE][E_F.TABS]
}

export const courseOverviewRawData = selector({
  key: 'courseOverviewRawData',
  get: ({ get }) => {
    return get(courseOverviewState).rawData
  },
  set: ({ get, set }, data) => {
    const hasChanged = hasChangedCourse(get(courseOverviewState).rawData, data)

    const newForm = hasChanged
      ? nextFormValue(data)
      : get(courseOverviewState).form

    const newEcommerceForm = hasChanged
      ? nextEcommerceValue(data)
      : get(courseOverviewState).ecommerceForm

    const newTabs = hasChanged
      ? nextTabs(data)
      : get(courseOverviewState).ecommerceForm.values[ECO_FORM.COURSE_TABS]

    const newTabsErrors = new Array(newTabs.length).fill(
      createDefaultTabError()
    )

    const ecommerceCompletionStatus = isCourseEcommerceReady(data)
    const subjectCompletionData = courseSubjectCompletion(data)

    set(courseOverviewState, {
      ...get(courseOverviewState),
      rawData: data,
      form: {
        ...get(courseOverviewState).form,
        values: { ...get(courseOverviewState).form.values, ...newForm }
      },
      ecommerceForm: {
        ...get(courseOverviewState).ecommerceForm,
        values: {
          ...get(courseOverviewState).ecommerceForm.values,
          ...newEcommerceForm,
          [ECO_FORM.COURSE_TABS]: newTabs
        },
        errors: {
          ...get(courseOverviewState).ecommerceForm.errors,
          [ECO_FORM.COURSE_TABS]: newTabsErrors
        }
      },
      completionStatus: {
        ...get(courseOverviewState).completionStatus,
        ecommerce: ecommerceCompletionStatus,
        ...subjectCompletionData
      }
    })
  }
})
