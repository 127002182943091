import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 16,
    marginBottom: 40
  },

  icon: {
    fontSize: '0.85rem !important',
    fontWeight: 500
  }
}))

export default useStyles
