import { SCHEDULED_CONTENT_REMOTE_TYPES as REMOTE } from '../remote-fields/scheduled-content'
import { SCHEDULED_CONTENT_TYPES as LOCAL } from '../../../domain/courses'

const inbound = (type) => {
  const [key] = Object.entries(REMOTE).find(
    (keyValueArr) => keyValueArr[1] === type
  )

  if (!key) {
    throw new Error(
      `${type} is not a valid remote value for scheduled content type`
    )
  }

  return LOCAL[key]
}

const outbound = (type) => {
  const [key] = Object.entries(LOCAL).find(
    (keyValueArr) => keyValueArr[1] === type
  )

  if (!key) {
    throw new Error(
      `${type} is not a valid local value for scheduled content type`
    )
  }

  return REMOTE[key]
}

export const ScheduledContentTypeAdapter = {
  inbound,
  outbound
}
