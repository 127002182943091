import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  tab: {
    padding: '12px 32px 12px 32px',
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textDecoration: 'none'
  },

  tabActive: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  }
}))

export default useStyles
