import { USER_ORDER_ITEM_COMPLETION_STATUS } from '../../../domain/user-management-content/user-orders'

const REMOTE_STATUSES = {
  ON_GOING: 1,
  COMPLETED: 2,
  CANCELLED: 3
}

const inbound = (remoteValue) => {
  switch (remoteValue) {
    case REMOTE_STATUSES.ON_GOING:
      return USER_ORDER_ITEM_COMPLETION_STATUS.ON_GOING.value
    case REMOTE_STATUSES.COMPLETED:
      return USER_ORDER_ITEM_COMPLETION_STATUS.COMPLETED.value
    case REMOTE_STATUSES.CANCELLED:
      return USER_ORDER_ITEM_COMPLETION_STATUS.CANCELLED.value
    default:
      return ''
  }
}
const outbound = (localValue) => {
  switch (localValue) {
    case USER_ORDER_ITEM_COMPLETION_STATUS.ON_GOING.value:
      return REMOTE_STATUSES.ON_GOING
    case USER_ORDER_ITEM_COMPLETION_STATUS.COMPLETED.value:
      return REMOTE_STATUSES.COMPLETED
    case USER_ORDER_ITEM_COMPLETION_STATUS.CANCELLED.value:
      return REMOTE_STATUSES.CANCELLED
    default:
      return ''
  }
}

export const OrderItemCompletionStatusAdapter = {
  inbound,
  outbound
}
