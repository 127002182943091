import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoClass: {
    color: theme.palette.primary.main
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  SpaceBetween: {
    justifyContent: 'space-between'
  },
  TextBody: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '0px',
    marginTop: '8px',
    marginRight: '16px'
  },
  DivSave: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    right: '0px',
    marginRight: '100px',
    top: '0px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  CardContextRegister: {
    border: '1px solid rgb(235,235,235)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '49%',
    height: '306px',
    marginTop: '0px'
  },
  BackgroundSuccess: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #1F8563'
  },
  CardContext: {
    border: '1px solid rgb(235,235,235)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    marginTop: '24px',
    paddingBottom: '24px',
    '&:first-child': {
      marginTop: '40px'
    }
  },
  CardMain: {
    padding: '24px 16px 0px'
  },
  CardTitle: {
    fontWeight: 'normal',
    marginTop: '0px',
    marginBottom: '12px',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  CardDetails: {
    color: 'rgba(0,0,0,.54)',
    fontSize: '16px',
    marginBottom: '13px',
    lineHeight: '150%'
  },
  CardParagraph: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '8px',
    marginBottom: '24px'
  },
  CardFooter: {
    borderTop: '1px solid rgb(235,235,235)',
    padding: '4px 16px 4px 4px',
    color: '#245A93',
    display: 'flex',
    height: '46px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span:hover': {
      cursor: 'pointer'
    }
  },
  TextButton: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px'
  },
  helpText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textH5: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '133.4%',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '0px'
  },
  textH6: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '160%',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '0px'
  },
  marginTop: {
    marginTop: '24px'
  },
  marginBottom: {
    marginBottom: '24px'
  },
  RowAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: '40px'
  },
  TextMessage: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)'
  }
}))

export default useStyles
