import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@equipe-ninja/saraiva-ui'
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded'

import useStyles from './styles'
import SelectSubjectsDialog from '../SelectSubjectsDialog'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'

function SelectSubjectsButton({ hidden }) {
  const classes = useStyles()
  const { showingSubjectsDialog, toggleSubjectsDialog } = useContext(
    ConnectionContext
  )

  const renderSubjectsDialog = () => {
    if (!showingSubjectsDialog) {
      return null
    }

    return <SelectSubjectsDialog />
  }

  if (hidden) {
    return null
  }

  return (
    <>
      {renderSubjectsDialog()}

      <Button
        color="primary"
        onClick={() => toggleSubjectsDialog(true)}
        text="Botão"
        variant="outlined"
        className={classes.button}
        startIcon={<SchoolRoundedIcon />}
      >
        DISCIPLINAS
      </Button>
    </>
  )
}

SelectSubjectsButton.defaultProps = {
  hidden: false
}

SelectSubjectsButton.propTypes = {
  hidden: PropTypes.bool
}

export default SelectSubjectsButton
