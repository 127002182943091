import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 72,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },

  paper: {
    marginTop: 24
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24,
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`
  },

  contentBG: {
    backgroundColor: theme.palette.action.hover
  },

  button: {
    marginRight: 12,
    '&:last-child': {
      marginRight: 0
    }
  },
  divFlex: {
    display: 'flex',
    gap: 24,
    marginTop: 30,
    width: '75.367%'
  },
  divTypography: {
    position: 'absolute',
    marginLeft: 5
  },

  paperSearch: {
    width: 277,
    position: 'absolute',
    borderRadius: 4,
    padding: '10px 0px 10px 12px',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main
    },
    zIndex: 1000,
    marginTop: 8
  },

  searchWrapper: {
    marginBottom: 8
  },

  datePickers: {
    display: 'flex',
    gap: 24,
    marginTop: 30,
    width: '75.367%'
  },
  discription: {
    position: 'absolute',
    marginLeft: 5
  },

  snackDiv: {
    margin: '24px 16px 0 28px'
  }
}))

export default useStyles
