import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    marginBottom: 24,
    marginRight: '8vw',
    textAlign: 'right'
  },
  icon: {
    marginRight: 24,
    verticalAlign: 'bottom'
  }
})

export default useStyles
