export const USER_ORDER_ITEM_COMPLETION_STATUS = {
  CANCELLED: {
    value: 'cancelled',
    label: 'Curso cancelado'
  },
  COMPLETED: {
    value: 'completed',
    label: 'Curso finalizado'
  },
  ON_GOING: {
    value: 'ongoing',
    label: 'Curso em andamento'
  }
}
