import React, { useContext } from 'react'

import { Button, UploadBar } from '@equipe-ninja/saraiva-ui'
import { Box } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import { ContentsContext } from '../../../contexts/ContentsContext'
import { UploadContext } from '../../../contexts/UploadContext'

import useStyles from './styles'
import MenuNew from './MenuNew'

function ButtonNew() {
  const { toggleShowingContentMenu } = useContext(ContentsContext)

  const {
    showingUploadBar,
    countFileUpload,
    totalFiles,
    errorsBar,
    reloadFiles,
    filesError,
    clearState
  } = useContext(UploadContext)

  const classes = useStyles()

  const handleCloseUploadBar = () => {
    clearState()
  }
  const handleCancelUploadBar = () => {
    clearState()
  }

  const handleReloadFiles = () => {
    reloadFiles(filesError)
  }
  return (
    <>
      <Box className={classes.root}>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => toggleShowingContentMenu(e.currentTarget)}
          startIcon={<AddIcon />}
        >
          Novo
        </Button>

        <MenuNew />
      </Box>
      {showingUploadBar ? (
        <UploadBar
          progressFileUpload={countFileUpload}
          totalUploadFiles={totalFiles}
          errorsList={errorsBar}
          handleCancelUpload={handleCancelUploadBar}
          handleCloseUpload={handleCloseUploadBar}
          reloadErrorsUpload={handleReloadFiles}
        />
      ) : (
        <div />
      )}
    </>
  )
}

export default ButtonNew
