import React from 'react'
import { CircularProgress } from '@material-ui/core'

import useStyles from './styles'

export default function Loading() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
      <span>Carregando...</span>
    </div>
  )
}
