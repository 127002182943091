import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import useStyles from './styles'

import {
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import { Button, Dialog, SearchBar } from '@equipe-ninja/saraiva-ui'
import { ALL_QUESTIONS_DATABASE_FIELDS } from '../../../../../domain/courses/register-elearning-page/all-database-questions'
import { getAllDatabaseQuestions } from '../../../../../services/course-management-content/use-cases/get-all-database-questions'
import { QuestionCardPreview } from './QuestionCard/questionCardPreview'
import { debounce } from 'lodash'
import FixationExercise from '../../../../../services/fixationExerciseService'
import { allQuestionsDatabaseSelector } from '../../../../../atoms/subject'

const { SUBJECT_TITLE, COURSE_TITLE, EXERCISES_COUNT } =
  ALL_QUESTIONS_DATABASE_FIELDS

const headersCellsSubjectsInDatabase = [
  { name: 'Disciplina', code: SUBJECT_TITLE },
  { name: 'Curso de cadastro', code: COURSE_TITLE },
  { name: 'Quantidade de Exercicios', code: EXERCISES_COUNT }
]

const headersCellsQuestionsInSubjects = [
  { name: 'Questões', code: 'id' },
  { name: 'Banca/Prova', code: 'institution' },
  { name: 'Ano', code: 'year' },
  { name: '', code: 'origin_subject' }
]
const DEFAULT_BREADCRUMBS = [{ name: 'todas', value: null }]

const TABLE_SWITCH = { SUBJECTS: 0, QUESTIONS: 1, NOT_QUESTIONS: 2 }

const ReusableQuestionTable = () => {
  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageQuestions, setPageQuestions] = useState(0)
  const [rowsQuestionsPerPage, setRowsQuestionsPerPage] = useState(10)

  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingTable, setFetchingTable] = useState(true)

  const [questionsDatabase, setQuestionsDatabase] = useState([])
  const [exercise, setExercise] = useState()
  const [searchQuestionSubject, setSearchQuestionSubject] = useState('')

  const [disciplineId, setDisciplineId] = useState(0)
  const [switchTable, setSwitchTable] = useState(0)

  const [open, setOpen] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState(DEFAULT_BREADCRUMBS)

  const [searchQuestionSubjectValue, setSearchQuestionSubjectValue] =
    useState('')

  const [allSubjectQuestions, setAllSubjectQuestions] = useRecoilState(
    allQuestionsDatabaseSelector
  )

  const { checkedQuestions } = allSubjectQuestions

  const isSearch = allSubjectQuestions.total === 0

  const handleBreadCrumbs = (value) => {
    if (value === null) {
      setBreadCrumbs(DEFAULT_BREADCRUMBS)
      setSwitchTable(TABLE_SWITCH.SUBJECTS)
      setAllSubjectQuestions((prev) => ({ ...prev, checkedQuestions: [] }))
    }
  }

  const delayedQueryInQuestions = useRef(
    debounce((e) => {
      setSearchQuestionSubjectValue(e)
      setPage(0)
      setSwitchTable(TABLE_SWITCH.SUBJECTS)
    }, 800)
  ).current

  const handleSearchInQuestionsValue = (e) => {
    setSearchQuestionSubject(e.target.value)
    delayedQueryInQuestions(e.target.value)
  }

  const clearSearchInQuestions = () => {
    setSearchQuestionSubject('')
    setSearchQuestionSubjectValue('')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeQuestionsPage = (event, newPage) => {
    setPageQuestions(newPage)
  }

  const handleQuestionsPerPage = (event) => {
    setRowsQuestionsPerPage(parseInt(event.target.value, 10))
    setPageQuestions(0)
  }

  const handleSelect = (e, question) => {
    const { id, checked } = e.target

    if (!checked) {
      setAllSubjectQuestions((prev) => ({
        ...prev,
        checkedQuestions: prev.checkedQuestions.filter(
          (item) => item !== Number(id)
        )
      }))
      return
    }

    setAllSubjectQuestions((prev) => ({
      ...prev,
      checkedQuestions: [...prev.checkedQuestions, Number(id)]
    }))
  }

  const handleSelectAll = (event) => {
    const { checked } = event.target
    const allIds = questionsDatabase.map((item) => item.id)

    if (!checked) {
      setAllSubjectQuestions((prev) => ({
        ...prev,
        checkedQuestions: prev.checkedQuestions.filter(
          (item) => !allIds.includes(item)
        )
      }))
      return
    }

    setAllSubjectQuestions((prev) => ({
      ...prev,
      checkedQuestions: questionsDatabase.reduce((acc, cur) => {
        if (acc.includes(cur.id)) return acc
        return [...acc, cur.id]
      }, prev.checkedQuestions)
    }))
  }

  const insertToBreadcrumbs = (course, subject, id) => {
    setBreadCrumbs((prev) => [
      ...prev,
      { name: `${subject} (${course})`, value: id }
    ])
  }

  async function fetchQuestions(disciplineId, courseTitle, subjectTitle) {
    setIsFetching(true)
    setSwitchTable(TABLE_SWITCH.QUESTIONS)
    const isZero =
      pageQuestions === 0 ? 1 : pageQuestions === 1 ? 2 : pageQuestions + 1

    try {
      const response = await FixationExercise.getFixationExercise(
        disciplineId,
        isZero,
        rowsQuestionsPerPage
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      let newArr = result.fixation_exercise.map((item, index) => {
        let obj = {
          ...item,
          number: pageQuestions * rowsQuestionsPerPage + index + 1,
          course: courseTitle,
          total: result.count
        }

        return obj
      })
      if (breadCrumbs[1]?.value !== disciplineId) {
        insertToBreadcrumbs(subjectTitle, courseTitle, disciplineId)
      }
      setQuestionsDatabase(newArr)
      setDisciplineId(disciplineId)
    } catch (error) {
      console.error(error)
    }
    setIsFetching(false)
  }

  async function fetchExercice(exercise) {
    setFetchingTable(true)
    setOpen(true)
    setExercise(exercise)
    setFetchingTable(false)
  }

  const fetchSubjects = useCallback((search, pages, perPages) => {
    setAllSubjectQuestions((prev) => ({ ...prev, isFetchingQuestions: true }))
    getAllDatabaseQuestions(search, pages, perPages)
      .then((questionsDatabase) => {
        setAllSubjectQuestions((prev) => ({
          ...prev,
          questionsDatabase: questionsDatabase.data,
          total: questionsDatabase.count
        }))
      })
      .catch((err) =>
        console.error('Não foi possível buscar no Banco de Questões', err)
      )
      .finally(() =>
        setAllSubjectQuestions((prev) => ({
          ...prev,
          isFetchingQuestions: false
        }))
      )
  }, [])

  useEffect(() => {
    if (disciplineId !== 0) {
      fetchQuestions(disciplineId, pageQuestions, rowsQuestionsPerPage)
    }
  }, [pageQuestions, rowsQuestionsPerPage])

  useEffect(() => {
    fetchSubjects(searchQuestionSubjectValue, page, rowsPerPage)
  }, [
    fetchSubjects,
    searchQuestionSubjectValue,
    page,
    rowsPerPage,
    allSubjectQuestions.total
  ])

  useEffect(() => {
    return () =>
      setAllSubjectQuestions((prev) => ({ ...prev, checkedQuestions: [] }))
  }, [])

  const fetchSubjectTable = () => {
    return allSubjectQuestions.isFetchingQuestions ? (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    ) : isSearch === false ? (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headersCellsSubjectsInDatabase.map((headCell) => (
                  <TableCell>{headCell.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allSubjectQuestions.questionsDatabase.map((row) => (
                <>
                  <TableRow
                    className={classes.tableRow}
                    onClick={() =>
                      row.exercises_count === 0
                        ? setSwitchTable(TABLE_SWITCH.NOT_QUESTIONS)
                        : fetchQuestions(
                            row.subject_id,
                            row.course_title,
                            row.subject_title
                          )
                    }
                  >
                    <TableCell className={classes.fisrtCell}>
                      {row.subject_title}
                    </TableCell>
                    <TableCell>{row.course_title}</TableCell>
                    <TableCell>{row.exercises_count}</TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={allSubjectQuestions.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    ) : (
      <div className={classes.notQuestions}>
        <Typography fontWeight="fontWeightMedium">
          Nenhum resultado encontrado
        </Typography>
      </div>
    )
  }

  const fetchQuestionsTable = () => {
    return isFetching === true ? (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    ) : (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onClick={handleSelectAll}
                  checked={questionsDatabase.every((item) =>
                    checkedQuestions.includes(item.id)
                  )}
                  className={classes.checkBox}
                />
              </TableCell>
              {headersCellsQuestionsInSubjects.map((headCell) => (
                <TableCell>{headCell.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {questionsDatabase.map((row) => (
              <>
                <TableRow
                  key={row.id}
                  className={
                    (checkedQuestions.includes(row.id) &&
                      classes.selectedRow) ||
                    classes.tableRow
                  }
                >
                  <TableCell>
                    <Checkbox
                      className={classes.checkBox}
                      key={row.id}
                      id={row.id}
                      onClick={(e) => handleSelect(e, row)}
                      checked={checkedQuestions.includes(row.id)}
                    />
                  </TableCell>
                  <TableCell>{`Questão ${row.number}`}</TableCell>
                  <TableCell>{row.institution}</TableCell>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>
                    <Button
                      startIcon={<Visibility />}
                      onClick={() => fetchExercice(row)}
                      color="primary"
                    >
                      visualizar
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={questionsDatabase[0].total}
          rowsPerPage={rowsQuestionsPerPage}
          page={pageQuestions}
          onPageChange={handleChangeQuestionsPage}
          onRowsPerPageChange={handleQuestionsPerPage}
        />
        <Dialog
          title="Banco de Questões"
          size={'lg'}
          open={open}
          primaryButton="fechar visualização"
          handleConfirm={() => setOpen(false)}
        >
          {isFetchingTable === true ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.sectionContainer}>
              <QuestionCardPreview exercise={exercise} />
            </div>
          )}
        </Dialog>
      </Paper>
    )
  }

  const fetchNoExercises = () => {
    return (
      <div className={classes.notQuestions}>
        <Typography>Nenhum exercício cadastrado</Typography>
      </div>
    )
  }

  const tableSwitch = () => {
    switch (switchTable) {
      case TABLE_SWITCH.SUBJECTS:
        return fetchSubjectTable()
      case TABLE_SWITCH.QUESTIONS:
        return fetchQuestionsTable()
      case TABLE_SWITCH.NOT_QUESTIONS:
        return fetchNoExercises()
      default:
        return fetchSubjectTable()
    }
  }

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.searchContainer}>
        <SearchBar
          size="full"
          placeholder="Busque por disciplina ou curso"
          value={searchQuestionSubject}
          onChange={handleSearchInQuestionsValue}
          clearSearch={clearSearchInQuestions}
          goBackSearch={clearSearchInQuestions}
          onSubmit={(e) => {
            e.preventDefault()
          }}
        />
      </div>
      <Paper>
        <div className={classes.headContainer}>
          <Button
            className={
              isSearch === true || switchTable !== TABLE_SWITCH.NOT_QUESTIONS
                ? classes.exerciceNoCount
                : classes.breadcrumbs
            }
            color="primary"
            onClick={() => setSwitchTable(TABLE_SWITCH.SUBJECTS)}
          >
            voltar
          </Button>
          <Breadcrumbs aria-label="breadcrumb">
            {breadCrumbs.map((item) => (
              <Button
                className={
                  isSearch === true ||
                  switchTable === TABLE_SWITCH.NOT_QUESTIONS
                    ? classes.exerciceNoCount
                    : classes.breadcrumbs
                }
                color="primary"
                onClick={() => handleBreadCrumbs(item.value)}
              >
                {item.name}
              </Button>
            ))}
          </Breadcrumbs>
          <Typography
            className={
              checkedQuestions.length === 0
                ? classes.exerciceNoCount
                : classes.exerciceCount
            }
          >
            {checkedQuestions.length === 1
              ? `${checkedQuestions.length} QUESTÃO SELECIONADA`
              : `${checkedQuestions.length} QUESTÕES SELECIONADAS`}
          </Typography>
        </div>
        {tableSwitch()}
      </Paper>
    </div>
  )
}

export default ReusableQuestionTable
