import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from '@material-ui/core'
import { Dialog } from '@equipe-ninja/saraiva-ui'

import DeleteIcon from '../../../../components/DeleteIcon'
import IntegrationsService from '../../../../services/integrationsService'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import useStyles from './styles'
import Messages from '../../../../helpers/messages'

function DeploymentRow({ deploymentId }) {
  const classes = useStyles()
  const [showingDialogWarning, toggleDialogWarning] = useState(false)
  const { connection, updateConnection, deleting, setDeleting } =
    useContext(ConnectionContext)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const deleteDeploymentId = async () => {
    const payload = { deployment_id: deploymentId }

    try {
      setDeleting(true)

      await IntegrationsService.deleteDeploymentId(connection.id, payload)

      const newDeploymentIds = connection.integration_data.deployment.filter(
        (id) => id !== deploymentId
      )

      setSuccessMessage(Messages.deployment.deleted)
      const newConnection = {
        ...connection,
        integration_data: {
          ...connection.integration_data,
          deployment: newDeploymentIds
        }
      }
      updateConnection(newConnection)
    } catch (err) {
      setErrorMessage(Messages.deployment.errorOnDelete)
      // eslint-disable-next-line no-console
      console.error(err)
    } finally {
      setDeleting(false)
      toggleDialogWarning(false)
    }
  }

  return (
    <>
      <Dialog
        open={showingDialogWarning}
        handleClose={() => toggleDialogWarning(false)}
        handleConfirm={deleteDeploymentId}
        title="Tem certeza que deseja excluir esse ID de desenvolvimento?"
        primaryButton="excluir"
        isDisabled={deleting}
      >
        {`ID: ${deploymentId}`}
      </Dialog>

      <ListItem>
        <ListItemText
          primary="ID de Desenvolvimento:"
          className={classes.label}
        />
        <ListItemText primary={deploymentId} className={classes.value} />
        <ListItemSecondaryAction>
          <DeleteIcon onClick={() => toggleDialogWarning(true)} edge="end" />
        </ListItemSecondaryAction>
      </ListItem>

      <Divider />
    </>
  )
}

DeploymentRow.propTypes = {
  deploymentId: PropTypes.string.isRequired
}

export default DeploymentRow
