import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import {
  Alert,
  Button as ButtonSaraiva,
  Dialog
} from '@equipe-ninja/saraiva-ui'
import { FormBannerHome } from './components/FormBannerHome'
import { BANNER_CONECTA_LIST_ITEM_FIELDS as FIELDS } from '../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import useStyles from './styles'
import BackButton from '../../../components/BackButton'
import ContentConnection from '../../../services/contentConnection'

const { TITLE, URL, IMG_WEB, IMG_MOBILE, IMG_TABLET } = FIELDS

export function BannerHomeConecta() {
  const classes = useStyles()
  const history = useHistory()

  const [, setLoading] = useState(false)
  const [openBackDialog, setOpenBackDialog] = useState(false)
  const [openCreateAlert, setOpenCreateAlert] = useState(false)

  const [formState, setFormState] = useState({
    [TITLE.value]: '',
    [URL.value]: '',
    [IMG_WEB.value]: '',
    [IMG_TABLET.value]: '',
    [IMG_MOBILE.value]: ''
  })

  // eslint-disable-next-line no-unused-vars
  const [formErrors, setFormErrors] = useState({
    [TITLE.value]: '',
    [URL.value]: '',
    [IMG_WEB.value]: '',
    [IMG_TABLET.value]: '',
    [IMG_MOBILE.value]: ''
  })

  const isImagesValid = () => {
    const {
      [IMG_WEB.value]: imgWeb,
      [IMG_TABLET.value]: imgTablet,
      [IMG_MOBILE.value]: imgMobile
    } = formState

    const title = formState[TITLE.value]
    const url = formState[URL.value]

    return (
      title.trim() !== '' &&
      url.trim() !== '' &&
      imgWeb &&
      imgTablet &&
      imgMobile
    )
  }

  const handleChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [field]: value
    }))
  }

  const saveBanner = async () => {
    setLoading(true)

    try {
      const response = await ContentConnection.createBanner(
        formState[TITLE.value],
        formState[URL.value],
        formState[IMG_WEB.value],
        formState[IMG_TABLET.value],
        formState[IMG_MOBILE.value]
      )
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      await response.json()

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleBackButton = () => {
    const hasObjectOrFileValues = Object.values(formState).some(
      (value) =>
        typeof value === 'object' && value !== null && !Array.isArray(value)
    )

    if (hasObjectOrFileValues) {
      setOpenBackDialog(true)
    } else {
      history.push('/curso?brand=conecta&tab=página%20inicial')
    }
  }

  const handleCloseAlert = () => {
    setTimeout(() => {
      setOpenCreateAlert(false)
    }, 3000)
  }
  const handleSaveBanner = async () => {
    saveBanner()
    setOpenCreateAlert(true)
    handleCloseAlert()
    setTimeout(() => {
      history.push('/curso?brand=conecta&tab=página%20inicial')
    }, 3000)
  }

  return (
    <div style={{ paddingLeft: 50, paddingBottom: 90 }}>
      <BackButton onClick={() => handleBackButton()} />
      <Typography className={classes.bannerPageTitle} variant="h4">
        CRIAR BANNER
      </Typography>
      <FormBannerHome
        formState={formState}
        onChange={handleChange}
        formErrors={formErrors}
      />

      <ButtonSaraiva
        color="primary"
        variant="contained"
        disabled={!isImagesValid()}
        className={classes.saveButton}
        onClick={() => handleSaveBanner()}
      >
        SALVAR
      </ButtonSaraiva>

      <Dialog
        title="Sair da página"
        primaryButton="Sair"
        secondaryButton="Cancelar"
        size="md"
        open={openBackDialog}
        handleConfirm={() =>
          history.push('/curso?brand=conecta&tab=página%20inicial')
        }
        handleClose={() => setOpenBackDialog(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ color: 'black' }}>
            Tem certeza que deseja sair da página?
          </Typography>
          <Typography>
            Ao sair dessa página sem salvar você perderá as informações já
            cadastradas
          </Typography>
        </div>
      </Dialog>
      <div
        className={classes.alerts}
        style={{ display: !openCreateAlert ? 'none' : 'initial' }}
      >
        <Alert severity="success" open={openCreateAlert}>
          Campanha criada com sucesso!
        </Alert>
      </div>
    </div>
  )
}
