import { atom } from 'recoil'

export const courseCreateState = atom({
  key: 'courseCreateState',
  default: {
    isFetching: false,
    courseData: {},
    messageEcommerce: '',
    messageElearning: ''
  }
})
