import {
  SERVICE_REMOTE_FIELDS as R,
  COURSE_SERVICE_TABS_REMOTE_FIELDS as R_T,
  COURSE_SERVICES_PLANS_FIELDS as R_P
} from '../remote-fields/courses-services-fields'

import {
  ECOMMERCE_TABS_FIELDS as L_T,
  ECOMMERCE_FORM_FIELDS as L
} from '../../../domain/courses/entities'
import {
  outboundDecimal,
  outboundInteger
} from '../../utils/outbound-data-transformation'

const PAYMENT_FREQUENCY = 'Monthly'

const outbound = (data) => ({
  [R.DESCRIPTION]: data[L.DESCRIPTION],
  [R.KEYWORDS]: data[L.KEY_WORDS],
  [R.OUTBOUND_TABS]: data[L.COURSE_TABS].map((tab) => ({
    [R_T.CONTENT]: tab[L_T.CONTENT],
    [R_T.ORDER]: tab[L_T.ORDER],
    [R_T.TITLE]: tab[L_T.TITLE]
  })),
  [R.OUTBOUND_PLAN]: {
    [R_P.AVAILABILITY_MONTHS]: outboundInteger(data[L.ACCESS_WINDOW]),
    [R_P.DESCRIPTION]: data[L.NAME],
    [R_P.DISCOUNT_AMOUNT]: 0,
    [R_P.DURATION_HOURS]: outboundInteger(data[L.COURSE_LENGTH]),
    [R_P.NAME]: data[L.NAME],
    [R_P.PAYMENT_FREQUENCY]: PAYMENT_FREQUENCY,
    [R_P.PRICE]: outboundDecimal(data[L.VALUE]),
    [R_P.ACTIVE]: true
  },
  [R.OUTBOUND_CATEGORIES]: [data[L.CATEGORY], data[L.SUB_CATEGORY]].filter(
    (e) => ![null, ''].includes(e)
  ),
  [R.OUTBOUNT_FILE]: data[L.IMG_BINARY] || null,
  [R.SLUG]: data[L.URL]
})

export const CourseServiceUpdateAdapter = {
  outbound
}
