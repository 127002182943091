import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonSelectDiscipline: {
    padding: '0',
    height: '5vh',
    width: '258px',
    margin: '24px 8px 8px 8px'
  },
  checkboxItems: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px'
  },
  checked: {
    color: theme.palette.primary.main,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  notChecked: {
    color: theme.palette.action.active,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },

  container: {
    borderRadius: 10,
    border: '#E0E0E0 1px solid',
    marginRight: 130,
    marginLeft: 133,
    marginBottom: 63,
    backgroundColor: '#FFFFFF'
  },

  tableHead: {
    borderTop: 'solid 1px #E0E0E0'
  },
  paper: {
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    height: 56,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10
  },
  btDelete: {
    width: 102,
    height: 32
  },

  pasteIcon: {
    margin: '0 10px 0 10px'
  },
  root: {
    width: 600,
    height: 305.18
  }
}))

export default useStyles
