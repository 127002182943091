import { selector } from 'recoil'
import { courseOverviewState } from '../atom/course-overview-state'

export const courseOverviewForm = selector({
  key: 'courseOverviewForm',
  get: ({ get }) => {
    return {
      values: get(courseOverviewState).form.values,
      errors: get(courseOverviewState).form.errors
    }
  },
  set: ({ get, set }, data) => {
    set(courseOverviewState, {
      ...get(courseOverviewState),
      form: { ...get(courseOverviewState).form, ...data }
    })
  }
})
