import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  Button,
  TextInput,
  SelectSaraiva,
  MenuItem
} from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'

import {
  questionAlternativesSelector,
  questionDialogFormValuesSelector,
  questionFormErrorsSelector
} from '../../../../../atoms/subject/selectors/fixation-exercise'
import {
  MAX_QUESTION_ALTERNATIVES_COUNT,
  MIN_QUESTION_ALTERNATIVES_COUNT,
  QUESTION_ALTERNATIVES_FIELDS,
  QUESTION_FIELDS
} from '../../../../../domain/fixation-exercise/question-fields'
import { getAlternativeLabelByOrder } from '../../../../../domain/fixation-exercise/get-question-alternative-label-by-order'

import { useStyles } from './styles'

export function QuestionManagementForm() {
  const styles = useStyles()

  const [questionFormValues, setQuestionFormValues] = useRecoilState(
    questionDialogFormValuesSelector
  )
  const [questionAlternatives, setQuestionAlternatives] = useRecoilState(
    questionAlternativesSelector
  )
  const questionFormErrors = useRecoilValue(questionFormErrorsSelector)

  const hasMaxAlternativeCount =
    MAX_QUESTION_ALTERNATIVES_COUNT === questionAlternatives.length

  const hasMinAlternativeCount =
    MIN_QUESTION_ALTERNATIVES_COUNT === questionAlternatives.length

  const changeFormValues = (formField, value) => {
    setQuestionFormValues((prev) => ({
      ...prev,
      [formField]: value
    }))
  }

  const changeQuestionAlternativeContent = (content, order) => {
    setQuestionAlternatives((prev) =>
      prev.map((alternative) => {
        if (alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER] === order) {
          return {
            ...alternative,
            [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: content
          }
        }

        return alternative
      })
    )
  }

  const addQuestionAlternative = () => {
    if (hasMaxAlternativeCount) return

    const newAlternative = {
      [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: '',
      [QUESTION_ALTERNATIVES_FIELDS.ORDER]: questionAlternatives.length + 1
    }

    setQuestionAlternatives((prev) => [...prev, newAlternative])
  }

  const removeQuestionAlternative = (order) => {
    if (hasMinAlternativeCount) return

    setQuestionAlternatives((prev) =>
      prev.filter(
        (alternative) =>
          alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER] !== order
      )
    )
  }

  return (
    <div className={styles.questionManagementForm}>
      <div className="form-group">
        <Typography color="textPrimary" variant="h6">
          Informações gerais
        </Typography>

        <div className="input-group">
          <Typography
            component="label"
            htmlFor="key_words"
            color="textSecondary"
          >
            Insira as Palavras-chaves que irão identificar a questão
          </Typography>

          <TextInput
            id="key_words"
            label="Palavras-chaves"
            size="full"
            error={!!questionFormErrors?.[QUESTION_FIELDS.KEY_WORDS]}
            helperText={questionFormErrors?.[QUESTION_FIELDS.KEY_WORDS]}
            value={questionFormValues[QUESTION_FIELDS.KEY_WORDS]}
            onChange={(e) =>
              changeFormValues(QUESTION_FIELDS.KEY_WORDS, e.target.value)
            }
            required
            fullWidth
          />
        </div>
        <div className="input-group">
          <Typography
            component="label"
            htmlFor="table_test"
            color="textSecondary"
          >
            Insira o nome da banca/prova de onde a questão foi retirada
          </Typography>

          <TextInput
            id="table_test"
            label="Banca/Prova"
            size="full"
            error={!!questionFormErrors?.[QUESTION_FIELDS.TABLE_TEST]}
            helperText={questionFormErrors?.[QUESTION_FIELDS.TABLE_TEST]}
            value={questionFormValues[QUESTION_FIELDS.TABLE_TEST]}
            onChange={(e) =>
              changeFormValues(QUESTION_FIELDS.TABLE_TEST, e.target.value)
            }
            required
            fullWidth
          />
        </div>
        <div className="input-group">
          <Typography
            component="label"
            htmlFor="test_year"
            color="textSecondary"
          >
            Insira o ano de realização da prova de onde a questão foi retirada
          </Typography>

          <TextInput
            id="test_year"
            label="Ano"
            size="full"
            error={!!questionFormErrors?.[QUESTION_FIELDS.TEST_YEAR]}
            helperText={questionFormErrors?.[QUESTION_FIELDS.TEST_YEAR]}
            value={questionFormValues[QUESTION_FIELDS.TEST_YEAR]}
            onChange={(e) =>
              changeFormValues(QUESTION_FIELDS.TEST_YEAR, e.target.value)
            }
            required
            fullWidth
          />
        </div>
      </div>
      <div className="form-group">
        <Typography color="textPrimary" variant="h6">
          Enunciado
        </Typography>
        <div className="input-group">
          <Typography
            component="label"
            htmlFor="enunciation"
            color="textSecondary"
          >
            Insira o enunciado da questão
          </Typography>

          <TextInput
            id="enunciation"
            label="Enunciado"
            size="full"
            error={!!questionFormErrors?.[QUESTION_FIELDS.ENUNCIATION]}
            helperText={questionFormErrors?.[QUESTION_FIELDS.ENUNCIATION]}
            value={questionFormValues[QUESTION_FIELDS.ENUNCIATION]}
            onChange={(e) =>
              changeFormValues(QUESTION_FIELDS.ENUNCIATION, e.target.value)
            }
            required
            fullWidth
            multiline
            rows={5}
          />
        </div>
      </div>
      <div className="form-group">
        <Typography color="textPrimary" variant="h6">
          Alternativas
        </Typography>
        <div className="input-group">
          <Typography color="textSecondary">
            Insira de 2 a 5 alternativas para a questão
          </Typography>

          <div className="alternatives-group">
            {questionAlternatives.map((alternative, index) => (
              <div key={alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]}>
                <TextInput
                  label={`Alternativa ${getAlternativeLabelByOrder(
                    alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]
                  )}`}
                  size="full"
                  error={
                    !!questionFormErrors?.[
                      `${QUESTION_FIELDS.ALTERNATIVES}[${index}].content`
                    ]
                  }
                  helperText={
                    questionFormErrors?.[
                      `${QUESTION_FIELDS.ALTERNATIVES}[${index}].content`
                    ]
                  }
                  value={alternative[QUESTION_ALTERNATIVES_FIELDS.CONTENT]}
                  onChange={(e) =>
                    changeQuestionAlternativeContent(
                      e.target.value,
                      alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]
                    )
                  }
                  required
                  fullWidth
                  multiline
                  rows={2}
                />

                {!hasMinAlternativeCount && (
                  <div className="btn-container">
                    <Button
                      className={styles.removeQuestionButton}
                      onClick={() =>
                        removeQuestionAlternative(
                          alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]
                        )
                      }
                    >
                      excluir alternativa
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {!hasMaxAlternativeCount && (
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={addQuestionAlternative}
          >
            Adicionar Alternativa
          </Button>
        )}
      </div>
      <div className="form-group">
        <Typography color="textPrimary" variant="h6">
          Gabarito
        </Typography>
        <div className="input-group">
          <Typography
            component="label"
            htmlFor="correct_alternative"
            color="textSecondary"
          >
            Insira a alternativa correta da questão
          </Typography>

          <SelectSaraiva
            label="Alternativa correta *"
            id="correct_alternative"
            size="full"
            error={!!questionFormErrors?.[QUESTION_FIELDS.CORRECT_ALTERNATIVE]}
            helperText={
              !!questionFormErrors?.[QUESTION_FIELDS.CORRECT_ALTERNATIVE] &&
              'Campo obrigatório'
            }
            value={
              questionFormValues[QUESTION_FIELDS.CORRECT_ALTERNATIVE] ?? ''
            }
            onChange={(e) =>
              changeFormValues(
                QUESTION_FIELDS.CORRECT_ALTERNATIVE,
                e.target.value
              )
            }
            fullWidth
            required
          >
            {questionAlternatives.map((alternative) => (
              <MenuItem
                key={alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]}
                value={alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]}
              >
                Alternativa{' '}
                {getAlternativeLabelByOrder(
                  alternative[QUESTION_ALTERNATIVES_FIELDS.ORDER]
                )}
              </MenuItem>
            ))}
          </SelectSaraiva>
        </div>
      </div>
    </div>
  )
}
