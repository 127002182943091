import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  textInput: {
    marginRight: 28,
    marginBottom: 32
  },

  textAlternative: {
    marginTop: 40,
    paddingBottom: 20
  },
  content: {
    // width: 880
  },

  title: {
    marginBottom: 24,
    color: 'black'
  },
  subTitle: {
    marginTop: 48,
    marginBottom: 24,
    color: 'black'
  },
  footer: {
    marginTop: 24,
    marginBottom: 16
  },
  TextAlternative: {
    marginTop: 40,
    paddingBottom: 20
  },
  btnAddAlternative: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 56,
    marginLeft: 337,
    marginBottom: 48
  },
  btnDeleteAlternative: {
    top: '0px',
    left: '672px',
    color: '#A42C31',
    position: 'relative'
  },
  jusficationQuestion: {
    marginLeft: 30
  },
  container: {
    width: 857
  },
  contentJusfication: {
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}))

export default useStyles
