import React, { useState, useEffect } from 'react'
import { useHistory, generatePath, useParams } from 'react-router-dom'
import { CardExtraContent } from '@equipe-ninja/saraiva-ui'
import Grid from '@material-ui/core/Grid'
import Crop75Icon from '@material-ui/icons/Crop75'
import PagesIcon from '@material-ui/icons/Pages'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { ProductSelectNoContext } from '../../../components/ProductSelect/ProductSelectNoContext'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { PRODUCT_SELECTION_OPTIONS } from '../../../domain/general/product-selection-options'

export function PromotionalEntryPage() {
  const [selectedBrand, setSelectedBrand] = useState()
  const history = useHistory()
  const { brand } = useParams()

  const hasSelectedOption = () => {
    return selectedBrand && Object.keys(selectedBrand).length > 0
  }

  const navigateWithBrand = (path) => {
    history.push(generatePath(path, { brand: selectedBrand }))
  }

  useEffect(() => {
    if (brand) {
      const productList = PRODUCT_SELECTION_OPTIONS.find(
        (p) => p.value === brand
      )
      setSelectedBrand(productList.value)
    } else {
      setSelectedBrand()
    }
  }, [brand])

  return (
    <ContentWithHeader title="Conteúdo promocional">
      <ProductSelectNoContext
        onChange={(option) => setSelectedBrand(option)}
        brandOptions={['lfg']}
        selectedBrand={selectedBrand}
      />

      {hasSelectedOption() ? (
        <Grid container spacing={3}>
          <Grid item>
            <CardExtraContent
              copyIcon={false}
              hoverTitle="Acessar"
              title="Gerenciar páginas promocionais"
              icon={<PagesIcon style={{ fontSize: 84 }} />}
              onClick={() =>
                navigateWithBrand(RoutesMapping.promotionalPromoPagesList)
              }
            />
          </Grid>

          <Grid item>
            <CardExtraContent
              copyIcon={false}
              title="Gerenciar banners"
              icon={<Crop75Icon style={{ fontSize: 84 }} />}
              hoverTitle="Acessar"
              onClick={() =>
                navigateWithBrand(RoutesMapping.promotionalBannerList)
              }
            />
          </Grid>

          <Grid item>
            <CardExtraContent
              copyIcon={false}
              title="Gerenciar descontos"
              icon={<AttachMoneyIcon style={{ fontSize: 84 }} />}
              hoverTitle="Acessar"
              onClick={() =>
                navigateWithBrand(RoutesMapping.promotionalDiscountList)
              }
            />
          </Grid>
        </Grid>
      ) : null}
    </ContentWithHeader>
  )
}
