module.exports = {
  connectionForm: {
    created:
      'Conexão com AVA adicionada, siga as instruções para concluir o processo.',
    errorOnCreate:
      'Não foi possível criar uma conexão no momento, tente novamente mais tarde.',
    errorOnCNPJLength: 'É necessário que o CNPJ tenha 14 números.',
    errorOnRegisteredCNPJ: 'Esta instituição já está cadastrada.',
    clientNotFound: 'IES não encontrada.'
  },

  connectionMarc: {
    success: 'Instituição cadastrada no Marc21 com sucesso'
  },

  connectionJwt: {
    success: 'Instituição cadastrada no JWT com sucesso'
  },

  toggleConnection: {
    success: 'Conexão atualizada com sucesso.',
    error:
      'Não foi possível alterar o estado da conexão, tente novamente mais tarde'
  },

  fetchConnections: {
    error: 'Não foi possível listar as conexões, tente novamente mais tarde.'
  },

  fetchLibraries: {
    error: 'Não foi possível listar as bibliotecas, tente novamente mais tarde.'
  },

  deploymentForm: {
    created: 'ID de desenvolvimento inserido com sucesso.',
    errorOnCreate: 'Não foi possível cadastrar o ID de Desenvolvimento.'
  },

  deployment: {
    deleted: 'ID de Desenvolvimento excluído com sucesso.',
    errorOnDelete:
      'Não foi possível a exclusão desse ID de Desenvolvimento, tente novamente mais tarde.'
  },

  configForm: {
    success: 'Conexão atualizada com sucesso.',
    error: 'Não foi possível atualizar a conexão.'
  },

  configIntegration: {
    success: 'A conexão foi editada com sucesso.',
    error: 'Não foi possivel editar a conexão, tente novamente mais tarde.'
  },

  subjectsDialog: {
    success: 'Conteúdo(s) adicionado(s) com sucesso!',
    error: 'Não foi possível salvar uma ou mais disciplinas.'
  },

  subjectsDelete: {
    success: 'Conteúdo(s) excluído(s) com sucesso!'
  },

  content: {
    loadingFile: 'Aguarde, estamos carregando seu arquivo',
    errorList: 'Não foi possível listar o conteúdo, tente novamente mais tarde'
  },

  createFolder: {
    success: 'Pasta criada com sucesso',
    error: 'Não foi possível criar uma pasta, tente novamente mais tarde',
    alreadyCreated: 'Essa pasta já foi criada, confira o nome da pasta'
  },

  createFile: {
    success: 'Arquivo criado com sucesso',
    error: 'Não foi possível criar um arquivo, tente novamente mais tarde',
    alreadyCreated: 'Este arquivo já foi criado, confira o nome associado.'
  },

  editContentName: {
    success: 'Nome alterado com sucesso',
    error:
      'Não foi possível renomear o arquivo/pasta, tente novamente mais tarde',
    folderAlreadyCreated: 'Já existe uma pasta com o mesmo nome.',
    fileAlreadyCreated: 'Já existe um arquivo com o mesmo nome.'
  },

  uploadDirectory: {
    error:
      'Não foi possível realizar o upload da pasta, tente novamente mais tarde'
  },

  contentDelete: {
    success: 'Conteúdo apagado com sucesso',
    error: 'Erro ao apagar conteúdo. Verifique se a pasta está vazia.'
  },

  pathDescription: {
    default: 'Ainda não há descrição para essa pasta'
  },

  archivedLibrary: {
    default:
      'Esta biblioteca foi arquivada e não pode ser utilizada pelos usuários, para desarquiva-la clique em “desarquivar biblioteca”.',
    details:
      'Esta biblioteca foi arquivada e não pode ser utilizada pelos usuários, para desarquiva-la retorne a aba de ‘detalhes’.'
  },

  dateTicketEdit: {
    retroactive: 'Não é possível alterar para uma data retroativa',
    beforeInitialDate: 'A data de fim não pode ser menor que a data de início.'
  },

  accessManager: {
    allowAccess:
      'Ao clicar em confirmar você estará liberando acesso a essa pessoa de visualizar e editar todas as páginas de gerenciamento do BackOffice',
    denyAcces:
      'Ao clicar em confirmar você estará bloqueando o acesso dessa pessoa de visualizar e editar todas as páginas de gerenciamento do BackOffice',
    accessAllowed: 'Acesso liberado com sucesso',
    accessDenied: 'Acesso bloqueado com sucesso',
    error: 'Não foi possível carregar lista de acessos'
  },

  duplicateBanner: {
    default:
      'Você está criando um novo banner a partir de um banner existente duplicado.'
  },

  contentUpdate: {
    success: 'Arquivo substituido com sucesso',
    error: 'Ocorreu um problema e o arquivo não foi substituído'
  },
  simulatedDialog: {
    success: 'Simulado adicionado com sucesso!',
    error: 'Não foi possível salvar um ou mais simulado'
  },
  addQuestionDialog: {
    success: 'Questão adicionada ao banco de questões com sucesso.',
    error: 'Não foi possível adicionar uma ou mais questões.'
  },

  deleteBankQuestion: {
    success: 'Questão excluída com sucesso!',
    error: 'Não foi possível excluir uma ou mais questões.'
  },
  simulatedEdit: {
    success: 'Simulado alterado com sucesso!',
    error: 'Não foi possível alterar um ou mais simulado'
  },

  generatedAccess: {
    success: 'Link de acesso gerado.',
    error: 'Não foi possível gerar o acesso'
  },

  editQuestionDialog: {
    success: 'Questão editada com sucesso!',
    error: 'Não foi possível editar um ou mais Questões.'
  },
  deleteSimulated: {
    success: 'Simulado excluído com sucesso!',
    error: 'Não foi possível excluir um ou mais Simulados.'
  },
  invalidFile: {
    error: 'Selecione um arquivo .csv válido.'
  },
  emailSent: {
    success: 'E-mail enviado com sucesso!',
    error: 'Não foi possível enviar o e-mail.'
  },
  incorrectData: {
    error: 'Dados incorretos, verifique e tente novamente.'
  },
  systemUnavailable: {
    error: 'Sistema indisponível no momento, tente novamente mais tarde.'
  },
  caeInvalid: {
    error: 'O novo CAE inserido é inválido.Por favor, revise e tente novamente.'
  },
  userDelete: {
    success: 'Usuário excluído com sucesso!',
    error: 'Não foi possível excluir o usuário.'
  }
}
