import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  contentContainer: {
    marginTop: '24px'
  },

  divider: {
    marginTop: '24px',
    marginBottom: '36px'
  },

  loaderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },

  tableContainer: {
    marginTop: '24px'
  }
}))

export default useStyles
