import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { deleteRequestResponseHandler } from '../../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para remocao de paginas promocionais
 * @returns Promise de sucesso ou rejeita com erro.
 */
const deletePromotionalPage = (id) => {
  return new Promise((resolve, reject) => {
    const path = `markets/api/v1/pages/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .delete(path, null, headers)
      .then((response) => deleteRequestResponseHandler(response))
      .then(() => {
        resolve()
      })
      .catch((error) => reject(error))
  })
}
/* eslint-enable no-unused-vars */

export { deletePromotionalPage }
