import { makeStyles, createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  breakpoints: {
    values: {
      laptop: 1440
    }
  }
})

export const useStyles = makeStyles((theme) => ({
  table: {
    width: 'auto',
    marginRight: '8vw',
    marginLeft: '8vw',
    '&.MuiTableCell-paddingCheckbox': {
      paddingLeft: '19px !important'
    }
  },
  tableHeaders: {
    display: 'flex',
    [theme.breakpoints.up('laptop')]: {
      // backgroundColor: 'red',
      whiteSpace: 'nowrap'
    }
  },
  flexDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteDiv: {
    paddingTop: 3,
    paddingRight: 16
  },
  deleteBtn: {
    height: 42,
    visibility: 'initial'
  },
  folder: {
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  icon: {
    marginRight: 12,
    verticalAlign: 'bottom'
  },
  cell: {
    color: theme.palette.text.secondary,
    padding: '6px 18px 6px 16px !important'
  },
  accordionDiv: {
    width: '100%'
  },
  flexDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20
  },
  previa: {
    color: theme.palette.text.secondary,
    width: 0,
    padding: '6px 0px 6px 0px'
  },
  expanded: {
    animation: '$rotate 800ms ease-in-out',
    transform: 'rotate(-180deg)',
    padding: 0
  },
  notExpanded: {
    animation: '$reverseRotate 800ms ease-in-out',
    transform: 'rotate(0)'
  },
  accordionCustom: {
    padding: '0 !important'
  },
  checkBoxCustom: {
    '&.Mui-checked': {
      color: '#624C92'
    }
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#efedf4 !important"
    }
  },
  selectedRow: {
    backgroundColor: "#efedf4 !important"
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 76
  },
  contentBG: {
    backgroundColor: theme.palette.action.hover
  },
  contentPadding: {
    paddingTop: 12
  }
}))

export default useStyles
