import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Snackbar } from '@equipe-ninja/saraiva-ui'

import { GeneralContext } from '../../contexts/GeneralContext'
import ProductSelect from '../../components/ProductSelect'
import BdsContent from './BdsContent'
import useStyles from './styles'

function NewMarcConnections() {
  const classes = useStyles()

  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    productSelected,
    setProduct
  } = useContext(GeneralContext)

  const mainContent = () => {
    if (productSelected.value !== 'bds') {
      return <></>
    }

    return <BdsContent />
  }

  return (
    <Grid className={classes.root}>
      <Snackbar
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
        autoHideDuration={5000}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        severity="error"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
        autoHideDuration={5000}
      >
        {errorMessage}
      </Snackbar>

      <Typography variant="h3" className={classes.titleH3}>
        Gerenciar marc21
      </Typography>

      <ProductSelect onChange={(e) => setProduct(e)} onlyBds />

      {mainContent()}
    </Grid>
  )
}

export default NewMarcConnections
