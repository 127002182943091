import React, { useContext, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import Users from '../../../../../services/accounts/users'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import PropTypes from 'prop-types'

export default function EditNameUserDialog({
  open,
  onClose,
  currentName,
  onUpdated
}) {
  const [newName, setNewName] = useState('')
  const [isNameValid, setIsNameValid] = useState(false)
  const { userData } = useContext(GeneralContext)

  const validateName = (name) => {
    const re = /^[A-Za-z\u00C0-\u00FF\s]+$/
    return re.test(String(name).trim())
  }

  const handleNameChange = (name) => {
    setNewName(name)
    setIsNameValid(validateName(name))
  }

  const handleSave = async () => {
    if (isNameValid && newName) {
      try {
        const user_id = userData.user_id

        const payload = {
          email: '',
          first_name: newName,
          last_name: '',
          cpf: ''
        }

        const response = await Users.editUser(payload, user_id)

        if (response.ok) {
          handleClose()
          onUpdated()
        } else {
          console.error('Failed to update email', await response.text())
        }
      } catch (error) {
        console.error('Failed to update email', error)
      }
    }
  }

  const handleClose = () => {
    setNewName('')
    setIsNameValid(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { width: '600px', height: '292px' }
      }}
    >
      <DialogTitle id="form-dialog-title">Alterar nome do usuário</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="current-email"
          label="Atual nome"
          fullWidth
          variant="standard"
          disabled
          defaultValue={currentName}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            style: { borderBottom: '1px solid rgba(0, 0, 0, 0.192)' }
          }}
        />
        <TextField
          style={{ marginTop: '15px' }}
          error={!isNameValid && newName}
          helperText={
            !isNameValid && newName
              ? 'Não é possível inserir números ou símbolos neste campo'
              : ''
          }
          id="new-nome"
          label="Novo nome"
          fullWidth
          variant="outlined"
          value={newName}
          onChange={(event) => handleNameChange(event.target.value)}
          FormHelperTextProps={{
            style: { margin: 0 }
          }}
        />
      </DialogContent>
      <Divider></Divider>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCELAR
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isNameValid}>
          ALTERAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}
EditNameUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentEmail: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired
}
