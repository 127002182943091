export const outboundDate = (date) => {
  return date && date.toISOString()
}

export const outboundDecimal = (decimalString) => {
  if (decimalString === undefined || decimalString === null) return ''

  return parseFloat(decimalString.replace(',', '.'))
}

export const outboundInteger = (integerString) => {
  if (integerString === undefined || integerString === null) return ''

  return parseInt(integerString, 10)
}
