import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { listItemAdapterInbound } from '../adapters/vouchers-adapters'
import { getResquestResponseHandler } from '../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para carregamento de lista de vouchers
 * @returns lista de vouchers apos passar por um adaptador de entrada
 */
const getVouchersList = async (search = '') => {
  return new Promise((resolve, reject) => {
    const path = '/tickets/api/v1/tickets/ecommerce'
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((json) => {
        // resolve(json.data.filter((e) => e[F.CAMPAIGN_NAME].includes(search)))
        resolve(listItemAdapterInbound(json.data))
      })
      .catch((err) => reject(err))
  })
}
/* eslint-enable no-unused-vars */

export { getVouchersList }
