import { generateFormError } from '../../../general/generate-form-errors'
import { SCHEDULED_CONTENT_FIELDS } from '../scheduled-content-fields'

const {
  ANSWER_CONTENT,
  ANSWER_PUBLISHING_DATE,
  CONTENT,
  END_DATE,
  EXERCISE_TYPE,
  ID,
  INITIAL_DATE,
  LINK,
  SCHEDULED_CONTENT_TYPE,
  TITLE
} = SCHEDULED_CONTENT_FIELDS

const formFormat = {
  [ANSWER_CONTENT]: '',
  [ANSWER_PUBLISHING_DATE]: null,
  [CONTENT]: '',
  [END_DATE]: null,
  [EXERCISE_TYPE]: '',
  [ID]: null,
  [INITIAL_DATE]: null,
  [LINK]: '',
  [SCHEDULED_CONTENT_TYPE]: '',
  [TITLE]: ''
}

export const scheduledContentDefaultForm = () => {
  return {
    values: {
      ...formFormat
    },
    errors: generateFormError(formFormat)
  }
}
