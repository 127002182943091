import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../helpers/mock-server-utils'
import { orderDetailAdapterInbound } from '../adapters/order-detail-adapters'
import { ORDER_DETAIL_FIELDS as REMOTE_FIELDS } from '../remote-fields/order-detail-fields'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar os detalhes do pedido
 * @returns Promise com a resposta ou rejeita com erro
 */
const getOrderDetail = (id) => {
  return new Promise((resolve, reject) => {
    const path = ``
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, headers)
      .then((response) => {
        resolve(orderDetailAdapterInbound(response))
      })
      .catch((error) => reject(error))
  })
}

const orderDetailfactory = (orderId) => {
  return {
    [REMOTE_FIELDS.KEY]: orderId,
    [REMOTE_FIELDS.ACQUISITION_DATE]: new Date(2022, 1, 18),
    [REMOTE_FIELDS.ORDER_STATUS]: 'Ativo',
    [REMOTE_FIELDS.ORDER_PRICE]: '60,00',
    [REMOTE_FIELDS.PAYMENT_METHOD]:
      Math.ceil(Math.random() * 2) === 1 ? 'Cartão' : 'Boleto',
    [REMOTE_FIELDS.CARD_FLAG]: 'Master',
    [REMOTE_FIELDS.CARD_NAME]: 'Danilo F Mendes',
    [REMOTE_FIELDS.CODE]:
      '34191.09461 25754.580196 61309.830000 1 86700000008000',
    [REMOTE_FIELDS.DUE_DATE]: new Date(2022, 1, 5, 17, 15),
    [REMOTE_FIELDS.BUYER_DOCUMENT]: '99999999999',
    [REMOTE_FIELDS.NUMBER_INSTALLMENTS]: '8',
    [REMOTE_FIELDS.BILLING_ADDRESS]: {
      [REMOTE_FIELDS.STREET]: 'Avenida Raimundo Pereira de Magalhães',
      [REMOTE_FIELDS.NUMBER]: '2500',
      [REMOTE_FIELDS.CEP]: '05145-100',
      [REMOTE_FIELDS.CITY]: 'São Paulo',
      [REMOTE_FIELDS.NEIGHBORHOOD]: 'Jardim Íris',
      [REMOTE_FIELDS.STATE]: 'São Paulo',
      [REMOTE_FIELDS.COUNTRY]: 'Brasil'
    },
    [REMOTE_FIELDS.VOUCHER]: {
      [REMOTE_FIELDS.VOUCHER_NAME]: 'LFG50%OFF',
      [REMOTE_FIELDS.VOUCHER_PERCENTAGE]: '80%'
    },
    [REMOTE_FIELDS.COURSE_SUBTOTAL]: '1200,00',
    [REMOTE_FIELDS.ORDER_SUMMARY]: [
      {
        [REMOTE_FIELDS.COURSE_ID]: 1,
        [REMOTE_FIELDS.COURSE_NAME]:
          'Cálculos e Teses Revisionais de Benefícios Previdenciários',
        [REMOTE_FIELDS.COURSE_PRICE]: '60,00',
        [REMOTE_FIELDS.COURSE_IMAGE]:
          'https://www.iped.com.br/img/cursos/m_61635.jpg'
      },
      {
        [REMOTE_FIELDS.COURSE_ID]: 2,
        [REMOTE_FIELDS.COURSE_NAME]: 'Legislação Penal Especial',
        [REMOTE_FIELDS.COURSE_PRICE]: '300,00',
        [REMOTE_FIELDS.COURSE_IMAGE]:
          'https://www.iped.com.br/img/cursos/m_61635.jpg'
      }
    ]
  }
}

const getOrderDetailMocked = (orderId) => {
  return mockedServerAnswer(
    orderDetailfactory(orderId),
    true,
    1000,
    orderDetailAdapterInbound
  )
}

export { getOrderDetailMocked as getOrderDetail }
