export const BANNER_SIZES = {
  WEB: 'web',
  RESPONSIVE: 'responsive',
  OFFERS: 'offers'
}

export function bannersSizers(type) {
  switch (type) {
    case BANNER_SIZES.RESPONSIVE:
      return { width: 414, height: 430 }
    case BANNER_SIZES.OFFERS:
      return { width: 283, height: 230 }
    default:
      return { width: 1280, height: 350 }
  }
}

export const BANNER_SIZES_CONECTA = {
  WEB: 'web',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

export function bannersSizersConecta(type) {
  switch (type) {
    case BANNER_SIZES_CONECTA.TABLET:
      return { width: 544, height: 172 }
    case BANNER_SIZES_CONECTA.MOBILE:
      return { width: 422, height: 172 }
    default:
      return { width: 936, height: 172 }
  }
}
