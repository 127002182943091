import { selector } from 'recoil'
import { courseTopicsState } from '../course-topics-state'

export const reusableTopicSelector = selector({
  key: 'reusableTopicSelector',
  get: ({ get }) => {
    return {
      reusableTopics: get(courseTopicsState).reusableTopics,
      isFetchingReusableTopics: get(courseTopicsState).isFetchingReusableTopics
    }
  },
  set: ({ get, set }, { reusableTopics, isFetchingReusableTopics }) => {
    set(courseTopicsState, {
      ...get(courseTopicsState),
      reusableTopics,
      isFetchingReusableTopics
    })
  }
})
