import React from 'react'
import { Switch } from 'react-router-dom'
import { RoutesMapping } from '../helpers/routes-mapping'
import { PromotionalEntryPage } from '../pages/promotional/PromotionalEntryPage'
import { PrivateRoute } from '../components/PrivateRoute'
import { BannersPage } from '../pages/promotional/BannersPage'
import { EditBanner } from '../pages/promotional/BannersPage/Edit/EditBanner'
import { EditBannerSale } from '../pages/promotional/BannersPage/Edit/EditBannerSale'
import { Duplicate } from '../pages/promotional/BannersPage/Duplicate'
import { Create } from '../pages/promotional/BannersPage/Create'
import { PromotionalProvider } from '../contexts/PromotionalContext'
import { DiscountCampaignList } from '../pages/promotional/discount-campaign/DiscountCampaignList'
import { DiscountCampaignDetails } from '../pages/promotional/discount-campaign/DiscountCampaignDetails'

import {
  PromotionalPagesNew,
  PromotionalPagesList,
  PromotionalPagesEdit
} from '../pages/promotional/promotional-pages'

export function PromotionalContentRoutes() {
  return (
    <PromotionalProvider>
      <Switch>
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalPageEntryPotint}
          component={PromotionalEntryPage}
          defaultLayout
        />

        <PrivateRoute
          exact
          path={RoutesMapping.promotionalPromoPagesList}
          component={PromotionalPagesList}
          defaultLayout
        />

        <PrivateRoute
          exact
          path={RoutesMapping.promotionalPromoPagesEdit}
          component={PromotionalPagesEdit}
          defaultLayout
        />

        <PrivateRoute
          exact
          path={RoutesMapping.promotionalPromoPagesNew}
          component={PromotionalPagesNew}
          defaultLayout
        />

        <PrivateRoute
          exact
          path={RoutesMapping.promotionalBannerList}
          component={BannersPage}
          defaultLayout
        />
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalBannerCreate}
          component={Create}
          defaultLayout
        />
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalBannerEdit}
          component={EditBanner}
          defaultLayout
        />
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalBannerSaleEdit}
          component={EditBannerSale}
          defaultLayout
        />
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalBannerDuplicar}
          component={Duplicate}
          defaultLayout
        />
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalBannerSaleDuplicar}
          component={Duplicate}
          defaultLayout
        />

        <PrivateRoute
          exact
          path={RoutesMapping.promotionalDiscountList}
          component={DiscountCampaignList}
          defaultLayout
        />
        <PrivateRoute
          exact
          path={RoutesMapping.promotionalDiscountDetails}
          component={DiscountCampaignDetails}
          defaultLayout
        />
      </Switch>
    </PromotionalProvider>
  )
}
