import { makeStyles } from '@material-ui/core/styles'
import {
  spacing,
  pageClasses
} from '../../../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...pageClasses(theme),
  ...spacing,
  formContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  datesContainer: {
    display: 'flex',
    '& > *:nth-child(2)': {
      marginLeft: 24
    }
  }
}))

export default useStyles
