import { makeStyles } from '@material-ui/core/styles'
import { spacing, pageClasses } from '../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  ...pageClasses(theme),
  addTabButton: {
    height: '136px',
    backgroundColor: 'rgba(36, 90, 147, 0.08)',
    width: '100%',
    border: '2px dashed #7698bb',
    textTransform: 'none',
    '&:hover': {
      border: '2px solid #245a93'
    }
  },
  addIcon: {
    color: theme.palette.text.secondary
  },
  labelOverride: {
    '& label': {
      zIndex: '1 !important'
    }
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconButton: {
    margin: '-8px 0'
  }
}))

export default useStyles
