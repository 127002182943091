import Joi from 'joi'
import { VOUCHER_FIELDS as F } from '../index'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../helpers/validation'

const schema = Joi.object({
  [F.START_DATE]: Joi.date().required(),
  [F.END_DATE]: Joi.date().min(Joi.ref(F.START_DATE)).required().messages({
    'date.min': 'Deve ser posterior a data de início'
  }),
  [F.STATUS]: Joi.boolean().required()
})

export const editStatusValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schema)
}

export const editStatusFieldValidation = (field, value, formValues) => {
  return validateSingleFieldAndGenerateErrors(field, value, formValues, schema)
}
