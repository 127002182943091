import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 72,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    },
    '& .MuiTableCell-body': {
      textAlign: 'left'
    },
    '& .MuiTableCell-head': {
      textAlign: 'left'
    }
  },

  paper: {
    marginTop: 24,
    width: '100%'
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24,
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`
  },

  contentBG: {
    backgroundColor: theme.palette.action.hover
  },

  button: {
    marginRight: 12,
    '&:last-child': {
      marginRight: 0
    }
  },

  breadcrumbs: {
    display: 'flex',
    alignItems: 'center'
  },

  paperSearch: {
    width: 277,
    position: 'absolute',
    borderRadius: 4,
    padding: '10px 0px 10px 12px',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main
    },
    zIndex: 1000,
    marginTop: 8
  },

  searchBar: {
    backgroundColor: 'red'
  },

  snackDiv: {
    margin: '24px 16px 0 28px'
  },

  inactivated: {
    '& .MuiTableCell-body': {
      color: 'rgba(0,0,0,0.38)',
    },
  }
}))

export default useStyles
