import {
  VOUCHERS_REMOTE_LIST_ITEM_FIELDS as REMOTE_LIST_ITEM,
  VOUCHERS_REMOTE_DETAILS_FIELDS as REMOTE_DETAILS
} from '../remote-fields/vouchers-remote-fields'
import {
  VOUCHER_LIST_ITEM_FIELDS as LIST_F,
  VOUCHER_FIELDS as F,
  VOUCHER_TYPES
} from '../../../domain/vouchers'
import { RemoteDateUtils } from '../../utils/remote-date-utils'
import { outboundDate } from '../../utils/outbound-data-transformation'
import { VOUCHER_STATUSES } from '../../../domain/vouchers/voucher-statuses'
import { formatDateToISO } from '../../../helpers/formatDate'

// eslint-disable-next-line no-unused-vars
const adapterVouchersSimplified = (remoteDataList) => {
  return remoteDataList.map((remoteData) => ({
    [LIST_F.ID]: remoteData[REMOTE_LIST_ITEM.ID],
    [LIST_F.PREFIX]: remoteData[REMOTE_LIST_ITEM.PREFIX],
    [LIST_F.TOTAL_AS_TEXT]: `${remoteData[REMOTE_LIST_ITEM.TOTAL_ACTIVATED]}/${
      remoteData[REMOTE_LIST_ITEM.TOTAL_AVAILABLE]
    }`,
    [LIST_F.VOUCHER_TYPE]: remoteData[REMOTE_LIST_ITEM.VOUCHER_TYPE]
  }))
}

export const listItemAdapterInbound = (remoteDataList) => {
  return remoteDataList.map((remoteData) => ({
    [LIST_F.ID]: remoteData[REMOTE_LIST_ITEM.ID],
    [LIST_F.CAMPAIGN_NAME]: remoteData[REMOTE_LIST_ITEM.CAMPAIGN_NAME],
    [LIST_F.PREFIX]: remoteData[REMOTE_LIST_ITEM.PREFIX],
    [LIST_F.TOTAL_AS_TEXT]: `${remoteData[REMOTE_LIST_ITEM.TOTAL_ACTIVATED]}/${
      remoteData[REMOTE_LIST_ITEM.TOTAL_AVAILABLE]
    }`,
    [LIST_F.DISCOUNT_VALUE]: `${remoteData[REMOTE_LIST_ITEM.DISCOUNT_VALUE]}%`,
    [LIST_F.START_DATE]: RemoteDateUtils.parseDateStringToHuman(
      remoteData[REMOTE_LIST_ITEM.START_DATE]
    ),
    [LIST_F.END_DATE]: RemoteDateUtils.parseDateStringToHuman(
      remoteData[REMOTE_LIST_ITEM.END_DATE]
    ),
    [LIST_F.VOUCHER_TYPE]:
      remoteData[REMOTE_DETAILS.VOUCHER_TYPE] === VOUCHER_TYPES.MANY_USES.value
        ? VOUCHER_TYPES.MANY_USES.label
        : VOUCHER_TYPES.ONE_USE.label,
    [LIST_F.STATUS]: remoteData[REMOTE_LIST_ITEM.STATUS]
      ? VOUCHER_STATUSES.ACTIVE.label
      : VOUCHER_STATUSES.INACTIVE.label
  }))
}

export const listCompanyAdapterInbound = (remoteDataList) => {
  return remoteDataList.data.map((remoteData) => ({
    [LIST_F.ID]: remoteData[REMOTE_LIST_ITEM.ID],
    [LIST_F.CAMPAIGN_NAME]: remoteData[REMOTE_LIST_ITEM.CAMPAIGN_NAME],
    [LIST_F.PREFIX]: remoteData[REMOTE_LIST_ITEM.PREFIX],
    [LIST_F.TOTAL_AS_TEXT]: `${remoteData[REMOTE_LIST_ITEM.TOTAL_ACTIVATED]}/${
      remoteData[REMOTE_LIST_ITEM.TOTAL_AVAILABLE]
    }`,
    [LIST_F.DISCOUNT_VALUE]: `${remoteData[REMOTE_LIST_ITEM.DISCOUNT_VALUE]}%`,
    [LIST_F.START_DATE]: RemoteDateUtils.parseDateStringToHuman(
      remoteData[REMOTE_LIST_ITEM.START_DATE]
    ),
    [LIST_F.END_DATE]: RemoteDateUtils.parseDateStringToHuman(
      remoteData[REMOTE_LIST_ITEM.END_DATE]
    ),
    [LIST_F.VOUCHER_TYPE]:
      remoteData[REMOTE_DETAILS.VOUCHER_TYPE] === VOUCHER_TYPES.MANY_USES.value
        ? VOUCHER_TYPES.MANY_USES.label
        : VOUCHER_TYPES.ONE_USE.label,
    [LIST_F.STATUS]: remoteData[REMOTE_LIST_ITEM.STATUS]
      ? VOUCHER_STATUSES.ACTIVE.label
      : VOUCHER_STATUSES.INACTIVE.label
  }))
}

export const detailsAdapterOutbound = (localData) => {
  return {
    [REMOTE_DETAILS.CAMPAIGN_NAME]: localData[F.CAMPAIGN_NAME],
    [REMOTE_DETAILS.CAMPAIGN_VOUCHERS_LIST]:
      localData[F.CAMPAIGN_VOUCHERS_LIST],
    [REMOTE_DETAILS.DISCOUNT_VALUE]: localData[F.DISCOUNT_VALUE],
    [REMOTE_DETAILS.END_DATE]: outboundDate(localData[F.END_DATE]),
    [REMOTE_DETAILS.ID]: localData[F.ID],
    [REMOTE_DETAILS.PREFIX]: localData[F.PREFIX],
    [REMOTE_DETAILS.START_DATE]: outboundDate(localData[F.START_DATE]),
    [REMOTE_DETAILS.STATUS]: localData[F.STATUS],
    [REMOTE_DETAILS.TOTAL_ACTIVATED]: localData[F.TOTAL_ACTIVATED],
    [REMOTE_DETAILS.TOTAL_AVAILABLE]: localData[F.TOTAL_AVAILABLE],
    [REMOTE_DETAILS.VOUCHER_TYPE]: localData[F.VOUCHER_TYPE]
  }
}

export const createVoucherManyUsesAdapter = (localData) => {
  return {
    ticket: {
      [REMOTE_DETAILS.PREFIX]: localData[F.PREFIX],
      [REMOTE_DETAILS.DISCOUNT_VALUE]: localData[F.DISCOUNT_VALUE],
      [REMOTE_DETAILS.START_DATE]: formatDateToISO(localData[F.START_DATE]),
      [REMOTE_DETAILS.END_DATE]: formatDateToISO(localData[F.END_DATE]),
      [REMOTE_DETAILS.TOTAL_AVAILABLE]: localData[F.USES_PER_VOUCHER],
      [REMOTE_DETAILS.CAMPAIGN_NAME]: localData[F.CAMPAIGN_NAME],
      [REMOTE_DETAILS.VOUCHER_TYPE]: localData[F.VOUCHER_TYPE]
    }
  }
}

export const createVoucherOneUseAdapter = (localData) => {
  return {
    ticket: {
      [REMOTE_DETAILS.PREFIX]: localData[F.PREFIX],
      [REMOTE_DETAILS.DISCOUNT_VALUE]: localData[F.DISCOUNT_VALUE],
      [REMOTE_DETAILS.START_DATE]: formatDateToISO(localData[F.START_DATE]),
      [REMOTE_DETAILS.END_DATE]: formatDateToISO(localData[F.END_DATE]),
      [REMOTE_DETAILS.TOTAL_AVAILABLE]: localData[F.USES_PER_VOUCHER],
      [REMOTE_DETAILS.CAMPAIGN_NAME]: localData[F.CAMPAIGN_NAME],
      [REMOTE_DETAILS.VOUCHER_TYPE]: localData[F.VOUCHER_TYPE],
      [REMOTE_DETAILS.OFFERABLE_TYPE]: 'plan',
      [REMOTE_DETAILS.OFFERABLE_ID]: '3'
    },
    [REMOTE_DETAILS.QUANTITY_TICKETS]: parseInt(localData[F.VOUCHER_AMOUNT], 10)
  }
}

export const detailsAdapterInbound = (remoteData) => {
  return {
    [F.CAMPAIGN_NAME]: remoteData[REMOTE_DETAILS.CAMPAIGN_NAME],
    [F.DISCOUNT_VALUE]: remoteData[REMOTE_DETAILS.DISCOUNT_VALUE],
    [F.END_DATE]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_DETAILS.END_DATE]
    ),
    [F.ID]: remoteData[REMOTE_DETAILS.ID],
    [F.PREFIX]: remoteData[REMOTE_DETAILS.PREFIX],
    [F.START_DATE]: RemoteDateUtils.parseRemoteDate(
      remoteData[REMOTE_DETAILS.START_DATE]
    ),
    [F.STATUS]: remoteData[REMOTE_DETAILS.STATUS]
      ? VOUCHER_STATUSES.ACTIVE.value
      : VOUCHER_STATUSES.INACTIVE.value,
    [F.TOTAL_ACTIVATED]: remoteData[REMOTE_DETAILS.TOTAL_ACTIVATED],
    [F.TOTAL_AVAILABLE]: remoteData[REMOTE_DETAILS.TOTAL_AVAILABLE],
    [F.VOUCHER_TYPE]: remoteData[REMOTE_DETAILS.VOUCHER_TYPE]
  }
}

export const editVoucherAdapter = (localData) => {
  return {
    [REMOTE_DETAILS.STATUS]: localData[F.STATUS],
    [REMOTE_DETAILS.END_DATE]: formatDateToISO(localData[F.END_DATE]),
    [REMOTE_DETAILS.TOTAL_AVAILABLE]: localData[F.USES_PER_VOUCHER]
  }
}
