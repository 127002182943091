import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { TextInput, SelectSaraiva, Button } from '@equipe-ninja/saraiva-ui'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Card from '@material-ui/core/Card'
import MenuItem from '@material-ui/core/MenuItem'
import MuiButton from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useRecoilState, useRecoilValue } from 'recoil'
import useStyles from './styles'
import {
  ECOMMERCE_TABS_FIELDS,
  ECOMMERCE_FORM_FIELDS
} from '../../../../domain/courses/entities'
import {
  createDefaultEmptyTab,
  createDefaultTabError,
  singleProductCourseTabFieldValidation,
  singleProductCourseTabValidation
} from '../../../../domain/product-course/business'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import TextEditor from '../../../../components/TextEditor'
import {
  ecommerceForm,
  ecommerceTabsForm,
  courseFetchingControls
} from '../../../../atoms/course'

const { CONTENT, ORDER, TITLE } = ECOMMERCE_TABS_FIELDS
const { ID } = ECOMMERCE_FORM_FIELDS

export const CoursesTabsForm = (props) => {
  const { onPreviousSuccess, onFinalize } = props
  const classes = useStyles()
  const { setErrorMessage } = useContext(GeneralContext)
  const [{ values, errors }, setForm] = useRecoilState(ecommerceTabsForm)
  const {
    values: { [ID]: identifier }
  } = useRecoilValue(ecommerceForm)
  const controls = useRecoilValue(courseFetchingControls)
  const [refreshToken, setrefreshToken] = useState(Date.now())

  const [orders, setOrders] = useState([])
  const { length } = values
  const { length: errorsLength } = errors

  const addTabsAndErrorsToState = (newTabsState, newErrorsState) => {
    setForm((prev) => ({
      ...prev,
      values: newTabsState,
      errors: newErrorsState
    }))
  }

  const addNewCourseTab = () => {
    const shouldAddNewErrorObject = errorsLength < length + 1

    const newStateTabs = [
      ...values,
      createDefaultEmptyTab({ [ORDER]: length + 1 })
    ]
    let newStateTabsErrors = [...errors]

    if (shouldAddNewErrorObject) {
      newStateTabsErrors = [...errors, createDefaultTabError()]
    }

    addTabsAndErrorsToState(newStateTabs, newStateTabsErrors)
  }

  const setTabStateField = (value, field, tabIndex) => {
    setForm((prev) => {
      return {
        ...prev,
        values: prev.values.map((e, i) => {
          if (i === tabIndex) {
            return { ...e, [field]: value }
          }

          return e
        })
      }
    })
  }

  const validateField = (value, field, tabIndex) => {
    const error = singleProductCourseTabFieldValidation(
      field,
      value,
      values[tabIndex]
    )

    setForm((prev) => {
      return {
        ...prev,
        errors: prev.errors.map((tabErrors, index) => {
          if (index === tabIndex) {
            return { ...tabErrors, ...error }
          }

          return tabErrors
        })
      }
    })
  }

  const onChangeEvent = (event, field, tabIndex) => {
    const newValue = event.target.value

    validateField(newValue, field, tabIndex)

    setTabStateField(newValue, field, tabIndex)
  }

  const onChangeOrder = (event, tabIndex) => {
    const newValue = event.target.value
    const prevValue = values[tabIndex][ORDER]
    const currentOrderHolderIndex = values.reduce((prev, tab, index) => {
      return tab[ORDER] === newValue ? index : prev
    }, null)

    setForm((prev) => ({
      ...prev,
      values: prev.values.map((e, i) => {
        if (i === tabIndex) return { ...e, [ORDER]: newValue }
        if (i === currentOrderHolderIndex) return { ...e, [ORDER]: prevValue }

        return e
      })
    }))
  }

  const onDeleteOrder = (tabIndex) => {
    const deletedOrder = values[tabIndex][ORDER]

    setForm((prev) => ({
      ...prev,
      values: prev.values.reduce((previous, current, index) => {
        if (current[ORDER] > deletedOrder) {
          return [...previous, { ...current, order: current[ORDER] - 1 }]
        }

        if (index === tabIndex) {
          return previous
        }

        return [...previous, current]
      }, []),
      errors: prev.errors.filter((_, index) => index !== tabIndex)
    }))

    setTimeout(() => setrefreshToken(Date.now()))
  }

  const validateAllTabsAndSetErrors = () => {
    let areAllTabsValid = true

    setForm((prev) => ({
      ...prev,
      errors: prev.errors.map((error, index) => {
        const { isValid, errors: tabErrors } = singleProductCourseTabValidation(
          values[index]
        )

        areAllTabsValid = areAllTabsValid && isValid

        return { ...error, ...tabErrors }
      })
    }))

    return { isValid: areAllTabsValid }
  }

  const notifyLocalErrors = () => {
    setErrorMessage(
      'Houve falhas de validação, verifique os campos em vermelho'
    )
  }

  const onClickPrevious = () => {
    const { isValid: areTabsValid } = validateAllTabsAndSetErrors()

    if (!areTabsValid) {
      notifyLocalErrors()

      return
    }

    onPreviousSuccess()
  }

  const onChangeEditorContent = (contentAsHtml, tabIndex) => {
    setTabStateField(contentAsHtml, CONTENT, tabIndex)
  }

  const onSave = () => {
    const { isValid: areAllTabsValid } = validateAllTabsAndSetErrors()

    if (!areAllTabsValid) {
      notifyLocalErrors()

      return
    }

    onFinalize()
  }

  const matchErrorEntriesToTabCount = () => {
    if (errorsLength < length) {
      const errorsList = new Array(length).fill(null).map(() => {
        return createDefaultTabError()
      })

      setForm((prev) => ({ ...prev, errors: errorsList }))
    }
  }

  useEffect(() => {
    if (identifier && values.length === 0) {
      addNewCourseTab()
    }

    if (identifier) {
      matchErrorEntriesToTabCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier])

  useEffect(() => {
    if (length > 0) {
      setOrders(new Array(length).fill(null).map((e, i) => i + 1))
    }
  }, [length])

  return (
    <div className={classes.marginTop16}>
      {values.map((tab, tabIndex) => (
        <Card className={cls(classes.paper, classes.marginTopMedium)}>
          <div className={classes.cardHeader}>
            <Typography variant="h6">Aba do curso</Typography>

            {length > 1 && (
              <IconButton
                aria-label="deletar tab"
                onClick={() => onDeleteOrder(tabIndex)}
                className={classes.iconButton}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>

          <TextInput
            className={classes.marginTopMedium}
            error={!!errors?.[tabIndex]?.[TITLE]}
            helperText={errors?.[tabIndex]?.[TITLE]}
            label="Título da aba"
            onChange={(evt) => onChangeEvent(evt, TITLE, tabIndex)}
            size="full"
            required
            value={tab[TITLE]}
          />

          <div className={cls(classes.marginTopMedium, classes.labelOverride)}>
            <SelectSaraiva
              value={tab[ORDER]}
              onChange={(evt) => onChangeOrder(evt, tabIndex)}
              label="Ordem de exibição *"
              required
              size="full"
            >
              {orders.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectSaraiva>
          </div>

          <div style={{ marginTop: '21px' }}>
            <TextEditor
              refreshToken={refreshToken}
              onChange={(content) => onChangeEditorContent(content, tabIndex)}
              initialValue={tab[CONTENT]}
            />
          </div>
        </Card>
      ))}

      <MuiButton
        classes={{ root: cls(classes.addTabButton, classes.marginTopMedium) }}
        onClick={addNewCourseTab}
      >
        <AddIcon className={classes.addIcon} />

        <Typography className={classes.marginLeft16} variant="h5">
          Adicionar Aba
        </Typography>
      </MuiButton>

      <div className={cls(classes.formActionsContainer, classes.marginTopXL)}>
        <Button
          color="primary"
          onClick={onClickPrevious}
          disabled={controls.isExecutingTask}
        >
          ANTERIOR
        </Button>

        <Button
          className={classes.marginLeftSmall}
          color="primary"
          disabled={controls.isExecutingTask}
          onClick={onSave}
          variant="contained"
        >
          finalizar cadastro e-commerce
        </Button>
      </div>
    </div>
  )
}

CoursesTabsForm.propTypes = {
  onPreviousSuccess: PropTypes.func.isRequired,
  onFinalize: PropTypes.func.isRequired
}

CoursesTabsForm.defaultProps = {}
