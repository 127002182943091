import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import httpRequest from '../../../../gateway/httpRequest'

const saveImageBannerRotationWeb = (formData) => {
  return new Promise((resolve, reject) => {
    const path = `/markets/api/v1/uploads/create_banner_rotation_web`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpRequest()
      .post(path, formData, headers)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const saveImageBannerRotationRecursive = (formData) => {
  return new Promise((resolve, reject) => {
    const path = `/markets/api/v1/uploads/create_banner_rotation_responsive`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpRequest()
      .post(path, formData, headers)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const saveImageBannerSales = (formData) => {
  return new Promise((resolve, reject) => {
    const path = `/markets/api/v1/uploads/banner_emphasis`
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpRequest()
      .post(path, formData, headers)
      .then((response) => {
        resolve(response.data.name)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export {
  saveImageBannerRotationRecursive,
  saveImageBannerRotationWeb,
  saveImageBannerSales
}
