import React, { useState, useContext, useEffect } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, Dialog } from '@equipe-ninja/saraiva-ui'
import DeleteIcon from '@material-ui/icons/Delete'
import useStyles from './styles'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import {
  PROMOTIONAL_PAGES_FIELDS,
  PUBLISHING_STATUS
} from '../../../../domain/promotional-content/promotional-pages'
import {
  promotionalPageValidation,
  promotionalPageFieldValidation
} from '../../../../domain/promotional-content/promotional-pages/business'
import { initialURLValueForBrand } from '../../../../domain/brands'
import {
  getPromotionalPage,
  updatePromotionalPage,
  deletePromotionalPage
} from '../../../../services/promotional-content/promotional-pages'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { PromotionalPageForm } from '../PromotionalPageForm'

const { KEY, CONTENT, END_DATE, REDIRECT_URL, START_DATE, STATUS, TITLE, URL } =
  PROMOTIONAL_PAGES_FIELDS

export const PromotionalPagesEdit = () => {
  const classes = useStyles()
  const history = useHistory()
  const [isExecutingTask, setIsExecutingTask] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [originalTitle, setOriginalTitle] = useState('')
  const [isFetching, setIsFetching] = useState(true)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const { id } = useRequiredParamsAccess(
    'id',
    generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
  )

  const [formState, setFormState] = useState({
    [KEY.value]: id,
    [CONTENT.value]: '',
    [END_DATE.value]: null,
    [REDIRECT_URL.value]: initialURLValueForBrand(brand),
    [START_DATE.value]: null,
    [STATUS.value]: PUBLISHING_STATUS.ACTIVE.value,
    [TITLE.value]: '',
    [URL.value]: initialURLValueForBrand(brand)
  })

  const [formErrors, setFormErrors] = useState({
    [KEY.value]: false,
    [CONTENT.value]: false,
    [END_DATE.value]: false,
    [REDIRECT_URL.value]: false,
    [START_DATE.value]: false,
    [STATUS.value]: false,
    [TITLE.value]: false,
    [URL.value]: false
  })

  const backButtonPath = () => {
    return generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
  }

  const validateField = (field, value) => {
    const validationResult = promotionalPageFieldValidation(
      field,
      value,
      formState,
      'edit'
    )

    setFormErrors((prev) => ({ ...prev, ...validationResult }))
  }

  const onChangeField = (field, newValue) => {
    setFormState((prevState) => {
      return { ...prevState, [field]: newValue }
    })

    validateField(field, newValue)
  }

  const updatePage = () => {
    setIsExecutingTask(true)
    updatePromotionalPage(id, formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
        )
        setSuccessMessage(
          `Página promocional '${response[TITLE.value]}' atualizada com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const deletePage = () => {
    setIsExecutingTask(true)
    deletePromotionalPage(formState[KEY.value])
      .then(() => {
        history.replace(
          generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
        )
        setSuccessMessage(
          `Página promocional '${originalTitle}' excluida da com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
        setShowDeleteDialog(false)
      })
  }

  const submitForm = () => {
    const { isValid, errors } = promotionalPageValidation(formState, 'edit')

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }

    updatePage()
  }

  useEffect(() => {
    setIsFetching(true)
    if (!id) return

    getPromotionalPage(id)
      .then((data) => {
        setOriginalTitle(data[TITLE.value])
        setFormState(data)
        setIsFetching(false)
      })
      .catch((err) => {
        setErrorMessage(err.message)
        history.replace(
          generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
        )
      })
  }, [id, history, setErrorMessage, brand])

  return (
    <ContentWithHeader title="Editar página" backButtonRoute={backButtonPath()}>
      {isFetching ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <PromotionalPageForm
            brand={brand}
            formErrors={formErrors}
            formState={formState}
            originalTitle={originalTitle}
            onChange={onChangeField}
          />

          <div
            className={[classes.buttonsContainers, classes.marginTop24].join(
              ' '
            )}
          >
            <Button
              disabled={isExecutingTask}
              color="primary"
              className={classes.deleteButton}
              onClick={() => setShowDeleteDialog(true)}
              startIcon={<DeleteIcon />}
              variant="outlined"
            >
              Excluir página
            </Button>

            <Button
              disabled={isExecutingTask}
              color="primary"
              onClick={submitForm}
              variant="contained"
            >
              Salvar
            </Button>
          </div>
        </>
      )}

      <SnackbarOutlet />

      <Dialog
        open={showDeleteDialog}
        title="Excluir página"
        secondaryButton="cancelar"
        primaryButton="excluir"
        handleClose={() => setShowDeleteDialog(false)}
        handleConfirm={deletePage}
        content="Conteúdo default"
      >
        Ao excluir a página promocional &apos;{originalTitle}&apos;, ela não
        estará mais acessível para os clientes, nem será mantida como histórico.
      </Dialog>
    </ContentWithHeader>
  )
}
