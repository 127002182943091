import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'
import { getCourseSubjectsPagesList } from '../services/course-management-content/use-cases/get-course-subject-list'
import { GeneralContext } from './GeneralContext'
import { addReusableTopics } from '../services/course-management-content/use-cases/post-reusable-topics'
import { allTopicSelector } from '../atoms/course-topics'

const RegisterElearningContext = createContext()

function RegisterElearningProvider({ children }) {
  const [searchSubjectsInCourseTerm, setSearchSubjectsInCourseTerm] =
    useState('')
  const [searchSubjectsInCourseValue, setSearchSubjectsInCourseValue] =
    useState('')
  const [subjectsInCourseList, setSubjectsInCourseList] = useState([])
  const [selectedSubjectsInCourse, selectSubejctsInCourse] = useState([])
  const [searchInAllSubjectsTerm, setSearchInAllSubjectsTerm] = useState('')
  const [searchInAllSubjectsValue, setSearchInAllSubjectsValue] = useState('')
  const [isFetchingInCourse, setIsFetchingInCourse] = useState(true)
  const [isFetchingInAllSubjects, setIsFetchingInAllSubjects] = useState(true)
  const [breadcrumbsPath, setBreadcrumbsPath] = useState([])
  const [relationshipId, setRelationshipId] = useState([])

  const setAllTopics = useSetRecoilState(allTopicSelector)
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)

  const updateIsAvailable = (subjectId, newValue) => {
    const subjectIndex = subjectsInCourseList.findIndex(
      (subject) => subject.key === subjectId
    )

    const newSubjectsList = [...subjectsInCourseList]

    newSubjectsList[subjectIndex].is_available = newValue

    setSubjectsInCourseList(newSubjectsList)
  }

  const updateFullPosted = (subjectId, newValue) => {
    const subjectIndex = subjectsInCourseList.findIndex(
      (subject) => subject.key === subjectId
    )

    const newSubjectsList = [...subjectsInCourseList]

    newSubjectsList[subjectIndex].full_posted = newValue

    setSubjectsInCourseList(newSubjectsList)
  }

  // eslint-disable-next-line consistent-return
  const findSubjectAux = (arr, id) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const sub of arr) {
      if (sub.key === id) return sub
      if (sub.children) {
        const nestedChildren = findSubjectAux(sub.children, id)
        if (nestedChildren) return nestedChildren
      }
    }
  }
  const findSubject = (sub, id) => findSubjectAux([sub], id)

  const handleClickFolder = async (rowId) => {
    setIsFetchingInCourse(true)
    getCourseSubjectsPagesList(searchSubjectsInCourseValue)
      .then((list) => {
        setSubjectsInCourseList(list)
        return list
      })
      .then((list) => {
        if (rowId) {
          const rowClicked = findSubjectAux(list, rowId)
          setBreadcrumbsPath([
            { label: 'Todos', key: null },
            ...rowClicked.path
          ])
          setSubjectsInCourseList(rowClicked.children)
        } else {
          setBreadcrumbsPath([{ label: 'Todos', key: null }])
        }
      })
      .catch(() =>
        setErrorMessage(
          'Não foi possível buscar a lista de disciplinas no curso'
        )
      )
      .finally(() => setIsFetchingInCourse(false))
  }

  const handleReuseTopics = (courseId, relationship) => {
    const payload = { relationship_ids: relationship }
    setIsFetchingInAllSubjects(true)
    addReusableTopics(courseId, payload)
      .then(() => {
        setRelationshipId([])
        setSuccessMessage('Disciplina(s) reaproveitada(s) com sucesso')
        setAllTopics((prev) => ({ ...prev, requestId: Date.now() }))
      })
      .catch(() => {
        setErrorMessage('Erro ao reutilizar disciplina(s)')
      })
      .finally(() => {
        setIsFetchingInAllSubjects(false)
      })
  }

  const removeSubjectFromCourse = (id) => {
    const newSubjectsInCourseList = subjectsInCourseList.filter(
      (sub) => sub.key !== id
    )
    setSubjectsInCourseList(newSubjectsInCourseList)
  }

  return (
    <RegisterElearningContext.Provider
      value={{
        searchSubjectsInCourseTerm,
        setSearchSubjectsInCourseTerm,

        searchSubjectsInCourseValue,
        setSearchSubjectsInCourseValue,

        subjectsInCourseList,
        setSubjectsInCourseList,

        selectedSubjectsInCourse,
        selectSubejctsInCourse,

        searchInAllSubjectsTerm,
        setSearchInAllSubjectsTerm,

        searchInAllSubjectsValue,
        setSearchInAllSubjectsValue,

        isFetchingInCourse,
        setIsFetchingInCourse,

        isFetchingInAllSubjects,
        setIsFetchingInAllSubjects,

        breadcrumbsPath,
        setBreadcrumbsPath,

        relationshipId,
        setRelationshipId,

        updateIsAvailable,
        updateFullPosted,
        findSubject,
        findSubjectAux,
        handleClickFolder,
        handleReuseTopics,
        removeSubjectFromCourse
      }}
    >
      {children}
    </RegisterElearningContext.Provider>
  )
}

RegisterElearningProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { RegisterElearningContext, RegisterElearningProvider }
