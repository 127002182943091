import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { TextInput, SelectSaraiva } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import { DISCOUNT_CAMPAIGN_FIELDS } from '../../../../domain/promotional-content/discounts-campaign'

const { CAMPAIGN_NAME, CAMPAIGN_STATUS } = DISCOUNT_CAMPAIGN_FIELDS

export const CampaignForm = (props) => {
  const { onChange, formState, formErrors } = props
  const classes = useStyles()
  return (
    <>
      <form style={{ width: '100%' }} className={classes.marginTopMedium}>
        <TextInput
          color="primary"
          error={!!formErrors[CAMPAIGN_NAME]}
          helperText={formErrors[CAMPAIGN_NAME]}
          label="Nome da campanha"
          onChange={(ev) => onChange(ev, CAMPAIGN_NAME)}
          size="full"
          required
          value={formState[CAMPAIGN_NAME]}
        />

        <div className={classes.marginTopMedium}>
          <SelectSaraiva
            error={!!formErrors[CAMPAIGN_STATUS]}
            helperText={formErrors[CAMPAIGN_STATUS]}
            label="Status da publicação*"
            onChange={(ev) => onChange(ev, CAMPAIGN_STATUS)}
            required
            size="full"
            value={formState[CAMPAIGN_STATUS]}
          >
            <MenuItem value="">Nenhum valor selecionado</MenuItem>
            <MenuItem value>Em exibição no site</MenuItem>
            <MenuItem value={false}>Inativo</MenuItem>
          </SelectSaraiva>
        </div>
      </form>
    </>
  )
}

CampaignForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    [CAMPAIGN_NAME]: PropTypes.string,
    [CAMPAIGN_STATUS]: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired,
  formErrors: PropTypes.shape({
    [CAMPAIGN_NAME]: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    [CAMPAIGN_STATUS]: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired
}

CampaignForm.defaultProps = {}
