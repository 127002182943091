import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%'
  },
  wrapper: {
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0
    }
  },
  root: {
    '& .MuiTextField-root': {
      backgroundColor: theme.palette.background.default
    }
  }
}))

export default useStyles
