import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { Tabs } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { ContainerWithLoader } from '../../../components/ContainerWithLoader'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { getDetailsVouchers } from '../../../services/vouchers'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../hooks/use-required-param-access'
import { VOUCHER_FIELDS } from '../../../domain/vouchers/voucher-fields'
import { VoucherDetails } from './VoucherDetails'
import { VoucherDetailsVoucherTable } from './VoucherDetailsVouchersTable'

const { CAMPAIGN_NAME } = VOUCHER_FIELDS

export const VouchersEdit = () => {
  const classes = useStyles()
  const [uiControls, setUiControls] = useState({ isFetching: true })
  const [detailsData, setDetailsData] = useState({
    [CAMPAIGN_NAME]: ''
  })

  useRequiredParamsAccess('brand', RoutesMapping.vouchersLegacyEntryPoint)
  const { id } = useRequiredParamsAccess(
    'id',
    RoutesMapping.vouchersLegacyEntryPoint
  )

  useEffect(() => {
    getDetailsVouchers(id)
      .then((dataFromService) => {
        setDetailsData((prev) => ({ ...prev, ...dataFromService }))
      })
      .catch(() => {})
      .finally(() => {
        setUiControls((prev) => ({ ...prev, isFetching: false }))
      })
  }, [id])

  return (
    <ContentWithHeader
      backButtonRoute={RoutesMapping.vouchersLegacyEntryPoint}
      title={
        uiControls.isFetching ? '' : `Voucher ${detailsData[CAMPAIGN_NAME]}`
      }
    >
      <ContainerWithLoader isLoading={uiControls.isFetching}>
        <Paper>
          <Tabs screenSize="desktop" color="primary" showDivider>
            {[
              {
                label: 'Detalhes',
                content: (
                  <>
                    <Divider />
                    <div className={classes.tabContent}>
                      <VoucherDetails detailsData={detailsData} />
                    </div>
                  </>
                )
              },
              {
                label: 'Vouchers',
                content: (
                  <>
                    <Divider />
                    <div className={classes.tabContent}>
                      <VoucherDetailsVoucherTable detailsData={detailsData} />
                    </div>
                  </>
                )
              }
            ]}
          </Tabs>
        </Paper>
      </ContainerWithLoader>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}

VouchersEdit.propTypes = {}

VouchersEdit.defaultProps = {}
