import React, { useState } from 'react'
import { CardExtraContent } from '@equipe-ninja/saraiva-ui'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import DevicesIcon from '@material-ui/icons/Devices'
import useStyles from './styles'
import { useHistory } from 'react-router-dom'
import { RoutesMapping } from '../../../helpers/routes-mapping'

export function SsoUserSelect() {
  const classes = useStyles()
  const history = useHistory()

  const [showComponent, setShowComponent] = useState(false)

  const handleGenerateUserAccess = () => {
    setShowComponent(true)
  }
  const returnGenerateUserAccess = () => {
    setShowComponent(false)
  }
  return (
    <>
    <div className={classes.container}>
        <CardExtraContent
          onClick={() => history.push(RoutesMapping.userData)}
          title="Dados dos usuários"
          hoverTitle="Acessar"
          icon={<PermIdentityIcon style={{ fontSize: 100 }} />}
          copyIcon={false} />
        <div className={classes.cardSpacing}>
          <CardExtraContent
            onClick={() => history.push(RoutesMapping.simUser)}
            title="Simulação de conta"
            hoverTitle="Acessar"
            icon={<DevicesIcon style={{ fontSize: 100 }} />}
            copyIcon={false} />
            
        </div>
      </div>
    </>
  )
}