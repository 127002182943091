import {
  BANNER_FIELDS as LIST_FIELDS,
  BANNER_REMOTE_FIELDS as REMOTE_FIELDS
} from '../../../../domain/promotional-content/banners'
import { formatGMTDate } from '../../../../helpers/formatDate'

export const listItemAdapterInbound = (remoteDataList) => {
  const session = {
    HOME: 2
  }
  return remoteDataList.map((remoteData) => ({
    [LIST_FIELDS.KEY.value]: remoteData[REMOTE_FIELDS.KEY],
    [LIST_FIELDS.TYPE.value]: remoteData[REMOTE_FIELDS.TYPE],
    [LIST_FIELDS.TITLE.value]: remoteData[REMOTE_FIELDS.TITLE],
    [LIST_FIELDS.SESSION.value]:
      remoteData[REMOTE_FIELDS.SESSION] === session.HOME ? 'Home' : '',
    [LIST_FIELDS.URL.value]: [
      remoteData[REMOTE_FIELDS.URL],
      remoteData[REMOTE_FIELDS.URL_2],
      remoteData[REMOTE_FIELDS.URL_3],
      remoteData[REMOTE_FIELDS.URL_4]
    ],
    [LIST_FIELDS.START_DATE.value]: formatGMTDate(
      remoteData[REMOTE_FIELDS.START_DATE]
    ),
    [LIST_FIELDS.END_DATE.value]: formatGMTDate(
      remoteData[REMOTE_FIELDS.END_DATE]
    ),
    [LIST_FIELDS.STATUS.value]:
      remoteData[REMOTE_FIELDS.STATUS] === true ? 'ativo' : 'inativo',
    [LIST_FIELDS.ORDER.value]: [
      remoteData[REMOTE_FIELDS.ORDER],
      remoteData[REMOTE_FIELDS.ORDER_2],
      remoteData[REMOTE_FIELDS.ORDER_3],
      remoteData[REMOTE_FIELDS.ORDER_4]
    ],
    [LIST_FIELDS.IMAGE_WEB.value]: remoteData[REMOTE_FIELDS.IMAGE_WEB],
    [LIST_FIELDS.IMAGE_RECURSIVE.value]: [
      remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE],
      remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE_2],
      remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE_3],
      remoteData[REMOTE_FIELDS.IMAGE_RECURSIVE_4]
    ]
  }))
}
