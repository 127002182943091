import Joi from 'joi'
import { VOUCHER_FIELDS as F } from '../index'
import {
  validateAndGenerateErrors,
  validateSingleFieldAndGenerateErrors
} from '../../../helpers/validation'

const schema = Joi.object({
  [F.CAMPAIGN_NAME]: Joi.string().required(),
  [F.PREFIX]: Joi.string().required(),
  [F.DISCOUNT_VALUE]: Joi.number().min(0).max(100).required().messages({
    'number.base': 'Campo Obrigatório',
    'number.max': 'Valor máximo: 100'
  }),
  [F.VOUCHER_AMOUNT]: Joi.number().greater(0).required().messages({
    'number.base': 'Campo Obrigatório',
    'number.greater': 'Deve ser maior que 0'
  }),
  [F.USES_PER_VOUCHER]: Joi.number().greater(0).required().messages({
    'number.base': 'Campo Obrigatório',
    'number.greater': 'Deve ser maior que 0'
  }),
  [F.START_DATE]: Joi.date().required(),
  [F.END_DATE]: Joi.date().min(Joi.ref(F.START_DATE)).required().messages({
    'date.min': 'Deve ser posterior a data de início'
  }),
  [F.STATUS]: Joi.boolean().required().messages({
    'boolean.base': 'Campo Obrigatório'
  })
})

export const voucherValidation = (formValues) => {
  return validateAndGenerateErrors(formValues, schema)
}

export const voucherFieldValidation = (field, value, formValues) => {
  return validateSingleFieldAndGenerateErrors(field, value, formValues, schema)
}
