/* eslint-disable array-callback-return */
import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para remocao de uma lista de vouchers
 * @param {*} - Lista de vouchers que devem ser removidos
 * @returns promise que resolve para sucesso e rejeita para falha
 */
const deleteVouchers = async (voucherIdList) => {
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const responses = await Promise.all(
    voucherIdList.map((id) => {
      const path = `/tickets/api/v1/tickets/ecommerce/${id}`
      return httpClient.delete(path, null, headers)
    })
  )
  return responses
}

export { deleteVouchers }
