export const CourseRelationshipFields = Object.freeze({
  ID: 'id',
  COURSE_ID: 'course_id',
  ACTIVE: 'active',
  REUSE: 'reuse',
  SUBJECT_ID: 'subject_id',
  THEME_ID: 'theme_id',
  CLASS_ID: 'class_id',
  CONTENT_ID: 'content_id',
  AVAILABLE: 'available'
})
