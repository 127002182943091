export const typeAdapter = (LOCAL, REMOTE, typeName, defaultValue) => {
  const defaultValues = { inbound: null, outbound: null }
  const emptyValues = { ...defaultValues, ...defaultValue }

  const inbound = (type) => {
    if (type === null) {
      return emptyValues.inbound
    }

    const [key] = Object.entries(REMOTE).find(
      (keyValueArr) => keyValueArr[1] === type
    )

    if (!key) {
      throw new Error(
        `${type} is not a valid remote value for ${typeName} type`
      )
    }

    return LOCAL[key]
  }

  const outbound = (type) => {
    if (type === null) {
      return emptyValues.outbound
    }

    const [key] = Object.entries(LOCAL).find(
      (keyValueArr) => keyValueArr[1] === type
    )

    if (!key) {
      throw new Error(`${type} is not a valid local value for ${typeName} type`)
    }

    return REMOTE[key]
  }

  return {
    inbound,
    outbound
  }
}
