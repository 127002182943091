/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'

import { ConnectionsContext } from '../../contexts/ConnectionsContext'

const ConfirmModal = ({
  handleCheck,
  disabled,
  handleConfirm,
  handleClickClose
}) => {
  const { showingConnectionForm, toggleConnectionForm } =
    useContext(ConnectionsContext)

  const handleClose = () => {
    toggleConnectionForm(false)
    handleClickClose()
  }

  const onClickConfirm = () => {
    toggleConnectionForm(false)
    handleConfirm()
  }

  return (
    <Dialog
      open={showingConnectionForm}
      size="md"
      title="Editar Informações"
      secondaryButton="cancelar"
      primaryButton="Editar"
      handleClose={handleClose}
      handleConfirm={onClickConfirm}
      label="Sim tenho certeza!"
      alertMessage
      handleCheck={() => handleCheck()}
      isDisabled={disabled}
    >
      Você tem certeza que deseja editar essa(s) informaç(ões)?
    </Dialog>
  )
}

export default ConfirmModal
