import React, { useState } from 'react'

import { Checkbox, Divider, Typography } from '@material-ui/core'
import {
  Alert,
  Button,
  DatePickerSaraiva,
  TextInput
} from '@equipe-ninja/saraiva-ui'
import { Add, Delete } from '@material-ui/icons'

import useStyles from './styles'
import AccessLink from '../../../../services/accessLinkService'

export const ConectaVouchers = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  // Inputs
  const [inputs, setInputs] = useState([''])
  const [accessLink, setAccessLink] = useState('')

  // Checkbox
  const [checked, setChecked] = useState(false)

  // Date Picker
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  // Alert
  const [openAlert, setOpentAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)

  // Disabled
  const [isValid, setIsValid] = useState({
    caeError: false,
    startError: false,
    endError: false
  })

  const fetchAccessLink = async () => {
    const endDateChecked = checked === false ? null : endDate
    const startDateChecked = checked === false ? null : startDate

    const payload = {
      count: 1,
      cae_ids: inputs,
      is_campaign: checked,
      campaign_start_date: startDateChecked,
      campaign_end_date: endDateChecked
    }

    try {
      setLoading(true)

      if (
        (inputs.includes('') && checked === false) ||
        (checked && (startDate === '' || endDate === '' || inputs.includes('')))
      ) {
        setIsValid((prev) => ({
          ...prev,
          caeError:
            (inputs.includes('') && checked === false) ||
            (checked && inputs.includes('')),
          startError: checked && startDate === '',
          endError: checked && endDate === ''
        }))

        setErrorAlert(true)

        setTimeout(() => {
          setErrorAlert(false)
        }, 5000)

        return
      }

      const response = await AccessLink.createAccessLink(payload)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()
      setAccessLink(result[0].link)
      setOpentAlert(true)

      setLoading(false)

      setTimeout(() => {
        setOpentAlert(false)
      }, 5000)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleInputChange = (index, value) => {
    setIsValid((prev) => ({ ...prev, caeError: false }))
    const updatedInputs = [...inputs]
    updatedInputs[index] = Number(value) || ''
    setInputs(updatedInputs)
  }

  const handleAddInput = () => {
    setInputs([...inputs, ''])
  }

  const handleRemoveInput = (index) => {
    if (inputs.length > 1) {
      const updatedInputs = [...inputs]
      updatedInputs.splice(index, 1)
      setInputs(updatedInputs)
    }
  }

  const handleStartDate = (str) => {
    setIsValid((prev) => ({ ...prev, startError: false }))
    setInitialDate(str)
    const date = new Date(str)
    const mnth = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const newInitialDate = [day, mnth, date.getFullYear()].join('-')

    setStartDate((prev) => {
      if (prev !== newInitialDate) {
        return newInitialDate
      }
      return prev
    })
  }

  const handleEndDate = (str) => {
    setIsValid((prev) => ({ ...prev, endError: false }))
    setFinalDate(str)
    const date = new Date(str)
    const mnth = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const newFinalDate = [day, mnth, date.getFullYear()].join('-')

    setEndDate((prev) => {
      if (prev !== newFinalDate) {
        return newFinalDate
      }
      return prev
    })
  }

  const handleClean = () => {
    setChecked(false)
    setInputs([''])
    setStartDate(null)
    setEndDate(null)
  }

  const handleCancel = () => {
    setChecked(false)
    setInputs([''])
    setStartDate(null)
    setEndDate(null)
    setAccessLink('')
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(accessLink)
  }

  return (
    <section>
      {openAlert === true && (
        <div className={classes.alert}>
          <Alert severity="success">Link criado com sucesso. </Alert>
        </div>
      )}

      {errorAlert === true && (
        <div className={classes.alert}>
          <Alert severity="error">
            Campos obrigatórios a serem preenchidos.
          </Alert>
        </div>
      )}

      <Typography variant="h4" className={classes.sectionTitle}>
        Saraiva Conecta
      </Typography>

      <div className={classes.checkboxContainer}>
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          onClick={() => setChecked(!checked)}
        />
        <Typography>
          Se você está criando um link para uma campanha, selecionar o
          check-box.
        </Typography>
      </div>

      <div className={classes.caeContainer}>
        <Typography variant="body1" className={classes.sectionText}>
          Digite no campo abaixo o CAE do(s) produto(s):
        </Typography>
        {inputs.map((input, index) => (
          <div className={classes.caeInputBox} key={index}>
            <TextInput
              label="CAE*"
              value={input}
              className={classes.caeInputBox}
              onChange={(e) => handleInputChange(index, e.target.value)}
              error={isValid.caeError}
              helperText={isValid.caeError === true ? 'Campo Obrigatório' : ''}
            />
            {index !== 0 && (
              <Button
                className={classes.buttonX}
                onClick={() => handleRemoveInput(index)}
                startIcon={<Delete />}
              />
            )}
          </div>
        ))}

        <Button
          color="primary"
          startIcon={<Add />}
          className={classes.addInputButton}
          onClick={handleAddInput}
          disabled={accessLink !== ''}
        >
          {inputs.length <= 1 ? 'ADICIONAR MAIS' : 'ADICIONAR MAIS CAMPOS'}
        </Button>
      </div>

      {checked !== false && (
        <div className={classes.campaignContainer}>
          <div className={classes.dataPikerBox}>
            <DatePickerSaraiva
              label="Data de início"
              value={initialDate}
              onChange={handleStartDate}
              error={isValid.startError}
              helperText={
                isValid.startError === true ? 'Campo Obrigatório' : ''
              }
            />

            <div style={{ marginLeft: '24px' }}>
              <DatePickerSaraiva
                label="Data do fim"
                value={finalDate}
                onChange={handleEndDate}
                error={isValid.endError}
                helperText={
                  isValid.endError === true ? 'Campo Obrigatório' : ''
                }
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ marginBottom: '56px' }}>
        <Button
          className={classes.linkButton}
          color="primary"
          variant="contained"
          onClick={fetchAccessLink}
          disabled={accessLink !== ''}
        >
          CRIAR LINK(S)
        </Button>

        <Button
          className={classes.cancelButton}
          color="primary"
          variant="outlined"
          onClick={handleClean}
          disabled={accessLink !== ''}
        >
          Cancelar
        </Button>
      </div>
      <Divider style={{ marginBottom: '24px' }} />

      <div>
        <Typography variant="h5" className={classes.exportTitle}>
          Links de acesso
        </Typography>

        <TextInput
          label="Link de acesso"
          size="md"
          value={accessLink}
          className={classes.accessLinkInput}
        />
        <Button
          className={classes.linkButton}
          color="primary"
          variant="contained"
          onClick={handleCopyLink}
          disabled={accessLink === ''}
        >
          COPIAR LINK
        </Button>

        <Button
          className={classes.cancelButton}
          color="primary"
          variant="outlined"
          onClick={handleCancel}
          disabled={accessLink === ''}
        >
          Cancelar
        </Button>
      </div>
    </section>
  )
}
