import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { postResquestResponseHandler } from '../../../utils/response-handlers'
import { PromoPageDetailsAdapter } from '../adapters/promo-page-details-adapter'

/**
 * Caso de uso para criacao de paginas promocionais
 * @returns lista de paginas promocionais apos passar por um adaptador de entrada
 */
const createPromotionalPage = (formValues) => {
  return new Promise((resolve, reject) => {
    const path = 'markets/api/v1/pages'
    const payload = PromoPageDetailsAdapter.outbound(formValues)
    const headers = httpClient.buildHeader({
      'session-token': getSessionToken()
    })

    httpClient
      .post(path, payload, headers)
      .then((response) => postResquestResponseHandler(response))
      .then((json) => {
        resolve(PromoPageDetailsAdapter.inbound(json.data))
      })
      .catch((error) => reject(error))
  })
}

export { createPromotionalPage }
