import React, { useState, useRef } from 'react'
import { Paper, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { generatePath } from 'react-router-dom'
import ContentTitle from '../../../../components/ContentTitle'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import useStyles from '../styles'
import { CreateBanner } from './CreateBanner'
import { CreateBannerSale } from './CreateBannerSale'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'

export function Create() {
  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const [tipoBanner, setTipoBanner] = useState('none')
  const [formSharedState, setFormSharedState] = useState({})

  const classes = useStyles()
  const node = useRef()

  const handleSetForm = (field, dateValue) => {
    setFormSharedState({ ...formSharedState, [field]: dateValue })
  }

  return (
    <ContentWithHeader
      title="Criar Banner"
      backButtonRoute={generatePath(RoutesMapping.promotionalBannerList, {
        brand
      })}
    >
      <ContentTitle title="Tipo de banner" size="small" />
      <Paper ref={node} className={classes.paper}>
        <RadioGroup
          style={{ flexDirection: 'row', paddingLeft: 12, margin: 0 }}
          value={tipoBanner}
          onChange={(e) => setTipoBanner(e.target.value)}
        >
          <FormControlLabel
            value="rotary"
            control={<Radio size="small" />}
            label="Rotativo"
          />
          <FormControlLabel
            value="sale"
            control={<Radio size="small" />}
            label="Ofertas em destaque"
          />
        </RadioGroup>
      </Paper>
      {tipoBanner === 'rotary' && (
        <CreateBanner state={formSharedState} onChange={handleSetForm} />
      )}
      {tipoBanner === 'sale' && (
        <CreateBannerSale state={formSharedState} onChange={handleSetForm} />
      )}
    </ContentWithHeader>
  )
}
