import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { ReusableTopicsAdapter } from '../adapters/reusable-topic'
import { getResquestResponseHandler } from '../../utils/response-handlers'

/* eslint-disable no-unused-vars */

/**
 * Caso de uso para carregamento de lista de disciplinas cadastradas no banco
 * na tela de cadastro de curso no ambiente de aprendizagem
 * @returns lista de disciplinas cadastradas em banco
 */

const getAllSubjectsPagesList = async (
  courseId,
  search = '',
  perPage,
  page
) => {
  const path = `/offers/api/v1/backoffice/course/${courseId}/reusable_topics?search=${search}&per_page=${perPage}&page=${
    page + 1
  }`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)

  const data = await getResquestResponseHandler(response)
  const adaptedData = ReusableTopicsAdapter.inbound(data.data)

  return [adaptedData, data.total_count]
}

export { getAllSubjectsPagesList }
