import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import AddIcon from '@material-ui/icons/Add'
import useStyles from './styles.js'
import SelectSubjectsTable from './SelectSubjectsTable'
import AdditionalContent from './AdditionalContent'
import { SnackbarOutlet } from '../../components/SnackbarOutlet'

export default function Ies() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { goBack } = history

  const [ies, setIes] = useState({})

  useEffect(() => {
    setIes(location.state.ies)
  }, [location])

  const [showDialog, setShowDialog] = useState(false)
  const handleOpenDialogAdditionalContent = () => {
    setShowDialog(true)
  }

  const handleCloseDialogAdditionalContent = () => {
    setShowDialog(false)
  }
  return (
    <>
      <div className={classes.root}>
        <SnackbarOutlet />

        <Button
          className={classes.backButton}
          color="primary"
          onClick={() => {
            if (localStorage.getItem('lastAccessFromBo')) {
              localStorage.removeItem('lastAccessFromBo')
              window.location.assign('/')
            } else {
              goBack()
            }
          }}
          startIcon={<LeftArrowIcon />}
        >
          Voltar
        </Button>
        <div className={classes.content}>
          <Button
            className={classes.addButton}
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialogAdditionalContent()}
          >
            ADICIONAR CONTEÚDO
          </Button>
          <Typography variant="h4" className={classes.titleIes}>
            {ies.client_name}
          </Typography>
        </div>

        <SelectSubjectsTable />
      </div>
      <AdditionalContent
        setShowDialog={handleCloseDialogAdditionalContent}
        showDialog={showDialog}
      />
    </>
  )
}
