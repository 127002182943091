import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Button, Dialog } from '@equipe-ninja/saraiva-ui'

import { useHistory, useParams } from 'react-router-dom'
import { useStyles } from './styles'
import { DialogComplementaryMaterialManagement } from '../../../ComplementaryMaterials/components/DialogComplementaryMaterialManagement'
import { GeneralContext } from '../../../../../../contexts/GeneralContext'
import { getComplementaryMaterialById } from '../../../../../../services/complementary-material'
import { deleteComplementaryMaterial } from '../../../../../../services/complementary-material/use-cases/delete-complementary-material'

/**
 *
 * @param {{
 * material: {
 *  id: number;
 *  name: string;
 *  order: number;
 *  shortDescription: string;
 *  fullDescription: string
 * }
 * }} props
 * @returns {import('react').FC}
 */
export const DetailsTab = ({ material }) => {
  const { materialId } = useParams()
  const history = useHistory()
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const styles = useStyles()
  const [materialDetails, setMaterialDetails] = useState(material)
  const [isEditMaterialDialogOpen, setIsEditMaterialDialogOpen] =
    useState(false)
  const [isDeleteMaterialDialogOpen, setIsDeleteMaterialDialogOpen] =
    useState(false)

  const openEditMaterialDialog = () => setIsEditMaterialDialogOpen(true)

  const closeEditMaterialDialog = () => {
    setIsEditMaterialDialogOpen(false)
    setTimeout(async () => {
      try {
        const responseMaterial = await getComplementaryMaterialById(materialId)
        setMaterialDetails(responseMaterial)
      } catch (error) {
        setErrorMessage('Erro ao buscar material')
      }
    }, 1000)
  }

  const openDeleteMaterialDialog = () => setIsDeleteMaterialDialogOpen(true)
  const closeDeleteMaterialDialog = () => setIsDeleteMaterialDialogOpen(false)

  const handleDeleteMaterial = async () => {
    try {
      await deleteComplementaryMaterial(materialDetails.id)
      setSuccessMessage(`Material ${materialDetails.id} excluído com sucesso.`)
      closeDeleteMaterialDialog()
      history.goBack()
    } catch (error) {
      setErrorMessage('Erro ao excluir material')
    }
  }

  return (
    <>
      <div className={styles.tabContainer}>
        <div>
          <Typography className={styles.heading}>Nome</Typography>
          <Typography>{materialDetails.name}</Typography>
        </div>
        <div>
          <Typography className={styles.heading}>Descrição breve</Typography>
          <Typography>{materialDetails.shortDescription}</Typography>
        </div>
        <div>
          <Typography className={styles.heading}>Descrição completa</Typography>
          <Typography>{materialDetails.fullDescription}</Typography>
        </div>
        <div>
          <Typography className={styles.heading}>Ordem de exibição</Typography>
          <Typography>{materialDetails.order}</Typography>
        </div>

        <div className={styles.divCta}>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Edit />}
            onClick={openEditMaterialDialog}
          >
            ALTERAR MATERIAL
          </Button>

          <Button
            className={styles.btnDanger}
            variant="outlined"
            startIcon={<Delete />}
            onClick={openDeleteMaterialDialog}
          >
            EXCLUIR MATERIAL
          </Button>
        </div>
      </div>

      <DialogComplementaryMaterialManagement
        isOpen={isEditMaterialDialogOpen}
        handleOpen={openEditMaterialDialog}
        handleClose={closeEditMaterialDialog}
        mode="edit"
        materialData={material}
      />

      <Dialog
        title="Excluir material"
        secondaryButton="CANCELAR"
        primaryButton="CONFIRMAR"
        size="md"
        handleClose={closeDeleteMaterialDialog}
        handleConfirm={handleDeleteMaterial}
        open={isDeleteMaterialDialogOpen}
        label="Sim, tenho certeza!"
        alert
      >
        Você tem certeza que deseja excluir este material? Após excluir ele não
        poderá mais ser acessado pelos alunos.
      </Dialog>
    </>
  )
}

DetailsTab.propTypes = {
  material: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired
}
