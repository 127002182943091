import React from 'react'
import { Breadcrumbs } from '@material-ui/core'
import { useRecoilState } from 'recoil'
import { NavigateNext } from '@material-ui/icons'
import { Button } from '@equipe-ninja/saraiva-ui'
import useStyles from '../../../styles'
import { selectedTopicsDataSelector } from '../../../../../atoms/course-topics'

export const SubjectBreadcrumb = () => {
  const [{ breadcrumbs }, setState] = useRecoilState(selectedTopicsDataSelector)
  const classes = useStyles()

  const handleClick = (key) => {
    setState((prev) => ({ ...prev, selectedTopicId: key, subjectSearch: '' }))
  }

  return (
    <Breadcrumbs separator={<NavigateNext />} className={classes.breadcrumbs}>
      {breadcrumbs.map((step) => (
        <Button
          key={step.value}
          color="primary"
          onClick={async () => handleClick(step.value)}
        >
          {step.label}
        </Button>
      ))}
    </Breadcrumbs>
  )
}
