import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import { Tabs } from '@equipe-ninja/saraiva-ui'
import { BANNER_PAGES_LIST_ITEM_FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import CardRotary from './CardRotary'
import useStyles from '../styles'
import CardSale from './CardSale'

export function Home({ bannersAtivos, bannersInativos, tipoBanner }) {
  const classes = useStyles()
  return (
    <div style={{ marginTop: 48 }}>
      <Tabs screenSize="desktop" color="primary" variant="fullWidth">
        {[
          {
            label: 'EM EXIBIÇÃO NO SITE',
            content:
              bannersAtivos.length === 0 ? (
                <>
                  <Divider />
                  <div className={classes.contentContainer}>
                    Nenhum banner em exibição no momento
                  </div>
                </>
              ) : (
                <>
                  <Divider />
                  {tipoBanner === 'rotary' && (
                    <CardRotary banners={bannersAtivos} typeBanner="ativos" />
                  )}
                  {tipoBanner === 'sale' && (
                    <CardSale banners={bannersAtivos} status="ativos" />
                  )}
                </>
              )
          },
          {
            label: 'INATIVOS',
            content:
              bannersInativos.length === 0 ? (
                <div className={classes.contentContainer}>
                  Aqui aparecerá o histório dos banners que não estão em
                  exibição no site.
                </div>
              ) : (
                <>
                  <Divider />
                  {tipoBanner === 'rotary' && (
                    <CardRotary
                      banners={bannersInativos}
                      typeBanner="inativos"
                    />
                  )}
                  {tipoBanner === 'sale' && (
                    <CardSale banners={bannersInativos} status="inativos" />
                  )}
                </>
              )
          }
        ]}
      </Tabs>
    </div>
  )
}

Home.propTypes = {
  bannersAtivos: PropTypes.arrayOf(BANNER_PAGES_LIST_ITEM_FIELDS),
  bannersInativos: PropTypes.arrayOf(BANNER_PAGES_LIST_ITEM_FIELDS),
  tipoBanner: PropTypes.string.isRequired
}

Home.defaultProps = {
  bannersAtivos: [],
  bannersInativos: []
}
