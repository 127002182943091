import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import {
  detailsAdapterInbound,
  editVoucherAdapter
} from '../adapters/vouchers-adapters'
import { VOUCHER_FIELDS as FIELDS } from '../../../domain/vouchers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para edicao de status/datas de um voucher
 * @returns promive com os valores atualizados ou rejeita com erro
 */
const editStatusVouchers = (formValues) => {
  return new Promise((resolve, reject) => {
    const path = `/tickets/api/v1/tickets/ecommerce/${formValues[FIELDS.ID]}`
    const payload = editVoucherAdapter(formValues)
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .put(path, payload, headers)
      .then((response) => {
        resolve(detailsAdapterInbound(response))
      })
      .catch((error) => reject(error))
  })
}
/* eslint-enable no-unused-vars */

export { editStatusVouchers }
