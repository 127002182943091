import React, { useEffect, useState, useCallback } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { Tabs } from '@equipe-ninja/saraiva-ui'
import { Card } from '@material-ui/core'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { CampaignDetails } from './components/CampaignDetails'
import { CampaignDiscountList } from './components/CampaignDiscountList'
import { getDiscountCampaignDetails } from '../../../../services/promotional-content/campaign-discounts'
import { DISCOUNT_CAMPAIGN_FIELDS } from '../../../../domain/promotional-content/discounts-campaign'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'

const { CAMPAIGN_DATA, CAMPAIGN_NAME, CAMPAIGN_STATUS, ID } =
  DISCOUNT_CAMPAIGN_FIELDS

export const DiscountCampaignDetails = () => {
  const { brand, id } = useParams()
  const [isFetching, setIsFetching] = useState(false)
  const [details, setDetails] = useState(null)

  const campaignEditHandler = (updateCampaign) => {
    setDetails((prev) => ({ ...prev, ...updateCampaign }))
  }

  const fetchDetail = useCallback((campaignId) => {
    setIsFetching(true)
    getDiscountCampaignDetails(campaignId)
      .then((data) => {
        setDetails(data)
      })
      .catch(() => {
        return null
      })
      .finally(() => {
        setIsFetching(false)
      })
  }, [])

  useEffect(() => {
    fetchDetail(id)
  }, [fetchDetail, id])

  return (
    <ContentWithHeader
      titleSize="medium"
      title={details?.[CAMPAIGN_NAME]}
      backButtonRoute={generatePath(RoutesMapping.promotionalDiscountList, {
        brand
      })}
    >
      <ContainerWithLoader isLoading={isFetching}>
        <Card>
          <Tabs screenSize="desktop" showDivider>
            {[
              {
                label: 'DETALHES',
                content: (
                  <>
                    <CampaignDetails
                      name={details?.[CAMPAIGN_NAME]}
                      status={details?.[CAMPAIGN_STATUS]}
                      campaignId={details?.[ID]}
                      onEdit={campaignEditHandler}
                    />
                  </>
                )
              },
              {
                label: 'DESCONTOS',
                content: (
                  <>
                    <CampaignDiscountList
                      data={details?.[CAMPAIGN_DATA] || []}
                    />
                  </>
                )
              }
            ]}
          </Tabs>
        </Card>
      </ContainerWithLoader>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}

DiscountCampaignDetails.propTypes = {}

DiscountCampaignDetails.defaultProps = {}
