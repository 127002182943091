import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 72,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  text: {
    marginTop: '40px',
    marginBottom: '24px',
    color: theme.palette.text.primary
  },
  results: {
    fontSize: '20px'
  },
  textH4: {
    fontSize: '34px'
  },
  textH3: {
    marginTop: '22px',
    fontSize: '48px'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 12
  }
}))

export default useStyles
