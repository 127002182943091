import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Button } from '@equipe-ninja/saraiva-ui/core/button/Button'
import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { Dialog } from '@equipe-ninja/saraiva-ui/core/dialog/Dialog'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/GetApp'
import Divider from '@material-ui/core/Divider'
import { debounce } from 'lodash'
import { VouchersTable } from '../VouchersTable'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import useStyles from './styles'
import { VOUCHER_LIST_ITEM_FIELDS } from '../../../../domain/vouchers'
import {
  getVouchersList,
  deleteVouchers,
  exportVouchers
} from '../../../../services/vouchers'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { useDownloadFile } from '../../../../hooks/use-download-file'
import { RoutesMapping } from '../../../../helpers/routes-mapping'

const { ID, CAMPAIGN_NAME } = VOUCHER_LIST_ITEM_FIELDS

export const VouchersSection = ({ brandLabel, brandIdentifier }) => {
  const classes = useStyles()
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [uiControls, setUiControls] = useState({ isExecutingAction: false })
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const { downloadFile } = useDownloadFile()
  const [tableData, setTableData] = useState({
    vouchers: [],
    isFetching: false,
    selectedRows: []
  })

  const anotateListItemWithRedirect = useCallback(
    (list) => {
      return list.map((item) => ({
        ...item,
        redirectTo: {
          fromColumn: CAMPAIGN_NAME,
          path: generatePath(RoutesMapping.vouchersEdit, {
            brand: brandIdentifier,
            id: item[ID]
          })
        }
      }))
    },
    [brandIdentifier]
  )

  const navigateToAddVouchers = () => {
    history.push(
      generatePath(RoutesMapping.vouchersCreate, { brand: brandIdentifier })
    )
  }

  const onCheckRows = (rows) => {
    setTableData((prevState) => ({
      ...prevState,
      selectedRows: rows
    }))
  }

  const fetchVouchers = useCallback(
    (term) => {
      setTableData((prevState) => ({ ...prevState, isFetching: true }))

      getVouchersList(term)
        .then((vouchers) => {
          setTableData((prevState) => ({
            ...prevState,
            selectedRows: [],
            vouchers: anotateListItemWithRedirect(vouchers)
          }))
        })
        .catch(() => {
          setErrorMessage('Não foi possível buscar a lista de vouchers')
        })
        .finally(() => {
          setTableData((prevState) => ({ ...prevState, isFetching: false }))
        })
    },
    [setErrorMessage, setTableData, anotateListItemWithRedirect]
  )

  const deleteSelectedVouchers = () => {
    setShowDialog(false)

    const voucherIds = tableData.selectedRows.map((e) => e[ID])

    if (!voucherIds) {
      setErrorMessage('Nenhum voucher selecionado')
      return
    }

    setUiControls((prev) => ({ ...prev, isExecutingAction: true }))

    deleteVouchers(voucherIds)
      .then(() => {
        fetchVouchers('')
        setSuccessMessage('Vouchers removidos com sucesso')
      })
      .catch(() => {
        setErrorMessage('Não foi possível remover os vouchers selecionados')
      })
      .finally(() =>
        setUiControls((prev) => ({ ...prev, isExecutingAction: false }))
      )
  }

  const debounceFetch = useRef(
    debounce((term) => {
      fetchVouchers(term)
    }, 800),
    []
  ).current

  const handleSearchValue = (event) => {
    const newValue = event?.target?.value
    setSearchTerm(event?.target?.value)

    debounceFetch(newValue)
  }

  const clearSearch = () => {
    setSearchTerm('')
    fetchVouchers('')
  }

  const exportSelectedVouchers = () => {
    if (uiControls.isExecutingAction) return

    setUiControls((prev) => ({ ...prev, isExecutingAction: true }))

    const vouchersIds = tableData.selectedRows.map((e) => e[ID])

    exportVouchers(vouchersIds)
      .then((data) => {
        downloadFile(
          data,
          `vouchers_${new Date()
            .toLocaleString('pt-br')
            .replaceAll(/[/ :]/g, '_')}.csv`
        )
      })
      .catch(() => {
        setErrorMessage('Não foi possível exportar os dados selecionados')
      })
      .finally(() => {
        setUiControls((prev) => ({ ...prev, isExecutingAction: false }))
      })
  }

  useEffect(() => {
    fetchVouchers()
  }, [fetchVouchers])

  useEffect(() => {
    if (tableData.selectedRows.length > 0) {
      return setUiControls((prev) => ({ ...prev, isExecutingAction: false }))
    }
    return setUiControls((prev) => ({ ...prev, isExecutingAction: true }))
  }, [tableData.selectedRows])

  return (
    <section>
      <Typography variant="h4" className={classes.sectionTitle}>
        {brandLabel}
      </Typography>

      <Button
        color="primary"
        variant="contained"
        onClick={() => navigateToAddVouchers()}
      >
        ADICIONAR VOUCHERS
      </Button>

      <Divider className={classes.sectionDivider} />

      <Typography variant="h5" className={classes.subsectionTitle}>
        Vouchers
      </Typography>

      <div className={classes.actionsSection}>
        <div style={{ width: '416px' }}>
          <SearchBar
            value={searchTerm}
            onChange={handleSearchValue}
            placeholder="Buscar por voucher"
            size="full"
            clearSearch={clearSearch}
            goBackSearch={clearSearch}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          />
        </div>

        <div>
          <Button
            disabled={uiControls.isExecutingAction}
            startIcon={<DeleteIcon />}
            color="primary"
            variant="outlined"
            onClick={() => setShowDialog(true)}
          >
            EXCLUIR
          </Button>

          <Button
            disabled={uiControls.isExecutingAction}
            className={classes.marginLeftSmall}
            startIcon={<DownloadIcon />}
            color="primary"
            variant="contained"
            onClick={exportSelectedVouchers}
          >
            EXPORTAR
          </Button>
        </div>
      </div>

      <ContainerWithLoader
        className={classes.marginTopMedium}
        isLoading={tableData.isFetching}
      >
        <VouchersTable
          data={tableData.vouchers}
          selectedRows={tableData.selectedRows}
          onCheckRows={onCheckRows}
        />
      </ContainerWithLoader>

      <Dialog
        title="Excluir voucher"
        secondaryButton="Cancelar"
        primaryButton="Salvar"
        size="lg"
        handleClose={() => setShowDialog(false)}
        handleConfirm={() => deleteSelectedVouchers()}
        open={showDialog}
        label="Sim, tenho certeza!"
        alert
      >
        <p>
          Quando excluido, este voucher não poderá ser utilizados por nenhum
          usuário. Assim, todos os usuários que tiverem este voucher em mãos,
          não poderão utiliza-lo mais.
        </p>
        Esta ação não pode ser desfeita.
      </Dialog>

      <SnackbarOutlet />
    </section>
  )
}

VouchersSection.propTypes = {
  brandLabel: PropTypes.string.isRequired,
  brandIdentifier: PropTypes.string.isRequired
}

VouchersSection.defaultProps = {}
