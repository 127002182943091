/* eslint-disable react/destructuring-assignment */
import React, { useState, useContext } from 'react'
import { Button, CardInfo, Dialog } from '@equipe-ninja/saraiva-ui'
import { Divider, Typography } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { useRecoilState } from 'recoil'
import FixationExercise from '../../../../../services/fixationExerciseService'
import { useStyles } from './styles'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import { questionDialogFormValuesSelector } from '../../../../../atoms/subject/selectors/fixation-exercise'
import { QuestionCardExerciseUpdate } from '../QuestionCardExerciseUpdate'
import {
  QUESTION_ALTERNATIVES_FIELDS,
  QUESTION_FIELDS
} from '../../../../../domain/fixation-exercise/question-fields'

const cardStyles = {
  background: '#fff',

  '& main': {
    padding: 24
  }
}

export function QuestionCard(exercise) {
  const styles = useStyles()

  const { onDelete, refreshDataCallBack } = exercise
  const { data } = exercise.exercise
  const exercises = exercise.exercise.item

  const [isOpenDialogChange, openDialogChange] = useState(false)
  const [isOpenDialogChangeConfirm, openDialogChangeConfirm] = useState(false)
  const [isOpenDialogDelete, openDialogDelete] = useState(false)
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [isFetching, setIsFetching] = useState(false)

  const [questionFormValues, setQuestionFormValues] = useRecoilState(
    questionDialogFormValuesSelector
  )

  const closeDialogChange = () => {
    openDialogChange(false)
    setQuestionFormValues((prev) => ({
      ...prev,
      [QUESTION_FIELDS.KEY_WORDS]: '',
      [QUESTION_FIELDS.CORRECT_ALTERNATIVE]: '',
      [QUESTION_FIELDS.TEST_YEAR]: '',
      [QUESTION_FIELDS.ENUNCIATION]: '',
      [QUESTION_FIELDS.TABLE_TEST]: '',
      [QUESTION_FIELDS.ALTERNATIVES]: [
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 1,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        },
        {
          [QUESTION_ALTERNATIVES_FIELDS.ORDER]: 2,
          [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: ''
        }
      ]
    }))
  }

  const questionHeader = (index) => {
    switch (index) {
      case 0:
        return 'A)'
      case 1:
        return 'B)'
      case 2:
        return 'C)'
      case 3:
        return 'D)'
      case 4:
        return 'E)'
      default:
        return ''
    }
  }

  const deleteFixationExerciseHandler = (currentSubjectId, questionId) => {
    if (isFetching) return
    setIsFetching(true)

    FixationExercise.deleteFixationExercise(currentSubjectId, questionId)
      .then(() => {
        setSuccessMessage('Exercício Excluído com sucesso')
        openDialogDelete(false)
        onDelete(questionId)
      })
      .catch(() => {
        setErrorMessage('Não foi possível excluir esse exercício de fixação')
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const putFixationExerciseHandler = (data) => {
    if (isFetching) return
    setIsFetching(true)

    FixationExercise.putFixationExercise(data)
      .then(() => {
        openDialogChange(false)
        openDialogChangeConfirm(false)
        setSuccessMessage('Exercício alterado com sucesso')
        closeDialogChange()
        refreshDataCallBack()
      })
      .catch(() => {
        setErrorMessage('Não foi possivel alterar esse exercício')
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const correctAlternative = (index) => {
    switch (index) {
      case 1:
        return 'A'
      case 2:
        return 'B'
      case 3:
        return 'C'
      case 4:
        return 'D'
      case 5:
        return 'E'
      default:
        return ''
    }
  }

  function getCorrectAlternative(correctId) {
    const filterCorrectAlternative = exercises.alternatives.filter(
      (item) => item.id === correctId
    )
    const result =
      correctAlternative(filterCorrectAlternative[0]?.order) ??
      'Não existe Resposta'
    return result
  }

  return (
    <CardInfo className={cardStyles}>
      <Typography variant="h6" color="textPrimary">
        {`Questão ${exercises.id}`}
      </Typography>

      <Typography
        className={styles.subtitle}
        variant="body2"
        color="textSecondary"
      >
        Banco de Questões: {data.disciplineTitle}
      </Typography>

      <div className={styles.infoContainer}>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Palavras-chave
          </Typography>
          <Divider className={styles.divider} />
          <Typography variant="body2" color="textSecondary">
            {exercises.tags}
          </Typography>
        </div>
        <div className="info-group row">
          <div className="full">
            <Typography variant="body1" color="textPrimary">
              Prova / Banca
            </Typography>
            <Divider className={styles.divider} />
            <Typography variant="body2" color="textSecondary">
              {exercises.institution}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="textPrimary">
              Ano de realização
            </Typography>
            <Divider className={styles.divider} />
            <Typography variant="body2" color="textSecondary">
              {exercises.year}
            </Typography>
          </div>
        </div>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Enunciado
          </Typography>
          <Divider className={styles.divider} />
          <Typography variant="body2" color="textSecondary">
            {exercises.content}
          </Typography>
        </div>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Alternativas
          </Typography>
          <Divider className={styles.divider} />
          <div className={styles.alternativesContainer}>
            {exercises.alternatives.map((i, index) => (
              <Typography variant="body2" color="textSecondary">
                {`${questionHeader(index)} ${i.content}`}
              </Typography>
            ))}
          </div>
        </div>
        <div className="info-group">
          <Typography variant="body1" color="textPrimary">
            Alternativa correta:{' '}
            {getCorrectAlternative(exercises.correct_exercise_alternative_id)}
          </Typography>
        </div>
      </div>

      <div className={styles.callToAction}>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<Edit />}
          onClick={openDialogChange}
        >
          Alterar Questão
        </Button>
        <Button
          className="delete-button"
          variant="outlined"
          startIcon={<Delete />}
          onClick={() => openDialogDelete(true)}
        >
          Excluir Questão do Exercício
        </Button>

        <Dialog
          open={isOpenDialogChange}
          title={`Alterar questão ${exercises.id}`}
          size="lg"
          primaryButton="Próximo"
          secondaryButton="cancelar"
          handleClose={closeDialogChange}
          handleConfirm={openDialogChangeConfirm}
        >
          <QuestionCardExerciseUpdate data={exercises} />
        </Dialog>

        <Dialog
          open={isOpenDialogChangeConfirm}
          title="Alterar questão"
          size="md"
          primaryButton="alterar"
          secondaryButton="cancelar"
          handleClose={() => openDialogChangeConfirm(false)}
          handleConfirm={() => putFixationExerciseHandler(questionFormValues)}
          label="Sim, tenho certeza!"
          alert
        >
          <Typography color="textPrimary">
            Você tem certeza que deseja alterar a questão?
          </Typography>
          Essa alteração irá refletir em todos os exercícios que utilizam a
          questão.
        </Dialog>

        <Dialog
          open={isOpenDialogDelete}
          title="Excluir questão "
          size="md"
          primaryButton="excluir"
          secondaryButton="cancelar"
          handleClose={() => openDialogDelete(false)}
          handleConfirm={() =>
            deleteFixationExerciseHandler(
              exercise.exercise.item.currentSubjectId,
              exercise.exercise.item.id
            )
          }
        >
          Você tem certeza que deseja excluir a questão do exercício?
        </Dialog>
      </div>
    </CardInfo>
  )
}
