import React from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'

export function MaterialDeleteDialog({
  materialDialogOpen,
  setMaterialDialogOpen
}) {
  const handleClose = () => {
    setMaterialDialogOpen(false)
  }

  return (
    <div>
      <Dialog
        title="Excluir material complementar "
        secondaryButton="CANCELAR"
        primaryButton="EXCLUIR"
        size="md"
        open={materialDialogOpen}
        handleClose={() => handleClose()}
        // handleConfirm={() => save()}
        // isDisabled={saving}
        alert
        label="Sim, tenho certeza!"
      >
        <Typography variant="body1" style={{ color: '#000' }}>
          Tem certeza que deseja excluir o material complementar?
        </Typography>
        <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Ao realizar essa ação o usuário perdera o acesso a esse conteúdo
        </Typography>
      </Dialog>
    </div>
  )
}
