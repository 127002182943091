import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(24)
  },
  cardContainer: {
    marginTop: theme.typography.pxToRem(40)
  },
  loading: {
    marginTop: theme.typography.pxToRem(40),
    display: 'block'
  }
}))
