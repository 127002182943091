import React, { useContext, useState } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import { Button, Dialog, Snackbar } from '@equipe-ninja/saraiva-ui'
import { snackbarStyle } from '../../layouts/styles'

import useStyles from './styles.js'
import ProductSelect from '../../components/ProductSelect'

import DialogForm from './DialogForm'
import { GeneralContext } from '../../contexts/GeneralContext.js'

function JWTConection() {
  const classes = useStyles()
  const { productSelected, setProduct } = useContext(GeneralContext)

  const [openDialog, setOpenDialog] = useState(false)
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const [createdInstitution, setCreatedInstitution] = useState({})
  const [snackBar, setSnackBar] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const snackbarStyles = snackbarStyle()

  const callBackFunc = (childData) => {
    setCreatedInstitution(childData)
    setIsDisabled(true)
  }

  const selectProduct = (productValue) => {
    setProduct(productValue)
  }

  const openDialogEraseData = () => {
    setOpenDialog(false)
    setIsDisabled(false)
    setOpenAlertDialog(false)
    setCreatedInstitution({})
  }

  return (
    <Grid className={classes.root}>
      <Typography variant="h3" className={`${classes.text} ${classes.textH3}`}>
        Gerenciar JWT
      </Typography>

      <ProductSelect onChange={selectProduct} onlyBds />

      {productSelected.value && (
        <>
          <Typography
            variant="h4"
            className={`${classes.text} ${classes.textH4}`}
          >
            Biblioteca Digital Saraiva
          </Typography>
          <div className={classes.buttons}>
            <Button
              onClick={() => setOpenDialog(true)}
              color="primary"
              variant="contained"
              disabled={isDisabled}
            >
              Cadastrar instituição
            </Button>
            {createdInstitution.name && (
              <Button
                onClick={() => setOpenAlertDialog(true)}
                color="primary"
                variant="outlined"
              >
                Cadastrar nova instituição
              </Button>
            )}
          </div>
          <Divider style={{ margin: '24px 0' }} />
          {createdInstitution.name && (
            <div>
              <p className={`${classes.text} ${classes.results}`}>
                Instituição: {createdInstitution.name}
              </p>
              <p className={`${classes.text} ${classes.results}`}>
                CNPJ: {createdInstitution.id_number}
              </p>
              <p className={`${classes.text} ${classes.results}`}>
                Chave: {createdInstitution.secret_key}
              </p>
              <Snackbar
                classes={() => snackbarStyles}
                handleClose={() => setSnackBar(false)}
                show={snackBar}
                severity="success"
              >
                Instituição cadastrada no JWT com sucesso
              </Snackbar>
            </div>
          )}
        </>
      )}
      {openDialog && (
        <DialogForm
          parentCallBack={callBackFunc}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}
      {openAlertDialog && (
        <Dialog
          open={openAlertDialog}
          title="Atenção"
          secondaryButton="recusar"
          primaryButton="aceitar"
          size="md"
          handleClose={() => setOpenAlertDialog(false)}
          handleConfirm={openDialogEraseData}
          label="Gerar nova instituição"
          alert
        >
          Ao cadastrar uma nova instituição, as informações sobre a instituição
          atual serão apagadas.
        </Dialog>
      )}
    </Grid>
  )
}

export default JWTConection
