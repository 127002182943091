/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Checkbox
} from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'
import FolderIcon from '@material-ui/icons/Folder'

import useStyles from './styles'
import AvaConnection from '../../../services/avaConnection'
import Loading from '../../../components/Loading'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { ConnectionContext } from '../../../contexts/ConnectionContext'
import messages from '../../../helpers/messages'

function SelectSubjectsDialog({ connection, handleCancel }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [checkedAll, setCheckedAll] = useState(false)

  const { setSuccessMessage, setErrorMessage, setUpdated } = useContext(GeneralContext)
  const [saving, setSaving] = useState(false)
  const [subjects, setSubjects] = useState([])

  const save = async () => {
    try {
      setSaving(true)

      const selectedSubjectIds = subjects
        .filter((subject) => subject.checked === true)
        .map((item) => item.id)

      const payload = {
        modules_id: selectedSubjectIds
      }

      const response = await AvaConnection.saveDisciplines(
        connection.client_id,
        payload
      )

      if (!response.ok) {
        const error = await response.json()
        throw error
      }
      setSuccessMessage(messages.subjectsDialog.success)
      handleCancel()
      setUpdated(true)
    } catch (error) {
      setErrorMessage(messages.subjectsDialog.error)
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setSaving(false)
    }
  }

  async function fetchSubjects() {
    try {
      setLoading(true)
      const response = await AvaConnection.returnDisciplines()

      const result = await response.json()

      const subjectsList = result.list.map((subject) => {
        const checked = connection?.modules.includes(subject.id)

        return {
          id: subject.id,
          name: subject.name,
          checked
        }
      })

      setSubjects(subjectsList)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSubjects()
  }, [])

  const checkOrUncheckAll = async (evt) => {
    setLoading(true)
    setCheckedAll(!checkedAll)
    const subjectsUpdated = await Promise.all(
      subjects.map((subject) => ({
        ...subject,
        checked: evt.target.checked
      }))
    )
    setSubjects(subjectsUpdated)
    setLoading(false)
  }

  const checkOrUncheckSubject = (evt, id) => {
    const subjectsUpdated = subjects.map((subject) => {
      if (subject.id === id) {
        return { ...subject, checked: evt.target.checked }
      }
      return subject
    })

    setSubjects(subjectsUpdated)
  }

  const renderBody = () => {
    if (loading) {
      return <Loading />
    }

    return (
      <>
        <DialogTitle>Selecionar disciplinas</DialogTitle>

        <DialogContent className={classes.content}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      onChange={(event) => checkOrUncheckAll(event)}
                      style={{ marginLeft: -8 }}
                      checked={checkedAll}
                    />
                    NOME
                  </TableCell>
                </TableRow>
              </TableHead>

              {!loading && (
                <TableBody>
                  {subjects?.map((subject) => {
                    return (
                      <TableRow key={subject.id}>
                        <TableCell className={classes.checkboxItems}>
                          <Checkbox
                            key={subject.id}
                            checked={subject.checked}
                            onChange={(evt) =>
                              checkOrUncheckSubject(evt, subject.id)
                            }
                            color="primary"
                          />

                          <Box
                            className={
                              subject.checked
                                ? classes.checked
                                : classes.notChecked
                            }
                          >
                            <FolderIcon className={classes.pasteIcon} />
                            {subject.name}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleCancel()}
            disabled={false}
          >
            CANCELAR
          </Button>

          <Button color="primary" onClick={save} disabled={false}>
            SALVAR
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <Dialog open onClose={() => handleCancel()}>
      {renderBody()}
    </Dialog>
  )
}

export default SelectSubjectsDialog
