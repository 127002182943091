import { getSessionToken } from '../../../../auth'
import httpClient from '../../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../../helpers/mock-server-utils'
import {
  SCHEDULED_CONTENT_REMOTE_FIELDS as R,
  SCHEDULED_CONTENT_REMOTE_TYPES as RT,
  SCHEDULED_CONTENT_REMOTE_EXERCISE_TYPE as ET
} from '../../remote-fields/scheduled-content'
import { ScheduledContentAdapter } from '../../adapters/scheduled-content'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para carregar a lista de conteudos agendados baseado no curso e
 * tipo de conteudo agendado
 * @returns lista de usuários apos passar por um adaptador de entrada
 */
const getScheduledContentByType = async (courseId, type) => {
  const path = `/contents/api/v1/scheduled_contents_by_course?id=${courseId}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)
  const responseData = await response.json()
  return ScheduledContentAdapter.inboundList(responseData)
}

/* eslint-enable no-unused-vars */

const factory = () => {
  return [
    {
      [R.ANSWER_CONTENT]: null,
      [R.ANSWER_PUBLISHING_DATE]: '2022-07-31',
      [R.CONTENT]: null,
      [R.END_DATE]: '2022-07-31',
      [R.ID]: 2,
      [R.INITIAL_DATE]: '2022-07-01',
      [R.LINK]: null,
      [R.TITLE]: 'Título enunciado 1',
      [R.SCHEDULED_CONTENT_TYPE]: RT.OAB_SECOND_PHASE,
      [R.EXERCISE_TYPE]: ET.QUESTION_BLOCK
    },
    {
      [R.ANSWER_CONTENT]: 'Gabarito Item 1',
      [R.ANSWER_PUBLISHING_DATE]: '2022-05-25',
      [R.CONTENT]: 'Texto enunciado item 1',
      [R.END_DATE]: '2022-06-30',
      [R.ID]: 1,
      [R.INITIAL_DATE]: '2022-05-01',
      [R.LINK]: '',
      [R.TITLE]: 'Título enunciado 2',
      [R.SCHEDULED_CONTENT_TYPE]: RT.OAB_SECOND_PHASE,
      [R.EXERCISE_TYPE]: ET.QUESTION_BLOCK
    }
  ]
}

// eslint-disable-next-line no-unused-vars
const mockedResponse = (courseId, type) => {
  return mockedServerAnswer(
    factory(),
    true,
    1000,
    ScheduledContentAdapter.inbound
  )
}

export { getScheduledContentByType }
