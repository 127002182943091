import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { postResquestResponseHandler } from '../../utils/response-handlers'
import { COURSE_REMOTE_FIELDS } from '../remote-fields/course'
import { SERVICE_REMOTE_FIELDS } from '../remote-fields/courses-services-fields'
import { CourseAdapter } from '../adapters/course'
import { COURSE_CREATE_FIELDS as F } from '../../../domain/courses/course-create-fields'
import { COURSE_FIELDS } from '../../../domain/courses'
import { generateServicesConstantsForEnviroment } from '../../../domain/courses/business'

const generateServicePayload = (data) => {
  const { LFG_BRAND_ID, ACTIVE, APP_ID } =
    generateServicesConstantsForEnviroment()

  return {
    [SERVICE_REMOTE_FIELDS.DESCRIPTION]: data[F.COURSE_NAME],
    [SERVICE_REMOTE_FIELDS.NAME]: data[F.COURSE_NAME],
    [SERVICE_REMOTE_FIELDS.BRAND]: LFG_BRAND_ID,
    [SERVICE_REMOTE_FIELDS.SKU]: data[F.SKU],
    [SERVICE_REMOTE_FIELDS.ACTIVE]: ACTIVE,
    [SERVICE_REMOTE_FIELDS.APP_ID]: APP_ID
  }
}

const generateCoursePayload = (data, serviceId) => {
  return {
    [COURSE_REMOTE_FIELDS.TITLE]: data[F.COURSE_NAME],
    [COURSE_REMOTE_FIELDS.SERVICE_ID]: serviceId
  }
}

const headers = () => {
  return httpClient.buildHeader({ 'session-token': getSessionToken() })
}

const addCategories = (serviceId, data) => {
  const path = `/offers/api/v1/services/${serviceId}/categories`
  const categoryId = data[F.CATEGORY]
  const subcategoryId = data[F.SUB_CATEGORY]

  const categoryRequest = httpClient.put(
    `${path}/${categoryId}`,
    null,
    headers()
  )

  const subcategoryRequest = httpClient.put(
    `${path}/${subcategoryId}`,
    null,
    headers()
  )

  const requests = subcategoryId
    ? [categoryRequest]
    : [categoryRequest, subcategoryRequest]

  return Promise.all(requests)
}

/**
 * Caso de uso para criacao de curso
 * @returns dados do curso criado
 */
export const createCourses = async (data) => {
  const servicePayload = generateServicePayload(data)
  const servicePath = '/offers/api/v1/services'
  const coursePath = '/offers/api/v1/course'

  const parsedServiceJson = await httpClient
    .post(servicePath, servicePayload, headers())
    .then((response) => postResquestResponseHandler(response))

  const serviceId = parsedServiceJson.data[SERVICE_REMOTE_FIELDS.ID]

  await addCategories(serviceId, data)

  const coursePayload = generateCoursePayload(
    data,
    parsedServiceJson.data[SERVICE_REMOTE_FIELDS.ID]
  )

  const parsedCourseJson = await httpClient
    .post(coursePath, coursePayload, headers())
    .then((response) => postResquestResponseHandler(response))

  const adaptedResponse = CourseAdapter.inbound(parsedCourseJson)

  return adaptedResponse?.[COURSE_FIELDS.ID]
}
