import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: '85%',
    paddingBottom: 70,
    '& .MuiSnackbar-root': {
      marginTop: 64,
      right: 0
    }
  },
  titleH3: {
    marginBottom: '24px',
    color: theme.palette.text.primary,
    marginTop: '26px',
    fontSize: '48px'
  }
}))

export default useStyles
