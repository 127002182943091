export const COURSE_CREATE_FIELDS = Object.freeze({
  ID: 'id',
  SKU: 'sku',
  COURSE_NAME: 'name',
  TYPE: 'type',
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  SAVE_DATE: 'saveDate',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  STATUS: 'status',
  URL_REDIRECT: 'urlRedirect',
  STATUS_FORM_ECOMMERCE: 'statusFormEcommerce',
  STATUS_FORM_ELEARNING: 'statusFormElearning'
})
