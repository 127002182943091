import { makeStyles } from '@material-ui/core/styles'

const paperStyles = makeStyles((theme) => ({
  root: {
    '& > .MuiDialogContent-root': {
      overflowY: 'hidden',
      padding: 0,
      width: '100% !important'
    }
  },
  folder: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px',
    cursor: 'pointer',
    width: '-webkit-fill-available'
  },
  cell: {
    color: theme.palette.text.secondary,
    padding: '8px 8px',
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    display: 'flex',
    padding: 10,
    '&:hover': {
      backgroundColor: '#efedf4 !important'
    }
  },
  tableRowUA: {
    display: 'flex',
    padding: '10px 44px'
  },
  icon: {
    margin: '0 10px 0 10px'
  }
}))

export default paperStyles
