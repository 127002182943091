import {
  DISCOUNT_CAMPAIGN_FIELDS as FIELDS,
  DISCOUNT_DETAILS_ITEM_FIELDS as D_FIELDS
} from '../../../../domain/promotional-content/discounts-campaign'
import {
  REMOTE_DISCOUNT_FIELDS as REMOTE,
  REMOTE_DISCOUNT_CAMPAIGN_FIELDS as D_REMOTE
} from '../remote-fields/discount-fields'
import { parseRemoteDecimal } from '../../../utils/inbound-utils'
import { RemoteDateUtils } from '../../../utils/remote-date-utils'

const outbound = (campainData, discountListAsCsv) => {
  const formData = new FormData()
  formData.append([REMOTE.CAMPAIGN_NAME], campainData[FIELDS.CAMPAIGN_NAME])
  formData.append([REMOTE.CAMPAIGN_STATUS], campainData[FIELDS.CAMPAIGN_STATUS])
  const blob = new File([discountListAsCsv], 'campanha.csv', {
    type: 'text/csv;charset=utf-8'
  })
  formData.append([REMOTE.CAMPAIGN_DATA], blob)

  return formData
}

const calculateFromValue = (priceFrom, discount) => {
  return parseRemoteDecimal(
    (priceFrom * discount) / (100 - discount) + priceFrom
  )
}

const dataInbound = (data) => {
  return data.map((item) => ({
    [D_FIELDS.ID]: item[D_REMOTE.ID],
    [D_FIELDS.NAME]: item[D_REMOTE.NAME],
    [D_FIELDS.SKU]: item[D_REMOTE.SKU],
    [D_FIELDS.START_DATE]: RemoteDateUtils.parseDateStringToHuman(
      item[D_REMOTE.START_DATE]
    ),
    [D_FIELDS.END_DATE]: RemoteDateUtils.parseDateStringToHuman(
      item[D_REMOTE.END_DATE]
    ),
    [D_FIELDS.FROM_VALUE]: calculateFromValue(
      item[D_REMOTE.PRICE_TO],
      item[D_REMOTE.DISCOUNT]
    ),
    [D_FIELDS.TO_VALUE]: parseRemoteDecimal(item[D_REMOTE.PRICE_TO])
  }))
}

const inbound = (data) => {
  return {
    [FIELDS.ID]: data[REMOTE.ID],
    [FIELDS.CAMPAIGN_NAME]: data[REMOTE.CAMPAIGN_NAME],
    [FIELDS.CAMPAIGN_STATUS]: data[REMOTE.CAMPAIGN_STATUS],
    [FIELDS.CAMPAIGN_DATA]: dataInbound(data.itens || [])
  }
}

const outboundNameStatus = (data) => {
  return {
    [REMOTE.ID]: data[FIELDS.ID],
    [REMOTE.CAMPAIGN_NAME]: data[FIELDS.CAMPAIGN_NAME],
    [REMOTE.CAMPAIGN_STATUS]: data[FIELDS.CAMPAIGN_STATUS]
  }
}

export const CampaignAdapter = {
  outbound,
  inbound,
  outboundNameStatus
}
