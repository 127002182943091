import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, Grid, Typography } from '@material-ui/core'

import CoverSaraivaEducacao from '../../static/images/CoverSaraivaEducacao.png'

export default function Home() {
  const useStyles = makeStyles((theme) => ({
    image: {
      marginTop: 156,
      marginLeft: '4vw',
      maxHeight: '80%',
      maxWidth: '80%',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 360,
        maxWidth: 325
      }
    },
    content: {
      minHeight: 144,
      paddingTop: 48,
      paddingBottom: 48,
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.up('md')]: {
        marginTop: '21vw',
        marginRight: '4vw'
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 264
      }
    }
  }))
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <Box display={{ xs: 'none', md: 'block' }}>
          <img src={CoverSaraivaEducacao} alt="SARAIVA" className={classes.image} />
        </Box>
      </Grid>

      <Grid item xs={12} md={7}>
        <Paper className={classes.content}>
          <Typography variant="h5">
            Nossas boas-vindas ao sistema back office da Saraiva Educação
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}
