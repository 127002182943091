import { BRANDS_IDENTIFIER } from '../brands/brands'

const brandWithAccessToPromotionalPages = Object.freeze([BRANDS_IDENTIFIER.LFG])

/**
 * Verifica se uma brand tem acesso ao conteudo da pagina promocional
 *
 * @param {*} brandIdentifier - identificador da brand. ver dominio {@link BRANDS_IDENTIFIER (link)}
 */
export const hasAccessToPromotionalPages = (brandIdentifier) => {
  return brandWithAccessToPromotionalPages.includes(brandIdentifier)
}
