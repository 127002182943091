import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import { SearchBar, Table } from '@equipe-ninja/saraiva-ui'
import { CircularProgress } from '@material-ui/core'

import { EditContentMenu } from './EditContentMenu'
import { GeneralContext } from '../../../../../../contexts/GeneralContext'
import { getComplementaryContents } from '../../../../../../services/complementary-material'
import { useStyles } from './styles'
import { DialogContentManagement } from '../DialogContentManagement'

/**
 *
 * @param {{materialId: string | number}} props
 * @returns {import('react').FC}
 */
export const ContentsTab = ({ materialId, requestId }) => {
  const { setErrorMessage } = useContext(GeneralContext)
  const styles = useStyles()

  const [isFetchingContents, setIsFetchingContents] = useState(false)
  const [contentsList, setContentsList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [contentToEdit, setContentToEdit] = useState(null)
  const [isEditContentDialogOpen, setIsEditContentDialogOpen] = useState(false)

  const fetchMaterialContents = useCallback(async () => {
    setIsFetchingContents(true)

    try {
      const data = await getComplementaryContents(materialId)

      setContentsList(data)
    } catch (err) {
      setErrorMessage('Não foi possível buscar os conteúdos complementares')
    } finally {
      setIsFetchingContents(false)
    }
  }, [materialId, setErrorMessage])

  useEffect(() => {
    fetchMaterialContents()
  }, [fetchMaterialContents])

  useEffect(() => {
    if (requestId !== 0) {
      fetchMaterialContents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId])

  const handleEditContent = useCallback(
    (contentId) => {
      const content = contentsList.find((ct) => ct.id === contentId)
      if (!content) return

      setContentToEdit(content)
      setIsEditContentDialogOpen(true)
    },
    [contentsList]
  )

  const contentsToShow = useMemo(() => {
    if (contentsList.length === 0) return []

    const contentsFilteredBySearchText = searchText
      ? contentsList.filter((content) => content.name.includes(searchText))
      : contentsList

    return contentsFilteredBySearchText.map((content) => {
      return {
        key: content.id,
        name: content.name,
        order: content.order,
        type: content.type,
        callToAction: (
          <EditContentMenu
            contentId={content.id}
            handleEditContent={handleEditContent}
          />
        )
      }
    })
  }, [searchText, contentsList, handleEditContent])

  return (
    <div>
      {isFetchingContents ? (
        <div className={styles.tabContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.tabContainer}>
            <SearchBar
              value={searchText}
              clearSearch={() => setSearchText('')}
              onChange={(e) => setSearchText(e.target.value)}
              onSubmit={(e) => e.preventDefault()}
              placeholder="Buscar por nome"
            />
          </div>

          <div>
            <Table
              cells={[
                'Nome do conteúdo',
                'Ordem de exibição',
                'Formato do arquivo',
                ''
              ]}
              rows={contentsToShow}
            />
          </div>

          <DialogContentManagement
            mode="edit"
            contentData={contentToEdit}
            isOpen={isEditContentDialogOpen}
            handleOpen={() => setIsEditContentDialogOpen(true)}
            handleClose={() => setIsEditContentDialogOpen(false)}
            fetchMaterialContents={fetchMaterialContents}
          />
        </>
      )}
    </div>
  )
}

ContentsTab.propTypes = {
  requestId: PropTypes.number.isRequired,
  materialId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
}
