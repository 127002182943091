import React from 'react'
import { useRecoilValue } from 'recoil'
import { scheduledContentTypeSelector } from '../../../../atoms/scheduled-content'
import { ExercisesOab } from './components/ExercisesOab'
import { SCHEDULED_CONTENT_TYPES } from '../../../../domain/courses'
import useStyles from './styles'

export const ScheduledContentTypeRelay = () => {
  const contentType = useRecoilValue(scheduledContentTypeSelector)
  const classes = useStyles()
  return (
    <div className={classes.marginTopXL}>
      {contentType === SCHEDULED_CONTENT_TYPES.OAB_SECOND_PHASE && (
        <ExercisesOab />
      )}
    </div>
  )
}

ScheduledContentTypeRelay.propTypes = {}

ScheduledContentTypeRelay.defaultProps = {}
