import React, { useState, useEffect, useContext } from 'react'

import { GeneralContext } from '../../../contexts/GeneralContext'
import IntegrationsService from '../../../services/integrationsService'
import messages from '../../../helpers/messages'

import useStyles from './styles'
import {
  Button,
  MenuItem,
  SelectSaraiva,
  TextInput
} from '@equipe-ninja/saraiva-ui'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import { Box, Typography } from '@material-ui/core'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'

export function GenerateUserAccess() {
  const classes = useStyles()
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [email, setEmail] = useState('')
  const [app, setApp] = useState('')
  const [saving, setSaving] = useState(false)
  const [accessLink, setAccessLink] = useState()
  const isFormValid = email.trim() !== '' && app.trim() !== ''

  const save = async () => {
    setSaving(false)
    const payload = {
      app: app,
      email: email
    }
    try {
      const response = await IntegrationsService.generateUserAccessToken(
        payload
      )
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setAccessLink(result)
      setSuccessMessage(messages.generatedAccess.success)
      window.open(result, '_blank')
      setEmail('')
      setApp('')
    } catch (error) {
      if (error.errors) {
        setErrorMessage(error.errors)
      } else {
        setErrorMessage(messages.generatedAccess.error)
      }
    } finally {
      setSaving(true)
    }
  }

  return (
    <>
      <Button
        color="primary"
        startIcon={<LeftArrowIcon />}
        onClick={() => window.history.back()}
        className={classes.backButton}
      >
        GERENCIAR USUÁRIOS
      </Button>
      <Box>
        <ContentWithHeader title="Acessar a conta dos usuários">
          <Typography variant="body1" className={classes.sectionText}>
            Digite o e-mail da conta do usuário e selecione a aplicação:
          </Typography>
          <form className={classes.forms}>
            <TextInput
              className={classes.input}
              color="primary"
              label="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <SelectSaraiva
              value={app}
              label="Aplicação *"
              required
              onChange={(e) => setApp(e.target.value)}
            >
              <MenuItem value="Conecta">Conecta</MenuItem>
              <MenuItem value="BDS">BDS</MenuItem>
              <MenuItem value="Backoffice">Backoffice</MenuItem>
              <MenuItem value="Ava Lfg">Ava Lfg</MenuItem>
            </SelectSaraiva>
          </form>
          <Button
            color="primary"
            variant="contained"
            onClick={save}
            disabled={!isFormValid}
          >
            Acessar
          </Button>
        </ContentWithHeader>
      </Box>
      <div className={classes.root}>
        <SnackbarOutlet />
      </div>
    </>
  )
}
