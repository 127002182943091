/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  Typography,
  Divider,
  Box,
  Button
} from '@material-ui/core'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import { Create, Delete } from '@material-ui/icons'
import { SearchBarFilter } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import ViewQuestion from '../ViewQuestion'
import IntegrationsService from '../../services/integrationsService'
import { GeneralContext } from '../../contexts/GeneralContext'
import { DeleteBankQuestion } from '../DeleteBankQuestion'
import EditQuestion from '../EditQuestion'

export const QuestionTable = ({ updatedQuestions }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [checked, setChecked] = useState(false)
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [showDialog, setShowDialog] = useState(false)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [showDialogDelete, setshowDialogDelete] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')

  const filterOptions = [
    { label: 'Código', value: 'code' },
    { label: 'Área', value: 'knowledge_area' },
    { label: 'Disciplina', value: 'subject' },
    { label: 'Tema', value: 'theme' },
    { label: 'Objetivo', value: 'goal' },
    { label: 'Fonte', value: 'question_source' }
  ]
  const [showEditQuestion, setShowEditQuestion] = useState(false)

  const {
    setView,
    newQuestion,
    setSelectedQuestionId,
    deletedQuestion,
    setViewEditQuestion
  } = useContext(GeneralContext)

  const handleInputChange = ({ filterTerm, filterName }) => {
    setSearch(filterTerm)
    setSelectedCategory(filterName)
  }

  const handleButtonClick = (row) => {
    const sortedRow = { ...row }
    if (sortedRow.question_alternatives) {
      sortedRow.question_alternatives.sort((a, b) => a.order - b.order)
    }

    setView(row)
    setShowDialog(true)
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleOpenEditQuestion = () => {
    const selectedQuestion = rows.find((row) => row.id === selected[0])
    setViewEditQuestion(selectedQuestion)
    setShowEditQuestion(true)
  }

  const handleCloseEditQuestion = () => {
    setShowEditQuestion(false)
    setViewEditQuestion(null)
  }

  const handleOpenDialogDelete = () => {
    setshowDialogDelete(true)
  }

  const handleCloseDialogDelete = () => {
    setshowDialogDelete(false)
  }

  const handleCheckAll = (event) => {
    event.stopPropagation()
    setChecked(event.target.checked)
    const newSelected = event.target.checked ? rows.map((row) => row.id) : []
    setSelected(newSelected)
    setSelectedQuestionId(newSelected)
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    setSelectedQuestionId(newSelected)
  }

  const resetCheckboxes = () => {
    setChecked(false)
    setSelected([])
    setSelectedQuestionId([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  useEffect(() => {
    const isAllSelected = rows.length === selected.length
    setChecked(isAllSelected)
  }, [selected, rows.length])

  const fetchData = useCallback(async () => {
    const pagination = page === 0 ? 1 : page === 1 ? 2 : page + 1

    if (search) {
      try {
        const response = await IntegrationsService.searchFilter(
          pagination,
          rowsPerPage,
          search,
          selectedCategory
        )
        const jsonData = await response.json()
        setRows(jsonData.data)
      } catch (error) {
        console.error('Error while searching:', error)
      }
    } else {
      try {
        const response = await IntegrationsService.searchDataBaseQuestions(
          pagination,
          rowsPerPage,
          search,
          selectedCategory
        )

        if (!response.ok) {
          throw new Error(response.status)
        }

        const jsonData = await response.json()
        setRows(jsonData.data)

        setTotalQuestions(jsonData.total)
        setChecked(false)
      } catch (error) {
        console.error('Error fetching database questions:', error)
      }
    }
  }, [page, rowsPerPage, search, selectedCategory])

  useEffect(() => {
    const isAllSelected = rows.length === selected.length
    setChecked(isAllSelected)
  }, [selected, rows.length])

  useEffect(() => {
    if (!updatedQuestions || rows.length !== false) {
      const timer = setTimeout(() => {
        fetchData()
      }, 100)

      return () => clearTimeout(timer)
    }
  }, [
    updatedQuestions,
    page,
    rowsPerPage,
    newQuestion,
    deletedQuestion,
    search,
    selectedCategory,
    rows.length,
    fetchData
  ])

  useEffect(() => {
    setSelected([])
  }, [deletedQuestion])

  return (
    <>
      <div className={classes.filterContainer}>
        <SearchBarFilter
          filterOptions={filterOptions}
          searchPlaceHolder="Buscar por código, área, disciplina, tema, objetivo ou fonte"
          handleSearchTerm={handleInputChange}
          defaultFilterOption="code"
        />
      </div>

      <TableContainer className={classes.tableContainer} component={Paper}>
        <Box>
          <Typography className={classes.text} variant="body1" color="primary">
            QUESTÕES
            <div className={classes.btnContainer}>
              {selected.length > 0 && (
                <div>
                  <Button
                    className={classes.btnEdit}
                    size="small"
                    variant="outlined"
                    startIcon={<Create />}
                    onClick={() => handleOpenEditQuestion()}
                    disabled={selected.length > 1}
                  >
                    Editar Questão
                  </Button>
                  <Button
                    className={classes.btnDelete}
                    size="small"
                    variant="outlined"
                    startIcon={<Delete />}
                    onClick={() => handleOpenDialogDelete()}
                  >
                    Excluir
                  </Button>
                </div>
              )}
            </div>
          </Typography>

          <Divider />
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleCheckAll}
                />
              </TableCell>
              <TableCell className={classes.headerCell}>Código</TableCell>
              <TableCell className={classes.headerCell}>Área</TableCell>
              <TableCell className={classes.headerCell}>Disciplina</TableCell>
              <TableCell className={classes.headerCell}>Tema</TableCell>
              <TableCell className={classes.headerCell}>Objetivo</TableCell>
              <TableCell className={classes.headerCell}>Fonte</TableCell>
              <TableCell className={classes.headerCell}>Formato</TableCell>
              <TableCell className={classes.headerCell}>Ano</TableCell>
              <TableCell className={classes.headerCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const isItemSelected = isSelected(row.id)
              const labelId = `enhanced-table-checkbox-${row.id}`
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      onClick={(event) => handleClick(event, row.id)}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.knowledge_area}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{row.theme}</TableCell>
                  <TableCell>{row.goal}</TableCell>
                  <TableCell>{row.question_source}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.year}</TableCell>
                  <TableCell className={classes.maxWidthColumn}>
                    <Button
                      style={{ color: '#624C92 ' }}
                      onClick={() => handleButtonClick(row)}
                    >
                      <RemoveRedEyeIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalQuestions}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {showDialog && (
          <ViewQuestion
            setShowDialog={handleDialogClose}
            showDialog={showDialog}
          />
        )}

        <DeleteBankQuestion
          setshowDialogDelete={handleCloseDialogDelete}
          showDialogDelete={showDialogDelete}
          selectedQuestionIds={selected}
        />

        <EditQuestion
          setShowEditQuestion={handleCloseEditQuestion}
          showEditQuestion={showEditQuestion}
          updateData={fetchData}
          resetCheckboxes={resetCheckboxes}
        />
      </TableContainer>
    </>
  )
}
