/* eslint-disable array-callback-return */
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import cls from 'classnames'
import { Button } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import { RoutesMapping } from '../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../hooks/use-required-param-access'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { VOUCHER_FIELDS, VOUCHER_TYPES } from '../../../domain/vouchers'
import { GeneralContext } from '../../../contexts/GeneralContext'
import {
  voucherFieldValidation,
  voucherValidation
} from '../../../domain/vouchers/business'
import { SnackbarOutlet } from '../../../components/SnackbarOutlet'
import { createVouchers, editStatusVouchers } from '../../../services/vouchers'
import { NewVoucherForm } from './NewVoucherForm'

const {
  ID,
  VOUCHER_TYPE,
  CAMPAIGN_NAME,
  PREFIX,
  DISCOUNT_VALUE,
  USES_PER_VOUCHER,
  VOUCHER_AMOUNT,
  START_DATE,
  END_DATE,
  STATUS
} = VOUCHER_FIELDS

export const VouchersNew = () => {
  const classes = useStyles()
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useContext(GeneralContext)
  const [uiControls, setUiControls] = useState({ isExecutingTask: false })
  const [formState, setFormState] = useState({
    [ID]: null,
    [VOUCHER_TYPE]: '',
    [CAMPAIGN_NAME]: '',
    [PREFIX]: '',
    [DISCOUNT_VALUE]: '',
    [USES_PER_VOUCHER]: '',
    [VOUCHER_AMOUNT]: '',
    [START_DATE]: null,
    [END_DATE]: null,
    [STATUS]: ''
  })

  const [formErrors, setFormErrors] = useState({
    [ID]: false,
    [VOUCHER_TYPE]: false,
    [CAMPAIGN_NAME]: false,
    [PREFIX]: false,
    [DISCOUNT_VALUE]: false,
    [USES_PER_VOUCHER]: false,
    [VOUCHER_AMOUNT]: false,
    [START_DATE]: false,
    [END_DATE]: false,
    [STATUS]: false
  })

  useRequiredParamsAccess('brand', RoutesMapping.vouchersLegacyEntryPoint)

  const navigateToList = () => {
    history.push(RoutesMapping.vouchersLegacyEntryPoint)
  }

  const validateFieldValue = (field, value) => {
    const fieldValidation = voucherFieldValidation(field, value, formState)
    setFormErrors((prev) => ({ ...prev, ...fieldValidation }))
  }

  const onChangeVoucherType = (newValue) => {
    if (newValue === VOUCHER_TYPES.ONE_USE.value) {
      setFormState((prev) => ({
        ...prev,
        [VOUCHER_TYPE]: newValue,
        [USES_PER_VOUCHER]: 1
      }))

      validateFieldValue(USES_PER_VOUCHER, 1)

      return
    }

    if (newValue === VOUCHER_TYPES.MANY_USES.value) {
      setFormState((prev) => ({
        ...prev,
        [VOUCHER_TYPE]: newValue,
        [VOUCHER_AMOUNT]: 1
      }))

      validateFieldValue(VOUCHER_AMOUNT, 1)
    }
  }

  const onChange = (field, value) => {
    if (field === VOUCHER_TYPE) {
      onChangeVoucherType(value)
    } else {
      setFormState((prev) => ({ ...prev, [field]: value }))

      validateFieldValue(field, value)
    }
  }

  const isFormValid = () => {
    const { isValid, errors } = voucherValidation(formState)

    if (!isValid) {
      setFormErrors((prev) => ({ ...prev, ...errors }))
    }

    return isValid
  }

  const sendForm = () => {
    setUiControls((prev) => ({ ...prev, isExecutingTask: true }))
    createVouchers(formState)
      .then((res) => {
        if (formState[STATUS] === false) {
          if (res.data) {
            Promise.all(
              res.data.map((voucher) => {
                editStatusVouchers({ ...formState, [ID]: voucher.id })
              })
            )
              .then(() => {
                setTimeout(() => {
                  // aguarda um tempo para listar com o status novo
                  setSuccessMessage(
                    `Voucher para a campanha "${formState[CAMPAIGN_NAME]}" criado com sucesso`
                  )
                  navigateToList()
                }, 1000)
              })
              .catch(() =>
                setErrorMessage(`Não foi possível inativar o voucher`)
              )
          } else {
            editStatusVouchers({ ...formState, [ID]: res.id })
              .then(() => {
                setSuccessMessage(
                  `Voucher para a campanha "${formState[CAMPAIGN_NAME]}" criado com sucesso`
                )
                navigateToList()
              })
              .catch(() =>
                setErrorMessage(`Não foi possível inativar o voucher`)
              )
          }
        } else {
          setSuccessMessage(
            `Voucher para a campanha "${formState[CAMPAIGN_NAME]}" criado com sucesso`
          )
          navigateToList()
        }
      })
      .catch(() => {
        setErrorMessage('Não foi possível criar o voucher')
      })
      .finally(() => {
        setUiControls((prev) => ({ ...prev, isExecutingTask: false }))
      })
  }

  const submitForm = () => {
    if (isFormValid()) {
      sendForm()
    } else {
      setErrorMessage(
        'Não foi possível criar o voucher, verifique os campos em vermelho'
      )
    }
  }

  return (
    <ContentWithHeader
      backButtonRoute={RoutesMapping.vouchersLegacyEntryPoint}
      title="Criar Voucher"
    >
      <NewVoucherForm
        formErrors={formErrors}
        formState={formState}
        onChange={onChange}
      />

      {formState[VOUCHER_TYPE] && (
        <div
          className={cls(classes.marginTopMedium, classes.formActionsContainer)}
        >
          <Button
            color="primary"
            disabled={uiControls.isExecutingTask}
            onClick={navigateToList}
            variant="outlined"
          >
            Cancelar
          </Button>

          <Button
            className={classes.marginLeft16}
            color="primary"
            disabled={uiControls.isExecutingTask}
            onClick={() => submitForm()}
            variant="contained"
          >
            Salvar
          </Button>
        </div>
      )}

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}

VouchersNew.propTypes = {}

VouchersNew.defaultProps = {}
