import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  label: {
    flex: 'none',
    width: 247,
    fontSize: 14,
    color: theme.palette.text.secondary
  },

  value: {
    fontSize: 14,
    color: theme.palette.text.secondary
  }
}))

export default useStyles
