import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { putRequestResponseHandler } from '../../utils/response-handlers'
import { ServiceAdapter } from '../adapters/service-adapter'
import { CourseAdapter } from '../adapters/course'
import { removeEmptyValuesFromParams } from '../../utils/param-utils'
import { outboundDate } from '../../utils/outbound-data-transformation'
import { COURSE_OVERVIEW } from '../../../domain/courses/entities'
import { COURSE_REMOTE_FIELDS as C_REMOTE } from '../remote-fields/course'
import { SERVICE_REMOTE_FIELDS as S_REMOTE } from '../remote-fields/courses-services-fields'
import { CourseServiceStatusAdapter } from '../adapters/course-service-status'

/**
 * Dados de curso
 * @typedef {Object} OverviewData
 * @property {title} title - Titulo do curso
 * @property {string} status - status do curso
 * @property {Date | null} sellingStartDate - data do inicio das vendas
 * @property {Date | null} sellingEndDate - data do fim das vendas
 */

const headers = () => {
  return httpClient.buildHeader({ 'session-token': getSessionToken() })
}

const courseOutboundAdapter = (data) => {
  return {
    [C_REMOTE.TITLE]: data?.[COURSE_OVERVIEW.TITLE]
  }
}

const serviceAdapter = (data) => {
  return {
    [S_REMOTE.NAME]: data?.[COURSE_OVERVIEW.TITLE],
    [S_REMOTE.SELLING_END_DATE]: outboundDate(data?.[COURSE_OVERVIEW.END_DATE]),
    [S_REMOTE.SELLING_START_DATE]: outboundDate(
      data?.[COURSE_OVERVIEW.START_DATE]
    ),
    [S_REMOTE.STATUS]:
      data?.[COURSE_OVERVIEW.STATUS] &&
      CourseServiceStatusAdapter.outbound(data?.[COURSE_OVERVIEW.STATUS])
  }
}

const updateCourse = (id, courseData) => {
  const coursePayload = removeEmptyValuesFromParams(
    courseOutboundAdapter(courseData)
  )
  const path = `/offers/api/v1/course/${id}`

  return new Promise((resolve, reject) => {
    httpClient
      .patch(path, coursePayload, headers())
      .then((response) => putRequestResponseHandler(response))
      .then((parsedJson) => {
        resolve(CourseAdapter.inbound(parsedJson.data))
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const updateService = (id, serviceData) => {
  const servicePayload = removeEmptyValuesFromParams(
    serviceAdapter(serviceData)
  )
  const path = `/offers/api/v1/services/${id}`

  return new Promise((resolve, reject) => {
    httpClient
      .patch(path, servicePayload, headers())
      .then((response) => putRequestResponseHandler(response))
      .then((parsedJson) => {
        resolve(ServiceAdapter.inbound(parsedJson.data))
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * Altera os dados de um serviço/curso
 * @param {number} courseId
 * @param {number} serviceId
 * @param {OverviewData} data
 * @returns Promise com sucesso se todas as operações forem bem sucedidas
 */
export const updateCurseOverview = (courseId, serviceId, data) => {
  const courseRequest = updateCourse(courseId, data)
  const serviceRequest = updateService(serviceId, data)

  return new Promise((resolve, reject) => {
    Promise.all([courseRequest, serviceRequest])
      .then((values) => resolve({ course: values[0], servcice: values[1] }))
      .catch((err) => reject(err))
  })
}
