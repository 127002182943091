import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { CourseOverviewAdapter } from '../adapters/course-overview'
import { getResquestResponseHandler } from '../../utils/response-handlers'

/**
 * Caso de uso para carregamento dos detalhes de um curso
 * @returns lista de usuários apos passar por um adaptador de entrada
 */
export const getCourseOverview = (courseId) => {
  const path = `/offers/api/v1/backoffice/course/${courseId}/?course_overview`

  const headers = httpClient.buildHeader({ 'session-token': getSessionToken() })

  return new Promise((resolve, reject) => {
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((parsedJson) => {
        resolve(CourseOverviewAdapter.inbound(parsedJson.data))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
