export const SCHEDULED_CONTENT_FIELDS = Object.freeze({
  ANSWER_CONTENT: 'answerContent',
  ANSWER_PUBLISHING_DATE: 'answerPublishingDate',
  CONTENT: 'content',
  END_DATE: 'endDate',
  EXERCISE_TYPE: 'exerciseType',
  ID: 'id',
  INITIAL_DATE: 'initialDate',
  LINK: 'link',
  SCHEDULED_CONTENT_TYPE: 'scheduledContentType',
  TITLE: 'title'
})
