import React from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'
import Users from '../../../../../services/accounts/users'
import PropTypes from 'prop-types'

export function EbookDeleteDialog({
  ebookDialogOpen,
  setEbookDialogOpen,
  userId,
  appName,
  caeId,
  onDeletionComplete
}) {
  const handleClose = () => {
    setEbookDialogOpen(false)
  }

  const handleDelete = async () => {
    try {
      const response = await Users.deleteUserProductCae(userId, appName, caeId)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      onDeletionComplete()
      handleClose()
    } catch (error) {
      console.error('An error occurred while deleting the ebook:', error)
    }
  }

  return (
    <div>
      <Dialog
        title="Excluir e-book "
        secondaryButton="CANCELAR"
        primaryButton="EXCLUIR"
        size="md"
        open={ebookDialogOpen}
        handleClose={() => handleClose()}
        handleConfirm={() => handleDelete()}
        alert
        label="Sim, tenho certeza!"
      >
        <Typography variant="body1" style={{ color: '#000' }}>
          Tem certeza que deseja excluir o e-book?
        </Typography>
        <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Ao realizar essa ação o usuário perdera o acesso a esse conteúdo
        </Typography>
      </Dialog>
    </div>
  )
}
EbookDeleteDialog.propTypes = {
  ebookDialogOpen: PropTypes.bool.isRequired,
  setEbookDialogOpen: PropTypes.func.isRequired,
  appName: PropTypes.string.isRequired,
  caeId: PropTypes.arrayOf(PropTypes.number).isRequired,
  userId: PropTypes.number.isRequired,
  onDeletionComplete: PropTypes.func.isRequired
}
