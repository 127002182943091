import React, { useContext, useState, useEffect } from 'react'
import {
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio
} from '@material-ui/core'
import { TextInput, Dialog } from '@equipe-ninja/saraiva-ui'

import useStyles from './styles'
import { ConnectionsContext } from '../../../contexts/ConnectionsContext'
import { GeneralContext } from '../../../contexts/GeneralContext'
import IntegrationsService from '../../../services/integrationsService'
import OffersService from '../../../services/offersService'
import Messages from '../../../helpers/messages'
import { cnpjMask } from '../../../helpers/cnpjControls'

function isInvalidCnpjNumber(value) {
  return value.length > 0 && value.length < 18
}

function isValidCnpjNumber(value) {
  return value.length === 18
}

const INITIAL_STATE = {
  dons: {
    client_id: null,
    name: '',
    id_number: '',
    lms: '',
    lti_version: 'LTI v1.3',
    product: 'ead_direito',
    app_token: process.env.REACT_APP_DONS_TOKEN
  },
  bds: {
    client_id: null,
    name: '',
    id_number: '',
    lms: '',
    lti_version: 'LTI v1.3',
    service_id: null,
    library_code: '',
    library_name: '',
    product: 'bds',
    app_token: process.env.REACT_APP_BDS_TOKEN
  }
}

function ConnectionForm() {
  const { toggleConnectionForm, showingConnectionForm, addConnectionOnState } =
    useContext(ConnectionsContext)
  const { setSuccessMessage, setErrorMessage, productSelected } =
    useContext(GeneralContext)

  const [data, setData] = useState(INITIAL_STATE[productSelected.value])

  const [isInvalidCnpj, setIsInvalidCnpj] = useState(false)

  const [saving, setSaving] = useState(false)

  const classes = useStyles()

  const findClientByCnpj = async (cnpj) => {
    try {
      const response = await IntegrationsService.verifyClientByCNPJ(cnpj)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setData({
        ...data,
        name: result.data.name,
        client_id: result.data.id,
        id_number: cnpj
      })
    } catch (error) {
      setData({
        ...data,
        name: '',
        client_id: null,
        id_number: cnpj
      })

      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const updateField = (evt) => {
    let { value } = evt.target

    if (evt.target.name === 'id_number') {
      value = cnpjMask(value)

      if (isInvalidCnpjNumber(value)) {
        setIsInvalidCnpj(true)
      } else {
        setIsInvalidCnpj(false)
        findClientByCnpj(value)
      }
    }

    setData({
      ...data,
      [evt.target.name]: value
    })
  }

  const save = async () => {
    setSaving(true)

    try {
      const payload = {
        ...data,
        'application-token': productSelected.token,
        integration_type: 'lti_client'
      }
      const response = await IntegrationsService.createIntegration(payload)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const connection = await response.json()

      setSuccessMessage(Messages.connectionForm.created)
      addConnectionOnState(connection)
      toggleConnectionForm(false)
    } catch (err) {
      setErrorMessage(Messages.connectionForm.errorOnCreate)
      // eslint-disable-next-line no-console
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  const findLibraryByCode = async () => {
    try {
      let response = await OffersService.searchServiceBySku(data.library_code)
      if (!response.ok) {
        const errors = await response.json()
        setData({
          ...data,
          library_name: '',
          service_id: null
        })
        throw errors
      }

      response = await response.json()
      setData({
        ...data,
        library_name: response.data.name,
        service_id: response.data.id
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const isValidForm = () => {
    return (
      data.name &&
      isValidCnpjNumber(data.id_number) &&
      data.lms &&
      data.lti_version &&
      data.product &&
      data.app_token
    )
  }

  const renderLibraryCodeInput = () => {
    if (productSelected.value !== 'bds') {
      return null
    }

    return (
      <>
        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="Chave da Biblioteca"
            type="text"
            name="library_code"
            value={data.library_code}
            onChange={updateField}
            onBlur={findLibraryByCode}
            size="full"
            required
          />
        </div>
        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="Nome da Biblioteca"
            type="text"
            name="library_name"
            value={data.library_name}
            onChange={updateField}
            size="full"
            disabled={!!data.library_name}
          />
        </div>
      </>
    )
  }

  useEffect(() => {
    const clearState = () => {
      setData(INITIAL_STATE[productSelected.value])
    }

    clearState()
  }, [productSelected, showingConnectionForm])

  return (
    <Dialog
      title="Adicionar Conexão com AVA"
      open={showingConnectionForm}
      secondaryButton="cancelar"
      primaryButton="Salvar"
      handleClose={() => toggleConnectionForm(false)}
      handleConfirm={() => save()}
      isDisabled={saving || !isValidForm()}
    >
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <TextInput
            autoFocus
            color="primary"
            label="CNPJ da IES"
            type="number"
            name="id_number"
            value={data.id_number}
            helperText={
              isInvalidCnpj ? 'É necessário que o CNPJ tenha 14 números.' : ''
            }
            error={isInvalidCnpj}
            onChange={updateField}
            size="full"
          />
        </div>

        <div className={classes.wrapper}>
          <TextInput
            color="primary"
            label="Nome da IES"
            disabled={
              !data.id_number ||
              isInvalidCnpjNumber(data.id_number) ||
              data.client_id
            }
            type="text"
            name="name"
            value={data.name}
            onChange={updateField}
            size="full"
          />
        </div>

        {renderLibraryCodeInput()}

        <div className={classes.wrapper}>
          <Typography variant="body1" className={classes.label}>
            Modelo de AVA
          </Typography>

          <FormControl>
            <RadioGroup
              className={classes.radioGroup}
              aria-label="ava-model"
              name="lms"
              value={data.lms}
              onChange={updateField}
              required
            >
              <FormControlLabel
                value="Moodle"
                control={<Radio color="primary" />}
                label="MOODLE"
                className={classes.radioLabel}
              />
              <FormControlLabel
                value="Canvas"
                control={<Radio color="primary" />}
                label="CANVAS"
                className={classes.radioLabel}
              />
              <FormControlLabel
                value="Blackboard"
                control={<Radio color="primary" />}
                label="BLACKBOARD"
                className={classes.radioLabel}
              />
              <FormControlLabel
                value="Brightspace"
                control={<Radio color="primary" />}
                label="BRIGHTSPACE"
                className={classes.radioLabel}
              />
              <FormControlLabel
                value="Outros"
                control={<Radio color="primary" />}
                label="OUTROS"
                className={classes.radioLabel}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.wrapper}>
          <Typography variant="body1" className={classes.label}>
            Versão do LTI
          </Typography>

          <FormControl>
            <RadioGroup
              className={classes.radioLabel}
              aria-label="lti_version"
              name="lti_version"
              value={data.lti_version}
              onChange={updateField}
              required
            >
              <FormControlLabel
                value="LTI v1.3"
                control={<Radio checked color="primary" />}
                label="LTI v1.3"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </Dialog>
  )
}

export default ConnectionForm
