import React, { useState, useContext, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import {
  Grid,
  Typography,
  Divider,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { Button, Table } from '@equipe-ninja/saraiva-ui'

import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { Snackbar } from '@equipe-ninja/saraiva-ui/core/snackbar/Snackbar'
import { useParams } from 'react-router-dom'
import { snackbarStyle } from '../../layouts/styles'
import useStyles from './styles.js'
import DialogForm from './DialogForm'
import ticketService from '../../services/ticketService'
import { GeneralContext } from '../../contexts/GeneralContext'
import ProductSelect from '../../components/ProductSelect'
import Loading from '../../components/Loading'
import Messages from '../../helpers/messages'
import { BRANDS } from '../../domain/brands/brands'
import { VouchersSection } from './components/VouchersSection'
import { PRODUCT_SELECTION_OPTIONS } from '../../domain/general/product-selection-options'
import { ConectaVouchers } from './components/ConectaVouchers'

function Libraries() {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const [libraries, setLibraries] = useState([])
  const [dropDown, setDropDown] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('number')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [alertSnackBar, setAlertSnackBar] = useState(false)
  const snackbarStyles = snackbarStyle()

  const {
    loading,
    setLoading,
    productSelected,
    setProduct,
    setErrorMessage,
    errorMessage
  } = useContext(GeneralContext)
  const { brand } = useParams()
  const node = useRef()

  async function fetchLocalLibrarie() {
    if (brand) {
      const productList = PRODUCT_SELECTION_OPTIONS.find(
        (p) => p.value === brand
      )
      if (productList) {
        fetchLibraries(productList)
      }
    }
  }

  async function fetchLibraries(product) {
    setProduct(product)
    localStorage.setItem('librarie', JSON.stringify(product))

    if (!product.value) {
      return
    }

    try {
      setLoading(true)

      const response = await ticketService.listLibraries()

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()
      setLibraries(
        result.data.sort((a, b) => a.library_archived - b.library_archived)
      )

      setLoading(false)
    } catch (err) {
      console.error(err)
      setProduct({})
      setErrorMessage(Messages.fetchLibraries.error)
    } finally {
      setLoading(false)
    }
  }

  async function searchLibrariesBy() {
    try {
      setLoading(true)
      setDropDown(false)

      const response = await ticketService.searchLibrariesBy(
        selectedFilter,
        searchValue
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setLibraries(result.data)
    } catch (err) {
      console.error(err)
      setAlertSnackBar(true)
      setErrorMessage('Não foi possível realizar a busca. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
      setDropDown(false)
    }, 800)
  ).current

  const handleSearchValue = (e) => {
    setSearchTerm(e.target.value)
    delayedQuery(e.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
    fetchLocalLibrarie()
  }

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setDropDown(false)
  }

  useEffect(() => {
    fetchLocalLibrarie()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (dropDown) {
      document.addEventListener('mousedown', handleClick)
    } else {
      document.removeEventListener('mousedown', handleClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [dropDown])

  useEffect(() => {
    searchValue.length > 0 ? searchLibrariesBy() : fetchLocalLibrarie()
    // eslint-disable-next-line
  }, [searchValue])

  const renderLibraries = () => {
    if (loading) {
      return <Loading />
    }

    if (!productSelected.value) {
      return null
    }

    return (
      <>
        <Typography
          variant="h4"
          className={`${classes.text} ${classes.textH4}`}
        >
          {productSelected.label}
        </Typography>

        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
        >
          ADICIONAR VOUCHERS
        </Button>

        <Divider className={classes.divider} />

        <Typography
          variant="h5"
          className={`${classes.text} ${classes.textH5}`}
          onClick={() => setDropDown(false)}
        >
          Bibliotecas
        </Typography>
        <div
          className={classes.searchWrapper}
          onClick={() => setDropDown(true)}
        >
          <SearchBar
            value={searchTerm}
            onChange={handleSearchValue}
            placeholder="Buscar por:"
            size="full"
            clearSearch={clearSearch}
            goBackSearch={clearSearch}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          />
        </div>
        {dropDown && (
          <Paper ref={node} className={classes.paper}>
            <p style={{ margin: '0px 0px 4px 0px' }}>Filtrar por:</p>
            <RadioGroup
              style={{ paddingLeft: 12, margin: 0 }}
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <FormControlLabel
                value="number"
                control={<Radio size="small" />}
                label="Nº da biblioteca"
              />
              <FormControlLabel
                value="code"
                control={<Radio color="primary" size="small" />}
                label="Chave da biblioteca"
              />
              <FormControlLabel
                value="name"
                control={<Radio size="small" />}
                label="Nome da biblioteca"
              />
            </RadioGroup>
          </Paper>
        )}

        {loading ? (
          <Loading />
        ) : (
          <Table
            onPageChange={() => null}
            cells={[
              'Nº da biblioteca',
              'Chave da biblioteca',
              'Nome da biblioteca',
              {
                name: 'Status da biblioteca',
                order: true,
                code: 'status'
              }
            ]}
            rows={libraries.map((library) => {
              return {
                key: library.library_code,
                number: library.library_number,
                code: library.library_code,
                name: library.library_name,
                status: library.library_archived ? (
                  <p style={{ color: '#b1b1b1', margin: 0 }}>Arquivada</p>
                ) : (
                  'Disponível'
                ),
                redirectTo: {
                  path: `/libraries/${library.library_code}`,
                  fromColumn: 'number'
                }
              }
            })}
          />
        )}

        {
          <Snackbar
            show={alertSnackBar}
            classes={() => snackbarStyles}
            handleClose={() => setAlertSnackBar(false)}
            severity="error"
          >
            {errorMessage}
          </Snackbar>
        }

        {openDialog && (
          <DialogForm
            open={openDialog}
            handleClose={() => setOpenDialog(false)}
          />
        )}
      </>
    )
  }

  return (
    <Grid className={classes.root}>
      <Typography variant="h3" className={`${classes.text} ${classes.textH3}`}>
        Gerenciar voucher
      </Typography>

      <ProductSelect
        onChange={fetchLibraries}
        productsOptions={[
          BRANDS.BDS.value,
          BRANDS.LFG.value,
          BRANDS.CONECTA.value
        ]}
        productLabel="Selecionar produto"
      />

      {productSelected.value === BRANDS.LFG.value ? (
        <VouchersSection
          brandLabel={BRANDS.LFG.label}
          brandIdentifier={BRANDS.LFG.value}
        />
      ) : productSelected.value === BRANDS.CONECTA.value ? (
        <ConectaVouchers />
      ) : (
        renderLibraries()
      )}
    </Grid>
  )
}

export default Libraries
