/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */

import React, { useContext, forwardRef } from 'react'
import { ContentsContext } from '../../../../contexts/ContentsContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { UploadContext } from '../../../../contexts/UploadContext'
import Messages from '../../../../helpers/messages'

function UploadDirectory() {
  const { toggleShowingContentMenu } = useContext(ContentsContext)

  const { setErrorMessage } = useContext(GeneralContext)

  const {
    toggleShowingUploadBar,
    setCountFileUpload,
    setTotalFiles,
    getRelativePathListFile,
    doUploadFile,
    doUploadFolder,
    clearTreePath,
    filesError,
    setReload,
    listUploadErrors,
    setErrorsBar,
    clearState
  } = useContext(UploadContext)

  async function uploadDirectory(event) {
    window.addEventListener('beforeunload', function (event) {
      event.preventDefault()
      event.returnValue = 'Mensagem de aviso'
      return 'Mensagem de aviso'
    })

    await clearState()

    try {
      toggleShowingContentMenu(null)

      const directory = event.target.files
      const files = []
      localStorage.setItem('upload', '1')

      for (const file of directory) {
        if (!file.name.includes('.DS_Store') && file.name.includes('.h5p')) {
          files.push(file)
        }
      }
      if (files.length > 0) {
        setTotalFiles(files.length)
        toggleShowingUploadBar(true)
      }
      for (const folder of files) {
        const relativePathList = getRelativePathListFile(
          folder.webkitRelativePath
        )
        await doUploadFolder(relativePathList)
      }
      localStorage.setItem('upload', '1')

      for (const file of files) {
        const relativePathList = getRelativePathListFile(
          file.webkitRelativePath
        )
        if (localStorage.getItem('upload') === '0') {
          break
        }
        const relativePath = relativePathList.join('/')
        await doUploadFile(file, relativePath)
        setCountFileUpload((currentState) => {
          return currentState + 1
        })
      }
      clearTreePath()
      setErrorsBar(listUploadErrors)
      if (filesError !== []) {
        setReload(true)
      }
    } catch (error) {
      toggleShowingUploadBar(false)
      setErrorMessage(Messages.uploadDirectory.error)
    }
  }

  return (
    <>
      <input
        id="uploadDirectory"
        type="file"
        hidden
        onChange={uploadDirectory}
        allowdirs=""
        multiple=""
        directory=""
        webkitdirectory=""
      />
    </>
  )
}
export default forwardRef(UploadDirectory)
