import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../utils/response-handlers'
import { AllQuestionsSubjectAdapter } from '../adapters/all-database-questions'

/* eslint-disable no-unused-vars */

/**
 * Caso de uso para carregamento da lista dos exercicios cadastrados no banco
 * @returns lista de exercicios do Banco de Exercicios
 */

const getAllDatabaseQuestions = async (search, page, perPage) => {
  const path = `/offers/api/v1/fixation_exercise/questions_database?search=${search}&page=${
    page + 1
  }&per_page=${perPage}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)
  const response = await httpClient.get(path, null, headers)

  const data = await getResquestResponseHandler(response)
  const adaptedData = {
    count: data.count,
    data: AllQuestionsSubjectAdapter.inbound(data.data)
  }

  return adaptedData
}

export { getAllDatabaseQuestions }
