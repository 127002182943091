import React, { useState, useEffect, useContext } from 'react'

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from '@material-ui/core'
import { Button, TextInput } from '@equipe-ninja/saraiva-ui'

import Messages from '../../../../helpers/messages'
import ContentConnection from '../../../../services/contentConnection'
import { ContentsContext } from '../../../../contexts/ContentsContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'

import useStyles from './styles'

function CreateFolderDialog() {
  const {
    addContentOnState,
    showingCreateFolderForm,
    toggleCreateFolderForm,
    moduleParentId,
    folderName,
    setFolderName,
    folderDescription,
    setFolderDescription
  } = useContext(ContentsContext)

  const { setSuccessMessage, setErrorMessage, setInfoMessage } =
    useContext(GeneralContext)

  const classes = useStyles()

  const createFolder = async () => {
    const payload = {
      name: folderName,
      description: folderDescription,
      module_parent_id: moduleParentId
    }

    try {
      const response = await ContentConnection.createFolder(payload)

      if (response.status === 422) {
        setInfoMessage(Messages.createFolder.alreadyCreated)
        return
      }
      const result = await response.json()

      toggleCreateFolderForm(false)
      setFolderName('')
      setFolderDescription('')

      const newFolder = {
        id: result.id,
        name: result.name,
        type: 'folder',
        updated_at: result.updated_at
      }
      addContentOnState(newFolder)

      setSuccessMessage(Messages.createFolder.success)
    } catch (err) {
      setErrorMessage(Messages.createFolder.error)
    }
  }

  const [disabledButton, setDisabledButton] = useState(true)
  useEffect(() => {
    setDisabledButton(!folderName || !folderDescription)
  }, [folderName, folderDescription])

  return (
    <Dialog
      open={Boolean(showingCreateFolderForm)}
      onClose={() => toggleCreateFolderForm(false)}
      fullWidth
    >
      <DialogContent className={classes.root}>
        <DialogTitle variant="h6" className={classes.titleDialog}>
          Criar Pasta
        </DialogTitle>
        <form>
          <div className={classes.wrappedInput}>
            <div className={classes.labelInput}>
              <TextInput
                autofocus
                color="primary"
                label="Nome"
                type="text"
                id="folderName"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                size="full"
              />
            </div>
            <div className={classes.labelInput}>
              <TextInput
                autofocus
                color="primary"
                label="Descrição"
                type="text"
                id="folderDescription"
                value={folderDescription}
                onChange={(e) => setFolderDescription(e.target.value)}
                size="full"
              />
            </div>
          </div>
          <DialogActions className={classes.buttonDialogAction}>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => toggleCreateFolderForm(false)}
            >
              CANCELAR
            </Button>

            <Button
              disabled={disabledButton}
              className={classes.button}
              color="primary"
              onClick={createFolder}
            >
              SALVAR
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateFolderDialog
