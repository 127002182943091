import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Folder from '@material-ui/icons/Folder'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import FolderBreadcrumb from './folderBreadcrumb'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  table: {
    padding: 16
  },
  header: {
    height: 56
  },
  row: {
    height: 52
  },
  icon: {
    paddingRight: 8
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    position: 'absolute',
    top: 20,
    width: 1
  },
  alignHeader: {
    paddingLeft: 6
  },
  cell: {
    color: theme.palette.text.secondary
  }
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
function EnhancedTableHead(props) {
  const classes = useStyles()
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow className={classes.header}>
        <TableCell
          key="name"
          align="left"
          sortDirection={orderBy === 'name' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
            className={classes.alignHeader}
          >
            Nome
            {orderBy === 'name' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="updated_at"
          align="left"
          sortDirection={orderBy === 'updated_at' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'updated_at'}
            direction={orderBy === 'updated_at' ? order : 'asc'}
            onClick={createSortHandler('updated_at')}
          >
            Última Atualização
            {orderBy === 'updated_at' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="type"
          align="left"
          sortDirection={orderBy === 'type' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'type'}
            direction={orderBy === 'type' ? order : 'asc'}
            onClick={createSortHandler('type')}
          >
            Tipo
            {orderBy === 'type' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
function getBreadcrumbItens(path) {
  const firstFolder = localStorage.getItem('@dons/folder_id')
  let show = false
  const breadcrumb = []
  path.map((folder) => {
    if (show) breadcrumb.push(folder)
    if (folder.id === firstFolder) show = true
    return breadcrumb
  })
  return breadcrumb
}
export default function FolderList({ list, path }) {
  const classes = useStyles()
  const folderBreadcrumb = getBreadcrumbItens(path)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer className={classes.table} component={Paper}>
      <FolderBreadcrumb path={folderBreadcrumb} />
      <Table aria-label="collapsible table" size="small">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(list, getComparator(order, orderBy)).map((row) => {
            return (
              <TableRow className={classes.row} hover>
                <TableCell className={classes.cell}>
                  <Link
                    className={classes.link}
                    href={
                      row.type === 'folder'
                        ? `/show_folder/${row.id}`
                        : `/show_content/${row.id}`
                    }
                  >
                    <span className={classes.icon}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        className={classes.link}
                      >
                        {row.type === 'folder' ? (
                          <Folder />
                        ) : (
                          <InsertDriveFile />
                        )}
                      </IconButton>
                    </span>
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {row.updated_at}
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {row.type === 'folder' ? `-` : `${row.type}`}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

FolderList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      type: PropTypes.number.isRequired
    })
  ).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}
EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.objectOf.isRequired
}
