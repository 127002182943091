import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Tab, Tabs, Typography } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'
import { useHistory } from 'react-router-dom'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import useStyles from './styles'
import ContentWithHeader from '../../../components/ContentWithHeader'
import { formatCPF } from '../../../helpers/formatCPF'
import { maskedPassword } from '../../../helpers/maskedPassword'
import { GeneralContext } from '../../../contexts/GeneralContext'
import EditEmailUserDialog from './components/EditEmailUserDialog'
import EditNameUserDialog from './components/EditNameUserDialog'
import EditLastNameUserDialog from './components/EditLastNameUserDialog'
import EditCpfUserDialog from './components/EditCpfUserDialog'
import { ResetPasswordDialog } from './components/ResetPasswordDialog'
import { UserProducts } from './UserProducts'
import { UserDeleteDialog } from './components/UserDeleteDialog'
import Users from '../../../services/accounts/users'

export function UserSheet({ location }) {
  const classes = useStyles()
  const history = useHistory()
  const [value, setValue] = React.useState(0)
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)
  const [nameDialogOpen, setNameDialogOpen] = useState(false)
  const [lastNameDialogOpen, setLastNameDialogOpen] = useState(false)
  const [cpfDialogOpen, setCpfDialogOpen] = useState(false)
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [currentName, setCurrentName] = useState('')
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentLastName, setCurrentLastName] = useState('')
  const [currentCpf, setCurrentCpf] = useState('')
  const { setUserData } = useContext(GeneralContext)

  useEffect(() => {
    if (location?.state?.data) {
      setUserData(location.state.data)
    }
    window.scrollTo(0, 0)
  }, [location.state, setUserData])

  const { data } = location.state || {}

  const handleUpdate = () => {
    setUpdateTrigger((prev) => !prev)
  }

  const searchUser = async () => {
    const userId = data.user_id

    try {
      const response = await Users.searchUserProducts(userId)
      if (response.status === 200) {
        const result = await response.json()

        setUserInfo(result.information.User)
      } else {
        console.error('Erro ao buscar informações do usuário:')
      }
    } catch (error) {
      console.error('Erro ao buscar informações do usuário:', error)
    }
  }
  useEffect(() => {
    if (data?.user_id) {
      searchUser()
    }
  }, [data.user_id, updateTrigger])

  useEffect(() => {
    if (userInfo) {
      setCurrentEmail(userInfo[0]?.email)
      setCurrentName(userInfo[0]?.first_name)
      setCurrentLastName(userInfo[0]?.last_name)
      setCurrentCpf(userInfo[0]?.cpf)
    }
  }, [userInfo])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Button
        color="primary"
        className={classes.backButton}
        onClick={() => history.push('/dados-dos-usuarios')}
        startIcon={<LeftArrowIcon />}
      >
        Voltar
      </Button>
      {userInfo && userInfo.length > 0 ? (
        <ContentWithHeader
          title={`${userInfo[0]?.first_name} ${userInfo[0]?.last_name}`}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="product-tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Detalhes" />
            <Tab label="Produtos" />
          </Tabs>
          <Divider style={{ marginBottom: 48 }} />
          {value === 0 ? (
            <>
              <div className={classes.cardContainer}>
                <div className={classes.infoCard}>
                  <Typography>E-mail</Typography>
                  <Typography variant="body1" className={classes.userInfo}>
                    {userInfo[0]?.email ? userInfo[0]?.email : ''}
                  </Typography>
                </div>
                <Divider />
                <div className={classes.editContainer}>
                  <Button
                    className={classes.editButton}
                    onClick={() => setEmailDialogOpen(true)}
                  >
                    Alterar Informação
                  </Button>
                  <CreateIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setEmailDialogOpen(true)}
                    className={classes.editIcon}
                  />
                </div>
              </div>
              <div className={classes.cardContainer}>
                <div className={classes.infoCard}>
                  <Typography>Senha</Typography>
                  <Typography variant="body1" className={classes.userInfo}>
                    {maskedPassword(data.email)}
                  </Typography>
                </div>
                <Divider />
                <div className={classes.editContainer}>
                  <Button
                    className={classes.editButton}
                    onClick={() => setPasswordDialogOpen(true)}
                  >
                    Resetar senha
                  </Button>
                  <CreateIcon
                    style={{ cursor: 'pointer' }}
                    className={classes.editIcon}
                    onClick={() => setPasswordDialogOpen(true)}
                  />
                </div>
              </div>
              <div className={classes.cardContainer}>
                <div className={classes.infoCard}>
                  <Typography>Nome</Typography>
                  <Typography variant="body1" className={classes.userInfo}>
                    {userInfo[0]?.first_name}
                  </Typography>
                </div>
                <Divider />
                <div className={classes.editContainer}>
                  <Button
                    className={classes.editButton}
                    onClick={() => setNameDialogOpen(true)}
                  >
                    Alterar Informação
                  </Button>
                  <CreateIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setNameDialogOpen(true)}
                    className={classes.editIcon}
                  />
                </div>
              </div>
              <div className={classes.cardContainer}>
                <div className={classes.infoCard}>
                  <Typography>Sobrenome</Typography>
                  <Typography variant="body1" className={classes.userInfo}>
                    {userInfo[0]?.last_name}
                  </Typography>
                </div>
                <Divider />
                <div className={classes.editContainer}>
                  <Button
                    className={classes.editButton}
                    onClick={() => setLastNameDialogOpen(true)}
                  >
                    Alterar Informação
                  </Button>
                  <CreateIcon
                    style={{ cursor: 'pointer' }}
                    className={classes.editIcon}
                    onClick={() => setLastNameDialogOpen(true)}
                  />
                </div>
              </div>
              <div className={classes.cardContainer}>
                <div className={classes.infoCard}>
                  <Typography>CPF</Typography>
                  <Typography variant="body1" className={classes.userInfo}>
                    {userInfo[0]?.cpf && formatCPF(userInfo[0]?.cpf)}
                  </Typography>
                </div>
                <Divider />
                <div className={classes.editContainer}>
                  <Button
                    className={classes.editButton}
                    onClick={() => setCpfDialogOpen(true)}
                  >
                    Alterar Informação
                  </Button>
                  <CreateIcon
                    style={{ cursor: 'pointer' }}
                    className={classes.editIcon}
                    onClick={() => setCpfDialogOpen(true)}
                  />
                </div>
              </div>
              <div className={classes.deleteBtnContainer}>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  className={classes.deleteButton}
                  onClick={() => setUserDialogOpen(true)}
                >
                  Excluir Usuário
                </Button>
              </div>
            </>
          ) : (
            <UserProducts userId={data.user_id} />
          )}
        </ContentWithHeader>
      ) : (
        ''
      )}

      <EditEmailUserDialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        currentEmail={currentEmail}
        onUpdated={handleUpdate}
      />

      <EditNameUserDialog
        open={nameDialogOpen}
        onClose={() => setNameDialogOpen(false)}
        currentName={currentName}
        onUpdated={handleUpdate}
      />

      <EditLastNameUserDialog
        open={lastNameDialogOpen}
        onClose={() => setLastNameDialogOpen(false)}
        currentName={currentLastName}
        onUpdated={handleUpdate}
      />

      <EditCpfUserDialog
        open={cpfDialogOpen}
        onClose={() => setCpfDialogOpen(false)}
        currentCpf={currentCpf}
        onUpdated={handleUpdate}
      />

      <ResetPasswordDialog
        passwordDialogOpen={passwordDialogOpen}
        setPasswordDialogOpen={() => setPasswordDialogOpen(false)}
      />

      <UserDeleteDialog
        userDialogOpen={userDialogOpen}
        setUserDialogOpen={() => setUserDialogOpen(false)}
        userId={data.user_id}
      />
    </>
  )
}

UserSheet.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired
}
