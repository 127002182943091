import React from 'react'
import cls from 'classnames'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { MenuItem, SelectSaraiva, TextInput } from '@equipe-ninja/saraiva-ui'

import useStyles from '../styles'
import { CARD_COMPLEMENTARY_MATERIAL_FIELDS } from '../../../../../domain/complementary-material/card-complementary-material-fields'

const { NAME, FULL_DESCRIPTION, SHORT_DESCRIPTION, ORDER } =
  CARD_COMPLEMENTARY_MATERIAL_FIELDS

const extractTextValueFromError = (error) => {
  return typeof error === 'string' ? error : ''
}

export default function FormComplementaryMaterial({
  formState,
  onChangeFormState,
  formErrors
}) {
  const classes = useStyles()

  return (
    <div className={classes.cardContent}>
      <div className={classes.cardHeader}>
        <Typography variant="h6" color="textPrimary">
          Material complementar
        </Typography>
      </div>
      <p className={`${classes.Text} ${classes.dialogFirstText}`}>
        Insira o nome deste material como deve aparecer no ambiente de
        aprendizagem.
      </p>
      <TextInput
        className={classes.marginTop16}
        label="Nome"
        onChange={(e) => onChangeFormState(NAME, e.target.value)}
        size="full"
        required
        value={formState[NAME]}
        error={!!formErrors[NAME]}
        helperText={extractTextValueFromError(formErrors[NAME])}
      />

      <p className={`${classes.Text} ${classes.marginTopMedium}`}>
        Insira a descrição breve que acompanhará o título.
      </p>
      <TextInput
        className={classes.marginTopMedium}
        label="Descrição breve"
        onChange={(e) => onChangeFormState(SHORT_DESCRIPTION, e.target.value)}
        size="full"
        required
        value={formState[SHORT_DESCRIPTION]}
        error={!!formErrors[SHORT_DESCRIPTION]}
        helperText={extractTextValueFromError(formErrors[SHORT_DESCRIPTION])}
      />

      <p className={`${classes.Text} ${classes.marginTopMedium}`}>
        Insira a descrição completa para explicar para o aluno o proposito deste
        material.
      </p>

      <TextInput
        className={classes.marginTopMedium}
        label="Descrição completa"
        multiline
        rows={4}
        onChange={(e) => {
          onChangeFormState(FULL_DESCRIPTION, e.target.value)
        }}
        size="full"
        required
        value={formState[FULL_DESCRIPTION]}
        error={!!formErrors[FULL_DESCRIPTION]}
        helperText={extractTextValueFromError(formErrors[FULL_DESCRIPTION])}
      />

      <p className={`${classes.Text} ${classes.marginTopMedium}`}>
        Insira a ordem no qual o card aparecerá na plataforma.
      </p>

      <div
        className={cls(
          classes.marginTopMedium,
          classes.marginBottomMedium,
          classes.labelOverride
        )}
      >
        <SelectSaraiva
          value={formState[ORDER]}
          onChange={(e) => {
            onChangeFormState(ORDER, e.target.value)
          }}
          label="Ordem de exibição *"
          required
          size="full"
          error={!!formErrors[ORDER]}
          helperText={extractTextValueFromError(formErrors[ORDER])}
        >
          {[0, 1, 2, 3].map((item, indexForm) => (
            <MenuItem key={item} value={indexForm + 1}>
              {indexForm + 1}
            </MenuItem>
          ))}
        </SelectSaraiva>
      </div>
    </div>
  )
}

FormComplementaryMaterial.propTypes = {
  formState: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  formErrors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  onChangeFormState: PropTypes.func.isRequired
}
