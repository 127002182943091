import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { CourseListAdapter } from './courses-adapters'
import { getResquestResponseHandler } from '../../utils/response-handlers'

/**
 * Caso de uso para carregamento de lista de usuários na pagina de gerenciamento de usuários
 * @returns lista de usuários apos passar por um adaptador de entrada
 */
export const getCoursesList = async (search = '') => {
  const path = `/offers/api/v1/course?all_courses_with_services&query=${search}`

  const headers = httpClient.buildHeader({ 'session-token': getSessionToken() })

  return new Promise((resolve, reject) => {
    httpClient
      .get(path, null, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((parsedJson) => {
        resolve(CourseListAdapter.inbound(parsedJson.data))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
