import React, { useContext, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import Users from '../../../../../services/accounts/users'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import PropTypes from 'prop-types'

export default function EditEmailUserDialog({
  open,
  onClose,
  currentEmail,
  onUpdated
}) {
  const [newEmail, setNewEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const { userData } = useContext(GeneralContext)

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return re.test(String(email).toLowerCase())
  }

  const handleEmailChange = (email) => {
    setNewEmail(email)
    setIsEmailValid(validateEmail(email))
  }

  const handleSave = async () => {
    if (isEmailValid && newEmail) {
      try {
        const user_id = userData.user_id

        const payload = {
          email: newEmail,
          first_name: '',
          last_name: '',
          cpf: ''
        }

        const response = await Users.editUser(payload, user_id)

        if (response.ok) {
          handleClose()
          onUpdated()
        } else {
          console.error('Failed to update email', await response.text())
        }
      } catch (error) {
        console.error('Failed to update email', error)
      }
    }
  }

  const handleClose = () => {
    onClose()
    setNewEmail('')
    setIsEmailValid(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { width: '600px', height: '292px' }
      }}
    >
      <DialogTitle id="form-dialog-title">
        Alterar e-mail do usuário
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="current-email"
          label="Atual e-mail"
          fullWidth
          variant="standard"
          disabled
          defaultValue={currentEmail}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            style: { borderBottom: '1px solid rgba(0, 0, 0, 0.192)' }
          }}
        />
        <TextField
          style={{ marginTop: '15px' }}
          error={!isEmailValid && newEmail}
          helperText={
            !isEmailValid && newEmail
              ? `${newEmail} não é um e-mail válido`
              : ''
          }
          id="new-email"
          label="Novo e-mail"
          fullWidth
          variant="outlined"
          value={newEmail}
          onChange={(event) => handleEmailChange(event.target.value)}
          FormHelperTextProps={{
            style: { margin: 0 }
          }}
        />
      </DialogContent>
      <Divider></Divider>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCELAR
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isEmailValid}>
          ALTERAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}
EditEmailUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentEmail: PropTypes.string,
  onUpdated: PropTypes.func.isRequired
}
