import httpRequest from '../gateway/httpRequest'

const contentsService = (request = httpRequest) => {
  const headers = {
    'application-token': process.env.REACT_APP_DONS_TOKEN
  }

  const ltiValidation = (id) =>
    request().get(
      `contents/api/v1/lti/index/?id_token=${localStorage.getItem(
        '@dons/token'
      )}&state=${localStorage.getItem('@dons/state')}&id=${id}`,
      headers
    )

  const jwtValidation = async (id) => {
    const clientId = Number(localStorage.getItem('@dons/client_id'))
    let accessToken = sessionStorage.getItem('ltiSession')

    if (!accessToken) {
      const sessionData = await request().post(
        'accounts/api/v1/sessions',
        {
          integration_type: 'jwt',
          token: localStorage.getItem('@dons/token'),
          client_name: localStorage.getItem('@dons/client_name'),
          client_id: clientId
        },
        headers
      )
      sessionStorage.setItem('ltiSession', sessionData.data.tokens.local_token)
      accessToken = sessionData.data.tokens.local_token
    }

    return request().get(
      `contents/api/v1/lti/index/?id=${id}&client_id=${clientId}`,
      {
        'application-token': process.env.REACT_APP_DONS_TOKEN,
        'session-token': accessToken
      }
    )
  }

  const getContent = (id) =>
    request().get(
      `contents/api/v1/lti/show/?id_token=${localStorage.getItem(
        '@dons/token'
      )}&id=${id}`,
      headers
    )

  const getAuthorization = () =>
    request().get('contents/api/v1/gcloud/connect', headers)

  const getH5pContent = (file) => {
    const body = {
      file
    }

    if (localStorage.getItem('@dons/client_id')) {
      body.client_id = localStorage.getItem('@dons/client_id')
      body.client_name = localStorage.getItem('@dons/client_name')
      headers['session-token'] = sessionStorage.getItem('ltiSession')
    } else {
      body.id_token = localStorage.getItem('@dons/token')
    }

    return request().post('contents/api/v1/lti/h5p_content', body, headers)
  }

  const getFolderContent = (id) =>
    request().get(`contents/api/v1/modules/${id}`, headers)

  const sendTracking = async (payload) => {
    // eslint-disable-next-line no-param-reassign
    payload = {
      ...payload,
      payload: {
        ...payload.payload,
        request_token: localStorage.getItem('@dons/token'),
        context_screen: window.screen.width,
        context_locale: navigator.language,
        context_page_path: window.location.pathname,
        context_page_referrer: document.referrer,
        context_page_url: window.location.href,
        context_user_agent: navigator.userAgent
      }
    }
    return request().post('lti_trackings', payload, headers)
  }

  return {
    ltiValidation,
    jwtValidation,
    getContent,
    getAuthorization,
    getH5pContent,
    getFolderContent,
    sendTracking
  }
}

export default contentsService
