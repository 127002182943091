import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  marginTop24: {
    marginTop: '24px'
  },
  buttonsContainers: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export default useStyles
