import React, { useState, useContext } from 'react'
import { useRecoilValue } from 'recoil'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { USER_MANAGEMENT_DETAIL_FIELDS as F } from '../../../../domain/user-management-content/user-management-page'
import { CardInfo } from './CardInfo'
import { ModalEditField } from '../Edit/ModalEditField'
import { ModalEditAddress } from '../Edit/ModalEditAddress'
import useStyles from './CardInfo/styles'
import { userDetailsState } from '../../../../atoms/user-details'

export function Details() {
  const classes = useStyles()
  const userDetails = useRecoilValue(userDetailsState)
  const fieldAddress = ['CEP', 'STREET', 'NEIGHBORHOOD', 'CITY', 'STATE']
  const [isFetching] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [openModalEditAddress, setOpenModalEditAddress] = useState(false)
  const [dataModal, setDataModal] = useState({
    field: '',
    label: '',
    value: ''
  })
  const [dataModalAddress, setDataModalAddress] = useState({
    field: '',
    label: '',
    address: ''
  })
  const { setSuccessMessage } = useContext(GeneralContext)

  const closeModalEdit = () => {
    setOpenModalEdit(!openModalEdit)
  }

  const closeModalEditAddress = () => {
    setOpenModalEditAddress(!openModalEditAddress)
  }

  // eslint-disable-next-line no-unused-vars
  const saveChange = (form) => {
    setOpenModalEdit(false)
    return setSuccessMessage('Informação do usuário alterada com sucesso')
  }

  const handleOpenDialogEdit = (key) => {
    setDataModal({
      field: F[key].value,
      label: F[key].label,
      value: userDetails[F[key].value],
      func: saveChange
    })
    setOpenModalEdit(!openModalEdit)
  }

  const handleOpenDialogEditAddress = (key) => {
    setDataModalAddress({
      field: F[key].value,
      label: F[key].label,
      address: { ...userDetails[F.ADDRESS.value] },
      func: saveChange
    })
    setOpenModalEditAddress(!openModalEditAddress)
  }

  const getJustification = (justifications, field) => {
    if (justifications) {
      const array = justifications
      return array[field]
    }
    return []
  }

  return (
    <ContainerWithLoader isLoading={isFetching}>
      <>
        <CardInfo
          onClick={() => handleOpenDialogEdit('EMAIL')}
          title={F.EMAIL.label}
        >
          <div>{userDetails[F.EMAIL.value]}</div>
        </CardInfo>
        <CardInfo
          onClick={() => handleOpenDialogEdit('PASSWORD')}
          title={F.PASSWORD.label}
          button="Alterar informação"
          justification={getJustification(
            userDetails.justifications,
            F.PASSWORD.value
          )}
        >
          <div>**********</div>
        </CardInfo>
        <CardInfo
          onClick={() => handleOpenDialogEdit('NAME')}
          title={F.NAME.label}
          button="Alterar informação"
          justification={getJustification(
            userDetails.justifications,
            F.NAME.value
          )}
        >
          <div>{userDetails[F.NAME.value]}</div>
        </CardInfo>
        <CardInfo
          onClick={() => handleOpenDialogEdit('LAST_NAME')}
          title={F.LAST_NAME.label}
          button="Alterar informação"
          justification={getJustification(
            userDetails.justifications,
            F.LAST_NAME.value
          )}
        >
          <div>{userDetails[F.LAST_NAME.value]}</div>
        </CardInfo>
        <CardInfo
          onClick={() => handleOpenDialogEdit('CPF')}
          title={F.CPF.label}
        >
          <div>{userDetails[F.CPF.value]}</div>
        </CardInfo>
        <CardInfo
          onClick={() => handleOpenDialogEditAddress('ADDRESS')}
          title={F.ADDRESS.label}
          button="Alterar informação"
          justification={getJustification(
            userDetails.justifications,
            F.ADDRESS.value
          )}
        >
          <>
            {userDetails[F.ADDRESS.value] &&
              fieldAddress.map((field) => (
                <div key={field}>
                  {F[field].label} :{' '}
                  {userDetails[F.ADDRESS.value][F[field].value]}
                </div>
              ))}
          </>
        </CardInfo>
        <div className={classes.CardContext}>
          <main
            className={`${classes.CardMain} ${classes.CardMainPaddingBottom}`}
          >
            <div className={classes.subTitle}>
              {F.CREATE_ACCOUNT_DATE.label}
            </div>
            <div className={classes.CardDetails}>
              {userDetails[F.CREATE_ACCOUNT_DATE.value]}
            </div>
            <div className={classes.subTitle}>{F.LAST_ACCESS.label}</div>
            <div className={classes.CardDetails}>
              {userDetails[F.LAST_ACCESS.value]}
            </div>
          </main>
        </div>
        <ModalEditField
          open={openModalEdit}
          onClose={closeModalEdit}
          onClick={dataModal.func}
          data={dataModal}
        />

        <ModalEditAddress
          open={openModalEditAddress}
          onClose={closeModalEditAddress}
          onClick={dataModalAddress.func}
          data={dataModalAddress}
        />
      </>
    </ContainerWithLoader>
  )
}

Details.propTypes = {}
