import React, { useState, useContext } from 'react'
import { Dialog, TextInput } from '@equipe-ninja/saraiva-ui'

import { GeneralContext } from '../../../contexts/GeneralContext'
import { ConnectionsContext } from '../../../contexts/ConnectionsContext'
import Messages from '../../../helpers/messages'
import { cnpjMask, isInvalidCnpjNumber } from '../../../helpers/cnpjControls'
import IntegrationsService from '../../../services/integrationsService'
import OffersService from '../../../services/offersService'
import useStyles from '../dialogStyles'

const DialogForm = () => {
  const classes = useStyles()

  const [saving, setSaving] = useState(false)
  const [cnpj, setCnpjValue] = useState('')
  const [isInvalidCnpj, setIsInvalidCnpj] = useState(false)
  const [cnpjErrorMessage, setCnpjErrorMessage] = useState(
    Messages.connectionForm.errorOnCNPJLength
  )
  const [iesId, setIesId] = useState(null)
  const [iesName, setIesName] = useState('')
  const [libraryId, setLibraryId] = useState(null)
  const [libraryCode, setLibraryCode] = useState('')
  const [libraryName, setLibraryName] = useState('')

  const { setSuccessMessage, setErrorMessage, productSelected } =
    useContext(GeneralContext)
  const { showingConnectionForm, toggleConnectionForm, addConnectionOnState } =
    useContext(ConnectionsContext)

  const findClientByCnpj = async (_cnpj) => {
    try {
      let response = await IntegrationsService.verifyClientByCNPJ(_cnpj)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      response = await response.json()
      setIesId(response.data.id)
      setIesName(response.data.name)
    } catch (error) {
      setIesId(null)
      setIesName(null)
      console.error(error)
    }
  }

  const setCnpj = async (_cnpj) => {
    if (_cnpj.length > 18) return

    setCnpjValue(cnpjMask(_cnpj))

    if (isInvalidCnpjNumber(_cnpj)) {
      setIsInvalidCnpj(true)
      setCnpjErrorMessage(Messages.connectionForm.errorOnCNPJLength)
    } else {
      setIsInvalidCnpj(false)
      //* TODO: Verificar se está funcionando, quando não encontra o cliente está setando o ies como vazio o que deixa o input bugado
      /* findClientByCnpj(_cnpj) */
    }
  }

  const findLibraryByCode = async () => {
    try {
      let response = await OffersService.searchServiceBySku(libraryCode)
      if (!response.ok) {
        const errors = await response.json()
        setLibraryId(null)
        setLibraryName('')
        throw errors
      }

      response = await response.json()
      setLibraryId(response.data.id)
      setLibraryName(response.data.name)
    } catch (error) {
      console.error(error)
    }
  }

  const save = async () => {
    try {
      setSaving(true)

      const payload = {
        'application-token': productSelected.token,
        integration_type: 'jwt_integration',
        name: iesName,
        id_number: cnpj,
        library_code: libraryCode,
        library_name: libraryName
      }

      const response = await IntegrationsService.createIntegration(payload)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setSuccessMessage(Messages.connectionJwt.success)
      addConnectionOnState(result)
      toggleConnectionForm(false)
    } catch (error) {
      console.error(error)
      setErrorMessage(Messages.connectionForm.errorOnCreate)
    } finally {
      setSaving(false)
    }
  }

  const isDialogDisabled = () => {
    return saving || cnpj.length !== 18
  }

  const handleClose = () => {
    setCnpjValue('')
    setIsInvalidCnpj(false)
    setIesId(null)
    setIesName('')
    setLibraryCode('')
    setLibraryName('')
    toggleConnectionForm(false)
  }

  return (
    <Dialog
      isDisabled={isDialogDisabled()}
      open={showingConnectionForm}
      size="md"
      title="Cadastrar instituição"
      secondaryButton="cancelar"
      primaryButton="salvar"
      handleClose={handleClose}
      handleConfirm={save}
    >
      <div className={classes.root}>
      <TextInput
          size="full"
          color="primary"
          label="Nome da instituição"
          value={iesName}
          onChange={(e) => setIesName(e.target.value)}
          required
          disabled={!!iesId}
        />
        <TextInput
          size="full"
          color="primary"
          label="CNPJ da instituição"
          value={cnpj}
          helperText={isInvalidCnpj ? cnpjErrorMessage : ''}
          error={isInvalidCnpj}
          onChange={(e) => setCnpj(e.target.value)}
          required
        />
      </div>
    </Dialog>
  )
}

export default DialogForm
