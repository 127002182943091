import React, { useState, useContext, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import {
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { Button, Table } from '@equipe-ninja/saraiva-ui'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import GetAppIcon from '@material-ui/icons/GetApp'
import ArrowForwarIcon from '@material-ui/icons/ArrowForwardIos'


import { Dialog } from '@equipe-ninja/saraiva-ui/core/dialog/Dialog'
import { GeneralContext } from '../../../contexts/GeneralContext'
import Tabs from '../Tabs'
import BackButton from '../../../components/BackButton'
import Loading from '../../../components/Loading'
import ExportationDialog from '../../../components/ExportationDialog'
import ticketService from '../../../services/ticketService'
import useStyles from './styles.js'
import { formatDate } from '../../../helpers/formatDate'
import { SearchBar } from '@equipe-ninja/saraiva-ui/core/search_bar/SearchBar'
import { Snackbar } from '@equipe-ninja/saraiva-ui/core/snackbar/Snackbar'
import messages from '../../../helpers/messages'
import { snackbarStyle } from '../../../layouts/styles'

function prepareExportationColumns(selectedColumns) {
  let columns = selectedColumns.map((sc) => sc.value)

  if (columns.includes('name')) {
    columns = [...columns, 'first_name', 'last_name']
    columns = columns.filter((column) => column !== 'name')
  }

  return columns
}

export default function TicketUsers() {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const [selectedUsers, selectUsers] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [showingExportationDialog, toggleExportationDialog] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('name')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searching, setSearching] = useState(false)
  const [alertSnackBar, setAlertSnackBar] = useState(false)
  const [ticketCode, setTicketCode] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const snackbarStyles = snackbarStyle()

  const { loading, setLoading, setErrorMessage, errorMessage } =
    useContext(GeneralContext)
  const { ticketId, libraryCode } = useParams()

  const history = useHistory()
  const dropDownNode = useRef()

  useEffect(() => {
    fetchUsers()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchUsers()
    // eslint-disable-next-line
  }, [ticketCode])

  useEffect(() => {
    fetchTickets()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    searchValue.length > 0 ? searchUsersBy() : fetchUsers()
    // eslint-disable-next-line
  }, [searchValue])

  useEffect(() => {
    if (dropDown) {
      document.addEventListener('mousedown', toCloseDropDown)
    } else {
      document.removeEventListener('mousedown', toCloseDropDown)
    }

    return () => {
      document.removeEventListener('mousedown', toCloseDropDown)
    }
  }, [dropDown])

  async function fetchUsers() {
    try {
      setLoading(true)

      const response = await ticketService.fetchUsersByTicketId(ticketId)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setUsers(result.data)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setErrorMessage(
        'Não foi possível carregar os usuários. Tente novamente mais tarde.'
      )
    }
  }

  async function fetchTickets() {
    try {
      setLoading(true)

      const response = await ticketService.fetchTicketsByLibraryCode(
        libraryCode
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      if (result.data.some((ticket) => ticket.archived === true)) {
        setIsArchived(true)
      }

      const ticket = result.data.find((obj) => obj.id === parseInt(ticketId))
      setTicketCode(ticket.code)

      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const unbindUsers = async () => {
    const unbindedUsers = selectedUsers.map((user) => {
      return {
        id: user.key.split('#')[0],
        activated_at: user.key.split('#')[1]
      }
    })

    const payload = {
      list_users: unbindedUsers
    }

    try {
      setLoading(true)
      const response = await ticketService.unbindUsers(ticketId, payload)

      if (response.status === 204) {
        fetchUsers()
        selectUsers([])
        setOpenDialog(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const exportUsers = async (selectedColumns) => {
    try {
      setExporting(true)

      const payload = {
        userIds: selectedUsers.map((user) =>  {
          return {
            id: Number(user.key.split("#")[0]),
            activated_at: user.key.split("#")[1]
          }
        }),
        columns: prepareExportationColumns(selectedColumns),
      }

      const response = await ticketService.exportTicketUsers(ticketId, payload)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const universalBOM = '\uFEFF'
      const csvContent = universalBOM + (await response.text())
      const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8'
      })
      const data = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = data
      link.download = `voucher-${ticketId}-users.csv`
      link.click()

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        setExporting(false)
        toggleExportationDialog(false)
      }, 100)
    } catch (error) {
      console.error(error)
      setErrorMessage('Não foi possível exportar os usuários.')
      setExporting(false)
    }
  }

  async function searchUsersBy() {
    try {
      setSearching(true)
      setDropDown(false)

      const response = await ticketService.searchUsersBy(
        ticketId,
        selectedFilter,
        searchValue
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      setUsers(result.data)
    } catch (err) {
      console.error(err)
      setAlertSnackBar(true)
      setErrorMessage('Não foi possível realizar a busca. Tente novamente.')
    } finally {
      setSearching(false)
    }
  }

  const delayedQuery = useRef(
    debounce((e) => {
      setSearchValue(e)
    }, 800)
  ).current

  const handleSearchValue = (e) => {
    setSearchTerm(e.target.value)
    delayedQuery(e.target.value)
  }

  const ticketStatus = (user) => {
    if (
      parseInt(user.recent_ticket_id) === parseInt(ticketId) &&
      user.recent_activated === user.ticket_activated_at &&
      !user.inactivated_at
    ) {
      return 'Ativo'
    }
    return 'Inativo'
  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchValue('')
  }

  const toCloseDropDown = (e) => {
    if (dropDownNode && dropDownNode.current.contains(e.target)) {
      return
    }
    setDropDown(false)
  }

  const renderExportationDialog = () => {
    if (!showingExportationDialog) {
      return null
    }

    return (
      <ExportationDialog
        handleClose={() => toggleExportationDialog(false)}
        handleExport={exportUsers}
        exporting={exporting}
        columns={[
          { label: 'Nome', value: 'name' },
          { label: 'E-mail', value: 'email' },
          { label: 'Ativado em', value: 'ticket_activated_at' },
          { label: 'Inativado em', value: 'inactivated_at' },
          { label: 'Status', value: 'status' }
        ]}
      />
    )
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <BackButton
        onClick={() => history.push(`/libraries/${libraryCode}/tickets`)}
      />

      <Typography variant="h4">Biblioteca {libraryCode}</Typography>

      {renderExportationDialog()}

      <Paper variant="outlined" className={classes.paper}>
        <Tabs activeTab="tickets" />

        {isArchived && (
          <div className={classes.snackDiv}>
            <Snackbar classes={() => snackbarStyles} noClose severity="info">
              {messages.archivedLibrary.details}
            </Snackbar>
          </div>
        )}
        <div
          className={
            selectedUsers.length > 0
              ? `${classes.content} ${classes.contentBG}`
              : classes.content
          }
        >
          <div className={classes.breadcrumbs}>
            <Button
              color="primary"
              onClick={() =>
                window.location.assign(`/libraries/${libraryCode}/tickets`)
              }
            >
              INÍCIO
            </Button>

            <ArrowForwarIcon
              style={{
                fontSize: 14,
                marginLeft: 9,
                marginRight: 9,
                fontWeight: 500
              }}
            />

            <Button color="primary" onClick={() => {}}>
              {ticketCode}
            </Button>
          </div>
          {selectedUsers.length > 0 ? (
            <div className={classes.buttons}>
              <Button
                color="primary"
                onClick={() => setOpenDialog(true)}
                startIcon={<ToggleOffIcon />}
                variant="outlined"
                disabled={selectedUsers.length === 0 || isArchived}
                className={classes.button}
              >
                DESVINCULAR USUÁRIO
              </Button>

              <Button
                color="primary"
                onClick={() => toggleExportationDialog(true)}
                startIcon={<GetAppIcon />}
                variant="contained"
                disabled={selectedUsers.length === 0}
                className={classes.button}
              >
                EXPORTAR
              </Button>
            </div>
          ) : (
            <div
              className={classes.searchWrapper}
              onClick={() => setDropDown(true)}
            >
              <SearchBar
                value={searchTerm}
                onChange={handleSearchValue}
                clearSearch={clearSearch}
                placeholder="Buscar por:"
                size="sm"
                goBackSearch={clearSearch}
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              />
              {dropDown && (
                <Paper ref={dropDownNode} className={classes.paperSearch}>
                  <p style={{ margin: '0px 0px 4px 0px' }}>Filtrar por:</p>
                  <RadioGroup
                    style={{ paddingLeft: 12, margin: 0 }}
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                  >
                    <FormControlLabel
                      value="name"
                      control={<Radio size="small" />}
                      label="Nome"
                    />
                    <FormControlLabel
                      value="email"
                      control={<Radio size="small" />}
                      label="E-mail"
                    />
                  </RadioGroup>
                </Paper>
              )}
            </div>
          )}
        </div>

        {searching ? (
          <Loading />
        ) : (
          <Table
            displayRowCheckbox
            selectedRows={selectedUsers}
            onCheckRows={(rows) => selectUsers(rows)}
            cells={[
              'Nome',
              'E-mail',
              {
                name: 'Ativado em',
                order: true,
                code: 'ticket_activated_at'
              },
              'Inativado em',
              'Status',
            ]}
            rows={users
              .map((user) => {
              return {
                key: user.id + '#' + user.ticket_activated_at,
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                activatedAt: formatDate(user.ticket_activated_at),
                inactivatedAt: user.inactivated_at
                  ? formatDate(user.inactivated_at)
                  : '-',
                ticket_status: ticketStatus(user),
                className: ticketStatus(user) === "Ativo" ? "activated" : classes.inactivated
              }
            })
            .sort((a, b) => a.ticket_status.localeCompare(b.ticket_status))
          }
          />
        )}
      </Paper>

      {openDialog && (
        <Dialog
          open={openDialog}
          title="Desvincular usuário"
          secondaryButton="cancelar"
          primaryButton="desvincular"
          size="md"
          handleClose={() => setOpenDialog(false)}
          handleConfirm={unbindUsers}
          label="Sim, tenho certeza!"
          alert
        >
          Usuários ao serem desvinculados não estarão mais associados a este
          voucher, perdendo seu acesso a biblioteca. Observe que a conta deste
          usuário não será deletada do nosso sistema.
        </Dialog>
      )}

      {
        <Snackbar classes={() => snackbarStyles}
          show={alertSnackBar}
          handleClose={() => setAlertSnackBar(false)}
          severity="error"
        >
          {errorMessage}
        </Snackbar>
      }
    </div>
  )
}
