import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    minHeight: '88px',
    width: '100%'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    padding: '24px',
    position: 'absolute',
    right: 0,
    top: 0
  }
}))

export default useStyles
