import Joi from 'joi'

import {
  MAX_QUESTION_ALTERNATIVES_COUNT,
  MIN_QUESTION_ALTERNATIVES_COUNT,
  QUESTION_ALTERNATIVES_FIELDS,
  QUESTION_FIELDS
} from './question-fields'
import { validateAndGenerateErrors } from '../../helpers/validation'

const questionAlternativeSchema = Joi.object({
  [QUESTION_ALTERNATIVES_FIELDS.CONTENT]: Joi.string().required()
})

const questionFormSchema = Joi.object({
  [QUESTION_FIELDS.KEY_WORDS]: Joi.string().required(),
  [QUESTION_FIELDS.TABLE_TEST]: Joi.string().required(),
  [QUESTION_FIELDS.TEST_YEAR]: Joi.string().required(),
  [QUESTION_FIELDS.ENUNCIATION]: Joi.string().required(),
  [QUESTION_FIELDS.ALTERNATIVES]: Joi.array()
    .items(questionAlternativeSchema)
    .min(MIN_QUESTION_ALTERNATIVES_COUNT)
    .max(MAX_QUESTION_ALTERNATIVES_COUNT),
  [QUESTION_FIELDS.CORRECT_ALTERNATIVE]: Joi.number().required()
})

export const questionFormValidation = (formState) => {
  return validateAndGenerateErrors(formState, questionFormSchema)
}
