export const USER_FIELDS_LIST_ITEM = {
  KEY: 'id',
  FULL_NAME: 'fullName',
  CPF: 'cpf',
  EMAIL: 'email',
  STATUS: 'status',
  NAME: 'name',
  LAST_NAME: 'lastname',
  STREET: 'street',
  CEP: 'cep',
  CITY: 'city',
  NEIGHBORHOOD: 'neighborhood',
  STATE: 'state',
  CREATE_ACCOUNT_DATE: `createAccountDate`,
  LAST_ACCESS: `lastAccess`,
  ACCESS_LOCALE: `accessLocale`,
  JUSTIFICATIONS: 'justifications',
  MOVING_DATE: 'movingDate',
  CHANGE_BY: 'changedBy',
  INFORMATION_CHANGE: 'informationChange',
  JUSTIFICATION: 'justification'
}
