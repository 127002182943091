import { REMOTE_DISCOUNT_FIELDS as REMOTE } from '../remote-fields/discount-fields'
import { DISCOUNT_CAMPAIGN_FIELDS as FIELDS } from '../../../../domain/promotional-content/discounts-campaign'

const statusInbound = (status) => {
  return status ? 'Em exibição no site' : 'Inativo'
}

const inbound = (data) => {
  return data.map((item) => ({
    [FIELDS.ID]: item[REMOTE.ID],
    [FIELDS.CAMPAIGN_NAME]: item[REMOTE.CAMPAIGN_NAME],
    [FIELDS.CAMPAIGN_STATUS]: statusInbound(item[REMOTE.CAMPAIGN_STATUS])
  }))
}

export const DiscountAdapter = {
  listInbound: inbound
}
