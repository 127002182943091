export const COURSE_OVERVIEW_RAW_DATA = {
  SERVICE: 'service',
  COURSE: 'course',
  FULL_POSTED_SUBJECT_COUNT: 'subjectCount'
}

export const COURSE_OVERVIEW = {
  TITLE: 'title',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  STATUS: 'status'
}
