import React, { useContext, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import PropTypes from 'prop-types'
import cls from 'classnames'
import Alert from '@material-ui/lab/Alert'
import { TextInput, Button } from '@equipe-ninja/saraiva-ui'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ContentsProvider } from '../../../../contexts/ContentsContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { UploadProvider } from '../../../../contexts/UploadContext'
import {
  initialURLValueForBrand,
  BRANDS_IDENTIFIER
} from '../../../../domain/brands'
import { ECOMMERCE_FORM_FIELDS } from '../../../../domain/courses/entities'
import {
  generalInfoFieldValidation,
  generalInfoValidation
} from '../../../../domain/product-course/business'
import { ImageDimension } from '../../../../helpers/imageDimension'
import {
  decimalPipeMask,
  numberPipeMask
} from '../../../../helpers/input-pipe-masks'
import { ButtonUpload } from '../../../promotional/BannersPage/Form/ButtonUpload'
import useStyles from './styles'
import { ecommerceForm, courseFetchingControls } from '../../../../atoms/course'
import { CategorySelector } from '../../../../components/LFG/CategorySelector'

const {
  ACCESS_WINDOW,
  CATEGORY,
  COURSE_LENGTH,
  DESCRIPTION,
  IMG_BINARY,
  IMG_CHANGED,
  IMG_URL,
  KEY_WORDS,
  SUB_CATEGORY,
  URL,
  VALUE
} = ECOMMERCE_FORM_FIELDS

const WIDTH = 624
const HEIGHT = 307

export const GeneralInfoForm = (props) => {
  const { onSuccess } = props
  const { isExecutingTask } = useRecoilValue(courseFetchingControls)
  const [{ values, errors }, setEcommerceForm] = useRecoilState(ecommerceForm)
  const { setErrorMessage } = useContext(GeneralContext)
  const [isImgValid, setIsImgValid] = useState(true)

  const classes = useStyles()

  const onChangeEvent = (event, field, adapter) => {
    let newValue = event.target.value
    if (adapter) {
      newValue = adapter(newValue)
    }

    const validation = generalInfoFieldValidation(field, newValue, values)

    setEcommerceForm((prev) => ({
      ...prev,
      values: { ...prev.values, [field]: newValue },
      errors: { ...prev.errors, ...validation }
    }))
  }

  const validateImgDimenstions = (image) => {
    return ImageDimension.imageSizeValidator(image, {
      width: WIDTH,
      height: HEIGHT
    })
  }

  const onUpload = (field, file) => {
    validateImgDimenstions(file).then((isValid) => {
      if (isValid) {
        const validation = generalInfoFieldValidation(IMG_BINARY, file, values)

        setEcommerceForm((prev) => ({
          ...prev,
          values: { ...prev.values, [field]: file, [IMG_CHANGED]: true },
          errors: { ...prev.errors, ...validation }
        }))
        setIsImgValid(true)
      } else {
        setIsImgValid(false)
      }
    })
  }

  const onDeleteImg = () => {
    setEcommerceForm((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [IMG_BINARY]: null,
        [IMG_URL]: '',
        [IMG_CHANGED]: true
      }
    }))
  }

  const onNextClick = () => {
    const { isValid, errors: validationErrors } = generalInfoValidation(values)

    if (isValid) {
      onSuccess()
      return
    }

    setErrorMessage(
      'Houve falhas de validação, verifique os campos em vermelho'
    )

    setEcommerceForm((prev) => ({
      ...prev,
      errors: { ...prev.errors, ...validationErrors }
    }))
  }

  return (
    <section>
      <Typography className={classes.marginTopXL} variant="h5">
        Detalhes
      </Typography>

      <TextInput
        className={classes.marginTopMedium}
        error={!!errors[DESCRIPTION]}
        helperText={errors[DESCRIPTION]}
        label="Descrição completa do que o curso oferece"
        multiline
        rows={4}
        onChange={(evt) => onChangeEvent(evt, DESCRIPTION)}
        required
        size="full"
        value={values[DESCRIPTION]}
      />

      <TextInput
        className={classes.marginTopMedium}
        error={!!errors[COURSE_LENGTH]}
        helperText={errors[COURSE_LENGTH]}
        label="Carga horária"
        onChange={(evt) => onChangeEvent(evt, COURSE_LENGTH, numberPipeMask)}
        size="full"
        required
        value={values[COURSE_LENGTH]}
      />

      <TextInput
        className={classes.marginTopMedium}
        error={!!errors[ACCESS_WINDOW]}
        helperText={errors[ACCESS_WINDOW]}
        label="Tempo de acesso em meses"
        onChange={(evt) => onChangeEvent(evt, ACCESS_WINDOW, numberPipeMask)}
        size="full"
        required
        value={values[ACCESS_WINDOW]}
      />

      <TextInput
        className={classes.marginTopMedium}
        error={!!errors[VALUE]}
        helperText={errors[VALUE]}
        label="Preço do curso"
        onChange={(evt) => onChangeEvent(evt, VALUE, decimalPipeMask)}
        size="full"
        required
        value={values[VALUE]}
      />

      <Typography className={classes.marginTopXL} variant="h5">
        Categoria
      </Typography>

      <CategorySelector
        category={values[CATEGORY]}
        categoryError={errors[CATEGORY]}
        categoryField={CATEGORY}
        subcategory={values[SUB_CATEGORY]}
        subcategoryError={errors[SUB_CATEGORY]}
        subcategoryField={SUB_CATEGORY}
        onChange={onChangeEvent}
        className={classes.marginTopMedium}
      />

      <Typography className={classes.marginTopXL} variant="h5">
        URL
      </Typography>

      <TextInput
        className={classes.marginTopMedium}
        error={!!errors[URL]}
        startAdornment={
          <InputAdornment>{`${initialURLValueForBrand(
            BRANDS_IDENTIFIER.LFG
          )}`}</InputAdornment>
        }
        helperText={
          <div style={{ marginLeft: '-12px' }}>
            {errors[URL] && (
              <span style={{ display: 'block' }}>{errors[URL]}</span>
            )}

            <span>
              {`Inserir o subdiretório da URL, ou seja as páginas internas do seu site: ${initialURLValueForBrand(
                BRANDS_IDENTIFIER.LFG
              )}`}
              <strong>concursos-cursos-carreiras-juridicas</strong>
            </span>
          </div>
        }
        label="URL do curso"
        onChange={(evt) => onChangeEvent(evt, URL)}
        size="full"
        required
        value={values[URL]}
      />

      <TextInput
        className={classes.marginTopMedium}
        helperText={
          <span style={{ marginLeft: '-12px' }}>
            Inserir palavras chave no formato: Direito civil, Jurídico, (palavra
            seguido de virgula e espaço).
          </span>
        }
        label="Palavras-chave - Informações SEO"
        onChange={(evt) => onChangeEvent(evt, KEY_WORDS)}
        size="full"
        value={values[KEY_WORDS]}
      />

      <Typography className={classes.marginTopXL} variant="h5">
        Imagem do curso
      </Typography>

      <Typography className={classes.marginTopMedium} variant="body2">
        {`Utilizar JPG ou PNG no tamanho ${WIDTH}x${HEIGHT}px`}
      </Typography>

      <div className={classes.marginTopSmaller}>
        <ContentsProvider>
          <UploadProvider>
            <ButtonUpload
              className={classes.uploadFile}
              btnLabel="ANEXAR IMAGEM DO CURSO"
              field={IMG_BINARY}
              image={values[IMG_URL] || values[IMG_BINARY]}
              onDelete={(field, type) => onDeleteImg(field, type)}
              onUpload={(field, file, type) => onUpload(field, file, type)}
            />
          </UploadProvider>
        </ContentsProvider>

        {!isImgValid && (
          <Alert severity="error" style={{ marginTop: '4px' }}>
            A imagem fornecido não atende às dimensões esperadas
          </Alert>
        )}

        {errors[IMG_BINARY] && (
          <Alert severity="error" style={{ marginTop: '4px' }}>
            Campo obrigatório
          </Alert>
        )}
      </div>

      <div className={cls(classes.formActionsContainer, classes.marginTopXL)}>
        <Button color="primary" disabled>
          ANTERIOR
        </Button>

        <Button
          className={classes.marginLeftSmall}
          color="primary"
          disabled={isExecutingTask}
          onClick={onNextClick}
          variant="outlined"
        >
          PRÓXIMO
        </Button>
      </div>
    </section>
  )
}

GeneralInfoForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
}

GeneralInfoForm.defaultProps = {}
