import {
  PROMOTIONAL_PAGES_LIST_ITEM_FIELDS as LIST_FIELDS,
  PUBLISHING_STATUS
} from '../../../../domain/promotional-content/promotional-pages'
import { REMOTE_FIELDS_LIST_ITEM } from '../use-cases/promotional-pages-remote-fields'
import { RemoteDateUtils } from '../../../utils/remote-date-utils'

export const listItemAdapterInbound = (remoteDataList) => {
  return remoteDataList.map((remoteData) => ({
    [LIST_FIELDS.KEY.value]: remoteData[REMOTE_FIELDS_LIST_ITEM.KEY],

    [LIST_FIELDS.TITLE.value]: remoteData[REMOTE_FIELDS_LIST_ITEM.TITLE],

    [LIST_FIELDS.URL.value]: remoteData[REMOTE_FIELDS_LIST_ITEM.URL],

    [LIST_FIELDS.START_DATE.value]: RemoteDateUtils.parseDateStringToHuman(
      remoteData[REMOTE_FIELDS_LIST_ITEM.START_DATE]
    ),

    [LIST_FIELDS.END_DATE.value]: RemoteDateUtils.parseDateStringToHuman(
      remoteData[REMOTE_FIELDS_LIST_ITEM.END_DATE]
    ),

    [LIST_FIELDS.STATUS.value]:
      remoteData[REMOTE_FIELDS_LIST_ITEM.STATUS] === true
        ? PUBLISHING_STATUS.ACTIVE.label
        : PUBLISHING_STATUS.INACTIVE.label
  }))
}
