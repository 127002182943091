import { getSessionToken } from '../../../auth'

import httpClient from '../../../gateway/generalHttpRequest'
import httpRequest from '../../../gateway/httpRequest'

const updateFileContent = async (dataFile) => {
  const path = `/contents/api/v1/complementary_content/upload`
  let headers = {
    'session-token': getSessionToken()
  }
  headers = httpClient.buildHeader(headers)
  headers = {
    ...headers,
    'Content-Type': 'multipart/form-data'
  }

  const response = await httpRequest().post(path, dataFile, headers)

  return response
}

export { updateFileContent }
