import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Divider } from '@material-ui/core'
import { SelectSaraiva } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles.js'
import { PRODUCT_SELECTION_OPTIONS } from '../../domain/general/product-selection-options'
import { BRANDS_IDENTIFIER } from '../../domain/brands/brands'

export const ProductSelectNoContext = ({
  onChange,
  brandOptions,
  selectedBrand
}) => {
  const [productSelected, setProductSelected] = useState()
  const classes = useStyles()

  const productList = brandOptions.map((brand) =>
    PRODUCT_SELECTION_OPTIONS.find((p) => p.value === brand)
  )

  const changeHandler = (ev) => {
    const brandIdentifierFromEvent = ev.target.value

    setProductSelected(brandIdentifierFromEvent)

    onChange(brandIdentifierFromEvent)
  }

  useEffect(() => {
    setProductSelected(selectedBrand)
  }, [selectedBrand])

  return (
    <>
      <div className={classes.root}>
        <SelectSaraiva
          label="Produtos"
          onChange={(ev) => changeHandler(ev)}
          value={productSelected}
        >
          <MenuItem value="">
            <em>Nenhum Produto</em>
          </MenuItem>

          {productList.map((product) => (
            <MenuItem value={product.value} key={product.value}>
              {product.label}
            </MenuItem>
          ))}
        </SelectSaraiva>
      </div>

      <Divider className={classes.divider} />
    </>
  )
}

ProductSelectNoContext.propTypes = {
  onChange: PropTypes.func.isRequired,
  /**
   * A lista dos produtos que serao exibidos no dropdown
   *
   * ### Exemplo
   *
   * ```js
   * const brandOptions = ['bds', 'lfg']
   *
   * <ProductSelectNoContext {...} brandOptions={brandOptions} />
   * ```
   */
  brandOptions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(BRANDS_IDENTIFIER))
  ).isRequired,
  /**
   * valor da brand selecionada
   * vide: {@link BRANDS_IDENTIFIER (link)}
   */
  selectedBrand: PropTypes.string
}

ProductSelectNoContext.defaultProps = {
  selectedBrand: ''
}
