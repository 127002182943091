/* eslint-disable array-callback-return */
import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import {
  VOUCHER_FIELDS as FIELDS,
  VOUCHER_TYPES
} from '../../../domain/vouchers'
import {
  createVoucherManyUsesAdapter,
  createVoucherOneUseAdapter
} from '../adapters/vouchers-adapters'
import { postResquestResponseHandler } from '../../utils/response-handlers'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para criacao de voucher
 * @returns dados do voucher criado ou uma lista de errors
 */
const createVouchers = (formValues) => {
  return new Promise((resolve, reject) => {
    const path = '/tickets/api/v1/tickets/ecommerce'
    let payload = ''
    if (formValues[FIELDS.VOUCHER_TYPE] === VOUCHER_TYPES.MANY_USES.value) {
      payload = createVoucherManyUsesAdapter(formValues)
    } else {
      payload = createVoucherOneUseAdapter(formValues)
    }

    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .post(path, payload, headers)
      .then((response) => postResquestResponseHandler(response))
      .then((json) => {
        resolve(json)
      })
      .catch((error) => reject(error))
  })
}
/* eslint-enable no-unused-vars */

export { createVouchers }
