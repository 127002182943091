import React from 'react'
import { Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { RoutesMapping } from '../helpers/routes-mapping'
import { PrivateRoute } from '../components/PrivateRoute'
import { UserManagemant } from '../pages/UserManagement'
import { UserDetail } from '../pages/UserManagement/UserDetail'

export function UserManagementContentRoutes() {
  return (
    <RecoilRoot>
      <Switch>
        <PrivateRoute
          exact
          path={RoutesMapping.userManagementPage}
          component={UserManagemant}
          defaultLayout
        />

        <PrivateRoute
          path={RoutesMapping.userManagementPageDetail}
          component={UserDetail}
          defaultLayout
        />
      </Switch>
    </RecoilRoot>
  )
}
