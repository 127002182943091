export const PAYMENT_INFO_FIELDS = Object.freeze({
  ORDER_ITEM_ID: 'id',
  PAYMENT_TYPE: 'paymentType',

  CREDIT_CARD: 'creditCard',
  CREDIT_CARD_HOLDER_NAME: 'holderName',
  INSTALLMENTS: 'installments',

  BANK_SLIP_CODE: 'code',
  BANK_SLIP_DUE_DATE: 'dueDate',
  PERSON_ID: 'personId',

  VALUE: 'value'
})
