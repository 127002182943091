import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Grid, useMediaQuery } from '@material-ui/core'
import { SimpleHeader } from '@equipe-ninja/saraiva-ui'
import {
  SettingsInputCompositeRounded,
  SchoolRounded,
  Announcement,
  Lock
} from '@material-ui/icons'
import MenuDrawer from './Drawer'
import userService from '../services/accounts/users'

import { useStyles } from './styles'

const menuItems = [
  {
    icon: <SchoolRounded />,
    label: 'Conteúdo',
    onClick: () => {
      window.location.assign('/contents')
    }
  },
  {
    icon: <SettingsInputCompositeRounded />,
    label: 'Conexões',
    onClick: () => {
      window.location.assign('/connections')
    }
  },
  {
    icon: <Lock />,
    label: 'Gerenciar acessos',
    onClick: () => window.location.assign('/access_manager')
  },
  {
    icon: <Announcement />,
    label: 'Feedback',
    onClick: () => alert('Você clicou em Feedback')
  }
]

export default function Layout({ children }) {
  const classes = useStyles()
  const history = useHistory()

  const onClickHome = () => history.push('/')

  const onExit = () => {
    const { REACT_APP_SSO_URL } = process.env

    localStorage.removeItem('sessionToken')

    window.location = `${REACT_APP_SSO_URL}/logout`
  }

  function renderDrawer(isdesktopScreen) {
    if (isdesktopScreen) {
      return (
        <Grid>
          <MenuDrawer />
        </Grid>
      )
    }
    return null
  }

  return (
    <Grid container>
      <Grid item className={classes.layoutHeader}>
        <SimpleHeader
          email={userService.getEmail()}
          username={userService.getFullName()}
          onClickHome={onClickHome}
          onExit={onExit}
          bottomItems={menuItems}
        />
      </Grid>
      {renderDrawer(useMediaQuery('(min-width:600px)'))}
      <Grid item xs={12} className={classes.layoutContainer}>
        {children}
      </Grid>
    </Grid>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
