export const COURSE_SERVICE_STATUS = {
  DRAFT: 'draft',
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available'
}

export const courseServiceStatusLabel = (status) => {
  switch (status) {
    case COURSE_SERVICE_STATUS.AVAILABLE:
      return 'Disponível para venda'

    case COURSE_SERVICE_STATUS.UNAVAILABLE:
      return 'Inativo'

    default:
      return 'Rascunho'
  }
}
