import links from '../components/config/h5p/links'

function createLinkTag(src) {
  const link = document.createElement('link')
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('type', 'text/css')
  link.setAttribute('href', src)
  return link
}

export default function importLinks() {
  let link = null
  const linkFragment = document.createDocumentFragment()
  links.forEach((src) => {
    link = createLinkTag(src)
    linkFragment.appendChild(link)
  })
  return linkFragment
}
