import React from 'react'
import PropTypes from 'prop-types'
import { CardInfo } from '@equipe-ninja/saraiva-ui'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import Alert from '@material-ui/lab/Alert'
import useStyles from './styles'
import {
  SCHEDULED_CONTENT_FIELDS,
  scheduledContentExerciseTypeLabel
} from '../../../../../../domain/courses'
import { formatDate } from '../../../../../../helpers/data-transformation'

const bgWhite = {
  background: '#fff'
}

const {
  ANSWER_CONTENT,
  ANSWER_PUBLISHING_DATE,
  CONTENT,
  END_DATE,
  ID,
  INITIAL_DATE,
  LINK,
  TITLE,
  SCHEDULED_CONTENT_TYPE,
  EXERCISE_TYPE
} = SCHEDULED_CONTENT_FIELDS

export const ExerciceCard = (props) => {
  const { data, onClick, position } = props
  const classes = useStyles()

  return (
    <div style={{ marginTop: -16 }}>
      <CardInfo
        className={bgWhite}
        icon={<CreateIcon className={classes.icon} />}
        text="Alterar exercício"
        onClick={onClick}
      >
        <Typography variant="body1" color="textPrimary">
          Exercicio {position}
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.marginTopSmaller}
        >
          {`Tipo de exercício: ${scheduledContentExerciseTypeLabel(
            data?.[EXERCISE_TYPE]
          )}`}
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.marginTopSmaller}
        >
          {`Data de inicio: ${formatDate(data?.[INITIAL_DATE])}`}
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.marginTopSmaller}
        >
          {`Data de fim: ${formatDate(data?.[END_DATE])}`}
        </Typography>
      </CardInfo>
    </div>
  )
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
const StylizeForValue = ({ value }) => {
  const classes = useStyles()
  const hasValue = value?.trim()?.length > 0

  return (
    <span className={hasValue ? classes.valid : classes.invalid}>
      {hasValue ? 'Cadastrado' : 'Não cadastrado'}
    </span>
  )
}

const AlertWhenStarted = ({ date, comparedTo }) => {
  const classes = useStyles()

  if (!date) return null

  if (date > comparedTo) return null

  return (
    <Alert severity="error" className={classes.marginTopSmaller}>
      A data de inicio deste exercício já está em vigência. Cadastre as
      informações necessárias para que não prejudique os alunos.
    </Alert>
  )
}
/* eslint-enable react/prop-types */
/* eslint-enable no-unused-vars */

ExerciceCard.propTypes = {
  data: PropTypes.shape({
    [ANSWER_CONTENT]: PropTypes.string,
    [ANSWER_PUBLISHING_DATE]: PropTypes.instanceOf(Date),
    [CONTENT]: PropTypes.string,
    [END_DATE]: PropTypes.instanceOf(Date),
    [ID]: PropTypes.number,
    [INITIAL_DATE]: PropTypes.instanceOf(Date),
    [LINK]: PropTypes.string,
    [TITLE]: PropTypes.string,
    [SCHEDULED_CONTENT_TYPE]: PropTypes.string,
    [EXERCISE_TYPE]: PropTypes.string
  }),
  onClick: PropTypes.func,
  position: PropTypes.number.isRequired
}

ExerciceCard.defaultProps = {
  data: {},
  onClick: () => null
}
