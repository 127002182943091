import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const ConnectionsContext = createContext()

function ConnectionsProvider({ children }) {
  const [connections, setConnections] = useState([])
  const [showingConnectionForm, toggleConnectionForm] = useState(false)

  const addConnectionOnState = (connection) => {
    setConnections([connection, ...connections])
  }

  return (
    <ConnectionsContext.Provider
      value={{
        connections,
        setConnections,

        showingConnectionForm,
        toggleConnectionForm,

        addConnectionOnState
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  )
}

ConnectionsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { ConnectionsContext, ConnectionsProvider }
