export const COURSE_LIST_ITEM_FIELDS = Object.freeze({
  KEY: { value: 'key', label: 'identificador' },
  COURSE_NAME: { value: 'CourseName', label: 'Curso' },
  URL: { value: 'url', label: 'URL' },
  SKU: { value: 'sku', label: 'SKU' },
  CHANGE_DATE: { value: 'changeDate', label: 'Data de alteração' },
  SALE_START_DATE: {
    value: 'saleStartDate',
    label: 'Data de início da venda'
  },
  COURSE_PRICE: { value: 'coursePrice', label: 'Preço' },
  PUBLICATION_STATE: {
    value: 'publicationStatus',
    label: 'Status de publicação'
  }
})
