import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    padding: '120px 20px 0px 270px',
    [theme.breakpoints.down(600)]: {
      padding: '100px 16px 105px 16px'
    }
  },
  layoutHeader: {
    zIndex: theme.zIndex.drawer + 1
  }
}))


export const snackbarStyle = makeStyles(() => ({
  root: {
    top: 84
  }
}))