import React, { useContext, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import { GeneralContext } from '../../../../../contexts/GeneralContext'
import Users from '../../../../../services/accounts/users'
import PropTypes from 'prop-types'
export default function EditCpfUserDialog({
  open,
  onClose,
  currentCpf,
  onUpdated
}) {
  const [cpf, setCpf] = useState('')
  const [isCpfValid, setIsCpfValid] = useState(false)
  const [containsLetters, setContainsLetters] = useState(false)
  const { userData } = useContext(GeneralContext)

  const validateCpf = (cpf) => {
    const isValidFormat = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/.test(cpf.trim())
    const containsLetters = /[a-zA-Z]/.test(cpf)
    return { isValidFormat, containsLetters }
  }

  const handleCpfChange = (cpf) => {
    const { isValidFormat, containsLetters } = validateCpf(cpf)
    setCpf(cpf)
    setIsCpfValid(isValidFormat)
    setContainsLetters(containsLetters)
  }

  const handleSave = async () => {
    if (isCpfValid && cpf) {
      try {
        const user_id = userData.user_id

        const payload = {
          email: '',
          first_name: '',
          last_name: '',
          cpf: cpf
        }

        const response = await Users.editUser(payload, user_id)
        if (response.ok) {
          handleClose()
          onUpdated()
        } else {
          console.error('Failed to update email', await response.text())
        }
      } catch (error) {
        console.error('Failed to update email', error)
      }
    }
  }

  const handleClose = () => {
    setCpf('')
    setIsCpfValid(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { width: '600px', height: '292px' }
      }}
    >
      <DialogTitle id="form-dialog-title">Alterar CPF do usuário</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="current-email"
          label="Atual CPF"
          fullWidth
          variant="standard"
          disabled
          defaultValue={currentCpf}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            style: { borderBottom: '1px solid rgba(0, 0, 0, 0.192)' }
          }}
        />
        <TextField
          style={{ marginTop: '15px' }}
          error={containsLetters}
          helperText={
            containsLetters ? 'Não é possível inserir letras nesse campo' : ''
          }
          id="new-cpf"
          label="CPF"
          fullWidth
          variant="outlined"
          value={cpf}
          onChange={(event) => handleCpfChange(event.target.value)}
          FormHelperTextProps={{
            style: { margin: 0 }
          }}
        />
      </DialogContent>
      <Divider></Divider>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCELAR
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isCpfValid}>
          ALTERAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}
EditCpfUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentEmail: PropTypes.string,
  onUpdated: PropTypes.func.isRequired
}
