import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { Dialog, TextInput, DatePickerSaraiva } from '@equipe-ninja/saraiva-ui'
import Typography from '@material-ui/core/Typography'
import { ContainerWithLoader } from '../../../../components/ContainerWithLoader'
import { CompletionStatus } from '../Orders/components/CompletionStatus'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { extendAccessCourse } from '../../../../services/user-management-content'
import useStyles from './styles'

export const CourseExtendDialog = (props) => {
  const {
    showDialog,
    onExtendAccessSuccess,
    onCloseDialog,
    courseId,
    courseStatus,
    courseDateEnd
  } = props

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [state, setState] = useState({
    isExecutingTask: false,
    isFetching: false
  })
  const [reason, setReason] = useState({
    value: '',
    error: ''
  })
  const [courseDateEndExtend, setCourseDateEndExtend] = useState({
    value: new Date(),
    error: ''
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [messageToConfirm, setMessageToConfirm] = useState(
    `'${courseDateEnd} para ${new Date().toLocaleDateString('pt-BR', {
      timeZone: 'UTC'
    })}'`
  )

  const classes = useStyles()

  const onChange = (event) => {
    const value = event.target?.value
    let error = ''
    if (value.trim() === '') {
      setIsDisabled(true)
      error = 'O campo é obrigatório'
    } else {
      setIsDisabled(false)
    }
    setReason((prev) => ({ ...prev, value, error }))
  }

  const executeExtendAccess = () => {
    setState((prev) => ({ ...prev, isExecutingTask: true }))

    extendAccessCourse(courseId)
      .then(() => {
        setSuccessMessage('Curso prorrogado com sucesso')
        setOpenConfirmDialog(false)
        setReason('')
        onExtendAccessSuccess()
      })
      .catch(() => {
        setErrorMessage('Não foi possível prorrogar o curso')
      })
      .finally(() => {
        setState((prev) => ({ ...prev, isExecutingTask: false }))
      })
  }

  const closeDialogHandler = () => {
    setReason((prev) => ({ ...prev, error: '', value: '' }))
    onCloseDialog()
  }

  const changeStep = () => {
    setOpenConfirmDialog(!openConfirmDialog)
    onCloseDialog()
  }

  const onChangeHandler = (selectedDate) => {
    setCourseDateEndExtend({ value: selectedDate, error: '' })
    setMessageToConfirm(
      `'${courseDateEnd} para ${selectedDate.toLocaleDateString('pt-BR', {
        timeZone: 'UTC'
      })}'`
    )
  }

  return (
    <>
      <Dialog
        open={showDialog}
        title="Prorrogar acesso"
        primaryButton="Próximo"
        secondaryButton="Cancelar"
        handleClose={() => closeDialogHandler()}
        handleConfirm={() => changeStep()}
        isDisabled={isDisabled || state.isExecutingTask}
      >
        <ContainerWithLoader isLoading={state.isFetching}>
          <div className={classes.contentContainer}>
            <Typography
              className={cls(classes.contentItem, classes.textPrimary)}
              variant="h6"
            >
              Dados do curso
            </Typography>

            <Typography className={cls(classes.CardSubTitle)}>
              Status do usuário no curso
            </Typography>
            <div className={cls(classes.text)}>
              <CompletionStatus status={courseStatus} />
            </div>

            <Typography className={cls(classes.CardSubTitle)}>
              Data de fim do curso
            </Typography>
            <Typography className={cls(classes.text, classes.text2)}>
              {courseDateEnd}
            </Typography>

            <DatePickerSaraiva
              label="Data de prorrogação"
              value={courseDateEndExtend.value}
              size="lg"
              onChange={(selectedDate) => {
                onChangeHandler(selectedDate)
              }}
              error={false}
              helperText=""
            />

            <TextInput
              className={cls(classes.contentItem2, classes.marginTopMedium)}
              label="Justificativa de alteração*"
              color="primary"
              helperText={reason.error}
              error={!!reason.error}
              size="full"
              value={reason.value}
              onChange={onChange}
            />
          </div>
        </ContainerWithLoader>
      </Dialog>

      <Dialog
        title="Prorrogar acesso"
        secondaryButton="CANCELAR"
        primaryButton="SALVAR"
        size="md"
        handleClose={changeStep}
        handleConfirm={() => executeExtendAccess()}
        open={openConfirmDialog}
        label="Sim, tenho certeza!"
        alert
      >
        {messageToConfirm}
      </Dialog>
    </>
  )
}

CourseExtendDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onExtendAccessSuccess: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  courseId: PropTypes.number.isRequired,
  courseStatus: PropTypes.string.isRequired,
  courseDateEnd: PropTypes.string.isRequired
}
