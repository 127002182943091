import {
  DatePickerSaraiva,
  Button,
  MenuItem,
  SelectSaraiva
} from '@equipe-ninja/saraiva-ui'
import React, { useState, useContext, useEffect } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { isNumber } from 'lodash'
import PropTypes from 'prop-types'
import { FormBannerSale } from '../Form/FormBannerSale'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { BANNER_PAGES_LIST_ITEM_FIELDS as FIELDS } from '../../../../domain/promotional-content/promotional-pages/banner-pages-list-item-fields'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { initialURLValueForBrand } from '../../../../domain/brands'
import useStyles from '../styles'
import {
  bannerRotationValidation,
  bannerRotationFieldValidation
} from '../../../../domain/promotional-content/banners/business/banners-ratativo-validation'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import ContentTitle from '../../../../components/ContentTitle'
import { createBanner } from '../../../../services/promotional-content/banners-pages'

// eslint-disable-next-line no-unused-vars
export function CreateBannerSale({ state, onChange }) {
  const classes = useStyles()
  const history = useHistory()
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const [isExecutingTask, setIsExecutingTask] = useState(false)

  const {
    KEY,
    TITLE,
    URL,
    SESSION,
    ORDER,
    IMG_WEB,
    IMG_RECURSIVE,
    START_DATE,
    END_DATE,
    STATUS
  } = FIELDS

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalBannerList,
    hasAccessToPromotionalPages
  )

  const [formState, setFormState] = useState({
    type: 'sale',
    [KEY.value]: null,
    [TITLE.value]: 'Banner ofertas em destaque',
    [URL[0].value]: URL.map(() => initialURLValueForBrand(brand)),
    [SESSION.value]: 2,
    [ORDER[0].value]: ORDER.map((_, i) => (i + 1).toString()),
    [IMG_WEB.value]: '',
    [IMG_RECURSIVE[0].value]: IMG_RECURSIVE.map(() => ''),
    [END_DATE.value]: null,
    [START_DATE.value]: null,
    [STATUS.value]: ''
  })

  const [formErrors, setFormErrors] = useState({
    type: 'sale',
    [KEY.value]: '',
    [TITLE.value]: '',
    [URL[0].value]: URL.map(() => ''),
    [SESSION.value]: 2,
    [ORDER[0].value]: ORDER.map(() => ''),
    [IMG_WEB.value]: null,
    [IMG_RECURSIVE[0].value]: IMG_RECURSIVE.map(() => null),
    [START_DATE.value]: '',
    [END_DATE.value]: '',
    [STATUS.value]: ''
  })

  const onChangeOrder = (newValue, itemIndex) => {
    const currentOrderValue = formState[ORDER[0].value][itemIndex]
    const currentHolderIndexForNewValue = formState[ORDER[0].value].reduce(
      (prev, current, index) => {
        if (prev) return prev
        return current === newValue ? index : prev
      },
      null
    )

    setFormState((prevState) => ({
      ...prevState,
      [ORDER[0].value]: prevState[ORDER[0].value].map((item, index) => {
        if (index === itemIndex) return newValue
        if (index === currentHolderIndexForNewValue) return currentOrderValue
        return item
      })
    }))
  }

  const onChangeHandler = (field, dateValue, sequence) => {
    if (isNumber(sequence)) {
      if (field === ORDER[0].value) {
        onChangeOrder(dateValue, sequence)
      } else {
        const newState = formState[field].map((e, i) => {
          return i === sequence ? dateValue : e
        })

        setFormState((prev) => ({ ...prev, [field]: newState }))
      }
    } else {
      const fieldValidation = bannerRotationFieldValidation(
        field,
        dateValue,
        formState,
        'create'
      )
      setFormState({ ...formState, [field]: dateValue })
      setFormErrors((prev) => ({ ...prev, ...fieldValidation }))
    }
    onChange(field, dateValue)
  }

  const onCreate = () => {
    createBanner(formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalBannerList, { brand })
        )
        setSuccessMessage(
          `Banner '${response[TITLE.value]}' criado com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const hasFormValidation = () => {
    const { isValid, errors } = bannerRotationValidation(formState)

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }

    onCreate()
  }

  useEffect(() => {
    setFormState({
      ...formState,
      urls: [
        state.urls || initialURLValueForBrand(brand),
        initialURLValueForBrand(brand),
        initialURLValueForBrand(brand),
        initialURLValueForBrand(brand)
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ContentTitle title={formState[TITLE.value]} size="small" />
      {URL.map((url_, index) => (
        <FormBannerSale
          formState={formState}
          formErrors={formErrors}
          onChange={onChangeHandler}
          sequence={index}
        />
      ))}
      <ContentTitle title="Publicar" size="small" />
      <div
        className={classes.contentDisplayLeft}
        style={{ alignItems: 'flex-start' }}
      >
        <DatePickerSaraiva
          error={!!formErrors[START_DATE.value]}
          helperText={formErrors[START_DATE.value]}
          label="Data de início do banner *"
          value={formState[START_DATE.value]}
          onChange={(selectedDate) => {
            onChangeHandler(START_DATE.value, selectedDate)
          }}
          size="sm"
        />
        <DatePickerSaraiva
          error={!!formErrors[END_DATE.value]}
          helperText={formErrors[END_DATE.value]}
          label="Data de fim do banner *"
          value={formState[END_DATE.value]}
          onChange={(selectedDate) => {
            onChangeHandler(END_DATE.value, selectedDate)
          }}
          size="sm"
        />
      </div>
      <div className={classes.contentDisplayLeft}>
        <SelectSaraiva
          error={!!formErrors[STATUS.value]}
          helperText={formErrors[STATUS.value]}
          value={formState[STATUS.value]}
          onChange={(e) => {
            onChangeHandler(STATUS.value, e.target.value)
          }}
          label="Status da publicação*"
          required
          size="full"
        >
          <MenuItem value="">Nenhum status de publicação</MenuItem>
          <MenuItem value="ativo">Em exibição no site</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </SelectSaraiva>
      </div>
      <div className={classes.footerUpdate} style={{ marginBottom: 178 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={hasFormValidation}
          disabled={isExecutingTask}
        >
          SALVAR
        </Button>
      </div>
      <SnackbarOutlet />
    </>
  )
}

CreateBannerSale.propTypes = {
  state: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired
}
