import { atom } from 'recoil'
import { locateTopicById } from '../../domain/courses/register-elearning-page/business'
import { REGISTER_ELEARNING_ALL_SUBJECTS_LIST_FIELDS as F } from '../../domain/courses/register-elearning-page/register-elearning-all-subject-list-fields'

export const defaultBreadcrumbsValue = () => [{ label: 'Todos', value: null }]
export const breadcrumbsEntry = (label, value) => ({ label, value })

export const computeSelectedTopicsByTopicId = (allTopics, selectedTopicId) => {
  const topic = locateTopicById(selectedTopicId, allTopics)

  return {
    selectedTopicData: topic?.[F.CHILDREN.value] ?? null
  }
}

export const computeSelectedTopicFromState = (state) => {
  const { breadcrumbs, allTopics, selectedTopicData } = state

  const topicId = breadcrumbs?.[breadcrumbs.length - 1]?.value

  if (!topicId) return { selectedTopicData }

  return {
    selectedTopicData:
      locateTopicById(topicId, allTopics)?.[F.CHILDREN.value] ?? null
  }
}

export const courseTopicsState = atom({
  key: 'courseTopicsState',
  default: {
    isFetching: false,
    courseTitle: '',
    isFetchingReusableTopics: false,
    isFetchingQuestions: false,
    isExecutingTask: false,
    subjectSearch: '',
    allTopics: [],
    topicVisitingHierarchy: [],
    selectedTopicData: null,
    selectedTopicId: null,
    breadcrumbs: defaultBreadcrumbsValue(),
    requestId: 0,
    requestTimeStamp: 0,
    reusableTopics: [],
    questionsDatabase: { data: [], count: 0 }
  }
})
