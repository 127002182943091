import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: '24px 0px'
  },
  divider: {
    margin: '0px 0px 40px 0px'
  }
}))

export default useStyles
