import { Dialog, TextInput } from '@equipe-ninja/saraiva-ui'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@material-ui/core'
import useStyles from './styles'
import userService from '../../../../services/accounts/users'
import { userEditValidation } from '../../../../domain/user-management-content/user-management-page/user-managent-edit-validation'

export function ModalEditField({ open, data, onClose, onClick }) {
  const styles = useStyles()
  const { field, label, value } = data
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [formState, setFormState] = useState({
    [field]: '',
    old: '',
    justify: ''
  })
  const [formErrors, setFormErrors] = useState({})
  const [informationChange, setInformationChange] = useState('')
  const [disabledButtonNext, setDisabledButtonNext] = useState(true)

  const changeStep = () => {
    setOpenConfirmDialog(!openConfirmDialog)
    onClose()
  }

  const handleTitle = () => {
    if (field === 'password') {
      return `Resetar ${label.toLowerCase()} do usuário`
    }
    return `Alterar ${label.toLowerCase()} do usuário`
  }

  const validateFields = (form) => {
    const { errors } = userEditValidation(form, value)
    setFormErrors(errors)
    if (!errors[field] && !errors.justify) {
      setDisabledButtonNext(false)
      const message =
        field === 'password'
          ? 'Resetar senha do usuário?'
          : `'${value}' para '${form[field]}'?`
      setInformationChange(message)
    } else {
      setDisabledButtonNext(true)
    }
  }

  const onChangeHandlerForEvent = (fieldTochange, valueInput) => {
    setFormState({ ...formState, [fieldTochange]: valueInput })
    validateFields({ ...formState, [fieldTochange]: valueInput })
  }

  const handleSaveChanges = () => {
    setOpenConfirmDialog(false)
    onClick({
      ...formState,
      date: new Date(),
      changedBy: userService.getEmail(),
      informationChange
    })
  }

  useEffect(() => {
    setFormState({ justify: '', [field]: value, old: value })
    setFormErrors({})
  }, [value])

  return (
    <>
      <Dialog
        open={open}
        title={handleTitle()}
        secondaryButton="CANCELAR"
        primaryButton="PRÓXIMO"
        handleClose={() => onClose(false)}
        handleConfirm={changeStep}
        content=""
        size="md"
        isDisabled={disabledButtonNext}
      >
        <Divider />
        <div className={styles.divInputs}>
          {field !== 'password' && (
            <TextInput
              color="primary"
              size="full"
              className={styles.inputs}
              label={label}
              onChange={(e) =>
                onChangeHandlerForEvent(`${field}`, e.target.value)
              }
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              required
              fullWidth
              value={formState[field]}
            />
          )}
          <TextInput
            color="primary"
            size="full"
            label="Justificativa da alteração"
            onChange={(e) => onChangeHandlerForEvent('justify', e.target.value)}
            error={!!formErrors.justify}
            helperText={formErrors.justify}
            required
            fullWidth
            value={formState.justify}
          />
        </div>
      </Dialog>

      <Dialog
        title={handleTitle()}
        secondaryButton="CANCELAR"
        primaryButton="SALVAR"
        size="md"
        handleClose={changeStep}
        handleConfirm={handleSaveChanges}
        open={openConfirmDialog}
        label="Sim, tenho certeza!"
        alert
      >
        {informationChange}
      </Dialog>
    </>
  )
}

ModalEditField.defaultProps = {
  open: false
}

ModalEditField.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.objectOf.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
