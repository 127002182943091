import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './styles'

/**
 * Monta um componente que exibirá um loader no lugar do conteudo de acordo com a parametro fornecido
 *
 * ### Exemplo
 *
 * ```jsx
 * <ContainerWithLoader
 *  isLoading={isLoading}
 *  className={classes.loaderComFundoVermelho}
 * >
 *  <h1>Conteudo</h1>
 * </ContainerWithLoader>
 * ```
 */
export const ContainerWithLoader = ({ isLoading, children, className }) => {
  const classes = useStyles()
  return (
    <div className={[classes.container, className].join(' ')}>
      {isLoading ? (
        <span className={classes.loaderContainer}>
          <CircularProgress />
        </span>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

ContainerWithLoader.propTypes = {
  /** quando `true` exibirá o loader, caso contrário exibirá os filhos */
  isLoading: PropTypes.bool.isRequired,
  /** Conteudo que sera exibido quando `isLoading` for falso */
  children: PropTypes.node,
  /** Class para customização do container */
  className: PropTypes.string
}

ContainerWithLoader.defaultProps = {
  children: null,
  className: ''
}
