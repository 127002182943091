export const PUBLISHING_STATUS = Object.freeze({
  ACTIVE: {
    label: 'Em exibição no site',
    value: 'active'
  },
  INACTIVE: {
    label: 'Inativo',
    value: 'inative'
  }
})
