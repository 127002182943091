import { getSessionToken } from '../../../auth'
import { CardComplementaryMaterialAdapter } from '../adapters/card-complementary-material'
import httpClient from '../../../gateway/generalHttpRequest'
import {
  postResquestResponseHandler,
  putRequestResponseHandler
} from '../../utils/response-handlers'

const createComplementaryMaterial = async (complementaryMaterial) => {
  const path = '/contents/api/v1/complementary_material'
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)

  const response = await httpClient.post(
    path,
    CardComplementaryMaterialAdapter.outbound(complementaryMaterial),
    headers
  )
  const responseJson = await postResquestResponseHandler(response)

  return CardComplementaryMaterialAdapter.inbound(responseJson)
}

const updateComplementaryMaterial = async (complementaryMaterial) => {
  const path = `/contents/api/v1/complementary_material/${complementaryMaterial.id}`
  let headers = { 'session-token': getSessionToken() }
  headers = httpClient.buildHeader(headers)

  const response = await httpClient.put(
    path,
    CardComplementaryMaterialAdapter.outbound(complementaryMaterial),
    headers
  )
  const responseJson = await putRequestResponseHandler(response)

  return CardComplementaryMaterialAdapter.inbound(responseJson)
}

export { createComplementaryMaterial, updateComplementaryMaterial }
