export const VOUCHER_LIST_ITEM_FIELDS = Object.freeze({
  ID: 'key',
  CAMPAIGN_NAME: 'name',
  PREFIX: 'code',
  TOTAL_AS_TEXT: 'totalAsText',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  DISCOUNT_VALUE: 'discountValue',
  VOUCHER_TYPE: 'type',
  STATUS: 'status'
})
