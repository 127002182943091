import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 24
  },
  hiddenButton: {
    marginTop: 24,
    display: 'none'
  }
}))

export default useStyles
