import { selector } from 'recoil'
import {
  courseTopicsState,
  defaultBreadcrumbsValue
} from '../course-topics-state'

const breadcrumbUpdater = (currentState, subjectSearch) => {
  if (currentState.subjectSearch !== subjectSearch) {
    return { breadcrumbs: defaultBreadcrumbsValue() }
  }

  return {}
}

export const subjectSearchSelector = selector({
  key: 'subjectSearchSelector',
  get: ({ get }) => {
    return {
      subjectSearch: get(courseTopicsState).subjectSearch
    }
  },
  set: ({ get, set }, { subjectSearch }) => {
    const breadcrumbNextState = breadcrumbUpdater(
      get(courseTopicsState),
      subjectSearch
    )
    set(courseTopicsState, {
      ...get(courseTopicsState),
      subjectSearch,
      ...breadcrumbNextState
    })
  }
})
