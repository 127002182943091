import React, { useState, useContext } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { Button } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'
import ContentWithHeader from '../../../../components/ContentWithHeader'
import { SnackbarOutlet } from '../../../../components/SnackbarOutlet'
import { RoutesMapping } from '../../../../helpers/routes-mapping'
import { useRequiredParamsAccess } from '../../../../hooks/use-required-param-access'
import { hasAccessToPromotionalPages } from '../../../../domain/promotional-content/promotional-pages-access-for-brands'
import { PROMOTIONAL_PAGES_FIELDS } from '../../../../domain/promotional-content/promotional-pages'
import {
  promotionalPageValidation,
  promotionalPageFieldValidation
} from '../../../../domain/promotional-content/promotional-pages/business'
import { initialURLValueForBrand } from '../../../../domain/brands'
import { createPromotionalPage } from '../../../../services/promotional-content/promotional-pages'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { PromotionalPageForm } from '../PromotionalPageForm'

export const PromotionalPagesNew = () => {
  const classes = useStyles()
  const history = useHistory()
  const [isExecutingTask, setIsExecutingTask] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const {
    KEY,
    CONTENT,
    END_DATE,
    REDIRECT_URL,
    START_DATE,
    STATUS,
    TITLE,
    URL
  } = PROMOTIONAL_PAGES_FIELDS

  const { brand } = useRequiredParamsAccess(
    'brand',
    RoutesMapping.promotionalPageEntryPotint,
    hasAccessToPromotionalPages
  )

  const [formState, setFormState] = useState({
    [KEY.value]: null,
    [CONTENT.value]: '',
    [END_DATE.value]: null,
    [REDIRECT_URL.value]: initialURLValueForBrand(brand),
    [START_DATE.value]: null,
    [STATUS.value]: '',
    [TITLE.value]: '',
    [URL.value]: initialURLValueForBrand(brand)
  })

  const [formErrors, setFormErrors] = useState({
    [KEY.value]: false,
    [CONTENT.value]: false,
    [END_DATE.value]: false,
    [REDIRECT_URL.value]: false,
    [START_DATE.value]: false,
    [STATUS.value]: false,
    [TITLE.value]: false,
    [URL.value]: false
  })

  const backButtonPath = () => {
    return generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
  }

  const validateField = (field, value) => {
    const validationResult = promotionalPageFieldValidation(
      field,
      value,
      formState,
      'create'
    )

    setFormErrors((prev) => ({ ...prev, ...validationResult }))
  }

  const onChangeField = (field, newValue) => {
    setFormState((prevState) => {
      return { ...prevState, [field]: newValue }
    })

    validateField(field, newValue)
  }

  const createPage = () => {
    setIsExecutingTask(true)

    createPromotionalPage(formState)
      .then((response) => {
        history.replace(
          generatePath(RoutesMapping.promotionalPromoPagesList, { brand })
        )
        setSuccessMessage(
          `Página promocional '${response[TITLE.value]}' criada com sucesso`
        )
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsExecutingTask(false)
      })
  }

  const submitForm = () => {
    const { isValid, errors } = promotionalPageValidation(formState, 'create')

    if (!isValid) {
      setFormErrors(errors)
      setErrorMessage(
        'Não foi possível salvar, por favor, verifique os campos em vermelho'
      )
      return
    }

    createPage()
  }

  return (
    <ContentWithHeader title="Criar página" backButtonRoute={backButtonPath()}>
      <PromotionalPageForm
        brand={brand}
        formErrors={formErrors}
        formState={formState}
        onChange={onChangeField}
      />

      <div
        className={[classes.buttonsContainers, classes.marginTop24].join(' ')}
      >
        <Button
          disabled={isExecutingTask}
          color="primary"
          onClick={submitForm}
          variant="contained"
        >
          Salvar
        </Button>
      </div>

      <SnackbarOutlet />
    </ContentWithHeader>
  )
}
