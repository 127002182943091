export const BRANDS_IDENTIFIER = Object.freeze({
  BDS: 'bds',
  SSO: 'sso',
  DONS: 'dons',
  LFG: 'lfg',
  CONECTA: 'conecta'
})

export const BRANDS = Object.freeze({
  BDS: {
    label: 'Biblioteca Digital Saraiva',
    value: BRANDS_IDENTIFIER.BDS
  },
  SSO: {
    label: 'SSO',
    value: BRANDS_IDENTIFIER.SSO
  },
  DONS: {
    label: 'DONS',
    value: BRANDS_IDENTIFIER.DONS
  },
  LFG: {
    label: 'LFG',
    value: BRANDS_IDENTIFIER.LFG
  },
  CONECTA: {
    label: 'Saraiva Conecta',
    value: BRANDS_IDENTIFIER.CONECTA
  }
})
