import React, { useContext } from 'react'

import { Breadcrumbs } from '@material-ui/core'

import { Button } from '@equipe-ninja/saraiva-ui'
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons'
import { ContentsContext } from '../../../../contexts/ContentsContext'

import useStyles from './styles'

function BreadcrumbsMenu() {
  const { fetchContents, contentsPath } = useContext(ContentsContext)

  const classes = useStyles()

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon />}
      className={classes.breadcrumbs}
    >
      <Button color="primary" onClick={() => fetchContents()}>
        Início
      </Button>

      {contentsPath.map((content) => (
        <Button
          key={content.id}
          color="primary"
          onClick={() => fetchContents(content.id)}
        >
          {content.name}
        </Button>
      ))}
    </Breadcrumbs>
  )
}

export default BreadcrumbsMenu
