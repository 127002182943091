import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { listItemAdapterInbound } from '../adapters/course-subject-page-adapters'
import { getResquestResponseHandler } from '../../utils/response-handlers'

/**
 * Caso de uso para carregamento de lista de disciplinas na tela de cadastro de curso no ambiente de aprendizagem
 * @returns lista de disciplinas cadastradas no curso
 */
const getCourseSubjectsPagesList = async (courseId) => {
  const path = `/offers/api/v1/backoffice/course/${courseId}/topics`
  const headers = httpClient.buildHeader({ 'session-token': getSessionToken() })
  const response = await httpClient.get(path, null, headers)

  const data = await getResquestResponseHandler(response)

  const adaptedData = listItemAdapterInbound(data)

  return adaptedData
}

export { getCourseSubjectsPagesList }
