import { selector } from 'recoil'
import { scheduledContentState } from '../scheduled-content-state'

export const scheduledContentFormSelector = selector({
  key: 'scheduledContentFormSelector',
  get: ({ get }) => {
    return get(scheduledContentState).form
  },
  set: ({ get, set }, newValue) => {
    set(scheduledContentState, {
      ...get(scheduledContentState),
      form: newValue
    })
  }
})
