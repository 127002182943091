import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const AccessService = {
  async getAccessUsers() {
    const path = `accounts/api/v1/users/sso?app_token=a6bc757d2df972ab8f7e3cfe499d147`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async searchUsersByNameEmail(searchParam) {
    const path = `accounts/api/v1/users/sso?keyword=${searchParam}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async releaseAccess(userId) {
    const path = `accounts/api/v1/users/release-access-to-the-bo`
    let headers = {
      'session-token': getSessionToken(),
      'application-token': process.env.REACT_APP_TOKEN
    }
    let body = {
      user_id: userId,
      application_name: 'backoffice'
    }

    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, body, headers)
    return response
  }
}

export default AccessService
