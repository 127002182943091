import React from 'react'
import { Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { RoutesMapping } from '../helpers/routes-mapping'
import { PrivateRoute } from '../components/PrivateRoute'
import { VouchersNew } from '../pages/vouchers/VouchersNew'
import { VouchersEdit } from '../pages/vouchers/VouchersEdit'

export function VouchersRoutes() {
  return (
    <RecoilRoot>
      <Switch>
        <PrivateRoute
          component={VouchersNew}
          defaultLayout
          exact
          path={RoutesMapping.vouchersCreate}
        />

        <PrivateRoute
          component={VouchersEdit}
          defaultLayout
          exact
          path={RoutesMapping.vouchersEdit}
        />
      </Switch>
    </RecoilRoot>
  )
}
