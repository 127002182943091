import { CategoryAdapter } from '../adapters/category'
import httpClient from '../../../gateway/generalHttpRequest'
import { getResquestResponseHandler } from '../../utils/response-handlers'
import { filterSelectablesCategories } from '../../../domain/product-course/business'

/**
 * Caso de uso para buscar a lista de categories
 * @returns Promise com a resposta ou rejeita com erro
 */
export const getCategories = () => {
  const path = `/offers/api/v1/categories`
  const headers = httpClient.buildHeader()

  return new Promise((resolve, reject) => {
    httpClient
      .get(path, headers)
      .then((response) => getResquestResponseHandler(response))
      .then((body) => {
        resolve(filterSelectablesCategories(CategoryAdapter.inbound(body.data)))
      })
      .catch((error) => reject(error))
  })
}
