import { getSessionToken } from '../../../auth'
import httpClient from '../../../gateway/generalHttpRequest'
import { mockedServerAnswer } from '../../../helpers/mock-server-utils'
import { userDetailAdapterInbound } from '../adapters/user-detail-adapters'
import { USER_FIELDS_LIST_ITEM as REMOTE_FIELDS } from '../remote-fields/user-list-fields'

/* eslint-disable no-unused-vars */
/**
 * Caso de uso para buscar os detalhes do usuário
 * @returns Promise com a resposta ou rejeita com erro
 */
const getUserDetail = (id) => {
  return new Promise((resolve, reject) => {
    const path = `promotional/api/v1/services/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)

    httpClient
      .get(path, headers)
      .then((response) => {
        resolve(userDetailAdapterInbound(response))
      })
      .catch((error) => reject(error))
  })
}

const userDetailfactory = (id) => {
  return {
    [REMOTE_FIELDS.KEY]: id,
    [REMOTE_FIELDS.NAME]: `Usuário ${id}`,
    [REMOTE_FIELDS.LAST_NAME]: `sobrenome`,
    [REMOTE_FIELDS.CPF]: `${id}999999999${id}`,
    [REMOTE_FIELDS.EMAIL]: 'danilofelipe.mendes@gmail.com',
    [REMOTE_FIELDS.ADDRESS]: {
      [REMOTE_FIELDS.CEP]: '30295-375',
      [REMOTE_FIELDS.STREET]: 'Rua Santo Antônio, 605',
      [REMOTE_FIELDS.NEIGHBORHOOD]: 'Conjunto Taquaril',
      [REMOTE_FIELDS.CITY]: 'Belo Horizonte',
      [REMOTE_FIELDS.STATE]: 'MG'
    },
    [REMOTE_FIELDS.CREATE_ACCOUNT_DATE]: new Date(2022, 1, 18, 17, 15),
    [REMOTE_FIELDS.LAST_ACCESS]: new Date(2022, 0, 18, 17, 15),
    [REMOTE_FIELDS.ACCESS_LOCALE]: `Rua Santo Antônio, 605, Conjunto Taquaril, Belo Horizonte, MG`,
    [REMOTE_FIELDS.JUSTIFICATIONS]: {
      [REMOTE_FIELDS.NAME]: [
        {
          [REMOTE_FIELDS.MOVING_DATE]: new Date(2022, 2, 3, 14, 15),
          [REMOTE_FIELDS.CHANGE_BY]: 'filipe.siqueira@platosedu.com.br',
          [REMOTE_FIELDS.INFORMATION_CHANGE]: [
            'Alterar nome do usuário de Danilop para Danilo'
          ],
          [REMOTE_FIELDS.JUSTIFICATION]:
            'O cliente percebeu um erro de digitação em seu nome que gostaria de ser corrigido.'
        }
      ],
      [REMOTE_FIELDS.CPF]: [
        {
          [REMOTE_FIELDS.MOVING_DATE]: new Date(2022, 1, 10, 13, 15),
          [REMOTE_FIELDS.CHANGE_BY]: 'filipe.siqueira@platosedu.com.br',
          [REMOTE_FIELDS.INFORMATION_CHANGE]: [
            'Alterar cpf do usuário de xxxxxx para xxxxxx'
          ],
          [REMOTE_FIELDS.JUSTIFICATION]:
            'O cliente percebeu um erro de digitação em seu nome que gostaria de ser corrigido.'
        }
      ]
    }
  }
}

const getUserDetailMocked = (id) => {
  return mockedServerAnswer(
    userDetailfactory(id),
    true,
    1000,
    userDetailAdapterInbound
  )
}

export { getUserDetailMocked as getUserDetail }
