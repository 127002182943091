import React, { useState, useEffect, useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Checkbox
} from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'
import FolderIcon from '@material-ui/icons/Folder'

import useStyles from './styles'
import AvaConnection from '../../../../services/avaConnection'
import Loading from '../../../../components/Loading'
import { ConnectionContext } from '../../../../contexts/ConnectionContext'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import Messages from '../../../../helpers/messages'

function SelectSubjectsDialog() {
  const classes = useStyles()
  const {
    connection,
    updateConnection,
    toggleSubjectsDialog,
    saving,
    setSaving,
    fetching,
    setFetching
  } = useContext(ConnectionContext)

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const [subjects, setSubjects] = useState([])

  const save = async () => {
    try {
      setSaving(true)

      const selectedSubjectIds = subjects
        .filter((subject) => subject.checked === true)
        .map((item) => item.id)

      const payload = {
        modules_id: selectedSubjectIds
      }

      await AvaConnection.saveDisciplines(connection.id, payload)

      updateConnection({ modules: selectedSubjectIds })
      setSuccessMessage(Messages.subjectsDialog.success)
      toggleSubjectsDialog(false)
    } catch (error) {
      setErrorMessage(Messages.subjectsDialog.error)
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setSaving(false)
    }
  }

  useEffect(() => {
    async function fetchSubjects() {
      try {
        setFetching(true)

        const response = await AvaConnection.returnDisciplines()

        const result = await response.json()

        const subjectsList = result.list.map((subject) => {
          const checked = connection.modules.includes(subject.id)

          return {
            id: subject.id,
            name: subject.name,
            checked
          }
        })

        setSubjects(subjectsList)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setFetching(false)
      }
    }

    fetchSubjects()
  }, [connection.modules, setFetching])

  const checkOrUncheckAll = (evt) => {
    const subjectsUpdated = subjects.map((subject) => ({
      ...subject,
      checked: evt.target.checked
    }))

    setSubjects(subjectsUpdated)
  }

  const checkOrUncheckSubject = (evt, id) => {
    const subjectsUpdated = subjects.map((subject) => {
      if (subject.id === id) {
        return { ...subject, checked: evt.target.checked }
      }
      return subject
    })

    setSubjects(subjectsUpdated)
  }

  const renderBody = () => {
    if (fetching) {
      return <Loading />
    }

    return (
      <>
        <DialogTitle>Selecionar disciplinas</DialogTitle>

        <DialogContent className={classes.content}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      onChange={checkOrUncheckAll}
                      style={{ marginLeft: -8 }}
                    />
                    NOME
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {subjects.map((subject) => {
                  return (
                    <TableRow key={subject.id}>
                      <TableCell className={classes.checkboxItems}>
                        <Checkbox
                          key={subject.id}
                          checked={subject.checked}
                          onChange={(evt) =>
                            checkOrUncheckSubject(evt, subject.id)
                          }
                          color="primary"
                        />

                        <Box
                          className={
                            subject.checked
                              ? classes.checked
                              : classes.notChecked
                          }
                        >
                          <FolderIcon className={classes.pasteIcon} />
                          {subject.name}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => toggleSubjectsDialog(false)}
            disabled={saving}
          >
            CANCELAR
          </Button>

          <Button color="primary" onClick={save} disabled={saving}>
            SALVAR
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <Dialog open onClose={() => toggleSubjectsDialog(false)}>
      {renderBody()}
    </Dialog>
  )
}

export default SelectSubjectsDialog
