import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../../../../shared/shared-style-classes'

const useStyles = makeStyles((theme) => ({
  ...spacing,
  valid: {
    color: theme.palette.success.main
  },
  invalid: {
    color: theme.palette.error.main
  },
  icon: {
    color: theme.palette.text.secondary
  }
}))

export default useStyles
