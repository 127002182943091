import { selector } from 'recoil'
import { scheduledContentState } from '../scheduled-content-state'
import { SCHEDULED_CONTENT_FIELDS } from '../../../domain/courses'

const { EXERCISE_TYPE, SCHEDULED_CONTENT_TYPE } = SCHEDULED_CONTENT_FIELDS

export const scheduledContentFormTypeValues = selector({
  key: 'scheduledContentFormTypeValues',
  get: ({ get }) => {
    return {
      [EXERCISE_TYPE]: get(scheduledContentState).form.values[EXERCISE_TYPE],
      [SCHEDULED_CONTENT_TYPE]: get(scheduledContentState).form.values[
        SCHEDULED_CONTENT_TYPE
      ]
    }
  },
  set: ({ get, set }, newValue) => {
    set(scheduledContentState, {
      ...get(scheduledContentState),
      form: {
        ...get(scheduledContentState).form,
        values: {
          ...get(scheduledContentState).form.values,
          ...newValue
        }
      }
    })
  }
})
