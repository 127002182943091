import React from 'react'
import { useRecoilState } from 'recoil'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import { SelectSaraiva } from '@equipe-ninja/saraiva-ui'
import { SCHEDULED_CONTENT_TYPES } from '../../../../domain/courses'
import { scheduledContentTypeSelector } from '../../../../atoms/scheduled-content'
import { ScheduledContentTypeRelay } from '../ScheduledContentTypeRelay'

import useStyles from './styles'

export const ScheduledContent = () => {
  const classes = useStyles()
  const [contentType, setContentType] = useRecoilState(
    scheduledContentTypeSelector
  )

  return (
    <section className={classes.marginTop20}>
      <Typography variant="body1" color="textSecondary">
        Aqui é possivel cadastrar conteúdos com função de agendamento dentro da
        plataforma.
      </Typography>

      <Typography
        variant="h5"
        color="textPrimary"
        className={classes.marginTopXL}
      >
        Conteúdos agendados
      </Typography>

      <div className={classes.marginTopMedium}>
        <SelectSaraiva
          value={contentType}
          label="Tipo de conteúdo"
          size="md"
          onChange={(ev) => setContentType(ev.target.value)}
        >
          <MenuItem value="">Nenhum conteúdo selecionado</MenuItem>
          <MenuItem value={SCHEDULED_CONTENT_TYPES.OAB_SECOND_PHASE}>
            Exercícios 2º fase
          </MenuItem>
        </SelectSaraiva>
      </div>

      <Divider className={classes.marginTopMedium} />

      {contentType && <ScheduledContentTypeRelay />}
    </section>
  )
}

ScheduledContent.propTypes = {}

ScheduledContent.defaultProps = {}
