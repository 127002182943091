import { PRODUCT_COURSE_CATEGORY_REMOTE_FIELDS as REMOTE } from '../remote-fields/course-product-category-remote-fields'
import { PRODUCT_COURSE_CATEGORY_FIELDS as CATEGORY } from '../../../domain/product-course'

const inbound = (remoteData) => {
  return remoteData.map((category) => ({
    [CATEGORY.ID]: category[REMOTE.ID],
    [CATEGORY.NAME]: category[REMOTE.NAME],
    [CATEGORY.SUB_CATEGORIES]: category[REMOTE.SUB_CATEGORIES].map(
      (subcategory) => ({
        [CATEGORY.ID]: subcategory[REMOTE.ID],
        [CATEGORY.NAME]: subcategory[REMOTE.NAME]
      })
    )
  }))
}

export const CategoryAdapter = {
  inbound
}
